import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';

interface IHistoryItemProps {
    fromDateTime: string;
    type: string;
}

export const HistoryItem: React.FC<IHistoryItemProps> = ({ fromDateTime, type }) => (
    <Box borderBottom="1px solid #D1D2DB" py="10px">
        <Text color="brand" fontSize="16px" fontWeight={400} lineHeight="24px" mb="8px">
            Payment
        </Text>
        <Flex align="center" justifyContent="space-between">
            <Text color="secondaryFont" fontSize="15px" fontWeight={300} lineHeight="22px">
                {dayjs.utc(fromDateTime).format('MMM D, YYYY')}
            </Text>
            <Text color="green" fontSize="16px" lineHeight="24px" fontWeight={400}>
                ${type === 'Monthly' ? '9.99' : '69.99'}
            </Text>
        </Flex>
    </Box>
);
