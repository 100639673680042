import React, { Dispatch, SetStateAction } from 'react';
import {
    Avatar,
    Box,
    Flex,
    HStack,
    Image,
    Text,
    Tag,
    MenuButton,
    Menu,
    IconButton,
    MenuList,
    MenuItem,
    Button,
} from '@chakra-ui/react';
import { AddReportPayload, PostTypeResponse, UpdatePostPayload } from '../types';
import { baseUrl } from '../../../common/api/api.base';
import dayjs from 'dayjs';
import {
    BookMark,
    CommentsIcon,
    DeleteIcon,
    EditPostIcon,
    MoreIcon,
    ReportIcon,
} from '../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { saveTogglePostRequest, updatePostRequest } from '../store/actions';
import { colors } from '../../../common/constants';
import { getAvatarPicture } from '../../../common/utils';
import { IdNameTypes } from '../../../common/types';
import { RootStore } from '../../../store';
import _ from 'lodash';
import { useState } from 'react';
import { DetailsPostModal, EditPostModal } from '../modals';

interface IPostBoxPropsRow {
    item: PostTypeResponse;
    onAddReport: (payload: AddReportPayload) => void;
    onDeletePost: (id: number) => void;
    setEditPostId: Dispatch<SetStateAction<number>>;
    setDetailsPostId: Dispatch<SetStateAction<number>>;
    setReportPostId?: Dispatch<SetStateAction<number>>;
    setReportTypeId?: Dispatch<SetStateAction<number>>;
}

export const ItemRowPost: React.FC<IPostBoxPropsRow> = ({
    item,
    onAddReport,
    onDeletePost,
    setEditPostId,
    setDetailsPostId,
    setReportTypeId,
    setReportPostId,
}) => {
    var { reportTypes } = useSelector((state: RootStore) => state.directories);
    
    const currentUserId = useSelector((state: RootStore) => state.user.user.id);
    const dispatch = useDispatch();
    const postTags =
        item.tags.length > 2
            ? item.tags
                .slice(0, 2)
                .concat({ id: Number(new Date()), name: `+${item.tags.length - 2} More` })
            : item.tags;

    const onSaveTogglePost = () => {
        dispatch(
            saveTogglePostRequest({
                PostId: item.id,
                isSavedPost: !item.isSaved,
            }),
        );
    };

    const isMine = currentUserId === item.owner.id;
    const [showDetailsPostModal, setShowDetailsPostModal] = useState<boolean>(false);
    const [showEditPostModal, setShowEditPostModal] = useState<boolean>(false);
    
    const onUpdatePost = async (payload: UpdatePostPayload) => {
      setDetailsPostId(Number(item.id));
      setShowDetailsPostModal(false);
      setShowEditPostModal(true);
      dispatch(updatePostRequest(payload));
    };

    return (
      <>
        <div className="blast_cell">
          <div className="top_panel">
            <div className="left">
              <Avatar
                mr={'8px'}
                boxSize={'6'}
                name={'avatar'}
                src={getAvatarPicture(item.owner.avatar)}
              />
              <div className="user_name">
                {item.owner.fullName}
              </div>
              <Text color={'#888DAB'} lineHeight={'21px'} fontSize={'14px'} fontWeight={500} marginLeft="15px">
                {dayjs(item.createdAt).format('D MMMM YYYY')}
              </Text>
            </div>
            <div className="right">
              <HStack align={'24px'}>
                <Flex align={'center'} marginRight="15px">
                  <CommentsIcon />
                  {item.commentsCount && <Text ml={'4px'}>{item.commentsCount}</Text>}
                </Flex>
                <Flex as={'button'} align={'center'} onClick={onSaveTogglePost}>
                  <BookMark colorFill={item.isSaved ? '#2C335C' : 'none'} />
                  {!!item.savedCount && <Text ml={'4px'}>{item.savedCount}</Text>}
                </Flex>
                {/*
                <Flex as={'button'} align={'center'}>
                  <Menu>
                    <MenuButton>
                      <MoreIcon />
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => {
                        setShowDetailsPostModal(true);
                      }}
                      >
                        Show
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
                */}
              </HStack>
            </div>
          </div>
          <div className="contentCenter"
            onClick={() => {
              setShowDetailsPostModal(true);
            }}
            >
            <div className="ccLeft">
              <Box overflow={'hidden'}>
                { item.photo.itemHash? (
                <div className="image_cell">
                  <img
                    src={`${baseUrl}/api/storage/static-images/${item.photo.itemHash}${item.photo.itemExtension}`}
                  />
                </div>
                ) : (
                  <div className="image_cell">
                    <img
                      src={`${baseUrl}/img/noimage.svg`}
                    />
                  </div>
                )}
              </Box>
            </div>
            <div className="right">
              <div className="tile_name" onClick={() => {
                  setShowDetailsPostModal(true);
                  //setEditPostId(item.id);
                }}>
                {item.title}
              </div>
              <HStack my={'0px'} spacing={2}>
                {postTags.map(item => (
                  <Tag p={'8px 12px'} fontSize="12px" borderRadius={'40px'} key={item.id} variant={'outline'} color="#2C335C">
                    {item.name}
                  </Tag>
                ))}
              </HStack>
            </div>
          </div>
        </div>
        <DetailsPostModal
            detailsPostId={item.id}
            reportTypes={reportTypes}
            onAddReport={onAddReport}
            setReportPostId={() => {

            }}
            setReportTypeId={() => {

            }}
            onDeletePost={onDeletePost}
            setEditPostId={() => {
              setShowDetailsPostModal(false);
              setShowEditPostModal(true);
            }}
            size={'2xl'}
            isOpen={showDetailsPostModal}
            onClose={() => {
              setShowDetailsPostModal(false);
              //setDetailsPostId(0);
              //push(pathname);
            }}
            title={'Blast'}
        />
        <EditPostModal
          updatePostLoading={false}
          onUpdatePost={onUpdatePost}
          editPostId={item.id}
          size={'4xl'}
          isOpen={showEditPostModal}
          onClose={() => {
            setShowEditPostModal(false);
          }}
          title={'Edit Post'}
        />
      </>
    );
};
