import { sendIssueRequest } from './actions';
import { IResponseWithoutValue } from '../../../../types';
import API from '../../../../api/api.base';
import { call, takeEvery } from '@redux-saga/core/effects';

export function* workerSendIssue(action: ReturnType<typeof sendIssueRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            `/api/requests/issue/${action.payload.id}`,
            action.payload,
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${action.payload.token}`,
            },
        );
        console.log(result);
    } catch (err) {
        console.log('workerSendIssue error', err);
    }
}

export default function* watchSendIssueSaga() {
    yield takeEvery(sendIssueRequest.type, workerSendIssue);
}
