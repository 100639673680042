import React, { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Flex,
    HStack,
    PinInput,
    PinInputField,
    Button,
    CloseButton,
} from '@chakra-ui/react';
import { BackArrow } from '../../../assets/icons';
import { colors } from '../../../common/constants';
import Countdown from 'react-countdown';
import { Controller, useForm } from 'react-hook-form';
import { fonts, countdownRenderer } from '../../../common/utils';
import { VerifyEmailCodePayload } from '../../../pages/Settings/types';
import { loadStripe } from "@stripe/stripe-js";
import axios from 'axios';
import { Loader } from '..';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import {useHistory, useLocation} from "react-router-dom";

interface IAddPaymentModal {
    isOpen: boolean;
    onClose: () => void;
}

var stripe: any = null;
var clientSecret: string = '';
var defaultCountry = 'US';
var defaultPostal = '42223';
var stripeElement: any = null;

export const AddPaymentModal: React.FC<IAddPaymentModal> = ({ isOpen, onClose }) => {
    const {
        formState: { errors },
        control,
        watch,
        setValue,
    } = useForm({});
    const values = watch();

    const handleClose = () => {
      onClose();
    };

    const toast = useToast();
    var paymentElement = null;

    const history = useHistory();
    const { user: currentUser } = useSelector((state: RootStore) => state.user);
    const [loader, setLoader] = useState<boolean>(false);
    const [LoaderBtn, setLoaderBtn] = useState<boolean>(false);

    useEffect(() => {
      if(!isOpen){
        return;
      }
      console.log('INIT STRIPE');
      initStripeForm();
    }, [isOpen]);

    const search = useLocation().search;
    const paymentIntent = new URLSearchParams(search).get('payment_intent');

    useEffect(() => {
      if(!paymentIntent){
        return;
      }

      setTimeout(() => {
        console.log('--> SAVE PAYMENT METHOD');
        axios.post(window.baseUrl+'/api/save_user_payment_methods', {
          paymentIntent: paymentIntent
        })
        .then((response: any) => {
          history.push('/settings/payments');
          setTimeout(() => {
            location.reload();
          }, 100);
          //this.$router.push({name: 'cabinet.settings'});
          //this.$refs.PaymetMethodsList.getPayments();
        });
      }, 1000);
    }, []);

    const createStripeForm = async () => {
      setLoader(true);
      
      stripeElement = stripe.elements({
        clientSecret: clientSecret
      });

      paymentElement = stripeElement.create('payment', {
        defaultValues: {
          billingDetails:{
            email: currentUser.email,
            address: {
              country: defaultCountry,
              postal_code: defaultPostal,
            }
          },
        },
        fields: {
          billingDetails: {
            address: {
              country: 'never',
              postalCode: 'never'
            }
          }
        }
      });

      setLoader(false);

      paymentElement.mount("#payment-element");
      const linkAuthenticationElement = stripeElement.create("linkAuthentication");
      linkAuthenticationElement.mount("#link-authentication-element");
    };

    const initStripeForm = async () => {
      setLoader(true);
      await loadStripeSession();

      await axios.post(window.baseUrl+'/api/create_stripe_payment_intent')
        .then(async response => {
          setLoader(false);
          clientSecret = response.data.clientSecret;
          createStripeForm();
        }).catch(error => {
          
        });

      setLoader(false);
    };

    const loadStripeSession = async () => {
      if(stripe !== null){
        return;
      }
      let stripeObj = await loadStripe(window.stripeKey);
      stripe = stripeObj;
    };

    const addPayment = async () => {
      setLoaderBtn(true);
      await stripe.confirmPayment({
        elements: stripeElement,
        confirmParams: {
          return_url: window.location.origin+'/settings/payments?after-add-card=1',
          payment_method_data: {
            billing_details: {
              address: {
                country: defaultCountry,
                postal_code: defaultPostal,
              }
            }
          },
        }
      }).then((result: { error: any; }) => {
        setLoaderBtn(false);

        if (result.error) {
          let error = result.error;
          if(error.type === "card_error" || error.type === "validation_error"){
            toast({
              title: error.message,
              status: 'error',
              isClosable: true,
            });
            //this.messages.push(error.message);
          }else{
            toast({
              title: 'An unexpected error occured.',
              status: 'error',
              isClosable: true,
            });
            //this.messages.push("An unexpected error occured.");
          }
        }else{
          console.log('payment success');
        }
      });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent
              w={'71.5vw'}
              borderRadius={'18px'}
              h={'85vh'}
              bgColor={colors.modalBackground}
              alignItems="center"
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  w="100%"
                  px="30px"
                  pt="10px"
                  >
                    <Box as="button" onClick={onClose}>
                      <BackArrow />
                    </Box>
                    <ModalHeader flex={1} align="center">
                        <Text
                          fontSize="22px"
                          color="brand"
                          textAlign="center"
                          lineHeight="18px"
                          fontWeight={700}
                          fontFamily={fonts.montserrat}
                          >
                            Add payment card
                        </Text>
                    </ModalHeader>
                    <CloseButton onClick={onClose} />
                </Flex>
                <ModalBody w="100%" d="flex" flexDirection="column" overflowY="auto">
                    <Flex direction="column" align="center" mt="50px">
                        {loader? (<Loader spinnerSize={'md'} centerHeight={'100%'} />) :
                        (<><form
                          id="payment-form"
                          >
                          <div id="link-authentication-element" />
                          <div id="payment-element" />
                          <br />
                        </form>

                        <Button
                          width="242px"
                          height="59px"
                          variant="main"
                          mt="30px"
                          isLoading={LoaderBtn}
                          onClick={addPayment}
                          >
                          Add
                        </Button>
                        </>)}
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
