import React, { useState } from 'react';
import {
    Box,
    Container,
    HStack,
    Link,
    Flex,
    Button,
    IconButton,
    useMediaQuery,
} from '@chakra-ui/react';
import { LogoIcon } from '../../../assets/icons';
import { colors } from '../../constants';
import { Link as ReactLink } from 'react-router-dom';
import { fonts } from '../../utils';
import { MobileMenu } from './MobileMenu';
import { TopPanelHeader } from './TopPanelHeader';
import { ModalDemo } from '../../../pages/Landing/ModalDemo';
import { CenterMenuPanel } from './CenterMenuPanel';

export const LandingHeader: React.FC<{ isFinalScreen?: boolean }> = ({ isFinalScreen }) => {
    const [isLargerThan1070] = useMediaQuery('(min-width: 1070px)');
    const [isOpenModalForm, setisOpenModalForm] = useState<boolean>(false);

    return (
        <>
            <ModalDemo
                isOpen={isOpenModalForm}
                onClose={() => {
                    setisOpenModalForm(false);
                }}
            />
            <TopPanelHeader 
                isFinalScreen={isFinalScreen}
            />
            <Box
                py={isFinalScreen ? '15px' : undefined}
                my={isFinalScreen ? '0px' : '15px'}
                boxShadow={isFinalScreen ? 'base' : undefined}
            >
                <Container maxW="container.xl">
                    <Flex
                        justify="space-between"
                        align="center"
                        // py={{ base: '10px', lg: '0' }}
                        py={isLargerThan1070 ? '0' : '10px'}
                        >
                        <div className="flex_tm_center">
                            <Link href="/">
                                <LogoIcon fill={colors.brand} />
                            </Link>

                            <CenterMenuPanel />

                            <HStack display={isLargerThan1070 ? 'flex' : 'none'} spacing={'20px'}>
                                <Button
                                    height="59px"
                                    width="179px"
                                    background={colors.calendarBlue}
                                    borderRadius="30px"
                                    fontFamily={fonts.montserrat}
                                    fontWeight="bold"
                                    fontSize="14px"
                                    _hover={{ bg: colors.brand, textDecoration: 'none' }}
                                    as={Link}
                                    color="white"
                                    onClick={() => {
                                        setisOpenModalForm(true)
                                    }}
                                >
                                    Get a demo
                                </Button>
                                <Button
                                    border={'1px solid rgb(47, 128, 237)'}
                                    height="59px"
                                    width="199px"
                                    background={'white'}
                                    borderRadius="30px"
                                    fontFamily={fonts.montserrat}
                                    fontWeight="bold"
                                    fontSize="14px"
                                    _hover={{ bg: colors.secondaryFont, textDecoration: 'none' }}
                                    as={Link}
                                    color={colors.calendarBlue}
                                    href="/auth/signup/createaccount"
                                >
                                    Get Started Free
                                </Button>
                            </HStack>
                        </div>
                        <Box
                            // display={{ base: 'block', lg: 'none' }}
                            display={isLargerThan1070 ? 'none' : 'block'}
                            zIndex={10001}
                        >
                            <MobileMenu />
                        </Box>
                    </Flex>
                </Container>
            </Box>
        </>
    );
};
