type ISetValueAspercent = (
    value: string | number,
    setValueCallback: (value: string | number) => any,
) => any;

export const setValueAsPercent: ISetValueAspercent = (value, setValueCallback) => {
    const MIN_VALUE = 0;
    const MAX_VALUE = 100;
    const numberValue = Number(value);

    if (Number.isNaN(numberValue) || !value) {
        return;
    }
    if (numberValue > MAX_VALUE) {
        setValueCallback(MAX_VALUE);
    } else if (numberValue < MIN_VALUE) {
        setValueCallback(MIN_VALUE);
    } else {
        setValueCallback(value);
    }
};
