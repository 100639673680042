import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    FormControl,
    FormErrorMessage,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Box,
    Text,
    Center,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { loginResolver } from '../../../common/schemes';
import { CustomButton, CustomInput } from '../../../common/components';
import { LoginPayload } from '../../../common/types';

import { EmailIcon, LockIcon } from '../../../assets/icons';
import { theme } from '../../../theme';
import { SignInProps } from './types';
import { AuthCardPanel } from '../../../common/components/aglobal/AuthCardPanel';
import { GoogleAuth } from './GoogleAuth';

export const SignIn: React.FC<SignInProps> = ({ loading, onSubmit, nestedPath }) => {
    const {
        setValue,
        formState: { errors },
        handleSubmit,
        register,
    } = useForm<LoginPayload>({ resolver: loginResolver });

    const search = useLocation().search;
    const email = new URLSearchParams(search).get("email");

    if(email){
        setValue('email', email);
    }

    return (
        <Center w="100%" h="100%">
            <form className="fixed_form" onSubmit={handleSubmit(onSubmit)}>
                <Box
                    borderRadius={20}
                    backgroundColor="#FFFFFF"
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    p="60px"
                    className="fixed_m_box"
                    >
                    <AuthCardPanel>
                        <Stack backgroundColor="whiteAlpha.900">
                            <Heading
                                color="brand"
                                fontFamily={theme.fonts.body}
                                fontSize="25px"
                                fontWeight="700"
                                alignSelf={'start'}
                            >
                                Log in
                            </Heading>
                            <FormControl isInvalid={!!errors.email}>
                                <Text mt="30px" color="brand" fontWeight="300" fontSize="16px">
                                    Email
                                </Text>
                                <InputGroup>
                                    <InputLeftElement h="50px" w="50px" children={<EmailIcon />} />
                                    <Input
                                        type="text"
                                        {...register('email', { required: true })}
                                        label="Email"
                                        width="100%"
                                        placeholder="Email"
                                        h="50px"
                                        bg="#F6F7FB"
                                        borderRadius="80px"
                                        fontFamily={theme.fonts.body}
                                        color={theme.colors.brand}
                                        fontWeight={400}
                                        fontSize="16px"
                                    />
                                </InputGroup>
                                {errors.email && (
                                    <FormErrorMessage fontSize="16px">
                                        Email is required
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!errors.password}>
                                <InputGroup>
                                    <CustomInput
                                        type="password"
                                        register={{ ...register('password', { required: true }) }}
                                        width="100%"
                                        placeholder="Password"
                                        label="Password"
                                        isPassword
                                        leftIcon={<LockIcon />}
                                        height="50px"
                                        fontSize="16px"
                                        textSize="16px"
                                    />
                                </InputGroup>
                                {errors.password && (
                                    <FormErrorMessage fontSize="16px">
                                        Password is required
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                            <Text
                                mt="20px !important"
                                textAlign="start"
                                as="ins"
                                fontFamily={theme.fonts.body}
                                color={theme.colors.secondaryFontDarker}
                                fontWeight={400}
                                fontSize="16px"
                            >
                                <Link to={`email`}>Forgot Your Password?</Link>
                            </Text>
                            <Box mt="24px !important">
                                <CustomButton
                                    type="submit"
                                    isLoading={loading}
                                    loadingText="Signing in..."
                                    text="Sign In"
                                    width="100%"
                                    height="50px"
                                    bgColor="brand"
                                    boxShadow="0px 3px 3px grey"
                                />
                            </Box>

                            <br/>
                            <div className="g-auth-outer">
                                <GoogleAuth />
                            </div>

                        </Stack>
                    </AuthCardPanel>
                </Box>
            </form>
        </Center>
    );
};
