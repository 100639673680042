import dayjs from 'dayjs';

import { getDuration } from '../../../../../common/components/blocks/DatePicker/utils';
import {
    EXPIRATION_STATUS,
    WARN_EXPIRY_LIMIT,
} from '../../../../../common/constants/license.constants';
import { ExpirationStatus } from '../../../../../common/types/license.types';

import { LicenseType } from '../../../types';

interface ReturnType {
    expirationStatus: ExpirationStatus;
    expiresIn: number;
}

export const useLicense = (license: LicenseType): ReturnType => {
    const { expirationDate, isVerified, isNew } = license;

    const days = getDuration(expirationDate, dayjs().toString()).asDays();
    const duration = Math.ceil(days);

    const defineExpirationStatus = () => {
        const newLicense = isNew;
        const activeLicenseWithoutWarn = duration > 0 && duration > WARN_EXPIRY_LIMIT;
        const activeLicenseWithWarn = duration > 0 && duration <= WARN_EXPIRY_LIMIT;

        if (newLicense) return EXPIRATION_STATUS.NEW;
        if (activeLicenseWithoutWarn) return EXPIRATION_STATUS.ACTIVE;
        if (activeLicenseWithWarn) return EXPIRATION_STATUS.EXPIRING;

        return EXPIRATION_STATUS.EXPIRED;
    };

    return { expirationStatus: defineExpirationStatus(), expiresIn: duration };
};
