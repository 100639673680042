import React, { FC } from 'react';

import { Box, Center, SimpleGrid, Text } from '@chakra-ui/react';

import { InfiniteScroll, Loader } from '../../../common/components/blocks';
import AgentInfoCard from '../../../common/components/blocks/AgentInfoCard';

import { PersonCardType } from '../../../common/types';
import { INFINITE_SCROLL_HEIGHT } from '../../../common/constants';

interface IProps {
    loading: boolean;
    items: PersonCardType[];
    loadMoreLoading: boolean;
    loadMoreItems: () => void;
    withoutAvatar?: boolean;
}

const getFullName = (firstName?: string, lastName?: string) => {
    if (firstName && !lastName) {
        return firstName;
    }

    if (!firstName && lastName) {
        return lastName;
    }

    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    }

    return '';
};

const PeopleCardsContainer: FC<IProps> = ({
    items,
    loadMoreLoading,
    loading,
    loadMoreItems,
    withoutAvatar = false,
}) => {
    return (
        <Box>
            {loading ? (
                <Loader spinnerSize="md" centerHeight={INFINITE_SCROLL_HEIGHT} />
            ) : (
                <InfiniteScroll
                    height={INFINITE_SCROLL_HEIGHT}
                    boxProps={{ pt: '10px' }}
                    onReachBottom={loadMoreItems}
                >
                    {items.length ? (
                        <SimpleGrid templateColumns="1fr 1fr" gap="40px">
                            {items.map(({ firstName, lastName, id, ...item }, index) => {
                                return (
                                    <AgentInfoCard
                                        key={index}
                                        fullName={getFullName(firstName, lastName)}
                                        userId={id}
                                        withoutAvatar={withoutAvatar}
                                        {...item}
                                    />
                                );
                            })}
                        </SimpleGrid>
                    ) : (
                        <Center h="100%">
                            <Text color="brand" fontSize="16px">
                                There is no agency roster at this time
                            </Text>
                        </Center>
                    )}

                    {loadMoreLoading && <Loader spinnerSize="md" centerHeight="100px" />}
                </InfiniteScroll>
            )}
        </Box>
    );
};

export default PeopleCardsContainer;
