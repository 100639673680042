import React, { useState } from 'react';
import { SubmitHandler, Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';

import { Box, Text, Flex, HStack, PinInput, PinInputField, Checkbox } from '@chakra-ui/react';

import { CustomInput, CustomButton } from '../../../../../common/components';
import { Typography } from '../../../../../common/components/blocks/Typography/Typography';

import { EmailIcon, NameInputIcon, CheckIcon } from '../../../../../assets/icons';

import { ISignUpProps, SignUpData } from '../../types';

import { colors } from '../../../../../common/constants';

export const SignUpForm: React.FC<ISignUpProps> = ({
    loading,
    stateErrors,
    nestedPath,
    onSendEmailCode,
    step,
    onConfirmEmail,
    onSendPhoneCode,
    onConfirmPhone,
    accessToken,
    onSetStep,
}) => {
    const [isAgree, setIsAgree] = useState(false);
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
        watch,
        reset,
    } = useForm<SignUpData>({});

    const fullName = `${watch('firstName')}${
        watch('middleName') ? ` ${watch('middleName')}` : ''
    } ${watch('lastName')}`;

    const onSubmit: SubmitHandler<SignUpData> = data => {
        switch (step) {
            case 1:
                const sendEmailCodePayload = {
                    firstName: data.firstName.trim(),
                    lastName: data.lastName.trim(),
                    email: data.email.trim(),
                    middleName: data.middleName?.trim(),
                };
                onSendEmailCode(sendEmailCodePayload);
                break;
            case 2:
                const payload = {
                    email: data.email,
                    token: data.phoneNumberCode? data.phoneNumberCode : '0000',
                };
                onConfirmEmail(payload);
                break;
            case 3:
                const sendPhoneCodePayload = {
                    token: accessToken,
                    payload: {
                        phoneNumber: `+1${data.phoneNumber.replace(/[^\d]/g, '')}`,
                    },
                };
                onSendPhoneCode(sendPhoneCodePayload);
                break;
            case 4:
                const confirmPhonePayload = {
                    token: accessToken,
                    payload: {
                        phoneNumber: `+1${data.phoneNumber.replace(/[^\d]/g, '')}`,
                        code: data.emailCode,
                    },
                };
                onConfirmPhone(confirmPhonePayload);
                reset();
                break;
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {step < 3 && (
                <>
                    <CustomInput
												disabled={(step < 2)? false : true}
                        label="First Name*"
                        width="100%"
                        leftIcon={<NameInputIcon />}
                        rightIcon={step >= 3 && <CheckIcon />}
                        placeholder="First Name"
                        margin="26px 0 0 0"
                        register={{ ...register('firstName') }}
                        errors={errors.firstName}
                        pattern="[A-Za-z]"
                        fontSize="14px"
                        textSize="16px"
                        isBorder={false}
                        height="50px"
                    />
                    <CustomInput
												disabled={(step < 2)? false : true}
                        label="Middle Name or Initial"
                        width="100%"
                        leftIcon={<NameInputIcon />}
                        rightIcon={step >= 3 && <CheckIcon />}
                        placeholder="Middle Name Or Initial"
                        margin="26px 0 0 0"
                        register={{ ...register('middleName') }}
                        errors={errors.middleName}
                        isBorder={false}
                        fontSize="14px"
                        textSize="16px"
                        height="50px"
                    />
                    <CustomInput
												disabled={(step < 2)? false : true}
                        label="Last Name*"
                        width="100%"
                        leftIcon={<NameInputIcon />}
                        rightIcon={step >= 3 && <CheckIcon />}
                        placeholder={'Last Name'}
                        register={{ ...register('lastName') }}
                        margin="26px 0 0 0"
                        errors={errors.lastName}
                        isBorder={false}
                        fontSize="14px"
                        textSize="16px"
                        height="50px"
                    />
                </>
            )}
            {step >= 3 && (
                <CustomInput
                    label="Full Name"
                    width="100%"
                    leftIcon={<NameInputIcon />}
                    rightIcon={step >= 3 && <CheckIcon />}
                    placeholder="Full Name"
                    value={fullName}
                    margin="26px 0 0 0"
                    isBorder={false}
                    fontSize="14px"
                    textSize="16px"
                    height="50px"
                    disabled
                />
            )}
							<>
								<CustomInput
										disabled={(step < 2)? false : true}
										label="Email*"
										width="100%"
										leftIcon={<EmailIcon />}
										rightIcon={step >= 3 && <CheckIcon />}
										placeholder="Email"
										margin="26px 0 0 0"
										register={{ ...register('email') }}
										errors={errors.email}
										isBorder={false}
										fontSize="14px"
										textSize="16px"
										height="50px"
								/>
								{(step > 1) && (
									<div className="change_email_btn" onClick={() => {
											onSetStep(1);
									}}>
											Change email
									</div>
									)}
							</>
            {step === 2 && (
                <>
                    <Box
                        pt="22px"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        <Text color="brand" fontWeight={300} fontSize="14px" lineHeight="18px">
                            Enter Code We Sent To Your Email
                        </Text>
                        <Text color="green" fontWeight={300} fontSize="14px" lineHeight="18px">
                            <Countdown
                                date={Date.now() + 5 * 60 * 1000}
                                renderer={(props: any) => {
                                    const timerValue = `${props.formatted.minutes}:${props.formatted.seconds}`;
                                    return <Text>{timerValue}</Text>;
                                }}
                                onComplete={() => {
                                    onSetStep(1);
                                }}
                            />
                        </Text>
                    </Box>
                    <Box mt="16px">
                        <Controller
                            control={control}
                            name="phoneNumberCode"
                            render={({ field: { ref, ...restField } }) => (
                                <HStack
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                >
                                    <PinInput placeholder="-" autoFocus={true} {...restField}>
                                        <PinInputField
                                            w="65px"
                                            h="65px"
                                            borderRadius="15px"
                                            ref={ref}
                                        />
                                        <PinInputField w="65px" h="65px" borderRadius="15px" />
                                        <PinInputField w="65px" h="65px" borderRadius="15px" />
                                        <PinInputField w="65px" h="65px" borderRadius="15px" />
                                    </PinInput>
                                </HStack>
                            )}
                        />
                    </Box>
                </>
            )}
            {step >= 3 && (
							<>
                <CustomInput
										disabled={(step < 4)? false : true}
                    label="Phone Number"
                    type="tel"
                    width="100%"
                    mask="999-999-9999"
                    placeholder="Phone Number"
                    margin="26px 0 0 0"
                    register={{ ...register('phoneNumber') }}
                    errors={errors.phoneNumber}
                    isBorder={false}
                    fontSize="14px"
                    textSize="16px"
                    height="50px"
                />
								{ (step === 4) && (
									<div className="change_email_btn" onClick={() => {
											onSetStep(3);
									}}>
											Change phone
									</div>
								)}
							</>
            )}
            {step >= 4 && (
                <>
                    <Box
                        pt="22px"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        <Text color="brand" fontWeight={300} fontSize="14px" lineHeight="18px">
                            Enter Code We Sent To Your Phone
                        </Text>
                        <Text color="green" fontWeight={300} fontSize="14px" lineHeight="18px">
                            <Countdown
                                date={Date.now() + 5 * 60 * 1000}
                                renderer={(props: any) => {
                                    const timerValue = `${props.formatted.minutes}:${props.formatted.seconds}`;
                                    return <Text>{timerValue}</Text>;
                                }}
                                onComplete={() => {
                                    onSetStep(3);
                                }}
                            />
                        </Text>
                    </Box>
                    <Box mt="16px">
                        <Controller
                            control={control}
                            name="emailCode"
                            render={({ field: { ref, ...restField } }) => (
                                <HStack
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                >
                                    <PinInput placeholder="-" autoFocus={true} {...restField}>
                                        <PinInputField
                                            w="65px"
                                            h="65px"
                                            borderRadius="15px"
                                            ref={ref}
                                        />
                                        <PinInputField w="65px" h="65px" borderRadius="15px" />
                                        <PinInputField w="65px" h="65px" borderRadius="15px" />
                                        <PinInputField w="65px" h="65px" borderRadius="15px" />
                                    </PinInput>
                                </HStack>
                            )}
                        />
                    </Box>
                </>
            )}
            {step === 1 ? (
                <Flex mt={'14px'} alignItems={'flex-start'} justifyContent={'space-between'}>
                    <Checkbox
                        sx={{
                            '.chakra-checkbox__control': {
                                borderRadius: '6px',
                                width: '16.2px',
                                height: '15.4px',
                            },
                            '.chakra-checkbox__control[data-checked]': {
                                backgroundColor: '#2C335C',
                                padding: '4px',
                            },
                            '.chakra-checkbox__control div svg': {
                                width: '0.8em',
                            },
                        }}
                        mt={'5px'}
                        mr={'5px'}
                        isChecked={isAgree}
                        onChange={e => {
                            setIsAgree(e.target.checked);
                        }}
                    />
                    <Box>
                        <Typography size={13} color={colors.secondaryFontDarker}>
                            By checking this box you agree to{' '}
                            <Link to={'/terms-and-conditions'}>
                                <Typography
                                    textDecoration={'underline'}
                                    color={'#1177BE'}
                                    weight={300}
                                    size={13}
                                >
                                    Our Terms of Service
                                </Typography>
                            </Link>{' '}
                            and{' '}
                            <Link to={'/privacy-policy'}>
                                <Typography
                                    textDecoration={'underline'}
                                    color={'#1177BE'}
                                    weight={300}
                                    size={13}
                                >
                                    Privacy Policy
                                </Typography>
                            </Link>
                        </Typography>
                    </Box>
                </Flex>
            ) : null}

            <Box mt="24px !important">
                <CustomButton
                    disabled={!isAgree}
                    type="submit"
                    isLoading={loading}
                    loadingText="Signing up..."
                    text="Next"
                    width="100%"
                    height="60px"
                    bgColor="brand"
                    boxShadow="0px 3px 3px grey"
                />
            </Box>
        </form>
    );
};
