import React, { useMemo, useState } from 'react';
import {
    Text,
    Box,
    List,
    Input,
    InputGroup,
    InputRightElement,
    Spinner,
    Flex,
} from '@chakra-ui/react';
//components
import { ConversationsListItem } from '../ConversationsItem/ConversationsItem';
import { ConversationPagination } from '../ConversationsPagination/ConversationsPagination';
import { SearchIcon } from '../../../../../assets/icons';
import { IConversationsList } from '../../../types';

let PageSize = 7;
export const ConversationsList: React.FC<IConversationsList> = ({
    conversations,
    currentConversation,
    setConversation,
    userId,
    setFilterInput,
    filterInput,
    conversationReady,
    token
}) => {
    const [currentPage, setCurrentPage] = useState<number>(1);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return conversations.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, conversations]);

    return (
        <>
            <Flex justifyContent="space-between" alignItems="center" pl="1vw" pr="1vw" mt="20px">
                <Text color="brand" fontSize="20px" fontWeight="600">
                    Messages
                </Text>
            </Flex>
            <Box p="0 15px" m="12px 0 10px 0">
                <InputGroup>
                    <Input
                        h="50px"
                        bg="#F6F7FB"
                        borderRadius="80px"
                        color="brand"
                        placeholder="Search By Name"
                        border="none"
                        fontSize="15px"
                        value={filterInput}
                        onChange={(event: any) => {
                            setFilterInput(event.target.value);
                        }}
                    />
                    <InputRightElement h="100%" mr="5px" zIndex="10" children={<SearchIcon />} />
                </InputGroup>
            </Box>
            <Box h="calc(90vh - 130px)">
                <Box height="calc(100% - 40px)" overflowY="auto">
                    <List>
                        {conversations.length ? (
                            currentTableData.map((item: any) => {
                                const correctItem = item.channelState.attributes.filter(
                                    (item: any) => {
                                        return item.Id !== userId;
                                    },
                                )[0];
                                return (
                                    <ConversationsListItem
                                        key={item.sid}
                                        id={item.sid}
                                        userId={userId}
                                        conversation={item}
                                        currentConversation={currentConversation}
                                        setConversation={setConversation}
                                        token={token}
                                        chatParticipantId={correctItem.Id}
                                    />
                                );
                            })
                        ) : conversationReady ? (
                            <Text p="0 1vw">No chats yet</Text>
                        ) : (
                            <Flex w="100%" justifyContent="center" mt="50px">
                                <Spinner color="brand" size="xl" />
                            </Flex>
                        )}
                    </List>
                </Box>
                <ConversationPagination
                    currentPage={currentPage}
                    totalCount={conversations.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
            </Box>
        </>
    );
};
