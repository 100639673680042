import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { from8amTo9pmTimeYupResolver } from '../constants';

import { ErrorMessages } from './messages';

const editableTimeSlotSchema = yup.object().shape({
    date: yup.number().positive(ErrorMessages.REQUIRED).required(ErrorMessages.REQUIRED),
    endTime: from8amTo9pmTimeYupResolver,
    startTime: from8amTo9pmTimeYupResolver,
    price: yup
        .number()
        .positive(ErrorMessages.POSITIVE_NUMBER)
        .typeError(ErrorMessages.NUMBER_INVALID),
});

export const editableTimeSlotResolver = yupResolver(editableTimeSlotSchema);
