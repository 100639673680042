import React from 'react';
import { Box, Container, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

import { fonts } from '../../common/utils';

import {
  LandingHeader,
  LandingFooter,
} from '../../common/components/landing';

import RequestDemoBG from '../../assets/landing/request-demo-bg.png'
import { requestDemoContent } from '../../common/constants';
import { BlueDash } from '../../assets/icons/index';
import RequestDemoForm from './RequestDemoForm';
import { LandingFooterv2 } from '../../common/components/landing/LandingFooterv2';

const RequestDemo: React.FC = () => {

  return (
    <Box>
      <LandingHeader />
      <Box
            h={{ base: '250px', lg: '450px' }}
            w="100%"
            backgroundRepeat="no-repeat"
            backgroundImage={RequestDemoBG}
            position="relative"
            backgroundSize="cover"
            backgroundPosition="center"
            overflowY="hidden"
        >
            <Box
                position="absolute"
                background="linear-gradient(180deg, #1E223D 0%, #2C335C 100%)"
                h="100%"
                w="100%"
                top="0"
                left="0"
                opacity={0.7}
                zIndex={1}
            />
            <Container maxW="container.xl" zIndex={2} position="inherit" h="100%">
                <Flex direction="column" height="100%" justify="center" pb="70px">
                    <Heading
                        color="white"
                        fontFamily={fonts.montserrat}
                        fontSize={{ base: '28px', lg: '52px' }}
                        lineHeight={{ base: '34px', lg: '62px' }}
                        fontWeight="bold"
                        w={{ base: '100%', lg: '60%' }}
                    >
                        See ZootPro in action
                    </Heading>
                    <Text
                        color="white"
                        fontFamily={fonts.montserrat}
                        fontSize="16px"
                        lineHeight="28px"
                        my="23px"
                        w={{ base: '100%', lg: '50%' }}
                    >
                        Experience a live customized demo, get answers to your specific questions, and find out why ZootPro is the right choice for your brokerage.
                    </Text>
                </Flex>
            </Container>
        </Box>
        <Box
            bg="white"
            borderTopRadius={{ base: '30px', md: '70px' }}
            mt="-70px"
            zIndex={10}
            position="relative"
        >
            <Container maxW="container.xl" py={{ base: '30px', lg: '75px' }}>
                <Flex 
                  marginTop={{base: '48px', md: '128px'}} 
                  marginBottom={{base: '48px', md: '128px'}} 
                  justifyContent="center"
                  alignItems={{base: 'center', md: 'start'}} 
                  direction={{base: 'column', md: 'row'}}
                >
                  <Box width={{base: '90%', md: '45%'}} marginRight={{base: '0', md: '106px'}} marginBottom={{base: '20px', md: '0'}}>
                    <Heading
                      color="#2C335C"
                      fontFamily={fonts.montserrat}
                      fontSize={{ base: '28px', lg: '46px' }}
                      lineHeight={{ base: '34px', lg: '56px' }}
                      fontWeight="700"
                      w={{ base: '100%', lg: '100%' }}
                    >
                      What can I expect?
                    </Heading>
                    <Box marginTop="8px">
                      <BlueDash />
                    </Box>
                    <Box marginTop="32px">
                      {requestDemoContent.map(block => (
                        <Flex key={block.text}>
                          <Icon 
                            as={CheckIcon} 
                            color='#2F80ED' 
                            w="14px" 
                            h="14px" 
                            marginRight="8px"
                            marginTop="6px"
                          ></Icon>
                          <Text
                            color="#2C335C"
                            fontFamily={fonts.mulish}
                            fontSize="15px"
                            lineHeight="25px"
                          >
                            {block.text}
                          </Text>
                        </Flex>
                      ))}
                    </Box>
                  </Box>
                  <Box 
                    width={{base: '100%', md: '512px'}} 
                    border="1px solid #D1D2DB" 
                    padding={{base: "24px", md: '48px'}}
                  >
                    <RequestDemoForm />
                  </Box>
                </Flex>
            </Container>
        </Box>
      <LandingFooterv2 />
    </Box>
  )
}

export default RequestDemo;
