import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Flex,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    CloseButton,
} from '@chakra-ui/react';
import { RootStore } from '../../../../store';
import { fonts, secondsToMinutes } from '../../../../common/utils';
import { colors, getInspectionItems, yesOrNo } from '../../../../common/constants';
import {
    CustomInput,
    CustomDropdown,
    RequestQuickInfo,
    CustomButton,
    Loader,
} from '../../../../common/components';
import { RequestInfoType } from '../../types';
import { CheckIcon, Clock } from '../../../../assets/icons';
import dayjs from 'dayjs';
import { ApplyingJobModal } from '../../../../common/components/modals/ApplyingJob/ApplyingJobModal';
import { IdNameTypes } from '../../../../common/types';

interface IAvailableRequestDetailsModal {
    isOpen: boolean;
    requestInfoLoading: boolean;
    requestInfo: RequestInfoType;
    onClose: Dispatch<SetStateAction<boolean>>;
    handleAcceptRequest: (IsAccepted: boolean, RequestId: number) => void;
}

export const AvailableRequestDetailsModal: React.FC<IAvailableRequestDetailsModal> = ({
    isOpen,
    onClose,
    requestInfo,
    requestInfoLoading,
    handleAcceptRequest,
}) => {
    const { loading, success } = useSelector((state: RootStore) => state.requests.acceptRequest);
    const bonusTypes = useSelector((state: RootStore) => state.directories.bonusTypes);

    const [agentBonusModalOpen, setAgentBonusModalOpen] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);

    // useEffect(() => {
    //     if (isOpen && requestInfo.createdAt) {
    //         const date1 = dayjs(requestInfo.createdAt);
    //         const date2 = dayjs();
    //         const timeDiff = date2.diff(date1, 'seconds');
    //         if (timeDiff < 3600) {
    //             setTimeLeft(3600 - timeDiff);
    //         } else {
    //             setTimeout(() => {
    //                 onClose(false);
    //             }, 1000);
    //         }
    //     }
    // }, [isOpen, requestInfo]);

    useEffect(() => {
        if (success) {
            onClose(false);
        }
    }, [success]);

    useEffect(() => {
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
            if (timeLeft <= 0) setTimeLeft(0);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const handlerAcceptRequest = (IsAccepted: boolean) => {
        handleAcceptRequest(IsAccepted, requestInfo.id);
        setTimeout(() => {
            location.reload();
            //onClose(false), 1000
        }, 50);
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => onClose(!isOpen)}
                size="4xl"
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent
                    borderRadius={'18px'}
                    h={'85vh'}
                    bgColor="#EBF2F8"
                    alignItems="center"
                >
                    <Flex
                        mt="36px"
                        alignItems="center"
                        justifyContent="space-between"
                        w="100%"
                        px="24px"
                    >
                        <Text
                            color={colors.brand}
                            fontWeight={700}
                            fontSize="22px"
                            lineHeight="18px"
                            fontFamily={fonts.montserrat}
                        >
                            Assistance: {requestInfo.requestTypeName}
                        </Text>
                        <Box as="button" onClick={() => onClose(!isOpen)}>
                            <CloseButton />
                        </Box>
                    </Flex>
                    <ModalBody w="100%" d="flex" flexDirection="column" mt={10}>
                        {requestInfoLoading ? (
                            <Loader spinnerSize="md" centerHeight="100%" />
                        ) : (
                            <>
                                <Flex justifyContent="space-between">
                                    <Box w="50%" pr="20px">
                                        <RequestQuickInfo
                                            date={requestInfo.dateTime}
                                            fromTime={requestInfo.fromTime}
                                            duration={requestInfo.duration}
                                            propertiesToShow={requestInfo.propertiesToShow}
                                            requestTypeName={requestInfo.requestTypeName}
                                            totalPrice={null}
                                            margin={0}
                                        />
                                        <Box mt={6}>
                                            <CustomInput
                                                defaultValue={requestInfo.states.join(', ')}
                                                label="State"
                                                register=""
                                                isBorder={false}
                                                bgColor="white"
                                                isReadOnly
                                            />
                                        </Box>
                                        <Text
                                            color="brand"
                                            fontWeight={700}
                                            mt={5}
                                            fontSize="16px"
                                            lineHeight="24px"
                                            fontFamily={fonts.poppins}
                                        >
                                            Addresses
                                        </Text>
                                        {requestInfo?.addresses &&
                                            requestInfo.addresses.map((address: string) => (
                                                <CustomInput
                                                    key={address}
                                                    placeholder="Address"
                                                    label=""
                                                    bgColor="white"
                                                    register={{ value: address }}
                                                    isReadOnly
                                                />
                                            ))}
                                        {!!requestInfo.generalNote && (
                                            <>
                                                <Text
                                                    color={colors.brand}
                                                    fontWeight={700}
                                                    fontSize="18px"
                                                    mt={5}
                                                    lineHeight="18px"
                                                    fontFamily={fonts.montserrat}
                                                >
                                                    General Notes:
                                                </Text>
                                                <Text
                                                    color={colors.brand}
                                                    fontWeight={400}
                                                    fontSize="16px"
                                                    mt={2}
                                                    lineHeight="26px"
                                                    fontFamily={fonts.poppins}
                                                >
                                                    {requestInfo.generalNote}
                                                </Text>
                                            </>
                                        )}
                                    </Box>
                                    <Box w="50%" pl="20px">
                                        {requestInfo.typeId !== 4 ? (
                                            <CustomDropdown
                                                label={`Is the ${requestInfo.requestTypeName} scheduled?`}
                                                options={getInspectionItems(
                                                    requestInfo.requestTypeName,
                                                )}
                                                register={{
                                                    value: requestInfo.isShowingScheduled
                                                        ? `Yes, I Have Scheduled The ${requestInfo.requestTypeName}`
                                                        : `No, the Agent Will Schedule The ${requestInfo.requestTypeName}`,
                                                    isReadOnly: true,
                                                    pointerEvents: 'none',
                                                }}
                                                errors=""
                                                margin="0 0 10px 0"
                                                bgColor="white"
                                            />
                                        ) : (
                                            <CustomDropdown
                                                label="Is access to The Property scheduled?"
                                                options={yesOrNo}
                                                register={{
                                                    value: requestInfo.isShowingScheduled
                                                        ? 'Yes'
                                                        : 'No',
                                                    isReadOnly: true,
                                                    pointerEvents: 'none',
                                                }}
                                                errors=""
                                                margin="20px 0 10px 0"
                                            />
                                        )}
                                        {requestInfo.typeId === 1 &&
                                        requestInfo.wouldLikeToOfferBonus ? (
                                            <>
                                                <CustomDropdown
                                                    label="I would like to offer a Bonus if a contract is ratified from the showing list within 48-hours after the showing:"
                                                    options={yesOrNo}
                                                    register={{
                                                        value: requestInfo.wouldLikeToOfferBonus
                                                            ? 'Yes'
                                                            : 'No',
                                                        isReadOnly: true,
                                                        pointerEvents: 'none',
                                                    }}
                                                    errors=""
                                                    margin="0 0 10px 0"
                                                    bgColor="white"
                                                />
                                                <Flex>
                                                    <CustomDropdown
                                                        register={{
                                                            value: requestInfo.bonusTypeId,
                                                            isReadOnly: true,
                                                            pointerEvents: 'none',
                                                        }}
                                                        options={bonusTypes.map(
                                                            (elem: IdNameTypes, index: number) => ({
                                                                label: elem.name,
                                                                value: elem.id,
                                                                id: index,
                                                            }),
                                                        )}
                                                        label="Bonus Type"
                                                        errors=""
                                                        margin="0 0 10px 0"
                                                        width="100%"
                                                        bgColor="white"
                                                    />
                                                    <Box w="20px" />
                                                    <CustomInput
                                                        label="Bonus"
                                                        bgColor="white"
                                                        register={{
                                                            value: requestInfo.amount?.toString(),
                                                            isReadOnly: true,
                                                        }}
                                                        margin="0 0 10px 0"
                                                        width="100%"
                                                    />
                                                </Flex>
                                            </>
                                        ) : null}
                                    </Box>
                                </Flex>
                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mt="auto"
                                    mb={5}
                                >
                                    <Box flex={1} mr="40px">
                                        <Flex flex={1} justify="space-between" align="center">
                                            <Text
                                                fontSize="13px"
                                                lineHeight="19px"
                                                color="secondaryFontDarker"
                                            >
                                                Potential Earning:
                                            </Text>
                                            <Text
                                                fontSize="19px"
                                                lineHeight="28px"
                                                fontWeight={500}
                                                color="brand"
                                            >
                                                ${requestInfo.totalPrice}
                                            </Text>
                                        </Flex>
                                        <Flex flex={1} justify="space-between" align="center">
                                            <Text
                                                color="secondaryFontDarker"
                                                fontSize="15px"
                                                lineHeight="18px"
                                            >
                                                Are you available?
                                            </Text>
                                            <HStack>
                                                {/*
                                                <Clock fill="#4FD1C5" />
                                                <Text
                                                    color="#4FD1C5"
                                                    fontSize="16px"
                                                    fontFamily={fonts.montserrat}
                                                    fontWeight={500}
                                                >
                                                    {secondsToMinutes(timeLeft)}
                                                </Text>
                                                */}
                                            </HStack>
                                        </Flex>
                                    </Box>
                                    <HStack spacing="20px" flex={1}>
                                        <CustomButton
                                            text="Reject"
                                            color="#FE805C"
                                            bgColor="#EEE1E1"
                                            height="60px"
                                            borderRadius="8px"
                                            icon={<CloseButton />}
                                            onClick={() =>
                                                handleAcceptRequest(false, requestInfo.id)
                                            }
                                            hoverColor="#EEC7C7"
                                        />
                                        <CustomButton
                                            text="Confirm"
                                            color="#6FCF97"
                                            bgColor="#D9EDEA"
                                            height="60px"
                                            borderRadius="8px"
                                            icon={<CheckIcon />}
                                            onClick={() => setAgentBonusModalOpen(true)}
                                            hoverColor="#C3EDDA"
                                            isLoading={loading}
                                        />
                                    </HStack>
                                </Flex>
                            </>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
            <ApplyingJobModal
                isOpen={agentBonusModalOpen}
                onClose={() => setAgentBonusModalOpen(false)}
                onConfirm={() => handlerAcceptRequest(true)}
                amount={requestInfo.totalPrice}
                type={'$'}
            />
        </>
    );
};
