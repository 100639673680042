import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Box,
    CloseButton,
    Flex,
    Text,
    Avatar,
    HStack,
    IconButton,
} from '@chakra-ui/react';
import { colors } from '../../../common/constants';
import { Loader, OpenHouseInfoSection } from '../../../common/components';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { getListingInfo } from '../../../store/actions/listings.actions';
import { useDelta } from 'react-delta';
import { Alerter, fonts, getAvatarPicture } from '../../../common/utils';
import { ShareIcon } from '../../../assets/icons/ShareIcon';
import { SharingModal } from '../../../common/components/modals/SharingModal/SharingModal';

interface IListingDetailsModalProps {
    isOpen: boolean;
    onClose: () => void;
    listingId: number;
}

export const ListingDetailsModal: React.FC<IListingDetailsModalProps> = ({
    isOpen,
    onClose,
    listingId,
}) => {
    const [isSharingModalOpen, setIsSharingModalOpen] = useState(false);

    const dispatch = useDispatch();

    const { loading, value: listingInfo } = useSelector(
        (state: RootStore) => state.listings.listingInfo,
    );
    const { loading: addSlotLoading, errors: addSlotErrors } = useSelector(
        (state: RootStore) => state.listings.addTimeSlot,
    );

    const addSlotLoadingDelta = useDelta(addSlotLoading);

    useEffect(() => {
        if (isOpen) {
            dispatch(getListingInfo(listingId));
        }
    }, [isOpen]);

    useEffect(() => {
        if (addSlotLoadingDelta && addSlotLoadingDelta.prev) {
            if (
                addSlotLoadingDelta.prev &&
                !addSlotLoadingDelta.curr &&
                !addSlotErrors.length &&
                isOpen
            ) {
                onClose();
                Alerter.info('This listing is now open house', 2000);
            }
        }
    }, [addSlotLoadingDelta]);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    });

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="5xl"
            scrollBehavior="inside"
            autoFocus={false}
        >
            <ModalOverlay />
            <ModalContent
                h="85vh"
                borderRadius="18px"
                bgColor={colors.modalBackground}
                alignItems="center"
                px="70px"
            >
                <ModalHeader
                    display="flex"
                    flexDirection="row"
                    w="100%"
                    px="0"
                    justifyContent="space-between"
                >
                    <Text color="brand" fontSize="22px" fontFamily={fonts.montserrat}>
                        Property Details
                    </Text>
                    <HStack spacing={1}>
                        <IconButton
                            aria-label="Share button"
                            icon={<ShareIcon />}
                            w="32px"
                            h="32px"
                            minW="auto"
                            onClick={() => setIsSharingModalOpen(true)}
                        />
                        <CloseButton onClick={onClose} />
                    </HStack>
                </ModalHeader>
                <ModalBody d="flex" flexDirection="column" w="100%" p="0">
                    <>
                        {loading ? (
                            <Loader centerHeight="100%" />
                        ) : (
                            <Flex>
                                <OpenHouseInfoSection
                                    openHouseInfo={listingInfo}
                                    optionalContainerProps={{ borderRight: 'none' }}
                                />
                                <Box flexShrink={0} width="35%" pr="15px">
                                    {isLoaded ? (
                                        <GoogleMap
                                            options={{ disableDefaultUI: true }}
                                            mapContainerStyle={{
                                                height: '275px',
                                                width: '100%',
                                                borderRadius: '20px',
                                            }}
                                            center={{
                                                lat: Number(listingInfo?.latitude),
                                                lng: Number(listingInfo?.longitude),
                                            }}
                                            zoom={10}
                                        >
                                            <Marker
                                                position={{
                                                    lat: Number(listingInfo?.latitude),
                                                    lng: Number(listingInfo?.longitude),
                                                }}
                                            />
                                        </GoogleMap>
                                    ) : (
                                        <></>
                                    )}
                                    <HStack spacing={2} mt="20px">
                                        <Avatar
                                            name={listingInfo?.agentName}
                                            src={getAvatarPicture(listingInfo?.avatar)}
                                            w="54px"
                                            h="54px"
                                        />
                                        <Box>
                                            <Text
                                                color="brand"
                                                fontWeight={700}
                                                fontSize="16px"
                                                lineHeight="23px"
                                            >
                                                {listingInfo?.agentName}
                                            </Text>
                                            <Text
                                                color="secondaryFontDarker"
                                                fontSize="12px"
                                                lineHeight="18px"
                                            >
                                                MLS Id: {listingInfo?.agentMlsId}
                                            </Text>
                                        </Box>
                                    </HStack>
                                    <Text
                                        mt="10px"
                                        fontSize="16px"
                                        color="secondaryFontDarker"
                                        lineHeight="23px"
                                        fontWeight={500}
                                    >
                                        {listingInfo?.agentOfficeName}
                                    </Text>
                                    {/*<Box mt="20px">*/}
                                    {/*    <AddTimeSlotCore*/}
                                    {/*        loading={addSlotLoading}*/}
                                    {/*        listingId={listingId}*/}
                                    {/*        buttonTitle="Make An Open House"*/}
                                    {/*        openHouseTypesLoading={openHouseTypesLoading}*/}
                                    {/*        openHouseHostTypes={openHouseHostTypes}*/}
                                    {/*        openHouseAudiences={openHouseAudiences}*/}
                                    {/*        openHouseTypes={openHouseTypes}*/}
                                    {/*    />*/}
                                    {/*</Box>*/}
                                    {/* <CustomButton
                                        text="Make An Open House"
                                        onClick={() => setShowAddTimeSlotModal(true)}
                                        bgColor="brand"
                                        height="50px"
                                        isLoading={loading}
                                        mt="20px"
                                    /> */}
                                </Box>
                            </Flex>
                        )}
                    </>
                </ModalBody>
            </ModalContent>
            <SharingModal
                isOpen={isSharingModalOpen}
                onClose={() => setIsSharingModalOpen(false)}
            />
        </Modal>
    );
};
