import React, { useState, useEffect } from 'react';
import { MyRequestsType } from '../types';
import { IdNameTypes } from '../../../common/types';
import { RequestBox } from './RequestBox';
import { dispatch, RootStore } from '../../../store';
import { setIsMyRequestInfoModalOpen } from '../store/actions';
import { useSelector } from 'react-redux';
import { MyRequestInfoModal } from '../modals/MyRequestInfoModal/MyRequestInfoModal';
import { useHistory } from 'react-router-dom';

type RequestBoxTypeInd = {
    request: MyRequestsType;
    requestCategories: IdNameTypes[];
    allowCountAccept?: boolean 
};

export const RequestBoxIndependent: React.FC<RequestBoxTypeInd> = ({
    request,
    requestCategories,
    allowCountAccept,
}) => {
    const [isMyRequestInfoModalOpen, setisMyRequestInfoModalOpen] = useState<boolean>(false);
    const router = useHistory();

    return (
        <>
          <RequestBox 
              allowCountAccept={allowCountAccept}
              request={request}
              onOpenModal={() => {
                setisMyRequestInfoModalOpen(true);
              }}
              requestCategories={requestCategories}
          />
          {isMyRequestInfoModalOpen && (
            <MyRequestInfoModal
                isOpen={isMyRequestInfoModalOpen}
                onClose={() => {
                  setisMyRequestInfoModalOpen(false);
                    router.push(router.location.pathname);
                }}
                pendingRequestToShowId={request.id}
                onGetMyRequests={() => {
                  console.log('UPDATED')
                }}
            />
          )}
        </>
    );
};
