import React from 'react';
import { Box, Text, Center } from '@chakra-ui/react';
import { InfiniteScroll, Loader } from '../../../common/components/blocks';
import { AgentActivityItem } from '../components/AgentActivityItem';
import { ActivitiesResponseType, SelectedActivity } from '../types';
import { IdNameTypes } from '../../../common/types';

interface IJobTabProps {
    loading: boolean;
    activity: ActivitiesResponseType[];
    setActivityId: (value: SelectedActivity) => void;
    loadMoreItems: () => void;
    loadMoreLoading: boolean;
    requestCategories: Array<IdNameTypes>;
}

export const AgentActivityTab: React.FC<IJobTabProps> = ({
    loading,
    activity,
    setActivityId,
    loadMoreItems,
    loadMoreLoading,
    requestCategories,
}) => (
    <Box>
        {loading ? (
            <Loader spinnerSize="md" centerHeight="calc(90vh - 180px)" />
        ) : (
            <InfiniteScroll onReachBottom={loadMoreItems} height="calc(90vh - 180px)">
                {activity.length ? (
                    <>
                        {activity.map(elem => (
                            <AgentActivityItem
                                elem={elem}
                                key={elem.id}
                                {...elem}
                                onOpenModal={() =>
                                    setActivityId({ id: elem.id, isMyActivity: false })
                                }
                                requestCategories={requestCategories}
                            />
                        ))}
                    </>
                ) : (
                    <Center h="100%">
                        <Text color="brand" fontSize="16px">
                            There is no activity at this time
                        </Text>
                    </Center>
                )}
                {loadMoreLoading && <Loader spinnerSize="md" centerHeight="100px" />}
            </InfiniteScroll>
        )}
    </Box>
);
