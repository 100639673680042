import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Center, Text } from '@chakra-ui/react';
import { useLocalStorage } from '../../../../../helpers/hooks/useLocalStorage/useLocalStorage';

import { DashboardContentHeader } from '../DashboardContentHeader/DashboardContentHeader';
import { InfiniteScroll } from '../../../InfiniteScroll/InfiniteScroll';
import { Loader } from '../../../Loader/Loader';
import { OpportunityItem } from '../../items/OpportunityItem/OpportunityItem';
import { AvailableBoxItem } from '../../items/AssistanceItem/AvalaibleBoxItem';
import { SentRequestItem } from '../../items/AssistanceItem/SentRequestItem';
import { OpenHouseItem } from '../../items/OpenHouseItem/OpenHouseItem';

import { JobDetailsModal } from '../../../../../../pages/Opportunities/modals';
import { OpenhouseDetailsModal } from '../../../../../../pages/Listings/modals';
import {
    acceptRequest,
    getAgencyRequests,
    getRequest,
} from '../../../../../../pages/Requests/store/actions';
import {
    AgencyRequestInfoModal,
    AvailableRequestDetailsModal,
    MyRequestInfoModal,
} from '../../../../../../pages/Requests/modals';

import { dispatch, RootStore } from '../../../../../../store';
import {
    getDashboardRequest,
    setDashboardRequestPageNumber,
} from '../../../../../../store/actions/dashboard.actions';
import { getMyListings } from '../../../../../../store/actions/listings.actions';
import { setConnectPaymentModalOpen } from '../../../../../../pages/Notifications/store/actions';

import { DashboardType, FilterState } from '../../../../../types/dashboard.types';
import {
    IAvailableItemBox,
    IOpenHouseItem,
    IOpportunityItem,
    ISentRequestItem,
} from '../../items/dashboardItemsTypes';
import { DASHBOARD_REQUEST_PAGE_SIZE } from '../../../../../constants/dashboard.constants';
import { getForDays } from '../../../../../utils/getForDays';

interface IDashboardContainerProps {
    isAccessAllowed: boolean;
}
interface ModalOptionsProps {
    type: '' | 'jobModal' | 'openHouseModal' | 'agencyModalBox' | 'agencyModal' | 'myRequestModal';
    id: number;
    modalConfig?: any;
}

export const DashboardContent: React.FC<IDashboardContainerProps> = ({ isAccessAllowed }) => {
    const [onEndReachedCalled, setOnEndReachedCalled] = useState(false);
    const [modalOptions, setModalOptions] = useState<ModalOptionsProps>({ type: '', id: 0 });
    const requestInfo = useSelector((state: RootStore) => state.requests.requestInfo.value);

    const onGetRequest = (id: any) => {
        dispatch(getRequest(id))
    };


    const onSetDashboardRequestPageNumber = (page: number) =>
        dispatch(setDashboardRequestPageNumber(page));
    const requestInfoLoading = useSelector(
        (state: RootStore) => state.requests.requestInfo.loading,
    );

    const [filterState, setFilterState] = useLocalStorage<FilterState>('filterState', {
        typeIds: [],
        categoryIds: [],
        openHouseTypeIds: [],
        forDays: [],
        sortingType: 0,
    });

    const {
        items: dashboardRequest,
        loading: dashboardRequestLoading,
        loadMoreLoading: dashboardRequestMoreLoading,
        totalCount: dashboardRequestTotalCount,
        pageNumber: currentPage,
    } = useSelector((state: RootStore) => state.dashboard.dashboardRequest);

    const handleDashboardData = () => {
        dispatch(
            getDashboardRequest({
                pageNumber: currentPage,
                pageSize: DASHBOARD_REQUEST_PAGE_SIZE,
                isMine: false,
                ...filterState,
                forDays: getForDays(filterState.forDays),
            }),
        );
    };

    useEffect(() => {
        handleDashboardData();
    }, [currentPage, filterState]);

    const loadMoreItems = () => {
        if (dashboardRequest.length >= dashboardRequestTotalCount) {
            setOnEndReachedCalled(true);
        } else if (!onEndReachedCalled) {
            onSetDashboardRequestPageNumber(currentPage + 1);
        }
    };

    const handleAcceptRequest = (IsAccepted: boolean, RequestId: number) => {
        if (!isAccessAllowed) {
            // dispatch(setConnectPaymentModalOpen({ isOpen: true }));
            //return;
        }
        dispatch(
            acceptRequest({
                RequestId,
                IsAccepted,
                PageSize: 20,
                includeOtherAgent: true,
                categoryIds: [],
                date: '',
                dashboardRequestPayload: {
                    pageNumber: currentPage,
                    pageSize: 20,
                    isMine: false,
                    ...filterState,
                    forDays: getForDays(filterState.forDays),
                },
            }),
        );
        handleModalClose();
        location.reload();
    };

    const handleModalClose = () => {
        setModalOptions({ type: '', id: 0 });
    };

    const onGetAgencyRequests = (page?: number) => {
        dispatch(
            getAgencyRequests({
                pageNumber: page || 1,
                pageSize: 10,
                categoryIds: [],
                date: '',
            }),
        );
    };

    const renderDashboardItems = (elem: { value: DashboardType }, index: number) => {
        const type = elem.value?.categoryId || 3;
        switch (type) {
            case 1: {
                const title = 'Assistance';
                if (elem.value.isOtherAgent === true) {
                    return (
                        <AvailableBoxItem
                            key={index}
                            {...(elem.value as IAvailableItemBox)}
                            request={elem.value}
                            title={title}
                            onOpenModal={() =>
                                setModalOptions({
                                    type: 'agencyModalBox',
                                    id: elem.value.id,
                                })
                            }
                            handleAcceptRequest={handleAcceptRequest}
                            requestInfo={requestInfo}
                            onGetRequest={onGetRequest}
                        />
                    );
                } else {
                    return (
                        <SentRequestItem
                            key={index}
                            {...(elem.value as ISentRequestItem)}
                            title={title}
                            onOpenModal={() =>
                                setModalOptions({
                                    type: 'myRequestModal',
                                    id: elem.value.id,
                                })
                            }
                        />
                    );
                }
            }
            case 2: {
                const title = 'Opportunity';
                const type = elem.value.isOtherAgent ? 'jobModal' : 'myRequestModal';
                return (
                    <OpportunityItem
                        title={title}
                        key={index}
                        {...(elem.value as IOpportunityItem)}
                        onOpenModal={() =>
                            setModalOptions({
                                type,
                                id: elem.value.id as number,
                            })
                        }
                    />
                );
            }
            case 3: {
                const title = 'Open House';
                const type = elem.value.isOtherAgent ? 'openHouseModal' : 'myRequestModal';
                const id = elem.value.isOtherAgent
                    ? elem.value.id
                    : elem.value.timeSlot?.assistRequestId;
                return (
                    <OpenHouseItem
                        title={title}
                        key={index}
                        {...(elem.value as IOpenHouseItem)}
                        onOpenModal={() =>
                            setModalOptions({
                                type,
                                id: id || 0,
                                modalConfig: {
                                    isOpen: true,
                                },
                            })
                        }
                    />
                );
            }
            default:
                return null;
        }
    };

    return (
        <>
            <DashboardContentHeader
                setFilterState={setFilterState}
                sortingType={filterState.sortingType}
                filterState={filterState}
            />
            {dashboardRequestLoading ? (
                <Loader spinnerSize="md" centerHeight="calc(90vh - 180px)" />
            ) : (
                <InfiniteScroll
                    height="100vh"
                    boxProps={{ pt: '0px' }}
                    onReachBottom={loadMoreItems}
                >
                    {dashboardRequest.length ? (
                        <>{dashboardRequest.map(renderDashboardItems)}</>
                    ) : (
                        <Center h="100%">
                            <Text color="brand" fontSize="16px" textAlign="center">
                                There are no coverage needs from the agents at your brokerage at
                                this moment. Please check back again later. When there is an agent
                                need, you will be able to view it right here.
                            </Text>
                        </Center>
                    )}
                    {dashboardRequestMoreLoading && (
                        <Loader spinnerSize="md" centerHeight="100px" />
                    )}
                </InfiniteScroll>
            )}
            <JobDetailsModal
                isOpen={modalOptions.type === 'jobModal'}
                onClose={handleModalClose}
                jobId={modalOptions.id}
                dashboardRequestPayload={{
                    pageNumber: currentPage,
                    pageSize: 20,
                    isMine: false,
                    ...filterState,
                    forDays: getForDays(filterState.forDays),
                }}
            />
            <OpenhouseDetailsModal
                showOpenhouseInfoModal={
                    modalOptions.type === 'openHouseModal' && modalOptions.modalConfig
                }
                setShowAddTimeSlotModal={() => {}}
                onClose={handleModalClose}
                listingId={modalOptions.id}
                getItems={() => dispatch(getMyListings())}
                dashboardRequestPayload={{
                    pageNumber: currentPage,
                    pageSize: 20,
                    isMine: false,
                    ...filterState,
                    forDays: getForDays(filterState.forDays),
                }}
            />
            <AvailableRequestDetailsModal
                isOpen={modalOptions.type === 'agencyModalBox'}
                onClose={handleModalClose}
                requestInfo={requestInfo}
                requestInfoLoading={requestInfoLoading}
                handleAcceptRequest={handleAcceptRequest}
            />
            <AgencyRequestInfoModal
                isOpen={modalOptions.type === 'agencyModal'}
                sentRequestToShowId={modalOptions.id}
                onClose={handleModalClose}
                onGetAgencyRequests={onGetAgencyRequests}
            />
            <MyRequestInfoModal
                isOpen={modalOptions.type === 'myRequestModal'}
                pendingRequestToShowId={modalOptions.id}
                onClose={handleModalClose}
                dashboardRequestPayload={{
                    pageNumber: currentPage,
                    pageSize: 20,
                    isMine: false,
                    ...filterState,
                    forDays: getForDays(filterState.forDays),
                }}
            />
        </>
    );
};
