import React from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { SentRequestItem, AvailableBox } from '../components';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { InfiniteScroll, Loader } from '../../../common/components/blocks';
import { SentRequestType, MyRequestsType, RequestInfoType } from '../types';

interface IAgencyRequestsTabProps {
    loadMoreAgencyRequests: () => void;
    setSentRequestToShowId: (value: number) => void;
    loadMoreLoading: boolean;
    handleAcceptRequest: (IsAccepted: boolean, RequestId: number) => void;
    handleOpenAvailableModal: (value: number) => void;
    requestInfo: RequestInfoType;
    onGetRequest: (id: number) => void;
}

export const AgencyRequestsTab: React.FC<IAgencyRequestsTabProps> = ({
    loadMoreAgencyRequests,
    setSentRequestToShowId,
    loadMoreLoading,
    handleAcceptRequest,
    handleOpenAvailableModal,
    requestInfo,
    onGetRequest,
}) => {
    const { loading, items } = useSelector((state: RootStore) => state.requests.agencyRequests);
    return (
        <Box className="AGENCY_REQUEST_TAB">
            {loading ? (
                <Loader spinnerSize="md" centerHeight="calc(90vh - 180px)" />
            ) : (
                <InfiniteScroll height="calc(90vh - 180px)" onReachBottom={loadMoreAgencyRequests}>
                    {items.length ? (
                        <>
                                {items.map((elem: SentRequestType | MyRequestsType) => {
                                if ('isOtherAgent' in elem) {
                                    return (
                                        <AvailableBox
                                            key={elem.id}
                                            request={elem}
                                            handleOpenAvailableModal={handleOpenAvailableModal}
                                            handleAcceptRequest={handleAcceptRequest}
                                            requestInfo={requestInfo}
                                            onGetRequest={onGetRequest}
                                        />
                                    );
                                } else {
                                    return (
                                        <SentRequestItem
                                            elem={elem}
                                            key={elem.id}
                                            {...elem}
                                            onOpenModal={value => setSentRequestToShowId(value)}
                                        />
                                    );
                                }
                            })}
                        </>
                    ) : (
                        <Center h="100%">
                            <Text color="brand" fontSize="16px">
                                There is no agency requests at this time
                            </Text>
                        </Center>
                    )}
                    {loadMoreLoading && <Loader spinnerSize="md" centerHeight="100px" />}
                </InfiniteScroll>
            )}
        </Box>
    );
};
