import { call, put, takeEvery } from 'redux-saga/effects';

import Api from '../../../common/api/api.base';
import { IGetHelpTypesSuccessResponse, IResponseWithCustomValue } from '../../../common/types';
import {
  getHelpTypes,
  getHelpTypesError,
  getHelpTypesSuccess,
} from '../../actions/directories.actions';

function* workerGetHelpTypes() {
  try {
    const result: IResponseWithCustomValue<IGetHelpTypesSuccessResponse> = yield call(
      Api.get,
      '/api/directories/help-types',
      {},
    );
    if (result.success) {
      yield put(getHelpTypesSuccess(result));
    } else {
      yield put(getHelpTypesError(result.errors));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchGetHelpTypesDirectorySaga() {
  yield takeEvery(getHelpTypes.type, workerGetHelpTypes);
}