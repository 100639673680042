import { Button, Flex, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { verify } from 'crypto';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { VerifyAuthModal } from '../../../../common/components/aglobal/VerifyAuthModal';
import { CustomInput } from '../../../../common/components/blocks';
import { dispatch, RootStore } from '../../../../store';
import { getUserInfoRequest } from '../../../../store/actions/user.actions';
import { RequestWithdrawResolver } from './RequestWithdrawResolver';

interface IFormChangeBalance {}

type RequestFormValues = {
    amount: number;
    company_name: string;
    account_number: string;
};

export const FormWithdraw: React.FC<IFormChangeBalance> = () => {
    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
        control,
        clearErrors,
        watch,
        reset,
        trigger,
        getValues,
    } = useForm<RequestFormValues>({
        resolver: RequestWithdrawResolver,
    });

    const [loader, setLoader] = useState<boolean>(false);
    const [openVerifyModal, setopenVerifyModal] = useState<boolean>(false);

    const toast = useToast();
    const { user: currentUser } = useSelector((state: RootStore) => state.user);

    const onSubmit = async (data: any) => {
      setLoader(true);
      await axios.post(window.baseUrl+'/api/withdraw/create', data)
        .then(() => {
          dispatch(getUserInfoRequest());
          setLoader(false);
          //reset();
          toast({
            title: 'Form submitted successfully',
            status: 'info',
            isClosable: true,
          });

        }).catch((error: any) => {
          setLoader(false);
          let status = error.response.status;

          if(status === 423){
            setopenVerifyModal(true);
          }

          if(status === 422){
            toast({
              title: error.response.data.error,
              status: 'error',
              isClosable: true,
            });
            return;
          }

        });
    };

    useEffect(() => {
      if(currentUser.userWithdrawProfile){
        let wp = currentUser.userWithdrawProfile;
        if(wp.company){
          setValue('company_name', wp.company);
        }
        if(wp.account){
          setValue('account_number', wp.account);
        }
      }
    }, [currentUser]);

    const toggleVerifyModal = () => {
      setopenVerifyModal(!openVerifyModal);
    };

    const afterUpdateVerify = (code: string) => {
      console.log('CODE!!!!!!', code);

      let vals = getValues();
      let data = {...vals, check_code: code};
      onSubmit(data);
    };

    return (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
          <CustomInput
                label="Company name: *"
                placeholder=""
                width="100%"
                register={{ ...register('company_name') }}
                errors={errors.company_name}
            />
            <br />
            <CustomInput
                label="Bank account number: *"
                placeholder=""
                width="100%"
                register={{ ...register('account_number') }}
                errors={errors.account_number}
            />
            <br />
            <CustomInput
                label="Enter Amount: *"
                placeholder="0"
                width="100%"
                register={{ ...register('amount') }}
                errors={errors.amount}
            />
            <br />
            <Button
              isFullWidth
              gridColumnStart="2"
              variant={'primary'}
              boxShadow="0px 3px 3px grey"
              isLoading={loader}
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              >
              Send form
            </Button>
            <br/>
          </form>
          <VerifyAuthModal
            isOpen={openVerifyModal}
            onClose={toggleVerifyModal}
            emitSubmited={afterUpdateVerify}
          />
        </>
    );
};
