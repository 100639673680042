import { createSlice } from '@reduxjs/toolkit';
import {
    toggleLandingVideoModal,
    sendCooperationDetailRequest,
    sendCooperationDetailSuccess,
    sendCooperationDetailError,
    sendContactForm,
    sendContactFormSuccess,
    sendContactFormError,
    sendHelpRequest,
    sendHelpRequestSuccess,
    sendHelpRequestError,
} from '../actions/landing.actions';
import { PayloadError } from '../../common/types';

type InitialState = {
    videoModalOpen: boolean;
    sendingDetailsLoading: boolean;
    sendingContactLoading: boolean;
    helpRequest: {
        errors: PayloadError[],
        loading: boolean
    }
};

const initialState: InitialState = {
    videoModalOpen: false,
    sendingDetailsLoading: false,
    sendingContactLoading: false,
    helpRequest: {
        errors: [],
        loading: false
    }
};

const landingSlice = createSlice({
    name: 'landing',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(toggleLandingVideoModal, (state, action) => {
                state.videoModalOpen = action.payload;
            })
            .addCase(sendCooperationDetailRequest, state => {
                state.sendingDetailsLoading = true;
            })
            .addCase(sendCooperationDetailSuccess, state => {
                state.sendingDetailsLoading = false;
            })
            .addCase(sendCooperationDetailError, state => {
                state.sendingDetailsLoading = false;
            })
            .addCase(sendContactForm, state => {
                state.sendingContactLoading = true;
            })
            .addCase(sendContactFormSuccess, state => {
                state.sendingContactLoading = false;
            })
            .addCase(sendContactFormError, state => {
                state.sendingContactLoading = false;
            })
            .addCase(sendHelpRequest, state => {
                state.helpRequest.loading = true;
            })
            .addCase(sendHelpRequestSuccess, state => {
                state.helpRequest.loading = false;
            })
            .addCase(sendHelpRequestError, (state, action) => {
                state.helpRequest.errors = action.payload;
            })
    },
});

export default landingSlice.reducer;
