import React, { useState } from 'react';
import { SubmitHandler, Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Countdown from 'react-countdown';

import {
    Box,
    Text,
    Flex,
    HStack,
    PinInput,
    PinInputField,
    Checkbox,
    IconButton,
		useToast,
} from '@chakra-ui/react';
import { ISignUpProps, SignUpData } from '../SignUp/types';
import { CustomButton, CustomInput } from '../../../common/components';
import { BackArrow, EmailIcon } from '../../../assets/icons';
import axios from 'axios';
import { FormChangePassword } from './FormChangePassword';
import { FormSendPhoneCode } from './FormSendPhoneCode';
import { FormConfirmCheck } from './FormConfirmCheck';
import { AuthCardPanel } from '../../../common/components/aglobal/AuthCardPanel';

export interface IcheckEmail {
    email?: string;
}

export type IFormdata = {
    code: string;
};

export type IUserRequest = {
	id?: number,
	first_name: string;
	last_name: string;
	email: string;
	phone_number: string;
};

export const EmailCodeForm: React.FC<IcheckEmail> = ({}) => {
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
        watch,
        reset,
    } = useForm<IFormdata>({});

		const history = useHistory();
		const toast = useToast();

		const [step, setStep] = useState<number>(0);

		const [user, setUser] = useState<IUserRequest>({
				first_name: '',
				last_name: '',
				email: '',
				phone_number: '',
		});

		const [wasSend, setwasSend] = useState<boolean>(false);
		const [codeConfirmed, setcodeConfirmed] = useState<boolean>(false);
		const [loading, setloading] = useState<boolean>(false);
		const [code, setCode] = useState<string>('');

    const search = useLocation().search;
    let email = new URLSearchParams(search).get("email");
		email = email? email : '';

    const onSubmit = () => {};

    const checkCode = (payload: IFormdata) => {
			setloading(true);
			axios.post(window.baseUrl+'/api/auth/checkCodeEmail', {
				email: email,
				code: payload.code
			})
			.then((response: any) => {
				setCode(payload.code);
				setloading(false);
				setStep(1);
				setUser(response.data.user);
			}).catch(() => {
				setloading(false);
				toast({
					title: 'The code is not correct',
					status: 'error',
					isClosable: true,
				});
			});
		};

    const sendCodeEmail = () => {
			setloading(true);
			axios.post(window.baseUrl+'/api/auth/sendCodeEmail', {
				email: email
			})
			.then((response: any) => {
				setwasSend(true);
				setloading(false);
			});
		};

		//email = "withindestruction2@gmail.com";
		/*
		const user = {
			id: '174',
			first_name: 'test FN',
			last_name: 'test LN',
			email: 'withindestruction2@gmail.com',
			phone_number: '222-222-2222',
			state_id: '1',
			agency_id: 294867,
			licence: 'license',
			mls_id: 'mls_id',
		};
		*/

    return (
				<>
				{(step === 3) && (
					{/*
					<FormConfirmCheck
						user={user}
						code={code}
						email={email}
					/>
					*/}
				)}
				{(step === 2) && (
					<FormSendPhoneCode
						code={code}
						email={email}
						user={user}
						clickBack={() => {
							setStep(1);
						}}
						isUpdates={() => {
							console.log('ALL WILL BE DONE!!!!!!!!!!')
							//setStep(3);
						}}
					/>
				)}
				{(step === 1) && (
					<FormChangePassword 
						code={code}
						email={email}
						clickBack={() => {
							setStep(0);
						}}
						isUpdates={() => {
							setStep(2);
						}}
					/>
				)}
				{(step === 0) && (
        <form className="fixed_form" onSubmit={handleSubmit(checkCode)}>
            <Box
                borderRadius={20}
                backgroundColor="#FFFFFF"
                maxW="100%"
                position="relative"
                p="60px"
                className="fixed_m_box"
                >
                <AuthCardPanel>
                <Flex
                    position="relative"
                    h="100%"
                    flexDirection="column"
                    justifyContent="space-around"
                    >
                    <IconButton
                        aria-label="Back"
                        variant="unstyled"
                        position="absolute"
                        top={{ base: '38px', lg: '3px' }}
                        left={{ base: '-10px', lg: '-3px' }}
                        d="flex"
                        alignItems="center"
                        onClick={() => {
													setwasSend(false);
													setcodeConfirmed(false);
													history.push('/auth/check');
												}}
                    >
                        <BackArrow />
                    </IconButton>
                    <Text
                        fontWeight={900}
                        lineHeight="140%"
                        fontSize={{ base: '18px', lg: '32px' }}
                        color="brand"
                        mt={{ base: '35px', lg: '50px' }}
                        ml={{ base: '50px', md: 0 }}
                    >
                        Enter Code We Sent To Your Email
                    </Text>

                    <CustomInput
                        disabled={true}
                        value={email}
                        label="Email*"
                        width="100%"
                        leftIcon={<EmailIcon />}
                        placeholder="Email"
                        margin="26px 0 0 0"
                        isBorder={false}
                        fontSize="14px"
                        textSize="16px"
                        height="50px"
                    />

										{/*
                    <Box
                        pt="22px"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        <Text
                            color="brand"
                            fontWeight={300}
                            fontSize="14px"
                            lineHeight="18px"
                        ></Text>
                        <Text color="green" fontWeight={300} fontSize="14px" lineHeight="18px">
                            <Countdown
                                date={Date.now() + 5 * 60 * 1000}
                                renderer={(props: any) => {
                                    const timerValue = `${props.formatted.minutes}:${props.formatted.seconds}`;
                                    return <Text>{timerValue}</Text>;
                                }}
                                onComplete={() => {}}
                            />
                        </Text>
                    </Box>
										*/}

										{wasSend && (
                    <Box mt="16px">
                        <Controller
                            control={control}
                            name="code"
                            render={({ field: { ref, ...restField } }) => (
                                <HStack
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                >
                                    <PinInput placeholder="-" autoFocus={true} {...restField}>
                                        <PinInputField
                                            w="65px"
                                            h="65px"
                                            borderRadius="15px"
                                            ref={ref}
                                        />
                                        <PinInputField w="65px" h="65px" borderRadius="15px" />
                                        <PinInputField w="65px" h="65px" borderRadius="15px" />
                                        <PinInputField w="65px" h="65px" borderRadius="15px" />
                                    </PinInput>
                                </HStack>
                            )}
                        />
                    </Box>
										)}

                    <Box mt="24px !important">
                        <CustomButton
                            type="submit"
														isLoading={loading}
                            loadingText="Signing up..."
                            text={wasSend? 'Check' : 'Send code'}
                            width="100%"
                            height="60px"
                            bgColor="brand"
                            boxShadow="0px 3px 3px grey"
														onClick={(e) => {
															if(!wasSend){
																e.preventDefault();
																sendCodeEmail();
															}
														}}
                        />
                    </Box>
                </Flex>
                </AuthCardPanel>
            </Box>
        </form>)}
				</>
    )
};
