import React from 'react';
import { ListingsType } from '../../../../types';
import { ListingItem } from '../../../../../pages/Listings/components';
import { InfiniteScroll, Loader } from '../../../../components';
import { Box, Text } from '@chakra-ui/react';

interface IListingsTabProps {
    loading: boolean;
    items: ListingsType[];
    setToShowAgentListing: (value: number) => void;
}

export const ListingsTab: React.FC<IListingsTabProps> = ({
    items,
    loading,
    setToShowAgentListing,
}) => (
    <Box h="100%">
        {loading ? (
            <Loader centerHeight="100%" />
        ) : (
            <InfiniteScroll>
                {!items.length && (
                  <div className="no_found">
                    Results not found
                  </div>
                )}
                {items.map(elem => (
                    <ListingItem
                        key={elem.id}
                        {...elem}
                        isOpenHouse={true}
                        onOpenModal={() => setToShowAgentListing(elem.id)}
                    />
                ))}
            </InfiniteScroll>
        )}
    </Box>
);
