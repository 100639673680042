import { Container, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { DashboardHeader } from '../../common/components/blocks/DashboardWrapper/DashboardHeader';
import { DashboardWrapper } from '../../common/components/blocks/DashboardWrapper/DashboardWrapper';
import SidebarContainer from '../Sidebar/Sidebar.container';
import { DasboardContent } from './DasboardContent';
import { MarkerContent } from './MarkerContent';

export const MarketContainerService: React.FC = () => {

    return (
        <Flex backgroundColor="brand" height="auto" flexDirection="column" alignItems="center">
            <DashboardHeader />
            <Container
                maxW="container.xl"
                bgColor="transparent"
                maxH="unset"
                overflowY="auto"
                mt="0px"
                mb="0px"
                borderRadius="0px"
                p="0px"
            >
                <MarkerContent />
            </Container>
        </Flex>
    );
    
    return (
        <DashboardWrapper
        paddingBottom="20px"
        paddingTop="20px"
        paddingLeft="20px"
        paddingRight="20px"
        sidebar={<SidebarContainer isAccessAllowed />}
        content={<MarkerContent />}
        />
    );
};
