import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MyRequestsType } from '../../Requests/types';
import { AvailableBoxIndependent } from '../../Requests/components/AvailableBoxIndependent';
import { RequestBox } from '../../Requests/components/RequestBox';
import { setIsMyRequestInfoModalOpen } from '../../Requests/store/actions';
import { dispatch } from '../../../store';
import { RequestBoxIndependent } from '../../Requests/components/RequestBoxIndependent';
import { PostTypeResponse } from '../../Updates/types';
import { ItemRowPost } from '../../Updates/components/ItemRowPost';
import { Link } from 'react-router-dom';

export const HomeBlasts = () => {
    const [loader, setLoader] = useState<Boolean>(false);
    const [itemsPosts, setItemsPosts] = useState<PostTypeResponse[]>([]);
    const [itemsPostsEmpty, setitemsPostsEmpty] = useState<boolean>(false);

    useEffect(() => {
      setTimeout(() => {
        getBlasts();
      }, 700);
    }, []);
  
    const getBlasts = () => {
      setLoader(true);
      axios.get(window.baseUrl+'/api/posts/get-posts')
      .then((response) => {
        if(!response.data.value.posts.length){
          setitemsPostsEmpty(true);
        }else{
          setitemsPostsEmpty(false);
        }

        setItemsPosts(response.data.value.posts);
        setLoader(false);
      });
    };

    return (
      <div className="HOME_BLASTS">
        <div className="title">
          Blasts
        </div>
        <div className="items_container">
          {itemsPostsEmpty && (
            <div className="empty_grid">
              No records found 
            </div>
          )}
          {itemsPosts.map((elem: PostTypeResponse) => (
            <ItemRowPost 
              key={elem.id}
              setReportPostId={() => {}}
              setReportTypeId={() => {}}
              setDetailsPostId={() => {}}
              setEditPostId={() => {}}
              item={elem}
              onAddReport={() => {}}
              onDeletePost={() => {}}
            />
          ))}
        </div>
        <div className="bottom_panel border_tb">
          <Link to="/updates">View all blasts</Link>
        </div>
      </div>
    );
};
