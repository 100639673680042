import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FindAssistance } from './FindAssistance';

import { RootStore } from '../../store';
import { getCurrentStates } from '../../store/actions/order.actions';
import { getStates } from '../../store/actions/directories.actions';
import { findAssistanceRequest } from './store/actions';
import { setConnectPaymentModalOpen } from '../Notifications/store/actions';

import { FindAssistancePayloadType } from './types';
import { FilterState } from '../../common/types/dashboard.types';

import useLocalStorage from '../../common/helpers/hooks/useLocalStorage/useLocalStorage';
import { getForDays } from '../../common/utils/getForDays';
import { DASHBOARD_REQUEST_PAGE_SIZE } from '../../common/constants/dashboard.constants';

export const FindAssistanceContainer: React.FC = () => {
    const dispatch = useDispatch();
    const { states, assistanceLoading } = useSelector((state: RootStore) => state.order);
    const roles = useSelector((state: RootStore) => state.auth.user.roles);
    const authUser = useSelector((state: RootStore) => state.auth.user);

    const isAccessAllowed = roles?.some((role: string) => (role === 'user' || role === 'newuser'));

    console.log('11112222222', states);

    const [filterState] = useLocalStorage<FilterState>('filterState', {
        typeIds: [],
        categoryIds: [],
        openHouseTypeIds: [],
        forDays: [],
        sortingType: 0,
    });

    const { pageNumber: currentPage } = useSelector(
        (state: RootStore) => state.dashboard.dashboardRequest,
    );
    
    const onFindAssistance = (data: FindAssistancePayloadType) => {
        if (!isAccessAllowed) {
            // dispatch(setConnectPaymentModalOpen({ isOpen: true }));
            //return;
        }
        
        dispatch(
            findAssistanceRequest({
                data,
                dashboardRequestPayload: {
                    ...filterState,
                    forDays: getForDays(filterState.forDays),
                    pageNumber: currentPage,
                    pageSize: DASHBOARD_REQUEST_PAGE_SIZE,
                    isMine: false,
                },
            }),
        );
    };

    useEffect(() => {
        dispatch(getCurrentStates());
        dispatch(getStates());
    }, []);

    return (
        <div className="find_assistance_form_outer">
            <FindAssistance
                states={states}
                onFindAssistance={onFindAssistance}
                assistanceLoading={assistanceLoading}
            />
        </div>
    );
};
