import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { CustomButton, Loader } from '..';
import { EnterCodeForEditModal } from '../../../pages/Settings/components/EnterCodeForEditModal';
import { AddPaymentModal } from './AddPaymentModal';
import './PaymentsMethodsList.scss';

import { IPaymentMethod } from '../../types/api.types';
import { DeleteIconRed } from '../../../assets/icons';

interface IPropInterface {
  payments: IPaymentMethod[],
  emitChanges?: () => void;
}


export const PaymentsMethodsList: React.FC<IPropInterface> = ({payments, emitChanges}) => 
{
  const [loader, setLoader] = useState<Boolean>(false);

  const setPaymentDefault = (paymentId: number) => {
    setLoader(true);
    axios.post(window.baseUrl+'/api/set_default_payment_method/'+paymentId)
    .then(() => {
      setLoader(false);
      if(emitChanges){
        emitChanges();
      }
    });
  };

  const deletePayment = (paymentId: number) => {
    setLoader(true);
    axios.post(window.baseUrl+'/api/delete_payment_method/'+paymentId)
    .then(() => {
      setLoader(false);
      if(emitChanges){
        emitChanges();
      }
    });
  };

	return (
		<div className="paymentListMethods_outer">
      {payments.map(payment => (
        <div key={payment.id} className={ "payment_row " + ( payment.is_default? "is_default" : "") }>
          <div className="left">
            
            <span className="round_c">

            </span>
            {payment.is_default? (<span className="text_default">Is default</span>) : (
              loader? (<Loader spinnerSize={'sm'} centerHeight={'100%'} />) :
              <span className="make_default" onClick={() => {setPaymentDefault(payment.id)}}>Make default</span>

            )}
          </div>
          <div className="right">
            {payment.card_brand} {payment.card_number}
            
            <div className="del_icon_p" onClick={() => {
                deletePayment(payment.id);
              }}>
              {loader? (<Loader spinnerSize={'sm'} centerHeight={'100%'} />) : (
                <DeleteIconRed />
              )}
            </div>
          </div>
        </div>
      ))}
		</div>
	);
};

