import React from 'react';
import { Box, Flex, IconButton, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';
import { colors } from '../../../common/constants';
import { CloseButton } from '../../../assets/icons';

interface ICustomModalProps {
    isOpen: boolean;
    title: string;
    size: string;
    onClose: () => void;
    isCentered?: boolean;
}

export const CustomModalWrapper: React.FC<ICustomModalProps> = ({
    size,
    isOpen,
    title,
    onClose,
    children,
    isCentered = false,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={size} isCentered={isCentered}>
            <ModalOverlay />
            <ModalContent overflow={'hidden'}>
                <Flex
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    px={'44px'}
                    pt={'40px'}
                >
                    <Text color={colors.brand} fontWeight={500} fontSize="20px" lineHeight="23px" marginLeft='24px'>
                        {title}
                    </Text>

                    <IconButton
                        onClick={onClose}
                        aria-label={'close modal'}
                        icon={<CloseButton />}
                        variant="ghost"
                    />
                </Flex>

                <Box px={'44px'} pb={'56px'}>
                    {children}
                </Box>
            </ModalContent>
        </Modal>
    );
};
