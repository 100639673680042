import { createSlice } from '@reduxjs/toolkit';
import { PayloadError } from '../../../common/types';
import { LicenseType, LicenseDraftType } from '../types';
import {
    addCalendarIdRequest,
    addCalendarIdSuccess,
    addCalendarRequest,
    addCalendarSuccess,
    changeEmailRequest,
    changeEmailSuccess,
    changeFullNameRequest,
    changeFullNameSuccess,
    changeLicensePhotoRequest,
    changeLicensePhotoSuccess,
    changePasswordRequest,
    changePasswordSuccess,
    changeUserAvatarRequest,
    changeUserAvatarSuccess,
    clearFields,
    editRateRequest,
    editRateSuccess,
    getCalendarRequest,
    getCalendarSuccess,
    getGoogleCalendarRequest,
    getGoogleCalendarSuccess,
    getLicenesDraftSuccess,
    getLicenseDraftRequest,
    getLicensesRequest,
    getLicenseSuccess,
    getRatesRequest,
    getRatesSuccess,
    sendCodeToEmailRequest,
    sendCodeToEmailSuccess,
    updatePhoneNumberRequest,
    updatePhoneNumberSuccess,
    verifyEmailCodeRequest,
    verifyEmailCodeSuccess,
    verifyPhoneNumberError,
    verifyEmailRequest,
    verifyEmailSuccess,
    verifyEmailError,
    verifyPhoneNumberRequest,
    verifyPhoneNumberSuccess,
    addLicenseRequest,
    addLicenseSuccess,
    addLicenseError,
    saveLicenseDraftRequest,
    saveLicenseDraftSuccess,
    saveLicenseDraftError,
    getAvailableRequests,
    getAvailableRequestsSuccess,
    getAvailableRequestsError,
    setAvailableRequests,
    setAvailableRequestsSuccess,
    setAvailableRequestsError,
    getNotificationSettingsRequest,
    getNotificationSettingsRequestSuccess,
    getNotificationSettingsRequestError,
    archiveCurrentUserSuccess,
    archiveCurrentUser,
    archiveCurrentUserError,
    switchIsArchiveCurrentUserModalOpen,
    renewLicenseRequest,
    renewLicenseSuccess,
    renewLicenseError,
} from './actions';

type InitialState = {
    errors: PayloadError[];
    loading: boolean;
    isCodeSent: boolean;
    isCodeVerified: boolean;
    isFieldChanged: boolean;
    isPhotoChanged: boolean;
    rates: any;
    isRateChanged: boolean;
    googleCalendar: string | null;
    isCalendarChanged: boolean;
    workHours: any;
    licenses: LicenseType[];
    draftLicense: LicenseDraftType | null;
    verifyEmail: {
        loading: boolean;
        errors: PayloadError[];
    };
    verifyPhoneNumber: {
        loading: boolean;
        errors: PayloadError[];
    };
    addLicense: {
        loading: boolean;
        errors: PayloadError[];
    };
    renewLicense: {
        loading: boolean;
        errors: PayloadError[];
    };
    saveDraft: {
        loading: boolean;
        errors: PayloadError[];
    };
    availableRequests: {
        loading: boolean;
        requestTypes: number[];
        errors: PayloadError[];
    };
    availableCases: {
        loading: boolean;
        cases: number[];
        errors: PayloadError[];
    };
    archiveCurrentUser: {
        errors: PayloadError[];
        loading: boolean;
        isModalOpened: boolean;
    };
};

export const initialState: InitialState = {
    errors: [],
    loading: false,
    isCodeSent: false,
    isCodeVerified: false,
    isFieldChanged: false,
    isPhotoChanged: false,
    rates: [],
    isRateChanged: false,
    googleCalendar: null,
    isCalendarChanged: false,
    workHours: [],
    licenses: [],
    draftLicense: null,
    verifyPhoneNumber: {
        errors: [],
        loading: false,
    },
    verifyEmail: {
        errors: [],
        loading: false,
    },
    addLicense: {
        errors: [],
        loading: false,
    },
    renewLicense: {
        errors: [],
        loading: false,
    },
    saveDraft: {
        errors: [],
        loading: false,
    },
    availableRequests: {
        errors: [],
        loading: false,
        requestTypes: [],
    },
    availableCases: {
        loading: false,
        cases: [],
        errors: [],
    },
    archiveCurrentUser: {
        errors: [],
        loading: false,
        isModalOpened: false,
    },
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(sendCodeToEmailRequest, state => {
                state.errors = [];
                state.loading = true;
                state.isCodeSent = false;
                state.isCodeVerified = false;
                state.isFieldChanged = false;
            })
            .addCase(sendCodeToEmailSuccess, state => {
                state.errors = [];
                state.loading = false;
                state.isCodeSent = true;
                state.isCodeVerified = false;
                state.isFieldChanged = false;
            })
            .addCase(verifyEmailCodeRequest, state => {
                state.errors = [];
                state.loading = true;
                state.isCodeSent = false;
                state.isCodeVerified = false;
                state.isFieldChanged = false;
            })
            .addCase(verifyEmailCodeSuccess, state => {
                state.errors = [];
                state.loading = false;
                state.isCodeSent = false;
                state.isCodeVerified = true;
                state.isFieldChanged = false;
            })
            .addCase(updatePhoneNumberRequest, state => {
                state.errors = [];
                state.loading = true;
                state.isCodeSent = false;
                state.isCodeVerified = false;
                state.isFieldChanged = false;
            })
            .addCase(updatePhoneNumberSuccess, state => {
                state.errors = [];
                state.loading = false;
                state.isCodeSent = true;
                state.isCodeVerified = false;
                state.isFieldChanged = false;
            })
            .addCase(verifyPhoneNumberRequest, state => {
                state.verifyPhoneNumber.errors = [];
                state.verifyPhoneNumber.loading = true;
                state.isCodeSent = false;
                state.isCodeVerified = false;
                state.isFieldChanged = false;
            })
            .addCase(verifyPhoneNumberSuccess, state => {
                state.verifyPhoneNumber.loading = false;
                state.isCodeSent = false;
                state.isCodeVerified = true;
                state.isFieldChanged = false;
            })
            .addCase(verifyPhoneNumberError, (state, action) => {
                state.verifyPhoneNumber.errors = action.payload;
                state.verifyPhoneNumber.loading = false;
                state.isCodeVerified = false;
            })
            .addCase(changeEmailRequest, state => {
                state.errors = [];
                state.loading = true;
                state.isCodeSent = false;
                state.isCodeVerified = false;
                state.isFieldChanged = false;
            })
            .addCase(changeEmailSuccess, state => {
                state.errors = [];
                state.loading = false;
                state.isCodeSent = true;
                state.isCodeVerified = false;
                state.isFieldChanged = false;
            })
            .addCase(verifyEmailRequest, state => {
                // state.errors = [];
                // state.loading = true;
                state.verifyEmail.loading = true;
                state.verifyEmail.errors = [];
                state.isCodeSent = false;
                state.isCodeVerified = false;
                state.isFieldChanged = false;
            })
            .addCase(verifyEmailSuccess, state => {
                // state.errors = [];
                // state.loading = false;
                state.verifyEmail.loading = false;
                state.isCodeSent = false;
                state.isCodeVerified = true;
                state.isFieldChanged = false;
            })
            .addCase(verifyEmailError, (state, action) => {
                state.verifyEmail.errors = action.payload;
                state.verifyEmail.loading = false;
                state.isCodeVerified = false;
            })
            .addCase(changePasswordRequest, state => {
                state.errors = [];
                state.loading = true;
                state.isCodeSent = false;
                state.isCodeVerified = false;
                state.isFieldChanged = false;
            })
            .addCase(changePasswordSuccess, state => {
                state.errors = [];
                state.loading = false;
                state.isCodeSent = false;
                state.isCodeVerified = false;
                state.isFieldChanged = true;
            })
            .addCase(changeFullNameRequest, state => {
                state.errors = [];
                state.loading = true;
                state.isCodeSent = false;
                state.isCodeVerified = false;
                state.isFieldChanged = false;
            })
            .addCase(changeFullNameSuccess, state => {
                state.errors = [];
                state.loading = false;
                state.isCodeSent = false;
                state.isCodeVerified = false;
                state.isFieldChanged = true;
            })
            .addCase(changeLicensePhotoRequest, state => {
                state.errors = [];
                state.loading = true;
                state.isCodeSent = false;
                state.isCodeVerified = false;
                state.isFieldChanged = false;
            })
            .addCase(changeLicensePhotoSuccess, state => {
                state.errors = [];
                state.loading = false;
                state.isCodeSent = false;
                state.isCodeVerified = false;
                state.isFieldChanged = true;
            })
            .addCase(clearFields, state => {
                state.errors = [];
                state.loading = false;
                state.isCodeSent = false;
                state.isCodeVerified = false;
                state.isFieldChanged = false;
                state.isRateChanged = false;
            })
            .addCase(changeUserAvatarRequest, state => {
                state.errors = [];
                state.loading = false;
                state.isCodeSent = false;
                state.isCodeVerified = false;
                state.isFieldChanged = false;
                state.isPhotoChanged = false;
            })
            .addCase(changeUserAvatarSuccess, state => {
                state.errors = [];
                state.loading = false;
                state.isCodeSent = false;
                state.isCodeVerified = false;
                state.isFieldChanged = false;
                state.isPhotoChanged = true;
            })
            .addCase(getRatesRequest, state => {
                state.loading = true;
            })
            .addCase(getRatesSuccess, (state, action) => {
                state.loading = false;
                state.rates = action.payload.value.rates;
            })
            .addCase(editRateRequest, state => {
                state.loading = true;
                state.isRateChanged = false;
            })
            .addCase(editRateSuccess, state => {
                state.loading = false;
                state.isRateChanged = true;
            })
            .addCase(getGoogleCalendarRequest, state => {
                state.loading = true;
                state.isCalendarChanged = false;
            })
            .addCase(getGoogleCalendarSuccess, (state, action) => {
                state.loading = false;
                state.googleCalendar = action.payload;
            })
            .addCase(addCalendarIdRequest, state => {
                state.loading = true;
                state.isCalendarChanged = false;
            })
            .addCase(addCalendarIdSuccess, state => {
                state.loading = false;
                state.isCalendarChanged = true;
            })
            .addCase(getCalendarRequest, state => {
                state.loading = true;
                state.isCalendarChanged = false;
            })
            .addCase(getCalendarSuccess, (state, action) => {
                state.loading = false;
                state.workHours = action.payload.value.workHours;
            })
            .addCase(addCalendarRequest, state => {
                state.loading = false;
                state.isCalendarChanged = true;
            })
            .addCase(addCalendarSuccess, state => {
                state.loading = false;
            })
            .addCase(getLicensesRequest, state => {
                state.loading = true;
            })
            .addCase(getLicenseSuccess, (state, action) => {
                state.loading = true;
                state.licenses = action.payload;
            })
            .addCase(renewLicenseRequest, state => {
                state.renewLicense.loading = true;
            })
            .addCase(renewLicenseSuccess, (state, action) => {
                state.renewLicense.loading = false;

                const licenseToUpdate = state.licenses.find(({ id }) => id === action.payload);

                if (!licenseToUpdate) return;

                licenseToUpdate.isNew = true;
            })
            .addCase(renewLicenseError, (state, action) => {
                state.renewLicense.loading = false;
                state.renewLicense.errors = action.payload;
            })
            .addCase(getLicenseDraftRequest, state => {
                state.loading = true;
            })
            .addCase(getLicenesDraftSuccess, (state, action) => {
                state.loading = false;
                state.draftLicense = action.payload;
            })
            .addCase(addLicenseRequest, state => {
                state.addLicense.errors = [];
                state.addLicense.loading = true;
            })
            .addCase(addLicenseSuccess, state => {
                state.addLicense.loading = false;
            })
            .addCase(addLicenseError, (state, action) => {
                state.addLicense.errors = action.payload;
                state.addLicense.loading = false;
            })
            .addCase(saveLicenseDraftRequest, state => {
                state.saveDraft.errors = [];
                state.saveDraft.loading = true;
            })
            .addCase(saveLicenseDraftSuccess, state => {
                state.saveDraft.loading = false;
            })
            .addCase(saveLicenseDraftError, (state, action) => {
                state.saveDraft.loading = false;
                state.saveDraft.errors = action.payload;
            })
            .addCase(getAvailableRequests, state => {
                state.availableRequests.loading = true;
                state.availableRequests.errors = [];
            })
            .addCase(getAvailableRequestsSuccess, (state, action) => {
                state.availableRequests.loading = false;
                state.availableRequests.requestTypes = action.payload.requestTypes;
            })
            .addCase(getAvailableRequestsError, (state, action) => {
                state.availableRequests.loading = false;
                state.availableRequests.errors = action.payload;
            })
            .addCase(getNotificationSettingsRequest, state => {
                state.availableCases.loading = true;
                state.availableCases.errors = [];
            })
            .addCase(getNotificationSettingsRequestSuccess, (state, action) => {
                state.availableCases.loading = false;
                state.availableCases.cases = action.payload.cases;
            })
            .addCase(getNotificationSettingsRequestError, (state, action) => {
                state.availableCases.loading = false;
                state.availableCases.errors = action.payload;
            })
            .addCase(archiveCurrentUser, state => {
                state.archiveCurrentUser.loading = true;
            })
            .addCase(archiveCurrentUserSuccess, state => {
                state.archiveCurrentUser.loading = false;
                state.archiveCurrentUser.errors = [];
            })
            .addCase(archiveCurrentUserError, (state, action) => {
                state.archiveCurrentUser.loading = false;
                state.archiveCurrentUser.errors = action.payload;
            })
            .addCase(switchIsArchiveCurrentUserModalOpen, (state, action) => {
                state.archiveCurrentUser.isModalOpened = action.payload;
            });
    },
});

export default settingsSlice.reducer;
