import React from 'react';
import { useRouteMatch } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ISignInRoutingProps } from './types';
import { MatchParams } from '../../../common/types';

import { AuthWrapper } from '../../../common/components';
import { SignInContainer } from './SignIn.container';
import { SignInEnterNewPasswordContainer } from './components/SignInEnterNewPassword/SignInEnterNewPasswordContainer';
import { SignInEnterCodeContainer } from './components/SignInEnterCode/SignInEnterCodeContainer';
import { SignInEnterEmailContainer } from './components/SignInEnterEmail/SignInEnterEmailContainer';

export const SignInRouting: React.FC<ISignInRoutingProps> = ({ nestedPath }) => {
    const match = useRouteMatch<MatchParams>();

    return (
        <Switch>
            <Route
                path={`${match.url}/login`}
                component={() => (
                    <AuthWrapper isSignIn child={<SignInContainer nestedPath={nestedPath} />} />
                )}
            />
            <Route
                path={`${match.url}/confirm`}
                component={() => (
                    <AuthWrapper
                        isSignIn
                        child={<SignInEnterNewPasswordContainer nestedPath={nestedPath} />}
                    />
                )}
            />
            <Route
                path={`${match.url}/code`}
                component={() => (
                    <AuthWrapper
                        isSignIn
                        child={<SignInEnterCodeContainer nestedPath={nestedPath} />}
                    />
                )}
            />
            <Route
                path={`${match.url}/email`}
                component={() => (
                    <AuthWrapper
                        isSignIn
                        child={<SignInEnterEmailContainer nestedPath={nestedPath} />}
                    />
                )}
            />
            <Redirect from="*" exact={true} to={`${match.url}/login`} />
        </Switch>
    );
};
