import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';

const signUpSchema = yup.object().shape({
    fullName: yup.string().required(ErrorMessages.REQUIRED),
    email: yup.string().required(ErrorMessages.REQUIRED).email(ErrorMessages.EMAIL_INVALID),
    phoneNumber: yup.string().required(ErrorMessages.REQUIRED),
});

const signUpFillDataSchema = yup.object().shape({
    /*
    state: yup.string().required(ErrorMessages.REQUIRED),
    */
    state: yup.string(),
    agencyId: yup
        .number()
        .test(
            'check-value',
            ErrorMessages.REQUIRED,
            value => value === null || typeof value === 'number',
        )
        .nullable(),
    licence: yup.string().required(ErrorMessages.REQUIRED),
    // mlsID: yup.string().required(ErrorMessages.REQUIRED),
    password: yup
        .string()
        .min(8, ErrorMessages.SHORT_PASSWORD)
        .required(ErrorMessages.REQUIRED)
        .matches(/\d/, ErrorMessages.PASSWORD_DIGIT)
        .matches(/[A-Z]/, ErrorMessages.PASSWORD_UPPERCASE)
        .matches(/\W/g, ErrorMessages.NON_ALPHANUMERIC_MISSING)
        .test('unique-chars', ErrorMessages.MINIMUM_4_CHARS, function (value?: string) {
            return new Set(value?.split('')).size >= 4;
        }),
    repeatPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const signUpFillDataResolver = yupResolver(signUpFillDataSchema);
export const signUpResolver = yupResolver(signUpSchema);
