import React from 'react';

export const MobileMenuBtn: React.FC = () => (
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1280_31082)">
<path d="M21.2306 20.2161H2.6537C2.47774 20.2161 2.30898 20.1459 2.18456 20.0209C2.06013 19.8959 1.99023 19.7263 1.99023 19.5495C1.99023 19.3727 2.06013 19.2031 2.18456 19.0781C2.30898 18.953 2.47774 18.8828 2.6537 18.8828H21.2306C21.4066 18.8828 21.5753 18.953 21.6998 19.0781C21.8242 19.2031 21.8941 19.3727 21.8941 19.5495C21.8941 19.7263 21.8242 19.8959 21.6998 20.0209C21.5753 20.1459 21.4066 20.2161 21.2306 20.2161Z" fill="white"/>
<path d="M21.2306 13.5482H2.6537C2.47774 13.5482 2.30898 13.4779 2.18456 13.3529C2.06013 13.2279 1.99023 13.0583 1.99023 12.8815C1.99023 12.7047 2.06013 12.5351 2.18456 12.4101C2.30898 12.2851 2.47774 12.2148 2.6537 12.2148H21.2306C21.4066 12.2148 21.5753 12.2851 21.6998 12.4101C21.8242 12.5351 21.8941 12.7047 21.8941 12.8815C21.8941 13.0583 21.8242 13.2279 21.6998 13.3529C21.5753 13.4779 21.4066 13.5482 21.2306 13.5482Z" fill="white"/>
<path d="M21.2306 6.88411H2.6537C2.47774 6.88411 2.30898 6.81388 2.18456 6.68885C2.06013 6.56383 1.99023 6.39426 1.99023 6.21745C1.99023 6.04064 2.06013 5.87107 2.18456 5.74604C2.30898 5.62102 2.47774 5.55078 2.6537 5.55078H21.2306C21.4066 5.55078 21.5753 5.62102 21.6998 5.74604C21.8242 5.87107 21.8941 6.04064 21.8941 6.21745C21.8941 6.39426 21.8242 6.56383 21.6998 6.68885C21.5753 6.81388 21.4066 6.88411 21.2306 6.88411Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1280_31082">
<rect width="23.8846" height="24" fill="white" transform="translate(0 0.882812)"/>
</clipPath>
</defs>
</svg>

);
