import React from 'react';
import { Flex, FlexProps, Text } from '@chakra-ui/react';

interface StateLabelProps extends FlexProps {
    children: React.ReactNode;
}

export const StateLabel: React.FC<StateLabelProps> = ({ children, ...restProps }) => {
    return (
        <Flex
            alignSelf="flex-start"
            border="1px"
            borderColor="secondaryFont"
            borderRadius="56px"
            padding="8px 16px"
            {...restProps}
        >
            <Text color="#888DAB" mr="8px">
                State:{' '}
            </Text>{' '}
            <Text color="brand">{children}</Text>
        </Flex>
    );
};
