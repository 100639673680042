import React from 'react';
import Select from 'react-select';
import { DropdownArrowIcon } from '../../../../assets/icons';
import { Text, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { colors } from '../../../../common/constants';
import { SelectValue } from '../../../../common/types';

interface IMultiSelectProps {
    options: SelectValue[];
    defaultValue?: SelectValue[];
    onChange: (val: string[]) => void;
    errors?: string;
    label?: string;
    height?: string;
    fontSize?: string;
    width?: string;
    value?: string[];
    maxLength?: number;
    disabled?: boolean;
}

export const MultiSelect: React.FC<IMultiSelectProps> = ({
    options,
    defaultValue,
    onChange,
    errors,
    label,
    height = '50px',
    fontSize = '13px',
    width = '100%',
    value,
    maxLength,
    disabled,
}) => {
    const opacity = disabled ? 0.5 : 1;
    return (
        <FormControl isInvalid={!!errors} mb={errors ? 0 : '26px'}>
            <Text color="brand" fontWeight="300" fontSize={fontSize}>
                {label}
            </Text>
            <Select
                menuPlacement="auto"
                value={
                    value
                        ? options.filter(({ value: optionValue }) =>
                              value.some(id => id === optionValue),
                          )
                        : undefined
                }
                defaultValue={defaultValue}
                isMulti
                components={{
                    DropdownIndicator: DropdownArrowIcon,
                    IndicatorSeparator: null,
                    ClearIndicator: undefined,
                }}
                name="colors"
                options={maxLength ? (value?.length === maxLength ? [] : options) : options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={ev => {
                    onChange(ev.map(elem => elem.value));
                }}
                noOptionsMessage={() => {
                    return maxLength
                        ? value?.length === maxLength
                            ? 'You have reached the max options value'
                            : 'No options available'
                        : 'No options available';
                }}
                isDisabled={disabled}
                styles={{
                    control: provided => ({
                        ...provided,
                        borderRadius: '40px',
                        minHeight: height,
                        width,
                        background: colors.inputBackground,
                        opacity: opacity,
                        borderColor: errors ? colors.chakraErrorRed : colors.chakraBorder,
                        boxShadow: errors ? `0 0 0 1px ${colors.chakraErrorRed}` : '',
                        zIndex: 100,
                        '&:hover': {
                            borderColor: colors.chakraBorderHover,
                        },
                    }),
                    menu: provided => ({
                        ...provided,
                        borderRadius: '20px',
                        padding: '10px',
                        zIndex: 100,
                    }),
                    option: provided => ({
                        ...provided,
                        borderRadius: '5px',
                        fontSize: '12px',
                        color: colors.brand,
                    }),
                    indicatorsContainer: () => ({
                        width: '25px',
                    }),
                    noOptionsMessage: provided => ({
                        ...provided,
                        fontSize,
                    }),
                    input: provided => ({
                        ...provided,
                        fontSize,
                        color: colors.brand,
                    }),
                    multiValue: provided => ({
                        ...provided,
                        background: colors.secondaryFont,
                        borderRadius: '15px',
                        fontSize: '14px',
                        color: colors.brand,
                    }),
                    multiValueRemove: provided => ({
                        ...provided,
                        borderRadius: '15px',
                    }),
                    placeholder: provided => ({
                        ...provided,
                        fontSize: fontSize,
                        color: colors.secondaryFontDarker,
                    }),
                    valueContainer: provided => ({
                        ...provided,
                        padding: '2px 15px',
                    }),
                }}
            />
            {errors && <FormErrorMessage fontSize="12px">{errors}</FormErrorMessage>}
        </FormControl>
    );
};
