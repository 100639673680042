import React from 'react';

import BlastEmpty from '../../../src/assets/img/blast.svg';
import BlastNew from '../../../src/assets/img/blast_new.svg';
import BuildNew from '../../../src/assets/img/build.svg';

export const BlastIcon: React.FC = () => (
    <img style={{width: '25px', height: '25px'}} src={BlastEmpty} />
);

export const BlastIconBig: React.FC = () => (
    <img style={{width: '56px', height: '56px'}} src={BlastEmpty} />
);

export const BlastIconNew: React.FC = () => (
    <img style={{width: '25px', height: '25px'}} src={BlastNew} />
);

export const BlastIconBigNew: React.FC = () => (
    <img style={{width: '56px', height: '56px'}} src={BlastNew} />
);

export const BuildSmall: React.FC = () => (
    <img style={{width: '25px', height: '25px'}} src={BuildNew} />
);

export const BuildBig: React.FC = () => (
    <img style={{width: '56px', height: '56px'}} src={BuildNew} />
);

export const BlastIconNewV2: React.FC<{isActive?: boolean}> = ({isActive = false}) => (
    <div className={'icon_fix '+ (isActive? 'active' : '')} 
        style={{width: "25px", height: '25px', maskImage: "url("+BlastNew+")"  }}
    />
);

import iconOffMarket from '../../../src/assets/img/offmarket.svg';

export const OffMarketV2: React.FC<{isActive?: boolean}> = ({isActive = false}) => (
    <div className={'icon_fix '+ (isActive? 'active' : '')} 
        style={{width: "25px", height: '25px', maskImage: "url("+iconOffMarket+")"  }}
    />
);

export const OffMarketV2Big: React.FC<{isActive?: boolean}> = ({isActive = false}) => (
    <div className={'icon_fix '+ (isActive? 'active' : '')} 
        style={{width: "56px", height: '56px', maskImage: "url("+iconOffMarket+")"  }}
    />
);

import iconSettings from '../../../src/assets/img/settings-02.svg';

export const SettingsIconV2: React.FC<{isActive?: boolean}> = ({isActive = false}) => (
    <div className={'icon_fix '+ (isActive? 'active' : '')} 
        style={{width: "25px", height: '25px', maskImage: "url("+iconSettings+")"  }}
    />
);

