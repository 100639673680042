import React from 'react';
import { ModalBody, Text, Flex, Box } from '@chakra-ui/react';
import { CustomButton } from '../../../blocks';
import { roundAplicantPrices } from '../../../../utils';

interface IShowingSummaryProps {
    numOfProperties: number;
    pricePerDoor: number | null;
    onSubmit: () => void;
    finishWorkLoading: boolean;
}

export const ShowingSummary: React.FC<IShowingSummaryProps> = ({
    numOfProperties,
    onSubmit,
    pricePerDoor,
    finishWorkLoading,
}) => {
    return (
        <ModalBody w="100%">
            <Box bg="bgPaymentInfo" borderRadius="8px" p="25px 35px">
                <Text fontSize="16px" fontWeight={700} lineHeight="22px" color="brand" mb="15px">
                    Showing
                </Text>
                <Flex justifyContent="space-between" align="center" mb="10px">
                    <Text fontSize="13px" color="brand" lineHeight="20px" fontWeight={400}>
                        Number of Properties:
                    </Text>
                    <Text
                        fontSize="16px"
                        lineHeight="24px"
                        fontWeight={500}
                        color="secondaryFontDarker"
                    >
                        {roundAplicantPrices(numOfProperties)}
                    </Text>
                </Flex>
                <Flex justifyContent="space-between" align="center" mb="10px">
                    <Text fontSize="13px" color="brand" lineHeight="20px" fontWeight={400}>
                        Your Price Per Door:
                    </Text>
                    <Text
                        fontSize="16px"
                        lineHeight="24px"
                        fontWeight={500}
                        color="secondaryFontDarker"
                    >
                        ${roundAplicantPrices(pricePerDoor || 0)}
                    </Text>
                </Flex>
                <Flex justifyContent="space-between" align="center">
                    <Text fontSize="13px" color="brand" lineHeight="20px" fontWeight={600}>
                        Estimated Total Earning:
                    </Text>
                    <Text fontSize="19px" color="brand" lineHeight="28px" fontWeight={600}>
                        ${roundAplicantPrices(numOfProperties * (pricePerDoor || 0))}
                    </Text>
                </Flex>
            </Box>
            <CustomButton
                text="Submit"
                onClick={onSubmit}
                height="60px"
                bgColor="brand"
                isLoading={finishWorkLoading}
                mt="30px"
            />
        </ModalBody>
    );
};
