import { createSlice } from '@reduxjs/toolkit';
import {
  getChatUserInfoSuccess,
  getChatUserInfoError,
  getChatUserInfo,
} from '../actions/messages.actions';
import { PayloadError } from '../../common/types';

const initialState: {
  loading: boolean;
  errors: PayloadError[];
  chatInfo: {
    userId: number;
    fullName: string;
    avatar: {
      id: number;
      itemHash: string;
      itemExtension: string;
    }
}} = {
  loading: false,
  errors: [],
  chatInfo: {
    userId: 0,
    fullName: '',
    avatar: {
      id: 0,
      itemHash: '',
      itemExtension: '',
    },
}}

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    .addCase(getChatUserInfo, (state) => {
      state.loading = true;
      state.errors = [];
    })
    .addCase(getChatUserInfoSuccess, (state, action) => {
      state.loading = false;
      state.chatInfo = action.payload;
    })
    .addCase(getChatUserInfoError, (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    })
  }
);

export default messagesSlice.reducer;
