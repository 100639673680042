import React from 'react';

export const BlueDash: React.FC = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="57" 
    height="5" 
    fill="none"
  >
    <rect width="57" height="4" y=".863" fill="#2F80ED" rx="2"/>
  </svg>
)