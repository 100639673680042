import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Flex,
    SimpleGrid,
} from '@chakra-ui/react';
import { useDelta } from 'react-delta';
import * as yup from 'yup';

import {
    CustomInput,
    CustomButton,
    Loader,
    RequestQuickInfo,
    CustomInputEdit,
    CustomDropdownEdit,
} from '../../../../common/components';

import { colors } from '../../../../common/constants';
import { RootStore } from '../../../../store';
import { ErrorMessages } from '../../../../common/schemes/messages';
import { CloseButton } from '../../../../assets/icons';

import {
    getBonusTypes,
    getClientTypes,
    getRequestTypes,
} from '../../../../store/actions/directories.actions';
import {
    getRequest,
    deleteRequest,
    changeClientName,
    changeClientPhone,
    changeClientType,
    changePrivateNote,
} from '../../../Requests/store/actions';
import { fonts, getFeePrice } from '../../../../common/utils';

import { IdNameTypes } from '../../../../common/types';

interface IPostDetailsModal {
    isOpen: boolean;
    onClose: () => void;
    postId: number;
    getItems: (page?: number) => void;
}

export const PostDetailsModal: React.FC<IPostDetailsModal> = ({
    isOpen,
    onClose,
    postId,
    getItems,
}) => {
    const dispatch = useDispatch();
    const { loading, value: data } = useSelector((state: RootStore) => state.requests.requestInfo);
    const { loading: deleteLoading, errors: deleteErrors } = useSelector(
        (state: RootStore) => state.requests.deleteRequest,
    );
    const clientTypes = useSelector((state: RootStore) => state.directories.clientTypes);

    const deleteLoadingDelta = useDelta(deleteLoading);

    const onDeleteRequest = (id: number) => {
        dispatch(deleteRequest({id}));
    };

    const [clientTypeValue, setClientTypeValue] = useState(data.clientTypeId);

    const feePrice = getFeePrice(data.totalPrice);

    const handleEditClientName = (clientName: string) => {
        dispatch(
            changeClientName({
                requestId: postId,
                clientName,
            }),
        );
    };

    const handleEditClientPhone = (clientPhoneNumber: string) => {
        dispatch(
            changeClientPhone({
                requestId: postId,
                clientPhoneNumber,
            }),
        );
    };

    const handleEditClientType = (clientTypedId: number) => {
        dispatch(
            changeClientType({
                requestId: postId,
                clientTypedId,
            }),
        );
    };

    const handleEditPrivateNote = (privateNote: string) => {
        dispatch(
            changePrivateNote({
                requestId: postId,
                privateNote,
            }),
        );
    };

    useEffect(() => {
        if (isOpen) {
            dispatch(getRequest(postId));
            dispatch(getRequestTypes());
            dispatch(getClientTypes());
        }
    }, [isOpen]);

    useEffect(() => {
        if (deleteLoadingDelta && deleteLoadingDelta.prev) {
            if (
                deleteLoadingDelta.prev &&
                !deleteLoadingDelta.curr &&
                !deleteErrors.length &&
                isOpen
            ) {
                onClose();
                getItems(1);
            }
        }
    }, [deleteLoadingDelta]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="5xl">
            <ModalOverlay />
            <ModalContent borderRadius="18px" bgColor={colors.modalBackground} alignItems="center">
                <ModalHeader
                    display="flex"
                    w="800px"
                    justifyContent="space-between"
                    flexDirection="row"
                    mt="20px"
                >
                    <Text color="brand">{data.requestTypeName}</Text>
                    <Box as="button" onClick={() => onClose()}>
                        <CloseButton />
                    </Box>
                </ModalHeader>
                <ModalBody overflowY="auto">
                    {loading ? (
                        <Loader spinnerSize="md" centerHeight="100%" />
                    ) : (
                        <>
                            <SimpleGrid columns={2} gap="50px" rowGap="0px">
                                <Flex flexDirection="column">
                                    {data.typeId === 7 ? (
                                        <Flex
                                            padding="20px"
                                            justifyContent="space-between"
                                            bg="#F6F7FB"
                                            borderRadius="24px"
                                            flexDirection="column"
                                        >
                                            <Text
                                                color="secondaryFontDarker"
                                                fontWeight={400}
                                                fontSize="14px"
                                                lineHeight="26px"
                                                fontFamily={fonts.poppins}
                                            >
                                                State
                                            </Text>
                                            {data.states &&
                                                data.states.map((state: string) => (
                                                    <Text
                                                        color="brand"
                                                        fontWeight={600}
                                                        fontSize="12px"
                                                        lineHeight="24px"
                                                        fontFamily={fonts.poppins}
                                                        key={state}
                                                    >
                                                        {state}
                                                    </Text>
                                                ))}
                                            <Text
                                                color="secondaryFontDarker"
                                                fontWeight={400}
                                                fontSize="14px"
                                                lineHeight="26px"
                                                fontFamily={fonts.poppins}
                                            >
                                                Agencies
                                            </Text>
                                            {data.agencies &&
                                                data.agencies.map((agency: string) => (
                                                    <Text
                                                        color="brand"
                                                        fontWeight={600}
                                                        fontSize="12px"
                                                        lineHeight="24px"
                                                        fontFamily={fonts.poppins}
                                                        key={agency}
                                                    >
                                                        {agency}
                                                    </Text>
                                                ))}
                                        </Flex>
                                    ) : (
                                        <RequestQuickInfo
                                            date={data.dateTime}
                                            fromTime={data.fromTime}
                                            duration={data.duration}
                                            propertiesToShow={data.propertiesToShow}
                                            requestTypeName={data.requestTypeName}
                                            totalPrice={data.totalPrice}
                                            isShowTotalPrice={false}
                                            margin={0}
                                        />
                                    )}

                                    <Box mt={6} w="350px">
                                        {data.states &&
                                            data.states.map((state: string) => (
                                                <CustomInput
                                                    key={state}
                                                    placeholder="State"
                                                    label="State"
                                                    register={{ value: state }}
                                                    isBorder={false}
                                                    isReadOnly
                                                />
                                            ))}
                                    </Box>
                                    <Box mt={6} w="350px">
                                        {data.typeId !== 7 && (
                                            <>
                                                <Text
                                                    color="brand"
                                                    fontWeight={700}
                                                    fontSize="16px"
                                                    lineHeight="24px"
                                                    fontFamily={fonts.poppins}
                                                >
                                                    Addresses
                                                </Text>
                                            </>
                                        )}
                                        <Box>
                                            {data.addresses &&
                                                data.addresses.map((address: string) => (
                                                    <CustomInput
                                                        key={address}
                                                        placeholder="Address"
                                                        register={{ value: address }}
                                                        props={{ mt: 2 }}
                                                        isReadOnly
                                                    />
                                                ))}
                                        </Box>
                                    </Box>
                                    {data.generalNote && (
                                        <Box mt={6} w="350px">
                                            <Text
                                                color="brand"
                                                fontWeight={700}
                                                fontSize="18px"
                                                lineHeight="18px"
                                                fontFamily={fonts.montserrat}
                                            >
                                                Description:
                                            </Text>
                                            <Text
                                                color="secondaryFontDarker"
                                                fontWeight={400}
                                                fontSize="16px"
                                                lineHeight="26px"
                                                fontFamily={fonts.poppins}
                                                mt={2}
                                            >
                                                {data.generalNote}
                                            </Text>
                                        </Box>
                                    )}
                                </Flex>
                                <Flex flexDirection="column">
                                    <Text
                                        color="brand"
                                        fontWeight={700}
                                        fontSize="18px"
                                        lineHeight="36px"
                                        fontFamily={fonts.poppins}
                                    >
                                        More details:
                                    </Text>
                                    {data.typeId === 1 && (
                                        <Flex flexDirection="column">
                                            <Flex mt={4} justifyContent="space-between">
                                                <CustomInput
                                                    label="Is The Showing Scheduled?"
                                                    isReadOnly
                                                    width="350px"
                                                    textSize="16px"
                                                    value={data.isShowingScheduled ? 'Yes' : 'No'}
                                                />
                                            </Flex>
                                            <Flex mt={4} justifyContent="space-between">
                                                <CustomInput
                                                    label="I would like to offer a Bonus if a contract is ratified from this showing list within 48-hours Of Showing:"
                                                    isReadOnly
                                                    width="350px"
                                                    textSize="16px"
                                                    value={
                                                        data.wouldLikeToOfferBonus ? 'Yes' : 'No'
                                                    }
                                                />
                                            </Flex>
                                            {data.wouldLikeToOfferBonus && (
                                                <Flex mt={4} justifyContent="space-between">
                                                    <CustomInput
                                                        label="bonus Type:"
                                                        isReadOnly
                                                        width="150"
                                                        value={data.bonusTypeId === 1 ? '%' : '$'}
                                                    />
                                                    <CustomInput
                                                        label="Bonus"
                                                        isReadOnly
                                                        width="150"
                                                        value={data.amount}
                                                    />
                                                </Flex>
                                            )}
                                        </Flex>
                                    )}
                                    <>
                                        <Flex mt={4}>
                                            <CustomDropdownEdit
                                                label="The Client is:"
                                                value={clientTypeValue}
                                                onChange={setClientTypeValue}
                                                options={clientTypes.map(
                                                    (elem: IdNameTypes, index: number) => ({
                                                        label: elem.name,
                                                        value: elem.id,
                                                        id: index,
                                                    }),
                                                )}
                                                editFunction={handleEditClientType}
                                                width="350px"
                                            />
                                        </Flex>
                                        <Flex mt={4}>
                                            <CustomInputEdit
                                                defaultValue={data?.referralClient?.name}
                                                placeholder="Enter"
                                                label="Client Name:"
                                                width="350px"
                                                editFunction={handleEditClientName}
                                                schema={yup
                                                    .string()
                                                    .required(ErrorMessages.REQUIRED)}
                                                errorMsg={ErrorMessages.REQUIRED}
                                            />
                                        </Flex>
                                        <Flex mt={4}>
                                            <CustomInputEdit
                                                defaultValue={data?.referralClient?.phone}
                                                placeholder="Enter"
                                                label="Client Phone Number:"
                                                width="350px"
                                                type="tel"
                                                mask="999-999-9999"
                                                editFunction={handleEditClientPhone}
                                                schema={yup
                                                    .string()
                                                    .required(ErrorMessages.REQUIRED)}
                                                errorMsg={ErrorMessages.REQUIRED}
                                            />
                                        </Flex>
                                        <Flex mt={4}>
                                            <CustomInputEdit
                                                defaultValue={data?.privateNote}
                                                placeholder="Enter"
                                                label="Private Notes To The Chosen Agent:"
                                                width="350px"
                                                editFunction={handleEditPrivateNote}
                                                schema={yup
                                                    .string()
                                                    .required(ErrorMessages.REQUIRED)}
                                                errorMsg={ErrorMessages.REQUIRED}
                                            />
                                        </Flex>
                                    </>
                                </Flex>
                            </SimpleGrid>
                            <SimpleGrid columns={2} gap="50px" rowGap="0px" mt="150px" mb="80px">
                                <Flex flexDirection="column">
                                    {data.typeId !== 7 && (
                                        <Box>
                                            <Flex justifyContent="space-between" width="350px">
                                                <Text fontSize="13px" color="secondaryFontDarker">
                                                    Fee
                                                </Text>
                                                <Text
                                                    fontSize="16px"
                                                    fontWeight={500}
                                                    color="secondaryFontDarker"
                                                >
                                                    ${feePrice}
                                                </Text>
                                            </Flex>
                                            <Flex
                                                justifyContent="space-between"
                                                width="350px"
                                                mt="10px"
                                            >
                                                <Text fontSize="13px" color="secondaryFontDarker">
                                                    Offer Price
                                                </Text>
                                                <Text
                                                    fontSize="16px"
                                                    fontWeight={500}
                                                    color="secondaryFontDarker"
                                                >
                                                    ${data.totalPrice}
                                                </Text>
                                            </Flex>
                                            <Flex
                                                justifyContent="space-between"
                                                width="350px"
                                                mt="10px"
                                            >
                                                <Text
                                                    fontSize="13px"
                                                    color="brand"
                                                    fontWeight={600}
                                                >
                                                    Estimated Cost
                                                </Text>
                                                <Text
                                                    fontSize="19px"
                                                    fontWeight={500}
                                                    color="brand"
                                                >
                                                    ${Number(data.totalPrice) + feePrice}
                                                </Text>
                                            </Flex>
                                        </Box>
                                    )}
                                </Flex>
                                <Flex alignItems="flex-end">
                                    <CustomButton
                                        width="350px"
                                        height="60px"
                                        isLoading={deleteLoading}
                                        loadingText="Deleting..."
                                        text="Delete"
                                        bgColor="brand"
                                        onClick={() => onDeleteRequest(postId)}
                                    />
                                </Flex>
                            </SimpleGrid>
                        </>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
