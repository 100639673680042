import React from 'react';

export const TwitterIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="92" fill="none">
        <path
            fill="#55ACEE"
            d="M69.766.917h-38.92C14.206.917.715 13.604.715 29.255v34.241c0 15.65 13.49 28.338 30.131 28.338h38.92c16.64 0 30.13-12.688 30.13-28.338V29.255c0-15.65-13.49-28.338-30.13-28.338Z"
        />
        {/* <g clipPath="url(#a)"> */}
            <path
                fill="#fff"
                d="M64.474 30.19c-1.06.47-2.186.779-3.339.915a5.83 5.83 0 0 0 2.556-3.216A11.64 11.64 0 0 1 60 29.299a5.807 5.807 0 0 0-4.244-1.835 5.814 5.814 0 0 0-5.663 7.139 16.506 16.506 0 0 1-11.984-6.075 5.788 5.788 0 0 0-.787 2.923 5.812 5.812 0 0 0 2.587 4.839 5.792 5.792 0 0 1-2.634-.727v.073a5.817 5.817 0 0 0 4.663 5.7 5.82 5.82 0 0 1-2.625.1 5.82 5.82 0 0 0 5.431 4.038 11.666 11.666 0 0 1-7.22 2.489c-.47 0-.932-.028-1.387-.082a16.455 16.455 0 0 0 8.912 2.612c10.694 0 16.541-8.859 16.541-16.541 0-.252-.005-.503-.017-.752a11.817 11.817 0 0 0 2.901-3.01Z"
            />
        {/* </g> */}
        <path
            fill="#fff"
            d="M39.209 61.537v1.294h-1.757v5.337h-1.616v-5.337H34.08v-1.294h5.13Zm8.376 1.36-1.426 5.271h-1.785l-.832-3.419-.859 3.42h-1.776l-1.436-5.271h1.616l.746 3.769.888-3.77h1.71l.897 3.75.737-3.75h1.52Zm1.429-.547c-.284 0-.517-.082-.7-.246a.833.833 0 0 1-.264-.623c0-.252.088-.46.265-.624a.985.985 0 0 1 .699-.255.94.94 0 0 1 .68.255.8.8 0 0 1 .274.624.82.82 0 0 1-.274.623c-.177.164-.403.246-.68.246Zm.803.548v5.27H48.2v-5.27h1.616Zm4.108 3.9v1.37h-.822c-.586 0-1.042-.141-1.37-.425-.327-.29-.49-.758-.49-1.407v-2.097H50.6v-1.341h.642v-1.285h1.615v1.285h1.058v1.341h-1.058v2.116c0 .157.038.27.114.34.075.07.201.104.378.104h.576Zm3.837 0v1.37h-.822c-.585 0-1.042-.141-1.37-.425-.327-.29-.49-.758-.49-1.407v-2.097h-.643v-1.341h.643v-1.285h1.615v1.285h1.058v1.341h-1.058v2.116c0 .157.038.27.113.34.076.07.202.104.378.104h.576Zm5.868-1.35c0 .151-.009.309-.028.472h-3.655c.025.328.129.58.311.756.19.17.42.255.69.255.403 0 .683-.17.84-.51h1.72a2.33 2.33 0 0 1-.482.935 2.346 2.346 0 0 1-.86.652 2.777 2.777 0 0 1-1.161.236c-.517 0-.977-.11-1.38-.33a2.352 2.352 0 0 1-.944-.945c-.227-.41-.34-.888-.34-1.436 0-.548.11-1.026.33-1.436.227-.41.542-.724.945-.944.403-.22.866-.33 1.389-.33.51 0 .963.106 1.36.32.396.214.705.52.925.916.227.397.34.86.34 1.389Zm-1.653-.425c0-.277-.094-.498-.283-.661a1.043 1.043 0 0 0-.708-.246c-.271 0-.501.079-.69.236-.182.158-.296.381-.34.671h2.021Zm4.12-1.247c.19-.29.426-.516.71-.68.283-.17.598-.255.944-.255v1.71h-.444c-.403 0-.705.088-.907.264-.201.17-.302.472-.302.907v2.447h-1.615v-5.271h1.615v.878Z"
        />
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M36.137 27.373h28.338v23.135H36.137z" />
            </clipPath>
        </defs>
    </svg>
);
