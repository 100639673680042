import React, { useState } from 'react';
import {
    Box,
    Input,
    Text,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    FormErrorMessage,
    FormControl,
    InputLeftAddon,
    TextProps,
    Tooltip,
} from '@chakra-ui/react';
import { EyeIcon, ClosedEyeIcon } from '../../../../assets/icons';
import InputMask from 'react-input-mask';
import { fonts } from '../../../utils';

import { InfoOutlineIcon } from '@chakra-ui/icons'

interface ICustomInputProps {
    labelProps?: TextProps;
    props?: any;
    label?: string;
    width?: string | { base?: string; sm?: string; md?: string; lg?: string };
    leftIcon?: any;
    rightIcon?: any;
    placeholder?: string;
    margin?: string | number;
    register?: any;
    errors?: any;
    isPassword?: boolean;
    bgColor?: string;
    isBorder?: boolean;
    disabled?: boolean;
    defaultValue?: string | number;
    isReadOnly?: boolean;
    color?: string;
    labelColor?: string;
    mask?: string;
    pattern?: string | RegExp;
    value?: string;
    cursor?: string;
    onChange?: (arg0: any) => void;
    onKeyDown?: (arg0: any) => void;
    fontWeight?: number;
    fontSize?: string;
    fontFamily?: string;
    textSize?: string;
    placeholderColor?: string;
    height?: string;
    borderRadius?: string;
    textTransform?: string;
    tooltipString?: string,
    type?:
        | 'none'
        | 'text'
        | 'tel'
        | 'url'
        | 'email'
        | 'number'
        | 'decimal'
        | 'search'
        | 'password'
        | 'time'
        | 'time-12-hours'
        | undefined;
}

const CustomInput = React.forwardRef<HTMLInputElement, ICustomInputProps>(
    (
        {
            onChange,
            onKeyDown,
            type,
            label,
            width,
            leftIcon,
            rightIcon,
            props,
            placeholder,
            errors,
            isPassword,
            mask,
            disabled,
            defaultValue,
            isReadOnly = false,
            labelProps,
            placeholderColor,
            margin,
            register,
            value,
            color = 'brand',
            labelColor = 'brand',
            cursor = 'text',
            isBorder = true,
            bgColor = '#F6F7FB',
            fontWeight = '400',
            fontSize = '13px',
            fontFamily = fonts.poppins,
            textSize = '13px',
            height = '50px',
            borderRadius = '80px',
            textTransform = 'none',
            tooltipString,
        },
        ref,
    ) => {
        const [showPassword, setShowPassword] = useState(false);

        const time12HoursFormatChars = {
            a: '[APap]',
            '9': '[0-9]',
        };

        const setType = () => {
            if (isPassword) {
                if (showPassword) {
                    return 'text';
                } else {
                    return 'password';
                }
            } else {
                return type === 'time-12-hours' ? 'text' : type;
            }
        };

        return (
            <Box w={width} textAlign="left" color="#fff" m={margin}>
                <FormControl isInvalid={!!errors}>
                    {label && (
                        <>
                            <Text
                                textTransform={'capitalize'}
                                color={labelColor}
                                fontWeight={fontWeight}
                                fontSize={fontSize}
                                fontFamily={fontFamily}
                                {...labelProps}
                            >
                                {label}
                                {tooltipString && (
                                    <Tooltip hasArrow bg='#fff' color='black' label={tooltipString}>
                                        <InfoOutlineIcon className="info_icon_rates" />
                                    </Tooltip>
                                )}
                            </Text>
                        </>
                    )}
                    <InputGroup>
                        {leftIcon && <InputLeftElement h={height} w={height} children={leftIcon} />}
                        {type === 'tel' && (
                            <InputLeftAddon
                                h={height}
                                children="+1"
                                borderRadius={borderRadius}
                                color="brand"
                                fontSize="14px"
                                borderWidth={isBorder ? '1px' : '0'}
                            />
                        )}
                        <Input
                            as={type === 'time-12-hours' || mask ? InputMask : undefined}
                            mask={type === 'time-12-hours' ? '99:99 aM' : mask}
                            formatChars={
                                type === 'time-12-hours' ? time12HoursFormatChars : undefined
                            }
                            ref={ref}
                            textTransform={type === 'time-12-hours' ? 'uppercase' : textTransform}
                            type={setType()}
                            h={height}
                            cursor={cursor}
                            bg={bgColor}
                            borderRadius={borderRadius}
                            borderWidth={isBorder ? '1px' : '0'}
                            color={color}
                            placeholder={placeholder}
                            _placeholder={{
                                color: placeholderColor ? 'black' : null,
                            }}
                            disabled={disabled}
                            defaultValue={
                                type === 'time-12-hours' && !defaultValue
                                    ? '00:00 AM'
                                    : defaultValue
                            }
                            value={value}
                            isReadOnly={isReadOnly}
                            focusBorderColor={isReadOnly && 'none'}
                            onKeyDown={onKeyDown ? onKeyDown : null}
                            onChange={onChange ? onChange : null}
                            fontWeight={fontWeight}
                            fontSize={textSize}
                            fontFamily={fontFamily}
                            boxShadow={'none'}
                            borderColor={'inherit'}
                            _hover={isReadOnly ? 'none' : null}
                            _focus={isReadOnly ? 'none' : null}
                            {...register}
                            {...props}
                        />
                        {isPassword && (
                            <InputRightElement
                                onClick={() => setShowPassword(!showPassword)}
                                h="100%"
                                mr="5px"
                                zIndex="10"
                                children={showPassword ? <EyeIcon /> : <ClosedEyeIcon />}
                            />
                        )}
                        {rightIcon && !isReadOnly && (
                            <InputRightElement zIndex={10} h="100%" mr="5px" children={rightIcon} />
                        )}
                    </InputGroup>
                    {errors && (
                        <FormErrorMessage fontSize={fontSize}>{errors.message}</FormErrorMessage>
                    )}
                </FormControl>
            </Box>
        );
    },
);

CustomInput.displayName = 'CustomInput';

export { CustomInput };
