import React, { useState } from 'react';
import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Textarea,
    TextareaProps,
    Text,
    Flex,
} from '@chakra-ui/react';
import { fonts } from '../../../common/utils';
import { colors } from '../../../common/constants';

interface IInputTextAreaProps extends TextareaProps {
    label: string;
    errorMsg?: string;
    defaultValue?: string;
}

export const InputTextArea = React.forwardRef<null, IInputTextAreaProps>(
    ({ label, errorMsg, maxLength = 1000, defaultValue, onChange, ...rest }, ref) => {
        const [charactersValue, setCharactersValue] = useState(defaultValue || '');
        return (
            <FormControl isInvalid={!!errorMsg}>
                <Flex>
                    <FormLabel m={0} fontWeight="300" fontSize="13px" fontFamily={fonts.poppins}>
                        {label}
                    </FormLabel>
                    <Flex flexGrow={1} justifyContent={'flex-end'}>
                        <Text
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight={'21px'}
                            color={'#A6AAC0'}
                            mr={'10px'}
                        >
                            Characters Left
                        </Text>
                        <Text
                            color={colors.brand}
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight={'21px'}
                        >
                            {maxLength - charactersValue.length}
                        </Text>
                    </Flex>
                </Flex>
                <Textarea
                    defaultValue={defaultValue}
                    onChange={ev => {
                        setCharactersValue(ev.target.value);
                        onChange?.(ev);
                    }}
                    maxLength={maxLength}
                    outline={'none'}
                    border={'none'}
                    pt={'18px'}
                    px={'18px'}
                    color={colors.brand}
                    fontWeight={400}
                    fontFamily={fonts.poppins}
                    lineHeight={'24px'}
                    fontSize={'16px'}
                    height={'120px'}
                    placeholder={'Write Text'}
                    paddingInline={'18px'}
                    bgColor={colors.inputBackground}
                    borderRadius={'24px'}
                    {...rest}
                    ref={ref}
                />
                {errorMsg && <FormErrorMessage fontSize="12px">{errorMsg}</FormErrorMessage>}
            </FormControl>
        );
    },
);

InputTextArea.displayName = 'InputTextArea';
