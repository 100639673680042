import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    IconButton,
    Modal,
    ModalContent,
    ModalOverlay,
    Text,
    Image,
    CloseButton,
    Link,
} from '@chakra-ui/react';
import Showing from '../../../assets/icons/GetHelpNow/get-help-1.svg';
import { colors } from '../../../common/constants';
import { fonts } from '../../../common/utils';

interface IMainHelpModalNow {
    isOpen: boolean;
    onClose: () => void;
    title: string;
}

import OpenHouse from '../../../assets/icons/GetHelpNow/get-help-3.svg';
import FinalWalk from '../../../assets/icons/GetHelpNow/get-help-4.svg';
import HomeInspection from '../../../assets/icons/GetHelpNow/get-help-2.svg';
import { GetHelpNowModal } from '../modals/GetHelpNowModal';
import { dispatch, RootStore } from '../../../store';
import { findJobPostsRequest } from '../../Opportunities/store/actions';
import { CreateNewPostModal as CreateNewPostModalBLAST } from '../../Updates/modals';
import { useSelector } from 'react-redux';
import { CreatePostPayload } from '../../Updates/types';
import { createPostRequest } from '../../Updates/store/actions';
import { CreateNewPostModal } from '../../Opportunities/modals';
import { useDelta } from 'react-delta';
import { NavLink, useHistory } from 'react-router-dom';
import { BlastIcon, BlastIconBig, BlastIconBigNew, BuildBig, OffMarketV2Big } from '../../../assets/icons/Blast';
import { RequestIconV2Big } from '../../../assets/icons/RequestIcon';
import { SidebarButton } from '../../../common/components/blocks/SidebarButton/SidebarButton';

export const MainHelpModalNow: React.FC<IMainHelpModalNow> = ({ isOpen, onClose, title }) => {
    const router = useHistory();

    const [isOpenHelp, setisOpenHelp] = useState(false);
    const [isOpenOp, setisOpenOp] = useState(false);
    const [showCreatePostModal, setCreatePostModal] = useState(false);

    const { loading: createPostLoading, errors: createPostErrors } = useSelector(
        (state: RootStore) => state.posts.createPost,
    );
    const createPostLoadingDelta = useDelta(createPostLoading);
    

    const getItems = (page?: number) => {
        dispatch(
            findJobPostsRequest({
                pageNumber: page || 1,
                pageSize: 10,
                isMine: false,
                typeIds: [],
                date: '',
            }),
        );
    };

    const onCreatePost = (payload: CreatePostPayload) => {
        dispatch(createPostRequest(payload));
    };

    useEffect(() => {
        if (
            createPostLoadingDelta?.prev &&
            !createPostLoadingDelta.curr &&
            !createPostErrors.length
        ) {
            router.push('/updates');
        }
    }, [createPostLoadingDelta]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                <ModalOverlay />
                <ModalContent
                    overflow={'hidden'}
                    borderRadius="18px"
                    bgColor={colors.modalBackground}
                    alignItems="center"
                >
                    <Text
                        color={colors.brand}
                        fontFamily={fonts.montserrat}
                        fontWeight={700}
                        fontSize="22px"
                        lineHeight="18px"
                        textAlign="center"
                        fontStyle="normal"
                        mt="60px"
                        mb="40px"
                    >
                        {title}
                    </Text>
                    <IconButton
                        onClick={onClose}
                        aria-label={'close modal'}
                        icon={<CloseButton />}
                        variant="ghost"
                        position="absolute"
                        top="30px"
                        right="55px"
                    />
                    <Box mb="90px" className="hm_box">
                        <Flex mb="20px" className="btns_flex">
                            <div className="pointer flex_cell" onClick={() => {
                                onClose();
                                setisOpenOp(false);
                                setisOpenHelp(true);
                            }}>
                                <Flex
                                    borderRadius="14px"
                                    borderWidth="5px"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    cursor="pointer"
                                >
                                    <Image
                                        src={FinalWalk}
                                        alt="Showing"
                                        width="56px"
                                        height="56px"
                                        mb="25px"
                                        mt="50px"
                                    />
                                    <Text
                                        width="235px"
                                        textAlign="center"
                                        mb="50px"
                                        lineHeight="28px"
                                        color={colors.brand}
                                        fontWeight="600"
                                        fontStyle="normal"
                                        fontFamily={fonts.poppins}
                                        fontSize="18px"
                                    >
                                        Need Coverage Now!
                                    </Text>
                                </Flex>
                            </div>
                            <div className="pointer flex_cell" onClick={() => {
                                    onClose();
                                    setisOpenHelp(false);
                                    setisOpenOp(true);
                                }}>
                                <Flex
                                    borderRadius="14px"
                                    borderWidth="5px"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    ml="20px"
                                    mr="20px"
                                >
                                    <Image
                                        src={HomeInspection}
                                        alt="HomeInspection"
                                        width="56px"
                                        height="56px"
                                        mb="25px"
                                        mt="50px"
                                    />
                                    <Text
                                        width="235px"
                                        textAlign="center"
                                        mb="50px"
                                        lineHeight="28px"
                                        color={colors.brand}
                                        fontWeight="600"
                                        fontStyle="normal"
                                        fontFamily={fonts.poppins}
                                        fontSize="18px"
                                    >
                                        Agent Opportunity
                                    </Text>
                                </Flex>
                            </div>
                        </Flex>
                        <Flex mb="20px" className="btns_flex">
                            <div className="pointer flex_cell" onClick={() => {
                                onClose();
                                setisOpenOp(false);
                                setisOpenHelp(false);
                                setCreatePostModal(true);
                            }}>
                                <Flex
                                    className="btn_c_flex"
                                    borderRadius="14px"
                                    borderWidth="5px"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    cursor="pointer"
                                    >
                                    <Box padding="38px 0px 20px 0px;">
                                        <BlastIconBigNew />
                                    </Box>
                                    <Text
                                        width="235px"
                                        textAlign="center"
                                        mb="50px"
                                        lineHeight="28px"
                                        color={colors.brand}
                                        fontWeight="600"
                                        fontStyle="normal"
                                        fontFamily={fonts.poppins}
                                        fontSize="18px"
                                    >
                                        Blast
                                    </Text>
                                </Flex>
                            </div>
                            <div className="pointer flex_cell" onClick={() => {
                                    
                                }}>
                                <NavLink to="/market">
                                    <Flex
                                        className="btn_c_flex"
                                        borderRadius="14px"
                                        borderWidth="5px"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        ml="20px"
                                        mr="20px"
                                        href="/market"
                                        >
                                        <Box padding="38px 0px 20px 0px;">
                                            <OffMarketV2Big />
                                        </Box>
                                        <Text
                                            width="235px"
                                            textAlign="center"
                                            mb="50px"
                                            lineHeight="28px"
                                            color={colors.brand}
                                            fontWeight="600"
                                            fontStyle="normal"
                                            fontFamily={fonts.poppins}
                                            fontSize="18px"
                                        >
                                            Off-Market-Need
                                        </Text>
                                    </Flex>
                                </NavLink>
                            </div>
                        </Flex>
                    </Box>
                </ModalContent>
            </Modal>
            <GetHelpNowModal
                isOpen={isOpenHelp}
                onClose={() => {
                    setisOpenOp(false);
                    setisOpenHelp(false);
                }}
                title={'What Do You Need Help With?'}
            />
            <CreateNewPostModal
                isOpen={isOpenOp}
                onClose={() => {
                    setisOpenOp(false);
                    setisOpenHelp(false);
                }}
                getItems={getItems}
            />
            <CreateNewPostModalBLAST
                createPostLoading={createPostLoading}
                onCreatePost={onCreatePost}
                size={'4xl'}
                isOpen={showCreatePostModal}
                onClose={() => {
                    setCreatePostModal(false);
                }}
                title={'Create New Blast'}
            />
        </>
    );
};
