import React from 'react';

export const TikTokIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="92" fill="none">
        <path
            fill="#FF007D"
            d="M69.21.917H30.292C13.651.917.161 13.604.161 29.255v34.241c0 15.65 13.49 28.338 30.13 28.338h38.92c16.64 0 30.131-12.688 30.131-28.338V29.255c0-15.65-13.49-28.338-30.131-28.338Z"
        />
        {/* <g clipPath="url(#a)"> */}
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M52.116 24.532a2.361 2.361 0 0 1 2.361 2.361 3.547 3.547 0 0 0 2.832 3.471 2.36 2.36 0 1 1-.94 4.629 8.226 8.226 0 0 1-1.892-.632v6.7a7.085 7.085 0 1 1-8.854-6.86 2.361 2.361 0 0 1 1.178 4.574 2.363 2.363 0 0 0 .592 4.648 2.362 2.362 0 0 0 2.361-2.361V26.893a2.361 2.361 0 0 1 2.362-2.361Z"
                clipRule="evenodd"
            />
        {/* </g> */}
        <path
            fill="#fff"
            d="M39.876 61.142v1.294H38.12v5.337h-1.615v-5.337h-1.757v-1.294h5.13Zm1.623.812c-.283 0-.516-.081-.699-.245a.833.833 0 0 1-.264-.624c0-.252.088-.46.264-.623a.985.985 0 0 1 .7-.255.94.94 0 0 1 .68.255.801.801 0 0 1 .273.623.82.82 0 0 1-.274.624c-.176.163-.403.245-.68.245Zm.803.548v5.271h-1.615v-5.27h1.615Zm4.392 5.271-1.606-2.21v2.21h-1.615v-6.99h1.615v3.864l1.596-2.145h1.993l-2.191 2.645 2.21 2.626h-2.002Zm7.388-6.631v1.294h-1.757v5.337H50.71v-5.337h-1.757v-1.294h5.129Zm3.21 6.707c-.517 0-.983-.11-1.398-.331a2.46 2.46 0 0 1-.973-.945c-.233-.409-.35-.887-.35-1.435 0-.542.12-1.017.36-1.427.239-.415.566-.733.982-.954.415-.22.881-.33 1.398-.33.516 0 .982.11 1.398.33.415.22.743.539.982.954.24.41.359.885.359 1.427 0 .541-.123 1.02-.368 1.435-.24.41-.57.725-.992.945-.416.22-.882.33-1.398.33Zm0-1.398c.308 0 .57-.114.784-.34.22-.227.33-.551.33-.973 0-.422-.107-.747-.32-.973a1.008 1.008 0 0 0-.775-.34c-.315 0-.577.113-.784.34-.208.22-.312.544-.312.973 0 .422.1.746.302.973.208.226.466.34.775.34Zm6.836 1.322-1.606-2.21v2.21h-1.615v-6.99h1.615v3.864l1.596-2.145h1.994l-2.192 2.645 2.21 2.626h-2.002Z"
        />
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M35.582 22.17H63.92v28.338H35.582z" />
            </clipPath>
        </defs>
    </svg>
);
