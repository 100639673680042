import { setNotificationsOpenhouseDetailsModal } from './../../pages/Notifications/store/actions';
import { Notification } from '../../pages/Notifications/types';
import {
    setNotificationsAvailableRequestModal,
    setNotificationsJobDetailsModal,
    setNotificationsMyRequestsModal,
} from '../../pages/Notifications/store/actions';
import { Dispatch } from 'react';
import { getRequest } from '../../pages/Requests/store/actions';
import { History } from 'history';

export const handlePressNotification = (item: Notification, dispatch: Dispatch<any>, router: History) => {
    switch (item.notificationTypeId) {
        // Owner Accepted Request - Activity: My Hirer
      case 2:
        if (item.assistRequestId) {
            router.push(`/activity?id=${item.assistRequestId}&isMyActivity=${false}`);
        }
        break;
      // New response - Requests: My requests
      case 3:
        if (item.assistRequestId) {
            dispatch(
                setNotificationsMyRequestsModal({
                    id: item.assistRequestId,
                    isOpen: true,
                    toApplicants: false,
                }),
            );
        }
        break;
      // New Assistance - coverage Assistance
      case 4:
        if (item.assistRequestId) {
            dispatch(
                setNotificationsAvailableRequestModal({
                    isOpen: true,
                }),
            );
            dispatch(getRequest(item.assistRequestId));
        }
        break;
                // deletion
                case 5:
                    break;
      // New Opportunity - applicant submitting Opportunity
      case 6:
        if (item.assistRequestId) {
            if (item.assistRequestId) {
                dispatch(
                    setNotificationsJobDetailsModal({ id: item.assistRequestId, isOpen: true }),
                );
            }
        }
        break;
      // New Open House - applicant submitting Open House
      case 7:
        if (item.listingId) {
            dispatch(
                setNotificationsOpenhouseDetailsModal({ id: item.listingId, isOpen: true }),
            );
        }
        break;
      // Applicant Accepted Request - Activity: My Tasks
      case 8:
        router.push(`/activity?id=${item.assistRequestId}&isMyActivity=${true}`);
        break;
      default:
        break;
    }
};
