import { createSlice } from '@reduxjs/toolkit';

import {
    getOpenHouseInfo,
    getOpenHouseInfoSuccess,
    getOpenHouseInfoError,
    getMyListings,
    getMyListingsSuccess,
    getMyListingsError,
    getMyClients,
    getMyClientsSuccess,
    getMyClientsError,
    updateListingTimeSlot,
    updateListingTimeSlotSuccess,
    updateListingTimeSlotError,
    deleteListingTimeSlot,
    deleteListingTimeSlotSuccess,
    deleteListingTimeSlotError,
    addListingTimeSlot,
    addListingTimeSlotSuccess,
    addListingTimeSlotError,
    recreateExternalTimeSlot,
    recreateExternalTimeSlotSuccess,
    recreateExternalTimeSlotError,
    getListingInfo,
    getListingInfoSuccess,
    getListingInfoError,
    getAgentOpenHouses,
    getAgentOpenHousesSuccess,
    getAgentOpenHousesError,
    getListingTypesRequest,
    getListingTypesSuccess,
    getListingTypesError,
    getAgencyListingsRequest,
    getAgencyListingsSuccess,
    getAgencyListingsError,
    getAgencyRosterRequest,
    getAgencyRosterSuccess,
    getAgencyRosterError,
    getAgencyCities,
    getAgencyCitiesSuccess,
    getAgencyCitiesError,
    selectMultipleTimeSlotsError,
    multipleTimeSlotsErrorClear,
    selectMultipleTimeSlotsRequest,
    selectMutlipleTimeSlotsSuccess,
} from '../actions/listings.actions';
import {
    OpenHouseInfoType,
    ListingsType,
    PersonCardType,
    ListingInfoType,
    PayloadError,
    IdNameTypes,
} from '../../common/types';
import { isAnyOf } from '../../common/utils';
import {
    getMyAgencyOpenHouses,
    getMyAgencyOpenHousesError,
    getMyAgencyOpenHousesSuccess,
    getPartnerAgencyOpenHouses,
    getPartnerAgencyOpenHousesError,
    getPartnerAgencyOpenHousesSuccess,
    startLoadMyListings,
} from '../../pages/HostOpenHouse/store/actions';
import { OpenHousesType } from '../../pages/HostOpenHouse/types';

type InitialState = {
    loading: boolean;
    myAgenciesLoading: boolean;
    myAgencyOpenHouses: Array<OpenHousesType>;
    myAgencyOpenHousesTotalCount: number;
    partnerAgenciesLoading: boolean;
    partnerAgencyOpenHousesTotalCount: number;
    partnerAgencyOpenHouses: Array<OpenHousesType>;
    errors: PayloadError[];
    openHouseInfo: {
        loading: boolean;
        errors: PayloadError[];
        value: OpenHouseInfoType;
    };
    myListings: {
        loading: boolean;
        errors: PayloadError[];
        active: ListingsType[];
        pending: ListingsType[];
        closed: ListingsType[];
    };
    myClients: {
        loading: boolean;
        errors: PayloadError[];
        items: PersonCardType[];
        loadMoreLoading: boolean;
        totalCount: number;
    };
    agencyListings: {
        loading: boolean;
        errors: PayloadError[];
        items: ListingsType[];
        totalCount: number;
        loadMoreLoading: boolean;
    };
    agencyRoster: {
        loading: boolean;
        errors: PayloadError[];
        items: PersonCardType[];
        totalCount: number;
        loadMoreLoading: boolean;
    };
    updateTimeSlot: {
        loading: boolean;
        id: number;
        errors: PayloadError[];
    };
    deleteTimeSlot: {
        loading: boolean;
        id: number;
    };
    addTimeSlot: {
        loading: boolean;
        errors: PayloadError[];
    };
    recreateExternalTimeSlot: {
        loading: boolean;
        errors: PayloadError[];
    };
    listingInfo: {
        loading: boolean;
        value: ListingInfoType;
        errors: PayloadError[];
    };
    agentOpenHouses: {
        items: ListingsType[];
        loading: boolean;
        totalCount: number;
        errors: PayloadError[];
    };
    listingTypes: {
        loading: boolean;
        errors: PayloadError[];
        items: IdNameTypes[];
    };
    agencyCities: {
        loading: boolean;
        items: string[];
        errors: PayloadError[];
    };
    multipleTimeSlotsSelect: {
        errors: PayloadError[];
        loading: boolean;
    };
};

const initialState: InitialState = {
    loading: false,
    myAgenciesLoading: false,
    myAgencyOpenHouses: [],
    myAgencyOpenHousesTotalCount: 0,
    partnerAgenciesLoading: false,
    partnerAgencyOpenHousesTotalCount: 0,
    partnerAgencyOpenHouses: [],
    errors: [],
    openHouseInfo: {
        loading: false,
        errors: [],
        value: {
            photos: [],
            userName: '',
            avatar: null,
            primaryPhoto: '',
            bathRoomCount: 0,
            badRoomCount: 0,
            area: 0,
            price: 0,
            listingDate: '',
            lastDateUpdate: '',
            type: '',
            state: '',
            longitude: '',
            latitude: '',
            city: '',
            stateCode: '',
            line: '',
            rapidApiPropertyId: '',
            agentMlsId: '',
            description: '',
            agentName: '',
            agentOfficeName: '',
            timeZone: '',
            postalCode: '',
            fipsCode: '',
            county: '',
            country: '',
            stories: 0,
            bathsFull: 0,
            bathshHalf: null,
            bedrooms: 0,
            beds: 0,
            garage: null,
            buildingAndConstructionsFeatureText: '',
            heatingAndCooling: '',
            roadFrontageType: '',
            landInfo: '',
            homeownersAssociation: '',
            schoolDistrict: '',
            otherPropertyInfo: '',
            utilities: '',
            listingTimeSlots: [],
        },
    },
    myListings: {
        loading: false,
        errors: [],
        active: [],
        pending: [],
        closed: [],
    },
    myClients: {
        loading: false,
        errors: [],
        items: [],
        loadMoreLoading: false,
        totalCount: 0,
    },
    agencyListings: {
        errors: [],
        items: [],
        loadMoreLoading: false,
        loading: false,
        totalCount: 0,
    },
    agencyRoster: {
        errors: [],
        items: [],
        loadMoreLoading: false,
        loading: false,
        totalCount: 0,
    },
    deleteTimeSlot: {
        id: 0,
        loading: false,
    },
    updateTimeSlot: {
        id: 0,
        loading: false,
        errors: [],
    },
    addTimeSlot: {
        loading: false,
        errors: [],
    },
    listingInfo: {
        loading: false,
        errors: [],
        value: {
            photos: [],
            avatar: null,
            primaryPhoto: '',
            bathRoomCount: 0,
            badRoomCount: 0,
            area: 0,
            price: 0,
            listingDate: '',
            lastDateUpdate: '',
            type: '',
            state: '',
            longitude: '',
            latitude: '',
            city: '',
            stateCode: '',
            line: '',
            rapidApiPropertyId: '',
            agentMlsId: '',
            description: '',
            agentName: '',
            agentOfficeName: '',
            timeZone: '',
            postalCode: '',
            fipsCode: '',
            county: '',
            country: '',
            stories: 0,
            bathsFull: 0,
            bathshHalf: null,
            bedrooms: 0,
            beds: 0,
            garage: null,
            buildingAndConstructionsFeatureText: '',
            heatingAndCooling: '',
            roadFrontageType: '',
            landInfo: '',
            homeownersAssociation: '',
            schoolDistrict: '',
            otherPropertyInfo: '',
            utilities: '',
            id: 0,
        },
    },
    agentOpenHouses: {
        errors: [],
        items: [],
        loading: false,
        totalCount: 0,
    },
    listingTypes: {
        loading: false,
        errors: [],
        items: [],
    },
    agencyCities: {
        errors: [],
        items: [],
        loading: false,
    },
    multipleTimeSlotsSelect: {
        errors: [],
        loading: false,
    },
    recreateExternalTimeSlot: {
        loading: false,
        errors: [],
    },
};

const listingsSlice = createSlice({
    name: 'listings',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getMyListings, (state, action) => {
                state.myListings.loading = true;
                state.myListings.errors = [];
            })
            .addCase(getMyListingsSuccess, (state, action) => {
                state.myListings.loading = false;
                state.myListings.active = action.payload.active;
                state.myListings.closed = action.payload.closed;
                state.myListings.pending = action.payload.pending;
                // state.myListings.totalCount = action.payload.totalCount;
                // state.myListings.items =
                //     action.payload.pageNumber === 1
                //         ? action.payload.listings
                //         : [...state.myListings.items, ...action.payload.listings];
            })
            .addCase(getMyListingsError, (state, action) => {
                state.myListings.loading = false;
                state.myListings.errors = action.payload;
            })

            .addCase(getMyClients, (state, action) => {
                if (action.payload.pageNumber === 1) {
                    state.myClients.loading = true;
                } else {
                    state.myClients.loadMoreLoading = true;
                }
                state.myClients.errors = [];
            })
            .addCase(getMyClientsSuccess, (state, action) => {
                state.myClients.loading = false;
                state.myClients.loadMoreLoading = false;
                state.myClients.totalCount = action.payload.totalCount;
                state.myClients.items =
                    action.payload.pageNumber === 1
                        ? action.payload.clients
                        : [...state.myClients.items, ...action.payload.clients];
            })
            .addCase(getMyClientsError, (state, action) => {
                state.myClients.loading = false;
                state.myClients.errors = action.payload;
            })
            .addCase(getAgencyListingsRequest, (state, action) => {
                if (action.payload.pageNumber === 1) {
                    state.agencyListings.loading = true;
                } else {
                    state.agencyListings.loadMoreLoading = true;
                }
                state.agencyListings.errors = [];
            })
            .addCase(getAgencyListingsSuccess, (state, action) => {
                state.agencyListings.totalCount = action.payload.totalCount;
                state.agencyListings.loading = false;
                state.agencyListings.loadMoreLoading = false;
                state.agencyListings.items =
                    action.payload.pageNumber === 1
                        ? action.payload.listings
                        : [...state.agencyListings.items, ...action.payload.listings];
            })
            .addCase(getAgencyListingsError, (state, action) => {
                state.agencyListings.errors = action.payload;
                state.agencyListings.loading = false;
                state.agencyListings.loadMoreLoading = false;
            })

            .addCase(getAgencyRosterRequest, (state, action) => {
                if (action.payload.pageNumber === 1) {
                    state.agencyRoster.loading = true;
                } else {
                    state.agencyRoster.loadMoreLoading = true;
                }
                state.agencyRoster.errors = [];
            })
            .addCase(getAgencyRosterSuccess, (state, action) => {
                state.agencyRoster.totalCount = action.payload.totalCount;
                state.agencyRoster.loading = false;
                state.agencyRoster.loadMoreLoading = false;
                state.agencyRoster.items =
                    action.payload.pageNumber === 1
                        ? action.payload.users
                        : [...state.agencyRoster.items, ...action.payload.users];
            })
            .addCase(getAgencyRosterError, (state, action) => {
                state.agencyRoster.errors = action.payload;
                state.agencyRoster.loading = false;
                state.agencyRoster.loadMoreLoading = false;
            })

            .addCase(getOpenHouseInfo, state => {
                state.openHouseInfo.errors = [];
                state.openHouseInfo.loading = true;
            })
            .addCase(getOpenHouseInfoSuccess, (state, action) => {
                state.openHouseInfo.loading = false;
                state.openHouseInfo.value = action.payload.value.openHouse;
            })
            .addCase(getOpenHouseInfoError, (state, action) => {
                state.openHouseInfo.loading = false;
                state.openHouseInfo.errors = action.payload;
            })
            .addCase(updateListingTimeSlot, (state, action) => {
                state.updateTimeSlot.id = action.payload.id;
                state.updateTimeSlot.loading = true;
                state.updateTimeSlot.errors = [];
            })
            .addCase(updateListingTimeSlotSuccess, (state, action) => {
                state.updateTimeSlot.id = 0;
                state.updateTimeSlot.loading = false;
            })
            .addCase(updateListingTimeSlotError, (state, action) => {
                state.updateTimeSlot.id = 0;
                state.updateTimeSlot.loading = false;
                state.updateTimeSlot.errors = action.payload;
            })
            .addCase(deleteListingTimeSlot, (state, action) => {
                state.deleteTimeSlot.id = action.payload.id;
                state.deleteTimeSlot.loading = true;
            })
            .addCase(deleteListingTimeSlotSuccess, (state, action) => {
                state.deleteTimeSlot.id = 0;
                state.deleteTimeSlot.loading = false;
                state.openHouseInfo.value.listingTimeSlots =
                    state.openHouseInfo.value.listingTimeSlots.filter(
                        slot => slot.id !== action.payload.id,
                    );
            })
            .addCase(deleteListingTimeSlotError, state => {
                state.deleteTimeSlot.id = 0;
                state.deleteTimeSlot.loading = false;
            })
            .addCase(addListingTimeSlot, state => {
                state.addTimeSlot.errors = [];
                state.addTimeSlot.loading = true;
            })
            .addCase(addListingTimeSlotSuccess, (state, action) => {
                console.log(action.payload, 'addListingTimeSlotSuccess action');
                state.addTimeSlot.loading = false;
                // state.myListings.items = state.myListings.items.map(elem => {
                //     if (elem.id === action.payload) {
                //         return { ...elem, isOpenHouse: true };
                //     } else {
                //         return elem;
                //     }
                // });
            })
            .addCase(addListingTimeSlotError, (state, action) => {
                state.addTimeSlot.errors = action.payload;
                state.addTimeSlot.loading = false;
            })
            .addCase(recreateExternalTimeSlot, state => {
                state.addTimeSlot.errors = [];
                state.addTimeSlot.loading = true;
            })
            .addCase(recreateExternalTimeSlotSuccess, (state, action) => {
                console.log(action.payload, 'recreateExternalTimeSlotSuccess action');
                state.addTimeSlot.loading = false;
            })
            .addCase(recreateExternalTimeSlotError, (state, action) => {
                state.addTimeSlot.errors = action.payload;
                state.addTimeSlot.loading = false;
            })
            .addCase(getListingInfo, state => {
                state.listingInfo.loading = true;
                state.listingInfo.errors = [];
            })
            .addCase(getListingInfoSuccess, (state, action) => {
                state.listingInfo.loading = false;
                state.listingInfo.value = action.payload;
            })
            .addCase(getListingInfoError, (state, action) => {
                state.listingInfo.loading = false;
                state.listingInfo.errors = action.payload;
            })
            .addCase(getAgentOpenHouses, state => {
                state.agentOpenHouses.errors = [];
                state.agentOpenHouses.loading = true;
            })
            .addCase(getAgentOpenHousesSuccess, (state, action) => {
                state.agentOpenHouses.items = action.payload.listings;
                state.agentOpenHouses.loading = false;
                state.agentOpenHouses.totalCount = action.payload.totalCount;
            })
            .addCase(getAgentOpenHousesError, (state, action) => {
                state.agentOpenHouses.errors = action.payload;
                state.agentOpenHouses.loading = false;
            })
            .addCase(getListingTypesRequest, state => {
                state.listingTypes.loading = true;
                state.listingTypes.errors = [];
            })
            .addCase(getListingTypesSuccess, (state, action) => {
                state.listingTypes.loading = false;
                state.listingTypes.items = action.payload.types;
            })
            .addCase(getListingTypesError, (state, action) => {
                state.listingTypes.loading = false;
                state.listingTypes.errors = action.payload;
            })
            .addCase(getAgencyCities, state => {
                state.agencyCities.loading = true;
                state.agencyCities.errors = [];
            })
            .addCase(getAgencyCitiesSuccess, (state, action) => {
                state.agencyCities.loading = false;
                state.agencyCities.items = action.payload;
            })
            .addCase(getAgencyCitiesError, (state, action) => {
                state.agencyCities.loading = false;
                state.agencyCities.errors = action.payload;
            })
            .addCase(startLoadMyListings, state => {
                state.loading = true;
            })
            .addCase(getMyAgencyOpenHouses, state => {
                state.myAgenciesLoading = true;
                state.myAgencyOpenHouses = [];
                state.errors = [];
            })
            .addCase(getMyAgencyOpenHousesSuccess, (state, action) => {
                state.loading = false;
                state.myAgenciesLoading = false;
                state.errors = [];
                state.myAgencyOpenHousesTotalCount = action.payload.result.value.totalCount;
                state.myAgencyOpenHouses =
                    action.payload.pageNumber === 1
                        ? action.payload.result.value.openHouses
                        : [...state.myAgencyOpenHouses, ...action.payload.result.value.openHouses];
            })
            .addCase(getMyAgencyOpenHousesError, (state, action) => {
                state.loading = false;
                state.myAgenciesLoading = false;
                state.errors = action.payload;
            })
            .addCase(getPartnerAgencyOpenHouses, state => {
                state.partnerAgenciesLoading = true;
                state.partnerAgencyOpenHouses = [];
                state.errors = [];
            })
            .addCase(getPartnerAgencyOpenHousesSuccess, (state, action) => {
                state.partnerAgenciesLoading = false;
                state.errors = [];
                state.partnerAgencyOpenHousesTotalCount = action.payload.result.value.totalCount;
                state.partnerAgencyOpenHouses =
                    action.payload.pageNumber === 1
                        ? action.payload.result.value.openHouses
                        : [
                              ...state.partnerAgencyOpenHouses,
                              ...action.payload.result.value.openHouses,
                          ];
            })
            .addCase(getPartnerAgencyOpenHousesError, (state, action) => {
                state.partnerAgenciesLoading = false;
                state.errors = action.payload;
            })
            .addCase(selectMultipleTimeSlotsRequest, state => {
                state.multipleTimeSlotsSelect.errors = [];
                state.multipleTimeSlotsSelect.loading = true;
            })

            .addCase(selectMultipleTimeSlotsError, (state, action) => {
                state.multipleTimeSlotsSelect.errors = action.payload;
                state.multipleTimeSlotsSelect.loading = false;
            })
            .addMatcher(
                isAnyOf(selectMutlipleTimeSlotsSuccess, multipleTimeSlotsErrorClear),
                state => {
                    state.multipleTimeSlotsSelect.errors = [];
                    state.multipleTimeSlotsSelect.loading = false;
                },
            );
        // .addMatcher(isAnyOf(updateListingTimeSlotSuccess, updateListingTimeSlotError), state => {
        //   state.updateTimeSlot.id = 0;
        //   state.updateTimeSlot.loading = false;
        // });
    },
});

export default listingsSlice.reducer;
