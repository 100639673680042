import React, { Dispatch, SetStateAction } from 'react';
import { Box, Center, Text, Flex, WrapItem, Image } from '@chakra-ui/react';

import { PostBox } from '../components';
import { InfiniteScroll, Loader } from '../../../common/components';

import { AddReportPayload, PostTypeResponse } from '../types';
import { IdNameTypes } from '../../../common/types';

import BlastEmpty from '../../../../src/assets/img/BlastEmpty.png';
import { fonts } from '../../../common/utils';
import { INFINITE_SCROLL_HEIGHT } from '../../../common/constants';

interface IPostTabProps {
    postsLoading: boolean;
    posts: PostTypeResponse[];
    reportTypes: IdNameTypes[];
    onDeletePost: (id: number) => void;
    onAddReport: (payload: AddReportPayload) => void;
    setEditPostId: Dispatch<SetStateAction<number>>;
    setDetailsPostId: Dispatch<SetStateAction<number>>;
    setReportPostId?: Dispatch<SetStateAction<number>>;
    setReportTypeId?: Dispatch<SetStateAction<number>>;
}

export const PostTab: React.FC<IPostTabProps> = ({
    posts,
    postsLoading,
    reportTypes,
    onDeletePost,
    onAddReport,
    setEditPostId,
    setDetailsPostId,
    setReportPostId,
    setReportTypeId,
}) => (
    <>
        {postsLoading ? (
            <Loader spinnerSize={'md'} centerHeight={INFINITE_SCROLL_HEIGHT} />
        ) : (
            <Box mt={'32px'}>
                <InfiniteScroll height={INFINITE_SCROLL_HEIGHT}>
                    {posts.length ? (
                        <>
                            <Text
                                mb={'19px'}
                                fontSize={'16px'}
                                fontWeight={'400'}
                                lineHeight={'25px'}
                                color={'#2C335C'}
                                pl="5px"
                            >
                                Posts
                            </Text>
                            <Flex flexDirection="row" flexWrap="wrap">
                                {posts.map(item => (
                                    <WrapItem key={item.id} p="5px" className="post_box_outer">
                                        <PostBox
                                            setReportPostId={setReportPostId}
                                            setReportTypeId={setReportTypeId}
                                            setDetailsPostId={setDetailsPostId}
                                            setEditPostId={setEditPostId}
                                            item={item}
                                            reportTypes={reportTypes}
                                            onAddReport={onAddReport}
                                            onDeletePost={onDeletePost}
                                        />
                                    </WrapItem>
                                ))}
                            </Flex>
                        </>
                    ) : (
                        <Center h="100%">
                            <Flex
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Image
                                    width="120px"
                                    height="120px"
                                    src={BlastEmpty}
                                    alt="Blast empty"
                                    mb="30px"
                                />
                                <Text
                                    fontFamily={fonts.poppins}
                                    color="secondaryFontDarker"
                                    fontSize="16px"
                                    fontWeight={500}
                                    lineHeight="24px"
                                    letterSpacing="0.19px"
                                >
                                    There are no saved posts yet
                                </Text>
                            </Flex>
                        </Center>
                    )}
                </InfiniteScroll>
            </Box>
        )}
    </>
);
