import React from 'react';

import icon from '../../../src/assets/img/clients.svg';

export const ClientsIcon: React.FC = () => (
    <img style={{width: '25px', height: '25px'}} src={icon} />
);

export const ClientsIconV2: React.FC<{isActive?: boolean}> = ({isActive = false}) => (
    <div className={'icon_fix '+ (isActive? 'active' : '')} 
        style={{width: "25px", height: '25px', maskImage: "url("+icon+")"  }}
    />
);
