import React, { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Flex,
    Button,
    CloseButton,
} from '@chakra-ui/react';
import axios from 'axios';
import { CustomButton, Loader } from '..';
import { useSelector } from 'react-redux';
import { dispatch, RootStore } from '../../../store';
import { useHistory } from 'react-router-dom';
import { colors } from '../../constants';
import { BackArrow } from '../../../assets/icons/BackArrow';
import { fonts } from '../../utils/fonts';
import { getUserInfoRequest } from '../../../store/actions/user.actions';
import _ from 'lodash';

interface ICancelSubscribeModal {
    isOpen: boolean;
    onClose: () => void;
		emitSubmited?: () => void;
}

export const CancelSubscribeModal: React.FC<ICancelSubscribeModal> = ({ isOpen, onClose, emitSubmited }) => {
    const handleClose = () => {
        onClose();
    };

		const [loading, SetLoagin] = useState(false);
		
    const toast = useToast();
    const history = useHistory();
    const { user: currentUser } = useSelector((state: RootStore) => state.user);
    const [loader, setLoader] = useState<boolean>(false);
    const [LoaderBtn, setLoaderBtn] = useState<boolean>(false);

    const cancelSubscribePlan = async () => {
      if(loader){
        return;
      }
      setLoader(true);
  
      await axios.post(window.baseUrl+'/api/stripe/ext/cancelSubscribePlan')
      .then(res => {
        setLoader(false);
        toast({
          title: 'Your subscribe successfully canceled',
          status: 'info',
          isClosable: true,
        });

        dispatch(getUserInfoRequest());
        handleClose();

        if(emitSubmited){
          emitSubmited();
        }
      }).catch((error: any) => {
        setLoader(false);
        let status = error.response.status;
        
        if(status === 422){
          toast({
            title: error.response.data.error,
            status: 'error',
            isClosable: true,
          });
          return;
        }
      });
    }

		const onSubmit = async () => {
      setLoader(true);
      await axios.post(window.baseUrl+'/api/subscribe/cancel')
        .then(() => {
          setLoader(false);
          toast({
            title: 'Your subscribe successfully canceled',
            status: 'info',
            isClosable: true,
          });

					dispatch(getUserInfoRequest());
					handleClose();

          if(emitSubmited){
            emitSubmited();
          }
        }).catch((error: any) => {
          setLoader(false);
          let status = error.response.status;

          if(status === 422){
            toast({
              title: error.response.data.error,
              status: 'error',
              isClosable: true,
            });
            return;
          }
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent
                w={'600px'}
                borderRadius={'18px'}
                h={'auto'}
                bgColor={colors.modalBackground}
                alignItems="center"
                paddingBottom="20px"
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    px="30px"
                    pt="10px"
                >
                    <Box as="button" onClick={onClose}>
                        <BackArrow />
                    </Box>
                    <ModalHeader flex={1} align="center">
                        <Text
                            fontSize="22px"
                            color="brand"
                            textAlign="center"
                            lineHeight="18px"
                            fontWeight={700}
                            fontFamily={fonts.montserrat}
                        >
                          Cancel Plan
                        </Text>
                    </ModalHeader>
                    <CloseButton onClick={onClose} />
                </Flex>
                <ModalBody w="100%" d="flex" flexDirection="column" overflowY="auto">
									<br />
									<p>Your current plan will be deactivated</p>
                  <br />
									<CustomButton
										text="Cancel"
										bgColor="brand"
										maxWidth="200px"
										height="50px"
										alignSelf="center"
										justifySelf="end"
										onClick={cancelSubscribePlan}
										isLoading={loader}
									/>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
