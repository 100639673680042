import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { RateType, StateDirectoryItem } from '../../../../types';
import { fonts } from '../../../../utils';
import { colors } from '../../../../../common/constants/layout.constants';

interface IPricingSummaryItemProps {
    rate: RateType;
    states: StateDirectoryItem[];
}

export const PricingSummaryItem: React.FC<IPricingSummaryItemProps> = (
    {rate: { agency, pricePerDoor, pricePerHour, state}, states}) => {

    const rates = [
        {
            title: 'Price per hour:',
            value: pricePerHour
        },
        {
            title: 'Price per door:',
            value: pricePerDoor
        }
    ]

    return (
        <Box>
            <Flex justifyContent="flex-start" alignItems="center" flexWrap="wrap" mt="32px" mb="27px">
                <Text
                    fontSize="16px"
                    lineHeight="24px"
                    fontWeight="400"
                    color={colors.brand}
                    fontFamily={fonts.poppins}
                    marginRight="10px"
                >
                    {state}
                </Text>
                <Text
                    fontSize="14px"
                    lineHeight="21px"
                    fontWeight="300"
                    color={colors.brand}
                    fontFamily={fonts.poppins}
                >
                    ({agency})
                </Text>
            </Flex>
            <Box borderBottom="1px solid #D1D2DB" pb="22px">
            {rates.map(rate => (
                <Flex alignItems="center" key={rate.title}>
                    <Text
                        color={colors.brand}
                        fontSize="14px"
                        lineHeight="21px"
                        fontWeight="300"
                        fontFamily={fonts.poppins}
                        mb="10px"
                        mr="10px"
                    >
                        {rate.title}
                    </Text>
                    <Text
                        color={colors.brand}
                        fontSize="16px"
                        lineHeight="24px"
                        fontWeight="400"
                        fontFamily={fonts.poppins}
                        mb="10px"
                        >
                        ${rate.value}
                    </Text>
                </Flex>
            ))}
            </Box>
        </Box>
)};
