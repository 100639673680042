import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitHandler } from 'react-hook-form';
import { login } from '../../../store/actions/auth/auth.actions';
import { RootStore } from '../../../store';

import { IAuthContainerProps, LoginPayload } from '../../../common/types';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { SignInNew } from '../SignIn/SignInNew';

export const SignInNewContainer: React.FC<IAuthContainerProps> = ({ nestedPath }) => {
    //const loading = useSelector((state: RootStore) => state.auth.loading);
    const history = useHistory();

    const dispatch = useDispatch();
    const [loader, setLoader] = useState<boolean>(false);

    const onSubmit = (payload: any) => {
        checkLogin(payload);
    };

    const checkLogin = async (payload: any) => {
        setLoader(true);

        await axios.post(window.baseUrl+'/api/auth/checkemail', {
            email: payload.email
        })
        .then((res: any) => {
          setLoader(false);
          history.push('/auth/signin/login?email='+payload.email);
        }).catch(error => {
          setLoader(false);
          history.push('/auth/check/emailcode?email='+payload.email);
        });
    };

    return <SignInNew loading={loader} onSubmit={onSubmit} nestedPath={nestedPath} />;

    /*
    return <SignIn loading={loading} onSubmit={onSubmit} nestedPath={nestedPath} />;
    */
};
