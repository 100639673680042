import React, { Dispatch, SetStateAction } from 'react';

import { Box, Button, Flex, HStack, Text, VStack } from '@chakra-ui/react';

import {
    CustomButton,
    CustomDropdown,
    CustomInput,
    RequestQuickInfo,
    ReportIssueButton,
} from '../../../../../common/components';
import { Typography } from '../../../../../common/components/blocks/Typography/Typography';

import { colors, yesOrNo } from '../../../../../common/constants';

import { fonts, getFeePrice } from '../../../../../common/utils';

import { IdNameTypes } from '../../../../../common/types';
import { RequestInfoType } from '../../../../Requests/types';
import { FinishWorkPayload } from '../../../types';

interface IOpportunitiesActivityInfo {
    bonusTypes: IdNameTypes[];
    clientTypes: IdNameTypes[];
    requestInfo: RequestInfoType;
    setToCancelWorkId: (value: number) => void;
    setToFinishWorkId: (value: number) => void;
    onOpenIssue: () => void;
    isMyActivity?: boolean;
    setConfirmApplicantModalOpen: Dispatch<SetStateAction<boolean>>;
    onConfirmRefferalActivity: () => void;
    onFinishWork: (data: FinishWorkPayload) => void;
}

export const OpportunitiesActivityInfo: React.FC<IOpportunitiesActivityInfo> = ({
    requestInfo,
    clientTypes,
    bonusTypes,
    setToCancelWorkId,
    setToFinishWorkId,
    onOpenIssue,
    isMyActivity,
    setConfirmApplicantModalOpen,
    onConfirmRefferalActivity,
    onFinishWork,
}) => {
    const fee = requestInfo.totalPrice ? getFeePrice(requestInfo.totalPrice) : 0;
    const estimatedCoast = requestInfo.totalPrice ? requestInfo.totalPrice + fee : 0;

    const handleFinish = () => {
        requestInfo.typeId === 7
            ? onFinishWork({
                  requestAddresses: [],
                  requestId: requestInfo.id,
                  duration: 0,
              })
            : setToFinishWorkId(requestInfo.id);
    };

    const handleReady = () => {
        requestInfo.typeId === 7 ? onConfirmRefferalActivity() : setConfirmApplicantModalOpen(true);
    };

    return (
        <Flex flexDirection={'column'} h={'full'} py={'30px'}>
            {/*Main Box*/}
            <Flex justifyContent="space-between">
                <Box w={'47%'}>
                    {requestInfo.typeId !== 7 ? (
                        <RequestQuickInfo
                            bg={colors.white}
                            margin={0}
                            date={requestInfo.dateTime}
                            fromTime={requestInfo.fromTime}
                            duration={requestInfo.duration}
                            propertiesToShow={requestInfo.propertiesToShow}
                            requestTypeName={requestInfo.requestTypeName}
                            totalPrice={requestInfo.totalPrice || 0}
                            isShowTotalPrice={false}
                        />
                    ) : (
                        <VStack align={'stretch'} bg="white" borderRadius="24px" padding="20px">
                            <Typography size={14} color={'#888DAB'}>
                                State
                            </Typography>
                            <Typography size={14} fontFamily={fonts.montserrat} weight={600}>
                                {`${requestInfo.states.join('')}`}
                            </Typography>
                            <Typography size={14} color={'#888DAB'}>
                                Agencies
                            </Typography>
                            {requestInfo.agencies.map(agency => (
                                <Typography
                                    key={agency}
                                    size={14}
                                    fontFamily={fonts.montserrat}
                                    weight={600}
                                >
                                    {agency}
                                </Typography>
                            ))}
                        </VStack>
                    )}
                    <Box mt={6}>
                        {requestInfo.states &&
                            requestInfo.typeId !== 7 &&
                            requestInfo.states.map((state: string) => (
                                <CustomInput
                                    bgColor={colors.white}
                                    key={state}
                                    placeholder="State"
                                    label="State"
                                    register={{ value: state }}
                                    isBorder={false}
                                    isReadOnly
                                    margin="0 0 26px 0"
                                />
                            ))}
                        {requestInfo.addresses && requestInfo.addresses.length !== 0 ? (
                            <>
                                <Typography fontFamily={fonts.montserrat} size={18} weight={700}>
                                    {requestInfo.typeId === 1 ? 'Addresses' : 'Address'}
                                </Typography>
                                {requestInfo.addresses.map(item => (
                                    <CustomInput
                                        bgColor={colors.white}
                                        key={item}
                                        register={{ value: item }}
                                        isBorder={false}
                                        isReadOnly
                                        margin="0 0 11px 0"
                                    />
                                ))}
                            </>
                        ) : null}
                        {requestInfo.generalNote ? (
                            <>
                                <Typography fontFamily={fonts.montserrat} size={18} weight={700}>
                                    Description:
                                </Typography>
                                <Text
                                    color="secondaryFontDarker"
                                    fontWeight={400}
                                    fontSize="16px"
                                    mt={2}
                                    lineHeight="26px"
                                    fontFamily={fonts.poppins}
                                >
                                    {requestInfo.generalNote}
                                </Text>
                            </>
                        ) : null}
                    </Box>
                </Box>
                <Box w={'47%'}>
                    <Typography fontFamily={fonts.montserrat} size={18} weight={700}>
                        More details:
                    </Typography>
                    {requestInfo.typeId === 1 ? (
                        <>
                            <CustomInput
                                bgColor={colors.white}
                                label={`Is The ${requestInfo.requestTypeName} Scheduled?`}
                                register={{ value: requestInfo.isShowingScheduled ? 'Yes' : 'No' }}
                                isBorder={false}
                                isReadOnly
                                margin="0 0 11px 0"
                            />
                            {requestInfo.wouldLikeToOfferBonus ? (
                                <>
                                    <CustomDropdown
                                        label="I would like to offer a Bonus if a contract is ratified from the showing list within 48-hours after the showing:"
                                        options={yesOrNo}
                                        register={{
                                            value: requestInfo.wouldLikeToOfferBonus ? 'Yes' : 'No',
                                            isReadOnly: true,
                                            pointerEvents: 'none',
                                        }}
                                        errors=""
                                        margin="0 0 10px 0"
                                        bgColor="white"
                                    />
                                    <Flex justifyContent={'space-between'}>
                                        <CustomDropdown
                                            register={{
                                                value: requestInfo.bonusTypeId,
                                                isReadOnly: true,
                                                pointerEvents: 'none',
                                            }}
                                            options={bonusTypes.map((elem, index) => ({
                                                label: elem.name,
                                                value: elem.id,
                                                id: index,
                                            }))}
                                            label="Bonus Type"
                                            errors=""
                                            margin="0 0 10px 0"
                                            bgColor="white"
                                            width={'47%'}
                                        />
                                        <CustomInput
                                            width={'47%'}
                                            label="Bonus"
                                            register={{
                                                value: requestInfo.amount?.toString(),
                                                isReadOnly: true,
                                            }}
                                            margin="0 0 10px 0"
                                            bgColor="white"
                                        />
                                    </Flex>
                                </>
                            ) : null}
                        </>
                    ) : null}
                    {requestInfo.clientTypeId ? (
                        <CustomInput
                            label="The Client is:"
                            value={
                                clientTypes.filter(it => it.id === requestInfo.clientTypeId)[0]
                                    ?.name
                            }
                            margin="0 0 10px 0"
                            isReadOnly
                            bgColor="white"
                        />
                    ) : null}
                    {requestInfo.referralClient.name ? (
                        <CustomInput
                            label="Client Name:"
                            value={requestInfo.referralClient.name}
                            margin="0 0 10px 0"
                            isReadOnly
                            bgColor="white"
                        />
                    ) : null}
                    {requestInfo.referralClient.phone ? (
                        <CustomInput
                            label="Client Phone Number"
                            value={requestInfo.referralClient.phone}
                            margin="0 0 10px 0"
                            isReadOnly
                            bgColor="white"
                            type={'tel'}
                        />
                    ) : null}
                    {requestInfo.privateNote ? (
                        <CustomInput
                            label="Private Notes To The Chosen Agent:"
                            value={requestInfo.privateNote}
                            margin="0 0 10px 0"
                            isReadOnly
                            bgColor="white"
                        />
                    ) : null}
                </Box>
            </Flex>
            <Flex alignItems={'flex-end'} justifyContent={'space-between'} mt={'auto'}>
                <Box w={'47%'}>
                    {requestInfo.typeId !== 7 && isMyActivity ? (
                        <HStack justifyContent={'space-between'} mb={'35px'}>
                            <Typography size={13} weight={600}>
                                Estimated Earnings:
                            </Typography>
                            <Typography size={19} weight={500}>
                                ${requestInfo.totalPrice}
                            </Typography>
                        </HStack>
                    ) : null}
                    {requestInfo.typeId !== 7 && !isMyActivity ? (
                        <Box mb={'35px'}>
                            <HStack justifyContent={'space-between'}>
                                <Typography size={13}>Offer Price:</Typography>
                                <Typography size={13}>${requestInfo.totalPrice ?? 0}</Typography>
                            </HStack>
                            <HStack justifyContent={'space-between'}>
                                <Typography size={13}>Fee:</Typography>
                                <Typography size={13}>${fee}</Typography>
                            </HStack>
                            <HStack justifyContent={'space-between'}>
                                <Typography size={13} weight={600}>
                                    Estimated Cost:
                                </Typography>
                                <Typography size={13} weight={600}>
                                    ${estimatedCoast}
                                </Typography>
                            </HStack>
                        </Box>
                    ) : null}

                    {requestInfo.isWorkConfirmed? '' : (
                    <HStack justifyContent={'space-between'}>
                        <ReportIssueButton
                            onClick={onOpenIssue}
                            w={isMyActivity ? '45%' : '100%'}
                        />
                        {(isMyActivity && !requestInfo.isWorkFinished)? (
                            <Button
                                variant={'outlinePrimary'}
                                w={'45%'}
                                onClick={() => setToCancelWorkId(requestInfo.id)}
                            >
                                Cancel
                            </Button>
                        ) : null}
                    </HStack>
                    )}
                </Box>

                {requestInfo.isWorkConfirmed? '' : (
                <Box w={'47%'}>
                    {isMyActivity ? (
                        <>
                        {!requestInfo.isWorkFinished && (
                            <Button isFullWidth variant={'primary'} onClick={handleFinish} className="OPORTUNITY_SHOWING">
                                Finish
                            </Button>
                        )}
                        </>
                    ) : !isMyActivity && requestInfo.isWorkFinished && !requestInfo.isWorkConfirmed ? (
                        <CustomButton
                            height="60px"
                            bg={'#4FD1C5'}
                            onClick={handleReady}
                            text="Ready"
                            color={colors.white}
                        />
                    ) : (requestInfo.isWorkConfirmed)? "" : (
                        <Button
                            isFullWidth
                            variant={'outlinePrimary'}
                            onClick={() => setToCancelWorkId(requestInfo.id)}
                        >
                            Cancel
                        </Button>
                    )}
                </Box>
                )}
            </Flex>
        </Flex>
    );
};
