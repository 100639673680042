import React from 'react';
import { useRouteMatch } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import { MatchParams } from '../../../common/types';

import { AuthWrapper } from '../../../common/components';
import { ISignInRoutingProps } from '../SignIn/types';
import { SignInContainer } from '../SignIn/SignIn.container';
import { SignInEnterNewPasswordContainer } from '../SignIn/components/SignInEnterNewPassword/SignInEnterNewPasswordContainer';
import { SignInEnterCodeContainer } from '../SignIn/components/SignInEnterCode/SignInEnterCodeContainer';
import { SignInEnterEmailContainer } from '../SignIn/components/SignInEnterEmail/SignInEnterEmailContainer';
import { SignInNewContainer } from './SignInNew.container';
import { SignUpForm } from '../SignUp/components/SignUpForm/SignUpForm';
import { EmailCodeForm } from './EmailCodeForm';

export const CheckRouting: React.FC<ISignInRoutingProps> = ({ nestedPath }) => {
    const match = useRouteMatch<MatchParams>();
    
    return (
        <Switch>
            <Route
                path={`${match.url}/email`}
                component={() => (
                    <AuthWrapper
                        isSignIn
                        child={<SignInNewContainer nestedPath={nestedPath} />}
                    />
                )}
            />
            <Route
                path={`${match.url}/emailcode`}
                component={() => (
                    <AuthWrapper
                        isSignIn
                        child={<EmailCodeForm />}
                    />
                )}
            />
            <Redirect from="*" exact={true} to={`${match.url}/email`} />
        </Switch>
    );
};
