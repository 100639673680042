import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ListingItem } from './ListingItem';
import { ListingsType, OpenHouseDetailsModalOpen } from '../../../common/types';

interface IListingSectionProps {
    name: string;
    items: ListingsType[];
    onOpenModal: OpenHouseDetailsModalOpen;
    onScheduleOpenHouse: (value: number) => void;
}

export const ListingSection: React.FC<IListingSectionProps> = ({
    items,
    name,
    onOpenModal,
    onScheduleOpenHouse,
}) => {
    return items.length ? (
        <Box>
            <Text
                color="brand"
                fontSize="18px"
                fontWeight={600}
                textTransform="uppercase"
                mt="20px"
            >
                {name}
            </Text>
            <Box p="16px 0 36px 0" borderBottom="1px solid #D1D2DB">
                {items.map(elem => (
                    <div className="MY_LISTING_OUTER" key={elem.id}>
                        <ListingItem
                            key={elem.id}
                            {...elem}
                            onOpenModal={value => onOpenModal(value, elem, true, false)}
                            onOpenManage={value => onOpenModal(value, elem, true, true)}
                            isMyListings={true}
                            onScheduleOpenHouse={onScheduleOpenHouse}
                        />
                    </div>
                ))}
            </Box>
        </Box>
    ) : null;}
