import React, { useEffect, useState } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { AvailableOptionsOverview } from './AvailableOptionsOverview';
import { SubscriptionStatus } from './SubscriptionStatus';
import { FreeTrial } from './FreeTrial';
import { BackButton, Loader } from '../../../../common/components';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { getCurrentSubscriptionRequest, getSubscriptionsHistoryRequest } from './store/actions';
import { useHistory } from 'react-router-dom';
import { InfoIcon } from '@chakra-ui/icons';

export const Subscription: React.FC = () => {
    const dispatch = useDispatch();

    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => {
        dispatch(getCurrentSubscriptionRequest());
        dispatch(getSubscriptionsHistoryRequest());
    }, []);

    const { value: currentSubscription, loading: currentSubLoading } = useSelector(
        (state: RootStore) => state.subscriptions.current,
    );
    const { items, loading: historyLoading } = useSelector(
        (state: RootStore) => state.subscriptions.history,
    );

    const { user: currentUser } = useSelector((state: RootStore) => state.user);

    const getBlock = () => {
        if(!currentUser){
            return (<></>);
        }
        if(!currentUser.id){
            return (<></>);
        }

        console.log('1111111', currentUser);

        if(!currentUser.is_id_license_verified){
            return (
                <div className="message_not_approved">
                    <InfoIcon marginRight="10px" />
                    Your account has not been approved. Please wait for an administrator to approve your account!
                </div>
            );
        }

        if (!currentSubscription.isSubscribed || showOptions) {
            return <AvailableOptionsOverview />;
        }
        if (currentSubscription.isSubscribed && currentSubscription.subscriptionType !== null) {
            return (
                <SubscriptionStatus
                    items={items}
                    loading={historyLoading}
                    subscribedTo={currentSubscription.subscribedTo}
                    type={currentSubscription.subscriptionType?.name}
                />
            );
        }
        if (currentSubscription.isSubscribed && currentSubscription.subscriptionType === null) {
            return (
                <FreeTrial
                    subscribedTo={currentSubscription.subscribedTo}
                    onUpgrade={() => setShowOptions(true)}
                />
            );
        }

        return null;
    };

    const history = useHistory();

    return (
        <Box h="80vh">
            <HStack mb="16px">
                <BackButton mb="0" text="" onClick={history.goBack} />
                <Text fontSize="24px" lineHeight="25px" color="brand" fontWeight="bold">
                    Subscription
                </Text>
            </HStack>
            {currentSubLoading ? <Loader centerHeight="500px" /> : getBlock()}
        </Box>
    );
};
