import { call, put, takeEvery } from 'redux-saga/effects';
import API from '../../common/api/api.base';
import {
    sendCooperationDetailRequest,
    sendCooperationDetailSuccess,
    sendCooperationDetailError,
    sendContactForm,
    sendContactFormError,
    sendContactFormSuccess,
    sendHelpRequest,
    sendHelpRequestSuccess,
    sendHelpRequestError,
} from '../actions/landing.actions';
import { IResponseWithoutValue } from '../../common/types';

function* workerSendCooperationDetail(action: ReturnType<typeof sendCooperationDetailRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            '/api/send-agency-cooperatoin-detail',
            action.payload,
        );
        if (result.success) {
            yield put(sendCooperationDetailSuccess());
        } else {
            yield put(sendCooperationDetailError(result.errors));
        }
    } catch (error) {
        console.log(error, 'workerSendCooperationDetail error');
    }
}

function* workerSendContactForm(action: ReturnType<typeof sendContactForm>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            '/api/contact-us',
            action.payload,
        );

        if (result.success) {
            yield put(sendContactFormSuccess());
        } else {
            yield put(sendContactFormError(result.errors));
        }
    } catch (error) {
        console.log('sendContactFormError', error);
    }
}

function* workerSendHelpRequest(action: ReturnType<typeof sendHelpRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            `/api/send-help-request`,
            action.payload
        );

        if (result.success) {
            yield put(sendHelpRequestSuccess());
        } else {
            yield put(sendHelpRequestError(result.errors));
        }
    } catch (error) {
        console.log('sendHelpRequestError', error);
    }
}

export default function* watchLandingSaga() {
    yield takeEvery(sendCooperationDetailRequest.type, workerSendCooperationDetail);
    yield takeEvery(sendContactForm.type, workerSendContactForm);
    yield takeEvery(sendHelpRequest.type, workerSendHelpRequest);
}
