type getFeePriceType = (price: number | string) => number;

export const getFeePrice: getFeePriceType = price => {
    const numberPrice = Number(price);

    if (Number.isNaN(numberPrice)) {
        throw new Error(`Invalid price: ${price}`);
    }

    return Number((numberPrice * 0.105).toFixed(2));
};
