import React, { useEffect, useState } from 'react';
import { DashboardWrapper } from '../../common/components/blocks/DashboardWrapper/DashboardWrapper';
import SidebarContainer from '../Sidebar/Sidebar.container';
import CalendarContainer from '../Calendar/Calendar.container';
import { getUserInfoRequest } from '../../store/actions/user.actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../store';
import { getCustomerAccountStatusRequest } from '../../pages/Settings/pages/Payments/store/actions';
import { IResponseWithCustomValue } from '../../common/types';
import { CurrentSubscription } from '../Settings/pages/Subscription/types';
import API from '../../common/api/api.base';
import { SubscriptionsModal } from '../../common/components/modals';
import { toggleSubscriptionsModal } from '../Settings/pages/Subscription/store/actions';
import { DashboardContent } from '../../common/components/blocks/DashboardWrapper/components';

const DashboardContainer: React.FC = () => {
    const [isAccessAllowed, setIsAccessAllowed] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUserInfoRequest());
        dispatch(getCustomerAccountStatusRequest());
    }, []);

    const { value: accountStatus } = useSelector(
        (state: RootStore) => state.payments.getCustomerAccountStatus,
    );
    const roles = useSelector((state: RootStore) => state.auth.user.roles);
    const { isModalOpen } = useSelector((state: RootStore) => state.subscriptions);

    useEffect(() => {
        const checkIsUserSubscribed = async () => {
            try {
                const result: IResponseWithCustomValue<CurrentSubscription> = await API.get(
                    '/api/subscriptions/current',
                    {},
                );
                if (result.success) {
                    if (!result.value.isSubscribed && !result.value.isFreeSubscriptionAvailable) {
                        dispatch(toggleSubscriptionsModal(true));
                    }
                    setIsAccessAllowed(roles?.some((role: string) => role === 'user'));
                }
            } catch (err) {
                console.log(err);
            }
        };
        checkIsUserSubscribed().catch(err => console.log(err));
    }, [roles, accountStatus]);


    useEffect(() => {
        
    }, [roles, accountStatus]);
    
    return (
        <>
            <DashboardWrapper
                sidebar={<SidebarContainer isAccessAllowed />}
                content={<DashboardContent isAccessAllowed={isAccessAllowed} />}
                // content={<CalendarContainer isAccessAllowed={isAccessAllowed} />}
            />
            {/*Modal*/}
            <SubscriptionsModal
                isOpen={isModalOpen}
                onClose={() => {
                    dispatch(toggleSubscriptionsModal(false));
                }}
            />
        </>
    );
};

export default DashboardContainer;
