import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

import { fonts } from '../../../utils';

interface ICustomButtonProps extends ButtonProps {
    text: string;
    isSmall?: boolean;
    isLoading?: boolean;
    icon?: React.ReactElement;
    type?: 'submit' | 'reset' | 'button';
    loadingText?: string;
    w?: string;
    height?: any;
    bgColor?: string;
    color?: string;
    boxShadow?: string;
    onClick?: (e? : any) => void;
    disabled?: boolean;
    borderRadius?: string;
    fontWeight?: string | number;
    borderColor?: string;
    fontSize?: any;
    colorScheme?: string;
    hoverColor?: string;
    to?: string;
    href?: string;
}

const CustomButton: React.FC<ICustomButtonProps> = ({
    text,
    icon,
    isSmall,
    isLoading,
    type = 'button',
    loadingText,
    w = '100%',
    height,
    bgColor,
    color = '#fff',
    boxShadow,
    onClick,
    disabled = false,
    borderRadius = '8px',
    fontWeight = 400,
    borderColor,
    fontSize = '16px',
    colorScheme = 'blackAlpha',
    // hoverColor,
    ...rest
}) => (
    <Button
        h={height}
        type={type}
        color={color}
        leftIcon={icon}
        fontWeight={fontWeight}
        borderRadius={borderRadius}
        isLoading={isLoading}
        loadingText={loadingText}
        colorScheme={colorScheme}
        fontFamily={fonts.poppins}
        bg={bgColor}
        w={w}
        boxShadow={boxShadow}
        borderColor={borderColor}
        borderWidth={borderColor ? '1px' : 0}
        onClick={onClick}
        disabled={disabled}
        fontSize={fontSize}
        // _hover={{
        //     bg: hoverColor,
        // }}
        {...rest}
    >
        {text}
    </Button>
);

export { CustomButton };
