import React from 'react';
import { LandingFooter, LandingHeader, TrialBanner } from '../../../common/components/landing';
import EdgeZpBG from '../../../assets/landing/edge-zoot-pro-bg.png';
import { Box, Container, Flex, Heading, Text } from '@chakra-ui/react';
import { fonts } from '../../../common/utils';
import { edgeBlocks } from '../../../common/constants';
import { EdgeBlock } from './components/EdgeBlock';
import { LandingFooterv2 } from '../../../common/components/landing/LandingFooterv2';

export const EdgeZootPro = () => {
    return (
        <>
            <LandingHeader />
            <Box
                h={{ base: '300px', lg: '450px' }}
                w="100%"
                backgroundRepeat="no-repeat"
                backgroundImage={EdgeZpBG}
                position="relative"
                backgroundSize="cover"
                overflowY="hidden"
            >
                <Box
                    position="absolute"
                    background="linear-gradient(180deg, #1E223D 0%, #2C335C 100%)"
                    h="100%"
                    w="100%"
                    top="0"
                    left="0"
                    opacity={0.7}
                    zIndex={1}
                />
                <Container maxW="container.xl" zIndex={2} position="inherit" h="100%">
                    <Flex direction="column" height="100%" justify="center" pb="70px">
                        <Heading
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize={{ base: '28px', lg: '54px' }}
                            lineHeight={{ base: '32px', lg: '70px' }}
                            fontWeight="600"
                            w={{ base: '100%', lg: '60%' }}
                        >
                            The ZootPro Edge
                        </Heading>
                        <Text
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize="16px"
                            lineHeight="24px"
                            my="23px"
                        >
                            Learn why real estate agents, teams, and brokers trust the ZootPro
                            productivity tool...
                        </Text>
                    </Flex>
                </Container>
            </Box>
            <Box
                bg="white"
                borderTopRadius={{ base: '30px', md: '70px' }}
                mt="-70px"
                zIndex={10}
                position="relative"
            >
                <Container
                    maxW="container.xl"
                    pt="40px"
                    pb={{ base: '50px', lg: '130px' }}
                    px="25px"
                >
                    {edgeBlocks.map(block => (
                        <EdgeBlock key={block.title} {...block} />
                    ))}
                </Container>
            </Box>
            <TrialBanner
                title="Grant instant fiduciary leverage to your clients by
                placing your entire brokerage list of agents as your assistants"
                buttonTitle="Activate your free account"
            />
            <LandingFooterv2 />
        </>
    );
};
