import React, { useState } from 'react';
import {
    Modal,
    ModalContent,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    Box,
    Text,
    CloseButton,
    Tabs,
    Tab,
    TabList,
    TabPanels,
    TabPanel,
    useMediaQuery,
    HStack,
    IconButton,
} from '@chakra-ui/react';
import { colors } from '../../../../common/constants';
import { InfoTab, ListingsTab, ReviewsTab } from './tabs';
import { UserFeedbackType } from '../../../../pages/Requests/types';
import { ProfileImageType, ListingsType, StateDirectoryItem } from '../../../../common/types';
import { ShareIcon } from '../../../../assets/icons/ShareIcon';
import { SharingModal } from '../SharingModal/SharingModal';

interface IUserProfileModalProps {
    isOpen: boolean;
    onClose: () => void;
    tabIndex: number;
    setTabIndex: (value: number) => void;
    feedbacks: UserFeedbackType[];
    feedbacksLoading: boolean;
    agentOpenHouses: ListingsType[];
    agentHousesLoading: boolean;
    userProfileImage: ProfileImageType;
    userProfileImageLoading: boolean;
    setToShowAgentListing: (value: number) => void;
    onOpenReviewer: (value: number) => void;
    setSearchAgentModalVisible: (isVisible: boolean) => void;
    states: StateDirectoryItem[];
}

const tabs = [
    { index: 0, name: 'Info' },
    { index: 1, name: 'Reviews' },
    { index: 2, name: 'Listings' },
];

export const UserProfileModal: React.FC<IUserProfileModalProps> = ({
    isOpen,
    onClose,
    setTabIndex,
    tabIndex,
    feedbacks,
    feedbacksLoading,
    agentHousesLoading,
    agentOpenHouses,
    userProfileImage,
    userProfileImageLoading,
    setToShowAgentListing,
    onOpenReviewer,
    setSearchAgentModalVisible,
    states,
}) => {
    const [isLargerThan1600] = useMediaQuery('(min-width: 1600px)');

    const [isSharingModalOpen, setIsSharingModalOpen] = useState(false);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={isLargerThan1600 ? '6xl' : '5xl'}>
            <ModalOverlay />
            <ModalContent
                borderRadius={'18px'}
                h={'85vh'}
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <Box d="flex" alignItems="center" justifyContent="space-between" w="100%" px={6}>
                    <ModalHeader flex={1} px="0">
                        <Text color="brand">User Profile</Text>
                    </ModalHeader>
                    <HStack spacing={1}>
                        <IconButton
                            aria-label="Share button"
                            icon={<ShareIcon />}
                            w="32px"
                            h="32px"
                            minW="auto"
                            onClick={() => setIsSharingModalOpen(true)}
                        />
                        <CloseButton onClick={onClose} />
                    </HStack>
                </Box>
                <ModalBody d="flex" flexDirection="column" overflowY="auto" h="100%" w="100%">
                    <Tabs variant="unstyled" index={tabIndex} w="100%" h="100%">
                        <TabList>
                            {tabs.map(elem => (
                                <Tab
                                    key={elem.index}
                                    border={
                                        tabIndex === elem.index
                                            ? `1px solid ${colors.brand}`
                                            : `1px solid ${colors.secondaryFontDarker}`
                                    }
                                    borderRadius="20px"
                                    minW="150px"
                                    onClick={() => setTabIndex(elem.index)}
                                    bg={tabIndex === elem.index ? colors.brand : 'transparent'}
                                    mr="10px"
                                >
                                    <Text
                                        fontSize="14px"
                                        color={
                                            tabIndex === elem.index
                                                ? 'white'
                                                : 'secondaryFontDarker'
                                        }
                                        fontWeight={400}
                                    >
                                        {elem.name}
                                    </Text>
                                </Tab>
                            ))}
                        </TabList>
                        <TabPanels height="calc(100% - 40px)" w="100%">
                            <TabPanel p="0" h="100%" pt="10px" w="100%">
                                <InfoTab
                                    userProfileImage={userProfileImage}
                                    loading={userProfileImageLoading}
                                    setSearchAgentModalVisible={setSearchAgentModalVisible}
                                    states={states}
                                />
                            </TabPanel>
                            <TabPanel p="0" h="100%" pt="10px" w="100%">
                                <ReviewsTab
                                    items={feedbacks}
                                    loading={feedbacksLoading}
                                    onOpenReviewer={onOpenReviewer}
                                />
                            </TabPanel>
                            <TabPanel p="0" h="100%" pt="10px" w="100%">
                                <ListingsTab
                                    items={agentOpenHouses}
                                    loading={agentHousesLoading}
                                    setToShowAgentListing={setToShowAgentListing}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
            </ModalContent>
            <SharingModal
                isOpen={isSharingModalOpen}
                onClose={() => setIsSharingModalOpen(false)}
            />
        </Modal>
    );
};
