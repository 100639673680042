import { AddIcon, EditIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { CustomButton, Loader } from '..';
import { EnterCodeForEditModal } from '../../../pages/Settings/components/EnterCodeForEditModal';
import { IPaymentMethod } from '../../types/api.types';
import { AddBalanceModal } from './AddBalanceModal';
import { AddPaymentModal } from './AddPaymentModal';
import './PaymentsList.scss';
import { PaymentsMethodsList } from './PaymentsMethodsList';

interface IPaytmentsListItem {
  allowBtn?: Boolean
}

export const PaymentsList: React.FC<IPaytmentsListItem> = ({allowBtn = true}) => 
{
  const [loading, SetLoagin] = useState(false);
  const [PaymentModal, setPaymentModal] = useState(false);
  const [balanceModal, setbalanceModal] = useState(false);
  const [payments, setPayments] = useState<IPaymentMethod[]>([]);

  const togglePaymentModal = () => {
    setPaymentModal(!PaymentModal);
  };

  const toggleBalanceModal = () => {
    setbalanceModal(!balanceModal);
  };
  
  useEffect(() => {
    setTimeout(() => {
      getPaymentMethods();
    }, 600);
  }, []);

  const getPaymentMethods = () => {
    SetLoagin(true);
    axios.get<IPaymentMethod[]>(window.baseUrl+'/api/get_payment_methods')
    .then((response) => {
      setPayments(response.data);
      SetLoagin(false);
    });
  };

	return (
		<div className="paymentList_outer">
			{loading? (<Loader spinnerSize={'md'} centerHeight={'100%'} />) : 
			(
      <>
        <PaymentsMethodsList 
          payments={payments}
          emitChanges={getPaymentMethods}
        />
        {allowBtn && (
          <>
          <CustomButton
            text="Add payment method"
            icon={<AddIcon color="white" />}
            bgColor="brand"
            maxWidth="250px"
            height="50px"
            alignSelf="center"
            justifySelf="end"
            onClick={togglePaymentModal}
          />
          <CustomButton
            style={{marginLeft: '20px'}}
            text="Top up balance"
            icon={<AddIcon color="white" />}
            bgColor="brand"
            maxWidth="200px"
            height="50px"
            alignSelf="center"
            justifySelf="end"
            onClick={toggleBalanceModal}
          />
        </>
        )}
      </>
			)
			}
      {allowBtn && (
        <>
        <AddPaymentModal
            isOpen={PaymentModal}
            onClose={togglePaymentModal}
        />
        <AddBalanceModal
            isOpen={balanceModal}
            onClose={toggleBalanceModal}
        />
        </>
      )}
		</div>
	);
};

