import React, { useEffect, useState } from 'react';
import { DashboardWrapper } from '../../common/components/blocks/DashboardWrapper/DashboardWrapper';
import { store } from '../../store';
import SidebarContainer from '../Sidebar/Sidebar.container';

export const MarkerContent: React.FC = () => {

    if(window.storeVue){
        window.storeVue.commit('updatedRoute');
    }

    useEffect(() => {
        const token = store.getState().auth.user.token.accessToken;

        const meta = document.createElement('meta');
        meta.name = "token_el";
        meta.content = token;
        document.head.appendChild(meta);

        const script = document.createElement('script');
        script.src = "https://api.zootpro.com/site/app.site.js?v="+Date.now();
        script.async = true;
        document.body.appendChild(script);
    
        const link = document.createElement('link');
        link.href = "https://api.zootpro.com/site/app.site.css?v="+Date.now();
        link.rel = "stylesheet";
        
        document.body.appendChild(link);

        window.tempEl = [script, link, meta];
        
        return () => {
            //console.log(script);
            //console.log(link);
            //console.log(meta);

            script.remove();
            link.remove();
            meta.remove();
            
            //document.body.parentNode?.removeChild(script);
            //document.body.parentNode?.removeChild(link);
            //document.body.parentNode?.removeChild(meta);
        }

    }, []);

    return (
        <>  
            <div id="appmarket"></div>
        </>
    );
};
