import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Box, Center, Button, Text, Link as ChakraLink, Flex } from '@chakra-ui/react';

import { LogoIcon } from '../../../../assets/icons';
import BottomCity from '../../../../assets/img/Bottom.png';
import { LogoIconDashboard } from '../../../../assets/icons/LogoDashboard';

import { colors } from '../../../constants';

import { fonts } from '../../../utils';

interface IAuthorization {
    child: React.ReactNode;
    isSignIn?: boolean | undefined;
}

export const AuthWrapper: React.FC<IAuthorization> = ({ child, isSignIn }) => (
    <Box minH="100vh" overflowY="auto">
        <Box backgroundColor={colors.white}>
            <Container
                display={'flex'}
                justifyContent="space-between"
                alignItems={'center'}
                maxW="container.xl"
                paddingY={{ base: '25px', lg: '15px' }}
            >
                <Link to={'/'} replace>
                    <LogoIcon fill={colors.brand} />
                </Link>
                <Flex alignItems={'center'}>
                    <Text display={{base: "none", md: "block"}} mr={'32px'}>
                        {isSignIn ? 'Don’t have an account? ' : 'Already have an account?'}
                    </Text>
                    <Button
                        border={'1px solid rgb(47, 128, 237)'}
                        height="59px"
                        width="145px"
                        background={'white'}
                        borderRadius="30px"
                        fontFamily={fonts.montserrat}
                        fontWeight="bold"
                        fontSize="14px"
                        _hover={{ bg: colors.secondaryFont, textDecoration: 'none' }}
                        as={ChakraLink}
                        color={colors.calendarBlue}
                        href={isSignIn ? '/auth/signup/createaccount' : '/auth/check'}
                    >
                        {isSignIn ? 'Sign Up' : 'Log in'}
                    </Button>
                </Flex>
            </Container>
        </Box>
        <Center
            flexDirection={'column'}
            alignItems="center"
            backgroundColor="brand"
            paddingBottom={'200px'}
            backgroundImage={`url(${BottomCity})`}
            bgPosition="bottom"
            bgRepeat="no-repeat"
            >
            <br />
            <br />
            <br />
            <br />
            {/*
            <Box mt={{base: "35px", md:'115px'}} mb={'30px'}>
                <LogoIconDashboard />
            </Box>
            */}
            <Container maxW="container.xl">
                <Center>{child}</Center>
            </Container>
        </Center>
        <Box paddingY={'35px'} backgroundColor={colors.white}>
            <Text textAlign={'center'}>© { (new Date).getFullYear() } ZOOTPRO Inc. All rights reserved.</Text>
        </Box>
    </Box>
);
