import React, { useEffect } from 'react';
import SidebarContainer from '../Sidebar/Sidebar.container';
import { DashboardWrapper } from '../../common/components/blocks/DashboardWrapper/DashboardWrapper';
import UpdatesContainer from './Updates.container';

export const UpdatesWraper: React.FC = () => {
    return (
        <DashboardWrapper
          paddingBottom="20px"
          paddingTop="20px"
          paddingLeft="20px"
          paddingRight="20px"
          sidebar={<SidebarContainer isAccessAllowed />}
          content={<UpdatesContainer />}
        />
    );
};
