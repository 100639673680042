import React, { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Flex,
    HStack,
    PinInput,
    PinInputField,
    Button,
    CloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { CustomButton, Loader } from '..';
import { useSelector } from 'react-redux';
import { dispatch, RootStore } from '../../../store';
import { useHistory, useLocation } from 'react-router-dom';
import { colors } from '../../constants';
import { BackArrow } from '../../../assets/icons/BackArrow';
import { fonts } from '../../utils/fonts';
import { FormChangeBalance } from './FormChangeBalance';
import { formatPrice } from './utils';
import { getUserInfoRequest } from '../../../store/actions/user.actions';
import { PaymentsList } from './PaymentsList';
import { Iplan } from '../../../pages/Settings/pages/Subscription/AvailableOptionsOverview';
import { AddIcon } from '@chakra-ui/icons';
import _ from 'lodash';

interface IAddSubscribeModal {
    isOpen: boolean;
    onClose: () => void;
		selectedPlan: Iplan;
		emitSubmited?: () => void;
}

export const AddSubscribeModal: React.FC<IAddSubscribeModal> = ({ isOpen, onClose, selectedPlan, emitSubmited }) => {
    const handleClose = () => {
        onClose();
    };

		const [loading, SetLoagin] = useState(false);
		
    const toast = useToast();
    const history = useHistory();
    const { user: currentUser } = useSelector((state: RootStore) => state.user);
    const [loader, setLoader] = useState<boolean>(false);
    const [LoaderBtn, setLoaderBtn] = useState<boolean>(false);

    const search = useLocation().search;
    const stripeSessionId = new URLSearchParams(search).get('session_id');

    const saveStribeSubscribe = async () => {
      if(!stripeSessionId){
        return;
      }

      await axios.post(window.baseUrl+'/api/stripe/ext/saveStribeSubscribe', {
        stripeSessionId: stripeSessionId,
				})
        .then(res => {
          if(res.data.reload){
            location.href = '/settings/subscription';
          }
        }).catch((error: any) => {
          
        });
    };

    useEffect(() => {
      saveStribeSubscribe();
    }, [stripeSessionId]);

		const onSubmit = async () => {
      setLoader(true);
      await axios.post(window.baseUrl+'/api/subscribe/set', {
					type: _.trim(selectedPlan.name, '/'),
				})
        .then(() => {
          setLoader(false);
          toast({
            title: 'Your subscribe successfully updated',
            status: 'info',
            isClosable: true,
          });

					dispatch(getUserInfoRequest());
					handleClose();

          if(emitSubmited){
            emitSubmited();
          }
        }).catch((error: any) => {
          setLoader(false);
          let status = error.response.status;

          if(status === 422){
            toast({
              title: error.response.data.error,
              status: 'error',
              isClosable: true,
            });
            return;
          }

          if(status === 423){
            console.warn('!!!THIS PROCCESS NOT WORK!!!!! STRIPE');
            return;
          }
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent
                w={'600px'}
                borderRadius={'18px'}
                h={'auto'}
                bgColor={colors.modalBackground}
                alignItems="center"
                paddingBottom="20px"
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    px="30px"
                    pt="10px"
                >
                    <Box as="button" onClick={onClose}>
                        <BackArrow />
                    </Box>
                    <ModalHeader flex={1} align="center">
                        <Text
                            fontSize="22px"
                            color="brand"
                            textAlign="center"
                            lineHeight="18px"
                            fontWeight={700}
                            fontFamily={fonts.montserrat}
                        >
                          Subscribe
                        </Text>
                    </ModalHeader>
                    <CloseButton onClick={onClose} />
                </Flex>
                <ModalBody w="100%" d="flex" flexDirection="column" overflowY="auto">
									<PaymentsList allowBtn={false} />

									<CustomButton
										text="Add payment card"
										icon={<AddIcon color="white" />}
										bgColor="brand"
										maxWidth="220px"
										height="50px"
										alignSelf="center"
										justifySelf="end"
										onClick={() => {
											history.push('/settings/payments');
										}}
									/>

									<h3>Payment detail</h3>
									<p>{selectedPlan.price}{selectedPlan.name}</p>
									<CustomButton
										text="Pay"
										bgColor="brand"
										maxWidth="200px"
										height="50px"
										alignSelf="center"
										justifySelf="end"
										onClick={onSubmit}
										isLoading={loader}
									/>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
