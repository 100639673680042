import * as yup from 'yup';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';

import { ampmTimeYupResolver } from '../../../../../common/constants';
import { ErrorMessages } from './messages';

export type CreateRestFormValues = {
    states: Array<string>;
    date: string;
    fromTime: string;
    price: number;
    address: string;
    description?: string;
    clientTypeId: number;
    clientName?: string;
    clientPhone?: string;
    privateNote?: string;
};

const createPostRestSchema = yup.object().shape({
    states: yup.array().required(ErrorMessages.REQUIRED),
    date: yup
        .string()
        .required(ErrorMessages.REQUIRED)
        .test('in_the_future', ErrorMessages.PAST_DATE, function (value?: string) {
            return dayjs.utc(value ? +value : undefined).isSameOrAfter(dayjs(), 'day');
        }),
    fromTime: ampmTimeYupResolver,
    price: yup
        .number()
        .required(ErrorMessages.REQUIRED)
        .integer('Should be integer')
        .typeError('Must be a number'),
    address: yup.string().required(ErrorMessages.REQUIRED),
    description: yup.string(),
    clientTypeId: yup.number().required(ErrorMessages.REQUIRED),
    clientName: yup.string(),
    clientPhone: yup.string(),
    privateNote: yup.string(),
});

export default yupResolver(createPostRestSchema);
