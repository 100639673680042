import { createAction } from '@reduxjs/toolkit';
import { PayloadError, SendContactFormType, SendHelpRequestType } from '../../common/types';

export const toggleLandingVideoModal = createAction<boolean>('TOGGLE_LANDING_VIDEO_MODAL');

export const sendCooperationDetailRequest = createAction<FormData>(
    'SEND_COOPERATION_DETAIL_REQUEST',
);
export const sendCooperationDetailSuccess = createAction('SEND_COOPERATION_DETAIL_SUCCESS');
export const sendCooperationDetailError = createAction<PayloadError[]>(
    'SEND_COOPERATION_DETAIL_ERROR',
);

export const sendContactForm = createAction<SendContactFormType>('SEND_CONTACT_FORM');
export const sendContactFormSuccess = createAction('SEND_CONTACT_FORM_SUCCESS');
export const sendContactFormError = createAction<PayloadError[]>('SEND_CONTACT_FORM_ERROR');

export const sendHelpRequest = createAction<SendHelpRequestType>('SEND_HELP_REQUEST');
export const sendHelpRequestSuccess = createAction('SEND_HELP_REQUEST_SUCCESS');
export const sendHelpRequestError = createAction<PayloadError[]>('SEND_HELP_REQUEST_ERROR');
