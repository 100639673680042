import React, { useState } from 'react';
import * as yup from 'yup';

import {
    Box,
    Flex,
    IconButton,
    Modal,
    ModalContent,
    ModalOverlay,
    Text,
    Image,
    CloseButton,
    Button,
    useToast,
} from '@chakra-ui/react';

import { colors } from '../../../common/constants';
import { fonts } from '../../../common/utils';
import { CustomInput, CustomTextarea } from '../../../common/components';
import { useForm } from 'react-hook-form';
import { ErrorMessages } from '../../../common/schemes/messages';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

interface IProp {
    isOpen: boolean;
    onClose: () => void;
}

type FormProps = {
    title: string;
    message: string;
};

const FormSchema = yup.object().shape({
    title: yup.string().required(ErrorMessages.REQUIRED),
    message: yup.string().required(ErrorMessages.REQUIRED),
});

export const ModalSupport: React.FC<IProp> = ({ isOpen, onClose, }) => {
    
    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
        control,
        clearErrors,
        watch,
        reset,
        trigger,
    } = useForm<FormProps>({
        resolver: yupResolver(FormSchema),
    });


    const [loader, setLoader] = useState<boolean>(false);
    const toast = useToast();

    const onSubmit = async (data: FormProps) => {
        setLoader(true);

        await axios.post(window.baseUrl+'/api/support/create', data)
            .then(() => {
                setLoader(false);
                reset();
                toast({
                    title: 'Form submitted successfully',
                    status: 'info',
                    isClosable: true,
                });
            })
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent
                    overflow={'hidden'}
                    height="455px"
                    borderRadius="18px"
                    bgColor={colors.modalBackground}
                    alignItems="center"
                >
                    <Text
                        color={colors.brand}
                        fontFamily={fonts.montserrat}
                        fontWeight={700}
                        fontSize="22px"
                        lineHeight="18px"
                        textAlign="center"
                        fontStyle="normal"
                        mt="60px"
                        mb="40px"
                    >
                        Write to support
                    </Text>
                    <IconButton
                        onClick={onClose}
                        aria-label={'close modal'}
                        icon={<CloseButton />}
                        variant="ghost"
                        position="absolute"
                        top="30px"
                        right="55px"
                    />
                    <Box mb="90px" width="100%" padding="0px 40px 0px 40px">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <CustomInput
                                label="Title: *"
                                placeholder=""
                                width="100%"
                                register={{ ...register('title') }}
                                errors={errors.title}
                            />
                            <br />
                            <CustomTextarea
                                label="Message: *"
                                placeholder=""
                                width="100%"
                                register={{ ...register('message') }}
                                errors={errors.message}
                            />
                            <br />
                            
                            <Button
                                isFullWidth
                                gridColumnStart="2"
                                variant={'primary'}
                                boxShadow="0px 3px 3px grey"
                                isLoading={loader}
                                onClick={() => {
                                    handleSubmit(onSubmit)();
                                }}
                                >
                                Send
                            </Button>
                            <br/>
                        </form>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    );
};
