import React from 'react';
import { useRouteMatch } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import { SignUpContainer } from './SignUp.container';
import { SignUpFillDataContainer } from './components/SignUpFillData/SignUpFillData.container';
import { SignUpUploadRealEstateLicense } from './components/SignUpUploadPhoto/SignUpUploadRealEstate.container';
import { SignUpUploadDriverLicense } from './components/SignUpUploadPhoto/SignUpUploadDriverLicense.container';
import { SignUpVerificationPopUp } from './components/SignUpVerificationPopUp/SignUpVerificationPopUp';
import { AuthWrapper } from '../../../common/components';

import { ISignUpRoutingProps } from './types';
import { MatchParams } from '../../../common/types';
export const SignUpRouting: React.FC<ISignUpRoutingProps> = ({ nestedPath }) => {
    const match = useRouteMatch<MatchParams>();
    return (
        <Switch>
            <Route
                path={`${match.url}/createaccount`}
                component={() => (
                    <AuthWrapper child={<SignUpContainer nestedPath={nestedPath} />} />
                )}
            />
            <Route
                path={`${match.url}/filldata`}
                component={() => <AuthWrapper child={<SignUpFillDataContainer />} />}
            />
            <Route
                path={`${match.url}/adddriverlicense`}
                component={() => <AuthWrapper child={<SignUpUploadDriverLicense />} />}
            />
            <Route
                path={`${match.url}/addrealestatelicense`}
                component={() => <AuthWrapper child={<SignUpUploadRealEstateLicense />} />}
            />
            <Route
                path={`${match.url}/verificationpopup`}
                component={() => <AuthWrapper child={<SignUpVerificationPopUp nestedPath={nestedPath} />} />}
            />
            <Redirect from="*" exact={true} to={`${match.url}/createaccount`} />
        </Switch>
    );
};
