import { useEffect, useState } from 'react';
import Api from '../../api/api.base';
import {
  IResponseWithCustomValue,
  ProfileImageType,
} from '../../types';

export const useGetUserProfileImage = (
  userId: number,
): ProfileImageType | undefined => {
  const [userProfileImage, setUserProfileImage] = useState<ProfileImageType>();

  async function userProfileResponse() {
    try {
      const result: IResponseWithCustomValue<{ profileImage: ProfileImageType }>  = await Api.get(
        `/api/profile/get-user-profile-image?id=${userId}`,
      {});

      if (result.success) {
        setUserProfileImage(result.value.profileImage);
      } else {
        console.log('useGetUserProfileImage', result?.errors[0].errorMessage);
      }
    } catch (error) {
      console.log('useGetUserProfileImage', error);
    }
  }

  useEffect(() => {
    if (userId) {
      userProfileResponse();
    }
 }, [userId]);

  return userProfileImage;
};