import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDelta } from 'react-delta';

import { Box, HStack, SimpleGrid, Button, Divider } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { CustomInput, CustomDropdown, DatePicker, TypeAhead } from '../../../common/components';
import { BonusConfirmModal } from '../../../common/components/modals/BonusConfirm/BonusConfirmModal';

import {
    agentPreferrences,
    getInspectionItems,
    TypeIds,
} from '../../../common/constants/dropdown.consatnts';

import { SearchBy, FindAssistancePayloadType } from '../types';
import { IAddressess, SelectValue } from '../../../common/types';

import { requestShowingResolver } from '../../../common/schemes';
import {
    getPlaceInfo,
    combineDateAndTime,
    formatHoursToDateTime,
    carryAddressLoadOptions,
    agentLoadOptions,
    convertTimeIn24HoursFormat,
    combineDateAndTimeNoUTC,
    filterAddressess,
    filterAddressessSync,
} from '../../../common/utils';
import { ClockIcon } from '../../../assets/icons';
import { HOMEPAGE_PATH } from '../../../common/constants';
import { getTimeOffset } from '../../../common/components/blocks/DatePicker/utils';

type RequestShowingFormValues = {
    dateTime: string;
    states: string;
    duration: number;
    addresses: Array<{ name: string }>;
    secondAddress: string;
    propertiesToShow: number;
    longitude: number;
    latitude: number;
    requestTypeId: number;
    privateNote: string;
    generalNote: string;
    wouldLikeToOfferBonus: string;
    clientTypeId: number;
    bonusTypeId: string;
    amount: number;
    pricePerDoor: number;
    pricePerHour: number;
    isShowingScheduled: string;
    isSearchByLocation: boolean;
    fromTime: string;
    preferences: string;
    searchBy: SearchBy;
    address: string;
    clientPhone: string;
    clientName: string;
    startingAddress: string;
    preferredAgentId: string;
};

interface IShowingFormProps {
    preferredAgentId?: string;
    searchBy: SearchBy;
    states: SelectValue[];
    filterStateCode: string;
    selectFilterStateCode: (stateName: string) => void;
    onFindAssistance: (data: FindAssistancePayloadType) => void;
    requestValue: TypeIds;
    assistanceLoading: boolean;
    allAddresses?: IAddressess[];
}

export const ShowingForm: React.FC<IShowingFormProps> = ({
    preferredAgentId,
    searchBy,
    states,
    filterStateCode,
    selectFilterStateCode,
    onFindAssistance,
    requestValue,
    assistanceLoading,
    allAddresses,
}) => {
    const {
        getValues,
        register,
        setValue,
        formState: { errors },
        handleSubmit,
        control,
        clearErrors,
        watch,
        reset,
        trigger,
    } = useForm<RequestShowingFormValues>({
        resolver: requestShowingResolver,
    });

    const { fields, append, remove } = useFieldArray({ control, name: 'addresses' });
    const history = useHistory();
    const [agentBonusModalOpen, setAgentBonusModalOpen] = useState(false);
    const [bonusAmount, setBonusAmount] = useState('');

    const assistanceLoadingDelta = useDelta(assistanceLoading);

    useEffect(() => {
        if (assistanceLoadingDelta && assistanceLoadingDelta.prev) {
            if (assistanceLoadingDelta.prev && !assistanceLoadingDelta.curr) {
                //history.push(HOMEPAGE_PATH);
                //history.push('/requests');
            }
        }
    }, [assistanceLoadingDelta]);

    const onError = async (data: any) => {
        console.log('OnError', data);
    }

    const onSubmit = async (data: RequestShowingFormValues) => {
        const placeInfo =
            searchBy === SearchBy.Location ? await getPlaceInfo(data.startingAddress) : undefined;
        const fromTime = convertTimeIn24HoursFormat(data.fromTime);

        if (searchBy === SearchBy.Location) {
            const dateTime = combineDateAndTime(
                dayjs(new Date().toISOString()).toISOString(),
                formatHoursToDateTime(fromTime),
            );

            onFindAssistance({
                ...data,
                requestTypeId: requestValue,
                preferredAgentId:
                    preferredAgentId && data.preferences === '2'
                        ? preferredAgentId
                        : data.preferences === '3'
                        ? data.preferredAgentId
                        : null,
                wouldLikeToOfferBonus: data.wouldLikeToOfferBonus === '1',
                latitude: placeInfo?.lat || 0,
                longitude: placeInfo?.lng || 0,
                isShowingScheduled: data.isShowingScheduled === 'Yes, I Have Scheduled The Showing',
                isSearchByLocation: true,
                dateTime,
                timeOffset: getTimeOffset(),
                states: [data.states],
                approximateDuration: 0,
            });
        } else {
            const dateTime = combineDateAndTime(
                dayjs(+data.dateTime).toISOString(),
                formatHoursToDateTime(fromTime),
            );

            onFindAssistance({
                ...data,
                requestTypeId: requestValue,
                preferredAgentId:
                    preferredAgentId && data.preferences === '2'
                        ? preferredAgentId
                        : data.preferences === '3'
                        ? data.preferredAgentId
                        : null,
                longitude: 0,
                latitude: 0,
                isSearchByLocation: false,
                wouldLikeToOfferBonus: data.wouldLikeToOfferBonus === '1',
                isShowingScheduled: data.isShowingScheduled === 'Yes, I Have Scheduled The Showing',
                dateTime,
                timeOffset: getTimeOffset(),
                states: [data.states],
                approximateDuration: 0,
            });
        }
    };

    const [
        watchPropertiesToShow,
        watchWouldLikeToOfferBonus,
        bonusTypeId,
        amount,
        dateForm,
        preferrencesValue,
        selectedState,
    ] = watch([
        'propertiesToShow',
        'wouldLikeToOfferBonus',
        'bonusTypeId',
        'amount',
        'dateTime',
        'preferences',
        'states',
    ]);

    useEffect(() => {
        remove();
        for (let i = 0; i < Number(watchPropertiesToShow); i++) {
            append({ name: '' });
        }
    }, [watchPropertiesToShow]);

    useEffect(() => {
        setValue('searchBy', searchBy);
    }, [searchBy]);

    useEffect(() => {
        selectFilterStateCode(selectedState);
    }, [selectedState]);

    useEffect(() => {
        if (preferredAgentId) {
            reset({ preferences: '2' });
        }
    }, [preferredAgentId]);

    useEffect(() => {
        setValue('preferredAgentId', '');
    }, [preferrencesValue]);

    const setDefaultState = () => {

        setTimeout(() => {
            if(getValues('states') != '' || !states.length){
                return;
            }
            setValue('states', states[0].value);
        }, 600);
    }

    useEffect(() => {
        setDefaultState();
    }, [states]);

    useEffect(() => {
        if (watchWouldLikeToOfferBonus === '1') {
            if (bonusTypeId === '1') {
                setBonusAmount(`${amount}% of the commission`);
            } else {
                setBonusAmount(`$${amount}.00`);
            }
        }
    }, [amount, bonusTypeId, watchWouldLikeToOfferBonus]);

    var countAddressess = filterAddressessSync(allAddresses, filterStateCode).length;

    return (
        <div className="SHOW_FORM_ASSIST">
            <form onSubmit={handleSubmit(onSubmit)}>
                <SimpleGrid columns={2} columnGap="60px">
                    <CustomDropdown
                        options={states?.map((item: any, i: any) => ({
                            ...item,
                            id: i,
                        }))}
                        label="State: *"
                        register={{ ...register('states') }}
                        errors={errors.states}
                        width="100%"
                        placeholder="Select"
                        margin={errors.states ? '0 10px 0 0' : '0 10px 26px 0'}
                    />

                    <CustomDropdown
                        label="Number of Properties: *"
                        options={Array(15)
                            .fill('')
                            .map((elem, index) => ({
                                label: index + 1 + '',
                                value: index + 1,
                                id: index,
                            }))}
                        placeholder="Select"
                        errors={errors.propertiesToShow}
                        margin={errors.propertiesToShow ? '0px' : '0 0 26px 0'}
                        register={{ ...register('propertiesToShow') }}
                    />
                    <DatePicker
                        minimumDate={new Date()}
                        label="Date: *"
                        displayValue={
                            searchBy === SearchBy.Location
                                ? dayjs.utc().format('MM/DD/YY')
                                : Number(dateForm)
                                ? dayjs.utc(Number(dateForm)).format('MM/DD/YY')
                                : ''
                        }
                        value={Number(dateForm) || dayjs.utc().valueOf()}
                        setValue={value => {
                            setValue('dateTime', value.toString());
                            clearErrors('dateTime');
                        }}
                        removeBottomMargin
                        placeholder="MM/DD/YY"
                        errMessage={errors.dateTime?.message}
                        borderRadius="50px"
                        formControlProps={{
                            margin: errors.states ? '0 10px 0 0' : '0 10px 26px 0',
                        }}
                        isDisabled={searchBy === SearchBy.Location}
                    />
                    <CustomInput
                        label="Start Time: *"
                        register={{ ...register('fromTime') }}
                        type="time-12-hours"
                        width="100%"
                        defaultValue="00:00 AM"
                        rightIcon={<ClockIcon color="#888DAB" />}
                        errors={errors.fromTime}
                    />
                </SimpleGrid>
                {searchBy === SearchBy.Location && (
                    <Box w={'full'}>
                        <TypeAhead
                            placeholder="Starting Location"
                            label="Starting address"
                            loadOptions={carryAddressLoadOptions(false, filterStateCode)}
                            onStateChange={ev => {
                                setValue('startingAddress', ev?.value || '');
                                clearErrors('startingAddress');
                            }}
                            errors={errors.startingAddress?.message}
                        />
                    </Box>
                )}
                {!!fields.length && (
                    <>
                        <Divider borderBottom="1px solid #D1D2DB" my="30px" mt="13px" />
                        <SimpleGrid columns={2} columnGap="60px">
                            {filterStateCode && fields.map((item, index) => (
                                <Controller
                                    key={index}
                                    render={field => (
                                        <>
                                        <TypeAhead
                                            key={filterStateCode}
                                            forceShow={true}
                                            placeholder="Enter"
                                            label={'Address ' + (index + 1)}
                                            onStateChange={ev => {
                                                //console.log('dddddd', ev);
                                                setValue(
                                                    `addresses.${index}.name`,
                                                    ev?.label || '',
                                                );
                                                clearErrors(`addresses.${index}.name`);
                                            }}
                                            /*
                                            loadOptions={filterAddressess(allAddresses, filterStateCode)}
                                            */
                                            loadOptions={carryAddressLoadOptions(
                                                false,
                                                filterStateCode,
                                            )}
                                            errors={errors.addresses?.[index]?.name?.message}
                                        />
                                        </>
                                    )}
                                    name={`addresses.${index}`}
                                    control={control}
                                    defaultValue={{ name: '' }}
                                />
                            ))}
                        </SimpleGrid>

                        <Divider borderBottom="1px solid #D1D2DB" my="30px" mt="13px" />
                    </>
                )}

                <SimpleGrid columns={2} columnGap="60px" alignItems="end">
                    <CustomInput
                        label="Description:"
                        width="100%"
                        placeholder="Enter"
                        register={{ ...register('generalNote') }}
                        errors={errors.generalNote}
                        margin={errors.generalNote ? '0' : '0 0 26px 0'}
                    />
                    <CustomDropdown
                        label="Is The Showing Scheduled? *"
                        options={getInspectionItems('Showing')}
                        register={{ ...register('isShowingScheduled') }}
                        errors={errors.isShowingScheduled}
                        width="100%"
                        placeholder="Select"
                        margin={errors.isShowingScheduled ? '0' : '0 0 26px 0'}
                    />
                    <CustomDropdown
                        label="I Would Like To Offer A Bonus If A Contract Is Ratified From The Showing List Within 48-Hours After The Showing"
                        placeholder="Select"
                        options={[
                            {
                                id: 1,
                                label: 'Yes',
                                value: '1',
                            },
                            {
                                id: 2,
                                label: 'No',
                                value: '2',
                            },
                        ]}
                        register={{ ...register('wouldLikeToOfferBonus') }}
                        errors={errors.wouldLikeToOfferBonus}
                        width="100%"
                        margin={errors.wouldLikeToOfferBonus ? '0' : '0 0 26px 0'}
                    />
                    {watchWouldLikeToOfferBonus === '1' ? (
                        <HStack my={'30px'} alignItems={'flex-start'}>
                            <CustomDropdown
                                label="Select bonus Type: *"
                                options={[
                                    {
                                        id: 1,
                                        label: '%',
                                        value: '1',
                                    },
                                    {
                                        id: 2,
                                        label: '$',
                                        value: '2',
                                    },
                                ]}
                                register={{ ...register('bonusTypeId') }}
                                errors={errors.bonusTypeId}
                                width="100%"
                            />
                            <CustomInput
                                label="Enter Bonus: *"
                                placeholder="Enter"
                                width="100%"
                                register={{ ...register('amount') }}
                                errors={errors.amount}
                            />
                        </HStack>
                    ) : null}
                    <CustomDropdown
                        label="The Client is: *"
                        options={[
                            { id: 1, label: 'A Couple', value: '1' },
                            { id: 2, label: 'A Family', value: '2' },
                            { id: 3, label: 'An Individual', value: '3' },
                        ]}
                        placeholder="Select"
                        register={{ ...register('clientTypeId') }}
                        errors={errors.clientTypeId}
                        width="100%"
                        margin={errors.clientTypeId ? '0' : '0 0 26px 0'}
                    />
                    <CustomInput
                        label="Client Name"
                        width="100%"
                        placeholder="Enter"
                        register={{ ...register('clientName') }}
                        errors={errors.clientName}
                        margin={errors.clientName ? '0' : '0 0 26px 0'}
                    />

                    <CustomInput
                        register={{ ...register('clientPhone') }}
                        label="Client Phone"
                        placeholder="Client phone"
                        errors={errors.clientPhone}
                        type="tel"
                        mask="999-999-9999"
                        margin={errors.clientPhone ? '0' : '0 0 26px 0'}
                    />
                    <CustomInput
                        label="Private Notes to the Chosen Agent"
                        width="100%"
                        register={{ ...register('privateNote') }}
                        placeholder="Enter"
                        errors={errors.privateNote}
                        margin={errors.privateNote ? '0' : '0 0 26px 0'}
                    />
                    <CustomDropdown
                        label="I Have A Preference For: *"
                        options={
                            preferredAgentId
                                ? agentPreferrences
                                : agentPreferrences.filter(elem => elem.value !== '2')
                        }
                        placeholder="Select"
                        register={{
                            ...register('preferences'),
                        }}
                        errors={errors.preferences}
                        width="100%"
                        margin={errors.preferences ? '0' : '0 0 26px 0'}
                    />
                    {preferrencesValue === '3' ? (
                        <TypeAhead
                            label="Previous Agent:"
                            placeholder="Previous Agent"
                            loadOptions={agentLoadOptions}
                            onStateChange={ev => {
                                setValue('preferredAgentId', ev?.value || '');
                                clearErrors('preferredAgentId');
                            }}
                            errors={errors.preferredAgentId?.message}
                        />
                    ) : null}
                </SimpleGrid>

                <SimpleGrid columns={2} columnGap="60px" alignItems="end" mt="31px">
                    <Button
                        className="SHOW_FORM_POST_BTN"
                        isFullWidth
                        gridColumnStart="2"
                        variant={'primary'}
                        boxShadow="0px 3px 3px grey"
                        isLoading={assistanceLoading}
                        onClick={() => {
                            handleSubmit(onSubmit, onError)();
                            /*
                            watchWouldLikeToOfferBonus === '1' && amount && bonusTypeId
                                ? async () => {
                                      const result = await trigger();
                                      if (result) {
                                          setAgentBonusModalOpen(true);
                                      }
                                  }
                                : handleSubmit(onSubmit)
                            */
                        }}
                    >
                        Post
                    </Button>
                </SimpleGrid>
            </form>
            <BonusConfirmModal
                isOpen={agentBonusModalOpen}
                onClose={() => setAgentBonusModalOpen(false)}
                onConfirm={handleSubmit(onSubmit)}
                amount={bonusAmount}
                type={'Showing'}
            />
        </div>
    );
};
