import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Text, HStack, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';
import { BackButton } from '../../common/components';
import { RootStore } from '../../store';
import { useHistory, useLocation } from 'react-router-dom';
import { HomeBrokerageRequests } from './components/HomeBrokerageRequests';
import { HomeMyRequests } from './components/HomeMyRequests';
import { HomeBlasts } from './components/HomeBlasts';

export const MyHomeContent = () => {
    useEffect(() => {
        
    }, []);

    return (
        <>
          <div className='home_row_cell'>
            <HomeBlasts />
          </div>
          <div className='home_row_cell'>
            <HomeMyRequests />
          </div>
          {/*
          <div className='home_row_cell'>
            <HomeBrokerageRequests />
          </div>
          */}
        </>
    );
};
