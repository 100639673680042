import React from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { MyRequestsType } from '../types';
import { RequestBox } from '../components';
import { Loader, InfiniteScroll } from '../../../common/components';
import { IdNameTypes } from '../../../common/types';

interface IMyRequestsTabProps {
    myRequests: MyRequestsType[];
    setPendingRequestToShowId: (value: number) => void;
    loadMoreMyRequests: () => void;
    loading: boolean;
    loadMoreLoading: boolean;
    requestCategories: IdNameTypes[];
}

export const MyRequestsTab: React.FC<IMyRequestsTabProps> = ({
    myRequests,
    setPendingRequestToShowId,
    loadMoreMyRequests,
    loading,
    loadMoreLoading,
    requestCategories,
}) => {
    return (
        <Box className="MY_REQUEST_TAB_COMPONENT">
            {loading ? (
                <Loader spinnerSize="md" centerHeight="calc(90vh - 180px)" />
            ) : (
                <InfiniteScroll height="calc(90vh - 180px)" onReachBottom={loadMoreMyRequests}>
                    {myRequests.length !== 0
                        ? myRequests.map((req, index) => (
                          <RequestBox
                            allowCountAccept={true}
                            key={index}
                            request={req}
                            onOpenModal={() => setPendingRequestToShowId(req.id)}
                            requestCategories={requestCategories}
                          />
                        ))
                        : null}
                    {!myRequests.length ? (
                        <Center h="100%">
                            <Text color="brand" fontSize="16px">
                                There is no requests at this time
                            </Text>
                        </Center>
                    ) : null}
                    {loadMoreLoading && <Loader spinnerSize="md" centerHeight="100px" />}
                </InfiniteScroll>
            )}
        </Box>
    );
};
