import * as yup from 'yup';
import { time12HoursRegExp, from8amTo9pmRegExp } from '../constants';
import { ErrorMessages } from '../schemes/messages';

export const ampmTimeYupResolver = yup
    .string()
    .required(ErrorMessages.REQUIRED)
    .matches(time12HoursRegExp, ErrorMessages.BAD_12_HOURS_TIME_FORMAT);

export const from8amTo9pmTimeYupResolver = yup
    .string()
    .required(ErrorMessages.REQUIRED)
    .test('bad-format', ErrorMessages.BAD_FROM_8AM_TO_9PM_FORMAT, value =>
        value ? from8amTo9pmRegExp.test(value) : false,
    );
