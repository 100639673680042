import React, { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Flex,
    HStack,
    PinInput,
    PinInputField,
    Button,
    CloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Loader } from '..';
import { useSelector } from 'react-redux';
import { dispatch, RootStore } from '../../../store';
import { useHistory } from 'react-router-dom';
import { colors } from '../../constants';
import { BackArrow } from '../../../assets/icons/BackArrow';
import { fonts } from '../../utils/fonts';
import { FormChangeBalance } from './FormChangeBalance';
import { formatPrice } from './utils';
import { getUserInfoRequest } from '../../../store/actions/user.actions';

interface IAddBalanceModal {
    isOpen: boolean;
    onClose: () => void;
}

export const AddBalanceModal: React.FC<IAddBalanceModal> = ({ isOpen, onClose }) => {
    const handleClose = () => {
        onClose();
    };
    const toast = useToast();
    const history = useHistory();
    const { user: currentUser } = useSelector((state: RootStore) => state.user);
    const [loader, setLoader] = useState<Boolean>(false);
    const [LoaderBtn, setLoaderBtn] = useState<Boolean>(false);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent
                w={'400px'}
                borderRadius={'18px'}
                h={'auto'}
                bgColor={colors.modalBackground}
                alignItems="center"
                paddingBottom="20px"
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    px="30px"
                    pt="10px"
                >
                    <Box as="button" onClick={onClose}>
                        <BackArrow />
                    </Box>
                    <ModalHeader flex={1} align="center">
                        <Text
                          fontSize="22px"
                          color="brand"
                          textAlign="center"
                          lineHeight="18px"
                          fontWeight={700}
                          fontFamily={fonts.montserrat}
                          >
                          Top up balance
                        </Text>
                    </ModalHeader>
                    <CloseButton onClick={onClose} />
                </Flex>
                <ModalBody w="100%" d="flex" flexDirection="column" overflowY="auto">
                  Your balance ${formatPrice(currentUser.balance)}
                  <br/>
                  <br/>
                  <FormChangeBalance 
                    emitSubmited={() => {
                        dispatch(getUserInfoRequest());
                        onClose()
                    }}
                  />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
