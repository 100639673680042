import React, { Dispatch, SetStateAction } from 'react';
import {
    Avatar,
    Box,
    Flex,
    HStack,
    Image,
    Text,
    Tag,
    MenuButton,
    Menu,
    IconButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react';
import { AddReportPayload, PostTypeResponse } from '../types';
import { baseUrl } from '../../../common/api/api.base';
import dayjs from 'dayjs';
import {
    BookMark,
    CommentsIcon,
    DeleteIcon,
    EditPostIcon,
    MoreIcon,
    ReportIcon,
} from '../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { saveTogglePostRequest } from '../store/actions';
import { colors } from '../../../common/constants';
import { getAvatarPicture } from '../../../common/utils';
import { IdNameTypes } from '../../../common/types';
import { RootStore } from '../../../store';

interface IPostBoxProps {
    item: PostTypeResponse;
    reportTypes: IdNameTypes[];
    onAddReport: (payload: AddReportPayload) => void;
    onDeletePost: (id: number) => void;
    setEditPostId: Dispatch<SetStateAction<number>>;
    setDetailsPostId: Dispatch<SetStateAction<number>>;
    setReportPostId?: Dispatch<SetStateAction<number>>;
    setReportTypeId?: Dispatch<SetStateAction<number>>;
}

export const PostBox: React.FC<IPostBoxProps> = ({
    item,
    reportTypes,
    onAddReport,
    onDeletePost,
    setEditPostId,
    setDetailsPostId,
    setReportTypeId,
    setReportPostId,
}) => {
    const currentUserId = useSelector((state: RootStore) => state.user.user.id);
    const dispatch = useDispatch();
    const postTags =
        item.tags.length > 2
            ? item.tags
                .slice(0, 2)
                .concat({ id: Number(new Date()), name: `+${item.tags.length - 2} More` })
            : item.tags;

    const onSaveTogglePost = () => {
        dispatch(
            saveTogglePostRequest({
                PostId: item.id,
                isSavedPost: !item.isSaved,
            }),
        );
    };

    const isMine = currentUserId === item.owner.id;

    return (
        <Box
            className="cell_blast_link_ads2"
            textAlign={'center'}
            position={'relative'}
            borderRadius={'16px'}
            width={'400px'}
            bgColor={'#FFFFFF'}
            p={'20px 16px 24px'}
            >
            {item.isSuspended && (
                <Box
                    py={'8px'}
                    top={0}
                    left={0}
                    right={0}
                    position={'absolute'}
                    bgColor={'#FFEFBE'}
                >
                    <Text
                        _before={{
                            content: `"!"`,
                            marginRight: '10px',
                        }}
                        color={colors.orange}
                        letterSpacing={'1px'}
                        textTransform={'uppercase'}
                        fontWeight={'600'}
                        fontSize={'12px'}
                        lineHeight={'16,8px'}
                    >
                        Suspended
                    </Text>
                </Box>
            )}

            <Box
                mt={item.isSuspended ? '40px' : 0}
                onClick={() => setDetailsPostId(item.id)}
                cursor={'pointer'}
            >
                {/*    Header*/}
                <Flex justifyContent={'space-between'} align={'center'}>
                    <Flex align={'center'}>
                        <Avatar
                            mr={'8px'}
                            boxSize={'6'}
                            name={'avatar'}
                            src={getAvatarPicture(item.owner.avatar)}
                        />
                        <Text
                            letterSpacing={1}
                            lineHeight={'21px'}
                            color="brand"
                            weight={500}
                            fontSize={'14px'}
                        >
                            {item.owner.fullName}
                        </Text>
                    </Flex>
                    {/*    DATE*/}
                    <Text color={'#888DAB'} lineHeight={'21px'} fontSize={'14px'} fontWeight={400}>
                        {dayjs(item.createdAt).format('MM/DD/YY')}
                    </Text>
                </Flex>
                {/*    IMAGE BOX*/}
                {item.photo.itemExtension && (
                    <Box overflow={'hidden'} borderRadius={'16px'} my={'16px'}>
                        <Image
                            w={'100%'}
                            h={'200px'}
                            objectFit={'cover'}
                            alt={'post image'}
                            src={`${baseUrl}/api/storage/static-images/${item.photo.itemHash}${item.photo.itemExtension}`}
                        />
                    </Box>
                )}
                {/*    Tittle*/}
                <Text
                    noOfLines={1}
                    color={'#16192C'}
                    fontSize={'16px'}
                    fontWeight={500}
                    letterSpacing={'2px'}
                    lineHeight={'22.4px'}
                >
                    {item.title}
                </Text>
                {/*    POST TAGS*/}
                <HStack my={'24px'} spacing={2}>
                    {postTags.map(item => (
                        <Tag p={'8px 12px'} borderRadius={'40px'} key={item.id} variant={'outline'}>
                            {item.name}
                        </Tag>
                    ))}
                </HStack>
            </Box>
            {/*    FOOTER POST*/}
            <Flex justifyContent={'space-between'} align={'center'}>
                <HStack align={'24px'}>
                    <Flex as={'button'} align={'center'} onClick={onSaveTogglePost}>
                        <BookMark colorFill={item.isSaved ? '#2C335C' : 'none'} />
                        {!!item.savedCount && <Text ml={'4px'}>{item.savedCount}</Text>}
                    </Flex>
                    <Flex align={'center'}>
                        <CommentsIcon />
                        {item.commentsCount && <Text ml={'4px'}>{item.commentsCount}</Text>}
                    </Flex>
                </HStack>
                {/*POST MENU */}

                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label={'More'}
                        icon={isMine ? <MoreIcon /> : <ReportIcon />}
                        boxShadow="none"
                        bg="transparent"
                    />
                    <MenuList>
                        {isMine ? (
                            <>
                                <MenuItem
                                    onClick={() => {
                                        setEditPostId(item.id);
                                    }}
                                    icon={<EditPostIcon />}
                                >
                                    Edit
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        onDeletePost(item.id);
                                    }}
                                    icon={<DeleteIcon />}
                                >
                                    Delete
                                </MenuItem>
                            </>
                        ) : (
                            <>
                                {reportTypes.map(reportType => (
                                    <MenuItem
                                        onClick={() => {
                                            if (reportType.id === 6) {
                                                setReportPostId?.(item.id);
                                                setReportTypeId?.(reportType.id);
                                            } else {
                                                onAddReport({
                                                    PostId: item.id,
                                                    TypeId: reportType.id,
                                                });
                                            }
                                        }}
                                        key={reportType.id}
                                    >
                                        {reportType.name}
                                    </MenuItem>
                                ))}
                            </>
                        )}
                    </MenuList>
                </Menu>
            </Flex>
        </Box>
    );
};
