import React from 'react';
import { Text, Box, Link, chakra } from '@chakra-ui/react';
import { colors } from '../../../common/constants';
import { fonts } from '../../../common/utils';
import { FormattedText, FormattedTextBold } from '../../../common/components/landing';

export const TermsTexts: React.FC = () => {
    return (
        <Box>
            <Text
                fontFamily={fonts.montserrat}
                fontSize="22px"
                lineHeight="18px"
                color={colors.brand}
                fontWeight="bold"
                mb="30px"
            >
                Terms of Service
            </Text>
            <FormattedText>Last updated: January 1, 2022</FormattedText>
            <FormattedText>
                These Terms of Service constitute a legally binding agreement between you and
                ZootPro, Inc. (“ZootPro”) governing your use of the ZootPro Platform. ZootPro’s
                websites (including <Link href="www.zootpro.com">www.zootpro.com</Link>,{' '}
                <Link href="www.zootprotm.com">www.zootprotm.com</Link>) (the “Sites”), mobile
                applications (the “Apps”), and related services, information and communications, and
                all its related documentation (the “Documentation”), are collectively referred to as
                the “ZootPro Platform.”
            </FormattedText>
            <FormattedText>
                ZOOTPRO Inc. as the Software Company and Licensor has developed, and is entitled to
                license to others including You, the Licensee and End User, a computer program
                called ZootPro. Licensee wishes to license the use of the Work, and Licensor has
                agreed to license such use pursuant to the terms of this agreement. The Work is
                licensed, not sold, to you.
            </FormattedText>
            <FormattedText>
                The use of all personal data you submit to the ZootPro Platform or which we collect
                about you is governed by our Privacy Policy (“Privacy Policy”). A copy of our
                Privacy Policy is available here. You acknowledge that by using the ZootPro Platform
                you have reviewed the Privacy Policy.
            </FormattedText>
            <FormattedText>
                Your consent at registration and continued use of the ZootPro Platform constitutes
                your acceptance of and agreement to all of the terms and conditions in these Terms
                of Service, the Privacy Policy, the ZootPro Compliance Promise (the “Compliance
                Promise”), as well as any future amendments and additions to this Agreement (as
                defined below) we may publish from time to time. If any future changes to this
                Agreement are unacceptable to you or cause you to no longer be in compliance with
                this Agreement, you must deactivate your account, and immediately stop using the
                ZootPro Platform.
            </FormattedText>
            <FormattedText>
                The Privacy Policy is incorporated by reference into these Terms of Service and
                together form and are hereinafter referred to as the “Agreement”.
            </FormattedText>
            <FormattedText>
                IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT AND ABIDE BY ITS TERMS, YOU MAY
                NOT USE OR ACCESS THE ZOOTPRO PLATFORM.
            </FormattedText>
            <FormattedText>
                PLEASE NOTE: FOR U.S. AND CANADIAN USERS, USERS AND ZOOTPRO RESOLVE DISPUTES THROUGH
                ARBITRATION. SECTION 28 CONTAINS AN ARBITRATION AGREEMENT WHICH REQUIRES YOU TO
                SUBMIT DISPUTES AND CLAIMS YOU HAVE AGAINST ZOOTPRO TO BINDING AND FINAL ARBITRATION
                ON AN INDIVIDUAL BASIS. PLEASE READ IT CAREFULLY AS IT AFFECTS YOUR LEGAL RIGHTS,
                INCLUDING, IF APPLICABLE, YOUR RIGHT TO OPT OUT OF ARBITRATION.
            </FormattedText>
            <FormattedText>
                BY ACKNOWLEDGING THE TERMS OF SERVICE AND/OR USING THE ZOOTPRO PLATFORM, YOU
                EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AND AGREE, WITHOUT LIMITATION
                OR QUALIFICATION, TO BE BOUND BY THIS AGREEMENT (INCLUDING, AS APPLICABLE FOR U.S
                AND CANADIAN USERS, THE DISPUTE RESOLUTION AND ARBITRATION AGREEMENT) AND YOU ACCEPT
                ALL OF ITS TERMS.
            </FormattedText>
            <FormattedText>
                DISCLAIMER: THIS WORK IS SOLELY INTENDED FOR USE BY LICENSED REAL ESTATE SALES
                PROFESSIONALS, LICENSED REAL ESTATE SALES BROKERS, AND REAL ESTATE VENDORS. LICENSED
                REAL ESTATE AGENTS REMAIN SUBJECT TO THEIR BROKER-AGENT AGREEMENTS AND REMAIN
                CULPABLE TO THE SUPERVISION OF THEIR BROKERS. ZOOTPRO IS NOT A BROKER AND DOES NOT
                SUPERVISE AGENTS. ZOOTPRO IS A SOSTWARE PRODUCT FACILITATING INNOVATIONS IN THE REAL
                ESTATE INDUSTRY. ZOOTPRO IS PROVIDED FOR USE SOLELY TO INDIVIDUALS THAT; (1) HOLD A
                VALID AND ACTIVE REAL ESTATE LICENSE ISSUED BY A STATE AND/OR TERRITORY OF THE
                UNITED STATES OF AMERICA ALLOWING SUCH INDIVIDUAL TO REPRESENT CONSUMERS IN THE
                PURCHASE AND/OR SALE OF REAL ESTATE; AND (2) HAVE ACCESS TO A MULTIPLE LISTING
                SERVICE BOUND OR ARE GOVERNED BY THE NATIONAL ASSOCIATION OF REALTOR OR APPLICABLE
                STATE ASSOCIATIONS OF REALTORSS; AND (3) ARE A MEMBER AND WORK UNDER THE SUPERVISION
                OF A BROKER RECOGNIZED AND OVERSEEN BY THE STATE’S REAL ESTATE COMMISSION BOARD AND
                LAWS; OR (4) HOLD A VALID AND ACTIVE REAL ESTATE BROKER’S LICENSE ISSUED BY A STATE
                AND/OR TERRITORY OF THE UNITED STATES OF AMERICA.
            </FormattedText>
            <FormattedText>
                BY CLICKING THE &quot;AGREE&quot; BUTTON, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND
                UNDERSTAND THIS AGREEMENT; (B) ACKNOWLEDGE AND CONFIRM THAT YOU ARE A LICENSED REAL
                ESTATE AGENT SUBJECT TO A BROKERAGE AGREEMENT AND SUPERVISION OF A BROKER; OR (C) A
                LICENSED REAL ESTATE BROKER SUPERVISED BY A STATE REAL ESTATE AUTHORITY WITHIN THE
                CONTINENTAL USA (D) YOU ADHERE BY THE CODE OF ETHICS OF THE NATIONAL ASSOCIATON OF
                REALTOR; (E) ACKNOWLEDGE AND REPRESENT THAT YOU ARE 18 YEARS OF AGE OR OLDER AND OF
                LEGAL AGE TO ENTER INTO A BINDING AGREEMENT; (F) ACKNOWLEDGE, ACCEPT AND CONSENT TO
                THE TERMS OF THIS AGREEMENT; AND (G) AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS.
            </FormattedText>
            <FormattedText>
                BY CONTINUING TO USE THE WORK, YOU WAIVE ANY CLAIMS OF IGNORANCE OF THIS AGREEMENT
                AND CONFIRM FULL KNOWLEDGE OF ITS TERMS.
            </FormattedText>
            <FormattedTextBold>
                IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT AND ABIDE BY ITS TERMS, YOU MAY
                NOT USE OR ACCESS THE ZOOTPRO PLATFORM.
            </FormattedTextBold>
            <FormattedText>
                PLEASE NOTE: FOR U.S. AND CANADIAN USERS, USERS AND ZOOTPRO RESOLVE DISPUTES THROUGH
                ARBITRATION. SECTION 28 CONTAINS AN ARBITRATION AGREEMENT WHICH REQUIRES YOU TO
                SUBMIT DISPUTES AND CLAIMS YOU HAVE AGAINST ZOOTPRO TO BINDING AND FINAL ARBITRATION
                ON AN INDIVIDUAL BASIS. PLEASE READ IT CAREFULLY AS IT AFFECTS YOUR LEGAL RIGHTS,
                INCLUDING, IF APPLICABLE, YOUR RIGHT TO OPT OUT OF ARBITRATION.
            </FormattedText>
            <FormattedText>
                BY ACKNOWLEDGING THE TERMS OF SERVICE AND/OR USING THE ZOOTPRO PLATFORM, YOU
                EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AND AGREE, WITHOUT LIMITATION
                OR QUALIFICATION, TO BE BOUND BY THIS AGREEMENT (INCLUDING, AS APPLICABLE FOR U.S
                AND CANADIAN USERS, THE DISPUTE RESOLUTION AND ARBITRATION AGREEMENT) AND YOU ACCEPT
                ALL OF ITS TERMS.
            </FormattedText>
            <FormattedText>A few highlights of these Terms of Service include:</FormattedText>
            <FormattedText>
                You must hold an active professional real estate license with a state body that is
                empowered to regulate real estate salespersons and brokers that are engaged in the
                sales and purchase of real estate. As a licensed real estate sales agent, you must
                be in submission to the supervision of a broker who is actively licensed and
                regulated by a state body in your selected jurisdiction of practice. You must be at
                least the legally required age in the jurisdiction in which you reside (18 years old
                for U.S. based users), and otherwise capable of entering into binding contracts, in
                order to use or access the ZootPro Platform (Section 2).
            </FormattedText>
            <FormattedText>
                Your agreement and acknowledgement that ZootPro is not a Real Estate Licensee or
                Broker.
            </FormattedText>
            <FormattedTextBold>
                Your agreement and acknowledgement that you remain and continue to solely be under
                the supervision of your real estate Broker.
            </FormattedTextBold>
            <FormattedText>
                Your agreement that the technology for the ZootPro Platform is provided “as is” and
                without warranty (Section 17). Your agreement that ZootPro provides no warranty and
                has no liability regarding User or Agent action on the ZootPro Platform or the
                performance of Tasks or Activities (Section 17). Your acknowledgment and agreement
                that ZootPro does not supervise, scope, direct, control, or monitor an Agent’s work
                and the Tasks or Activities performed (Section 1). Your acknowledgement and
                agreement that Hiring Agents and Brokers are solely responsible for determining if
                the Agent that they hire is qualified to perform the Task or Activity (Sections 1&
                23). Your acknowledgement and agreement that Agents are independent contractors of
                Hiring Agents and are not employees, independent contractors or service providers of
                ZootPro (Section 1). Your agreement to hold harmless and indemnify ZootPro from
                claims due to your use of or inability to use the ZootPro Platform or content
                submitted from your account to the ZootPro Platform (Section 18). For U.S. and
                Canadian Users, you agree to arbitrate disputes with ZootPro on an individual basis
                to the fullest extent permitted by applicable law in Section 28.
            </FormattedText>
            <FormattedTextBold>
                The ZootPro Platform Connects Real Estate Agents, Real Estate Brokers, and Vendors
            </FormattedTextBold>
            <FormattedText>
                The ZootPro Platform is a web and mobile-based multi-sided marketplace which enables
                connections between Hiring Real Estate Agent and Agents Who are Hired in a legally
                sound and compliance way through innovative software development and code. “Hiring
                Agents” are individual real estate sales agents seeking to obtain short-term
                services ( “Tasks or Activities” ) from other real estate sales Agents and are
                therefore hiring those Agents to conduct such Tasks and Activities, and “Agents” are
                real estate sales agents seeking to perform Tasks or Activities for Hiring Agents.
                Hiring Agents and Agents together are hereinafter referred to as “Users.” If you
                agree on the terms of a Task with another User, you and such other User form a
                Service Agreement directly between the two of you as set forth in more detail in
                Section 3 below.
            </FormattedText>
            <FormattedText>
                AGENTS ARE INDEPENDENT BUSINESS OWNERS. AGENTS ARE INDEPENDENT CONTRACTORS OF HIRING
                AGENTS AND NOT EMPLOYEES, PARTNERS, REPRESENTATIVES, AGENTS, JOINT VENTURERS,
                INDEPENDENT CONTRACTORS OR FRANCHISEES OF ZOOTPRO. ZOOTPRO DOES NOT PERFORM TASKS OR
                ACTIVITIES, ZOOTPRO IS NOT A REAL ESTATE BROKER AND DOES NOT EMPLOY INDIVIDUALS TO
                PERFORM TASKS OR ACTIVITIES. BY CONNECTING HIRING AGENTS WITH REAL ESTATE AGENTS
                LOOKING TO PROVIDE THEIR SERVICES, ZOOTPRO OPERATES AS A SOFTWARE MANUFACTURER
                PROVIDING AND ONLINE MARKETPLACE THAT CONNECTS HIRING AGENTS WITH SERVICE PROVIDERS
                (OTHER AGENTS) WHO WISH TO PERFORM A VARIETY OF TASKS IN A LEGALLY COMLIANT MANNER
                THAT DOES NOT VIOLATE REAL ESTATE RULES OR BROKER-AGENT AGREEMENTS, ZOOTPRO SOFTWARE
                IS BUILT TO RESPECT BROKER AGREEMENTS YET FACILLIATE AGENT TO AGENT TRANSACTIONS.
            </FormattedText>
            <FormattedText>
                USERS HEREBY ACKNOWLEDGE THAT ZOOTPRO IS NOT A REAL ESTATER BROKER, ZOOTPRO DOES NOT
                SUPERVISE, SCOPE, DIRECT, CONTROL OR MONITOR AN AGENT’S WORK AND EXPRESSLY DISCLAIMS
                (TO THE EXTENT PERMITTED BY LAW) ANY RESPONSIBILITY AND LIABILITY FOR THE WORK
                PERFORMED AND THE TASKS IN ANY MANNER, INCLUDING BUT NOT LIMITED TO A WARRANTY OR
                CONDITION OF GOOD AND WORKMANLIKE SERVICES, WARRANTY OR CONDITION OF QUALITY OR
                FITNESS FOR A PARTICULAR PURPOSE, OR COMPLIANCE WITH ANY LAW, STATUTE, ORDINANCE,
                REGULATION, OR CODE.
            </FormattedText>
            <FormattedText>
                Any reference on the ZootPro Platform to an Agent being licensed or credentialed in
                some manner, or &quot;badged,&quot; “reliable,” “reliability rate,” “elite,” “great
                value,” &quot;background checked,&quot; “vetted” (or similar language) designations
                indicates only that the Agent has completed a relevant account registration process
                or met certain criteria and does not represent anything else. Any such description
                is not an endorsement, certification or guarantee by ZootPro of such Agent’s skills
                or qualifications or whether they are insured, trustworthy, safe or suitable.
                Instead, any such description is intended to be useful information for Hiring Agents
                to evaluate when they make their own decisions about the identity and suitability of
                Agents whom they select, interact, or contract with via the ZootPro Platform. The
                ZootPro Platform enables connections between Users for the fulfillment of Tasks or
                Activities. ZootPro is not responsible for the performance or communications of
                Users, nor does it have control over the quality, timing, legality, failure to
                provide, or any other aspect whatsoever of Tasks or Activities, Agents, or Hiring
                Agents, nor of the integrity, responsibility, competence, qualifications, or any of
                the actions or omissions whatsoever of any Users, or of any ratings or reviews
                provided by Users with respect to each other. ZootPro makes no warranties or
                representations about the suitability, reliability, timeliness, or accuracy of the
                Tasks or Activities requested or services provided by, or the communications of or
                between, Users identified through the ZootPro Platform, whether in public or
                private, via on- or off-line interactions, or otherwise howsoever.
            </FormattedText>
            <FormattedTextBold>
                Agent Background Checks and User Representations and Warranties Agent Background
                Checks
            </FormattedTextBold>
            <FormattedText>
                Agents may be subject to a review process before they can register for and during
                their use of the ZootPro Platform, which may include but is not limited to identity
                checks, and broker and license verification. Although ZootPro may perform these
                background checks, ZootPro cannot confirm that any User is who they claim to be, and
                ZootPro cannot and does not assume any responsibility for the accuracy or
                reliability of these background check information. When interacting with other
                Users, you should exercise caution and common sense to protect your personal safety,
                data, and property, just as you would when interacting with other persons whom you
                don’t know. ZootPro will not be liable for any false or misleading statements made
                by Users of the ZootPro Platform.
            </FormattedText>
            <FormattedText>
                NEITHER ZOOTPRO, NOR ITS PARENTS, AFFILIATES OR LICENSORS, INCLUDING THEIR
                RESPECTIVE DIRECTORS, OFFICERS, SHAREHOLDERS, AGENTS, INVESTORS, SUBSIDIARIES,
                ATTORNEYS, REPRESENTATIVES, INSURERS, EMPLOYEES, SUCCESSORS AND ASSIGNS
                (COLLECTIVELY REFERRED TO AS “AFFILIATES” ) IS RESPONSIBLE OR LIABLE FOR THE
                CONDUCT, ACTS, OR OMISSIONS, WHETHER ONLINE OR OFFLINE, OF ANY USER OF THE ZOOTPRO
                PLATFORM AND, TO THE EXTENT PERMITTED BY LAW, YOU HEREBY RELEASE ZOOTPRO AND
                AFFILIATES FROM ANY AND ALL LIABILITY, CLAIMS, DEMANDS, OR DAMAGES OF EVERY KIND AND
                NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED,
                ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE ZOOTPRO PLATFORM.
            </FormattedText>
            <FormattedText>
                User Representations and Warranties All Users represent and warrant that:
            </FormattedText>
            <FormattedText tab>
                You are a licensed real estate sales agent regulated by a state commission within
                the United States;
            </FormattedText>
            <FormattedText tab>
                You are a licensed real estate sales broker regulated by a state commission within
                the United States;
            </FormattedText>
            <FormattedText tab>
                You are a licensed real estate sales agent subject to the supervision of a licensed
                real estate broker regulated by a state commission within the United States;
            </FormattedText>
            <FormattedText tab>
                You adhere to the Code of Ethics of the National Association of Realtors; or the
                state Realtor Association in which you are licensed;
            </FormattedText>
            <FormattedText tab>
                You adhere to the Code of Ethics of the National Association of Realtors; or the
                state Realtor Association in which you are licensed;
            </FormattedText>
            <FormattedText tab>
                You have the right, authority and capacity to enter into this Agreement and to abide
                by the terms and conditions of this Agreement, and that you will so abide;
            </FormattedText>
            <FormattedText tab>
                You have read, understand, and agree to be bound by these Terms of Service, and the
                Privacy Policy;
            </FormattedText>
            <FormattedText tab>
                You shall only perform Tasks or Activities arising out of the ZootPro platform in a
                jurisdiction where you hold a valid and active real estate license;
            </FormattedText>
            <FormattedText>
                Where you schedule an open house, you warrant and acknowledge that you have
                exclusive right of representation in the sale of such property that is the subject
                of open house; You will respect the privacy (including without limitation private,
                family and home life), property, and data protection rights of the end-clients of
                Hiring Agents and will not record (whether video or audio or otherwise) any Task or
                any interaction by or with any User, Hiring Agent end-clients, and/or ZootPro in
                connection with the ZootPro Platform without the prior written consent of ZootPro
                and/or the relevant User, as applicable; You will fulfill the commitments you make
                to other Users, communicate clearly and promptly through the message thread, be
                present and/or available at the time you agree upon with your Hiring Agent or other
                Agent, and only utilize the third party payment service provider specified or
                approved by ZootPro to make or receive payment for services provided through the
                ZootPro Platform (the “PSP”); You will pay a late fee for any Tasks or Activities
                for which you are hired that are cancelled within one hour of the start time of such
                Task or Activity;
            </FormattedText>
            <FormattedText>
                You will pay a late fee for any Tasks or Activities requested through the ZootPro
                platform that are cancelled within one hour of the start time of such Task or
                Activity;
            </FormattedText>
            <FormattedText>
                You will act professionally and responsibly in your interactions with other Users;
            </FormattedText>
            <FormattedText>
                You will use your real name or business name on your profile;
            </FormattedText>
            <FormattedText>
                When using or accessing the ZootPro Platform, you will act in accordance with all
                applicable local, state, provincial, national, or international law or custom and in
                good faith;
            </FormattedText>
            <FormattedText>
                You will not use the ZootPro platform for the purchase or delivery of alcohol, or
                any other controlled or illegal substances or services;
            </FormattedText>
            <FormattedText>
                Where you enter into this Agreement on behalf of a company or other organization,
                you represent and warrant that you have authority to act on behalf of that entity
                and to bind that entity to this Agreement;
            </FormattedText>
            <FormattedText>
                Other than as fully and promptly disclosed in writing to ZootPro, you do not have
                any motivation, status, or interest that ZootPro may reasonably wish to know about
                in connection with the ZootPro Platform, including without limitation, if you are
                using or will or intend to use the ZootPro Platform for any journalistic, academic,
                investigative, or unlawful purpose.
            </FormattedText>
            <FormattedText>
                When acting as Agents that are hired, Users additionally represent and warrant that:
            </FormattedText>
            <FormattedText tab>
                When using the ZootPro Platform, you are operating as a sole proprietor,
                partnership, limited liability Company, limited liability partnership, corporation
                or other business entity;
            </FormattedText>
            <FormattedText tab>
                You are customarily engaged in conducting licensed real estate sales and activities
                to consumers who are clients as expected of Agents providing services through the
                ZootPro Platform, and you will maintain an independent Agent in this regard to the
                consumer end-clients;
            </FormattedText>
            <FormattedText tab>
                You will not interfere or in any way demean, belittle or try to cause a negative
                change in the relationship between the consumer end-client and the Hiring Agent;
            </FormattedText>
            <FormattedText tab>
                You have the unrestricted right to work in the jurisdiction in which you will be
                performing Tasks or Activities as licensed real estate sales agent and you are not
                under any sanctions or restrictions preventing you from the practice of real estate
                sales and its corresponding tasks or Activities;
            </FormattedText>
            <FormattedText>
                If the Task is performed in a jurisdiction that requires you to have additional
                business licenses or business tax registrations, you have the required business
                license or business tax registration;
            </FormattedText>
            <FormattedText>
                You are responsible for identifying and obtaining any required licenses, permits, or
                registrations before offering your services and undertaking Tasks or Activities
                (including but not limited to a state contractor’s license pursuant to California
                Business and Professions Code section 7000 et seq., if such license is applicable to
                the Task you are performing);
            </FormattedText>
            <FormattedText>
                You have any and all insurance required to operate your business and provide your
                services;
            </FormattedText>
            <FormattedText>
                You will use your real name or business name and an up-to-date photo on your
                profile;
            </FormattedText>
            <FormattedText>
                You will honor your commitments to other Users on the Platform, including by
                responding to invitations promptly; performing the Task(s) as agreed upon with your
                Hiring agent; and providing timely, high-quality services to your Hiring agents;
            </FormattedText>
            <FormattedText>
                You will only offer and provide services for which you have the necessary skills and
                expertise, and provide those services safely and in accordance with all applicable
                laws.
            </FormattedText>
            <FormattedTextBold>Contract between Hiring agents and Agents</FormattedTextBold>
            <FormattedText>
                You acknowledge and agree that a legally binding contract with another User (the
                “Service Agreement”) is formed when you and that User agree on the terms of a Task
                or Activity. The terms of the Service Agreement include the terms set forth in this
                Section 3, the engagement terms proposed and accepted on the ZootPro Platform, and
                any other contractual terms accepted by both the Agent and their Hiring agent to the
                extent such terms do not conflict with the terms in this Agreement, including this
                Section 3, and do not expand ZootPro’s obligations or restrict ZootPro’s rights
                under this Agreement. ZootPro is not a party to any Service Agreement and the
                formation of a Service Agreement will not, under any circumstances, create an
                employment, broker-agent, or other service relationship between ZootPro and the
                Agent, nor will it create an employment relationship between the Hiring agent being
                serviced and the Agent. Users do not have authority to enter into written or oral —
                whether implied or express — contracts on behalf of ZootPro. The hired Agent is
                obligated to personally perform the Task for which he was hired. Agents may not
                engage assistants, helpers, subcontractors or other personnel (collectively “Agent
                Assistants”). An Agent’s failure to comply with this provision shall be a violation
                of these Terms of Service and could lead to removal from the ZootPro Platform.
            </FormattedText>
            <FormattedText>
                The Hiring Agent shall pay the Hired Agent(s) in full for all Tasks and Activities
                via the PSP as indicated on the ZootPro Platform, at the rates agreed to by the
                parties in the Service Agreement. Each User agrees to comply with the Service
                Agreement and this Agreement during the engagement, performance and completion of a
                Task. Agent agrees and acknowledges that there is a $30 cancellation fee for any
                Task or Activity which is not completed, and for any Task or Activity that is
                cancelled within one hour of the start time of such Task or Activity.
            </FormattedText>
            <FormattedTextBold>Billing and Payment</FormattedTextBold>
            <FormattedText>
                Users of the ZootPro Platform contract for Tasks or Activities directly with other
                Users. ZootPro will not be a party to any contracts for Tasks or Activities or
                services. Hiring agent, and not ZootPro, is responsible for payment for all Task
                services through the ZootPro Platform. Such payment must be made via the PSP.
                ZootPro is not obligated to compensate a Hired Agent for Hiring agent’s failure to
                pay for services. The One-time registration fee, Task Payment, service charge, and
                Confidence fee must be paid through the PSP. All Hiring agents on the ZootPro
                Platform will be required to provide their payment method details to ZootPro and the
                PSP. Hired Agents are responsible for accurately confirming completion of work to
                the Hiring agents within 24 hours of the work being performed, even if the Task is
                not completed in its entirety or is designated as “ongoing”. Hiring agents will be
                responsible for paying for each Task (the “Payment(s)” ), which will include (a) the
                pricing terms of the Task agreed upon by the parties ( “Task Payment” ), (b) any out
                of pocket expenses agreed upon by the parties and submitted by the Hired Agent in
                connection with the Task, (c) the service charge ZootPro assesses to the Hiring
                agent , and (d) the Confidence Fee ZootPro assesses to the Hiring agent for customer
                support, services in support of the Compliance Pledge, and other various other
                services, or (e) cancellation charges, if applicable. In addition, a tip or
                gratuity, as applicable, may be added by the Hiring agent or at the Hiring agent’s
                direction to the Invoice(s), and all such tips or gratuities shall go directly to
                the Agent. Hiring agents may also be charged credit card processing fees equal to 3%
                of the aggregate expense amount if expenses related to a Task individually, or Tasks
                or Activities in the aggregate over a 30-day period, exceed $300 / 300CAD, as
                applicable in your country. Agents will be responsible for paying (i) registration
                fees, if applicable, (ii) repayment of erroneous payments, and (iii) cancellation
                fees, where applicable. Users will be required to set up an account with the PSP,
                which may require any or all of the following: registration with the PSP, consent to
                the terms of service of the PSP, and completion of a vetting process and/or account
                validation at the request of the PSP. By accepting these Terms of Service, each User
                agrees that they have downloaded or printed, and reviewed and agreed to, the PSP
                Services Agreement (the “PSP Services Agreement”). Please note that ZootPro is not a
                party to the PSP Services Agreement and that you, the PSP and any other parties
                listed in the PSP Services Agreement are the parties to the PSP Services Agreement
                and that ZootPro has no obligations, responsibility or liability to any Agent or
                other party under the PSP Services Agreement.
            </FormattedText>
            <FormattedText>
                To help prevent fraud and safeguard User information from the risk of unauthorized
                access, ZootPro and/or the PSP may validate an account before activation and prior
                to each booking. As part of the validation process, temporary charges may be placed
                on the account associated with the User and then refunded within 1-5 business days.
                This temporary charge may vary depending on the estimated value of the Task and
                expenses.
            </FormattedText>
            <FormattedText>
                When Hiring agent receives confirmation through the ZootPro Platform or via email
                that a Task has been completed, Hiring agent automatically authorizes the PSP to
                process the Invoice(s). Hiring agents may be billed a one-hour cancellation charge
                as damages at the Agent’s hourly rate through the PSP if you book a Task, but cancel
                it before the scheduled time for performance (for users in Germany, please refer to
                the Jurisdiction-specific provisions).
            </FormattedText>
            <FormattedText>
                ZootPro reserves the right (but not the obligation) upon request from Hiring agent
                or Agent, or upon notice of any potential fraud, unauthorized charges or other
                misuse of the ZootPro Platform, to place on hold any Task Payment, out of pocket
                expenses, and/or tip or gratuity (if applicable), or refund or provide credits, or
                arrange for the PSP to do so.
            </FormattedText>
            <FormattedText>
                Users of the ZootPro Platform may be liable for any taxes or similar charges
                (including VAT, if applicable in the country where the Task is performed) required
                to be collected and/or paid on the Tasks or Activities and/or fees provided under
                the Agreement.
            </FormattedText>
            <FormattedText>
                In certain jurisdictions, applicable rules may require that we collect or report tax
                and/or revenue information about you. You agree that ZootPro may issue on your
                behalf receipts or similar documentation to facilitate accurate tax reporting.
            </FormattedText>
            <FormattedTextBold>Contests, Gift Cards, and Promotional Codes</FormattedTextBold>
            <FormattedText>
                ZootPro may from time to time provide certain promotional opportunities and contests
                to Users. All such promotions will be run at the sole discretion of ZootPro, and can
                be implemented, modified, or removed at any time by ZootPro without advance
                notification. The liability of ZootPro and Affiliates, and its ZootPro’s corporate
                partners pursuant to such promotional opportunities and contests shall be subject to
                the limitations set forth in Section 17 of these Terms of Service.
            </FormattedText>
            <FormattedText>
                ZootPro promotional codes (“Promo Codes”) may be available and can be used to pay
                Task Payments and the ZootPro service charge and Trust & Support fee in part or in
                full, but may not be used to pay for reimbursement of out of pocket expenses or tips
                or gratuity associated with a Task provided through the ZootPro Platform.
            </FormattedText>
            <FormattedText>A. Promo Codes</FormattedText>
            <FormattedText>
                Promo Codes are an offer by ZootPro to reduce the amount a Hiring agent has to pay
                in relation to a Task Payment, service charge, and/or Trust & Support fee. Promo
                Codes will not affect the amount of the Task Payment an Agent ultimately receives.
                The use or application of any Promo Code is solely intended as a promotional
                initiative and does not in any way create a relationship or engagement between
                ZootPro and the Agent or Hiring agent, or constitute wages, fees or other amounts
                paid to the Agent. You agree that you will use Promo Codes in accordance with the
                terms and conditions governing the Promo Code. A new user Promo Code may only be
                used once per User. ZootPro reserves the right to withhold or deduct credits or
                other features or benefits obtained through the use of a Promo Code by you or any
                other User in the event that the use or redemption of a Promo Code was in error,
                fraudulent, illegal, or otherwise in violation of the applicable Promo Code terms
                and conditions or this Agreement.
            </FormattedText>
            <FormattedText>Public Areas; Acceptable Use</FormattedText>
            <FormattedText>
                The ZootPro Platform may contain profiles, email systems, blogs, message boards,
                reviews, ratings, task postings, chat areas, news groups, forums, communities and/or
                other message or communication facilities ( “Public Areas” ) that allow Users to
                communicate with other Users. You may only use such community areas to send and
                receive messages and materials that are relevant and proper to the applicable forum.
                For the safety and integrity of the ZootPro Platform, you should not share your
                personal contact information with other Users.
            </FormattedText>
            <FormattedText>
                Without limitation, the ZootPro Platform may not be used for any of the following
                purposes:
            </FormattedText>
            <FormattedText tab>
                To defame, abuse, harass, stalk, threaten, intimidate, misrepresent, mislead or
                otherwise violate the rights (such as, but not limited to, rights of privacy,
                confidentiality, reputation, and publicity) of others, including Users and ZootPro
                staff;
            </FormattedText>
            <FormattedText tab>
                To publish, post, upload, distribute or disseminate any profane, defamatory,
                infringing, obscene or unlawful language, material or information;
            </FormattedText>
            <FormattedText tab>
                To upload files that contain software or other material that violates the
                intellectual property rights (or rights of privacy or publicity) of any User, third
                party, or ZootPro;
            </FormattedText>
            <FormattedText tab>
                To upload files or scripts such as Trojan horses, corrupt files, SQL injections,
                worms, timebombs, cancelbots or any other files or software that may damage ZootPro
                or its Users’ computers;
            </FormattedText>
            <FormattedText tab>
                To advertise or offer to sell any goods or services for any commercial purpose
                through the ZootPro Platform which are not relevant to the Task services;
            </FormattedText>
            <FormattedText tab>
                To post or complete a Task requiring a User to (i) purchase or obtain gift cards or
                money orders (ii) purchase high value items (over $300 / 300 CAD, as applicable in
                your country) without obtaining pre- authorization from ZootPro, (iii) travel into
                different countries during the performance of a Task, (iv) provide ridesharing or
                other peer to peer transportation services, (v) post ratings or reviews on any third
                party website in breach of such third party website’s terms of use, or (vi)
                otherwise engage in activity that is illegal or deemed dangerous, harmful or
                otherwise inappropriate by ZootPro in its sole discretion;
            </FormattedText>
            <FormattedText tab>
                To conduct or forward surveys, contests, pyramid schemes, or chain letters;
            </FormattedText>
            <FormattedText tab>
                To impersonate another person or a User or allow any other person or entity to use
                your user profile to post or view comments (except as may be permitted under Section
                3 for Hiring agent’s Agent).
            </FormattedText>
            <FormattedText>While using the ZootPro Platform, you may not:</FormattedText>
            <FormattedText tab>
                Use the ZootPro Platform for any unauthorized or illegal purpose, including but not
                limited to posting or performing a Task in violation of local, state, provincial,
                national, or international law;
            </FormattedText>
            <FormattedText tab>
                Post or upload any content which you have not obtained the necessary rights and
                permissions to use accordingly;
            </FormattedText>
            <FormattedText tab>Post the same Task repeatedly (“spamming”);</FormattedText>
            <FormattedText tab>
                Download any file posted by another User that you know, or reasonably should know,
                cannot be legally distributed through the ZootPro Platform;
            </FormattedText>
            <FormattedText tab>
                Restrict or inhibit any other User from using and enjoying the Public Areas;
            </FormattedText>
            <FormattedText tab>
                Imply or state that any statements you make (whether on or off the ZootPro Platform)
                are endorsed by ZootPro, without the prior written consent of ZootPro;
            </FormattedText>
            <FormattedText tab>
                Use a robot, spider, manual, meta tag, “hidden text,” agent, robot, script, and/or
                automatic processes or devices to data-mine, data-crawl, scrape, collect, mine,
                republish, redistribute, transmit, sell, license, download, manage or index the
                ZootPro Platform, or the electronic addresses or personal information of others, in
                any manner;
            </FormattedText>
            <FormattedText tab>
                Frame or utilize framing techniques to enclose the ZootPro Platform or any portion
                thereof; Hack or interfere with the ZootPro Platform, its servers or any connected
                networks;
            </FormattedText>
            <FormattedText tab>
                Adapt, alter, license, sublicense or translate the ZootPro Platform for your own
                personal or commercial use; Remove, alter, or misuse, visually or otherwise, any
                copyrights, trademarks or proprietary marks or rights owned by ZootPro and
                Affiliates;
            </FormattedText>
            <FormattedText tab>
                Upload content to the ZootPro Platform that is offensive and/or harmful, including,
                but not limited to, content that advocates, endorses, condones or promotes racism,
                bigotry, hatred or physical harm of any kind against any individual or group of
                individuals;
            </FormattedText>
            <FormattedText>
                Upload content that provides materials or access to materials that exploit people in
                an abusive, violent or sexual manner;
            </FormattedText>
            <FormattedText>
                Solicit for any other business, website or service, or otherwise contact Users for
                employment, contracting or any purpose not related to use of the ZootPro Platform as
                set forth herein;
            </FormattedText>
            <FormattedText>
                Collect usernames, email addresses, or other personal information of Users by
                electronic or other means; Use the ZootPro Platform or the Task services in
                violation of this Agreement;
            </FormattedText>
            <FormattedText>
                Use the ZootPro Platform in a manner that is false or misleading (directly or by
                omission or failure to update information), or for the purpose of accessing or
                otherwise obtaining ZootPro’s trade secret information for public disclosure or
                other purposes;
            </FormattedText>
            <FormattedText>
                Attempt to circumvent the payments system or service charge or Trust & Support fee
                in any way including, but not limited to, making or processing payments outside of
                the ZootPro Platform, providing inaccurate information on invoices, or otherwise
                invoicing in a fraudulent manner;
            </FormattedText>
            <FormattedText>
                Register under different usernames or identities after your account has been
                suspended or terminated, or register under multiple usernames or false identities,
                or register using a false or disposable email or phone number;
            </FormattedText>
            <FormattedText>
                Cause any third party to engage in the restricted activities above; or Use tools
                with the goal of masking your IP address (like the TOR network).
            </FormattedText>
            <FormattedText>
                You understand that all submissions made to Public Areas will be public and that you
                will be publicly identified by your name or login identification when communicating
                in Public Areas. ZootPro will not be responsible for the action of any Users with
                respect to any information or materials posted in Public Areas.
            </FormattedText>
            <FormattedTextBold>Mobile App Updates and Upgrades</FormattedTextBold>
            <FormattedText>
                By installing the App(s), you consent to the installation of the App(s) and any
                updates or upgrades that are released through the ZootPro Platform. The App
                (including any updates or upgrades) may (i) cause your device to automatically
                communicate with ZootPro’s servers to deliver the App functionality and to record
                usage metrics, (ii) affect App-related preferences or data stored on your device,
                and (iii) collect personal information as set out in our Privacy Policy. You can
                uninstall the App(s) at any time.
            </FormattedText>
            <FormattedTextBold>Deactivation and Suspension</FormattedTextBold>
            <FormattedText>
                ZootPro may suspend your right to use the ZootPro Platform pending its investigation
                of a potential breach by you of this Agreement. ZootPro may deactivate your account
                or limit your use of the ZootPro Platform upon its determination that you breached a
                provision of this Agreement (a “User Breach”). ZootPro will provide you with written
                notice of its determination as required by law, unless we have reason to believe the
                User’s account has been compromised such that the notice would go to the wrong
                person, or notice would otherwise be counterproductive or would create a risk to
                safety. If you wish to appeal this determination, please contact{' '}
                {<Link href="mailto:support@zootpro.com">support@zootpro.com</Link>} within 14 days
                of receipt of such notice with the grounds for your appeal.
            </FormattedText>
            <FormattedText>
                If ZootPro suspends or deactivates your account or limits your use of the ZootPro
                Platform pursuant to this Section 8, you are thereafter prohibited from registering
                and creating a new account under your name or business name, a fake or borrowed
                name, or the name of any third party, even if you may be acting on behalf of the
                third party.
            </FormattedText>
            <FormattedText>
                Even after your right to use the ZootPro Platform is suspended, terminated or
                limited, this Agreement will remain enforceable against you. ZootPro reserves the
                right to take appropriate legal action pursuant to the Agreement.
            </FormattedText>
            <FormattedText>
                ZootPro reserves the right to modify or discontinue, temporarily or permanently, all
                or any portion of the ZootPro Platform at its sole discretion. ZootPro will provide
                you with notice of any such modification or discontinuation as required by law. To
                the extent permitted by law, ZootPro shall not be liable to you for any modification
                or discontinuance of all or any portion of the ZootPro Platform. ZootPro has the
                right to restrict anyone from completing registration as an Agent if such person may
                threaten the safety and integrity of the ZootPro Platform, or if such restriction is
                necessary to address any other reasonable business concern. You may terminate this
                Agreement at any time by ceasing all use of the ZootPro Platform and deactivating
                your account.
            </FormattedText>
            <FormattedTextBold>Account, Password, Security, and Telephone Communications</FormattedTextBold>
            <FormattedText>
                You must register with ZootPro and create an account to use the ZootPro Platform.
                You are responsible for maintaining the confidentiality of any log-in, password, and
                account number provided by you or given to you by ZootPro for accessing the ZootPro
                Platform. You are solely and fully responsible for all activities that occur under
                your password or account, even if not authorized by you. ZootPro has no control over
                the use of any User’s account and expressly disclaims any liability derived
                therefrom. Should you suspect that any unauthorized party may be using your password
                or account or you suspect any other breach of security, you agree to notify ZootPro
                immediately. You acknowledge that telephone calls to or from ZootPro, together with
                its agents and Affiliates, may be monitored and recorded for the purposes of quality
                control and training. You verify that any contact information provided to ZootPro,
                its agents and Affiliates, and Users, including, but not limited to, your name,
                business name, mailing address, email address, your residential or business
                telephone number, and/or your mobile telephone number, is true and accurate. You
                verify that you are the current subscriber or owner of any telephone number that you
                provide. You are strictly prohibited from providing a phone number that is not your
                own. If we discover that any information provided in connection with your
                registration is false or inaccurate, we may suspend or deactivate your account.
                Should any of your contact information change, you agree to immediately notify
                ZootPro before the change goes into effect by visiting{' '}
                {<Link href="https://help.tr.co">help.tr.co</Link>}. If the change regards ownership
                of your telephone numbers, you may notify ZootPro by texting STOP to any text
                message sent to the retiring phone number.
            </FormattedText>
            <FormattedTextBold>User Created Content</FormattedTextBold>
            <FormattedText>
                “User Created Content” is defined as any information and materials you provide to
                ZootPro, its agents, Affiliates, and corporate partners, or other Users in
                connection with your registration for and use of the ZootPro Platform and
                participation in ZootPro promotional campaigns, including without limitation the
                information and materials posted or transmitted for use in Public Areas. You are
                solely responsible for User Created Content, and we act merely as a passive conduit
                for your online distribution and publication of your User Created Content. You
                acknowledge and agree that ZootPro is not involved in the creation, development of
                User Created Content, disclaims any responsibility for User Created Content, and
                cannot be liable for claims arising out of or relating to User Created Content.
                Further, you acknowledge and agree that ZootPro has no obligation to monitor or
                review User Created Content, but reserves the right to limit or remove User Created
                Content if it is not compliant with the terms of this Agreement.
            </FormattedText>
            <FormattedText>
                You hereby represent and warrant to ZootPro that your User Created Content (a) will
                not be false, inaccurate, incomplete or misleading; (b) will not be fraudulent or
                involve the transfer or sale of illegal, counterfeit or stolen items; (c) will not
                infringe on any third party’s privacy, or copyright, patent, trademark, trade secret
                or other proprietary right or rights of publicity or personality (to the extent
                recognized by law in the country where the Task is performed); (d) will not violate
                any law, statute, ordinance, code, or regulation (including without limitation those
                governing export control, consumer protection, unfair competition,
                anti-discrimination, incitement of hatred or false or misleading advertising,
                anti-spam or privacy); (e) will not be defamatory, libelous, malicious, threatening,
                or harassing; (f) will not be obscene or contain pornography (including but not
                limited to child pornography) or be harmful to minors; (g) will not contain any
                viruses, scripts such as Trojan Horses, SQL injections, worms, time bombs, corrupt
                files, cancelbots or other computer programming routines that are intended to
                damage, detrimentally interfere with, surreptitiously intercept or expropriate any
                system, data or personal information; (h) will not claim or suggest in any way that
                you are employed or directly engaged by or affiliated with ZootPro or otherwise
                purport to act as a representative or agent of ZootPro; and (i) will not create
                liability for ZootPro or cause ZootPro to lose (in whole or in part) the services of
                its Internet Service Providers (ISPs) or other partners or suppliers.
            </FormattedText>
            <FormattedText>
                The ZootPro Platform hosts User Created Content relating to reviews and ratings of
                specific Agents (“Feedback”). Feedback is such User’s opinion and not the opinion of
                ZootPro, and has not been verified or approved by ZootPro. You agree that ZootPro is
                not responsible or liable for any Feedback or other User Created Content. ZootPro
                encourages each User to give objective, constructive and honest Feedback about the
                other Users with whom they have transacted. ZootPro is not obligated to investigate
                any remarks posted by Users for accuracy or reliability or to consider any
                statements or materials posted or submitted by Users about any Feedback but may do
                so at its discretion. You agree that Feedback enables Users to post and other Users
                to read about Users’ expression of their experiences and that you will not complain
                or take any action merely because you happen to disagree with such Feedback. You may
                request removal of a review that violates this Agreement or the ZootPro Ratings and
                Reviews Policy by contacting the Support team at{' '}
                {<Link href="mailto:support@zootpro.com">support@zootpro.com</Link>} Each Hiring
                agent should undertake their own research prior to booking to be satisfied that a
                specific Agent has the right qualifications for a Task.
            </FormattedText>
            <FormattedText>
                ZootPro respects the personal and other rights of others, and expects Users to do
                the same. ZootPro is entitled to identify a User to other Users or to third parties
                who claim that their rights have been infringed by User Created Content submitted by
                that User, so that they may attempt to resolve the claim directly.
            </FormattedText>
            <FormattedText>
                If a User believes, in good faith, that any User Created Content provided on or in
                connection with the ZootPro Platform is objectionable or infringes any of its rights
                or the rights of others (e.g. counterfeiting, insult, invasion of privacy), the User
                is encouraged to notify ZootPro by using the Report button or writing to{' '}
                {<Link href="mailto:support@zootpro.com">support@zootpro.com</Link>}. If a User
                discovers that User Generated Content promotes crimes against humanity, incites
                hatred and/or violence, or concerns child pornography, the User must notify ZootPro.
            </FormattedText>
            <FormattedTextBold>Links to Third-Party Websites</FormattedTextBold>
            <FormattedText>
                The ZootPro Platform may contain links (such as hyperlinks) to third-party websites.
                Such links do not constitute endorsement by ZootPro or association with those
                websites, their content or their operators. Such links (including without limitation
                external websites that are framed by the ZootPro Platform as well as any
                advertisements displayed in connection therewith) are provided as an information
                service, for reference and convenience only. ZootPro does not control any such
                websites, and is not responsible for their availability, accuracy, content,
                advertising, products, or services. It is your responsibility to evaluate the
                content and usefulness of the information obtained from other websites. You
                acknowledge and agree that ZootPro is not involved in the creation or development of
                third-party websites and disclaims any responsibility for third-party websites, and
                cannot be liable for claims arising out of or relating to third- party websites.
                Further, you acknowledge and agree that ZootPro has no obligation to monitor,
                review, or remove links to third-party websites, but reserves the right to limit or
                remove links to third-party websites on the ZootPro Platform at its sole discretion.
            </FormattedText>
            <FormattedText>
                The use of any website controlled, owned or operated by a third party is governed by
                the terms and conditions of use and privacy policy for that website. You access such
                third-party websites at your own risk. ZootPro expressly disclaims any liability
                arising in connection with your use and/or viewing of any websites or other material
                associated with links that may appear on the ZootPro Platform. You hereby agree to
                hold ZootPro harmless from any liability that may result from the use of links that
                may appear on the ZootPro Platform.
            </FormattedText>
            <FormattedTextBold>ZootPro Operates as an Online Marketplace</FormattedTextBold>
            <FormattedText>
                ZootPro operates as an online marketplace that connects Hiring agents with service
                providers (Agents) who wish to perform a variety of Tasks or Activities. ZootPro is
                not a Broker and is not in the real estate business, but rather the software
                business. ZootPro does not perform Tasks or Activities and does not employ people to
                perform Tasks or Activities. Agents operate as independent business owners and are
                customarily engaged in an independently established business of the same nature as
                that involved in the services performed for Hiring agents through the ZootPro
                Platform. ZootPro does not control or direct the Agents’ performance of their
                services or set their work locations, work hours, or terms of work.
            </FormattedText>
            <FormattedText>
                Agents provide services under their own name or business name, and not under
                ZootPro’s name. Agents provide their own tools and supplies to perform their
                services; ZootPro does not provide the tools or supplies. Users are free to maintain
                their services without any restrictions from ZootPro and are free to offer and
                provide their services elsewhere, including through competing platforms. Agents are
                free to accept or reject Hiring agents and contracts. Agents are not penalized for
                rejecting Hiring agents or contracts, though if Agents accept a Hiring agent or
                contract through the ZootPro Platform, they are expected to fulfill their
                contractual obligations to their hiring agent. Agents set their own rates for
                services performed in the ZootPro general marketplace, without deduction by ZootPro.
            </FormattedText>
            <FormattedText>
                The ZootPro Platform is not an employment agency service or business and ZootPro is
                not an employer of any User. Agents acknowledge and confirm that they are
                responsible for exercising their own business judgment in entering into Service
                Agreements and performing Tasks or Activities and that, depending on how they
                exercise such business judgment, there is a chance for individual profit or loss.
            </FormattedText>
            <FormattedTextBold>ZootPro Compliance Promise</FormattedTextBold>
            <FormattedText>
                The ZootPro Compliance Promise applicable to the country where the Task is performed
                is part of this Agreement and incorporated by reference. ZootPro does not oversee,
                monitor or direct how an Agent performs a Task, does not monitor Tasks or Activities
                or chat threads between Users, and does not otherwise assume responsibility for the
                actions of Users. ZootPro is not a Broker. ZootPro software is built to be
                compliant, and to ensure that all users are affiliated to avoid the violation of any
                broker-agent rules or association rules. Hiring agents are advised to confirm with
                their Agent that s/he is qualified to perform the Task prior to the Task taking
                place. ZootPro is not liable for the acts or omissions of Users, nor does ZootPro
                provide insurance against any violations sustained by Users. That said, ZootPro
                wants Users to be confident in their experience using the ZootPro Platform and the
                Compliance Promise is in place to encourage continued use of the ZootPro Platform.
            </FormattedText>
            <FormattedText>
                The Compliance Promise does not supersede any of the terms set forth in these Terms
                of Service or the Privacy Policy, including any of the limitations on liability set
                forth in these Terms of Service. In the unlikely event of a conflict with the
                Compliance Promise, these Terms of Service prevail over any contrary interpretation
                of the Compliance Promise. The Compliance Promise is not insurance and ZootPro is
                not an insurer, as such terms are generally understood for regulatory purposes.
            </FormattedText>
            <FormattedTextBold>Intellectual Property Rights</FormattedTextBold>
            <FormattedText>
                All text, graphics, editorial content, data, formatting, graphs, designs, HTML, look
                and feel, photographs, music, sounds, images, software, videos, typefaces and other
                content, including ZootPro designs, trademarks, and logos (collectively “Proprietary
                Material”) that Users see or read through the ZootPro Platform is owned by ZootPro,
                excluding User Generated Content, which Users hereby grant ZootPro a license to use
                as set forth above in Article 10. Proprietary Material is protected in all forms,
                media and technologies now known or hereinafter developed. ZootPro owns all
                Proprietary Material, as well as the coordination, selection, arrangement and
                enhancement of such Proprietary Materials. The Proprietary Material is protected by
                domestic and international laws governing copyright, patents, and other proprietary
                rights. Users may not copy, download, use, redesign, reconfigure, or retransmit
                anything from the ZootPro Platform without ZootPro’s express prior written consent
                and, if applicable, the consent of the holder of the rights to the User Generated
                Content. Any use of such Proprietary Material other than as permitted herein is
                expressly prohibited.
            </FormattedText>
            <FormattedText>
                The service marks and trademarks of ZootPro, including without limitation ZootPro,
                ZootPro for Good, and associated logos, are service marks owned by ZootPro. Any
                other trademarks, service marks, logos and/or trade names appearing via the ZootPro
                Platform are the property of their respective owners. ZootPro’s proprietary marks
                and logos are not available for use by Agents. You may not copy or use any of these
                marks, logos or trade names without the express prior written consent of the owner.
            </FormattedText>
            <FormattedTextBold>Copyright Complaints and Copyright Agent</FormattedTextBold>
            <FormattedText>
                ZootPro respects the intellectual property of others and expects Users to do the
                same. If you believe, in good faith, that any materials provided on or in connection
                with the ZootPro Platform infringe upon your copyright or other intellectual
                property right, please send the following information to ZootPro’s Copyright Agent
                at ZootPro, Inc., P.O. Box 90833, Washington, DC 20002 or{' '}
                {<Link href="mailto:support@zootpro.com">support@zootpro.com</Link>}: A description
                of the copyrighted work that you claim has been infringed, including the URL
                (Internet address) or other specific location on the ZootPro Platform where the
                material you claim is infringed is visible. Include enough information to allow
                ZootPro to locate the material, and explain why you think an infringement has taken
                place;
            </FormattedText>
            <FormattedText>
                A description of the location where the original or an authorized copy of the
                copyrighted work exists -- for example, the URL (Internet address) where it is
                posted or the name of the book in which it has been published; Your name, address,
                telephone number, and e-mail address;
            </FormattedText>
            <FormattedText>
                A statement by you that you have a good faith belief that the disputed use is not
                authorized by the copyright owner, its agent, or the law;
            </FormattedText>
            <FormattedText>
                A statement by you, made under penalty of perjury, that the information in your
                notice is accurate, and that you are the copyright owner or authorized to act on the
                copyright owner’s behalf; and Your electronic or physical signature as the owner of
                the copyright or the person authorized to act on behalf of the owner of the
                copyright interest.
            </FormattedText>
            <FormattedTextBold>Confidential Information</FormattedTextBold>
            <FormattedText>
                You acknowledge that Confidential Information (as defined below) is a valuable,
                special and unique asset of ZootPro and agree that you will not, for the lifetime of
                your account on ZootPro plus 10 years thereafter, disclose, transfer, or use (or
                seek to induce others to disclose, transfer or use) any Confidential Information for
                any purpose other than use of the ZootPro Platform in accordance with these Terms of
                Service. If relevant, you may disclose the Confidential Information to your
                authorized employees and agents provided that they are also bound to maintain the
                confidentiality of Confidential Information. You shall use best efforts to protect
                Confidential Information from unauthorized disclosure, transfer or use. You shall
                promptly notify ZootPro in writing of any circumstances that may constitute
                unauthorized disclosure, transfer, or use of Confidential Information. You shall
                return all originals and any copies of any and all materials containing Confidential
                Information to ZootPro promptly upon deactivation of your User account or
                termination of this Agreement for any reason whatsoever.
            </FormattedText>
            <FormattedText>
                The term “Confidential Information” shall mean any and all of ZootPro’s trade
                secrets, confidential and proprietary information, and all other information and
                data of ZootPro that is not generally known to the public or other third parties who
                could derive value, economic or otherwise, from its use or disclosure. Confidential
                Information shall be deemed to include technical data, know-how, research, product
                plans, products, services, customers, markets, software, developments, inventions,
                processes, formulas, technology, designs, drawings, engineering, hardware
                configuration information, marketing, finances, strategic and other Proprietary
                Materials and confidential information relating to ZootPro or ZootPro’s business,
                operations or properties, including information about ZootPro’s staff, Users or
                partners, or other business information disclosed or obtained directly or indirectly
                in writing, orally or by drawings or observation.
            </FormattedText>
            <FormattedTextBold>Disclaimer of Warranties</FormattedTextBold>
            <FormattedText>Use Of The ZootPro Platform Is Entirely At Your Own Risk</FormattedText>
            <FormattedText>
                THE TECHNOLOGY OF THE ZOOTPRO PLATFORM IS PROVIDED ON AN “AS IS” BASIS WITHOUT
                WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                LIMITED TO, WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, GOOD AND WORKMANLIKE SERVICES, AND NON-INFRINGEMENT. ZOOTPRO MAKES NO
                WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE CONTENT
                PROVIDED THROUGH THE ZOOTPRO PLATFORM OR THE CONTENT OF ANY SITES LINKED TO THE
                ZOOTPRO PLATFORM AND ASSUMES NO LIABILITY OR RESPONSIBILITY IN CONTRACT, WARRANTY OR
                IN TORT FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL
                INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO
                AND USE OF THE ZOOTPRO PLATFORM, (III) ANY ACCESS TO OR USE OF OUR SECURE SERVERS
                AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN;
                AND (IV) EVENTS BEYOND OUR REASONABLE CONTROL.
            </FormattedText>
            <FormattedText>
                ZootPro does not warrant, endorse, guarantee or assume responsibility for any
                service advertised or offered by a third-party through the ZootPro Platform or any
                hyperlinked website or featured in any banner or other advertising, and ZootPro will
                not be a party to or in any way be responsible for any transaction between you and
                other Users, or you and third-party providers of products or services. As with the
                purchase of a product or service through any medium or in any environment, you
                should use your best judgment and exercise caution where appropriate. Without
                limiting the foregoing, ZootPro and Affiliates do not warrant that access to the
                ZootPro Platform will be uninterrupted or that the ZootPro Platform will be
                error-free; nor do they make any warranty as to the results that may be obtained
                from the use of the ZootPro Platform, or as to the timeliness, accuracy,
                reliability, completeness or content of any Task, service, information or materials
                provided through or in connection with the use of the ZootPro Platform. ZootPro and
                Affiliates are not responsible for the conduct, whether online or offline, of any
                User. ZootPro and Affiliates do not warrant that the ZootPro Platform is free from
                computer viruses, system failures, worms, trojan horses, or other harmful components
                or malfunctions, including during hyperlink to or from third-party websites. ZootPro
                and Affiliates will implement appropriate technical and organizational measures to
                ensure a level of security adapted to the risk for any personal information supplied
                by you.
            </FormattedText>
            <FormattedText>
                Notwithstanding any feature or service, each Hiring agent is responsible for
                determining the Task and selecting or otherwise approving their Agent, and ZootPro
                does not warrant the skills or experience of any Agent hired by a Hiring agent and
                does not recommend any particular Agent. ZootPro does not provide any warranties or
                guarantees regarding any Agent’s ability, professional accreditation, registration
                or licensure. ZootPro is not licensed as a Broker and does not supervise any Agent.
            </FormattedText>
            <FormattedTextBold>No Liability</FormattedTextBold>
            <FormattedText>
                You acknowledge and agree that ZootPro is only willing to provide the ZootPro
                Platform if you agree to certain limitations of our liability to you and third
                parties. Therefore, you agree not to hold ZootPro and Affiliates, or their corporate
                partners, liable for any claims, demands, damages, expenses, losses, governmental
                obligations, suits, and/or controversies of every kind and nature, known and
                unknown, suspected and unsuspected, disclosed and undisclosed, direct, indirect,
                incidental, actual, consequential, economic, special, or exemplary, including
                attorney’s fees and costs (collectively, “Liabilities” ) that have arisen or may
                arise, relating to your or any other party’s use of or inability to use the ZootPro
                Platform, including without limitation any Liabilities arising in connection with
                the conduct, act or omission of any User (including without limitation stalking,
                harassment that is sexual or otherwise, acts of physical violence, and destruction
                of personal property), any dispute with any User, any instruction, advice, act, or
                service provided by ZootPro and Affiliates, and any destruction of your User
                Generated Content.
            </FormattedText>
            <FormattedText>
                UNDER NO CIRCUMSTANCES WILL ZOOTPRO AND AFFILIATES OR THEIR CORPORATE PARTNERS BE
                LIABLE FOR, AND YOU HEREBY RELEASE ZOOTPRO AND AFFILIATES AND THEIR CORPORATE
                PARTNERS FROM ANY DIRECT, INDIRECT, INCIDENTAL, ACTUAL, CONSEQUENTIAL, ECONOMIC,
                SPECIAL OR EXEMPLARY DAMAGES (INCLUDING BUT NOT LIMITED TO LOST PROFITS, LOSS OF
                DATA, LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE, SYSTEM FAILURE,
                FAILURE TO STORE ANY INFORMATION OR OTHER CONTENT MAINTAINED OR TRANSMITTED BY
                ZOOTPRO, THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR ATTORNEYS FEES AND COSTS)
                ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OF OR INABILITY TO USE THE
                ZOOTPRO PLATFORM OR THE TASK SERVICES, EVEN IF ADVISED OF THE POSSIBILITY OF THE
                SAME. Some jurisdictions do not allow the exclusion of certain warranties or
                limitation of incidental or consequential damages; in such cases the above
                limitations may not apply to you in their entirety.
            </FormattedText>
            <FormattedText>
                ZOOTPRO AND AFFILIATES EXPRESSLY DISCLAIM ANY LIABILITY THAT MAY ARISE BETWEEN USERS
                OF ITS ZOOTPRO PLATFORM. ZOOTPRO AND AFFILIATES ALSO DO NOT ACCEPT ANY LIABILITY
                WITH RESPECT TO THE QUALITY OR FITNESS OF ANY WORK PERFORMED VIA THE ZOOTPRO
                PLATFORM.
            </FormattedText>
            <FormattedText>
                IF, NOTWITHSTANDING THE FOREGOING EXCLUSIONS, IT IS DETERMINED THAT ZOOTPRO AND
                AFFILIATES OR THEIR CORPORATE PARTNERS ARE LIABLE FOR DAMAGES, IN NO EVENT WILL THE
            </FormattedText>
        </Box>
    );
};
