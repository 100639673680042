import React from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import { CustomDropdown } from '../../../common/components';
import { MultipleFilter } from './MultipleFilter';
import { SortupIcon } from '../../../assets/icons';
import { sortByTypes } from '../../../common/constants';
import { IdNameTypes, StateDirectoryItem } from '../../../common/types';
import { RootStore } from '../../../store';
import { useSelector } from 'react-redux';

interface IFiltersBlockProps {
    listingTypes: IdNameTypes[];
    agencyCities: string[];
    sortValue: number;
    setSortValue: (value: number) => void;
    setIsSorted: React.Dispatch<React.SetStateAction<boolean>>;
    setIsOpenHouse: (value: boolean) => void;
    setPropertyTypes: (value: number[]) => void;
    setCities: (value: string[]) => void;
    setStates?: (value: number[]) => void;
}

export const FiltersBlock: React.FC<IFiltersBlockProps> = ({
    listingTypes,
    agencyCities,
    setSortValue,
    sortValue,
    setIsSorted,
    setCities,
    setIsOpenHouse,
    setPropertyTypes,
    setStates,
}) => {
    const states = useSelector((state: RootStore) => state.user.user.currentStates) || [];

    return (
        <Flex align="flex-end" mt="-20px">
            <CustomDropdown
                label="Sort By"
                options={sortByTypes}
                register={{
                    onChange: (ev: React.ChangeEvent<HTMLSelectElement>) =>
                        setSortValue(+ev.target.value),
                    value: sortValue.toString(),
                }}
            />
            <IconButton
                aria-label="filter"
                icon={<SortupIcon />}
                h="50px"
                w="50px"
                mx="10px"
                onClick={() => setIsSorted(prev => !prev)}
            />
            <MultipleFilter
                label="Filter By"
                onApply={value => {
                    setPropertyTypes(value['Property Type'] as number[]);
                    setIsOpenHouse(
                        (value['Open House'][0] as boolean)
                            ? (value['Open House'][0] as boolean)
                            : false,
                    );
                    setCities(value.City as string[]);

                    if(setStates){
                        setStates(value['States'] as number[]);
                    }
                }}
                items={[

                    {
                        categoryTitle: 'Property Type',
                        categories: listingTypes.map(it => ({
                            label: it.name,
                            value: it.id,
                        })),
                    },
                    {
                        categories: [
                            {
                                label: 'Open House Available',
                                value: true,
                            },
                        ],
                        categoryTitle: 'Open House',
                    },
                    {
                        categories: agencyCities.map(city => ({
                            label: city,
                            value: city,
                        })),
                        categoryTitle: 'City',
                    },
                    {
                        categories: states.map((state: { state: any; stateId: any; }) => ({
                            label: state.state,
                            value: state.stateId,
                        })),
                        categoryTitle: 'States',
                    },
                ]}
            />
        </Flex>
    )
};
