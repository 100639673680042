import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, IconButton, chakra } from '@chakra-ui/react';

import { fonts } from '../../../common/utils';
import { MyRequestsType, RequestInfoType } from '../types';
import { BorderIconBox } from './BorderIconBox';
import { colors } from '../../../common/constants';
import { formatDateTime } from '../../../common/utils/formatDateTime';
import {
    CalendarIcon,
    CheckIcon,
    ClockIcon,
    DropdownArrowIcon,
    LocationIcon,
    CloseButton,
    Home,
    WavesIcon,
} from '../../../assets/icons';
import { acceptRequest, deleteRequestAfterTimer, getRequest } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import dayjs from 'dayjs';
import { secondsToMinutes } from '../../../common/utils';
import { ApplyingJobModal } from '../../../common/components/modals/ApplyingJob/ApplyingJobModal';
import { AvailableBox } from './AvailableBox';
import { AvailableRequestDetailsModal } from '../modals';

type AvailableBoxIndType = {
    availableTitle?: boolean;
    request: MyRequestsType;
    emitUpdate?: () => void;
};

export const AvailableBoxIndependent: React.FC<AvailableBoxIndType> = ({
    availableTitle = true,
    request,
    emitUpdate,
}) => {
    const dispatch = useDispatch();

    const requestInfo = useSelector((state: RootStore) => state.requests.requestInfo.value);
    const requestInfoLoading = useSelector(
        (state: RootStore) => state.requests.requestInfo.loading,
    );

    const onGetRequest = (id: number) => dispatch(getRequest(id));

    const [isAvailableModalOpen, setIsAvailableModalOpen] = useState(false);

    const handleOpenAvailableModal = (id: number) => {
        setIsAvailableModalOpen(true);
        onGetRequest(id);
    };

    const handleAcceptRequest = (IsAccepted: boolean, RequestId: number) => {
      dispatch(
          acceptRequest({
              RequestId,
              IsAccepted,
              PageSize: 10,
              includeOtherAgent: true,
              categoryIds: [],
              date: '',
          }),
      );

      if(emitUpdate){
        emitUpdate();
      }
  };

    return (
      <>
        <AvailableBox
          availableTitle={availableTitle}
          request={request}
          handleOpenAvailableModal={handleOpenAvailableModal}
          handleAcceptRequest={handleAcceptRequest}
          requestInfo={requestInfo}
          onGetRequest={onGetRequest}
        />
        <AvailableRequestDetailsModal
            isOpen={isAvailableModalOpen}
            onClose={setIsAvailableModalOpen}
            requestInfo={requestInfo}
            requestInfoLoading={requestInfoLoading}
            handleAcceptRequest={handleAcceptRequest}
        />
      </>
    );
};
