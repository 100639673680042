import React from 'react';
import { colors } from '../../common/constants';

export const SendIconGrey: React.FC = () => (
    <svg width="20" height="17" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.0118862 0.785645L0 9.11896L17.8571 11.4999L0 13.8809L0.0118862 22.2142L25 11.4999L0.0118862 0.785645Z"
            fill="#CBD5E0"
        />
    </svg>
);
