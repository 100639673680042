import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';

import Requests from './Requests';
import { RootStore } from '../../store';
import {
    getMyRequests,
    getRequest,
    getAgencyRequests,
    acceptRequest,
    setIsMyRequestInfoModalOpen,
} from './store/actions';
import { setConnectPaymentModalOpen } from '../Notifications/store/actions';
import { getRequestCategories } from '../../store/actions/directories.actions';
import { AgencyRequestInfoModal, MyRequestInfoModal } from './modals';
import { useHistory } from 'react-router-dom';

const RequestsContainer: React.FC = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [typeIdsSelected, setTypeIdsSelected] = useState<number[]>([]);
    const [agencyRequestsCurrentPage, setAgencyRequestsCurrentPage] = useState(1);
    const [myRequestsCurrentPage, setMyRequestsCurrentPage] = useState(1);
    const [onEndAgencyRequestsReachedCalled, setOnEndAgencyRequestsReachedCalled] = useState(false);
    const [onEndMyRequestsReachedCalled, setOnEndMyRequestsReachedCalled] = useState(false);
    const [agencyRequestToShowId, setAgencyRequestToShowId] = useState(0);
    const [showAgencyRequestModal, setShowAgencyRequestModal] = useState(false);
    const [needUpdateRequest, setNeedUpdateRequest] = useState(0);
    const [myRequestToShowId, setMyRequestToShowId] = useState(0);

    const isMyRequestInfoModalOpen = useSelector(
        (state: RootStore) => state.requests.isMyRequestInfoModalOpen,
    );

    const {
        items: myRequests,
        totalCount: myRequestsTotalCount,
        loading: requestsLoading,
        loadMoreLoading: pendingLoadMoreLoading,
    } = useSelector((state: RootStore) => state.requests.myRequests);
    const requestInfo = useSelector((state: RootStore) => state.requests.requestInfo.value);
    const roles = useSelector((state: RootStore) => state.auth.user.roles);
    const requestInfoLoading = useSelector(
        (state: RootStore) => state.requests.requestInfo.loading,
    );
    const {
        items: agencyRequests,
        totalCount: agencyRequestsTotalCount,
        loadMoreLoading: sentLoadMoreLoading,
    } = useSelector((state: RootStore) => state.requests.agencyRequests);
    const requestCategories = useSelector(
        (state: RootStore) => state.directories.requestCategories,
    );

    const isAccessAllowed = roles?.some((role: string) => (role === 'user' || role === 'newuser'));

    const router = useHistory();

    const dispatch = useDispatch();
    const onGetRequest = (id: number) => dispatch(getRequest(id));
    const onGetMyRequests = (page?: number) =>
        dispatch(
            getMyRequests({
                PageNumber: page || myRequestsCurrentPage,
                PageSize: 10,
                includeOtherAgent: myRequestsCurrentPage === 1,
                categoryIds: typeIdsSelected,
                date: '',
            }),
        );
    const onGetAgencyRequests = (page?: number) =>
        dispatch(
            getAgencyRequests({
                pageNumber: page || agencyRequestsCurrentPage,
                pageSize: 10,
                categoryIds: typeIdsSelected,
                date: '',
            }),
        );

    const loadMoreAgencyRequests = () => {
        if (agencyRequests.length >= agencyRequestsTotalCount) {
            setOnEndAgencyRequestsReachedCalled(true);
        } else if (!onEndAgencyRequestsReachedCalled) {
            setAgencyRequestsCurrentPage((prev: number) => prev + 1);
        }
    };

    const loadMoreMyRequests = () => {
        if (myRequests.length >= myRequestsTotalCount) {
            setOnEndMyRequestsReachedCalled(true);
        } else if (!onEndMyRequestsReachedCalled) {
            setMyRequestsCurrentPage((prev: number) => prev + 1);
        }
    };

    const handleAcceptRequest = (IsAccepted: boolean, RequestId: number) => {
        if (!isAccessAllowed) {
            // dispatch(setConnectPaymentModalOpen({ isOpen: true }));
            return;
        }

        dispatch(
            acceptRequest({
                RequestId,
                IsAccepted,
                PageSize: 10,
                includeOtherAgent: myRequestsCurrentPage === 1,
                categoryIds: typeIdsSelected,
                date: '',
            }),
        );

        //setNeedUpdateRequest(Date.now());
    };

    useEffect(() => {
        if (tabIndex === 1) {
            onGetAgencyRequests();
        } else {
            onGetMyRequests();
        }
    }, [
        tabIndex,
        typeIdsSelected,
        agencyRequestsCurrentPage,
        myRequestsCurrentPage,
        isMyRequestInfoModalOpen,
        needUpdateRequest,
    ]);

    useEffect(() => {
        dispatch(getRequestCategories());
    }, []);

    // Open modal if url contains request id
    useEffect(() => {
        const { id } = qs.parse(router.location.search, { ignoreQueryPrefix: true });

        if (id) {
            setMyRequestToShowId(Number(id));
        }
    }, [router.location.search]);

    useEffect(() => {
        if (agencyRequestToShowId) {
            setShowAgencyRequestModal(true);
        }
    }, [agencyRequestToShowId]);

    useEffect(() => {
        if (myRequestToShowId) {
            dispatch(setIsMyRequestInfoModalOpen(true));
        }
    }, [myRequestToShowId]);

    return (
        <>
            <Requests
                requestInfo={requestInfo}
                onGetRequest={onGetRequest}
                myRequests={myRequests}
                requestsLoading={requestsLoading}
                requestInfoLoading={requestInfoLoading}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                loadMoreAgencyRequests={loadMoreAgencyRequests}
                typeIdsSelected={typeIdsSelected}
                setTypeIdsSelected={setTypeIdsSelected}
                setSentRequestToShowId={setAgencyRequestToShowId}
                setPendingRequestToShowId={setMyRequestToShowId}
                loadMoreMyRequests={loadMoreMyRequests}
                handleAcceptRequest={handleAcceptRequest}
                pendingLoadMoreLoading={pendingLoadMoreLoading}
                sentLoadMoreLoading={sentLoadMoreLoading}
                requestCategories={requestCategories}
            />
            <AgencyRequestInfoModal
                isOpen={showAgencyRequestModal}
                sentRequestToShowId={agencyRequestToShowId}
                onClose={() => {
                    setShowAgencyRequestModal(false);
                    setAgencyRequestToShowId(0);
                }}
                onGetAgencyRequests={onGetAgencyRequests}
            />
            <MyRequestInfoModal
                isOpen={isMyRequestInfoModalOpen}
                onClose={() => {
                    dispatch(setIsMyRequestInfoModalOpen(false));
                    setMyRequestToShowId(0);
                    router.push(router.location.pathname);
                }}
                pendingRequestToShowId={myRequestToShowId}
                onGetMyRequests={onGetMyRequests}
            />
        </>
    );
};

export default RequestsContainer;
