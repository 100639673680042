import { call, put, takeEvery, ForkEffect } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { login, loginSuccess, loginError } from '../../actions/auth/auth.actions';
import Api from '../../../common/api/api.base';
import { logout } from '../../actions/auth/auth.actions';
import { clearCurrentUser } from '../../../common/helpers/auth/session.helper';
import { LoginResponseSuccessType, RefreshTokenResponseSuccess } from '../../../common/types';
import { refreshToken, refreshTokenSuccess } from '../../actions/auth/auth.actions';
import { HOMEPAGE_PATH } from '../../../common/constants';
import axios from 'axios';

function* workerLogin(action: ReturnType<typeof login>) {
    const data = {
        userIdentifier: action.payload.email,
        password: action.payload.password,
    };
    try {
        const result: LoginResponseSuccessType = yield call(
            Api.post,
            'api/auth/signin',
            JSON.stringify(data),
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        );
        if (result.success) {
            axios.defaults.headers.common = {'Authorization': 'Bearer '+result.value.token.accessToken};

            yield put(loginSuccess(result));
            location.href = '/home';
            //yield put(push(HOMEPAGE_PATH));
        } else {
            yield put(loginError(result.errors));
        }
    } catch (error) {
        yield put(loginError([{ key: 'Error', errorMessage: 'Network error' }]));
    }
}

function* workerRefreshToken(action: ReturnType<typeof refreshToken>) {
    const data = {
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
    };
    try {
        const result: RefreshTokenResponseSuccess = yield call(
            Api.post,
            'api/token/refresh',
            JSON.stringify(data),
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        );
        if (result.success) {
            yield put(refreshTokenSuccess(result));
            yield put(push(HOMEPAGE_PATH));
        } else {
            yield put(loginError(result.errors));
        }
    } catch (error) {
        yield put(loginError([{ key: 'Error', errorMessage: 'Network error' }]));
    }
}

function* workerLogout(action: ReturnType<typeof logout>) {
    clearCurrentUser();
    yield put(push('/auth/signin/login'));
}

// prettier-ignore
export function* watchLoginSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(login.type, workerLogin);
}

export function* watchRefreshTokenSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(refreshToken.type, workerRefreshToken);
}

export function* watchLogoutSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(logout.type, workerLogout);
}
