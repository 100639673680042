import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { from8amTo9pmTimeYupResolver } from '../constants';
import { ErrorMessages } from './messages';

const timeSlotSchema = yup.object().shape({
    date: yup.number().positive(ErrorMessages.REQUIRED).required(ErrorMessages.REQUIRED),
    endTime: from8amTo9pmTimeYupResolver,
    startTime: from8amTo9pmTimeYupResolver,
    price: yup
        .number()
        .positive(ErrorMessages.POSITIVE_NUMBER)
        .typeError(ErrorMessages.NUMBER_INVALID),
    openHouseHostTypeId: yup.string().required(ErrorMessages.REQUIRED),
    clientTypeId: yup
        .string()
        .test('client-type-id', ErrorMessages.REQUIRED, function (value?: string) {
            return this.parent.openHouseHostTypeId === '1' ? !!value : true;
        }),
    wouldLikeToOfferBonus: yup
        .string()
        .test('offer-bonus', ErrorMessages.REQUIRED, function (value?: string) {
            return this.parent.openHouseHostTypeId === '1' ? !!value : true;
        }),
    openHouseAudienceId: yup.string(),
    openHouseTypeId: yup.string().required(ErrorMessages.REQUIRED),
});

export const timeSlotsResolver = yupResolver(timeSlotSchema);
