import React, { useState, useEffect } from 'react';
import { ModalBody, Text, Flex, Box } from '@chakra-ui/react';
import { ConfirmHeader } from './components';
import { RequestInfoType } from '../../../../pages/Requests/types';
import { roundAplicantPrices, fonts } from '../../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../../../store';
import { getRequestDurationsRequest } from '../../../../pages/Activity/store/actions';
import { CustomButton, CustomDropdown } from '../../../components';
import { FinishWorkPayload } from '../../../../pages/Activity/types';
import { calcPriceBonus } from './PropertiesSelectionConfirm';

interface IDurationConfirmationProps {
    onClose: () => void;
    requestInfo: RequestInfoType;
    onFinishWork: (data: FinishWorkPayload) => void;
    finishWorkLoading: boolean;
}

export const DurationConfirmation: React.FC<IDurationConfirmationProps> = ({
    onClose,
    requestInfo,
    onFinishWork,
    finishWorkLoading,
}) => {
    const dispatch = useDispatch();
    const [hours, setHours] = useState(parseInt(requestInfo.duration.toString(), 10));
    const [minutes, setMinutes] = useState(requestInfo.duration % 1 === 0.5 ? 30 : 0);

    const { pricePerHour } = useSelector((state: RootStore) => state.activity.requestDurations);

    var pricePerHourFix = (requestInfo.pricePerHour == null)? 0 : Number(requestInfo.pricePerHour);
    pricePerHourFix = calcPriceBonus(pricePerHourFix, requestInfo);
    
    useEffect(() => {
        if (requestInfo.id) {
            dispatch(getRequestDurationsRequest(requestInfo.id));
        }
    }, [requestInfo]);

    const onSubmit = () => {
        onFinishWork({
            requestAddresses: [],
            requestId: requestInfo.id,
            duration: hours * 60 + minutes,
        });
    };

    return (
        <>
            <ConfirmHeader onClose={onClose} title="Payment Confirmation" />
            <ModalBody w="100%">
                <Box bg="bgPaymentInfo" borderRadius="8px" p="25px 35px">
                    <Text
                        fontSize="16px"
                        fontWeight={700}
                        lineHeight="22px"
                        color="brand"
                        mb="15px"
                    >
                        {requestInfo.requestTypeName}
                    </Text>
                    {requestInfo.requestCategoryId === 1 ? (
                        <>
                            <Text
                                fontSize="13px"
                                color="brand"
                                lineHeight="16px"
                                fontWeight={400}
                                fontFamily={fonts.montserrat}
                            >
                                Duration:
                            </Text>
                            <Flex mb="30px">
                                <CustomDropdown
                                    label=""
                                    options={Array(12)
                                        .fill('')
                                        .map((_elem, index) => ({
                                            id: index + 1,
                                            value: index + 1,
                                            label: (index + 1).toString(),
                                        }))}
                                    register={{
                                        value: hours,
                                        onChange: (ev: any) => setHours(+ev.target.value),
                                        border: 'none',
                                    }}
                                    width="150px"
                                    bgColor="white"
                                    icon={
                                        <Text
                                            fontSize="16px"
                                            h="auto !important"
                                            fontWeight={400}
                                            color="gray"
                                        >
                                            h
                                        </Text>
                                    }
                                />
                                <Box w="20px" />
                                <CustomDropdown
                                    label=""
                                    options={[
                                        { id: 1, value: 0, label: '0' },
                                        { id: 2, value: 30, label: '30' },
                                    ]}
                                    register={{
                                        value: minutes,
                                        onChange: (ev: any) => setMinutes(+ev.target.value),
                                        border: 'none',
                                    }}
                                    bgColor="white"
                                    width="150px"
                                    icon={
                                        <Text
                                            fontSize="16px"
                                            height="auto !important"
                                            fontWeight={400}
                                            color="gray"
                                        >
                                            m
                                        </Text>
                                    }
                                />
                            </Flex>
                        </>
                    ) : null}

                    <Flex justifyContent="space-between" align="center" mb="10px">
                        <Text fontSize="13px" color="brand" lineHeight="20px" fontWeight={400}>
                            {requestInfo.requestCategoryId === 1
                                ? 'Your Price Per Hour:'
                                : 'Offer Price'}
                        </Text>
                        <Text
                            fontSize="16px"
                            lineHeight="24px"
                            fontWeight={500}
                            color="secondaryFontDarker"
                        >
                            $
                            {roundAplicantPrices(
                                requestInfo.requestCategoryId === 1
                                    ? pricePerHourFix
                                    : Number(requestInfo.totalPrice),
                            )}
                        </Text>
                    </Flex>
                    <Flex justifyContent="space-between" align="center">
                        <Text fontSize="13px" color="brand" lineHeight="20px" fontWeight={600}>
                            Estimated Total Earning:
                        </Text>
                        <Text fontSize="19px" color="brand" lineHeight="28px" fontWeight={600}>
                            $
                            {requestInfo.requestCategoryId === 1
                                ? roundAplicantPrices((hours + minutes / 60) * pricePerHourFix)
                                : roundAplicantPrices(requestInfo.totalPrice || 0)}
                        </Text>
                    </Flex>
                </Box>
                <CustomButton
                    text="Submit"
                    height="60px"
                    onClick={onSubmit}
                    bgColor="brand"
                    mt="50px"
                    isLoading={finishWorkLoading}
                />
            </ModalBody>
        </>
    );
};
