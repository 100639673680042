import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    FormControl,
    FormErrorMessage,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Box,
    Text,
    Center,
		Container,
		Flex,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { GoogleIcon } from '../../../assets/icons/GoogleIcon';

export const GoogleAuth: React.FC<{isSign?: boolean}> = ({isSign = true}) => {
    
	
		var CheckLoginStatus = function(windowModal: any){
			//newWindow.focus();


			/*
			if(windowModal.closed) {
				location.reload();
			}else{
				setTimeout(() => {
					CheckLoginStatus(windowModal);
				}, 1000);
			}
			*/
		};

		var authGoogle = function(){
			location.href = window.baseUrl+'/socialite/google';

			/*
			let windowGoogle = showWindowPopupCenter(window.baseUrl+'/socialite/google', "_blank", 700, 600);
			window.test = windowGoogle;
			setTimeout(() => {
				CheckLoginStatus(windowGoogle);
			}, 2000);
			*/
		};

    var showWindowPopupCenter = function(
				url: string, 
				title: string, 
				w: number,
				h: number
			) {
      // Fixes dual-screen position                         
      // Most browsers Firefox
      var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
      var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

      var width = window.innerWidth ?
				window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
      var height = window.innerHeight ? 
				window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

      var left = ((width / 2) - (w / 2)) + dualScreenLeft;
      var top = ((height / 2) - (h / 2)) + dualScreenTop;
      var newWindow = window.open(
				url, 
				title, 
				'toolbar=no, scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left
			);

      // Puts focus on the newWindow
      if(newWindow) {
        newWindow.focus();
      }

      return newWindow;
    };

    return (
        <Flex justifyContent="center" flex="center" flexDirection="column" width="100%">
					<div className="or_btn">
						OR
					</div>
					<div className="google_icon_outer" onClick={() => {
							authGoogle();
						}}>
						<div className="icon_outer">
							<GoogleIcon />
						</div>
						{isSign? 'Sign In with Google' : 'Sign Up with Google' }
					</div>
				</Flex>
    );
};
