import React, { useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text, Image, Menu, MenuList, MenuButton, MenuItem, Flex } from '@chakra-ui/react';
//components
import { MenuIcon } from '../../../../../assets/icons';
import { IChatHeader } from '../../../types';
import { axiosBase } from '../../../../../common/api/api.base';
import { months } from '../../../../../common/constants';
import { toggleUserProfileModal } from '../../../../../store/actions/profile.actions';
import { RootStore} from '../../../../../store';
import { getChatUserInfo } from '../../../../../store/actions/messages.actions';

const options = [{ id: 2, title: 'View Profile' }];

export const ChatHeader: React.FC<IChatHeader> = ({ currentConversation, userId }) => {
    const dispatch = useDispatch();
    const correctItem = currentConversation.channelState?.attributes.filter((item: any) => {
        return item.Id !== userId;
    })[0];

    const participantChatInfo = useSelector((state: RootStore) => state.messages.chatInfo);

    const onGetChatInfoRequest = (payload: any) => dispatch(getChatUserInfo(payload));

    useEffect(() => {
        onGetChatInfoRequest(correctItem.Id);
      }, [correctItem.Id]);

    const dateCreatedObject = currentConversation.channelState?.dateCreated;
    const day = dateCreatedObject?.getUTCDate();
    const month = months[dateCreatedObject?.getMonth()];
    const year = dateCreatedObject?.getUTCFullYear();
    const dateCreatedString = `${day} ${month}, ${year}`;

    return (
        <Flex
            h="15%"
            borderBottom="1px lightgrey solid"
            justifyContent="space-between"
            alignItems="center"
            p="0 25px"
        >
            <Box d="flex" alignItems="center">
                {participantChatInfo.avatar.itemHash ? (
                    <Image
                        src={`${axiosBase.defaults.baseURL}/api/storage/static-images/${participantChatInfo.avatar.itemHash}${participantChatInfo.avatar.itemExtension}`}
                        w="50px"
                        h="50px"
                        borderRadius="50%"
                    />
                ) : (
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        bg="green"
                        w="50px"
                        h="50px"
                        color="white"
                        borderRadius="50%"
                        fontSize="20px"
                    >
                        {participantChatInfo.fullName[0]}
                    </Flex>
                )}
                <Flex flexDirection="column" justifyContent="space-between" ml="15px">
                    <Text color="brand" fontSize="18px" fontWeight="600">
                        {participantChatInfo.fullName}
                    </Text>
                    <Text color="secondaryFontDarker" fontSize="14px" fontWeight="400">
                        {dateCreatedString}
                    </Text>
                </Flex>
            </Box>
            <Menu id="notificationsMenu">
                <MenuButton as="button">
                    <MenuIcon />
                </MenuButton>
                <MenuList as={Flex} direction="column">
                    {options.map((item: any, index: number) => (
                        <MenuItem key={index} as="button">
                            <Flex
                                bgColor="#F6F7FB"
                                p="5px"
                                w="100%"
                                borderRadius="8px"
                                justify="space-between"
                                align="center"
                            >
                                <Flex
                                    w="90%"
                                    onClick={() => {
                                        dispatch(
                                            toggleUserProfileModal({
                                                value: true,
                                                userId: correctItem.Id,
                                            }),
                                        );
                                    }}
                                >
                                    <Text fontSize="13px">{item.title}</Text>
                                </Flex>
                            </Flex>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Flex>
    );
};
