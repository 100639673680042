import { Link } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CheckIconV3 } from '../../../assets/icons/IconPack';

export const AuthCardPanel: React.FC = ({children}) => {
    
    return (
			<div className="flex_row_auth">
				<div className="left_col">
					<div className="title_1">
						ZOOTPRO 15 Day Free Trial
					</div>
					<div className="title_2">
						Add The Powerful Leverage Of ZootPro For Your Clients And Business
					</div>
					<div className="desc">
					Accelerate your real estate business on the only platform built to comply with the MLS and Commission rules.
					</div>
					<div className="markers">
						<div className="marker_one">
							<CheckIconV3 className="icon_a" />
							Instant access to runner and coverage agents.
						</div>
						<div className="marker_one">
							<CheckIconV3 className="icon_a" />
							Send, receive, and track referrals nationwide.
						</div>
						<div className="marker_one">
							<CheckIconV3 className="icon_a" />
							View and share listing exclusives.
						</div>
						<div className="marker_one">
							<CheckIconV3 className="icon_a" />
							Market-wide matches to your clients’ needs.
						</div>
						<div className="auth_left">
							Don’t have an account? <Link href='/auth/signup/createaccount'><b>Sign Up</b></Link>
						</div>
					</div>
				</div>
				<div className="right_col">
					<div className="rc_inner">
						{children}
					</div>
					<div className="auth_right">
							Don’t have an account? <Link href='/auth/signup/createaccount'><b>Sign Up</b></Link>
						</div>
				</div>
			</div>
    );
};
