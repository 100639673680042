import React from 'react';

import { Flex, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { colors } from '../../../constants';

import {
    CalendarIcon,
    ClockIcon,
    DollarSignIcon,
    Home,
    ConsultMethodIcon,
} from '../../../../assets/icons';

interface IIconBoxProps {
    text: string | number;
    icon: JSX.Element;
}

interface IRequestQuickInfoProps {
    date: string;
    fromTime: string | null;
    requestTypeName: string;
    propertiesToShow: number;
    duration: number;
    totalPrice: number | null;
    margin?: number | string;
    bg?: string;
    isShowTotalPrice?: boolean;
}

const IconBox: React.FC<IIconBoxProps> = ({ text, icon }) => (
    <Flex direction="column" alignItems="center" justifyContent="center" h="100%">
        {icon}
        <Text color="brand" marginTop="10px" fontSize="13px" lineHeight="16px" fontWeight={600}>
            {text}
        </Text>
    </Flex>
);

export const RequestQuickInfo: React.FC<IRequestQuickInfoProps> = ({
    date,
    requestTypeName,
    duration,
    propertiesToShow,
    totalPrice,
    margin = 8,
    bg = '#F6F7FB',
    isShowTotalPrice = true,
}) => (
    <Flex
        mt={margin}
        padding="20px"
        justifyContent="space-around"
        alignItems="center"
        bg={bg}
        borderRadius="24px"
    >
        <IconBox icon={<CalendarIcon />} text={dayjs.utc(date).format('MM/DD/YYYY')} />
        <IconBox icon={<ClockIcon />} text={dayjs(date).format('hh:mm A')} />
        {requestTypeName !== 'Other' && (
            <IconBox
                icon={
                    requestTypeName === 'Showing' ? (
                        <Home color={colors.brand} width="16" height="16" />
                    ) : (
                        <ConsultMethodIcon />
                    )
                }
                text={
                    requestTypeName === 'Showing'
                        ? `${propertiesToShow} houses`
                        : `${duration} hours`
                }
            />
        )}
        {totalPrice !== null && isShowTotalPrice ? (
            <IconBox icon={<DollarSignIcon />} text={totalPrice} />
        ) : null}
    </Flex>
);
