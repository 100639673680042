import dayjs from 'dayjs';

export const combineDateAndTime = (date: string, time: string): string => {
    const fromTime = dayjs.utc(time || undefined);
    return dayjs
        .utc(date || undefined)
        .set('hour', fromTime.get('hour'))
        .set('minute', fromTime.get('minute'))
        .toISOString();
};

export const combineDateAndTimeNoUTC = (date: string, time: string): string => {
    const fromTime = dayjs(time || undefined);

    return dayjs
        .utc(date || undefined)
        .set('hour', fromTime.get('hour'))
        .set('minute', fromTime.get('minute'))
        .toISOString()
        .slice(0, -1);
};

export const formatHoursToDateTime = (time?: string) => {
    if (!time) {
        return dayjs().toISOString();
    }

    return dayjs(new Date())
        .set('hour', +time.split(':')[0])
        .set('minute', +time.split(':')[1])
        .set('seconds', 0)
        .set('milliseconds', 0)
        .toISOString();
};
