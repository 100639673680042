import React from 'react';
import { useRouteMatch } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SignUpRouting } from './SignUp/SignUp.routing';
import { SignInRouting } from './SignIn/SignIn.routing';
import { MatchParams } from '../../common/types';
import { CheckRouting } from './CheckPages/Check.routing';
import { TokingRouting } from './Token/TokingRouting';

export const AuthRouting: React.FC = () => {
    const match = useRouteMatch<MatchParams>();

    return (
        <Switch>
            <Route
                path={`${match.url}/tokinLogin`}
                component={() => <TokingRouting nestedPath={match} />}
            />
            <Route
                path={`${match.url}/check`}
                component={() => <CheckRouting nestedPath={match} />}
            />
            <Route
                path={`${match.url}/signup`}
                component={() => <SignUpRouting nestedPath={match} />}
            />
            <Route
                path={`${match.url}/signin`}
                component={() => <SignInRouting nestedPath={match} />}
            />
            <Redirect from="*" exact={true} to={`${match.url}/signin`} />
        </Switch>
    );
};
