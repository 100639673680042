import { Flex, Text } from '@chakra-ui/react';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BackButton, CustomButton } from '../../../../common/components';
import { NoLicenseHello } from '../../../../common/components/aglobal/NoLicenseHello';
import { RootStore } from '../../../../store';
import {
    AddLicensePayload,
    LicenseDraftType,
    LicenseType,
    SaveLicenseDraftPayload,
} from '../../types';
import { AddLicenseItem } from './components/AddLicenseItem';
import { LicenseItem } from './components/LicenseItem';

interface ILicenses {
    licenses: LicenseType[];
    states: any;
    agencies: any;
    draft: LicenseDraftType;
    addLicenseLoading: boolean;
    saveDraftLoading: boolean;
    showLicenseEditor: boolean;
    accessToken: string;
    stateId: string;
    handleAddLicense: () => void;
    setStateId: (v: string) => void;
    saveLicenseDraft: (v: SaveLicenseDraftPayload) => void;
    addLicense: (v: AddLicensePayload) => void;
}

export const Licenses: React.FC<ILicenses> = ({
    licenses,
    states,
    agencies,
    draft,
    addLicenseLoading,
    saveDraftLoading,
    showLicenseEditor,
    accessToken,
    stateId,
    handleAddLicense,
    setStateId,
    saveLicenseDraft,
    addLicense,
}) => {
    const [choosenItem, setChoosenItem] = useState<number | null>(null);
    const addLicenseBoxRef = useRef<HTMLDivElement>(null);

    const { user: currentUser } = useSelector((state: RootStore) => state.user);
    const [showHello, setshowHello] = useState(true);

    const executeScroll = () => {
        addLicenseBoxRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        if (showLicenseEditor) {
            executeScroll();
        }
    }, [showLicenseEditor]);

    const history = useHistory();

    return (
        <>
        {(currentUser.id && showHello && !currentUser.issetLicenses) ? (
            <NoLicenseHello 
                onClick={() => {
                    setshowHello(false);
                }}
            />
        ) : (
            <Flex bgColor="#FFFFFF" flexDirection="column" borderRadius="10px" p="10px 50px">
                <Flex alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center">
                        <BackButton text="" onClick={history.goBack} />
                        <Text
                            fontSize="24px"
                            lineHeight="25px"
                            color="brand"
                            fontWeight="bold"
                            ml="22px"
                            mb="15px"
                        >
                            Licenses
                        </Text>
                    </Flex>
                    <CustomButton
                        marginLeft="20px"
                        bgColor="transparent"
                        color="brand"
                        borderColor="brand"
                        height="50px"
                        width="200px"
                        onClick={() => {
                            setChoosenItem(licenses.length + 1);
                            handleAddLicense();
                        }}
                        mb="15px"
                        text={!showLicenseEditor ? '+ Add License' : 'Undo Add'}
                    />
                </Flex>
                {licenses.length > 0 &&
                    licenses.map((license, index) => (
                        <LicenseItem
                            key={license.id}
                            license={license}
                            states={states}
                            draft={draft}
                            addLicenseLoading={addLicenseLoading}
                            saveDraftLoading={saveDraftLoading}
                            index={index}
                            showLicenseEditor={showLicenseEditor}
                            accessToken={accessToken}
                            choosenItem={choosenItem}
                            saveLicenseDraft={saveLicenseDraft}
                            addLicense={addLicense}
                            setStateId={setStateId}
                            handleAddLicense={handleAddLicense}
                            setChoosenItem={setChoosenItem}
                        />
                    ))}
                {showLicenseEditor && (
                    <AddLicenseItem
                        licenses={licenses}
                        agencies={agencies}
                        states={states}
                        draft={draft}
                        addLicenseLoading={addLicenseLoading}
                        saveDraftLoading={saveDraftLoading}
                        index={licenses.length + 1}
                        showLicenseEditor={showLicenseEditor}
                        accessToken={accessToken}
                        stateId={stateId}
                        choosenItem={choosenItem}
                        saveLicenseDraft={saveLicenseDraft}
                        addLicense={addLicense}
                        setStateId={setStateId}
                        handleAddLicense={handleAddLicense}
                        setChoosenItem={setChoosenItem}
                        refProp={addLicenseBoxRef}
                    />
                )}
            </Flex>
        )}
        </>
    );
};
