import axios from 'axios';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from '../../../common/components';
import { HOMEPAGE_PATH } from '../../../common/constants';
import { MatchParams } from '../../../common/types';
import { dispatch } from '../../../store';
import { loginSuccess } from '../../../store/actions/auth/auth.actions';

export interface Iinterface {

}


export const TokenLogin: React.FC<Iinterface> = () => {
    const history = useHistory();

    const match = useRouteMatch<MatchParams>();
    const [loading, setloading] = useState<boolean>(true);

    const search = useLocation().search;
    let token = new URLSearchParams(search).get("token");
    token = token? token : '';

    useEffect(() => {
      checkToken();
    }, []);

    const checkToken = () => {
      setloading(true);
      axios.post(window.baseUrl+'/api/auth/checkAuthToken', {
        token: token
      })
      .then((response: any) => {        
        setloading(false);

        dispatch(loginSuccess(response.data));
        history.push(HOMEPAGE_PATH);
      });
    };

    return (
        <div>
          {loading? (<Loader spinnerSize={'md'} centerHeight={'100%'} />) : ''}
        </div>
    );
};
