import {
    Box,
    Button,
    Flex,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Input,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { colors } from '../../../common/constants';
import { DragAndDropIcon, CloseButton, Bin, EditIcon, BackArrow } from '../../../assets/icons';
import { baseUrl } from '../../../common/api/api.base';
import { ChangeLicencePhotoPayload } from '../types';

interface IChangeLicenseModal {
    isOpen: boolean;
    onClose: () => void;
    licensePhoto: any;
    changeLicensePhoto: (v: ChangeLicencePhotoPayload) => void;
    isFieldChanged: boolean;
    clear: () => void;
}

export const ChangeLicenseModal: React.FC<IChangeLicenseModal> = ({
    isOpen,
    onClose,
    licensePhoto,
    changeLicensePhoto,
    isFieldChanged,
    clear,
}) => {
    const [photo, setPhoto] = useState<any>(null);
    const [photoFromServer, setPhotoFromServer] = useState(false);

    const handleClose = () => {
        onClose();
        clear();
    };

    useEffect(() => {
        if (licensePhoto && licensePhoto.itemHash && licensePhoto.itemHash.length > 1) {
            setPhotoFromServer(true);
        }
    }, [isOpen]);

    const handleSave = () => {
        changeLicensePhoto({ photo: photo });
        handleClose();
    };

    useEffect(() => {
        if (isFieldChanged && isOpen) {
            handleClose();
        }
    }, [isFieldChanged]);

    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const handleClick = (): void => {
        hiddenFileInput.current && hiddenFileInput.current.click();
    };

    const handleChange = (event: any): void => {
        setPhoto(event.target.files[0]);
        console.log(event.target.files[0]);
        event.target.value = null;
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose} size="large">
            <ModalOverlay />
            <ModalContent
                w={'71.5vw'}
                borderRadius={'18px'}
                h={'85vh'}
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <Box d="flex" alignItems="center" justifyContent="space-between" w="68vw">
                    <BackArrow />
                    <ModalHeader flex={1} align="center">
                        <Text>Driver License</Text>
                    </ModalHeader>
                    <Box as="button" onClick={() => onClose()}>
                        <CloseButton />
                    </Box>
                </Box>
                <ModalBody d="flex" w="68vw" flexDirection="column" overflowY="auto">
                    <Flex direction="column" align="center" mt="30px">
                        <Flex borderRadius={20} justifyContent="center">
                            <Box justifyContent="center">
                                {photo !== null || photoFromServer ? (
                                    <Image
                                        h="45vh"
                                        mb="40px"
                                        src={
                                            photoFromServer
                                                ? `${baseUrl}/api/storage/static-images/${licensePhoto?.itemHash}${licensePhoto?.itemExtension}`
                                                : URL.createObjectURL(photo)
                                        }
                                    />
                                ) : (
                                    <Box align="center">
                                        <Flex
                                            h="336px"
                                            w="343px"
                                            justifyContent="center"
                                            alignItems="center"
                                            bg="#F6F7FB"
                                            mb="40px"
                                            borderRadius="10px"
                                        >
                                            <DragAndDropIcon />
                                        </Flex>
                                    </Box>
                                )}
                                <Flex alignItems="center">
                                    <Input
                                        h="336px"
                                        w="343px"
                                        type="file"
                                        ref={hiddenFileInput}
                                        onChange={handleChange}
                                        display="none"
                                    />
                                </Flex>
                            </Box>
                        </Flex>
                        <Flex>
                            <Button
                                width="163px"
                                height="59px"
                                variant="main"
                                mr="15px"
                                onClick={() => {
                                    setPhoto(null);
                                    setPhotoFromServer(false);
                                }}
                            >
                                <Bin />
                                <Text ml="10px">Delete</Text>
                            </Button>
                            <Button
                                width="163px"
                                height="59px"
                                variant="main"
                                onClick={() => handleClick()}
                            >
                                <EditIcon color="white" />
                                <Text ml="10px">Change</Text>
                            </Button>
                        </Flex>
                        <Button
                            width="342px"
                            height="59px"
                            variant="main"
                            mt="30px"
                            onClick={handleSave}
                        >
                            Save Edits
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
