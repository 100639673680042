import {
    IResponseWithCustomValue,
    IdNameTypes,
    UserAvatarSuccessResponse,
    UserInfoSuccessResponse,
    SearchAgentByAreaResponse,
    IResponseWithoutValue,
    IUnreadResponse,
} from '../../common/types';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import API from '../../common/api/api.base';
import {
    getUserAvatarError,
    getUserAvatarRequest,
    getUserAvatarSuccess,
    getUserInfoError,
    getUserInfoRequest,
    getUserInfoSuccess,
    getProfileAgencies,
    getProfileAgenciesSuccess,
    searchAgentsByArea,
    searchAgentsByAreaSuccess,
    searchAgentsByAreaError,
    setCurrentAgencyState,
    getUnreadsData,
    setUnreadsData,
} from '../actions/user.actions';
import { Alerter } from '../../common/utils';
import qs from 'qs';

function* workerGetUnreadsData() {
    try {
        const result: IResponseWithCustomValue<IUnreadResponse> = yield call(
            API.get,
            `/api/store/unreads`,
            {},
        );
        if (result.success) {
            yield put(setUnreadsData(result.value));
        }
    } catch (err) {

    }
}

function* workerGetUserInfo() {
    try {
        const result: IResponseWithCustomValue<UserInfoSuccessResponse> = yield call(
            API.get,
            `/api/profile`,
            {},
        );
        if (result.success) {
            yield put(getUserInfoSuccess(result.value));
        } else {
            yield put(getUserInfoError());
        }
    } catch (err) {
        yield put(getUserInfoError());
    }
}

function* workerGetUserAvatar(action: ReturnType<typeof getUserAvatarRequest>) {
    try {
        const result: IResponseWithCustomValue<UserAvatarSuccessResponse> = yield call(
            API.get,
            `/api/profile/get-user-profile-image?id=${action.payload}`,
            {},
        );
        if (result.success) {
            yield put(getUserAvatarSuccess(result));
        } else {
            yield put(getUserAvatarError());
        }
    } catch (err) {
        yield put(getUserAvatarError());
    }
}

function* workerGetProfileAgencies(action: ReturnType<typeof getProfileAgencies>) {
    try {
        const result: IResponseWithCustomValue<{ agencies: IdNameTypes[] }> = yield call(
            API.get,
            `/api/profile/agencies/?id=${action.payload.id}`,
            {},
        );
        if (result.success) {
            yield put(getProfileAgenciesSuccess(result.value.agencies));
        }
    } catch (error) {
        console.log(error, 'workerGetProfileAgencies error');
    }
}
function* workerSearchAgentsByArea(action: ReturnType<typeof searchAgentsByArea>) {
    try {
        console.log('SEARCH BY AREA', action.payload);
        const result: IResponseWithCustomValue<{ users: SearchAgentByAreaResponse[] }> = yield call(
            API.get,
            `/api/profile/get-users-by-city?UserId=${action.payload.userId}&CityId=${action.payload.cityId}`,
            {},
        );
        if (result.success) {
            yield put(searchAgentsByAreaSuccess(result.value.users));
        } else {
            yield put(searchAgentsByAreaError(result.errors));
        }
    } catch (error) {
        Alerter.error('Something went wrong, try again later');
    }
}

function* workerSetCurrentAgencyState(action: ReturnType<typeof setCurrentAgencyState>) {
    const stringifiedParams = qs.stringify({ StateIds: action.payload }, { arrayFormat: 'repeat' });

    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            `/api/profile/set-current-agency-state?${stringifiedParams}`,
            {},
        );
        if (result.success) {
            window.location.reload();
        } else {
            Alerter.error('Something went wrong, try again later');
        }
    } catch (error) {
        Alerter.error('Something went wrong, try again later');
    }
}

export default function* watchUserInfoSaga() {
    yield takeEvery(getUnreadsData.type, workerGetUnreadsData);
    yield takeEvery(getUserInfoRequest.type, workerGetUserInfo);
    yield takeEvery(getUserAvatarRequest.type, workerGetUserAvatar);
    yield takeEvery(getProfileAgencies.type, workerGetProfileAgencies);
    yield takeEvery(searchAgentsByArea.type, workerSearchAgentsByArea);
    yield takeEvery(setCurrentAgencyState.type, workerSetCurrentAgencyState);
}
