import { call, put, takeEvery } from 'redux-saga/effects';

import {
    getAgenciesTypesError,
    getAllAgenciesTypesRequest,
    getAllAgenciesTypesSuccess,
} from '../../actions/directories.actions';

import Api from '../../../common/api/api.base';

import { IResponseWithCustomValue, IdNameTypes } from '../../../common/types';

function* workerGetAllAgencies() {
    try {
        const result: IResponseWithCustomValue<{
            agencies: IdNameTypes[];
        }> = yield call(Api.get, '/api/profile/agencies-all', {});

        if (result.success) {
            yield put(getAllAgenciesTypesSuccess(result.value.agencies));
        } else {
            yield put(getAgenciesTypesError(result.errors));
        }
    } catch (error) {
        console.log('Error');
        // AlerterService.error('Something went wrong, try again later');
    }
}

export function* watchAllGetAgenciesTypeDirectorySaga() {
    yield takeEvery(getAllAgenciesTypesRequest.type, workerGetAllAgencies);
}
