import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MyRequestsType } from '../../Requests/types';
import { AvailableBoxIndependent } from '../../Requests/components/AvailableBoxIndependent';
import { RequestBox } from '../../Requests/components/RequestBox';
import { setIsMyRequestInfoModalOpen } from '../../Requests/store/actions';
import { dispatch } from '../../../store';
import { RequestBoxIndependent } from '../../Requests/components/RequestBoxIndependent';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { BtnAddRequest } from '../../../common/components/aglobal/BtnAddRequest';
import { Link } from 'react-router-dom';

export const HomeMyRequests = () => {
    const [loader, setLoader] = useState<Boolean>(false);
    const [itemsRequests, setItemsRequests] = useState<MyRequestsType[]>([]);
    const [itemsBrRequests, setItemsBrRequests] = useState<MyRequestsType[]>([]);

    useEffect(() => {
      setTimeout(() => {
        getLastRequests();
        getLastBrRequests();
      }, 700);
    }, []);
  
    const getLastRequests = () => {
      setLoader(true);
      axios.post(window.baseUrl+'/api/request/pending?PageNumber=1&PageSize=4&includeOtherAgent=true')
      .then((response) => {
        if(!response.data.value.requests.length){
          setitemsRequestsEmpty(true);
        }else{
          setitemsRequestsEmpty(false);
        }

        setItemsRequests(response.data.value.requests);
        setLoader(false);
      });
    };

    const getLastBrRequests = () => {
      setLoader(true);
      axios.post(window.baseUrl+'/api/request/sent', {
        pageNumber : 1,
        pageSize: 4,
      })
      .then((response) => {
        if(!response.data.value.receivedAssistRequests.length){
          setitemsBrRequestsEmpty(true);
        }else{
          setitemsBrRequestsEmpty(false);
        }

        setItemsBrRequests(response.data.value.receivedAssistRequests);
        setLoader(false);
      });
    };

    const [tabVal, setTabVal] = useState<string>('2');
    const [itemsRequestsEmpty, setitemsRequestsEmpty] = useState<boolean>(false);
    const [itemsBrRequestsEmpty, setitemsBrRequestsEmpty] = useState<boolean>(false);

    return (
      <div className="HOME_MY_REQUESTS">
        <div className="title">
          <div className="left">
          Coverage
            <RadioGroup 
              className="radi_home_title"
              onChange={(val) => {
                setTabVal(val);
              }}
              defaultValue="2"
              >
              <Stack spacing={4} direction='row'>
                <Radio value='1'>My Requests</Radio>
                <Radio value='2'>Brokerage Requests</Radio>
              </Stack>
            </RadioGroup>
          </div>
          <div className="right">
            <div>
              <BtnAddRequest
                type="small"
              />
              </div>
          </div>
        </div>
        <div className="items_container">
          {tabVal === '1' && (
            <>
              {itemsRequestsEmpty && (
                <div className="empty_grid">
                  No records found 
                </div>
              )}
              {itemsRequests.map((elem: MyRequestsType) => (
                <RequestBoxIndependent
                  allowCountAccept={true}
                  key={elem.id}
                  request={elem}
                  requestCategories={[]}
                />
              ))}
            </>
          )}
          {tabVal === '2' && (
            <>
              {itemsBrRequestsEmpty && (
                <div className="empty_grid">
                  No records found 
                </div>
              )}
              {itemsBrRequests.map((elem: MyRequestsType) => (
                <div key={elem.id} className="fix_row_request item_row">
                  <AvailableBoxIndependent
                    request={elem}
                    emitUpdate={getLastBrRequests}
                    availableTitle={false}
                  />
                </div>
              ))}
            </>
          )}
        </div>
        <div className="bottom_panel">
          <Link to="/requests">View all Requests</Link>
        </div>
      </div>
    );
};
