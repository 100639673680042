import React from 'react';
import {
    Box,
    Flex,
    Text,
    HStack,
    Image,
    IconButton,
    chakra,
    Center,
    Button,
} from '@chakra-ui/react';
import { Location, EyeIcon, DropdownArrowIcon } from '../../../assets/icons';
import { IconDescription, CustomButton } from '../../../common/components';
import { colors } from '../../../common/constants';
import { ReactComponent as House } from '../../../assets/icons/house.svg';
import { ReactComponent as Price } from '../../../assets/icons/price.svg';
import { ReactComponent as LocationLime } from '../../../assets/icons/location.svg';
import { ListingsType, RenderTimeSlot } from '../../../common/types';
import { ItypeTimeslot } from '../../Activity/types';
import { OffMarketV2 } from '../../../assets/icons/Blast';
import { IconHomeV2 } from '../../../assets/icons/OtherIcons';

interface IListingItemProps extends ListingsType {
    onOpenModal: (value: number) => void;
    onOpenManage?: (value: number) => void;
    isMyListings?: boolean;
    onScheduleOpenHouse?: (value: number) => void;
    deniedHo?: boolean;
}

export const ListingItem: React.FC<IListingItemProps> = ({
    address,
    city,
    id,
    isOpenHouse,
    hasTimeSlots,
    onOpenModal,
    photo,
    postalCode,
    price,
    stateCode,
    type,
    timeSlot,
    isMyListings,
    listingStatusId,
    onScheduleOpenHouse,
    onOpenManage,
    hasUnpublishedExternal,
    deniedHo = false,
}) => {
    const fullAddressStr = (
        address: string | null,
        city: string | null,
        stateCode: string | null,
        postalCode: string | null,
    ) => {
        return `${address ? address + ', ' : ''}${city ? city + ', ' : ''}${
            stateCode ? stateCode + ' ' : ''
        }${postalCode ? postalCode : ''}`;
    };
    fullAddressStr(address, city, stateCode, postalCode);
    const getOpenHouseButtons = () => {
        const isMyListingsWithoutTimeSlots = !hasTimeSlots && isMyListings && listingStatusId === 1;
        const isMyListingsWithTimeSlots = hasTimeSlots && isMyListings && listingStatusId === 1;
        const isMyListingsWithExternalTimeSlots =
            hasTimeSlots && isMyListings && listingStatusId === 1 && hasUnpublishedExternal;

        let text = '';
        let onClick = () => {};

        if (isMyListingsWithExternalTimeSlots) {
            text = 'Publish O/H Cov.';
            onClick = () => onOpenManage?.(id);
        } else if (isMyListingsWithoutTimeSlots) {
            text = 'Request O/H Cov.';
            onClick = () => {
                //console.log('------>>>>>>>', onOpenManage);
                onScheduleOpenHouse?.(id)
                //onClick = () => onOpenManage?.(id);
            };
        } else if (isMyListingsWithTimeSlots) {
            text = 'Manage O/H Cov.';
            onClick = () => onOpenManage?.(id);
        } else {
            return null;
        }

        return (
            <>
                {!deniedHo && (
                    <CustomButton
                        text={text}
                        height="50px"
                        bgColor="brand"
                        alignSelf="center"
                        w="180px"
                        fontSize="14px"
                        onClick={onClick}
                    />
                )}
            </>
        );
    };

    return (
        <Flex
            className={"LISTING_ITEM "+((isOpenHouse && listingStatusId === 1)? 'ACTIVE_H' : '')}
            bg="inputBackground"
            borderRadius="14px"
            my="5px"
            justify="space-between"
            position="relative"
            padding="19px 15px"
        >
            <HStack w="40%" p="5px">
                <div className="image_relative">
                    <Image className="cover_image" w="58px" h="58px" borderRadius="16px" src={photo} />
                    {isOpenHouse && listingStatusId === 1 ? (
                        <IconHomeV2 className="icon_home_m" />
                    ) : null}
                </div>
                <Box>
                    <Text color="brand" fontSize="15px" fontWeight="700" lineHeight="18px">
                        {/*{address}, {city}, {stateCode} {postalCode}*/}
                        {fullAddressStr(address, city, stateCode, postalCode)}
                    </Text>
                    <HStack>
                        <Location />
                        <Text color="brand" fontSize="12px" lineHeight="16px" fontWeight={500}>
                            {stateCode}
                        </Text>
                    </HStack>
                </Box>
            </HStack>
            <Flex borderLeft={`1px solid ${colors.secondaryFont}`}>
                <IconDescription
                    icon={<Price />}
                    text={`$${price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                />
                <IconDescription icon={<House />} text={type.split('/').join(', ')} />
                <IconDescription icon={<LocationLime />} text={city} />
            </Flex>
            <Button
                onClick={() => onOpenModal(id)}
                bg="transparent"
                alignSelf="center"
                leftIcon={<EyeIcon />}
                fontSize="14px"
                fontWeight={400}
                color="brand"
                textDecorationLine="underline"
            >
                See Details
            </Button>
            {getOpenHouseButtons()}
            <IconButton
                onClick={() => onOpenModal(id)}
                bg="transparent"
                alignSelf="center"
                aria-label="More for job"
                icon={
                    <chakra.span transform="rotate(-90deg)">
                        <DropdownArrowIcon />
                    </chakra.span>
                }
            />
        </Flex>
    );
};
