import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Text, Avatar, HStack, Flex, IconButton, Link, SimpleGrid } from '@chakra-ui/react';
import {
    ImageLocation,
    BuildingIcon,
    LicenseIcon,
    ExperienceIcon,
    HandIcon,
    RatesIcon,
    EmailIcon,
    PhoneWithWavesIcon,
    SearchBoldIcon,
    LinkIcon,
} from '../../../../../assets/icons';
import {
    MainSummaryItem,
    YearSummaryItem,
    PieChart,
    PricingSummaryItem,
    UserProfileSocials,
} from '../components';
import { GoogleMap, useJsApiLoader, Polygon } from '@react-google-maps/api';
import { CustomButton, Loader } from '../../../index';
import {
    ProfileImageType,
    AddressLocationType,
    StateDirectoryItem,
} from '../../../../../common/types';
import { getTimeOnService, getAvatarPicture, getPolygonCenter } from '../../../../utils';
import { getAddressFromCoords } from '../../../../../pages/Settings/pages/Location/helpers/getAddressFromCoords';
import { colors } from '../../../../constants';
import { RootStore } from '../../../../../store';
import { useGetUserProfileImage } from '../../../../helpers/hooks/useGetUserProfileImage';

interface IInfoTabProps {
    userProfileImage: ProfileImageType;
    loading: boolean;
    setSearchAgentModalVisible: (isVisible: boolean) => void;
    states: StateDirectoryItem[];
}

export const InfoTab: React.FC<IInfoTabProps> = ({
    userProfileImage,
    loading,
    setSearchAgentModalVisible,
    states,
}) => {
    const [address, setAddress] = useState<AddressLocationType>({ area: '', state: '' });
    // const [stateGeocode, setStateGeocode] = useState<null | {
    //     lat: number;
    //     lng: number;
    // }>(null);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    });

    const { user: currentUser} = useSelector((state: RootStore) => state.user);
    const currentUserProfileImage = useGetUserProfileImage(currentUser.id);

    const displayRates = () => {
        let rates = [];
        if (currentUser.id !== userProfileImage.id && currentUserProfileImage) {
            rates = userProfileImage.rates;
            /* временно
            rates = userProfileImage.rates.filter(x =>
                currentUserProfileImage.rates.some(y => y.state === x.state)
            )
            */
        } else {
            rates = userProfileImage.rates;
        }

        return rates.map(rate => (
            <PricingSummaryItem
                key={rate.licenseId}
                rate={rate}
                states={states}
            />
        ))
    }

    useEffect(() => {
        if (
            userProfileImage.id &&
            userProfileImage.longitude &&
            !userProfileImage.notToUseLocation
        ) {
            getAddressFromCoords(
                userProfileImage.latitude,
                userProfileImage.longitude,
                setAddress,
                () => {},
            );
        }
    }, [userProfileImage]);

    return (
        <Flex h="100%">
            {loading ? (
                <Loader centerProps={{ flex: 1 }} />
            ) : (
                <>
                    <Box minHeight="750px" borderRight="1px solid #D1D2DB" pr="50px" mr="50px">
                        <HStack align="baseline" spacing="30px">
                            <Box border="2px dashed #2c335c" borderRadius="50%" p="5px">
                                <Avatar
                                    name={userProfileImage.fullName}
                                    w="130px"
                                    h="130px"
                                    src={getAvatarPicture(userProfileImage.avatar)}
                                />
                            </Box>
                            <Box>
                                <Text
                                    color="brand"
                                    fontSize="24px"
                                    lineHeight="36px"
                                    fontWeight="bold"
                                >
                                    {userProfileImage.fullName}
                                </Text>
                                {!userProfileImage.notToUseLocation &&
                                userProfileImage.longitude ? (
                                    <HStack my="10px">
                                        <ImageLocation />
                                        <Text
                                            color="brand"
                                            fontWeight="semibold"
                                            fontSize="16px"
                                            lineHeight="24px"
                                        >
                                            {`${address.area}, ${address.state}`}
                                        </Text>
                                    </HStack>
                                ) : null}
                                <HStack>
                                    <BuildingIcon />
                                    <Text color="brand" fontSize="14px" lineHeight="21px">
                                        {userProfileImage.agency}
                                    </Text>
                                </HStack>
                                <HStack my="10px">
                                    <LicenseIcon />
                                    <Text color="brand" fontSize="14px" lineHeight="21px">
                                        {userProfileImage.licenses}
                                    </Text>
                                </HStack>
                            </Box>
                        </HStack>
                        <Text color="brand" fontSize="18px" lineHeight="36px" mt="10px">
                            {userProfileImage.biography}
                        </Text>
                        <HStack spacing="20px" mt="15px" align="stretch">
                            {/*
                            <MainSummaryItem
                                label={
                                    userProfileImage.rating === 0
                                        ? 'No feedbacks'
                                        : `${Math.round(userProfileImage.rating)} Star Rating`
                                }
                                background={colors.lime}
                                icon={<RatesIcon />}
                            />
                            */}
                            <MainSummaryItem
                                label={getTimeOnService(userProfileImage.registrationDate)}
                                background={colors.pink}
                                icon={<ExperienceIcon />}
                            />
                            {userProfileImage.websiteLink ? (
                                <MainSummaryItem
                                    label="Agent website"
                                    background="#867AFA"
                                    icon={<LinkIcon />}
                                    url={userProfileImage.websiteLink}
                                />
                            ) : null}
                        </HStack>

                        <UserProfileSocials userData={userProfileImage} />

                        {[
                            userProfileImage?.assistHired,
                            userProfileImage?.assistGiven,
                            userProfileImage?.openHousesMade,
                            userProfileImage?.openHousesConducted,
                        ].some(elem => elem) ? (
                            <>
                                <Text
                                    color="brand"
                                    fontSize="16px"
                                    fontWeight="bold"
                                    lineHeight="24px"
                                    mt="30px"
                                >
                                    Last 12 Months
                                </Text>
                                <HStack spacing="20px" mt="10px">
                                    <YearSummaryItem
                                        amount={userProfileImage.assistGiven}
                                        label="Doors Opened"
                                    />
                                    <YearSummaryItem
                                        amount={userProfileImage.openHousesMade}
                                        label="Houses Hosted"
                                    />
                                </HStack>
                                <PieChart userProfileImage={userProfileImage} />
                            </>
                        ) : null}
                    </Box>
                    <Box flex={1}>
                        <Flex align="center" justify="space-between" mb="15px">
                            <Text color="brand" fontSize="16px" fontWeight="bold" lineHeight="24px">
                                Areas Serviced
                            </Text>
                            <IconButton
                                onClick={() => setSearchAgentModalVisible(true)}
                                aria-label="Search areas"
                                icon={<SearchBoldIcon />}
                            />
                        </Flex>
                        <Flex
                            overflowX="auto"
                            w="30vw"
                            mb="10px"
                            sx={{
                                scrollbarWidth: 'none',
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                            }}
                        >
                            {userProfileImage.preferredAreasOfServiceNames?.map(elem => (
                                <Text
                                    key={elem}
                                    color="brand"
                                    fontWeight="semibold"
                                    fontSize="16px"
                                    lineHeight="24px"
                                    mr="20px"
                                    flexShrink={0}
                                >
                                    {elem}
                                </Text>
                            ))}
                        </Flex>
                        {isLoaded ? (
                            <GoogleMap
                                options={{ disableDefaultUI: true }}
                                mapContainerStyle={{
                                    height: '275px',
                                    width: '100%',
                                    borderRadius: '20px',
                                }}
                                center={{
                                    lat: userProfileImage.polygons?.length
                                        ? getPolygonCenter(
                                              userProfileImage.polygons?.[0]?.coordinates || [],
                                          ).lat
                                        : 38.9071923,
                                    lng: userProfileImage.polygons.length
                                        ? getPolygonCenter(
                                              userProfileImage.polygons?.[0]?.coordinates || [],
                                          ).lng
                                        : -77.0368706,

                                    // lat: userProfileImage.latitude
                                    //     ? userProfileImage.latitude
                                    //     : stateGeocode?.lat || 0,
                                    // lng: userProfileImage.longitude
                                    //     ? userProfileImage.longitude
                                    //     : stateGeocode?.lng || 0,
                                }}
                                zoom={10}
                            >
                                {userProfileImage.polygons.map(elem => (
                                    <Polygon
                                        key={elem.id}
                                        paths={elem.coordinates.map(coords => ({
                                            lat: coords.lat,
                                            lng: coords.lng,
                                        }))}
                                        options={{
                                            fillColor: 'rgba(255, 0, 0,0.3)',
                                            strokeColor: 'rgba(255, 0, 0,0.8)',
                                            fillOpacity: 1,
                                        }}
                                    />
                                ))}
                            </GoogleMap>
                        ) : (
                            <></>
                        )}
                        <HStack mt="20px">
                            <Box
                                w="100%"
                                height="60px"
                                bgColor="brand"
                                d="flex"
                                alignItems="center"
                                justifyContent="center"
                                as={Link}
                                borderRadius="20px"
                                fontWeight="medium"
                                color="white"
                                href={`tel:${userProfileImage.phone}`}
                            >
                                <PhoneWithWavesIcon />
                                <Text ml="10px">Call</Text>
                            </Box>
                            <Box
                                w="100%"
                                height="60px"
                                bgColor="#3678BD"
                                d="flex"
                                alignItems="center"
                                justifyContent="center"
                                as={Link}
                                borderRadius="20px"
                                fontWeight="medium"
                                color="white"
                                href={`mailto:${userProfileImage.email}`}
                            >
                                <EmailIcon color="white" opacity={1} />
                                <Text ml="10px">Contact</Text>
                            </Box>
                            {/*
                            <Box
                                w="100%"
                                height="60px"
                                bgColor="#53A8D0"
                                d="flex"
                                alignItems="center"
                                justifyContent="center"
                                as={Link}
                                borderRadius="20px"
                                fontWeight="medium"
                                color="white"
                                href={`/find-assistance/${userProfileImage.id}`}
                            >
                                <Text ml="10px">Book Now</Text>
                            </Box>
                            */}
                        </HStack>
                        <Text
                            color="brand"
                            fontSize="16px"
                            fontWeight="bold"
                            lineHeight="24px"
                            mt="40px"
                            mb="10px"
                        >
                            Rates
                        </Text>
                        <Box 
                            overflowY="auto"
                            h="320px"
                            sx={{
                                scrollbarWidth: 'none',
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                            }}
                        >
                            {displayRates()}
                        </Box>
                    </Box>
                </>
            )}
        </Flex>
    );
};
