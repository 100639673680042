import { ComponentStyleConfig, theme as base } from '@chakra-ui/theme';
import { colors } from '../../common/constants';

export const Button: ComponentStyleConfig = {
    baseStyle: {
        borderRadius: '8px',
        color: colors.white,
        borderColor: 'none',
        fontWeight: '400',
    },
    variants: {
        main: {
            h: '50px',
            bg: colors.brand,
            color: colors.white,
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: '400',
        },
        primary: {
            ...base.components.Button.variants.solid,
            bg: 'brand',
            h: '59px',
            _hover: {
                bg: 'gray',
            },
            _active: {
                bg: 'gray',
            },
        },
        outlinePrimary: {
            ...base.components.Button.variants.outline,
            border: `1px solid ${colors.brand}`,
            h: '59px',
            color: colors.brand,
            _hover: {
                bg: 'gray.200',
            },
            _active: {
                bg: 'gray.200',
            },
        },
        link: {
            textDecoration: 'underline',
            h: 'auto',
            minH: 'auto',
            _hover: {
                _disabled: {
                    textDecoration: 'underline',
                },
            },
        },
    },
};
