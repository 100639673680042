import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    FormControl,
    FormErrorMessage,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Box,
    Text,
    Center,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { loginResolver, loginResolverNew } from '../../../common/schemes';
import { CustomButton, CustomInput } from '../../../common/components';
import { LoginPayload } from '../../../common/types';

import { CheckIcon, EmailIcon, LockIcon } from '../../../assets/icons';
import { theme } from '../../../theme';
import { SignInProps } from './types';
import axios from 'axios';
import { CheckIconV2 } from '../../../assets/icons/CalendarIcon2';
import { CheckIconV3 } from '../../../assets/icons/IconPack';
import { AuthCardPanel } from '../../../common/components/aglobal/AuthCardPanel';
import { GoogleAuth } from './GoogleAuth';

export const SignInNew: React.FC<SignInProps> = ({ loading, nestedPath }) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [userNotFound, setuserNotFound] = useState<boolean>(false);

    const history = useHistory();

    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm<LoginPayload>({ resolver: loginResolverNew });

    const onSubmit = (payload: any) => {
        checkLogin(payload);
    };

    const checkLogin = async (payload: any) => {
        setLoader(true);
        setuserNotFound(false);

        await axios.post(window.baseUrl+'/api/auth/checkemail', {
            email: payload.email
        })
        .then((res: any) => {
            setLoader(false);
            history.push('/auth/signin/login?email='+payload.email);
        }).catch(error => {
            let status = error.response.status;

            if(status === 403) {
                setuserNotFound(true);
            }

            if(status === 422) {
                setLoader(false);
                history.push('/auth/check/emailcode?email='+payload.email);
            }
        });
    };

    return (
        <Center w="100%" h="100%">
            <form className="fixed_form" onSubmit={handleSubmit(onSubmit)}>
                <Box
                    borderRadius={20}
                    backgroundColor="#FFFFFF"
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    p="60px"
										className="fixed_m_box"
										>
										<AuthCardPanel>
											<Stack backgroundColor="whiteAlpha.900">
													<Heading
															color="brand"
															fontFamily={theme.fonts.body}
															fontSize="25px"
															fontWeight="700"
															alignSelf={'start'}
													>
															Sign In
													</Heading>
													<FormControl isInvalid={!!errors.email}>
															<Text mt="30px" color="brand" fontWeight="300" fontSize="16px">
																	Email
															</Text>
															<InputGroup>
																	<InputLeftElement h="50px" w="50px" children={<EmailIcon />} />
																	<Input
																			type="text"
																			{...register('email', { required: true })}
																			label="Email"
																			width="100%"
																			placeholder="Email"
																			h="50px"
																			bg="#F6F7FB"
																			borderRadius="80px"
																			fontFamily={theme.fonts.body}
																			color={theme.colors.brand}
																			fontWeight={400}
																			fontSize="16px"
																	/>
															</InputGroup>
															{errors.email && (
																	<FormErrorMessage fontSize="16px">
																			Email is required
																	</FormErrorMessage>
															)}
													</FormControl>
													{userNotFound && (
															<div className="user_found">
																	This email can not be found. <br />
																	Please 
																	<a
																			href={`/auth/signup/createaccount`}
																	>
																	create your account
																	</a>
															</div>
													)}
													<Box mt="24px !important">
															<CustomButton
																	type="submit"
																	isLoading={loading}
																	loadingText="Signing in..."
																	text="Next"
																	width="100%"
																	height="50px"
																	bgColor="brand"
																	boxShadow="0px 3px 3px grey"
															/>
													</Box>
											</Stack>
											<div className="g-auth-outer">
												<GoogleAuth />
											</div>
										</AuthCardPanel>
                </Box>
            </form>
        </Center>
    );
};
