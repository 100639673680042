import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Text, SimpleGrid, Center, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { CustomButton } from '../../../../../common/components';
import { EditableTimeSlot } from '../../../components';

import { getTimeByTimeZone } from '../../../../../common/utils';

import {
    ApplyingJobModal,
    OpenHouseTImeSlotInfoModal,
} from '../../../../../common/components/modals';

import { RootStore } from '../../../../../store';

import {
    OpenHouseInfoType,
    ListintTimeSlotType,
    DeleteListingTimeSlotPayload,
    UpdateListingTimeSlotPayload,
    PayloadError,
    TimeSlotType,
} from '../../../../../common/types';

interface IOHDetailsTabProps {
    openHouseInfo: OpenHouseInfoType;
    setShowAddTimeSlotModal: (value: boolean) => void;
    listingId: number;
    onDeleteTimeSlot: (data: DeleteListingTimeSlotPayload) => void;
    isMyListings: boolean;
    onSelectMultipleTimeSlots: (value: number[]) => void;
    selectMultipleLoading: boolean;
    selectMultipleErrors: PayloadError[];
    createTimeSlotModalCompletedFieldsData: (value: TimeSlotType) => void;
}

const getTimeRange = (startTime: string, endTime: string, timeZone?: string) => {
    const startTime12Hours = getTimeByTimeZone(startTime, timeZone).format('hh:mm A');
    const endTime12Hours = getTimeByTimeZone(endTime, timeZone).format('hh:mm A');

    return `${startTime12Hours} - ${endTime12Hours}`;
};

export const OHDetailsTab: React.FC<IOHDetailsTabProps> = ({
    openHouseInfo,
    setShowAddTimeSlotModal,
    listingId,
    onDeleteTimeSlot,
    isMyListings,
    onSelectMultipleTimeSlots,
    selectMultipleErrors,
    selectMultipleLoading,
    createTimeSlotModalCompletedFieldsData,
}) => {
    const [selectedSlots, setSelectedSlots] = useState<number[]>([]);
    const { loading: deleteLoading, id: deleteId } = useSelector(
        (state: RootStore) => state.listings.deleteTimeSlot,
    );
    const { loading: updateLoading, id: updateId } = useSelector(
        (state: RootStore) => state.listings.updateTimeSlot,
    );
    const [timeSlotInfoModalOpen, setTimeSlotInfoModalOpen] = useState(false);
    const [agentBonusModalOpen, setAgentBonusModalOpen] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);

    const [timeSlotInfoModalData, setTimeSlotInfoModalData] = useState<TimeSlotType | null>(null);

    const onCheckRequest = (id: number, price: number) => {
        setSelectedSlots([id]);
        setTotalPrice(price);
    };
    
    return (
        <>
            <Box h="100%" className="LIST_CLIENT_OPENHOUSES">
                {isMyListings ? (
                    <>
                        {openHouseInfo.listingTimeSlots.map(
                            (listing: ListintTimeSlotType | TimeSlotType) => (
                                <EditableTimeSlot
                                    key={listing.id}
                                    {...listing}
                                    timeZone={openHouseInfo.timeZone}
                                    listingId={listingId}
                                    onDelete={onDeleteTimeSlot}
                                    setShowAddTimeSlotModal={setShowAddTimeSlotModal}
                                    deleteLoading={deleteLoading && deleteId === listing.id}
                                    updateLoading={updateLoading && updateId === listing.id}
                                    createTimeSlotModalCompletedFieldsData={() => {
                                        createTimeSlotModalCompletedFieldsData(listing);
                                    }}
                                />
                            ),
                        )}
                        <CustomButton
                            text="Add time slot"
                            bgColor="brand"
                            w="250px"
                            height="60px"
                            onClick={() => setShowAddTimeSlotModal(true)}
                        />
                    </>
                ) : (
                    <Flex
                        direction="column"
                        h="100%"
                        justifyContent="space-between"
                        overflowY="auto"
                    >
                        <SimpleGrid columns={3} gap="20px">
                            {openHouseInfo.listingTimeSlots &&
                            openHouseInfo.listingTimeSlots.length !== 0
                                ? openHouseInfo.listingTimeSlots.map(time => {
                                      const headerTime = dayjs(time.date).format(
                                          'dddd - MMMM D, YYYY',
                                      );
                                      const timeRange = getTimeRange(
                                          time.startTime,
                                          time.endTime,
                                          openHouseInfo.timeZone,
                                      );
                                      return (
                                          <Box key={time.listingId}>
                                              <Box>
                                                  <Text
                                                      color="brand"
                                                      fontSize="18px"
                                                      fontWeight="600"
                                                      lineHeight="27px"
                                                      mb="16px"
                                                  >
                                                      {headerTime}
                                                  </Text>
                                              </Box>
                                              {time.inProgress? (
                                                <CustomButton
                                                  height="50px"
                                                  justifyContent="space-evenly"
                                                  leftIcon={
                                                      time.hasBonus ? (
                                                          <Center
                                                              h="40px"
                                                              w="40px"
                                                              borderRadius="10px"
                                                              bgColor="orange"
                                                          >
                                                              <Text color="white" fontSize="14px">
                                                                  B
                                                              </Text>
                                                          </Center>
                                                      ) : undefined
                                                  }
                                                  bgColor="brand"
                                                  onClick={() => {

                                                  }}
                                                  text={timeRange+ "(in progress)"}
                                              />
                                              ) : (
                                              <CustomButton
                                                  height="50px"
                                                  justifyContent="space-evenly"
                                                  leftIcon={
                                                      time.hasBonus ? (
                                                          <Center
                                                              h="40px"
                                                              w="40px"
                                                              borderRadius="10px"
                                                              bgColor="orange"
                                                          >
                                                              <Text color="white" fontSize="14px">
                                                                  B
                                                              </Text>
                                                          </Center>
                                                      ) : undefined
                                                  }
                                                  bgColor="brand"
                                                  onClick={() => {
                                                      setTimeSlotInfoModalData(time);
                                                      onCheckRequest(time.requestId, time.price);
                                                  }}
                                                  text={timeRange}
                                              />
                                              )}
                                              <Text color="chakraErrorRed" fontSize="13px">
                                                  {
                                                      selectMultipleErrors.find(
                                                          elem => +elem.key === time.requestId,
                                                      )?.errorMessage
                                                  }
                                              </Text>
                                          </Box>
                                      );
                                  })
                                : null}
                        </SimpleGrid>
                    </Flex>
                )}
            </Box>
            <ApplyingJobModal
                isOpen={agentBonusModalOpen}
                onClose={() => setAgentBonusModalOpen(false)}
                onConfirm={() => onSelectMultipleTimeSlots(selectedSlots)}
                amount={totalPrice}
                type={'$'}
            />

            <OpenHouseTImeSlotInfoModal
                isOpen={Boolean(timeSlotInfoModalData)}
                onClose={() => setTimeSlotInfoModalData(null)}
                displayData={timeSlotInfoModalData as TimeSlotType}
                confirmButtonText="Continue"
                confirmButtonAction={() => {
                    setTimeSlotInfoModalData(null);
                    setAgentBonusModalOpen(true);
                }}
                timeZone={openHouseInfo.timeZone}
            />
        </>
    );
};
