import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, HStack, Checkbox, Text, Input, Flex, SimpleGrid } from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';

import {
    DatePicker,
    CustomNumberInput,
    CustomInput,
    CustomDropdown,
    CustomButton,
} from '../../../common/components';
import { BonusConfirmModal, OpenHouseTImeSlotInfoModal } from '../../../common/components/modals';

import {
    addListingTimeSlot,
    recreateExternalTimeSlot,
    updateListingTimeSlot,
} from '../../../store/actions/listings.actions';
import { timeSlotsResolver } from '../../../common/schemes';
import { getTimeByTimeZone, convertTimeIn24HoursFormat } from '../../../common/utils';
import { getTimeOffset } from '../../../common/components/blocks/DatePicker/utils';

import { sellerTypes } from '../../../common/constants';

import { IdNameTypes } from './NewTimeSlot';
import { TimeSlotType, TimeSlotTypeToServer } from '../../../common/types';

interface IAddTimeSlotCoreProps {
    loading: boolean;
    listingId: number;
    openHouseHostTypes: Array<IdNameTypes>;
    openHouseAudiences: Array<IdNameTypes>;
    openHouseTypes: Array<IdNameTypes>;
    openHouseTypesLoading: boolean;
    timeZone: string;
    completedFieldsData?: TimeSlotType;
}

const getTimeByDate = (
    dateISOString: string | number,
    hours: number,
    minutes: number,
    timeZone?: string,
) => {
    const date = getTimeByTimeZone(dateISOString, timeZone).add(1, 'day');

    return date.set('hour', hours).set('minute', minutes).toISOString();
};

export const AddTimeSlotCore: React.FC<IAddTimeSlotCoreProps> = ({
    listingId,
    loading,
    openHouseHostTypes,
    openHouseAudiences,
    openHouseTypes,
    timeZone = '',
    completedFieldsData,
}) => {
    const [isWantToPay, setIsWantToPay] = useState(false);
    const [bonusType, setBonusType] = useState('2');
    const [offer, setOffer] = useState(false);
    const [typeOH, setTypeOH] = useState('1');
    const [hostType, setHostType] = useState('1');
    const [agentBonusModalOpen, setAgentBonusModalOpen] = useState(false);
    const [timeSlotInfoModalOpen, setTimeSlotInfoModalOpen] = useState(false);
    const [bonusAmount, setBonusAmount] = useState('');

    const hasInterestedApplicant = completedFieldsData?.hasInterestedApplicant;
    const isFromExternalDb = completedFieldsData?.isFromExternalDb;

    const dispatch = useDispatch();
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        clearErrors,
        trigger,
        reset,
        getValues,
    } = useForm({
        resolver: timeSlotsResolver,
    });

    const timeOffset = getTimeOffset(timeZone);

    const [date, openHouseTypeId, wouldLikeToOfferBonus, openHouseHostTypeId, watchBonusAmount] =
        watch([
            'date',
            'openHouseTypeId',
            'wouldLikeToOfferBonus',
            'openHouseHostTypeId',
            'bonusAmount',
        ]);


    const onError = (data: any) => {
        console.log('---2222222');
        console.log(data);
    }

    const onSubmit = (data: TimeSlotType) => {
        console.log('---11111111>');

        if (!timeSlotInfoModalOpen && !agentBonusModalOpen) {
            setTimeSlotInfoModalOpen(true);
            return;
        }

        const startTime = convertTimeIn24HoursFormat(data.startTime) || '';
        const endTime = convertTimeIn24HoursFormat(data.endTime) || '';

        const dataCorrectTypes: TimeSlotTypeToServer = {
            ...data,
            date: getTimeByTimeZone(data.date).toISOString(),
            startTime: getTimeByDate(
                data.date,
                Number(startTime.split(':')[0]),
                Number(startTime.split(':')[1]),
                timeZone,
            ),
            endTime: getTimeByDate(
                data.date,
                Number(endTime.split(':')[0]),
                Number(endTime.split(':')[1]),
                timeZone,
            ),
            wouldLikeToOfferBonus: offer,
            clientTypeId: +data.clientTypeId,
        };

        delete dataCorrectTypes.price;

        const dispatchRecreateData = {
            listingId,
            openHouseTimeSlotPriceModel: dataCorrectTypes,
            timeOffset,
        };

        const dispatchAddData = {
            listingId,
            openHouseTimeSlotPriceModels: [dataCorrectTypes],
            timeOffset,
        };

        if (data.isFromExternalDb && !data.published) {
            dispatch(recreateExternalTimeSlot(dispatchRecreateData));
        } else if (completedFieldsData) {
            dispatch(updateListingTimeSlot(dataCorrectTypes));
        } else {
            dispatch(addListingTimeSlot(dispatchAddData));
        }
    };

    useEffect(() => {
        if (completedFieldsData) {
            const fieldData: TimeSlotTypeToServer = { ...completedFieldsData };

            delete fieldData.price;

            //const startTime = getTimeByTimeZone(fieldData.startTime, timeZone).format('hh:mm A');
            //const endTime = getTimeByTimeZone(fieldData.endTime, timeZone).format('hh:mm A');


            const startTime = getTimeByTimeZone(fieldData.startTime).format('hh:mm A');
            const endTime = getTimeByTimeZone(fieldData.endTime).format('hh:mm A');

            fieldData.date = new Date(fieldData.date).getTime();

            setBonusType(String(fieldData.bonusTypeId));
                
            reset({ ...fieldData, startTime, endTime });
        }
    }, [timeZone]);

    useEffect(() => {
        if (String(wouldLikeToOfferBonus) === 'true') {
            setOffer(true);

            if (String(bonusType) === '1') {
                setBonusAmount(`${watchBonusAmount}% of the commission`);
            } else {
                setBonusAmount(`$${watchBonusAmount}.00`);
            }
        } else {
            setOffer(false);
        }
    }, [watchBonusAmount, bonusType, wouldLikeToOfferBonus]);

    useEffect(() => {
        setHostType(openHouseHostTypeId);
    }, [openHouseHostTypeId]);

    useEffect(() => {
        setTypeOH(openHouseTypeId);

        if (String(openHouseTypeId) === '1') {
            setValue('virtualOpenHouseLink', '');
        }
    }, [openHouseTypeId]);

    console.log(sellerTypes);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                    <SimpleGrid columns={2} gap="50px" rowGap="0px">
                        <DatePicker
                            minimumDate={new Date()}
                            {...register('date')}
                            displayValue={date ? dayjs.utc(date).format('MM/DD/YY') : ''}
                            label="Date:*"
                            placeholder="MM/DD/YY"
                            setValue={(value: number) => {
                                setValue('date', value);
                                clearErrors('date');
                            }}
                            borderRadius="50px"
                            value={date}
                            mbCustom="20px"
                            errMessage={errors.date?.message}
                            isDisabled={hasInterestedApplicant || isFromExternalDb}
                        />
                        <HStack align="baseline">
                            <CustomInput
                                width="170px"
                                register={{
                                    ...register('startTime'),
                                }}
                                type="time-12-hours"
                                label="Start Time:*"
                                defaultValue="08:00 AM"
                                errors={errors?.startTime}
                                margin={errors?.startTime ? '0' : '0 0 26px 0'}
                                disabled={hasInterestedApplicant || isFromExternalDb}
                            />
                            <CustomInput
                                width="170px"
                                register={{
                                    ...register('endTime'),
                                }}
                                type="time-12-hours"
                                label="End Time:*"
                                defaultValue="09:00 PM"
                                errors={errors?.endTime}
                                margin={errors?.endTime ? '0' : '0 0 26px 0'}
                                disabled={hasInterestedApplicant || isFromExternalDb}
                            />
                        </HStack>
                        <CustomDropdown
                            width="350px"
                            label="Who Will Host This House?*"
                            options={openHouseHostTypes.map(it => ({
                                value: it.id,
                                label: it.name,
                            }))}
                            register={{ ...register('openHouseHostTypeId') }}
                            onChange={(value: any) => {
                                setValue('openHouseHostTypeId', value);
                            }}
                            errors={errors?.openHouseHostTypeId}
                            disabled={hasInterestedApplicant}
                        />
                        {String(hostType) === '1' && (
                            <>
                                <CustomDropdown
                                    label="The Seller is:*"
                                    options={sellerTypes.map(it => ({
                                        value: it.id,
                                        label: it.name,
                                    }))}
                                    placeholder="Select"
                                    register={{ ...register('clientTypeId') }}
                                    errors={errors?.clientTypeId}
                                    width="350px"
                                    margin={errors?.clientTypeId ? '0' : '0 0 26px 0'}
                                />
                                <CustomDropdown
                                    options={[
                                        { label: 'Yes', value: true, id: 1 },
                                        { label: 'No', value: false, id: 2 },
                                    ]}
                                    label="I would like to offer a Bonus if a contract is ratified from the open house within 48-hours after the showing: *"
                                    placeholder="Select"
                                    register={{ ...register('wouldLikeToOfferBonus') }}
                                    onChange={(ev: any) => {
                                        setValue('wouldLikeToOfferBonus', ev.target.value);
                                    }}
                                    errors={errors?.wouldLikeToOfferBonus}
                                    width="350px"
                                    disabled={hasInterestedApplicant}
                                />

                                {offer && (
                                    <SimpleGrid columns={2} gap="10px" rowGap="0px" pt="40px">
                                        <CustomDropdown
                                            options={[
                                                {
                                                    label: '$',
                                                    value: 2,
                                                    id: 2,
                                                },
                                                {
                                                    label: '%',
                                                    value: 1,
                                                    id: 1,
                                                },
                                            ]}
                                            label="Select bonus type:*"
                                            register={{
                                                ...register('bonusTypeId', {
                                                    value: bonusType,
                                                    onChange: (ev: any) => {
                                                        setBonusType(ev.target.value);
                                                    },
                                                }),
                                            }}
                                            errors={errors?.bonusTypeId}
                                            width="170px"
                                            disabled={hasInterestedApplicant}
                                        />
                                        <CustomNumberInput
                                            register={{ ...register('bonusAmount') }}
                                            type="number"
                                            label="Enter bonus:*"
                                            errors={errors?.bonusAmount}
                                            rightIcon={
                                                String(bonusType) === '1' ? (
                                                    <Text color="secondaryFontDarker">%</Text>
                                                ) : (
                                                    <Text color="secondaryFontDarker">$</Text>
                                                )
                                            }
                                            placeholder="100"
                                            width="170px"
                                            margin={
                                                errors?.bonusAmount ? '0' : '0 0 26px 0 !important'
                                            }
                                            max={String(bonusType) === '1' ? 100 : undefined}
                                            disabled={hasInterestedApplicant}
                                        />
                                    </SimpleGrid>
                                )}
                                <Box pt={offer ? '0px' : '40px'}>
                                    <CustomInput
                                        register={{ ...register('privateNote') }}
                                        label="Private Notes To The Chosen Agent:"
                                        placeholder="Enter"
                                        errors={errors?.privateNote}
                                        height="50px"
                                    />
                                </Box>
                            </>
                        )}
                        {openHouseAudiences && (
                        <Box pt={String(hostType) === '2' ? '0px' : offer ? '0px' : '20px'}>
                            {openHouseAudiences && 
                            <CustomDropdown
                                width="350px"
                                label="Audience for Open House:*"
                                options={openHouseAudiences.map(it => ({
                                    value: it.id,
                                    label: it.name,
                                }))}
                                register={{ ...register('openHouseAudienceId') }}
                                onChange={(type: any) => {
                                    setValue('openHouseAudienceId', type);
                                }}
                            />
                            }
                        </Box>
                        )}
                        <Box pt="20px">
                            <CustomDropdown
                                width="350px"
                                label="Type of Open House:*"
                                options={openHouseTypes.map(it => ({
                                    value: it.id,
                                    label: it.name,
                                }))}
                                register={{ ...register('openHouseTypeId') }}
                                onChange={(type: any) => {
                                    setValue('openHouseTypeId', type);
                                }}
                                disabled={hasInterestedApplicant}
                            />
                        </Box>
                        <Box pt="20px">
                            <CustomDropdown
                                width="350px"
                                label="Audience for Open House"
                                options={[
                                    {
                                        id: 1,
                                        label: 'Public',
                                        value: 'Public',
                                    },
                                    {
                                        id: 2,
                                        label: 'Broker',
                                        value: 'Broker',
                                    },
                                ]}
                                register={{ ...register('audience_open_house') }}
                                onChange={(type: any) => {
                                    setValue('audience_open_house', type);
                                }}
                            />
                        </Box>
                        {String(typeOH) === '2' && (
                            <Box pt="20px">
                                <CustomInput
                                    register={{ ...register('virtualOpenHouseLink') }}
                                    type="text"
                                    label="Link To Virtual Open House"
                                    errors={errors?.virtualOpenHouseLink}
                                    placeholder="Enter"
                                    width="350px"
                                    margin={
                                        errors?.virtualOpenHouseLink ? '0' : '0 0 26px 0 !important'
                                    }
                                />
                            </Box>
                        )}
                    </SimpleGrid>
                    {String(hostType) === '1' && (
                        <>
                            <Checkbox
                                defaultIsChecked={false}
                                checked={isWantToPay}
                                {...register('iWillPayAgentForHosting')}
                                onChange={ev => {
                                    setValue('iWillPayAgentForHosting', ev.target.checked); // It`s because of Safari
                                    setIsWantToPay(ev.target.checked);
                                }}
                                colorScheme="checkboxScheme"
                                mb="5px"
                                mt={String(typeOH) === '2' ? '0px' : '30px'}
                                isDisabled={hasInterestedApplicant}
                            >
                                <Text color="brand" fontWeight="300" fontSize="14px">
                                    I Will Pay Agent For Hosting This O/H
                                </Text>
                            </Checkbox>
                        </>
                    )}
                    <Controller
                        render={({ field }) => (
                            <Input label="" defaultValue={field.value} display="none" />
                        )}
                        name="listingId"
                        defaultValue={listingId}
                        control={control}
                    />
                </Box>
                <Flex justifyContent="flex-end">
                    <Box width="300px" mt="50px">
                        <CustomButton
                            className="BTN_SEND_OPEN_HOUSE_C"
                            text="Post"
                            bgColor="#2C335C"
                            height="50px"
                            minH="50px"
                            isLoading={loading}
                            mb="40px"
                            onClick={() => {
                                console.log('-->');
                                handleSubmit(onSubmit, onError)();
                            }}
                        />
                    </Box>
                </Flex>
            </form>
            <BonusConfirmModal
                isOpen={agentBonusModalOpen}
                onClose={() => setAgentBonusModalOpen(false)}
                onConfirm={handleSubmit(onSubmit)}
                amount={bonusAmount}
                type={'Open House'}
            />

            <OpenHouseTImeSlotInfoModal
                isOpen={timeSlotInfoModalOpen}
                onClose={() => setTimeSlotInfoModalOpen(false)}
                displayData={{
                    ...(getValues() as TimeSlotType),
                    date: completedFieldsData?.date || getValues().date,
                }}
                confirmButtonText="Post"
                confirmButtonAction={
                    String(wouldLikeToOfferBonus) === 'true' && bonusAmount && bonusType
                        ? async () => {
                              const result = await trigger();
                              if (result) {
                                  setAgentBonusModalOpen(true);
                                  setTimeSlotInfoModalOpen(false);
                              }
                          }
                        : handleSubmit(onSubmit)
                }
                timeZone={timeZone}
            />
        </>
    );
};
