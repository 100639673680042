/* eslint-disable prefer-arrow-callback */
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from '../../schemes/messages';

const requestShowingDateSchema = yup.object().shape({
    amount: yup
        .string()
        .default('')
        .test('required', ErrorMessages.REQUIRED, function (value?: string) {
            value = (value === undefined || value === '')? '0' : value;
            let valueInt = parseInt(value);
            
            return (valueInt < 1)? false : true;
        })
        .test('minprice', 'Minimum deposit amount 75$', function (value?: string) {
            value = (value === undefined || value === '')? '0' : value;
            let valueInt = parseInt(value);
            
            return (valueInt >= 75)? true : false;
        })
});

export const RequestBalanceResolver = yupResolver(requestShowingDateSchema);
