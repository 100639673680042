/* eslint-disable prefer-arrow-callback */
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { ErrorMessages } from './messages';
import { SearchBy } from '../../pages/FindAssistance/types';
import { ampmTimeYupResolver } from '../constants';
import { isNull } from 'util';

const addressModelSchema = {
    name: yup.string().required(ErrorMessages.REQUIRED),
};

const requestShowingDateSchema = yup.object().shape({
    dateTime: yup
        .string()
        .test('in_the_future', ErrorMessages.PAST_DATE, function (value?: string) {
            return dayjs.utc(value ? +value : undefined).isSameOrAfter(dayjs(), 'day');
        })
        .test('required', ErrorMessages.REQUIRED, function (value?: string) {
            return this.parent.searchBy === SearchBy.Location ? true : !!value;
        }),
    fromTime: ampmTimeYupResolver,
    propertiesToShow: yup
        .number()
        .min(1, ErrorMessages.POSITIVE_NUMBER)
        .max(15, 'This value should be less than 15')
        .required(ErrorMessages.REQUIRED)
        .typeError(ErrorMessages.REQUIRED),
    states: yup.string().required(ErrorMessages.REQUIRED),
    privateNote: yup.string().default(''),
    generalNote: yup.string().default(''),
    wouldLikeToOfferBonus: yup.string().required(ErrorMessages.REQUIRED),
    preferences: yup.string().required(ErrorMessages.REQUIRED),
    amount: yup
        .string()
        .default('')
        .test('required', ErrorMessages.REQUIRED, function (value?: string) {
            if(this.parent.wouldLikeToOfferBonus === '2' || !value){
                return true;
            }

            let valueInt = parseInt(value);
            return this.parent.searchBy === SearchBy.Location && this.parent.bonusTypeId
                ? !!valueInt
                : true;
        })
        .test('percent-validation', 'Percents can`t be larger than 100', function (value?: string) {
            if(this.parent.wouldLikeToOfferBonus === '2' || !value){
                return true;
            }

            let valueInt = parseInt(value);
            if (this.parent.bonusTypeId === '1') {
                return valueInt ? valueInt <= 100 : false;
            }
            return true;
        }),
    clientTypeId: yup.string().required(ErrorMessages.REQUIRED),
    isShowingScheduled: yup.string().required(ErrorMessages.REQUIRED),
    startingAddress: yup
        .string()
        .test('required', ErrorMessages.REQUIRED, function (value?: string) {
            return this.parent.searchBy === SearchBy.Location ? !!value : true;
        }),
    clientName: yup.string().default(''),
    clientPhone: yup.string().default(''),
    addresses: yup.array().of(yup.object().shape(addressModelSchema)),
    preferredAgentId: yup
        .string()
        .test('preferred-agent', ErrorMessages.REQUIRED, function (value?: string) {
            return this.parent.preferrences === '3' ? !!value : true;
        }),
});

export const requestShowingResolver = yupResolver(requestShowingDateSchema);
