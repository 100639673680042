import React, { useState } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Button, Link, IconButton } from '@chakra-ui/react';
import { ReactComponent as MenuIcon } from '../../../assets/landing/landing_mobile_menu.svg';
import { colors } from '../../constants';
import { fonts } from '../../utils';
import { Link as ReactLink } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../../assets/landing/menu_close.svg';

export const MobileMenu: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Menu autoSelect={false} isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <MenuButton onClick={() => setIsOpen(true)}>
                <MenuIcon />
            </MenuButton>
            <MenuList
                zIndex={15}
                w={{ base: '90vw', md: '95vw' }}
                mt={{ base: '50px', md: '30px' }}
                pb="30px"
            >
                <IconButton
                    aria-label="close menu"
                    variant="unstyled"
                    d="flex"
                    alignItems="center"
                    ml="auto"
                    mr="10px"
                    onClick={() => setIsOpen(false)}
                >
                    <CloseIcon />
                </IconButton>

                <MenuItem d="flex" justifyContent="center">
                    <Button
                        border={'1px solid rgb(47, 128, 237)'}
                        height="59px"
                        width="175px"
                        background={'white'}
                        borderRadius="30px"
                        fontFamily={fonts.montserrat}
                        fontWeight="bold"
                        fontSize="14px"
                        _hover={{ bg: colors.secondaryFont, textDecoration: 'none' }}
                        as={Link}
                        color={colors.calendarBlue}
                        href="/auth/check/email"
                    >
                        Sign in
                    </Button>
                </MenuItem>
                <MenuItem d="flex" justifyContent="center">
                    <Button
                        height="59px"
                        width="175px"
                        background={colors.calendarBlue}
                        borderRadius="30px"
                        fontFamily={fonts.montserrat}
                        fontWeight="bold"
                        fontSize="14px"
                        _hover={{ bg: colors.brand, textDecoration: 'none' }}
                        as={Link}
                        color="white"
                        href="/auth/signup/createaccount"
                    >
                        Join
                    </Button>
                </MenuItem>
            </MenuList>
        </Menu>
    );
};
