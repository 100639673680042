import React, { useEffect, Dispatch, SetStateAction } from 'react';
import { Flex, Box, Text, HStack, VStack, Avatar } from '@chakra-ui/react';
import {
    CustomInput,
    CustomButton,
    Loader,
    OpenHouseInfoSection,
    ReportIssueButton,
} from '../../../../../common/components';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import dayjs from 'dayjs';
import { Calendar, ClockIcon } from '../../../../../assets/icons';
import { RequestInfoType } from '../../../../Requests/types';
import {
    DeleteListingTimeSlotPayload,
    OpenHouseInfoType,
    IdNameTypes,
} from '../../../../../common/types';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../../../../store';
import { colors } from '../../../../../common/constants';
import { convertTimeIn12Hours, getAvatarPicture } from '../../../../../common/utils';
import {
    getOpenHouseHostTypes,
    getClientTypes,
    getBonusTypes,
    getOpenHouseAudiences,
    getOpenHouseTypes,
} from '../../../../../store/actions/directories.actions';

interface IOpenHouseActivityInfoProps {
    openHouseInfo: OpenHouseInfoType;
    loading: boolean;
    activity: RequestInfoType;
    onDeleteTimeSlot: (value: DeleteListingTimeSlotPayload) => void;
    setToCancelWorkId?: (value: number) => void;
    setToFinishWorkId?: (value: number) => void;
    onOpenIssue: () => void;
    setConfirmApplicantModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const OpenHouseActivityInfo: React.FC<IOpenHouseActivityInfoProps> = ({
    openHouseInfo,
    loading,
    activity,
    onDeleteTimeSlot,
    setToCancelWorkId,
    setToFinishWorkId,
    onOpenIssue,
    setConfirmApplicantModalOpen,
}) => {
    const dispatch = useDispatch();
    const { loading: deleteLoading, id: deleteId } = useSelector(
        (state: RootStore) => state.listings.deleteTimeSlot,
    );

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    });

    const { openHouseHostTypes, clientTypes, bonusTypes, openHouseAudiences, openHouseTypes } =
        useSelector((state: RootStore) => state.directories);

    useEffect(() => {
        dispatch(getOpenHouseHostTypes());
        dispatch(getClientTypes());
        dispatch(getBonusTypes());
        dispatch(getOpenHouseAudiences());
        dispatch(getOpenHouseTypes());
    }, []);

    return (
        <>
            {loading ? (
                <Loader centerHeight="calc(85vh - 90px)" />
            ) : (
                <Flex position="relative">
                    <OpenHouseInfoSection
                        openHouseInfo={openHouseInfo}
                        optionalContainerProps={{
                            width: '60%',
                            mr: 0,
                            pr: '30px',
                            borderRight: 'none',
                        }}
                    />
                    <Box
                        bg="#D4E3EF"
                        position="absolute"
                        right="-70px"
                        w="calc(40% + 70px)"
                        height="calc(100% + 165px)"
                        top="-145px"
                        pt="145px"
                        pr="70px"
                        pl="30px"
                    >
                        <HStack spacing={2}>
                            <Avatar
                                name={openHouseInfo?.agentName}
                                src={getAvatarPicture(openHouseInfo?.avatar)}
                                w="54px"
                                h="54px"
                            />
                            <Box>
                                <Text
                                    color="brand"
                                    fontWeight={700}
                                    fontSize="16px"
                                    lineHeight="23px"
                                >
                                    {openHouseInfo?.agentName}
                                </Text>
                                <Text color="secondaryFontDarker" fontSize="12px" lineHeight="18px">
                                    MLS Id: {openHouseInfo?.agentMlsId}
                                </Text>
                            </Box>
                        </HStack>
                        <Text
                            mt="10px"
                            fontSize="16px"
                            color="secondaryFontDarker"
                            lineHeight="23px"
                            fontWeight={500}
                            mb="25px"
                        >
                            {openHouseInfo?.agentOfficeName}
                        </Text>
                        {isLoaded ? (
                            <GoogleMap
                                options={{ disableDefaultUI: true }}
                                mapContainerStyle={{
                                    height: '275px',
                                    width: '100%',
                                    borderRadius: '20px',
                                }}
                                center={{
                                    lat: Number(openHouseInfo?.latitude),
                                    lng: Number(openHouseInfo?.longitude),
                                }}
                                zoom={10}
                            >
                                <Marker
                                    position={{
                                        lat: Number(openHouseInfo?.latitude),
                                        lng: Number(openHouseInfo?.longitude),
                                    }}
                                />
                            </GoogleMap>
                        ) : (
                            <></>
                        )}
                        <Text
                            color="brand"
                            fontWeight="semibold"
                            fontSize="13px"
                            lineHeight="19px"
                            mt="30px"
                            mb="15px"
                        >
                            Selected Open House time
                        </Text>
                        <CustomInput
                            label="Select Date"
                            placeholder="Select Date"
                            register={{
                                value: dayjs(activity.timeSlot.date).format('MM/DD/YY'),
                            }}
                            rightIcon={<Calendar />}
                            isReadOnly={true}
                            margin="0 0 26px 0"
                        />
                        <HStack mb="26px">
                            <CustomInput
                                register={{
                                    value: convertTimeIn12Hours(
                                        activity.timeSlot.startTime,
                                        activity.timeZone,
                                    ),
                                }}
                                label="Start time"
                                placeholder="From time"
                                type="time-12-hours"
                                rightIcon={<ClockIcon color={colors.secondaryFontDarker} />}
                                width="100%"
                                isReadOnly={true}
                            />
                            <CustomInput
                                register={{
                                    value: convertTimeIn12Hours(
                                        activity.timeSlot.endTime,
                                        activity.timeZone,
                                    ),
                                }}
                                label="End time"
                                placeholder="End time"
                                type="time-12-hours"
                                width="100%"
                                rightIcon={<ClockIcon color={colors.secondaryFontDarker} />}
                                isReadOnly={true}
                            />
                        </HStack>

                        {activity.timeSlot.openHouseHostTypeId ? (
                            <CustomInput
                                label="Who Will Host This House?"
                                isReadOnly={true}
                                register={{
                                    value:
                                        openHouseHostTypes.find(
                                            (elem: IdNameTypes) =>
                                                elem.id === activity.timeSlot.openHouseHostTypeId,
                                        )?.name || '',
                                }}
                                margin="0 0 26px 0"
                            />
                        ) : null}

                        {activity.clientTypeId ? (
                            <CustomInput
                                label="The Seller is:"
                                isReadOnly={true}
                                register={{
                                    value:
                                        clientTypes.find(
                                            (elem: IdNameTypes) =>
                                                elem.id === activity.clientTypeId,
                                        )?.name || '',
                                }}
                                margin="0 0 26px 0"
                            />
                        ) : null}

                        {activity.wouldLikeToOfferBonus ? (
                            <>
                                <CustomInput
                                    label="I Would Like To Offer A Bonus If A Contract Is Ratified From The Open House Within 48-Hours After The Showing:"
                                    isReadOnly={true}
                                    register={{
                                        value: activity.wouldLikeToOfferBonus ? 'Yes' : 'No',
                                    }}
                                    margin="0 0 26px 0"
                                />
                                <Flex>
                                    <CustomInput
                                        label="Bonus Type:"
                                        isReadOnly={true}
                                        register={{
                                            value: activity.bonusTypeId
                                                ? bonusTypes.find(
                                                    (elem: IdNameTypes) =>
                                                        elem.id === activity.bonusTypeId,
                                                )?.name
                                                : '',
                                        }}
                                        margin="0 0 26px 0"
                                    />
                                    <Box w="20px" />
                                    <CustomInput
                                        label="Bonus:"
                                        isReadOnly={true}
                                        register={{
                                            value: activity.amount?.toString() || '',
                                        }}
                                        margin="0 0 26px 0"
                                    />
                                </Flex>
                            </>
                        ) : null}

                        {activity.privateNote ? (
                            <CustomInput
                                label="Private Note:"
                                isReadOnly={true}
                                register={{ value: activity.privateNote }}
                                margin="0 0 26px 0"
                            />
                        ) : null}

                        {activity.timeSlot.openHouseAudienceId ? (
                            <CustomInput
                                label="Audience for Open House:"
                                isReadOnly={true}
                                register={{
                                    value:
                                        openHouseAudiences.find(
                                            (elem: IdNameTypes) =>
                                                elem.id === activity.timeSlot.openHouseAudienceId,
                                        )?.name || '',
                                }}
                                margin="0 0 26px 0"
                            />
                        ) : null}
                        {activity.timeSlot.openHouseTypeId ? (
                            <CustomInput
                                label="Type of Open House:"
                                isReadOnly={true}
                                register={{
                                    value:
                                        openHouseTypes.find(
                                            (elem: IdNameTypes) =>
                                                elem.id === activity.timeSlot.openHouseTypeId,
                                        )?.name || '',
                                }}
                                margin="0 0 26px 0"
                            />
                        ) : null}
                        {activity.timeSlot.virtualOpenHouseLink ? (
                            <CustomInput
                                label="Link To Virtual Open House:"
                                isReadOnly={true}
                                register={{ value: activity.timeSlot.virtualOpenHouseLink }}
                                margin="0 0 26px 0"
                            />
                        ) : null}
                        {typeof activity.totalPrice === 'number' ? (
                            <CustomInput
                                label="I Will Pay Agent For Hosting This O/H"
                                isReadOnly={true}
                                register={{ value: activity.totalPrice?.toString() || '' }}
                                margin="0 0 26px 0"
                            />
                        ) : null}

                        {activity.isWorkConfirmed? '' : (
                        <>
                            {activity.isOwner ? (

                            <VStack
                                className="OPEN_HOUSE_BTNS_OWNER"
                                >
                                {activity.isWorkFinished && !activity.isWorkConfirmed ? (
                                    <CustomButton
                                        height="60px"
                                        bg={'#4FD1C5'}
                                        onClick={() => setConfirmApplicantModalOpen(true)}
                                        text="Ready"
                                        color={colors.white}
                                    />
                                ) : (
                                    <>
                                        {setToCancelWorkId ? (
                                            <CustomButton
                                                text="Cancel"
                                                bgColor="transparent"
                                                color="brand"
                                                borderColor="brand"
                                                height="60px"
                                                onClick={() => setToCancelWorkId?.(activity.id)}
                                            />
                                        ) : (
                                            {/*
                                            <CustomButton
                                                text="Delete"
                                                bgColor="transparent"
                                                color="brand"
                                                borderColor="brand"
                                                height="60px"
                                                onClick={() =>
                                                    onDeleteTimeSlot({
                                                        id: activity.timeSlot.id,
                                                        listingId: activity.timeSlot.listingId,
                                                    })
                                                }
                                                isLoading={
                                                    deleteLoading && deleteId === activity.timeSlot.id
                                                }
                                            />
                                            */}
                                        )}
                                    </>
                                )}

                                <ReportIssueButton onClick={onOpenIssue} width="100%" />
                            </VStack>


                            ) : (
                            <VStack
                                className="OPEN_HOUSE_BTNS"
                                >
                                {setToFinishWorkId && !activity.isWorkFinished && (
                                    <CustomButton
                                        text="Finish"
                                        bgColor="brand"
                                        height="60px"
                                        onClick={() => setToFinishWorkId?.(activity.id)}
                                    />
                                )}
                                
                                <ReportIssueButton onClick={onOpenIssue} width="100%" />
                            </VStack>
                            )}
                        </>
                        )}
                    </Box>
                </Flex>
            )}
        </>
    );
};
