import {
    Box,
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    IconButton,
    CloseButton,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { BackArrow, DollarInputIcon } from '../../../assets/icons';
import { CustomInput } from '../../../common/components';
import { colors } from '../../../common/constants';
import { BestAgency } from '../../../assets/icons/SidebarIcons/BestAgency';
import { EditRatePayload } from '../types';

interface IEditRatesModal {
    isOpen: boolean;
    onClose: () => void;
    rate: any;
    editRate: (v: EditRatePayload) => void;
    clear: () => void;
}

export const EditRatesModal: React.FC<IEditRatesModal> = ({
    isOpen,
    onClose,
    rate,
    editRate,
    clear,
}) => {
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
        reset,
    } = useForm({});

    const onSubmit = (data: { pricePerHour: string; pricePerDoor: string }) => {
        const payload = {
            agencyId: Number(rate.agencyId),
            agency: rate.agency.toString(),
        };
        editRate({
            licenseId: rate.licenseId,
            pricePerDoor: Number(data.pricePerDoor),
            pricePerHour: Number(data.pricePerHour),
        });
        onClose();
        clear();
    };

    useEffect(() => reset(), [rate]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
            <ModalOverlay />
            <ModalContent
                borderRadius={'18px'}
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    px="30px"
                    pt="10px"
                >
                    <IconButton
                        onClick={onClose}
                        aria-label="Close modal"
                        variant="unstyled"
                        d="flex"
                        alignItems="center"
                    >
                        <BackArrow />
                    </IconButton>
                    <ModalHeader flex={1} align="center">
                        <Text>Change Rate</Text>
                    </ModalHeader>
                    <CloseButton onClick={onClose} />
                </Flex>
                <ModalBody d="flex" flexDirection="column" overflowY="auto">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Flex
                            direction="column"
                            justify="space-between"
                            align="center"
                            mt="20px"
                            height="100%"
                            pb="20px"
                        >
                            <Flex direction="column" justify="space-between">
                                <Flex align="center">
                                    <Flex
                                        bgColor="white"
                                        justify="center"
                                        align="center"
                                        width="50px"
                                        height="50px"
                                        pt="7px"
                                        pl="7px"
                                        borderRadius="25px"
                                    >
                                        <BestAgency />
                                    </Flex>
                                    <Text fontSize="20px" fontWeight="500" color="brand" ml="15px">
                                        {rate.agency}
                                    </Text>
                                </Flex>
                                <Flex my="35px" direction="column">
                                    <CustomInput
                                        label="Price per hour"
                                        register={{ ...register('pricePerHour') }}
                                        rightIcon={<DollarInputIcon />}
                                        defaultValue={rate?.pricePerHour}
                                    />
                                    <CustomInput
                                        label="Price per door"
                                        register={{ ...register('pricePerDoor') }}
                                        defaultValue={rate?.pricePerDoor}
                                        rightIcon={<DollarInputIcon />}
                                        margin="25px 0px 0px 0px"
                                    />
                                </Flex>
                            </Flex>
                            <Button variant="main" width="300px" type="submit">
                                Save
                            </Button>
                        </Flex>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
