import React, { useEffect, useState } from 'react';
import { SubmitHandler, Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Countdown from 'react-countdown';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    Box,
    Text,
    Flex,
    HStack,
    PinInput,
    PinInputField,
    Checkbox,
    IconButton,
    useToast,
} from '@chakra-ui/react';
import { ISignUpProps, SignUpData } from '../SignUp/types';
import { CustomButton, CustomInput } from '../../../common/components';
import { BackArrow, CheckIcon, EmailIcon, NameInputIcon } from '../../../assets/icons';
import axios from 'axios';
import { ErrorMessages } from '../../../common/schemes/messages';
import { dispatch } from '../../../store';
import { loginSuccess } from '../../../store/actions/auth/auth.actions';
import { HOMEPAGE_PATH } from '../../../common/constants';
import { AuthCardPanel } from '../../../common/components/aglobal/AuthCardPanel';

export type UserModel = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
};

export interface Icomponent {
    email: string;
    code: string;
    isUpdates?: () => void;
    user: UserModel;
    clickBack?: () => void;
}

export type IFormdata = {
    id: number,
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    phoneCode: string;
};

const formChema = yup.object().shape({
    firstName: yup.string().required(ErrorMessages.REQUIRED),
    lastName: yup.string().required(ErrorMessages.REQUIRED),
    email: yup.string().required(ErrorMessages.REQUIRED),
    phoneNumber: yup.string().required(ErrorMessages.REQUIRED),
});

export const FormSendPhoneCode: React.FC<Icomponent> = ({ email, code, isUpdates, user, clickBack }) => {
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
        watch,
        reset,
        setValue,
    } = useForm<IFormdata>({
        resolver: yupResolver(formChema),
    });

    const [loading, setloading] = useState<boolean>(false);
    const [codeSend, setCodeSend] = useState<boolean>(false);

    const history = useHistory();
    const toast = useToast();

    // fill data
    useEffect(() => {
      setValue('firstName', user.first_name);
      setValue('lastName', user.last_name);
      setValue('email', user.email);
      setValue('phoneNumber', user.phone_number);
    }, [user]);

    const saveUserForm = (payload: IFormdata) => {
      setloading(true);
      axios
        .post(window.baseUrl + '/api/auth/updateuserbaseCheckuser', {
          phoneNumber: payload.phoneNumber,
          phoneCode: payload.phoneCode,
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
        })
        .then((response: any) => {
          setCodeSend(true);
          setloading(false);

          dispatch(loginSuccess(response.data));
          history.push('/settings/licenses');

          if (isUpdates) {
              isUpdates();
          }
        }).catch(() => {
          setloading(false);
          toast({
            title: 'The code is not correct',
            status: 'error',
            isClosable: true,
          });
        });
    };

    const sendCodeToPhone = (payload: IFormdata) => {
      setloading(true);
      axios
        .post(window.baseUrl + '/api/auth/sendPhoneCheckuser', {
          email: email,
          code: code,
          phoneNumber: payload.phoneNumber,
        })
        .then((response: any) => {
          setCodeSend(true);
          setloading(false);
        });
    };

    return (
        <>
            <form className="fixed_form">
                <Box
                    borderRadius={20}
                    backgroundColor="#FFFFFF"
                    maxW="100%"
                    position="relative"
                    p="60px"
                    className="fixed_m_box"
                    >
                    <AuthCardPanel>
                    <Flex
                        h="100%"
                        flexDirection="column"
                        justifyContent="space-around"
                        paddingBottom="25px"
                        position="relative"
                    >
                        <IconButton
                            position="absolute"
                            aria-label="Back"
                            variant="unstyled"
                            top={{ base: '34px', lg: '-5px' }}
                            left={{ base: '0px', lg: '-5px' }}
                            d="flex"
                            alignItems="center"
                            onClick={() => {
                                if(clickBack){
                                    setCodeSend(false);
                                    clickBack()
                                }
                            }}
                        >
                            <BackArrow />
                        </IconButton>
                        <Text
                            fontWeight={900}
                            fontSize={{ base: '14px', lg: '18px' }}
                            color="brand"
                            mt={{ base: '35px', lg: '40px' }}
                            ml={{ base: '50px', md: 0 }}
                        >
                            Can we text you at this phone number?You can change this if it is inaccurate.
                        </Text>
                        <Box marginBottom="20px">
                            <CustomInput
                                label="First Name*"
                                width="100%"
                                leftIcon={<NameInputIcon />}
                                placeholder="First Name"
                                margin="26px 0 0 0"
                                register={{ ...register('firstName') }}
                                errors={errors.firstName}
                                pattern="[A-Za-z]"
                                fontSize="14px"
                                textSize="16px"
                                isBorder={false}
                                height="50px"
                            />
                            <CustomInput
                                label="Last Name*"
                                width="100%"
                                leftIcon={<NameInputIcon />}
                                placeholder={'Last Name'}
                                register={{ ...register('lastName') }}
                                errors={errors.lastName}
                                margin="26px 0 0 0"
                                isBorder={false}
                                fontSize="14px"
                                textSize="16px"
                                height="50px"
                            />
                            <CustomInput
                                label="Email*"
                                width="100%"
                                leftIcon={<EmailIcon />}
                                placeholder="Email"
                                margin="26px 0 0 0"
                                register={{ ...register('email') }}
                                errors={errors.email}
                                isBorder={false}
                                fontSize="14px"
                                textSize="16px"
                                height="50px"
                            />
                            <CustomInput
                                disabled={codeSend? true : false}
                                label="Phone Number"
                                type="tel"
                                width="100%"
                                mask="999-999-9999"
                                placeholder="Phone Number"
                                margin="26px 0 0 0"
                                register={{ ...register('phoneNumber') }}
                                errors={errors.phoneNumber}
                                isBorder={false}
                                fontSize="14px"
                                textSize="16px"
                                height="50px"
                            />
                            {codeSend && (
                            <Box mt="16px">
                                <Controller
                                    control={control}
                                    name="phoneCode"
                                    render={({ field: { ref, ...restField } }) => (
                                        <HStack
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="space-between"
                                        >
                                            <PinInput
                                                placeholder="-"
                                                autoFocus={true}
                                                {...restField}
                                            >
                                                <PinInputField
                                                    w="65px"
                                                    h="65px"
                                                    borderRadius="15px"
                                                    ref={ref}
                                                />
                                                <PinInputField
                                                    w="65px"
                                                    h="65px"
                                                    borderRadius="15px"
                                                />
                                                <PinInputField
                                                    w="65px"
                                                    h="65px"
                                                    borderRadius="15px"
                                                />
                                                <PinInputField
                                                    w="65px"
                                                    h="65px"
                                                    borderRadius="15px"
                                                />
                                            </PinInput>
                                        </HStack>
                                    )}
                                />
                            </Box>
                            )}
                            <Box mt="24px !important">
                                <CustomButton
                                    isLoading={loading}
                                    loadingText="Signing up..."
                                    text={codeSend? 'Next' : 'Send code'}
                                    width="100%"
                                    height="60px"
                                    bgColor="brand"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if(codeSend){
                                        handleSubmit(saveUserForm)();
                                      }else{
                                        handleSubmit(sendCodeToPhone)();
                                      }
                                    }}
                                    boxShadow="0px 3px 3px grey"
                                />
                            </Box>
                        </Box>
                    </Flex>
                    </AuthCardPanel>
                </Box>
            </form>
        </>
    );
};
