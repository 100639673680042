import {
    Box,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    IconButton,
    CloseButton,
    Avatar,
} from '@chakra-ui/react';
import React, { Dispatch, SetStateAction } from 'react';
import { BackArrow, HomeWithDoorIcon, StarIcon } from '../../../assets/icons';
import { CustomButton, Loader } from '../../../common/components';
import { colors } from '../../../common/constants';
import dayjs from 'dayjs';
import { DetailReport } from '../types';
import { fonts, getAvatarPicture } from '../../../common/utils';

interface IDetailsModal {
    isOpen: boolean;
    onClose: () => void;
    detailsReportItem: DetailReport;
    requestType: string;
    onOpenAgentProfile: (id: number, toFeedback?: boolean) => void;
    detailsReportLoading: boolean;
    setShowFeedbackModal: Dispatch<SetStateAction<boolean>>;
    setShowInfoFeedbackModal: Dispatch<SetStateAction<boolean>>;
}

export const DetailsModal: React.FC<IDetailsModal> = ({
    isOpen,
    onClose,
    detailsReportItem,
    requestType,
    onOpenAgentProfile,
    detailsReportLoading,
    setShowFeedbackModal,
    setShowInfoFeedbackModal,
}) => {
    const fees = (detailsReportItem?.amountEarned / 100) * detailsReportItem?.fee;
    console.log(detailsReportItem, 'detailsReportItem');

    const isReferralReportType = detailsReportItem?.typeId === 7;

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent
                borderRadius={'18px'}
                h={'85vh'}
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                {detailsReportLoading ? (
                    <Loader centerHeight={'100%'} />
                ) : (
                    <>
                        <Flex
                            alignItems="center"
                            justifyContent={'space-between'}
                            w="100%"
                            px="30px"
                            mt="40px"
                        >
                            <Box w={'32px'} />
                            <Text
                                fontFamily={fonts.montserrat}
                                lineHeight={'18px'}
                                fontSize={'22px'}
                                fontWeight={700}
                                color="brand"
                            >
                                Details
                            </Text>

                            <CloseButton onClick={onClose} />
                        </Flex>
                        <ModalBody w="100%" overflowY="auto">
                            <Flex
                                direction="column"
                                align="center"
                                mt="20px"
                                height="100%"
                                justify="space-between"
                                pb="30px"
                            >
                                <Flex direction="column" w="100%">
                                    <Flex align="center">
                                        <Flex
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            h={'30px'}
                                            w={'30px'}
                                            bgColor={
                                                detailsReportItem.isIncome ? '#4FD1C5' : 'orange'
                                            }
                                            borderRadius="50%"
                                            p={'5px'}
                                            mr="15px"
                                        >
                                            <HomeWithDoorIcon />
                                        </Flex>
                                        {detailsReportItem.isIncome ? (
                                            <Text fontSize="18px" fontWeight="700" color="#4FD1C5">
                                                {requestType}
                                            </Text>
                                        ) : (
                                            <Text fontSize="18px" fontWeight="700" color="#FE805C">
                                                {requestType}
                                            </Text>
                                        )}
                                    </Flex>
                                    <Box borderBottom="1px solid #D1D2DB" my="15px" />
                                    <Flex alignItems={'center'}>
                                        <Avatar
                                            mr={'11px'}
                                            name={detailsReportItem.feedback?.agent}
                                            boxSize={'37px'}
                                            src={
                                                detailsReportItem.feedback?.avatar
                                                    ? getAvatarPicture(
                                                          detailsReportItem.feedback?.avatar,
                                                      )
                                                    : undefined
                                            }
                                            aria-label={'avatar'}
                                        />
                                        <Text
                                            color="brand"
                                            fontWeight="500"
                                            fontSize="16px"
                                            as="button"
                                            textDecoration="underline"
                                            onClick={() =>
                                                onOpenAgentProfile(detailsReportItem.agentId, false)
                                            }
                                        >
                                            {detailsReportItem.agent}
                                        </Text>
                                    </Flex>
                                    <Box borderBottom="1px solid #D1D2DB" my="15px" />
                                    <Flex justify="space-between">
                                        <Text
                                            color="secondaryFontDarker"
                                            fontSize="16px"
                                            fontWeight="400"
                                        >
                                            Date:
                                        </Text>
                                        <Text color="brand" fontWeight="400" fontSize="16px">
                                            {dayjs(detailsReportItem?.dateTime).format(
                                                'MMM D, YYYY',
                                            )}
                                        </Text>
                                    </Flex>
                                    <Flex justify="space-between" mt="15px">
                                        <Text
                                            color="secondaryFontDarker"
                                            fontSize="16px"
                                            fontWeight="400"
                                        >
                                            Time:
                                        </Text>
                                        <Text color="brand" fontWeight="400" fontSize="16px">
                                            {detailsReportItem?.dateTime?.slice(11, 16)}
                                        </Text>
                                    </Flex>
                                    {!isReferralReportType && (
                                        <>
                                            <Box borderBottom="1px solid #D1D2DB" my="15px" />
                                            <Flex justify="space-between">
                                                <Text
                                                    color="secondaryFontDarker"
                                                    fontSize="16px"
                                                    fontWeight="400"
                                                >
                                                    Total Doors:
                                                </Text>
                                                <Text
                                                    color="brand"
                                                    fontWeight="400"
                                                    fontSize="16px"
                                                >
                                                    {detailsReportItem?.doors}
                                                </Text>
                                            </Flex>
                                        </>
                                    )}
                                    {!detailsReportItem.isIncome && detailsReportItem.clientName ? (
                                        <Flex justify="space-between" mt="15px">
                                            <Text
                                                color="secondaryFontDarker"
                                                fontSize="16px"
                                                fontWeight="400"
                                            >
                                                Client Name:
                                            </Text>
                                            <Text color="brand" fontWeight="400" fontSize="16px">
                                                {detailsReportItem?.clientName}
                                            </Text>
                                        </Flex>
                                    ) : null}
                                    {!detailsReportItem.isIncome &&
                                    detailsReportItem.clientPhone ? (
                                        <Flex justify="space-between" mt="15px">
                                            <Text
                                                color="secondaryFontDarker"
                                                fontSize="16px"
                                                fontWeight="400"
                                            >
                                                Client Phone:
                                            </Text>
                                            <Text color="brand" fontWeight="400" fontSize="16px">
                                                {detailsReportItem?.clientPhone.slice(2)}
                                            </Text>
                                        </Flex>
                                    ) : null}

                                    <Box borderBottom="1px solid #D1D2DB" my="15px" />
                                    <Flex justify="space-between">
                                        <Text
                                            color="secondaryFontDarker"
                                            fontSize="16px"
                                            fontWeight="400"
                                        >
                                            Rate:
                                        </Text>
                                        <Flex align="center">
                                            <StarIcon color="#FFAA2A" />

                                            <Text
                                                color="brand"
                                                fontWeight="400"
                                                fontSize="16px"
                                                ml="5px"
                                            >
                                                {detailsReportItem.rate}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                    <Flex justify="space-between" mt="15px">
                                        <Text
                                            color="secondaryFontDarker"
                                            fontSize="16px"
                                            fontWeight="400"
                                        >
                                            Feedback:
                                        </Text>
                                        <Flex as="button">
                                            <Text
                                                color="brand"
                                                fontWeight="400"
                                                fontSize="16px"
                                                as="button"
                                                textDecoration="underline"
                                                onClick={() => {
                                                    if (detailsReportItem.feedback) {
                                                        setShowInfoFeedbackModal(true);
                                                    } else {
                                                        setShowFeedbackModal(true);
                                                    }
                                                }}
                                            >
                                                {detailsReportItem.feedback ? 'See' : 'Create'}
                                            </Text>
                                        </Flex>
                                    </Flex>

                                    <Box borderBottom="1px solid #D1D2DB" my="15px" />

                                    {!isReferralReportType && (
                                        <Flex justify="space-between">
                                            <Text
                                                color="secondaryFontDarker"
                                                fontSize="16px"
                                                fontWeight="400"
                                            >
                                                Amount Earned:
                                            </Text>
                                            <Text color="brand" fontWeight="400" fontSize="16px">
                                                ${detailsReportItem.amountEarned?.toFixed(2)}
                                            </Text>
                                        </Flex>
                                    )}
                                    {!isReferralReportType && !detailsReportItem.isIncome ? (
                                        <Flex justify="space-between" mt="15px">
                                            <Text
                                                color="secondaryFontDarker"
                                                fontSize="16px"
                                                fontWeight="400"
                                            >
                                                Fees:
                                            </Text>
                                            <Text color="brand" fontWeight="400" fontSize="16px">
                                                ${fees?.toFixed(2)}
                                            </Text>
                                        </Flex>
                                    ) : null}
                                    <Flex justify="space-between" my="15px">
                                        <Text
                                            color="secondaryFontDarker"
                                            fontSize="16px"
                                            fontWeight="400"
                                        >
                                            Total:
                                        </Text>
                                        <Text color="brand" fontWeight="400" fontSize="16px">
                                            {isReferralReportType
                                                ? detailsReportItem.referralFee + '%'
                                                : '$' +
                                                  (fees + detailsReportItem.amountEarned).toFixed(
                                                      2,
                                                  )}
                                        </Text>
                                    </Flex>
                                </Flex>
                                {/*<Flex justify="center">*/}
                                {/*    <CustomButton*/}
                                {/*        width="260px"*/}
                                {/*        height="50px"*/}
                                {/*        text="Close"*/}
                                {/*        bgColor="brand"*/}
                                {/*        boxShadow="0px 3px 3px grey"*/}
                                {/*        onClick={onClose}*/}
                                {/*    />*/}
                                {/*</Flex>*/}
                            </Flex>
                        </ModalBody>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};
