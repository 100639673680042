import React, { useEffect, useState } from 'react';
import { Flex, Text, Box, Button, CloseButton, Image } from '@chakra-ui/react';
import { useHistory } from 'react-router';
import { BackArrow, DollarInputIcon, EditIcon } from '../../../../assets/icons';
import { BestAgency } from '../../../../assets/icons/SidebarIcons/BestAgency';
import { CustomInput } from '../../../../common/components';
import { EditRatesModal } from '../../components/EditRatesModal';
import { useDispatch, useSelector } from 'react-redux';
import { clearFields, editRateRequest, getRatesRequest } from '../../store/actions';
import { RootStore } from '../../../../store';
import { EditRatePayload } from '../../types';
import { getAvatarPicture } from '../../../../common/utils';
import { StateLabel } from './components/StateLabel';

export const Rates: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const editRate = (payload: EditRatePayload) => dispatch(editRateRequest(payload));
    const clear = () => dispatch(clearFields());

    const rates = useSelector((state: RootStore) => state.settings.rates);
    const loading = useSelector((state: RootStore) => state.settings.loading);
    const isRateChanged = useSelector((state: RootStore) => state.settings.isRateChanged);

    const [selectedRate, setSelectedRate] = useState({});
    const [showEditRatesModal, setEditRatesModal] = useState(false);

    useEffect(() => {
        dispatch(getRatesRequest());
    }, [isRateChanged]);

    const handleEditModal = () => {
        setEditRatesModal(!showEditRatesModal);
        setSelectedRate({});
    };

    const handleChange = (rate: any) => {
        setSelectedRate(rate);
    };

    useEffect(() => {
        const doesRateExist = !!Object.keys(selectedRate).length;

        if (doesRateExist) setEditRatesModal(!showEditRatesModal);
    }, [selectedRate]);

    return (
        <Flex direction="column" pt="20px" px="20px">
            <Flex justify="space-between" align="center" mb="40px" width="100%">
                <Flex align={'center'}>
                    <Box as="button" onClick={history.goBack} mr={'36px'}>
                        <BackArrow />
                    </Box>
                    <Text fontSize="22px" fontWeight="700">
                        My Rates
                    </Text>
                </Flex>
                <CloseButton onClick={history.goBack} />
            </Flex>
            <Flex flex="1" direction="column">
                {rates.map((item: any, index: any) => (
                    <Flex flex="0.5" direction="column" pl="20px" mb="30px" key={index}>
                        <Flex>
                            <BestAgency width="47" height="47" />
                            <Text fontSize="20px" fontWeight="500" color="brand" ml="15px">
                                {item?.agency}
                            </Text>
                        </Flex>
                        <StateLabel mt="8px">{item.state}</StateLabel>
                        <Flex
                            justify="space-between"
                            align="flex-end"
                            borderBottom="1px solid #D1D2DB"
                            pb="60px"
                        >
                            <Flex mt="32px">
                                <CustomInput
                                    tooltipString="This is the rate at which you will be paid for each hour you spend on any task other than a showing."
                                    label="Price per hour"
                                    register={''}
                                    rightIcon={<DollarInputIcon />}
                                    defaultValue={item?.pricePerHour}
                                    value={item?.pricePerHour}
                                    margin="0px 25px 0px 0px"
                                    disabled
                                />
                                <CustomInput
                                    tooltipString="This is the rate at which you will be paid per property shown as part of a showing coverage request."
                                    label="Price per door"
                                    register={''}
                                    rightIcon={<DollarInputIcon />}
                                    defaultValue={item?.pricePerDoor}
                                    value={item?.pricePerDoor}
                                    disabled
                                />
                            </Flex>
                            <Button variant="outline" h="50px" onClick={() => handleChange(item)}>
                                <EditIcon color="#888DAB" />{' '}
                                <Text fontSize="18px" fontWeight="300" color="#888DAB" ml="10px">
                                    Change
                                </Text>
                            </Button>
                        </Flex>
                    </Flex>
                ))}
            </Flex>
            <EditRatesModal
                rate={selectedRate}
                isOpen={showEditRatesModal}
                onClose={handleEditModal}
                editRate={editRate}
                clear={clear}
            />
        </Flex>
    );
};
