import React, { useState } from 'react';
import { GetFundingSourcesResponse } from '../../../../../pages/Settings/pages/Payments/types';
import { fundingSourceDefault } from '../../../../../pages/Settings/pages/Payments/consts';
import { PaymentDropDown } from '../../../../../pages/Settings/pages/Payments/components';
import { Button, Flex, VStack, ModalBody, Text } from '@chakra-ui/react';
import { Header } from './Header';
import { roundAplicantPrices } from '../../../../utils';

interface IProps {
    fundingSources: Array<GetFundingSourcesResponse>;
    loading: boolean;
    onClose: () => void;
    numberOfProperties: number;
    pricePerDoor: number | null;
    fee: number;
    onConfirm: (pmntVal: number, pmntMeth: GetFundingSourcesResponse) => void;
}

export const PaymentConfirmationShowing: React.FC<IProps> = ({
    fundingSources,
    loading,
    onClose,
    fee,
    numberOfProperties,
    onConfirm,
    pricePerDoor,
}) => {
    const [selectedWallet, setSelectedWallet] =
        useState<GetFundingSourcesResponse>(fundingSourceDefault);
    const [notSelectedWalletError, setNotSelectedWalletError] = useState('');

    const total = numberOfProperties * (pricePerDoor || 0) + fee;

    const confirmAndPay = () => {
        onConfirm(total, selectedWallet);
        /*
        if (selectedWallet.dwollaId) {
            onConfirm(total, selectedWallet);
        } else {
            setNotSelectedWalletError('Please choose your payment method');
        }
        */
    };

    const pricePerDoorFix = pricePerDoor? Number(pricePerDoor) : 0;
    const feeFix = pricePerDoor? pricePerDoor*0.105 : 0;
    const totalFix = pricePerDoorFix+feeFix;

    return (
        <>
            <Header title="Payment Confirmation" onClose={onClose} />
            <ModalBody overflowY="auto">
                <Flex flexDirection={'column'} h={'full'}>
                    {/*
                    <PaymentDropDown
                        items={fundingSources}
                        setValue={(value: GetFundingSourcesResponse) => {
                            setSelectedWallet(value);
                            setNotSelectedWalletError('');
                        }}
                        value={selectedWallet}
                        loading={loading}
                        error={notSelectedWalletError}
                    />
                    */}
                    <VStack
                        borderRadius="8px"
                        w={'100%'}
                        alignItems={'stretch'}
                        p={'30px'}
                        bg={'inputBackground'}
                        mt={'26px'}
                        spacing={'20px'}
                    >
                        {/*requestTypeName*/}
                        <Text color="brand" fontSize="16px" fontWeight={700} lineHeight="22px">
                            Showing
                        </Text>
                        {/*Number of Properties:*/}
                        <Flex alignItems={'center'} justify={'space-between'}>
                            <Text fontSize="13px" color="brand" fontWeight={400} lineHeight="20px">
                                Number of Properties:
                            </Text>
                            <Text
                                fontSize="16px"
                                color="secondaryFontDarker"
                                fontWeight={500}
                                lineHeight="24px"
                            >
                                {numberOfProperties}
                            </Text>
                        </Flex>
                        <Flex alignItems={'center'} justify={'space-between'}>
                            <Text fontSize="13px" color="brand" fontWeight={400} lineHeight="20px">
                                Price Per Door:
                            </Text>
                            <Text
                                fontSize="16px"
                                color="secondaryFontDarker"
                                fontWeight={500}
                                lineHeight="24px"
                            >
                                ${roundAplicantPrices(pricePerDoorFix)}
                            </Text>
                        </Flex>
                        {/*Fee*/}
                        <Flex alignItems={'center'} justify={'space-between'}>
                            <Text fontSize="13px" color="brand" fontWeight={400} lineHeight="20px">
                                Fee:
                            </Text>
                            <Text
                                fontSize="16px"
                                color="secondaryFontDarker"
                                fontWeight={500}
                                lineHeight="24px"
                            >
                                ${roundAplicantPrices(feeFix)}
                            </Text>
                        </Flex>
                        {/*Estimated Total Cost:*/}
                        <Flex alignItems={'center'} justify={'space-between'}>
                            <Text fontSize="13px" color="brand" fontWeight={600} lineHeight="20px">
                                Estimated Total Cost:
                            </Text>
                            <Text fontSize="19px" fontWeight={600} color="brand" lineHeight="28px">
                                ${roundAplicantPrices(totalFix)}
                            </Text>
                        </Flex>
                    </VStack>
                    <Flex mt="30px" alignItems={'center'} justifyContent={'space-between'}>
                        <Button onClick={onClose} w={'47%'} variant={'outlinePrimary'}>
                            Cancel
                        </Button>
                        <Button onClick={confirmAndPay} w={'47%'} variant={'primary'}>
                            Confirm & Pay
                        </Button>
                    </Flex>
                </Flex>
            </ModalBody>
        </>
    );
};
