import { createAction } from "@reduxjs/toolkit";
import { PayloadError } from "../../common/types";
import { DashboardType, GetDashboardRequestPayload } from "../../common/types/dashboard.types";

// GET DASHBOARD
export const getDashboardRequest = createAction<GetDashboardRequestPayload>('GET_DASHBOARD_REQUEST');

export const getDashboardRequestSuccess = createAction<{
    requests: DashboardType[];
    totalCount: number;
    pageNumber: number;
}>('GET_DASHBOARD_REQUEST_SUCCESS');

export const getDashboardRequestError = createAction<PayloadError[]>('GET_DASHBOARD_REQUEST_ERROR');

export const setDashboardRequestPageNumber = createAction<number>('SET_DASHBOARD_REQUEST_PAGE_NUMBER'); 
