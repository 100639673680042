import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import authReducer from './auth/auth.slice';
import signUpReducer from '../../pages/AuthPages/SignUp/store/slice';
import changePasswordReduce from '../../pages/AuthPages/SignIn/store/slice';
import opportunitiesReducer from '../../pages/Opportunities/store/slice';
import calendarReducer from '../../pages/Calendar/store/slice';
import orderReducer from './order.slice';
import directoriesReducer from './directories.slice';
import requestReducer from '../../pages/Requests/store/slice';
import listingsReducer from './listings.slice';
import dashboardReducer from './dashboard.slice';
import userReducer from './user.slice';
import settingsReducer from '../../pages/Settings/store/slice';
import serviceHistoryReducer from '../../pages/ServiceHistory/store/slice';
import activityReducer from '../../pages/Activity/store/slice';
import postsReducer from '../../pages/Updates/store/slice';
import locationReducer from '../../pages/Settings/pages/Location/store/slice';
import notificationsReducer from '../../pages/Notifications/store/slice';
import profileReducer from './profile.slice';
import paymentsReducer from '../../pages/Settings/pages/Payments/store/slice';
import onboardingReducer from './onboarding.slice';
import landingReducer from './landing.slice';
import unsubscribeEmailReducer from '../../pages/Unsubscribe/store/slice';
import writeToSupportReducer from '../../pages/ProfileHelp/store/slice';
import subscriptionsSlice from '../../pages/Settings/pages/Subscription/store/slice';
import searchUsersSlice from '../../common/components/blocks/SearchUsers/store/slice';
import messagesReducer from './messages.slice';

const rootReducer = (history: History): Reducer =>
    combineReducers({
        router: connectRouter(history),
        auth: authReducer,
        signUp: signUpReducer,
        changePassword: changePasswordReduce,
        opportunities: opportunitiesReducer,
        calendar: calendarReducer,
        order: orderReducer,
        directories: directoriesReducer,
        requests: requestReducer,
        listings: listingsReducer,
        user: userReducer,
        settings: settingsReducer,
        serviceHistory: serviceHistoryReducer,
        activity: activityReducer,
        posts: postsReducer,
        location: locationReducer,
        notifications: notificationsReducer,
        profile: profileReducer,
        payments: paymentsReducer,
        onboarding: onboardingReducer,
        landing: landingReducer,
        unsubscribeEmail: unsubscribeEmailReducer,
        writeToSupport: writeToSupportReducer,
        subscriptions: subscriptionsSlice,
        searchUsers: searchUsersSlice,
        dashboard: dashboardReducer,
        messages: messagesReducer
    });

export default rootReducer;
