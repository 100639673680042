export const USER_SOCIAL_NETWORKS = [
    'facebook',
    'instagram',
    'twitter',
    'tikTok',
    'youTube',
    'telegram',
    'whatsApp',
    'linkedIn',
] as const;
