import { call, put, takeEvery, ForkEffect } from 'redux-saga/effects';
import {
    changePassword,
    changePasswordSuccess,
    changePasswordError,
    sendPinCode,
    sendPinCodeSuccess,
    sendPinCodeError,
    setNewPassword,
    setNewPasswordSuccess,
    setNewPasswordError,
} from './actions';
import API from '../../../../common/api/api.base';
import { ChangePasswordSuccess, SendPinCodeSuccess } from '../types';
import { push } from 'connected-react-router';
import { IResponseWithCustomValue } from '../../../../common/types';

function* workerChangePassword(action: ReturnType<typeof changePassword>) {
    try {
        const result: ChangePasswordSuccess = yield call(
            API.post,
            `/api/auth/restore-password/send`,
            action.payload,
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        );
        if (result.success) {
            yield put(changePasswordSuccess(action.payload));
            yield put(push(`code`));
        } else {
            yield put(changePasswordError(result.errors));
        }
    } catch (error) {
        yield put(changePasswordError([{ key: 'Error', errorMessage: 'Network error' }]));
    }
}

export function* watchChangePasswordSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(changePassword.type, workerChangePassword);
}

function* workerSendPinCode(action: ReturnType<typeof sendPinCode>) {
    try {
        const result: IResponseWithCustomValue<SendPinCodeSuccess> = yield call(
            API.post,
            `/api/auth/restore-password/verify-code`,
            action.payload,
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        );
        if (result.success) {
            yield put(sendPinCodeSuccess(result));
            yield put(push(`confirm`));
        } else {
            console.log(result);
            yield put(sendPinCodeError(result.errors));
        }
    } catch (error) {
        yield put(sendPinCodeError([{ key: 'Error', errorMessage: 'Network error' }]));
    }
}

export function* watchSendPinCodeSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(sendPinCode.type, workerSendPinCode);
}

function* workerSetNewPassword(action: ReturnType<typeof setNewPassword>) {
    try {
        const result: IResponseWithCustomValue<SendPinCodeSuccess> = yield call(
            API.post,
            `/api/auth/restore-password/confirm`,
            action.payload,
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        );
        if (result.success) {
            yield put(setNewPasswordSuccess(result));
            yield put(push(`login`));
        } else {
            console.log(result);
            yield put(setNewPasswordError(result.errors));
        }
    } catch (error) {
        yield put(setNewPasswordError([{ key: 'Error', errorMessage: 'Network error' }]));
    }
}

export function* watchSetNewPasswordSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(setNewPassword.type, workerSetNewPassword);
}
