import React from 'react';
import { Box, Image, Text, Heading, Stack, useMediaQuery } from '@chakra-ui/react';

import { fonts } from '../../../../common/utils';
import { colors } from '../../../../common/constants';

interface IWhyBlockProps {
    position: string;
    image: string;
    title: string;
    text: string;
    description?: string;
}

export const EdgeBlock: React.FC<IWhyBlockProps> = ({
    position,
    image,
    title,
    text,
    description,
}) => {
    const [isMobile] = useMediaQuery('(max-width: 561px)');

    return (
        <Stack
            bg="#F8F9FA"
            marginTop={{ base: '0', lg: '50px' }}
            mb={{ base: '50px', lg: 0 }}
            padding={isMobile ? '0' : '40px'}
            alignItems={{ base: 'flex-start', lg: 'center' }}
            spacing={{ base: '0', lg: '75px' }}
            direction={{ base: 'column', lg: position === 'right' ? 'row' : 'row-reverse' }}
        >
            <Box
                d="flex"
                flexDir="column"
                flex={1}
                padding={isMobile ? '30px' : '0'}
                mb="30px"
                w={{ base: '100%', lg: '50%' }}
            >
                <Heading
                    color={colors.brand}
                    fontFamily={fonts.montserrat}
                    fontSize="28px"
                    lineHeight="48px"
                    fontWeight="700"
                >
                    {title}
                </Heading>
                <Text
                    color={colors.brand}
                    fontFamily={fonts.montserrat}
                    fontSize="16px"
                    lineHeight="24px"
                    paddingTop="20px"
                    fontWeight="400"
                >
                    {text}
                </Text>
                <Text
                    color={colors.brand}
                    fontFamily={fonts.montserrat}
                    fontSize="16px"
                    lineHeight="24px"
                    paddingTop="20px"
                    fontWeight="400"
                >
                    {description}
                </Text>
            </Box>
            <Image src={image} h="fit-content" w={{ base: '100%', lg: '50%' }} maxW="561px" />
        </Stack>
    );
};
