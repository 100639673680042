import React from 'react';

import { Box, Text, Avatar, HStack, Flex, IconButton, chakra, AvatarGroup } from '@chakra-ui/react';
import dayjs from 'dayjs';
import pluralize from 'pluralize';

import { IconDescription } from '../../../common/components/blocks';
import { colors } from '../../../common/constants';
import {
    getAvatarPicture,
    fonts,
    formatDateTime,
    convertTimeIn12Hours,
} from '../../../common/utils';

import { StatusActivityItem } from '../../../common/components/aglobal/StatusActivityItem';

import {
    Calendar,
    Location,
    Clock,
    Home,
    ConsultMethodIcon,
    DropdownArrowIcon,
} from '../../../assets/icons';

import { ActivitiesResponseType } from '../types';
import { IdNameTypes } from '../../../common/types';

interface IMyActivityItemProps extends ActivitiesResponseType {
    onOpenModal: () => void;
    requestCategories: Array<IdNameTypes>;
    elem? : any
}

export const AgentActivityItem: React.FC<IMyActivityItemProps> = ({
    requestTypeName,
    states,
    ownerFullName,
    agencyName,
    addresses,
    dateTime,
    propertiesToShow,
    categoryId,
    duration,
    onOpenModal,
    requestCategories,
    mediaFileModels,
    typeId,
    state,
    timeSlot,
    timeZone,
    elem,
}) => {
    const openHouseDate = timeSlot && formatDateTime(timeSlot.date).date;
    const openHouseTimeFrom = timeSlot && convertTimeIn12Hours(timeSlot.startTime, timeZone);
    const openHouseTimeTo = timeSlot && convertTimeIn12Hours(timeSlot.endTime, timeZone);

    //var time = '';
    if(dateTime){
        var { time } = formatDateTime(dateTime.replace('0001', new Date().getFullYear().toString()));
    }

    const categoryName = requestCategories.find((item: any) => item.id === categoryId)?.name;

    return (
        <Box className="AGENCY_AC_ITEM_COMPONENT">
            <Text fontSize="15px" fontWeight="500" color="#4FD1C5" alignSelf="flex-start" mb="5px">
                {categoryName}
            </Text>
            <Flex h="80px" bg="inputBackground" borderRadius="14px" px="25px" mb="10px">
                <Flex width="40%" direction="column" justifyContent="center" maxW="300px">
                    {elem && (
                        <StatusActivityItem 
                            elem={elem}
                        />
                    )}
                    <Text color="brand" fontSize="15px" lineHeight="18px" fontWeight={600}>
                        {requestTypeName}
                    </Text>
                    <HStack>
                        <Location />
                        <Text color="brand" fontSize="12px" lineHeight="16px" fontWeight={500}>
                            {requestTypeName === 'Showing'
                                ? states.join(',')
                                : `${states.join('')}, ${addresses?.join('')}`}
                        </Text>
                    </HStack>
                    <Text color="brand" fontSize="12px" lineHeight="16px" fontWeight={500}>
                        {ownerFullName} {agencyName}
                    </Text>
                </Flex>
                <Flex borderLeft={`1px solid ${colors.secondaryFont}`}>
                    {typeId === 7 ? (
                        <IconDescription
                            icon={
                                <Text
                                    color="brand"
                                    fontSize="12px"
                                    fontWeight="500"
                                    fontFamily={fonts.montserrat}
                                >
                                    State:
                                </Text>
                            }
                            text={state || ''}
                        />
                    ) : (
                        <>
                            {typeId === 6 ? (
                                <>
                                    <IconDescription icon={<Calendar />} text={openHouseDate} />
                                    <IconDescription
                                        icon={<Clock />}
                                        text={`Fr: ${openHouseTimeFrom}`}
                                    />
                                    <IconDescription
                                        icon={<Clock />}
                                        text={`To: ${openHouseTimeTo}`}
                                    />
                                </>
                            ) : (
                                <>
                                    <IconDescription
                                        icon={<Calendar />}
                                        text={dayjs.utc(dateTime).format('MM/DD/YYYY')}
                                    />
                                    <IconDescription icon={<Clock />} text={time} />
                                    {typeId !== 4 && (
                                        <IconDescription
                                            icon={
                                                requestTypeName === 'Showing' ? (
                                                    <Home
                                                        color={colors.brand}
                                                        width="16"
                                                        height="16"
                                                    />
                                                ) : (
                                                    <ConsultMethodIcon />
                                                )
                                            }
                                            text={
                                                requestTypeName === 'Showing'
                                                    ? `${propertiesToShow} ${pluralize(
                                                          'house',
                                                          propertiesToShow,
                                                      )}`
                                                    : `${duration} ${pluralize('hour', duration)}`
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Flex>
                {mediaFileModels?.length !== 0 ? (
                    <Flex ml="auto">
                        <AvatarGroup size="sm" max={3}>
                            {mediaFileModels?.map((photo: any) => (
                                <Avatar
                                    key={photo.id}
                                    border="none"
                                    marginInlineStart="0rem"
                                    name="User"
                                    src={getAvatarPicture(photo)}
                                />
                            ))}
                        </AvatarGroup>
                    </Flex>
                ) : null}
                <IconButton
                    onClick={onOpenModal}
                    bg="transparent"
                    ml="10px"
                    alignSelf="center"
                    aria-label="More for job"
                    icon={
                        <chakra.span transform="rotate(-90deg)">
                            <DropdownArrowIcon />
                        </chakra.span>
                    }
                />
            </Flex>
        </Box>
    );
};
