import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { SingleValue } from 'react-select';
import { Text, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { DropdownArrowIcon } from '../../../../assets/icons';
import { colors } from '../../../../common/constants';
import { SelectValue } from '../../../../common/types';
import { useDebounce } from '../../../helpers/hooks/useDebounce';

interface ITypeAheadProps {
    onStateChange: (value: SingleValue<SelectValue>) => void;
    placeholder: string;
    label: string;
    errors?: any;
    loadOptions: (value: string) => Promise<SelectValue[]>;
    width?: string;
    forceShow?: boolean,
}

export const TypeAhead: React.FC<ITypeAheadProps> = ({
    onStateChange,
    placeholder,
    label,
    errors,
    loadOptions,
    width,
    forceShow
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState<Array<object>>([]);
    const [isLoading, setIsSearchingLoading] = useState(false);

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(() => {
        if (debouncedSearchTerm) {
            setIsSearchingLoading(true);
            loadOptions(debouncedSearchTerm).then(results => {
                setIsSearchingLoading(false);
                setResults(results);
            });
        } else {
            if(!forceShow){
                setResults([]);
            }else{
                setIsSearchingLoading(true);
                loadOptions(debouncedSearchTerm).then(results => {
                    setIsSearchingLoading(false);
                    setResults(results);
                });
            }
        }
    }, [debouncedSearchTerm]);

    if(forceShow){
        useEffect(() => {
            if (debouncedSearchTerm) {
                setIsSearchingLoading(true);
                loadOptions(debouncedSearchTerm).then(results => {
                    setIsSearchingLoading(false);
                    setResults(results);
                });
            } else {
                setIsSearchingLoading(true);
                loadOptions(debouncedSearchTerm).then(results => {
                    setIsSearchingLoading(false);
                    setResults(results);
                });
            }
        }, []);
    }
    
    return (
        <FormControl isInvalid={!!errors} mb={errors ? 0 : '26px'}>
            <Text color="brand" fontWeight="300" fontSize="13px">
                {label}
            </Text>
            <Select
                isClearable
                backspaceRemovesValue
                options={results}
                placeholder={placeholder}
                components={{
                    DropdownIndicator: DropdownArrowIcon,
                    IndicatorSeparator: null,
                    ClearIndicator: undefined,
                }}
                // @ts-ignore
                onChange={(newValue: SingleValue<SelectValue>) => {
                    console.log('33333', results);
                    console.log('3333311', newValue);

                    // @ts-ignore
                    setSearchTerm(newValue);
                    onStateChange(newValue);
                }}
                onInputChange={(newValue: string) => {
                    setSearchTerm(newValue);
                }}
                isLoading={isLoading}
                styles={{
                    control: provided => ({
                        ...provided,
                        borderRadius: '40px',
                        height: '50px',
                        width,
                        background: colors.inputBackground,
                        borderColor: errors ? colors.chakraErrorRed : colors.chakraBorder,
                        boxShadow: errors ? `0 0 0 1px ${colors.chakraErrorRed}` : '',
                        zIndex: 9,
                        '&:hover': {
                            borderColor: colors.chakraBorderHover,
                        },
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        borderRadius: '20px',
                        padding: '10px',
                        zIndex: 10,
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        borderRadius: '5px',
                        fontSize: '12px',
                        color: colors.brand,
                    }),
                    placeholder: provided => ({
                        ...provided,
                        fontSize: '13px',
                        color: colors.secondaryFontDarker,
                    }),
                    indicatorsContainer: () => ({
                        width: '25px',
                    }),
                    noOptionsMessage: provided => ({
                        ...provided,
                        fontSize: '13px',
                    }),
                    input: provided => ({
                        ...provided,
                        fontSize: '13px',
                        color: colors.brand,
                    }),
                    valueContainer: provided => ({
                        ...provided,
                        fontSize: '13px',
                    }),
                    singleValue: provided => ({
                        ...provided,
                        color: colors.brand,
                    }),
                    loadingMessage: provided => ({
                        ...provided,
                        fontSize: '13px',
                    }),
                }}
            />
            {errors && <FormErrorMessage fontSize="14px">{errors}</FormErrorMessage>}
        </FormControl>
    );
};
