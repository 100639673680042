import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalBody,
    Box,
    ModalContent,
    CloseButton,
    ModalHeader,
    Text,
    HStack,
    Checkbox,
} from '@chakra-ui/react';
import { CustomButton, CustomInput } from '../../blocks';
import { ApplicantToSubmitType } from '../../../../pages/Requests/types';

interface IConfirmApplicantModalProps {
    isOpen: boolean;
    onClose: () => void;
    confirmApplicationConfig: ApplicantToSubmitType | null;
    onConfirm: (value: number) => void;
}

export const ConfirmApplicantModal: React.FC<IConfirmApplicantModalProps> = ({
    isOpen,
    onClose,
    confirmApplicationConfig,
    onConfirm,
}) => {
    const [termsAccepted, setTermsAccepted] = useState(false);

    const onCloseWithClear = () => {
        onClose();
        setTermsAccepted(false);
    };

    return (
        <Modal isOpen={isOpen} onClose={onCloseWithClear} isCentered size="xl">
            <ModalOverlay />
            <ModalContent borderRadius={'18px'} bgColor="white" alignItems="center">
                <ModalHeader
                    d="flex"
                    w="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    px="50px"
                    mt="30px"
                >
                    <Text color="brand" fontSize="16px">
                        Below is estimated cost to hire this agent. Please confirm:
                    </Text>
                    <CloseButton onClick={onCloseWithClear} />
                </ModalHeader>
                <ModalBody d="flex" flexDirection="column" px="50px">
                    <HStack align="flex-start">
                        <Checkbox
                            checked={termsAccepted}
                            onChange={ev => setTermsAccepted(ev.target.checked)}
                        />
                        <Text
                            color="brand"
                            fontSize="13px"
                            lineHeight="23px"
                            fontWeight="400"
                            mt="-5px !important"
                        >
                            By checking this box, you confirm and acknowledge that you may see a
                            temporary hold on your payment method in the amount of this estimated
                            cost. Don&apos;t worry - you will only be billed after the service is
                            completed. After completion of this service, you are fully responsible
                            for the cost owed to the agent hired. There is a 10.5% transaction fee
                            is added to the total. You will not be billed until thee task is
                            complete and you can cancel the task at any time. Activities cancelled
                            less than 24 hours before the start time will be billed a cancellation
                            fee. All activities have a 1-hour minimum commitment requires.
                        </Text>
                    </HStack>
                    <Box
                        bg="bgPaymentInfo"
                        padding="19px 34px"
                        borderRadius="8px"
                        mt="24px"
                        mb="45px"
                    >
                        <HStack justifyContent="space-between">
                            <Text color="brand" fontSize="13px" fontWeight={400} lineHeight="20px">
                                Estimated Price:
                            </Text>
                            <Text
                                color="secondaryFontDarker"
                                fontSize="16px"
                                fontWeight="500"
                                lineHeight="24px"
                            >
                                {`$${confirmApplicationConfig?.price.toString()}`}
                            </Text>
                        </HStack>
                        <HStack justifyContent="space-between" my="16px">
                            <Text color="brand" fontSize="13px" fontWeight={400} lineHeight="20px">
                                Fee:
                            </Text>
                            <Text
                                color="secondaryFontDarker"
                                fontSize="16px"
                                fontWeight="500"
                                lineHeight="24px"
                            >
                                {`$${confirmApplicationConfig?.fee.toString()}`}
                            </Text>
                        </HStack>
                        <HStack justifyContent="space-between">
                            <Text color="brand" fontSize="13px" fontWeight={600} lineHeight="20px">
                                Estimated Cost:
                            </Text>
                            <Text color="brand" fontSize="19px" lineHeight="28px" fontWeight={500}>
                                {`$${Number(
                                    (confirmApplicationConfig?.price || 0) +
                                        (confirmApplicationConfig?.fee || 0),
                                ).toFixed(2)}`}
                            </Text>
                        </HStack>
                    </Box>
                    <HStack mb="45px">
                        <CustomButton
                            text="Cancel"
                            variant="outline"
                            color="brand"
                            height="60px"
                            borderColor="brand"
                            onClick={onCloseWithClear}
                        />
                        <CustomButton
                            disabled={!termsAccepted}
                            text="Hire"
                            bgColor="brand"
                            height="60px"
                            onClick={() => {
                                onConfirm(confirmApplicationConfig?.applicantId || 0);
                                onCloseWithClear();
                            }}
                        />
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
