import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Flex, Box, Text, HStack, Button } from '@chakra-ui/react';

import { HomeInspectionForm, OtherForm, ShowingForm } from './components';

import { SearchBy, FindAssistancePayloadType } from './types';

import { DashboardHeader } from '../../common/components/blocks/DashboardWrapper/DashboardHeader';
import { BackButton, CustomDropdown } from '../../common/components';
import { TypeIds } from '../../common/constants/dropdown.consatnts';
import { RootStore } from '../../store';
import { getAllAddresses, getStates } from '../../store/actions/directories.actions';
import { IAddressess, SelectValue, StateDirectoryItem } from '../../common/types';

interface IFindAssistanceProps {
    states: string[];
    onFindAssistance: (data: FindAssistancePayloadType) => void;
    assistanceLoading: boolean;
}

export const FindAssistance: React.FC<IFindAssistanceProps> = ({
    states,
    onFindAssistance,
    assistanceLoading,
}) => {
    const history = useHistory();
    const { state: defaultRequestValue } = useLocation();
    const { preferred } = useParams<{ preferred?: string }>();
    const [requestValue, setRequestValue] = useState(defaultRequestValue || TypeIds.Showing);
    const [mappedStates, setMappedStates] = useState<SelectValue[]>([]);
    const [filterStateCode, setFilterStateCode] = useState('');
    const [searchBy, setSearchBy] = useState<SearchBy>(SearchBy.Date);

    const dispatch = useDispatch();

    //const { allAddresses } = useSelector((state: RootStore) => state.allAddresses);
    //console.log(allAddresses);

    const { states: allStates }: { states: StateDirectoryItem[] } = useSelector(
        (state: RootStore) => state.directories,
    );
    
    useEffect(() => {
        dispatch(getAllAddresses());
    }, []);

    const allAddresses : Array<IAddressess> = useSelector((state: RootStore) => state.directories.allAddresses);

    const selectFilterStateCode = (stateName: string) => {
        const stateCode = allStates.find(s => s.name === stateName)?.code || '';
        setFilterStateCode(stateCode);
    };

    useEffect(() => {
        const stateMap = states.map(state => ({ value: state, label: state }));
        setMappedStates(stateMap);
    }, [states]);

    useEffect(() => {
        if (!allStates) dispatch(getStates());
    }, []);

    return (
        <Flex backgroundColor="brand" minH="100vh" flexDirection="column" alignItems="center">
            <DashboardHeader />
            <Box bgColor="#EBF2F8" w="1000px" mt="15px" borderRadius="10px" p="25px 125px">
                <Box>
                    <Flex justifyContent="space-between">
                        <Flex alignItems="center">
                            <BackButton text="" />

                            <Text
                                fontSize="24px"
                                lineHeight="25px"
                                color="brand"
                                fontWeight="bold"
                                ml="22px"
                                mb="15px"
                            >
                                Find Assistance
                            </Text>
                        </Flex>
                        <Button
                            fontSize="34px"
                            fontWeight="100"
                            color="black"
                            mb="15px"
                            onClick={() => history.goBack()}
                        >
                            &times;
                        </Button>
                    </Flex>
                </Box>
                <HStack spacing="60px" mb={'30px'}>
                    <CustomDropdown
                        label="I need help with: *"
                        options={[
                            { id: 1, label: TypeIds[TypeIds.Showing], value: TypeIds.Showing },
                            {
                                id: 2,
                                label: TypeIds[TypeIds['Home Inspection']],
                                value: TypeIds['Home Inspection'],
                            },
                            {
                                id: 3,
                                label: TypeIds[TypeIds['Final Walk-Through']],
                                value: TypeIds['Final Walk-Through'],
                            },
                            {
                                id: 5,
                                label: TypeIds[TypeIds.Appraisal],
                                value: TypeIds.Appraisal,
                            },
                            { id: 4, label: TypeIds[TypeIds.Other], value: TypeIds.Other },
                        ]}
                        errors={''}
                        width="100%"
                        register={{
                            value: requestValue,
                            onChange: (ev: any) => {
                                setRequestValue(+ev.target.value);
                            },
                        }}
                    />
                    <CustomDropdown
                        label="Search By: *"
                        options={[
                            { id: 1, label: SearchBy[SearchBy.Date], value: SearchBy.Date },
                            /*
                            {
                                id: 2,
                                label: SearchBy[SearchBy.Location],
                                value: SearchBy.Location,
                            },
                            */
                        ]}
                        register={{
                            value: searchBy,
                            onChange: (ev: any) => {
                                setSearchBy(+ev.target.value);
                            },
                        }}
                        errors={''}
                        width="100%"
                    />
                </HStack>
                {requestValue === TypeIds.Showing ? (
                    <ShowingForm
                        allAddresses={allAddresses}
                        preferredAgentId={preferred}
                        searchBy={searchBy}
                        states={mappedStates}
                        filterStateCode={filterStateCode}
                        selectFilterStateCode={selectFilterStateCode}
                        onFindAssistance={onFindAssistance}
                        requestValue={requestValue}
                        assistanceLoading={assistanceLoading}
                    />
                ) : null}
                {requestValue === TypeIds.Appraisal ||
                requestValue === TypeIds['Final Walk-Through'] ||
                requestValue === TypeIds['Home Inspection'] ? (
                    <HomeInspectionForm
                        allAddresses={allAddresses}
                        preferredAgentId={preferred}
                        searchBy={searchBy}
                        states={mappedStates}
                        filterStateCode={filterStateCode}
                        selectFilterStateCode={selectFilterStateCode}
                        onFindAssistance={onFindAssistance}
                        requestValue={requestValue}
                        assistanceLoading={assistanceLoading}
                    />
                ) : null}
                {requestValue === TypeIds.Other ? (
                    <OtherForm
                        allAddresses={allAddresses}
                        preferredAgentId={preferred}
                        searchBy={searchBy}
                        states={mappedStates}
                        filterStateCode={filterStateCode}
                        selectFilterStateCode={selectFilterStateCode}
                        onFindAssistance={onFindAssistance}
                        requestValue={requestValue}
                        assistanceLoading={assistanceLoading}
                    />
                ) : null}
            </Box>
        </Flex>
    );
};
