import React, { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Flex,
    HStack,
    PinInput,
    PinInputField,
    Button,
    CloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { CustomButton, Loader } from '..';
import { useSelector } from 'react-redux';
import { dispatch, RootStore } from '../../../store';
import { useHistory } from 'react-router-dom';
import { colors } from '../../constants';
import { BackArrow } from '../../../assets/icons/BackArrow';
import { fonts } from '../../utils/fonts';
import { FormChangeBalance } from './FormChangeBalance';
import { formatPrice } from './utils';
import { getUserInfoRequest } from '../../../store/actions/user.actions';
import { PaymentsList } from './PaymentsList';
import { Iplan } from '../../../pages/Settings/pages/Subscription/AvailableOptionsOverview';
import { AddIcon, RepeatIcon } from '@chakra-ui/icons';
import _ from 'lodash';

interface IProp {

}

export const FreshListings: React.FC<IProp> = () => {

		const [submited, setsubmited] = useState(false);

    const { user: currentUser } = useSelector((state: RootStore) => state.user);
		
		const onSubmit = async () => {
      setsubmited(true);
      await axios.post(window.baseUrl+'/api/listings/freshListings')
        .then(() => {
          dispatch(getUserInfoRequest());
        });
    };

    return (
        <>
        {!submited && !currentUser.is_active_queue? (
          <div className="btn_fresh_listings" onClick={() => {
            onSubmit();
          }}>
            {currentUser.date_parsed_advertise && (
              <div className="date_loaded_l">
                Date loaded at {currentUser.date_parsed_advertise}
              </div>
            )}
            <RepeatIcon className="cion" />
            Refresh Listings. 
          </div>
        ) : (
          <div className="btn_fresh_listings">
            New listings will appear in 10-20 minutes
          </div>
        )}
      </>
    );
};
