import React, { useCallback, useEffect, useState } from 'react';
import { CustomModalWrapper, ImageUpload, InputTextArea } from '../../components';
import { Box, HStack, ModalBody, Tag, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { getDetailsPostRequest } from '../../store/actions';
import { CustomButton, CustomInput, Loader, MultiSelect } from '../../../../common/components';
import { colors } from '../../../../common/constants';
import { IdNameTypes } from '../../../../common/types';
import { Controller, Resolver, useForm } from 'react-hook-form';
import createPostResolver, { CreatePostFormValue } from '../validation/createPost.schema';
import { getPostTags } from '../../../../store/actions/directories.actions';
import { baseUrl } from '../../../../common/api/api.base';
import { UpdatePostPayload } from '../../types';
import { State } from '../../../Sidebar/types';
//TODO Need Refactoring

interface EditPostModalProps {
    size: string;
    isOpen: boolean;
    onClose: () => void;
    title: string;
    editPostId: number;
    onUpdatePost: (payload: UpdatePostPayload) => void;
    updatePostLoading: boolean;
}

export const EditPostModal: React.FC<EditPostModalProps> = ({
    size,
    isOpen,
    onClose,
    title,
    editPostId,
    updatePostLoading,
    onUpdatePost,
}) => {
    const dispatch = useDispatch();

    const [selectedTags, setSelectedTags] = useState<Array<number>>([]);
    const [uploadedFile, setUploadedFile] = useState<string | ArrayBuffer | null>(null);
    const [photoError, setPhotoError] = useState('');

    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
        control,
        setValue,
    } = useForm<CreatePostFormValue>({
        resolver: createPostResolver as Resolver<CreatePostFormValue>,
    });

    const {
        post,
        loading,
        errors: postErrors,
    } = useSelector((state: RootStore) => state.posts.detailsPost);
    const { postTags, loading: postsLoading } = useSelector(
        (state: RootStore) => state.directories,
    );
    const currentStates = useSelector((state: RootStore) => state.user.user.currentStates);

    const onSubmit = (data: CreatePostFormValue) => {
        const formData = new FormData();
        formData.append('Photo', data.photo);
        onUpdatePost({
            Tags: selectedTags,
            Photo: formData,
            StatesIds: data.states,
            Title: data.title,
            Body: data.body,
            Id: editPostId,
            IsPhotoUpdated: typeof uploadedFile !== 'string',
        });
    };

    const onDrop = useCallback(acceptedFiles => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        if (allowedTypes.includes(acceptedFiles[0].type)) {
            console.log(acceptedFiles, 'acceptedFiles');
            setUploadedFile(acceptedFiles[0]);
            setValue('photo', acceptedFiles[0]);
            setPhotoError('');
        } else {
            setUploadedFile(null);
            setPhotoError('Incorrect file format. Please select .jpg, .jpeg or .png');
        }
    }, []);

    useEffect(() => {
        if (editPostId) {
            if (post.tags.length !== 0) {
                const selectedPostTags = post.tags.map((tag: IdNameTypes) => tag.id);
                setSelectedTags(selectedPostTags);
            } else {
                setSelectedTags([]);
            }
            if (post.photo.itemHash !== null) {
                setUploadedFile(
                    `${baseUrl}/api/storage/static-images/${post.photo.itemHash}${post.photo.itemExtension}`,
                );
            } else {
                setUploadedFile(null);
            }
            if (post.states.length) {
                setValue(
                    'states',
                    post.states.map(({ id }: { id: number }) => id.toString()),
                );
            }
        }
    }, [post, editPostId]);

    useEffect(() => {
        dispatch(getPostTags());
    }, []);

    useEffect(() => {
        if (isOpen && editPostId) {
            dispatch(getDetailsPostRequest(editPostId));
        }
    }, [isOpen, editPostId]);

    useEffect(() => {
        if (!isOpen) {
            setUploadedFile(null);
            setSelectedTags([]);
            setPhotoError('');
            reset({});
        }
    }, [isOpen]);

    const statesOption = currentStates.map((state: State) => ({
        value: state.stateId.toString(),
        label: state.state,
    }));

    return (
        <CustomModalWrapper isOpen={isOpen} title={title} size={size} onClose={onClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody h={loading ? '70vh' : '100%'}>
                    {loading ? (
                        <Loader spinnerSize={'md'} centerHeight={'100%'} />
                    ) : (
                        <HStack spacing={'55px'} align={'baseline'}>
                            {/*LEFT SIDE*/}
                            <Box w={'100%'}>
                                <Controller
                                    render={({ field, fieldState }) => (
                                        <MultiSelect
                                            label="States: *"
                                            options={statesOption}
                                            value={field.value}
                                            onChange={field.onChange}
                                            errors={fieldState.error && fieldState.error.message}
                                            width="350px"
                                        />
                                    )}
                                    defaultValue={[]}
                                    control={control}
                                    name="states"
                                />
                                <Box mb={'26px'}>
                                    <CustomInput
                                        register={register('title')}
                                        placeholder={'Write Post Title'}
                                        label={'Post title'}
                                        errors={errors.title}
                                        defaultValue={post.title || ''}
                                    />
                                </Box>
                                <InputTextArea
                                    {...register('body')}
                                    label={'Post Text'}
                                    maxLength={1000}
                                    errorMsg={errors.body?.message}
                                    defaultValue={post.text || ''}
                                />
                            </Box>
                            {/*RIGHT SIDE*/}
                            <Box w={'100%'}>
                                {/*TAGS CONTAINER*/}
                                <Text fontWeight={400} fontSize={'14px'} lineHeight={'21px'}>
                                    Tags
                                </Text>
                                <Box
                                    mb={'15px'}
                                    bgColor={colors.inputBackground}
                                    borderRadius={'24px'}
                                    p={'16px'}
                                >
                                    {postsLoading ? (
                                        <Loader centerHeight={'100%'} spinnerSize={'md'} />
                                    ) : (
                                        <Wrap spacing={'12px'}>
                                            {postTags.map((tag: IdNameTypes) => {
                                                const isSelected = selectedTags.includes(tag.id);
                                                return (
                                                    <WrapItem key={tag.id}>
                                                        <Tag
                                                            onClick={() => {
                                                                if (
                                                                    !selectedTags.includes(tag.id)
                                                                ) {
                                                                    setSelectedTags(prev => [
                                                                        ...prev,
                                                                        tag.id,
                                                                    ]);
                                                                } else {
                                                                    setSelectedTags(prev =>
                                                                        prev.filter(
                                                                            t => t !== tag.id,
                                                                        ),
                                                                    );
                                                                }
                                                            }}
                                                            type={'button'}
                                                            as={'button'}
                                                            bg={isSelected ? colors.brand : 'none'}
                                                            color={
                                                                isSelected
                                                                    ? colors.white
                                                                    : colors.secondaryFontDarker
                                                            }
                                                            fontSize={'14px'}
                                                            p={'12px 16px'}
                                                            variant={'outline'}
                                                            h={'40px'}
                                                            borderRadius={'60px'}
                                                        >
                                                            {tag.name}
                                                        </Tag>
                                                    </WrapItem>
                                                );
                                            })}
                                        </Wrap>
                                    )}
                                </Box>
                                {/* IMAGE UPLOAD*/}
                                <ImageUpload
                                    setUploadedFile={setUploadedFile}
                                    onDrop={onDrop}
                                    uploadedFile={uploadedFile}
                                />
                                {photoError && (
                                    <Text mt={'8px'} fontSize={'12px'} color={'#EC7474'}>
                                        {photoError}
                                    </Text>
                                )}
                                {/*Submit Post Button*/}
                                <Box mt={'30px'}>
                                    <CustomButton
                                        isLoading={updatePostLoading}
                                        bgColor="brand"
                                        height={'50px'}
                                        type={'submit'}
                                        text={'Edit'}
                                        borderRadius="4px"
                                        fontWeight={300}
                                    />
                                </Box>
                            </Box>
                        </HStack>
                    )}
                </ModalBody>
            </form>
        </CustomModalWrapper>
    );
};
