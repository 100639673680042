import React, { useEffect, useState } from 'react';
import { HStack, PinInput, PinInputField, useToast } from '@chakra-ui/react';
import * as yup from 'yup';

import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Flex,
    Button,
    CloseButton,
} from '@chakra-ui/react';
import axios from 'axios';
import { CustomButton, CustomInput, Loader } from '..';
import { useSelector } from 'react-redux';
import { dispatch, RootStore } from '../../../store';
import { useHistory } from 'react-router-dom';
import { colors } from '../../constants';
import { BackArrow } from '../../../assets/icons/BackArrow';
import { fonts } from '../../utils/fonts';
import { getUserInfoRequest } from '../../../store/actions/user.actions';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from '../../schemes/messages';
import Countdown from 'react-countdown';
import { ClockIcon, NameInputIcon } from '../../../assets/icons';

// code
type RequestCodeFormValues = {
  code: string
};
const RequestCodeSchema = yup.object().shape({
  code: yup.string()
});

// phone
type RequestFormValues = {
  phone_number: string;
};
const RequestSchema = yup.object().shape({
  phone_number: yup.string().required(ErrorMessages.REQUIRED),
});

export const ConfirmPhoneNumber: React.FC = (props) => {

      const {
        control,
        register: registerCode,
        formState: { errors: errorsCode },
        handleSubmit: handleSubmitCode,
    } = useForm<RequestCodeFormValues>({
        resolver: yupResolver(RequestCodeSchema),
    });

    const {
        getValues,
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<RequestFormValues>({
        resolver: yupResolver(RequestSchema),
    });

		const [showCode, setShowCode] = useState(false);
		const [loading, SetLoagin] = useState(false);
		const [isOpen, setIsOpen] = useState(false);
		
    const toast = useToast();
    const history = useHistory();
    const { user: currentUser } = useSelector((state: RootStore) => state.user);
    const [loader, setLoader] = useState<boolean>(false);
    const [LoaderBtn, setLoaderBtn] = useState<boolean>(false);

    const onClose = () => {
      setIsOpen(false);
    }

		const onSubmit = async (data: RequestFormValues) => {
      setLoaderBtn(true);
      await axios.post(window.baseUrl+'/api/profile/sendPhoneCodeCurrentUser', data)
        .then(() => {
          setLoaderBtn(false);
          setShowCode(true);
        }).catch((error: any) => {
          setLoaderBtn(false);
          let status = error.response.status;

          if(status === 422){
            toast({
              title: error.response.data.error,
              status: 'error',
              isClosable: true,
            });
          }
        });
    };

		const onSubmitCode = async (data: RequestCodeFormValues) => {
      setLoaderBtn(true);
      await axios.post(window.baseUrl+'/api/profile/checkPhoneCodeAndSave', {
          code: data.code,
          phone: getValues('phone_number'),
        })
        .then(() => {
          setLoaderBtn(false);
          setShowCode(false);
          setIsOpen(false);
          dispatch(getUserInfoRequest());

          toast({
            title: 'Your phone number has been verified successfully',
            status: 'success',
            isClosable: true,
          });

        }).catch((error: any) => {
          setLoaderBtn(false);
          let status = error.response.status;
          if(status === 422){
            toast({
              title: error.response.data.error,
              status: 'error',
              isClosable: true,
            });
          }
        });
    };

    useEffect(() => {
      if(currentUser && currentUser.id){
        if(!currentUser.firstName){
          setIsOpen(true);
        }
      }
    }, [currentUser]);

    return (
        <div className="wraper_cpn">
          <div className="slot_val" onClick={() => {
              setIsOpen(true);
            }}>
            { props.children }
          </div>

          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="3xl">
              <ModalOverlay />
              <ModalContent
                  className="modal_confirm_phone"
                  w={'600px'}
                  borderRadius={'18px'}
                  h={'auto'}
                  bgColor={colors.modalBackground}
                  alignItems="center"
                  paddingBottom="20px"
              >
                  <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      w="100%"
                      px="30px"
                      pt="10px"
                      background="#fff"
                      borderRadius="20px 20px 0px 0px"
                      >
                      {showCode && (
                        <Box as="button" onClick={() => {
                          setShowCode(false)
                          }}>
                          <BackArrow />
                        </Box>
                      )}
                      <ModalHeader flex={1} align="center">
                          <Text
                            fontSize="22px"
                            color="brand"
                            textAlign="center"
                            lineHeight="18px"
                            fontWeight={700}
                            fontFamily={fonts.montserrat}
                            >
                            Welcome to ZootPro
                          </Text>
                          <div className="desc_title">
                            Please provide your phone number
                          </div>
                      </ModalHeader>
                      <CloseButton onClick={onClose} />
                  </Flex>
                  <ModalBody w="100%" background="#fff" d="flex" flexDirection="column" overflowY="auto">
                  {showCode? (
                    <div>
                      <form onSubmit={handleSubmitCode(onSubmitCode)} className="form_fl">
                        <>
                          <Box
                            mb="20px"
                            pt="22px"
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            >
                            <Text color="brand" fontWeight={300} fontSize="14px" lineHeight="18px">
                                Enter Code We Sent To Your Phone
                            </Text>
                            <Text color="green" fontWeight={300} fontSize="14px" lineHeight="18px">
                              <Countdown
                                date={Date.now() + 5 * 60 * 1000}
                                renderer={(props: any) => {
                                  const timerValue = `${props.formatted.minutes}:${props.formatted.seconds}`;
                                  return <Text>{timerValue}</Text>;
                                }}
                                onComplete={() => {
                                  setShowCode(false);
                                }}
                              />
                            </Text>
                          </Box>
                          <Box mt="16px" mb="30px">
                            <Controller
                              control={control}
                              name="code"
                              render={({ field: { ref, ...restField } }) => (
                              <HStack
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                >
                                <PinInput placeholder="-" autoFocus={true} {...restField}>
                                  <PinInputField
                                      w="65px"
                                      h="65px"
                                      borderRadius="15px"
                                      ref={ref}
                                  />
                                  <PinInputField w="65px" h="65px" borderRadius="15px" />
                                  <PinInputField w="65px" h="65px" borderRadius="15px" />
                                  <PinInputField w="65px" h="65px" borderRadius="15px" />
                                </PinInput>
                              </HStack>
                              )}
                            />
                          </Box>
                        </>

                        <Button
                          isFullWidth
                          gridColumnStart="2"
                          variant={'primary'}
                          boxShadow="0px 3px 3px grey"
                          isLoading={LoaderBtn}
                          onClick={() => {
                            handleSubmitCode(onSubmitCode)();
                          }}
                          >
                          Submit
                        </Button>
                        <br/>
                      </form>
                    </div>
                    ):
                    (
                    <form onSubmit={handleSubmit(onSubmit)} className="form_fl">
                      <CustomInput
                        label="Phone Number"
                        type="tel"
                        width="100%"
                        mask="999-999-9999"
                        placeholder="Phone Number"
                        margin="0px 0 6px 0"
                        register={{ ...register('phone_number') }}
                        errors={errors.phone_number}
                        isBorder={false}
                        fontSize="14px"
                        textSize="16px"
                        height="50px"
                      />
                      <br />

                      <Button
                        isFullWidth
                        gridColumnStart="2"
                        variant={'primary'}
                        boxShadow="0px 3px 3px grey"
                        isLoading={LoaderBtn}
                        onClick={() => {
                          handleSubmit(onSubmit)();
                        }}
                        >
                        Submit
                      </Button>
                      <br/>
                    </form>
                    )}
                  </ModalBody>
              </ModalContent>
          </Modal>
        </div>
    );
};
