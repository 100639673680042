import React, { useEffect, useState } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { BackButton } from '../../../common/components';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../../store';
import { getAvailableRequests, setAvailableRequests } from '../store/actions';
import { TaskAvailabilityItem } from '../components';
import { useHistory } from 'react-router-dom';

const RequestTypes = [
    { value: 1, label: 'Showing' },
    { value: 2, label: 'Home Inspection' },
    { value: 3, label: 'Final Walk-Through' },
    { value: 5, label: 'Appraisal' },
    { value: 4, label: 'Other' },
];

export const TaskAvailability: React.FC = () => {
    const dispatch = useDispatch();

    const [selectedTypes, setSelected] = useState<number[]>([]);

    const loading = useSelector((state: RootStore) => state.settings.availableRequests.loading);
    const availableRequests = useSelector(
        (state: RootStore) => state.settings.availableRequests.requestTypes,
    );

    const onSetAvailableRequests = (requestTypes: number[]) =>
        dispatch(setAvailableRequests({ requestTypes }));

    useEffect(() => {
        dispatch(getAvailableRequests());
    }, []);

    useEffect(() => {
        setSelected(availableRequests);
    }, [availableRequests]);

    const onChangeAvailability = (item: { value: number; label: string }) => {
        const index = selectedTypes.findIndex(t => t === item.value);
        if (index > -1) {
            const filteredItems = selectedTypes.filter(t => t !== item.value);
            setSelected(filteredItems);
            onSetAvailableRequests(filteredItems);
        } else {
            setSelected((prev: Array<number>) => {
                const addedItems = [...prev, item.value];
                onSetAvailableRequests(addedItems);
                return addedItems;
            });
        }
    };

    const history = useHistory();

    return (
        <Box>
            <HStack mb="16px">
                <BackButton mb="0" text="" onClick={history.goBack} />
                <Text fontSize="24px" lineHeight="25px" color="brand" fontWeight="bold">
                    Task Availability
                </Text>
            </HStack>
            <Box bg="#F1F2F4" borderRadius="10px" p="35px 60px">
                <Text fontSize="18px" lineHeight="27px" color="brand" mb="40px">
                    Enter your availability for agent assistance requests
                </Text>
                {RequestTypes.map(elem => (
                    <TaskAvailabilityItem
                        key={elem.value}
                        {...elem}
                        isChecked={selectedTypes.some(t => t === elem.value)}
                        loading={loading}
                        onChange={onChangeAvailability}
                    />
                ))}
            </Box>
        </Box>
    );
};
