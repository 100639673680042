import { createSlice } from '@reduxjs/toolkit';
import { AdvertisingPostTypeResponse, PostDetailResponse, PostTypeResponse } from '../types';
import {
    getPostsError,
    getPostsRequest,
    getPostsSuccess,
    getAdvertisingPostsError,
    getAdvertisingPostsRequest,
    getAdvertisingPostsSuccess,
    saveTogglePostSuccess,
    saveTogglePostError,
    saveTogglePostRequest,
    createPostRequest,
    createPostSuccess,
    createPostError,
    deletePostRequest,
    deletePostSuccess,
    deletePostError,
    getDetailsPostRequest,
    getDetailsPostSuccess,
    getDetailsPostError,
    getAdvertisingDetailsPostRequest,
    getAdvertisingDetailsPostSuccess,
    getAdvertisingDetailsPostError,
    updatePostRequest,
    updatePostSuccess,
    updatePostError,
    addReportRequest,
    addReportSuccess,
    addReportError,
    addCommentToPostRequest,
    addCommentToPostSuccess,
    addCommentToPostError,
    deleteCommentRequest,
    deleteCommentSuccess,
    deleteCommentError,
    editCommentRequest,
    editCommentSuccess,
    editCommentError,
} from './actions';
import { PayloadError } from '../../../common/types';

type InitialState = {
    posts: {
        loading: boolean;
        errors: PayloadError[];
        items: PostTypeResponse[];
    };
    advertisingPosts: {
        loading: boolean;
        errors: PayloadError[];
        items: AdvertisingPostTypeResponse[];
    };
    savePost: {
        errors: PayloadError[];
    };
    createPost: {
        loading: boolean;
        errors: PayloadError[];
    };
    deletePost: {
        loading: boolean;
        errors: PayloadError[];
    };
    detailsPost: {
        loading: boolean;
        post: PostDetailResponse;
        errors: PayloadError[];
    };
    advertisingDetailsPost: {
        loading: boolean;
        post: AdvertisingPostTypeResponse;
        errors: PayloadError[];
    };
    updatePost: {
        loading: boolean;
        errors: PayloadError[];
    };
    addReport: {
        loading: boolean;
        errors: PayloadError[];
    };
    addComment: {
        loading: boolean;
        errors: PayloadError[];
    };
    editComment: {
        loading: boolean;
        errors: PayloadError[];
    };
    deleteComment: {
        loading: boolean;
        errors: PayloadError[];
    };
};
const initialState: InitialState = {
    posts: {
        loading: false,
        errors: [],
        items: [],
    },
    advertisingPosts: {
        loading: false,
        errors: [],
        items: [],
    },
    savePost: {
        errors: [],
    },
    createPost: {
        loading: false,
        errors: [],
    },
    deletePost: {
        loading: false,
        errors: [],
    },
    detailsPost: {
        errors: [],
        loading: false,
        post: {
            isSuspended: false,
            isSaved: false,
            comments: [],
            states: [],
            createdAt: '',
            createdBy: {
                avatar: {
                    id: 0,
                    itemHash: null,
                    itemExtension: null,
                },
                fullName: '',
                id: 0,
            },
            id: 0,
            isEdited: false,
            photo: {
                id: 0,
                itemExtension: null,
                itemHash: null,
            },
            text: '',
            title: '',
            tags: [],
        },
    },
    advertisingDetailsPost: {
        errors: [],
        loading: false,
        post: {
            agencies: [],
            body: null,
            id: 0,
            createdAt: '',
            daysLeft: 0,
            link: '',
            photo: {
                id: 0,
                itemExtension: null,
                itemHash: null,
            },
            states: [],
            status: '',
            tags: [],
            title: '',
        },
    },
    updatePost: {
        loading: false,
        errors: [],
    },
    addReport: {
        loading: false,
        errors: [],
    },
    addComment: {
        loading: false,
        errors: [],
    },
    editComment: {
        loading: false,
        errors: [],
    },
    deleteComment: {
        loading: false,
        errors: [],
    },
};

const postsSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getPostsRequest, state => {
                state.posts.loading = true;
                state.posts.errors = [];
            })
            .addCase(getPostsSuccess, (state, action) => {
                state.posts.loading = false;
                state.posts.items = action.payload.posts;
            })
            .addCase(getPostsError, (state, action) => {
                state.posts.loading = false;
                state.posts.errors = action.payload;
            })
            .addCase(getAdvertisingPostsRequest, state => {
                state.advertisingPosts.loading = true;
                state.advertisingPosts.errors = [];
            })
            .addCase(getAdvertisingPostsSuccess, (state, action) => {
                state.advertisingPosts.loading = false;
                state.advertisingPosts.items = action.payload.posts;
            })
            .addCase(getAdvertisingPostsError, (state, action) => {
                state.advertisingPosts.loading = false;
                state.advertisingPosts.errors = action.payload;
            })
            .addCase(saveTogglePostRequest, state => {
                state.savePost.errors = [];
            })
            .addCase(saveTogglePostSuccess, (state, action) => {
                state.detailsPost.post =
                    state.detailsPost.post.id === action.payload.PostId
                        ? {
                              ...state.detailsPost.post,
                              isSaved: action.payload.isSavedPost,
                          }
                        : {
                              ...state.detailsPost.post,
                          };

                state.posts.items = state.posts.items.map(post => {
                    return post.id === action.payload.PostId
                        ? {
                              ...post,
                              isSaved: action.payload.isSavedPost,
                              savedCount: action.payload.isSavedPost
                                  ? post.savedCount + 1
                                  : post.savedCount - 1,
                          }
                        : post;
                });
            })
            .addCase(saveTogglePostError, (state, action) => {
                state.savePost.errors = action.payload;
            })
            //    Create post
            .addCase(createPostRequest, state => {
                state.createPost.loading = true;
                state.createPost.errors = [];
            })
            .addCase(createPostSuccess, state => {
                state.createPost.loading = false;
            })
            .addCase(createPostError, (state, action) => {
                state.createPost.loading = false;
                state.createPost.errors = action.payload;
            })
            //    Delete Post
            .addCase(deletePostRequest, state => {
                state.deletePost.loading = true;
                state.deletePost.errors = [];
            })
            .addCase(deletePostSuccess, (state, action) => {
                state.deletePost.loading = false;
            })
            .addCase(deletePostError, (state, action) => {
                state.deletePost.loading = false;
                state.deletePost.errors = action.payload;
            })
            //    Get Details Post
            .addCase(getDetailsPostRequest, state => {
                state.detailsPost.loading = true;
                state.detailsPost.errors = [];
            })
            .addCase(getDetailsPostSuccess, (state, action) => {
                state.detailsPost.loading = false;
                state.detailsPost.post = {
                    ...action.payload.post,
                };
            })
            .addCase(getDetailsPostError, (state, action) => {
                state.detailsPost.loading = false;
                state.detailsPost.errors = action.payload;
            })
            //    Get Advertising Details Post
            .addCase(getAdvertisingDetailsPostRequest, state => {
                state.advertisingDetailsPost.loading = true;
                state.advertisingDetailsPost.errors = [];
            })
            .addCase(getAdvertisingDetailsPostSuccess, (state, action) => {
                state.advertisingDetailsPost.loading = false;
                state.advertisingDetailsPost.post = {
                    ...action.payload.post,
                };
            })
            .addCase(getAdvertisingDetailsPostError, (state, action) => {
                state.advertisingDetailsPost.loading = false;
                state.advertisingDetailsPost.errors = action.payload;
            })
            //    Update Post
            .addCase(updatePostRequest, state => {
                state.updatePost.loading = true;
                state.updatePost.errors = [];
            })
            .addCase(updatePostSuccess, (state, action) => {
                state.updatePost.loading = false;
            })
            .addCase(updatePostError, (state, action) => {
                state.updatePost.loading = false;
                state.updatePost.errors = action.payload;
            })
            //    Add Report Post
            .addCase(addReportRequest, state => {
                state.addReport.loading = true;
                state.addReport.errors = [];
            })
            .addCase(addReportSuccess, (state, action) => {
                state.addReport.loading = false;
            })
            .addCase(addReportError, (state, action) => {
                state.addReport.loading = false;
                state.addReport.errors = action.payload;
            }) //    Add Comment
            .addCase(addCommentToPostRequest, state => {
                state.addComment.loading = true;
                state.addComment.errors = [];
            })
            .addCase(addCommentToPostSuccess, (state, action) => {
                state.addComment.loading = false;
                state.detailsPost.post.comments = [
                    ...state.detailsPost.post.comments,
                    action.payload.comment,
                ];
                state.posts.items = state.posts.items.map(post => {
                    return post.id === action.payload.postId
                        ? {
                              ...post,
                              commentsCount: post.commentsCount + 1,
                          }
                        : post;
                });
            })
            .addCase(addCommentToPostError, (state, action) => {
                state.addComment.loading = false;
                state.addComment.errors = action.payload;
            })
            // Edit Comment
            .addCase(editCommentRequest, state => {
                state.editComment.loading = true;
                state.editComment.errors = [];
            })
            .addCase(editCommentSuccess, (state, action) => {
                state.editComment.loading = false;
                state.detailsPost.post.comments = state.detailsPost.post.comments.map(comment => {
                    return comment.id === action.payload.commentId
                        ? {
                              ...comment,
                              text: action.payload.text,
                              isEdited: true,
                          }
                        : comment;
                });
            })
            .addCase(editCommentError, (state, action) => {
                state.editComment.loading = false;
                state.editComment.errors = action.payload;
            })
            // Delete Comment
            .addCase(deleteCommentRequest, state => {
                state.deleteComment.loading = true;
                state.deleteComment.errors = [];
            })
            .addCase(deleteCommentSuccess, (state, action) => {
                state.deleteComment.loading = false;
                state.detailsPost.post.comments = state.detailsPost.post.comments.filter(
                    comment => comment.id !== action.payload.commentId,
                );
                state.posts.items = state.posts.items.map(post => {
                    return post.id === action.payload.postId
                        ? {
                              ...post,
                              commentsCount: post.commentsCount - 1,
                          }
                        : post;
                });
            })
            .addCase(deleteCommentError, (state, action) => {
                state.deleteComment.loading = false;
                state.deleteComment.errors = action.payload;
            });
    },
});
export default postsSlice.reducer;
