import React from 'react';
import { Box, Text, useMediaQuery, Button, Link } from '@chakra-ui/react';
import { colors, pricingFeaturnes } from '../../../constants';
import { fonts } from '../../../utils';
import { FeatureItem } from './components/FeatureItem';

interface IPricingPlanBlock {
    period: 'Brokerage Office Plan' | 'Annual' | 'Monthly';
    color: string;
    periodText?: string;
}

export const PricingPlanBlock: React.FC<IPricingPlanBlock> = ({ period, color, periodText }) => {
    const [isLargerThan600] = useMediaQuery('(min-width: 600px');

    return (
        <Box
            w="100%"
            borderRadius="26px"
            p="20px"
            style={{ filter: 'drop-shadow(7px 0px 30px rgba(44, 51, 92, 0.3))' }}
            bg={'#fff'}
            fontFamily={fonts.montserrat}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                height={isLargerThan600 ? '50px' : 'auto'}
                mb="15px"
            >
                <Box
                    fontWeight="semibold"
                    fontSize={isLargerThan600 ? '22px' : '18px'}
                    color={period === 'Annual' ? colors.brand : colors.black}
                >
                    {periodText}
                </Box>
                {period !== 'Brokerage Office Plan' && (
                    <Box
                        fontWeight="semibold"
                        fontSize={isLargerThan600 ? '16px' : '12px'}
                        color="#EE4B2B"
                    >
                        15-Day Free trial
                    </Box>
                )}
            </Box>
            <Box mb="30px" position="relative">
                {period === 'Annual' && (
                    <>
                        
                        <Text
                            color={colors.black}
                            fontWeight="bold"
                            fontSize={isLargerThan600 ? '36px' : '30px'}
                            d="inline-block"
                        >
                            $4.17
                        </Text>
                        <Text d="inline-block" color={colors.black} fontSize="17px" ml="10px">
                            month
                        </Text>
                        <Text
                            color={colors.black}
                            fontSize={isLargerThan600 ? '16px' : '12px'}
                            fontWeight={700}
                            position="absolute"
                            bottom="-20px"
                        >
                            Billed annually at $49.99
                        </Text>
                    </>
                )}
                {period === 'Monthly' && (
                    <>
                        <Text
                            color={colors.black}
                            fontWeight="bold"
                            fontSize="36px"
                            d="inline-block"
                        >
                            $5.99
                        </Text>
                        <Text d="inline-block" color={colors.black} fontSize="17px" ml="10px">
                            month
                        </Text>
                    </>
                )}
                {period === 'Brokerage Office Plan' && (
                    <>
                        <Text
                            color={colors.black}
                            fontWeight="bold"
                            fontSize="36px"
                            d="inline-block"
                        >
                            Premier
                        </Text>
                        <Text
                            color={colors.black}
                            fontSize={isLargerThan600 ? '17px' : '12px'}
                            fontWeight={400}
                            position="absolute"
                            bottom="-15px"
                        >
                            Annual Group License
                        </Text>
                    </>
                )}
            </Box>
            <Box w={isLargerThan600 ? '80%' : '100%'}>
                <Button
                    d="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="26px"
                    color={colors.white}
                    fontSize={isLargerThan600 ? '20px' : '17px'}
                    fontWeight={700}
                    height={{ base: '65px', md: '75px' }}
                    bg={color}
                    _hover={{
                        textDecoration: 'none',
                    }}
                    as={Link}
                    href={
                        period === 'Brokerage Office Plan'
                            ? '/contact'
                            : '/auth/signup/createaccount'
                    }
                >
                    {period === 'Brokerage Office Plan' ? 'Contact Sales' : 'Start Trial'}
                </Button>
                <Box
                    d={'flex'}
                    mt="8px"
                    visibility={period === 'Brokerage Office Plan' ? 'hidden' : 'visible'}
                    justifyContent="center"
                    alignItems="center"
                    fontSize="13px"
                    borderRadius="26px"
                    color={colors.black}
                >
                    No Credit Card Required
                </Box>
            </Box>
            <Box marginTop="auto">
                <Box
                    fontWeight="semibold"
                    fontSize={isLargerThan600 ? '15px' : '13px'}
                    color={colors.black}
                    mt="30px"
                >
                    Access to these features and more:
                </Box>
                {pricingFeaturnes.map((item: string, index: number) => (
                    <FeatureItem key={index} title={item} color={colors.black} bgColor={color} />
                ))}
            </Box>
        </Box>
    );
};
