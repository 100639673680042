import { createSlice } from '@reduxjs/toolkit';
import {
    login,
    loginError,
    loginSuccess,
    logout,
    refreshToken,
    refreshTokenSuccess,
    removeAuthLoading,
} from '../../actions/auth/auth.actions';
import { PayloadError, LoginResponseType } from '../../../common/types';
import { IUserModel } from '../../../common/models';

export const initialState: {
    errors: PayloadError[];
    user: LoginResponseType;
    loading: boolean;
    loaded: boolean;
} = {
    errors: [],
    user: {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        roles: [],
        phoneNumber: null,
        isPhoneNumberConfirmed: false,
        token: {
            accessToken: '',
            accessTokenExpiration: '',
            refreshToken: '',
        },
        avatar: {},
        isIdLicensePhotoProvided: false,
        isSuspended: false,
        balance: 0,
    },
    loading: false,
    loaded: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(login, state => {
                state.loading = true;
                state.loaded = false;
                state.errors = [];
            })
            .addCase(loginSuccess, (state, action) => {
                state.user = action.payload.value;
                state.loading = false;
                state.loaded = true;
                state.errors = [];
            })
            .addCase(loginError, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            })
            .addCase(refreshToken, state => {
                state.loading = true;
                state.loaded = false;
                state.errors = [];
            })
            .addCase(refreshTokenSuccess, (state, action) => {
                state.user.token = action.payload.value;
                state.loading = false;
                state.loaded = true;
                state.errors = [];
            })
            .addCase(removeAuthLoading, state => {
                state.loading = false;
            })
            .addCase(logout, state => {
                state.user = initialState.user;
            });
    },
});

export default authSlice.reducer;
