import React, { useState } from 'react';
import * as yup from 'yup';

import {
    Box,
    Flex,
    IconButton,
    Modal,
    ModalContent,
    ModalOverlay,
    Text,
    Image,
    CloseButton,
    Button,
    useToast,
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { ErrorMessages } from '../../common/schemes/messages';
import { CustomInput, CustomTextarea } from '../../common/components';
import { fonts } from '../../common/utils';
import { colors } from '../../common/constants';

interface IProp {
    isOpen: boolean;
    onClose: () => void;
}

type FormProps = {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
};

const FormSchema = yup.object().shape({
    first_name: yup.string().required(ErrorMessages.REQUIRED),
    last_name: yup.string().required(ErrorMessages.REQUIRED),
    email: yup.string().required(ErrorMessages.REQUIRED),
    phone: yup.string().required(ErrorMessages.REQUIRED),
});

export const ModalDemo: React.FC<IProp> = ({ isOpen, onClose, }) => {
    
    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
        control,
        clearErrors,
        watch,
        reset,
        trigger,
    } = useForm<FormProps>({
        resolver: yupResolver(FormSchema),
    });


    const [loader, setLoader] = useState<boolean>(false);
    const toast = useToast();

    const onSubmit = async (data: FormProps) => {
        setLoader(true);

        await axios.post(window.baseUrl+'/api/siteDemoForm/send', data)
            .then(() => {
                setLoader(false);
                reset();
                toast({
                    title: 'Form submitted successfully',
                    status: 'info',
                    isClosable: true,
                });
            })
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent
                    overflow={'hidden'}
                    borderRadius="18px"
                    bgColor={colors.modalBackground}
                    alignItems="center"
                >
                    <Text
                        color={colors.brand}
                        fontFamily={fonts.montserrat}
                        fontWeight={700}
                        fontSize="22px"
                        lineHeight="18px"
                        textAlign="center"
                        fontStyle="normal"
                        mt="60px"
                        mb="40px"
                    >
                        New demo request
                    </Text>
                    <IconButton
                        onClick={onClose}
                        aria-label={'close modal'}
                        icon={<CloseButton />}
                        variant="ghost"
                        position="absolute"
                        top="30px"
                        right="55px"
                    />
                    <Box mb="50px" width="100%" padding="0px 40px 0px 40px">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <CustomInput
                                label="First Name: *"
                                placeholder=""
                                width="100%"
                                register={{ ...register('first_name') }}
                                errors={errors.first_name}
                            />
                            <br />
                            <CustomInput
                                label="Last Name: *"
                                placeholder=""
                                width="100%"
                                register={{ ...register('last_name') }}
                                errors={errors.last_name}
                            />
                            <br />
                            <CustomInput
                                label="Email: *"
                                placeholder=""
                                width="100%"
                                register={{ ...register('email') }}
                                errors={errors.email}
                            />
                            <br />
                            <CustomInput
                                label="Phone Number: *"
                                placeholder=""
                                width="100%"
                                register={{ ...register('phone') }}
                                errors={errors.phone}
                            />
                            <br />
                            
                            <Button
                                isFullWidth
                                gridColumnStart="2"
                                variant={'primary'}
                                boxShadow="0px 3px 3px grey"
                                isLoading={loader}
                                onClick={() => {
                                    handleSubmit(onSubmit)();
                                }}
                                >
                                Send
                            </Button>
                        </form>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    );
};
