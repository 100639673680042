import { Flex } from '@chakra-ui/react';
import React from 'react';

interface IActivetyItem {
    elem: any
}

export const StatusActivityItem: React.FC<IActivetyItem> = ({elem}) => 
{
	return (
		<Flex
			justifyContent="flex-start"
			>
			{elem.isWorkFinished && (
				<div className="status_pay" style={{color: 'green', fontSize: '12px', marginRight: '10px'}}>
					FINISED
				</div>
			)}
			{!elem.isWorkFinished && elem.isAccepted &&(
				<div className="status_pay" style={{color: '#ff0000', fontSize: '12px', marginRight: '10px'}}>
					NOT FINISED
				</div>
			)}
			{!elem.isWorkConfirmed && elem.isAccepted && (
				<div className="status_pay" style={{color: '#ff0000', fontSize: '12px', marginRight: '10px'}}>
					NOT PAID
				</div>
			)}
			{elem.isWorkConfirmed && (
				<div className="status_pay" style={{color: 'green', fontSize: '12px', marginRight: '10px'}}>
					PAID!
				</div>
			)}
			{elem.isChecked && (
				<div className="status_pay" style={{color: 'orange', fontSize: '12px', marginRight: '10px'}}>
					SUBMITTED
				</div>
			)}
		</Flex>
	);
};

