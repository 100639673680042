import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';

import { Flex, Text, HStack, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';
import { TabsSwitcher } from '../../../common/components/blocks/TabsSwitcher';
import { BackButton } from '../../../common/components';
import { MyListingsTab } from '../../Listings/tabs';
import {
    AddTimeSlotModal,
    ListingDetailsModal,
    OpenhouseDetailsModal,
} from '../../Listings/modals';

import {
    OpenHouseDetailsOpenConfig,
    ListingsType,
    TimeSlotType,
    myClientFromServerType,
} from '../../../common/types';

import { RootStore } from '../../../store';
import {
    getListingTypesRequest,
    getMyListings,
    getMyClients,
    getOpenHouseInfo,
} from '../../../store/actions/listings.actions';
import {
    getOpenHouseAudiences,
    getOpenHouseHostTypes,
    getOpenHouseTypes,
} from '../../../store/actions/directories.actions';
import { useDelta } from 'react-delta';
import { setConnectPaymentModalOpen } from '../../Notifications/store/actions';
import PeopleCardsContainer from '../../Listings/tabs/PeopleCardsContainer';
import { defaultOpenHouseDetailsOpenConfig } from '../MyStuff';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { FreshListings } from '../../../common/components/aglobal/FreshListings';

const tabsOptions = [
    {
        index: 0,
        label: 'My Listings',
    },
    {
        index: 1,
        label: 'My Clients',
    },
];

export const MyStuffContent: React.FC = () => {
    const {tab} = useParams<{tab:string}>();

    const [toShowListingId, setToShowListingId] = useState(0);
    const [showAddTimeSlotModal, setShowAddTimeSlotModal] = useState(false);
    const [timeSlotModalCompletedFieldsData, setTimeSlotModalCompletedFieldsData] = useState<
        TimeSlotType | undefined
    >(undefined);
    const [showOpenhouseInfoModal, setShowOpenhouseInfoModal] =
        useState<OpenHouseDetailsOpenConfig>(defaultOpenHouseDetailsOpenConfig);

    const [showListingInfoModal, setShowListingInfoModal] = useState(false);
    const [tabIndex, setTabIndex] = useState(_.toNumber(tab)? _.toNumber(tab) : 0);

    const [myClientsPageNumber, setMyClientsPageNumber] = useState(1);
    const [onEndReachedMyClientsCalled, setOnEndReachedMyClientsCalled] = useState(false);

    const { search, pathname } = useLocation();
    const { push } = useHistory();

    const { loading: addSlotLoading, errors: addSlotErrors } = useSelector(
        (state: RootStore) => state.listings.addTimeSlot,
    );

    const { loading: updateSlotLoading, errors: updateSlotErrors } = useSelector(
        (state: RootStore) => state.listings.updateTimeSlot,
    );

    const { openHouseTypes, openHouseAudiences, openHouseHostTypes, openHouseTypesLoading } =
        useSelector((state: RootStore) => state.directories);

    const roles = useSelector((state: RootStore) => state.auth.user.roles);

    const isAccessAllowed = roles?.some((role: string) => (role === 'user' || role === 'newuser'));

    const addSlotLoadingDelta = useDelta(addSlotLoading);
    const updateSlotLoadingDelta = useDelta(updateSlotLoading);

    const dispatch = useDispatch();
    const onGetOpenHouseTypes = () => dispatch(getOpenHouseTypes());
    const onGetOpenHouseHostTypes = () => dispatch(getOpenHouseHostTypes());
    const onGetOpenHouseAudiences = () => dispatch(getOpenHouseAudiences());
    const onGetListingTypes = () => dispatch(getListingTypesRequest());
    const onOpenListingsModal = () => {
        const { id } = qs.parse(search, { ignoreQueryPrefix: true });

        if (id) {
            setShowListingInfoModal(true);
            setToShowListingId(Number(id));
        }
    };

    useEffect(() => {
        if (tabIndex === 0) {
            dispatch(getMyListings());
        } else {
            dispatch(
                getMyClients({
                    pageNumber: myClientsPageNumber,
                    pageSize: 20,
                }),
            );
        }
    }, [tabIndex, myClientsPageNumber]);

    useEffect(() => {
        onGetListingTypes();
        onGetOpenHouseTypes();
        onGetOpenHouseHostTypes();
        onGetOpenHouseAudiences();
        onOpenListingsModal();
    }, []);

    useEffect(() => {
        setShowAddTimeSlotModal(false);
        dispatch(getMyListings());
        dispatch(getOpenHouseInfo(toShowListingId));

        /*
        if (addSlotLoadingDelta && addSlotLoadingDelta.prev) {
            if (addSlotLoadingDelta.prev && !addSlotLoadingDelta.curr) {
                setShowAddTimeSlotModal(false);
                dispatch(getMyListings());
                dispatch(getOpenHouseInfo(toShowListingId));
            }
        }

        if (updateSlotLoadingDelta && updateSlotLoadingDelta.prev) {
            if (
                updateSlotLoadingDelta.prev &&
                !updateSlotLoadingDelta.curr &&
                !updateSlotErrors.length
            ) {
                setShowAddTimeSlotModal(false);
                dispatch(getMyListings());
                dispatch(getOpenHouseInfo(toShowListingId));
            }
        }
        */
    }, [addSlotLoadingDelta, updateSlotLoadingDelta]);

    const onOpenModal = (
        value: number,
        listing: ListingsType,
        isMyListings: boolean,
        isManageOpenHouse: boolean,
    ) => {
        setToShowListingId(value);
        push(`${pathname}?id=${value}`);

        if (listing.hasTimeSlots) {
            setShowOpenhouseInfoModal({
                isOpen: true,
                listingStatusId: listing.listingStatusId || 0,
                isMyListings,
                isManageOpenHouse,
            });
        } else {
            setShowListingInfoModal(true);
        }
    };

    const onScheduleOpenHouse = (value: number) => {
        if (!isAccessAllowed) {
            // dispatch(setConnectPaymentModalOpen({ isOpen: true }));
            return;
        }
        setToShowListingId(value);
        setShowAddTimeSlotModal(true);
    };

    const {
        loading: myListingsLoading,
        active: activeListings,
        pending: pendingListings,
        closed: closedListings,
    } = useSelector((state: RootStore) => state.listings.myListings);

    const {
        loading: myClientsLoading,
        items: myClients,
        loadMoreLoading: myClientsMoreLoading,
        totalCount: myClientsTotalCount,
    } = useSelector((state: RootStore) => state.listings.myClients);

    const myClientsTransformed = myClients.map(
        ({ clientName, typeName, clientPhone }: myClientFromServerType) => ({
            firstName: clientName,
            agencyName: typeName,
            phoneNumber: clientPhone,
        }),
    );

    const loadMoreMyClients = () => {
        if (myClients.length >= myClientsTotalCount) {
            setOnEndReachedMyClientsCalled(true);
        } else if (!onEndReachedMyClientsCalled) {
            setMyClientsPageNumber((prev: number) => prev + 1);
        }
    };

    const history = useHistory();
    
    useEffect(() => {
        if(tab){
            setTabIndex(_.toNumber(tab));
        }
    }, [tab]);

    useEffect(() => {
        if(_.toNumber(tab) == _.toNumber(tabIndex)){
            return;
        }
        history.push('/my-stuff/'+tabIndex);
    }, [
        tabIndex,
    ]);

    return (
        <>
            <HStack my="16px">
                <Flex justifyContent="space-between" width="100%">
                    <Flex alignItems="center">
                        <BackButton mb="0" text="" />
                        <Text fontSize="24px" lineHeight="25px" color="brand" fontWeight="bold">
                            My Stuff
                        </Text>
                    </Flex>
                    <div className='1111'>
                        <FreshListings />
                    </div>
                </Flex>
            </HStack>
            <Tabs variant="unstyled" index={tabIndex}>
                <Flex justifyContent="space-between">
                    <TabsSwitcher
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                        tabsOptions={tabsOptions}
                    />
                </Flex>
                <TabPanels>
                    <TabPanel p="0" h="100%" pt="10px">
                        <MyListingsTab
                            loading={myListingsLoading}
                            activeListings={activeListings}
                            closedListings={closedListings}
                            pendingListings={pendingListings}
                            onOpenModal={onOpenModal}
                            onScheduleOpenHouse={onScheduleOpenHouse}
                        />
                    </TabPanel>
                    <TabPanel p="0" h="100%" pt="10px">
                        <PeopleCardsContainer
                            items={myClientsTransformed}
                            loading={myClientsLoading}
                            loadMoreLoading={myClientsMoreLoading}
                            loadMoreItems={loadMoreMyClients}
                            withoutAvatar
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <OpenhouseDetailsModal
                showOpenhouseInfoModal={showOpenhouseInfoModal}
                onClose={() => {
                    setShowOpenhouseInfoModal(defaultOpenHouseDetailsOpenConfig);
                    setToShowListingId(0);
                }}
                listingId={toShowListingId}
                setShowAddTimeSlotModal={setShowAddTimeSlotModal}
                getItems={() => dispatch(getMyListings())}
                createTimeSlotModalCompletedFieldsData={(data: TimeSlotType) => {
                    setTimeSlotModalCompletedFieldsData(data);
                }}
            />
            <AddTimeSlotModal
                isOpen={showAddTimeSlotModal}
                onClose={() => {
                    setShowAddTimeSlotModal(false);
                    setTimeSlotModalCompletedFieldsData(undefined);
                }}
                listingId={toShowListingId}
                loading={addSlotLoading}
                openHouseTypesLoading={openHouseTypesLoading}
                openHouseHostTypes={openHouseHostTypes}
                openHouseAudiences={openHouseAudiences}
                openHouseTypes={openHouseTypes}
                completedFieldsData={timeSlotModalCompletedFieldsData}
            />
            <ListingDetailsModal
                isOpen={showListingInfoModal}
                onClose={() => {
                    setShowListingInfoModal(false);
                    setToShowListingId(0);
                    push(pathname);
                }}
                listingId={toShowListingId}
            />
        </>
    );
};
