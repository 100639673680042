import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { useDelta } from 'react-delta';
import { Box, Flex, TabPanel, TabPanels, Tabs, Text, HStack, CloseButton } from '@chakra-ui/react';
import { BackArrow } from '../../../../assets/icons';
import { useHistory } from 'react-router-dom';
import { FormWithdraw } from './FormWithdraw';

export const WithdrawContainer: React.FC = () => {
    const history = useHistory();

    return (
        <>
            <Box h={'85vh'} maxWidth="500px" >
                <Flex justify="space-between" align="center" mb="40px" width="100%">
                    <HStack spacing="50px">
                        <Box onClick={history.goBack} as="button">
                            <BackArrow />
                        </Box>
                        <Text fontSize="22px" fontWeight="700" color="brand">
                            Withdraw Requests
                        </Text>
                    </HStack>
                    <Box onClick={history.goBack} as="button">
                        
                    </Box>
                </Flex>
                <FormWithdraw />
            </Box>
        </>
    );
};
