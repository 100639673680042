import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Container, Flex, HStack, Spinner, Text } from '@chakra-ui/react';

import { BackButton } from '../../../../common/components';
import { NotificationsSettingsItem } from './components/NotificationSettingsItem';

import { fonts } from '../../../../common/utils';
import { RootStore } from '../../../../store';
import {
    getNotificationSettingsRequest,
    setNotificationSettingsRequest,
} from '../../store/actions';
import { useHistory } from 'react-router-dom';

export const NotificationsSettings: React.FC = () => {
    const dispatch = useDispatch();
    const [selectedCases, setSelectedCases] = useState<number[]>([]);
    const { cases, loading } = useSelector((state: RootStore) => state.settings.availableCases);

    const onSetAvailableRequests = (cases: number[]) =>
        dispatch(setNotificationSettingsRequest({ cases }));

    useEffect(() => {
        dispatch(getNotificationSettingsRequest());
    }, []);

    useEffect(() => {
        setSelectedCases(cases);
    }, [cases]);

    const notificationSettings = [
        {
            title: 'Opportunity Notifications',
            settings: [
                { value: 1, label: 'Text messages' },
                { value: 2, label: 'Push messages' },
                { value: 12, label: 'Email messages' },
            ],
        },
        {
            title: 'Assistance Notifications',
            settings: [
                { value: 4, label: 'Text messages' },
                { value: 5, label: 'Push messages' },
                { value: 13, label: 'Email messages' },
            ],
        },
        {
            title: 'Chat Notifications',
            settings: [{ value: 3, label: 'Push messages' }],
        },
        // {
        //     title: 'Off Market Needs Notifications',
        //     settings: [{ value: 11, label: 'Push messages' }],
        // },
        {
            title: 'Account Notifications',
            settings: [
                { value: 8, label: 'Text messages' },
                { value: 9, label: 'Push messages' },
                { value: 10, label: 'Email messages' },
            ],
        },
        {
            title: 'Listings & Open House Notifications',
            settings: [
                { value: 6, label: 'Text messages' },
                { value: 7, label: 'Push messages' },
                { value: 14, label: 'Email messages' },
            ],
        },
        {
            title: 'Blasts Notifications',
            settings: [{ value: 15, label: 'Email messages' }],
        },
    ];

    const onChangeAvailability = (item: { value: number; label: string }) => {
        const index = selectedCases.findIndex(t => t === item.value);
        if (index > -1) {
            const filteredItems = selectedCases.filter(t => t !== item.value);
            setSelectedCases(filteredItems);
            onSetAvailableRequests(filteredItems);
        } else {
            const addedItems = [...selectedCases, item.value];
            onSetAvailableRequests(addedItems);
            setSelectedCases(addedItems);
        }
    };

    const history = useHistory();

    return (
        <Container maxW="container.lg">
            <HStack mb="16px" pt="30px">
                <BackButton mb="0" text="" onClick={history.goBack} />
                <Text
                    fontSize="22px"
                    lineHeight="18px"
                    color="brand"
                    fontWeight="bold"
                    fontFamily={fonts.montserrat}
                >
                    Notifications
                </Text>
            </HStack>
            <Flex
                flexWrap="wrap"
                mb="30px"
                minH="660px"
                justifyContent="center"
                alignItems="center"
            >
                {loading ? (
                    <Spinner size="xl" />
                ) : (
                    notificationSettings.map(elem => {
                        return (
                            <Flex key={elem.title} flexDirection="column" flexBasis="50%" pb="15px">
                                <Box
                                    bg="#F6F7FB"
                                    borderRadius="10px"
                                    p="25px 40px 0px"
                                    mr="15px"
                                    height="205px"
                                >
                                    <Text
                                        fontSize="18px"
                                        lineHeight="27px"
                                        color="brand"
                                        mb="20px"
                                        fontWeight="600"
                                        fontFamily={fonts.poppins}
                                    >
                                        {elem.title}
                                    </Text>
                                    {elem.settings.map(el => (
                                        <NotificationsSettingsItem
                                            key={el.value}
                                            {...el}
                                            loading={loading}
                                            onChange={onChangeAvailability}
                                            isChecked={!selectedCases.some(t => t === el.value)}
                                        />
                                    ))}
                                </Box>
                            </Flex>
                        );
                    })
                )}
            </Flex>
        </Container>
    );
};
