import React from 'react';
import { Badge } from '@chakra-ui/react';

import { useBadgeOptions } from './hooks/useBadgeOptions';
import { ExpirationStatus } from '../../../../../../common/types/license.types';

interface LicenseExpiryLableProps {
    expiresIn: number;
    status: ExpirationStatus;
}

export const LicenseExpiryLable: React.FC<LicenseExpiryLableProps> = ({ status, expiresIn }) => {
    const { title, variant } = useBadgeOptions(status, expiresIn);

    return <Badge variant={variant}>{title}</Badge>;
};
