import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, Divider, Center, HStack, SimpleGrid } from '@chakra-ui/react';
import { ApplicantItem } from '../components';
import { InfiniteScroll, Loader } from '../../../../../common/components';
import { ApplicantType } from '../../../types';
import { useDispatch } from 'react-redux';
import { confirmApplicant, rejectApplicantRequest } from '../../../store/actions';
import {
    ConfirmApplicantModal,
    ConfirmRefferalModal,
} from '../../../../../common/components/modals';
import { ApplicantToSubmitType } from '../../../types';
import { roundAplicantPrices } from '../../../../../common/utils';
import { GetDashboardRequestPayload } from '../../../../../common/types/dashboard.types';

interface IApplicantsProps {
    items: ApplicantType[];
    partnerApplicants: ApplicantType[];
    loading: boolean;
    requestId: number;
    isRefferal?: boolean;
    referralFee?: number;
    onClose: () => void;
    dashboardRequestPayload?: GetDashboardRequestPayload;
}

export const Applicants: React.FC<IApplicantsProps> = ({
    items,
    loading,
    isRefferal = false,
    referralFee = 0,
    partnerApplicants,
    requestId,
    onClose,
    dashboardRequestPayload,
}) => {
    const dispatch = useDispatch();

    const [confirmApplicationConfig, setConfirmApplicationConfig] =
        useState<null | ApplicantToSubmitType>(null);
    const [confirmApplicationModalOpen, setConfirmApplicationModalOpen] = useState(false);
    const [confirmRefferalModalOpen, setconfirmRefferalModalOpe] = useState(false);
    const onConfirmApplicant = (applicantId: number) => {
        dispatch(
            confirmApplicant({
                applicantId,
                requestId,
                dashboardRequestPayload,
            }),
        );
        onClose();
        //location.reload();
    };

    const onRejectApplicant = (applicantId: number) => {
        dispatch(rejectApplicantRequest({ applicantId, requestId, dashboardRequestPayload }));
        onClose();
    };

    const handleConfirmApplicantModalClose = () => {
        setConfirmApplicationModalOpen(false);
        setConfirmApplicationConfig(null);
    };

    const handleConfirmRefferalModalClose = () => {
        setconfirmRefferalModalOpe(false);
        setConfirmApplicationConfig(null);
    };

    useEffect(() => {
        if (confirmApplicationConfig !== null) {
            isRefferal ? setconfirmRefferalModalOpe(true) : setConfirmApplicationModalOpen(true);
        }
    }, [confirmApplicationConfig]);

    return loading ? (
        <Loader spinnerSize="md" centerHeight="65vh" />
    ) : (
        <Flex>
            <Box flex={1} pr="20px" borderRight="1px solid #D1D2DB">
                <Text fontSize="18px" lineHeight="27px" color="brand" fontWeight="600">
                    My agency
                </Text>
                {items.length ? (
                    <InfiniteScroll>
                        {items.map(elem => {
                            const confirmApplicationConfig = {
                                applicantId: elem.id,
                                fee: +roundAplicantPrices(isRefferal ? referralFee : elem.fee),
                                price: +roundAplicantPrices(elem.price),
                                requestId,
                            };

                            return (
                                <ApplicantItem
                                    key={elem.id}
                                    {...elem}
                                    onAccept={() => {
                                        setConfirmApplicationConfig(confirmApplicationConfig);
                                    }}
                                    onReject={() => onRejectApplicant(elem.id)}
                                />
                            );
                        })}
                    </InfiniteScroll>
                ) : (
                    <Center h="50vh">
                        <Text color="brand">There is no applicants from my agency</Text>
                    </Center>
                )}
            </Box>
            <Divider orientation="vertical" />
            <Box flex={1} ml="20px">
                <Text fontSize="18px" lineHeight="27px" color="brand" fontWeight="600">
                    Partner Agencies
                </Text>
                {partnerApplicants.length ? (
                    <InfiniteScroll>
                        {partnerApplicants.map(elem => {
                            const confirmApplicationConfig = {
                                applicantId: elem.id,
                                fee: +roundAplicantPrices(isRefferal ? referralFee : elem.fee),
                                price: +roundAplicantPrices(elem.price),
                                requestId,
                            };

                            return (
                                <ApplicantItem
                                    key={elem.id}
                                    {...elem}
                                    onAccept={() =>
                                        setConfirmApplicationConfig(confirmApplicationConfig)
                                    }
                                    onReject={() => onRejectApplicant(elem.id)}
                                />
                            );
                        })}
                    </InfiniteScroll>
                ) : (
                    <Center h="50vh">
                        <Text color="brand">There is no applicants from partner agencies</Text>
                    </Center>
                )}
            </Box>
            <ConfirmApplicantModal
                isOpen={confirmApplicationModalOpen}
                onClose={handleConfirmApplicantModalClose}
                onConfirm={onConfirmApplicant}
                confirmApplicationConfig={confirmApplicationConfig}
            />
            <ConfirmRefferalModal
                isOpen={confirmRefferalModalOpen}
                onClose={handleConfirmRefferalModalClose}
                onConfirm={onConfirmApplicant}
                confirmApplicationConfig={confirmApplicationConfig}
            />
        </Flex>
    );
};
