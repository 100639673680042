import React from 'react';
import { Box } from '@chakra-ui/react'
//components
import {
    ChatHeader,
    ChatFooter,
    ChatBody
} from './components';
import { IChatProps, IChatState } from '../types';
import { Conversation } from "@twilio/conversations/lib/conversation";
import { Message } from "@twilio/conversations/lib/message";


export class Chat extends React.Component<IChatProps, IChatState> {
    messagesEnd: any;

    constructor(props: IChatProps) {
        super(props);
        this.state = {
            conversationProxy: props.conversationProxy,
            messages: [],
            isFilesPicked: false,
            loadingState: 'initializing',
            boundConversations: new Set(),
        };
    }

    loadMessagesFor = (thisConversation: Conversation) => {
        console.log('1111!!!!!!!!!!!!!', this.state.conversationProxy);

        if (this.state.conversationProxy === thisConversation) {
            thisConversation.getMessages()
                .then((messagePaginator: any) => {
                    if (this.state.conversationProxy === thisConversation) {
                        this.setState({ messages: messagePaginator.items, loadingState: 'ready' });
                    }
                    this.state.conversationProxy.setAllMessagesRead();
                })
                .catch((err: any) => {
                    console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
                    this.setState({ loadingState: "failed" });
                });
        }
    };

    componentDidMount = () => {
        if (this.state.conversationProxy) {
            this.loadMessagesFor(this.state.conversationProxy);

            if (!this.state.boundConversations.has(this.state.conversationProxy)) {
                let newConversation = this.state.conversationProxy;
                newConversation.on('messageAdded', (m: Message) => this.messageAdded(m, newConversation));
                this.setState({ boundConversations: new Set([...this.state.boundConversations, newConversation]) });
            }
        }
        this.scrollToBottom();
    }

    componentDidUpdate = (oldProps: IChatProps, oldState: IChatState) => {
        if (this.state.conversationProxy !== oldState.conversationProxy) {
            this.setState({ messages: [] });
            this.loadMessagesFor(this.state.conversationProxy);

            if (!this.state.boundConversations.has(this.state.conversationProxy)) {
                let newConversation = this.state.conversationProxy;
                newConversation.on('messageAdded', (m: Message) => this.messageAdded(m, newConversation));
                this.setState({ boundConversations: new Set([...this.state.boundConversations, newConversation]) });
            }
        }
        this.scrollToBottom();
    };

    static getDerivedStateFromProps(newProps: IChatProps, oldState: IChatState) {
        let logic = (oldState.loadingState === 'initializing') || oldState.conversationProxy !== newProps.conversationProxy;
        if (logic) {
            return { loadingState: 'loading messages', conversationProxy: newProps.conversationProxy };
        } else {
            return null;
        }
    }

    messageAdded = (message: Message, targetConversation: Conversation) => {
        if (targetConversation === this.state.conversationProxy)
            this.setState((prevState: IChatState, props: IChatProps) => ({
                messages: [...prevState.messages, message]
            }));
    };

    sendMessage = (message: string) => {
        this.state.conversationProxy.sendMessage(message);
    };

    onDrop = (acceptedFiles: File) => {
        this.state.conversationProxy.sendMessage({ contentType: acceptedFiles.type, media: acceptedFiles });
    };

    setIsFilesPicked = (isPicked: boolean) => {
        this.setState({ isFilesPicked: isPicked })
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    render() {
        return (
            <>
                <ChatHeader
                    currentConversation={this.props.conversationProxy}
                    userId={this.props.userId}
                />
                <Box
                    h={this.state.isFilesPicked
                        ? this.props.isLargerThan900 ? "60%" : "50%"
                        : "70%"
                    }
                    p="0 2vw"
                    overflowY="scroll"
                    css={{
                        '&::-webkit-scrollbar': {
                            display: "none"
                        },
                    }}
                >
                    <ChatBody
                        messages={this.state.messages}
                        userId={this.props.userId}
                    />
                    <div style={{ float: "left", clear: "both" }}
                        ref={(el: any) => { this.messagesEnd = el; }}>
                    </div>
                </Box>
                <ChatFooter
                    sendMessage={this.sendMessage}
                    onDrop={this.onDrop}
                    setIsFilesPicked={this.setIsFilesPicked}
                    isFilesPicked={this.state.isFilesPicked}
                    isLargeThan900={this.props.isLargerThan900}
                />
            </>
        )
    }
}
