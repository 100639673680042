import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import {
    CloseButton,
    IconButton,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Text,
} from '@chakra-ui/react';

import { Typography } from '../../../common/components/blocks/Typography/Typography';
import { CustomButton } from '../../../common/components';

import { colors } from '../../../common/constants';
import { fonts } from '../../../common/utils';
import { archiveCurrentUser } from '../store/actions';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    loading?: boolean;
}

export const DeleteCurrentUserModal: FC<IProps> = ({ isOpen, onClose, loading }) => {
    const dispatch = useDispatch();

    const archiveCurrentAccount = () => dispatch(archiveCurrentUser());

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
            <ModalOverlay />

            <ModalContent p="20px" borderRadius="20px">
                <>
                    <ModalHeader alignItems="center" d="flex">
                        <Typography weight={600} size={20} margin={'0 auto'}>
                            Delete account
                        </Typography>
                        <IconButton 
                            onClick={onClose}
                            icon={<CloseButton />} 
                            aria-label={'close modal'} 
                            variant="ghost"
                            color={colors.secondaryFontDarker}
                        />
                    </ModalHeader>

                    <ModalBody mt="20px">
                        <Text fontFamily={fonts.poppins} color={colors.brand} fontSize="18px" textAlign={'center'}>
                            Are you sure that you want to delete your account?
                        </Text>

                        <Text
                            fontFamily={fonts.poppins}
                            textAlign={'center'}
                            color={colors.brand}
                            fontSize="18px"
                            mt="14px"
                        >
                            {`Once you've deleted your account, you will no longer be able to log in to 
                            the ZootPro site or apps. This action cannot be undone.`}
                        </Text>

                        {loading ? (
                            <Spinner />
                        ) : (
                            <CustomButton
                                text="Delete"
                                variant="outline"
                                h="62px"
                                color={colors.brand}
                                borderColor={colors.brand}
                                m="50px 0 12px"
                                onClick={archiveCurrentAccount}
                            />
                        )}

                        <CustomButton
                            text="Cancel"
                            h="62px"
                            bgColor={colors.brand}
                            onClick={onClose}
                        />
                    </ModalBody>
                </>
            </ModalContent>
        </Modal>
    );
};
