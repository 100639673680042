import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalBody,
    Box,
    ModalContent,
    CloseButton,
    ModalHeader,
    Text,
    HStack,
    Checkbox,
} from '@chakra-ui/react';
import { CustomButton } from '../../blocks';
import { ApplicantToSubmitType } from '../../../../pages/Requests/types';

interface IConfirmApplicantModalProps {
    isOpen: boolean;
    onClose: () => void;
    confirmApplicationConfig: ApplicantToSubmitType | null;
    onConfirm: (value: number) => void;
}

export const ConfirmRefferalModal: React.FC<IConfirmApplicantModalProps> = ({
    isOpen,
    onClose,
    confirmApplicationConfig,
    onConfirm,
}) => {
    const handleConfirm = () => {
        onConfirm(confirmApplicationConfig?.applicantId || 0);
        onClose();
    };

    console.log('--->', confirmApplicationConfig);

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
            <ModalOverlay />
            <ModalContent bgColor="white" alignItems="center">
                <ModalHeader
                    d="flex"
                    w="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    px="50px"
                    mt="30px"
                >
                    <Text color="brand" fontSize="16px">
                        Below is estimated referral fee to hire this agent. Please confirm:
                    </Text>
                    <CloseButton onClick={onClose} />
                </ModalHeader>
                <ModalBody d="flex" w="100%" flexDirection="column" px="50px">
                    <Box
                        bg="bgPaymentInfo"
                        padding="19px 34px"
                        borderRadius="8px"
                        mt="24px"
                        mb="45px"
                    >
                        <HStack justifyContent="space-between" my="16px">
                            <Text color="brand" fontSize="13px" fontWeight={400} lineHeight="20px">
                                Refferal Fee:
                            </Text>
                            <Text
                                color="secondaryFontDarker"
                                fontSize="16px"
                                fontWeight="500"
                                lineHeight="24px"
                            >
                                {`${confirmApplicationConfig?.fee.toString()}%`}
                            </Text>
                        </HStack>
                    </Box>
                    <HStack mb="45px">
                        <CustomButton
                            text="Cancel"
                            variant="outline"
                            color="brand"
                            height="60px"
                            borderColor="brand"
                            onClick={onClose}
                        />
                        <CustomButton
                            text="Hire"
                            bgColor="brand"
                            height="60px"
                            onClick={handleConfirm}
                        />
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
