import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SidebarContainer from '../Sidebar/Sidebar.container';
import { DashboardWrapper } from '../../common/components/blocks/DashboardWrapper/DashboardWrapper';
import { MyAgencyContent } from './components';
import { getUserInfoRequest } from '../../store/actions/user.actions';
import { getCustomerAccountStatusRequest } from '../Settings/pages/Payments/store/actions';
import { useParams } from 'react-router-dom';

export const defaultOpenHouseDetailsOpenConfig = {
    isOpen: false,
    isMyListings: true,
    isManageOpenHouse: false,
    listingStatusId: 0,
};

export const MyAgency: React.FC = () => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(getUserInfoRequest());
        dispatch(getCustomerAccountStatusRequest());
    }, []);

    return (
        <DashboardWrapper
            sidebar={<SidebarContainer isAccessAllowed />}
            content={<MyAgencyContent />}
        />
    );
};
