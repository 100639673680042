import React, { useEffect, useState } from 'react';
import { GetCitiesByCurrentAgencyResponse } from '../../../../pages/HostOpenHouse/types';
import { IResponseWithCustomValue } from '../../../types';
import API from '../../../api/api.base';
import { CustomSearchableSelect } from '../CustomSearchableSelect';
import { useDebounce } from '../../../utils';

interface ISearchCitiesPickerProps {
    setValue?: (value: number | string) => void;
    width?: string;
    minW?: string;
}

export const SearchCitiesPicker: React.FC<ISearchCitiesPickerProps> = ({
    setValue,
    width,
    minW,
    ...props
}) => {
    const [cities, setCities] = useState<GetCitiesByCurrentAgencyResponse[]>([]);
    const [term, setTerm] = useState('');
    const termDebounce = useDebounce(term, 300);
 

    const getCities = async (searchValue: string | number) => {
        try {
            const result: IResponseWithCustomValue<{
                cities: GetCitiesByCurrentAgencyResponse[];
            }> = await API.get(`/api/listings/cities-by-agency?Name=${searchValue}`, {});
            if (result.success) {
                setCities(result.value.cities.slice(0, 4));
            } else {
                console.log(result.errors[0].errorMessage);
            }
        } catch (err) {
            console.log(err, 'getCities');
        }
    };

    useEffect(() => {
        if (termDebounce.length >= 3) {
            getCities(termDebounce).catch(console.log);
        }
    }, [termDebounce]);

    console.log('=============!')

    return (
        <CustomSearchableSelect
            items={ cities.map((city) => {
                return {value: city.id, label: city.cityName, county: city.countyName}
            })}
            label="City/Neighborhood"
            placeHolder="e.g. ‘Downtown Los Angeles’"
            width={width}
            onChange={setValue}
            setTerm={setTerm}
            type="open house"
            minW={minW}
        />
    );
};
