import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useDelta } from 'react-delta';
import { useHistory } from 'react-router-dom';
import { Button, Heading } from '@chakra-ui/react';

import { RootStore, dispatch } from '../../store';
import { getHelpTypes } from '../../store/actions/directories.actions';
import { sendHelpRequest } from '../../store/actions/landing.actions';

import { CustomDropdown, CustomInput, TypeAhead } from '../../common/components';
import { agenciesLoadOptions, fonts } from '../../common/utils';
import { requestDemoResolver } from '../../common/schemes/request-demo.schema';
import { SendHelpRequestType } from '../../common/types';

const RequestDemoForm: React.FC = () => {
    const {
        formState: { errors },
        setValue,
        clearErrors,
        handleSubmit,
        register,
        control,
    } = useForm<SendHelpRequestType>({ resolver: requestDemoResolver });

    const { helpTypes } = useSelector((state: RootStore) => state.directories);
    const { loading } = useSelector((state: RootStore) => state.landing.helpRequest);

    const helpReqestLoadingDelta = useDelta(loading);
    const history = useHistory();

    const onSendHelpRequest = (payload: SendHelpRequestType) => dispatch(sendHelpRequest(payload));

    const onSubmit = (data: any) => {
        onSendHelpRequest(data);
    };

    useEffect(() => {
        dispatch(getHelpTypes());
    }, []);

    useEffect(() => {
        if (helpReqestLoadingDelta && helpReqestLoadingDelta.prev) {
            if (helpReqestLoadingDelta.prev && !helpReqestLoadingDelta.curr) {
                history.push('/');
            }
        }
    }, [helpReqestLoadingDelta]);

    return (
        <>
            <Heading
                color="#2C335C"
                fontFamily={fonts.poppins}
                fontSize={{ base: '28px', lg: '25px' }}
                lineHeight={{ base: '34px', lg: '35px' }}
                fontWeight="700"
                w={{ base: '100%', lg: '100%' }}
            >
                Request A Demo
            </Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
                <CustomInput
                    label="Business Email Address*"
                    width="100%"
                    placeholder="Email"
                    margin="26px 0 24px 0"
                    register={{ ...register('email') }}
                    errors={errors.email}
                    isBorder={false}
                    fontSize="13px"
                    fontWeight={300}
                    textSize="13px"
                    height="50px"
                />
                <CustomInput
                    label="First Name*"
                    width="100%"
                    placeholder="First Name"
                    margin="26px 0 24px 0"
                    register={{ ...register('firstName') }}
                    errors={errors.firstName}
                    pattern="[A-Za-z]"
                    fontSize="13px"
                    fontWeight={300}
                    textSize="13px"
                    isBorder={false}
                    height="50px"
                />
                <CustomInput
                    label="Last Name*"
                    width="100%"
                    placeholder={'Last Name'}
                    register={{ ...register('lastName') }}
                    margin="26px 0 24px 0"
                    errors={errors.lastName}
                    isBorder={false}
                    fontSize="13px"
                    fontWeight={300}
                    textSize="13px"
                    height="50px"
                />
                <Controller
                    control={control}
                    name="agencyId"
                    render={() => (
                        <TypeAhead
                            placeholder="Enter"
                            label="Brokerage Name*"
                            width="100%"
                            onStateChange={ev => {
                                setValue('agencyId', Number(ev?.value) || 0);
                                clearErrors('agencyId');
                            }}
                            loadOptions={agenciesLoadOptions}
                            errors={errors.agencyId?.message}
                        ></TypeAhead>
                    )}
                />

                <CustomDropdown
                    label="How can we help you?*"
                    placeholder="Select"
                    options={helpTypes.map((item: any) => ({
                        value: item.id,
                        label: item.name,
                        id: item.id,
                    }))}
                    width="100%"
                    register={{ ...register('helpTypeId') }}
                    fontSize="13px"
                    labelSize="13px"
                    labelWeigth={300}
                    capitalize={false}
                    errors={errors.helpTypeId}
                />
                <Button
                    marginTop="32px"
                    w={{ base: '220px', sm: '342px' }}
                    variant={'primary'}
                    borderRadius="22px"
                    boxShadow="0px 3px 3px grey"
                    type="submit"
                    isLoading={loading}
                >
                    Send
                </Button>
            </form>
        </>
    );
};

export default RequestDemoForm;
