import { put, call, takeEvery } from 'redux-saga/effects';
import { getRequest, getRequestSuccess, getRequestError, setReadRequest } from '../actions';
import API, { baseUrl } from '../../../../common/api/api.base';
import { GetRequestSuccessResponse } from '../../types';
import { IResponseWithCustomValue } from '../../../../common/types';
import axios from 'axios';

function workerSetRequest(action: ReturnType<typeof getRequest>) {
    axios.post(window.baseUrl+'/api/request-read/'+action.payload);
}

export default function* watchSetReadRequestSaga() {
    yield takeEvery(setReadRequest.type, workerSetRequest);
}
