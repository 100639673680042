import { ComponentStyleConfig } from '@chakra-ui/theme';

export const Badge: ComponentStyleConfig = {
    baseStyle: {
        fontSize: '12px',
        fontWeight: '400',
        textTransform: 'capitalize',
        color: 'brand',
        borderRadius: '4px',
        py: '3px',
        px: '8px',
    },
    variants: {
        primary: {
            bg: 'switchScheme.500',
        },
        warn: {
            bg: 'orange',
            color: 'white',
        },
        error: {
            bg: 'calendarRed',
            color: 'white',
        },
    },
};
