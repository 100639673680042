import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { CreateNewPostModal } from '../Opportunities/modals';
import { CreateNewPostModal as NewBlast } from '../Updates/modals';
import { GetHelpNowModal } from './modals/GetHelpNowModal/index';
import Sidebar from './Sidebar';

import { findJobPostsRequest } from '../Opportunities/store/actions';
import { createPostRequest } from '../Updates/store/actions';
import { CreatePostPayload } from '../Updates/types';
import { dispatch, RootStore } from '../../store';

interface ISidebarContainerProps {
    isAccessAllowed: boolean;
    onCLickLink?: () => void;
}

type ModalOptionsProps = {
    type: '' | 'getHelpNow' | 'newPostModal' | 'newBlast';
};

const SidebarContainer: React.FC<ISidebarContainerProps> = ({ 
    isAccessAllowed,
    onCLickLink
}) => {
    const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
    const [openModal, setOpenModal] = useState<ModalOptionsProps>({ type: '' });
    const history = useHistory();

    const states = useSelector((state: RootStore) => state.user.user.currentStates) || [];
    const { loading: createPostLoading, errors: createPostErrors } = useSelector(
        (state: RootStore) => state.posts.createPost,
    );

    const getItems = (page?: number) => {
        dispatch(
            findJobPostsRequest({
                pageNumber: page || 1,
                pageSize: 10,
                isMine: false,
                typeIds: [],
                date: '',
            }),
        );
    };

    const onCreatePost = (payload: CreatePostPayload) => {
        dispatch(createPostRequest(payload));
        handleModalClose();
    };

    const handleModalClose = () => {
        setOpenModal({ type: '' });
    };

    return (
        <>
            <Sidebar
                onCLickLink={onCLickLink}
                isRequestModalOpen={isRequestModalOpen}
                setIsRequestModalOpen={setIsRequestModalOpen}
                history={history}
                states={states}
                isAccessAllowed={isAccessAllowed}
                setOpenModal={setOpenModal}
            />
            <GetHelpNowModal
                isOpen={openModal.type === 'getHelpNow'}
                onClose={handleModalClose}
                title={'What Do You Need Help With?'}
            />
            <CreateNewPostModal
                isOpen={openModal.type === 'newPostModal'}
                onClose={handleModalClose}
                getItems={getItems}
            />
            <NewBlast
                createPostLoading={createPostLoading}
                onCreatePost={onCreatePost}
                size={'4xl'}
                isOpen={openModal.type === 'newBlast'}
                onClose={handleModalClose}
                title={'Create New Blast'}
            />
        </>
    );
};

export default SidebarContainer;
