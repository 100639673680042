import React from 'react';
import SidebarContainer from '../Sidebar/Sidebar.container';
import { DashboardWrapper } from '../../common/components/blocks/DashboardWrapper/DashboardWrapper';
import { MyStuffContent } from './components';

export const defaultOpenHouseDetailsOpenConfig = {
    isOpen: false,
    isMyListings: true,
    isManageOpenHouse: false,
    listingStatusId: 0,
};

export const MyStuff: React.FC = () => {
    return (
        <DashboardWrapper
            maxW="1400px"
            sidebar={<SidebarContainer isAccessAllowed />}
            content={<MyStuffContent />}
        />
    );
};
