export enum ErrorMessages {
    REQUIRED = 'This field is required',
    EMAIL_INVALID = 'Email is invalid',
    DATE_INVALID = 'You sholud be at least 21 year old to sign up',
    NUMBER_INVALID = 'This field should be a number',
    SHORT_PASSWORD = 'Password should be minimum 8 characters long',
    PASSWORD_INVALID = 'Password must have at least 8 characters, at least one uppercase letter and one number',
    URL_INVALID = 'Invalid URL',
    TIME_DURATION = 'Time duration is not correct',
    POSITIVE_NUMBER = 'This value should be greater than 0',
    PAST_DATE = 'Date should not be in the past',
    POSITIVE_VALUE = 'This value should be positive',
    NON_ALPHANUMERIC_MISSING = 'Password should have at least one non-alphanumeric character',
    PASSWORD_UPPERCASE = 'Password should have at least one uppercase character',
    PASSWORD_DIGIT = 'Password should have at least one digit',
    MINIMUM_4_CHARS = 'Password should have at least 4 unique characters',
    AT_LEAST_1_VALUE = 'Please select at least one value',
    PHONE_NUMBER_INCOMPLETE = 'Please enter full number',
    BAD_12_HOURS_TIME_FORMAT = 'Please, enter your time in 12-hours format',
    BAD_FROM_8AM_TO_9PM_FORMAT = 'Please, enter your time from 08:00 AM to 09:00 PM',
}
