import { put, call, takeEvery } from 'redux-saga/effects';
// import AlerterService from '../../../services/AlerterService';
import API from '../../../common/api/api.base';
import {
    findJobPostsRequest,
    findJobPostsSuccess,
    findJobPostsError,
    createJobPostRequest,
    createJobPostSuccess,
    createJobPostError,
    submitJobProposalRequest,
    submitJobProposalSuccess,
    submitJobProposalError,
    getReferralOpportunityAgenciesRequest,
    getReferralOpportunityAgenciesSuccess,
} from './actions';
import {
    IResponseWithCustomValue,
    IResponseWithoutValue,
    IdNameTypes,
} from '../../../common/types';
import { FindJobPostsResponseType } from '../types';
import { getDashboardRequest } from '../../../store/actions/dashboard.actions';

export function* workerFindJobPosts(action: ReturnType<typeof findJobPostsRequest>) {
    try {
        const result: IResponseWithCustomValue<{
            jobListModels: FindJobPostsResponseType[];
            totalCount: number;
        }> = yield call(API.post, '/api/requests/job-posts', action.payload);
        if (result.success) {
            yield put(
                findJobPostsSuccess({
                    pageNumber: action.payload.pageNumber,
                    ...result.value,
                }),
            );
        } else {
            yield put(findJobPostsError(result.errors));
            console.log(result.errors);
            // AlerterService.error(result?.errors[0].errorMessage);
        }
    } catch (error) {
        console.log(error);
        // AlerterService.error('Something went wrong, try again later');
    }
}

function* workerCreateJobPost(action: ReturnType<typeof createJobPostRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            '/api/requests/job-post',
            action.payload.data,
        );
        if (result.success) {
            yield put(createJobPostSuccess());
            // AlerterService.success(result.messages[0].messageText);
            yield put (getDashboardRequest(action.payload.dashboardRequestPayload));
        } else {
            //yield put(createJobPostError(result.errors));
            // AlerterService.error(result?.errors[0].errorMessage);
        }
    } catch (error) {
        console.log(error);
        // AlerterService.error('Something went wrong, try again later');
    }
}

function* workerSubmitJobProposal(action: ReturnType<typeof submitJobProposalRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            `/api/requests/job-posts/${action.payload.id}`,
            {},
        );
        if (result.success) {
            yield put(submitJobProposalSuccess());
            console.log('submitJobProposalSuccess', result);
            // AlerterService.success(result.messages[0].messageText);
            if (action.payload.isListings) {
                alert('action.payload.isListings');
            } else {
                console.log('go back');
                // goBack();
            }
            if (action.payload.dashboardRequestPayload) {
                yield put (getDashboardRequest(action.payload.dashboardRequestPayload));
            }
        } else {
            yield put(submitJobProposalError(result.errors));
            console.log('submitJobProposalError', result);
            // AlerterService.error(result?.errors[0].errorMessage);
        }
    } catch (error) {
        // AlerterService.error('Something went wrong, try again later');
        console.log('submitJobProposalError', error);
    }
}

function* workerGetReferralOpportunityAgencies(
    action: ReturnType<typeof getReferralOpportunityAgenciesRequest>,
) {
    try {
        const result: IResponseWithCustomValue<{ agencies: IdNameTypes[] }> = yield call(
            API.get,
            `/api/profile/agencies?id=${action.payload}`,
            {},
        );
        if (result.success) {
            yield put(getReferralOpportunityAgenciesSuccess(result.value.agencies));
        }
    } catch (error) {
        console.log(error, 'workerGetReferralOpportunityAgencies error');
    }
}

export default function* watchOpportunitiesSaga() {
    yield takeEvery(findJobPostsRequest.type, workerFindJobPosts);
    yield takeEvery(createJobPostRequest.type, workerCreateJobPost);
    yield takeEvery(submitJobProposalRequest.type, workerSubmitJobProposal);
    yield takeEvery(
        getReferralOpportunityAgenciesRequest.type,
        workerGetReferralOpportunityAgencies,
    );
}
