import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalBody,
    Box,
    ModalContent,
    CloseButton,
    ModalHeader,
    Text,
    HStack,
    Checkbox,
} from '@chakra-ui/react';

import { CustomButton } from '../../blocks';

import { checkboxTexts } from './constants';

interface IConfirmApplicantModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    amount: number | null;
    type: string;
    typeId?: number;
}

export const ApplyingJobModal: React.FC<IConfirmApplicantModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    amount,
    type,
    typeId,
}) => {
    const [termsAccepted, setTermsAccepted] = useState(false);

    const onCloseWithClear = () => {
        onClose();
        setTermsAccepted(false);
    };

    const checkboxText = amount ? checkboxTexts.withAmount : checkboxTexts.withoutAmount;
    const typeAndAmount = type === '%' ? `${amount}${type}` : `${type}${amount}`;

    return (
        <Modal isOpen={isOpen} onClose={onCloseWithClear} isCentered size="xl">
            <ModalOverlay />
            <ModalContent borderRadius={'18px'} bgColor="white" alignItems="center">
                <ModalHeader
                    d="flex"
                    w="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    px="50px"
                    mt="30px"
                >
                    <Text color="brand" fontSize="16px">
                        Below is the estimated earnings if you are hired to complete this activity:
                    </Text>
                    <CloseButton onClick={onCloseWithClear} />
                </ModalHeader>
                <ModalBody d="flex" flexDirection="column" px="50px">
                    <HStack align="flex-start">
                        <Checkbox
                            checked={termsAccepted}
                            onChange={ev => setTermsAccepted(ev.target.checked)}
                        />
                        <Text
                            color="brand"
                            fontSize="13px"
                            lineHeight="23px"
                            fontWeight="400"
                            mt="-5px !important"
                        >
                            {checkboxText}
                        </Text>
                    </HStack>
                    {Boolean(amount) && (
                        <Box bg="bgPaymentInfo" padding="19px 34px" borderRadius="8px" mt="24px">
                            <HStack justifyContent="space-between">
                                <Text
                                    color="brand"
                                    fontSize="13px"
                                    fontWeight="400"
                                    lineHeight="20px"
                                >
                                    Estimated Earnings:
                                </Text>
                                <Text
                                    color="brand"
                                    fontSize="16px"
                                    fontWeight="500"
                                    lineHeight="24px"
                                >
                                    {typeAndAmount}
                                </Text>
                            </HStack>
                        </Box>
                    )}
                    <HStack mb="45px" mt="24px">
                        <CustomButton
                            text="Cancel"
                            variant="outline"
                            color="brand"
                            height="60px"
                            borderColor="brand"
                            onClick={onCloseWithClear}
                        />
                        <CustomButton
                            className="CONFIRM_BTN"
                            disabled={!termsAccepted}
                            text="Submit"
                            bgColor="brand"
                            height="60px"
                            onClick={() => {
                                onConfirm();
                                onCloseWithClear();
                            }}
                        />
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
