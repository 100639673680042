import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { fonts } from '../../../../common/utils';
import { CustomButton } from '../../../../common/components';

interface IFreeTrialProps {
    subscribedTo: string;
    onUpgrade: () => void;
}

export const FreeTrial: React.FC<IFreeTrialProps> = ({ subscribedTo, onUpgrade }) => {

    const dateNow = dayjs().format('YYYY-MM-DD');
    const lastSubsriptionDay = dayjs(subscribedTo).format('YYYY-MM-DD');
    const daysLeft = dayjs(lastSubsriptionDay).diff(dayjs(dateNow), 'days');

    return (
        <Flex height="90%" flexDirection="column" justifyContent="space-between">
            <Box bg="#4FD1C5" borderRadius="26px" p="30px">
                <Flex align="center" justify="space-between">
                    <Text
                        fontSize="24px"
                        color="white"
                        fontWeight={600}
                        fontFamily={fonts.montserrat}
                        lineHeight="32px"
                    >
                        Free Account
                    </Text>
                    <Text
                        fontSize="17px"
                        lineHeight="21px"
                        fontWeight={500}
                        color="white"
                        fontFamily={fonts.montserrat}
                    >
                        {`/${daysLeft} days`}
                    </Text>
                </Flex>
                <Text
                    fontSize="17px"
                    lineHeight="21px"
                    fontWeight={600}
                    color="white"
                    fontFamily={fonts.montserrat}
                    marginTop="16px"
                >
                    Ends on {dayjs.utc(subscribedTo).format('MMMM DD, YYYY')}
                </Text>
            </Box>
            <CustomButton
                text="Upgrade"
                color="#2C335C"
                bgColor="transparent"
                height="56px"
                borderColor="#2C335C"
                borderRadius="16px"
                w="342px"
                onClick={onUpgrade}
                alignSelf="flex-end"
            />
        </Flex>
)};
