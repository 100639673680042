import React from 'react';
import { colors } from '../../common/constants';

interface IProps {
    width?: string;
    height?: string;
}

export const RadioUnselected: React.FC<IProps> = ({ width = '23', height = '23' }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="11.5" cy="11.5" r="11" stroke={colors.secondaryFontDarker} />
    </svg>
);
