import React, { useEffect, useState } from 'react';
import { Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { CustomButton, CustomDropdown, CustomInput } from '../../../../../common/components';
import { addLicenseResolver } from '../../../../../common/schemes';
import {
    AddLicensePayload,
    LicenseDraftForm,
    LicenseDraftType,
    LicenseType,
    SaveLicenseDraftPayload,
} from '../../../types';
import { StateDirectoryItem, AgenciesItem, GetCitiesResponse } from '../../../../../common/types';
import { SearchCitiesSelect } from '../../../../../common/components/blocks/SearchCitiesSelect';
interface ILicense {
    licenses: LicenseType[];
    states: StateDirectoryItem[];
    agencies: AgenciesItem[];
    draft: LicenseDraftType;
    addLicenseLoading: boolean;
    saveDraftLoading: boolean;
    index: number;
    showLicenseEditor: boolean;
    accessToken: string;
    stateId: string;
    choosenItem: number | null;
    setStateId: (v: string) => void;
    saveLicenseDraft: (v: SaveLicenseDraftPayload) => void;
    addLicense: (v: AddLicensePayload) => void;
    handleAddLicense: () => void;
    setChoosenItem: (id: number | null) => void;
    refProp: React.RefObject<HTMLDivElement>;
}

export const AddLicenseItem: React.FC<ILicense> = ({
    states,
    agencies,
    addLicenseLoading,
    saveDraftLoading,
    index,
    accessToken,
    stateId,
    setStateId,
    addLicense,
    handleAddLicense,
    choosenItem,
    licenses,
    refProp,
}) => {
    const {
        formState: { errors },
        handleSubmit,
        register,
        watch,
        setValue,
    } = useForm<LicenseDraftForm>({ resolver: addLicenseResolver });

    const values = watch();

    const [typeIdsSelected, setTypeIdsSelected] = useState<GetCitiesResponse[]>([]);
    const [stateIdValue, setStateIdValue] = useState(0);

    const onSubmit = (data: LicenseDraftForm) => {
        const addPayload = {
            state: data.state,
            licenseNumber: data.licenseNumber,
            mlsId: data.mlsId,
            preferredAreasOfServices: typeIdsSelected,
            agencyId: +data.agency,
        };
        addLicense(addPayload);
    };

    useEffect(() => {
        if (choosenItem !== index) {
            handleAddLicense();
        }
    }, [choosenItem]);

    useEffect(() => {
        setStateIdValue(Number(stateId));
        setTypeIdsSelected([]);
    }, [stateId]);

    const findStateId = (state: string) => {
        const id = states.find(st => st.name === state)?.id || 1;
        return String(id);
    };

    return (
        <Flex flexDirection="column" borderTop={index && '1px solid #D1D2DB'} ref={refProp}>
            <Flex>
                <Text mt="20px" fontSize="18px" fontWeight="600">
                    {values.state}
                </Text>
            </Flex>

            <Flex direction="column" align="center">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <SimpleGrid columns={2} gap="100px" rowGap="0px" mt="10px">
                        <Flex w="100%" mb="15px">
                            <CustomDropdown
                                options={states
                                    ?.filter(
                                        ({ name: id1 }) =>
                                            !licenses.some(({ state: id2 }) => id1 === id2),
                                    )
                                    ?.map((item: any) => ({
                                        value: item.name,
                                        label: item.name,
                                        id: item.id,
                                    }))}
                                label="State"
                                register={{
                                    ...register('state'),
                                    onChange: (value: any) => {
                                        setValue('state', value.target.value);
                                        setStateId(findStateId(value.target.value));
                                    },
                                }}
                                errors={errors.state}
                                width="100%"
                                height="50px"
                                fontSize="16px"
                                labelSize="16px"
                                placeholder="State"
                            />
                        </Flex>
                        <Flex w="100%" mb="15px">
                            <CustomDropdown
                                options={agencies?.map((item: any, i: any) => ({
                                    value: item.id,
                                    label: item.name,
                                    id: item.id,
                                }))}
                                label="Agency"
                                register={{
                                    ...register('agency'),
                                    onChange: (v: any) => {
                                        console.log('agency', v.target.value);
                                        setValue('agency', v.target.value);
                                    },
                                }}
                                placeholder={'Select'}
                                errors={errors.agency}
                                width="100%"
                                height="50px"
                                fontSize="16px"
                                labelSize="16px"
                            />
                        </Flex>
                        <Flex w="100%" mb="15px">
                            <CustomInput
                                label="License"
                                placeholder="License"
                                register={{ ...register('licenseNumber') }}
                                width="100%"
                                errors={errors.licenseNumber}
                                height="50px"
                                fontSize="16px"
                                textSize="16px"
                            />
                        </Flex>
                        <Flex w="100%" flexDir="column">
                            <Text
                                fontWeight="300"
                                fontSize="16px"
                                textSize="16px"
                                fontFamily="poppins"
                            >
                                Areas of Service
                            </Text>
                            <SearchCitiesSelect
                                stateIdValue={stateIdValue}
                                setTypeIdsSelected={setTypeIdsSelected}
                                typeIdsSelected={typeIdsSelected}
                                disabled={false}
                                token={accessToken}
                            />
                        </Flex>
                        <Flex w="100%" mb="15px">
                            <CustomInput
                                label="MLS ID"
                                placeholder="MLS ID"
                                register={{ ...register('mlsId') }}
                                width="100%"
                                errors={errors.mlsId}
                                height="50px"
                                fontSize="16px"
                                textSize="16px"
                            />
                        </Flex>
                        <Flex w="100%" justify="flex-end" mt="20px" align="center">
                            <CustomButton
                                text="Cancel"
                                isLoading={saveDraftLoading}
                                width="100px"
                                height="50px"
                                bgColor="#fff"
                                borderColor="brand"
                                color="brand"
                                margin="0 0 20px 0"
                                onClick={handleAddLicense}
                            />
                            <CustomButton
                                text="Save"
                                type="submit"
                                width="100px"
                                height="50px"
                                bgColor="brand"
                                margin="0 0 20px 20px"
                                isLoading={addLicenseLoading}
                            />
                        </Flex>
                    </SimpleGrid>
                </form>
            </Flex>
        </Flex>
    );
};
