import React, { useState, useEffect } from 'react';

import { Avatar, AvatarGroup, Box, Flex, Text, IconButton, chakra } from '@chakra-ui/react';
import dayjs from 'dayjs';
import pluralize from 'pluralize';

import {
    getAvatarPicture,
    secondsToMinutes,
    fonts,
    formatDateTime,
    convertTimeIn12Hours,
} from '../../../common/utils';

import { colors } from '../../../common/constants';

import { BorderIconBox } from './BorderIconBox';
import {
    CalendarIcon,
    ClockIcon,
    DropdownArrowIcon,
    LocationIcon,
    Home,
    WavesIcon,
} from '../../../assets/icons';

import { MyRequestsType } from '../types';
import { IdNameTypes } from '../../../common/types';
import { StatusActivityItem } from '../../../common/components/aglobal/StatusActivityItem';

type RequestBoxType = {
    request: MyRequestsType;
    onOpenModal: () => void;
    requestCategories: IdNameTypes[];
    allowCountAccept?: boolean 
};

export const RequestBox: React.FC<RequestBoxType> = ({
    request,
    onOpenModal,
    requestCategories,
    allowCountAccept,
}) => {
    const [timeLeft, setTimeLeft] = useState(() => {
        if (request.categoryId !== 1) {
            return 0;
        }
        const date1 = dayjs(request.createdAt);
        const date2 = dayjs();
        const timeDiff = date2.diff(date1, 'seconds');
        return timeDiff > 3600 ? 2000 : 3600 - timeDiff;
    });

    useEffect(() => {
        /*
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
            if (timeLeft <= 0) setTimeLeft(0);
        }, 1000);
        return () => clearInterval(intervalId);
        */
    }, [timeLeft]);

    const requestStates = request.states.join(', ');
    const requestAddresses = request.addresses.join(', ');

    const requestDate = formatDateTime(request.dateTime).date;
    const requestTime = formatDateTime(request.dateTime).time;
    const openHouseDate = request.timeSlot && formatDateTime(request.timeSlot.date).date;
    const openHouseTimeFrom = request.timeSlot
        ? convertTimeIn12Hours(request.timeSlot.startTime, request?.timeZone)
        : '';
    const openHouseTimeTo = request.timeSlot
        ? convertTimeIn12Hours(request.timeSlot.endTime, request?.timeZone)
        : '';

    const category = requestCategories?.filter(item => item.id === request.categoryId)[0]?.name;
    
    return (
        <Box mt="20px" className="REQUEST_BOX_COMPONENT">
            {category && (
                <Text
                    mb="10px"
                    color={colors.green}
                    fontWeight={500}
                    fontSize="15px"
                    lineHeight="18px"
                    fontFamily={fonts.montserrat}
                    >
                    {category}
                </Text>
            )}
            <Flex
                className="reques_box_inner"
                overflow="hidden"
                alignItems="center"
                borderRadius={14}
                justifyContent="space-between"
                bg="rgba(209, 210, 219, 0.2)"
                px="25px"
            >
                <Flex>
                    <Box py="15px" w="240px">
                        {allowCountAccept && request?.countAccepted ?  (
                            <span style={{color: 'orange', fontSize: '12px'}}>
                                Responded {request.countAccepted}
                            </span>
                        ): ''}
                        <Text
                            color="brand"
                            fontWeight={600}
                            fontSize="15px"
                            lineHeight="18px"
                            fontFamily={fonts.montserrat}
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            overflow="hidden"
                        >
                            {request.requestTypeName}
                        </Text>
                        <Flex alignItems="center" mt={1}>
                            <LocationIcon />
                            <Text
                                ml={1}
                                color={colors.brand}
                                fontWeight={500}
                                fontSize="12px"
                                lineHeight="16px"
                                fontFamily={fonts.montserrat}
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                >
                                {request.typeId === 7 && (request.state) }
                                {request.typeId === 1 ? requestStates : requestAddresses}
                            </Text>
                        </Flex>
                    </Box>
                    <Flex alignSelf="stretch">
                        {request.typeId === 7 ? (
                            <>
                            {/*
                            <BorderIconBox
                                className="border_cell_request_box"
                                text={request.state}
                                icon={
                                    <Text
                                        color="brand"
                                        fontSize="12px"
                                        fontWeight="500"
                                        fontFamily={fonts.montserrat}
                                    >
                                        State:
                                    </Text>
                                }
                                isFirst
                            />
                            */}
                            </>
                        ) : request.typeId === 6 ? (
                            <>
                                <BorderIconBox
                                    className="border_cell_request_box"
                                    text={openHouseDate}
                                    icon={<CalendarIcon />}
                                    isFirst
                                />
                                <BorderIconBox
                                    className="border_cell_request_box"
                                    text={`Fr: ${openHouseTimeFrom}`}
                                    icon={<ClockIcon />}
                                />
                                <BorderIconBox
                                    className="border_cell_request_box"
                                    text={`To: ${openHouseTimeTo}`}
                                    icon={<ClockIcon />}
                                />
                            </>
                        ) : (
                            <>
                                <BorderIconBox 
                                    className="border_cell_request_box" 
                                    text={requestDate} 
                                    icon={<CalendarIcon />} isFirst />
                                <BorderIconBox 
                                    className="border_cell_request_box" 
                                    text={requestTime}
                                    icon={<ClockIcon />} 
                                />
                                {request.typeId === 1 ? (
                                    <BorderIconBox
                                    className="border_cell_request_box" 
                                        text={`${request.propertiesToShow} ${pluralize(
                                            'house',
                                            request.propertiesToShow,
                                        )}`}
                                        icon={<Home height="15" width="15" color={colors.brand} />}
                                    />
                                ) : (
                                    request.typeId !== 4 && (
                                        <BorderIconBox
                                        className="border_cell_request_box" 
                                            text={`${request.duration} ${pluralize(
                                                'hour',
                                                request.duration,
                                            )}`}
                                            icon={<WavesIcon />}
                                        />
                                    )
                                )}
                            </>
                        )}
                    </Flex>
                </Flex>
                {!!timeLeft && request.categoryId === 1 && false && (
                    <Flex alignItems="center" justifyContent="center" className="time_block">
                        <ClockIcon color={colors.calendarGreen} />
                        <Text
                            ml={1}
                            color="calendarGreen"
                            fontWeight={500}
                            fontSize="16px"
                            lineHeight="18px"
                            fontFamily={fonts.montserrat}
                        >
                            {secondsToMinutes(timeLeft)}
                        </Text>
                    </Flex>
                )}
                {request?.mediaFileModels?.length !== 0 ? (
                    <Flex ml="auto" mr="10px">
                        <AvatarGroup size="sm" max={3}>
                            {request?.mediaFileModels?.map(photo => (
                                <Avatar
                                    key={photo.id}
                                    border="none"
                                    marginInlineStart="0rem"
                                    name="User"
                                    src={getAvatarPicture(photo)}
                                />
                            ))}
                        </AvatarGroup>
                    </Flex>
                ) : null}
                <IconButton
                    onClick={onOpenModal}
                    bg="transparent"
                    alignSelf="center"
                    aria-label="More for pending request"
                    icon={
                        <chakra.span transform="rotate(-90deg)">
                            <DropdownArrowIcon />
                        </chakra.span>
                    }
                />
            </Flex>
        </Box>
    );
};
