import React from 'react';
import { Box, Text, Select, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { DropdownArrowIcon } from '../../../../assets/icons';
import { ItemType, States } from '../../../types';
import { fonts } from '../../../utils';

interface ICustomDropdownProps {
    options: ItemType[] | States[] | Array<{ value: number | string; label: string }>;
    label: string;
    width?: string;
    margin?: string;
    register?: any;
    errors?: any;
    onChange?: any;
    placeholder?: string;
    value?: string;
    fontFamily?: string;
    labelSize?: string;
    fontSize?: string;
    height?: string;
    labelWeigth?: number;
    bgColor?: string;
    icon?: JSX.Element;
    borderRadius?: string;
    color?: string;
    disabled?: boolean;
    capitalize?: boolean;
}

const CustomDropdown: React.FC<ICustomDropdownProps> = ({
    options,
    label,
    width,
    margin,
    register,
    errors,
    onChange,
    placeholder,
    value,
    borderRadius = '80px',
    fontFamily = fonts.poppins,
    labelSize = '13px',
    fontSize = '13px',
    height = '50px',
    labelWeigth = 400,
    bgColor = 'inputBackground',
    color = 'brand',
    icon = <DropdownArrowIcon />,
    disabled = false,
    capitalize = true,
}) => (
    <Box w={width} textAlign="left" color="#fff" m={margin}>
        <FormControl isInvalid={!!errors}>
            <Text
                textTransform={capitalize ? 'capitalize' : 'none'}
                color={color}
                fontWeight={labelWeigth}
                fontSize={labelSize}
                fontFamily={fontFamily}
            >
                {label}
            </Text>
            <Select
                h={height}
                bg={bgColor}
                borderRadius={borderRadius}
                color={color}
                fontSize={fontSize}
                icon={disabled || register.isReadOnly ? undefined : icon}
                fontFamily={fontFamily}
                // defaultValue={options?.[0]}
                placeholder={placeholder}
                onChange={onChange ? v => onChange(v.target.value) : () => null}
                {...register}
                disabled={disabled}
            >
                {options?.map((item: any, index: number) => (
                    <option key={String(item.value) + String(index)} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </Select>
            {errors && <FormErrorMessage fontSize={labelSize}>{errors.message}</FormErrorMessage>}
        </FormControl>
    </Box>
);

export { CustomDropdown };
