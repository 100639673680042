import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const commentSchema =  yup.object().shape({
  comment: yup
    .string()
    .required('This field is required')
    .test('comment', 'This field is required', function (value) {
      return !(value && value.replace(/<(.|\n)*?>/g, '').trim().length === 0);
    }),
});

export default yupResolver(commentSchema);