/* eslint-disable prefer-arrow-callback */
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from '../../../../common/schemes/messages';

const RequestWithdrawSchema = yup.object().shape({
    amount: yup
        .string()
        .default('')
        .test('required', ErrorMessages.REQUIRED, function (value?: string) {
            value = (value === undefined || value === '')? '0' : value;
            let valueInt = parseInt(value);
            
            return (valueInt > 1)? true : false;
        }),
    company_name: yup.string().required(ErrorMessages.REQUIRED),
    account_number: yup.string().required(ErrorMessages.REQUIRED),
});

export const RequestWithdrawResolver = yupResolver(RequestWithdrawSchema);
