import { createSlice } from '@reduxjs/toolkit';
import { PayloadError, AgentModelsType } from '../../common/types';
import { useHistory } from 'react-router-dom';

import { isAnyOf } from '../../common/utils';
import { logout } from '../actions/auth/auth.actions';
import {
    //   findAssistance,
    //   findAssistanceError,
    //   findAssistanceSuccess,
    getCurrentStates,
    getCurrentStatesError,
    getCurrentStatesSuccess,
} from '../actions/order.actions';
import {
    findAssistanceRequest,
    findAssistanceSuccess,
    findAssistanceError,
} from '../../pages/FindAssistance/store/actions';

type InitialState = {
    loading: boolean;
    states: Array<string>;
    errors: PayloadError[];
    assistanceLoading: boolean;
    agentModels: Array<AgentModelsType>;
};

export const initialState: InitialState = {
    loading: false,
    errors: [],
    states: [],
    agentModels: [],
    assistanceLoading: false,
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            // .addCase(getCurrentStates, state => {
            //     state.loading = true;
            //     state.errors = [];
            // })
            .addCase(getCurrentStatesSuccess, (state, action) => {
                state.states = action.payload.value.states;
                // state.loading = false;
                // state.errors = [];
            })
            .addCase(getCurrentStatesError, (state, action) => {
                // state.loading = false;
                state.errors = action.payload;
            })

            .addCase(findAssistanceRequest, state => {
                state.assistanceLoading = true;
                // state.errors = [];
            })
            .addCase(findAssistanceSuccess, (state, action) => {
                console.log('ASSIS SUCCESS!!!!!!!');
                state.assistanceLoading = false;
                location.href = '/requests';

                // state.errors = [];
            })
            .addCase(findAssistanceError, (state, action) => {
                state.assistanceLoading = false;
                // state.errors = action.payload;
            })

            .addMatcher(isAnyOf(logout), state => {
                state.loading = false;
                state.errors = [];
                state.states = [];
                state.agentModels = [];
            });
    },
});

export default orderSlice.reducer;
