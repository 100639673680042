import React, { useEffect, useState } from 'react';

import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Flex,
    Button,
    CloseButton,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { useHistory } from 'react-router-dom';
import { InfoIcon } from '@chakra-ui/icons';
import { ConfirmPhoneNumber } from './ConfirmPhoneNumber';

export const UserWarningPanel: React.FC = () => {

    const { user: currentUser } = useSelector((state: RootStore) => state.user);
    const [isAllow, setIsAllow] = useState(false);

    const history = useHistory();

    useEffect(() => {
      setTimeout(() => {
        setIsAllow(true);
      }, 2000)
    }, []); 

    return (
      <>
        {isAllow && (
          <div className="user_warning_pane">
            {(!currentUser.issetLicenses && currentUser.id)? (
              <div className="flex-center">
                <div className="info_icon_outer">
                <InfoIcon />
                </div>

                <span onClick={() => {
                  history.push('/settings/licenses');
                  }}>
                  Please Add a license
                </span>
              </div>
            ): ''}
            {(currentUser.issetLicenses && currentUser.id && !currentUser.phone_number_confirmed)? (
              <div className="flex-center">
                <div className="info_icon_outer">
                <InfoIcon />
                </div>
                <ConfirmPhoneNumber>
                  Confirm phone number
                </ConfirmPhoneNumber>
              </div>
            ): ''}
          </div>
        )}
      </>
    );
};
