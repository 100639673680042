import React, { useState, useEffect } from 'react';
import { useDelta } from 'react-delta';
import { useDispatch, useSelector } from 'react-redux';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Box,
    Text,
} from '@chakra-ui/react';

import { CloseButton } from '../../../../assets/icons';

import { OtherForm } from './forms/Other.form';
import { ReferralForm, RestForm, ShowingForm } from './forms';

import { RootStore } from '../../../../store';
import {
    getStates,
    getBonusTypes,
    getAllAddresses,
} from '../../../../store/actions/directories.actions';
import { getCurrentStates } from '../../../../store/actions/order.actions';
import { createJobPostRequest, getReferralOpportunityAgenciesRequest } from '../../store/actions';
import { setConnectPaymentModalOpen } from '../../../Notifications/store/actions';

import { CreateJobPostPayload } from '../../types';
import { FilterState } from '../../../../common/types/dashboard.types';

import { colors } from '../../../../common/constants';
import { DASHBOARD_REQUEST_PAGE_SIZE } from '../../../../common/constants/dashboard.constants';
import useLocalStorage from '../../../../common/helpers/hooks/useLocalStorage/useLocalStorage';
import { getForDays } from '../../../../common/utils/getForDays';
import { useHistory } from 'react-router-dom';
import { IAddressess } from '../../../../common/types';

interface ICreateNewPostModal {
    isOpen: boolean;
    onClose: () => void;
    getItems: (page?: number) => void;
}

export const CreateNewPostModal: React.FC<ICreateNewPostModal> = ({
    isOpen,
    onClose,
    getItems,
}) => {
    const dispatch = useDispatch();
    const [requestValue, setRequestValue] = useState('1');

    const history = useHistory();

    const [filterState] = useLocalStorage<FilterState>('filterState', {
        typeIds: [],
        categoryIds: [],
        openHouseTypeIds: [],
        forDays: [],
        sortingType: 0,
    });

    useEffect(() => {
        dispatch(getAllAddresses());
    }, []);

    const allAddresses : Array<IAddressess> = useSelector((state: RootStore) => state.directories.allAddresses);

    const { pageNumber: currentPage } = useSelector((state: RootStore) => state.dashboard.dashboardRequest);

    const { states: currentStates } = useSelector((state: RootStore) => state.order);
    const { states: statesType, agenciesTypes } = useSelector(
        (state: RootStore) => state.directories,
    );
    const { loading: createPostLoading, errors: createPostErrors } = useSelector(
        (state: RootStore) => state.opportunities.createJobPost,
    );
    const { agenciesForState } = useSelector((state: RootStore) => state.opportunities);

    const roles = useSelector((state: RootStore) => state.auth.user.roles);

    const isAccessAllowed = roles?.some((role: string) => (role === 'user' || role === 'newuser'));

    const onGetReferralOpportunityAgenciesRequest = (id: number) =>
        dispatch(getReferralOpportunityAgenciesRequest(id));
    const createPostLoadingDelta = useDelta(createPostLoading);

    useEffect(() => {
        dispatch(getCurrentStates());
        dispatch(getStates());
        // dispatch(getAgenciesTypesRequest());
        dispatch(getBonusTypes());
    }, []);

    useEffect(() => {
        if (createPostLoadingDelta && createPostLoadingDelta.prev) {
            if (
                createPostLoadingDelta.prev &&
                !createPostLoadingDelta.curr &&
                !createPostErrors.length
            ) {
                onClose();
                getItems(1);
            }
        }
    }, [createPostLoadingDelta]);

    const onCreatePost = (payload: CreateJobPostPayload) => {
        if (!isAccessAllowed) {
            // dispatch(setConnectPaymentModalOpen({ isOpen: true }));
            return;
        }
        dispatch(createJobPostRequest({data: payload, dashboardRequestPayload: {
            ...filterState,
            forDays: getForDays(filterState.forDays),
            pageNumber: currentPage,
            pageSize: DASHBOARD_REQUEST_PAGE_SIZE,
            isMine: false,
        }}));

        //history.push('/requests');
    };

    const getForm = () => {
        switch (requestValue) {
            case '1':
                return (
                    <ShowingForm
                        allAddresses={allAddresses}
                        statesType={statesType}
                        currentStates={currentStates}
                        requestValue={requestValue}
                        onCreatePost={onCreatePost}
                        createPostLoading={createPostLoading}
                        setRequestValue={setRequestValue}
                    />
                );
            case '2':
            case '3':
            case '5':
                return (
                    <RestForm
                        allAddresses={allAddresses}
                        statesType={statesType}
                        currentStates={currentStates}
                        requestValue={requestValue}
                        onCreatePost={onCreatePost}
                        createPostLoading={createPostLoading}
                        setRequestValue={setRequestValue}
                    />
                );
            case '4':
                return (
                    <OtherForm
                        allAddresses={allAddresses}
                        statesType={statesType}
                        requestValue={requestValue}
                        createPostLoading={createPostLoading}
                        setRequestValue={setRequestValue}
                        currentStates={currentStates}
                        onCreatePost={onCreatePost}
                    />
                );
            case '7':
                return (
                    <ReferralForm
                        agenciesTypes={agenciesTypes}
                        statesType={statesType}
                        requestValue={requestValue}
                        onCreatePost={onCreatePost}
                        createPostLoading={createPostLoading}
                        setRequestValue={setRequestValue}
                        onGetReferralOpportunityAgenciesRequest={
                            onGetReferralOpportunityAgenciesRequest
                        }
                        agenciesForState={agenciesForState}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="5xl">
            <ModalOverlay />
            <ModalContent
                maxHeight="90vh"
                borderRadius="18px"
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <ModalHeader
                    d="flex"
                    w="800px"
                    justifyContent="space-between"
                    flexDirection="row"
                    mt="20px"
                >
                    <Text color="brand">New Opportunity</Text>
                    <Box as="button" onClick={() => onClose()}>
                        <CloseButton />
                    </Box>
                </ModalHeader>
                <ModalBody overflowY="auto">{getForm()}</ModalBody>
            </ModalContent>
        </Modal>
    );
};
