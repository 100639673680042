import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const requestDemoSchema = yup.object().shape({
    email: yup.string().required(ErrorMessages.REQUIRED).email(ErrorMessages.EMAIL_INVALID),
    firstName: yup.string().required(ErrorMessages.REQUIRED),
    lastName: yup.string().required(ErrorMessages.REQUIRED),
    agencyId: yup.mixed().test('required', ErrorMessages.REQUIRED, value => Boolean(value)),
    helpTypeId: yup.string().required(ErrorMessages.REQUIRED),
});

export const requestDemoResolver = yupResolver(requestDemoSchema);
