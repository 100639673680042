import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkIcon, MoreIcon, RightArrowIcon, SearchIconLight } from '../../../../assets/icons';
import { GetAgentsBySearchPayload, ToggleUserProfileModalPayload } from '../../../types';
import { useDebounce } from '../../../utils';
import { SearchUsersModal } from './components/SearchUsersModal';
import { CustomInput } from '../CustomInput/CustomInput';
import { getAgentsBySearch, resetSearchedAgents } from './store/actions';
import { RootStore } from '../../../../store';
import { toggleUserProfileModal } from '../../../../store/actions/profile.actions';
import { Box } from '@chakra-ui/react';

export const SearchUsersContainer: React.FC = () => {
    const dispatch = useDispatch();

    const onGetAgentsBySearch = (data: GetAgentsBySearchPayload) =>
        dispatch(getAgentsBySearch(data));
    const onResetSearchedAgents = () => dispatch(resetSearchedAgents());
    const onOpenProfileImage = (payload: ToggleUserProfileModalPayload) => {
        dispatch(toggleUserProfileModal(payload));
    };

    const { agents, loading, totalCount, loadMoreLoading } = useSelector(
        (state: RootStore) => state.searchUsers,
    );

    const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchValue2, setSearchValue2] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [onEndReachedCalled, setOnEndReachedCalled] = useState(false);

    const debounceSearchValue = useDebounce(searchValue, 500);

    const openSearchModal = () => {
        setIsOpenSearchModal(true);
        onGetAgentsBySearch({
            SearchQuery: searchValue,
            PageNumber: 1,
            PageSize: 10,
        });
    };

    useEffect(() => {
        /*
        if (debounceSearchValue.length >= 3) {
            onGetAgentsBySearch({
                SearchQuery: debounceSearchValue,
                PageNumber: currentPage,
                PageSize: 10,
            });
            setIsOpenSearchModal(true);
        } else {
            setCurrentPage(1);
        }
        */
    }, [debounceSearchValue, currentPage]);

    const loadMoreItems = () => {
        if (agents.length >= totalCount) {
            setOnEndReachedCalled(true);
        } else if (!onEndReachedCalled) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <>
            <CustomInput
                rightIcon={<Box onClick={() => {
                    openSearchModal();
                }} cursor="pointer"><RightArrowIcon /></Box>}
                leftIcon={<SearchIconLight />}
                placeholder="Search for Agents"
                value={searchValue}
                onChange={e => { 
                    setSearchValue(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        openSearchModal();
                    }
                }}
                bgColor="brand"
                color="#888DAB"
                fontWeight={400}
                fontSize="16px"
                textSize="16px"
                width="225px"
                borderRadius="20px"
                props={{ borderColor: '#888DAB' }}
            />
            <SearchUsersModal
                isOpen={isOpenSearchModal}
                onClose={() => {
                    setSearchValue('');
                    onResetSearchedAgents();
                    // setCurrentPage(1);
                    setOnEndReachedCalled(false);
                    setIsOpenSearchModal(false);
                }}
                loading={loading}
                agents={agents}
                loadMoreItems={loadMoreItems}
                loadMoreLoading={loadMoreLoading}
                onOpenProfileImage={payload => {
                    setSearchValue('');
                    onResetSearchedAgents();
                    // setCurrentPage(1);
                    setOnEndReachedCalled(false);
                    setIsOpenSearchModal(false);
                    onOpenProfileImage(payload);
                }}
            />
        </>
    );
};
