import React from 'react';
import { Box, Text, Button, Link, Flex } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { fonts } from '../../../utils';
import { SmallPassword } from '../../../../assets/icons';

interface ICustomButtonProps {
    text: string;
    body?: string;
    icon?: React.ReactElement;
    type?: 'submit' | 'reset' | 'button';
    width?: string;
    height?: string;
    bgColor?: string;
    onClick?: () => void;
    asLink?: boolean;
    href?: string;
    isAccessAllowed: boolean;
    unreads?: number;
    classN?: string;
}

const SidebarButton: React.FC<ICustomButtonProps> = ({
    classN = '',
    text,
    icon,
    type = 'button',
    width = '250px',
    height = '70px',
    bgColor = '#FFFFFF',
    onClick,
    body,
    asLink,
    href,
    isAccessAllowed,
    unreads = 0,
}) => {

    return (
    <Button
        className={'fix_sidebar_button '+classN}
        _disabled={{
            background: '#FFFFFF',
        }}
        overflow={'hidden'}
        h={height}
        type={type}
        borderRadius="20px"
        bg={bgColor}
        w={width}
        onClick={onClick}
        leftIcon={icon}
        justifyContent="flex-start"
        textColor="brand"
        as={asLink && isAccessAllowed ? NavLink : undefined}
        to={href || ''}
        _hover={{
            textDecoration: 'none',
            background: '#FFFFFF',
        }}
        disabled={!isAccessAllowed}
        >
        <Box d="flex" flexDirection="column" alignItems="flex-start" className="box_relative">
            <Text fontWeight={600} fontFamily={fonts.poppins} fontSize="16px">
                {text}
            </Text>
            <Text
                fontWeight={300}
                fontFamily={fonts.poppins}
                fontSize="11px"
                whiteSpace="pre-line"
                textAlign="start"
            >
                {body}
            </Text>
            {(unreads > 0) && (
                <>
                    <div className="unread_info">
                        { unreads? unreads : '' }
                    </div>
                </>
                )}
        </Box>
        {/*bg overlay*/}
        {!isAccessAllowed && (
            <Flex
                style={{
                    backdropFilter: 'blur(3px)',
                }}
                justifyContent={'center'}
                alignItems={'center'}
                right={0}
                left={0}
                h={'100%'}
                w={'100%'}
                position={'absolute'}
            >
                <SmallPassword />
            </Flex>
        )}
    </Button>
)};

export { SidebarButton };
