import React from 'react';
    
import { Flex } from '@chakra-ui/react';

import { SignUpVerificationPopUpProps } from '../../types';
import { MoodSmile } from '../../../../../assets/icons';

import { Typography } from '../../../../../common/components/blocks/Typography/Typography';

export const SignUpVerificationPopUp: React.FC<SignUpVerificationPopUpProps> = ({ nestedPath }) => (
    <Flex justifyContent={'center'} alignItems={'center'} mt={'150px'}>
        <Flex
            justifyContent={'center'}
            alignItems={'center'}
            borderRadius={20}
            backgroundColor="brand"
            w={{ base: '90%', lg: '32vw' }}
        >
            <Flex w={'278px'} flexDirection={'column'} alignItems={'center'}>
                <MoodSmile />
                <Typography mt={'10px'} weight={600} color={'white'} size={25}>
                    Congratulations!
                </Typography>
                <Typography mt={'10px'} textAlign={'center'} color={'white'} size={16}>
                You have completed registration, and you will receive a Welcome email 
                once your account details are verified in the next 24-48 hours.
                </Typography>
            </Flex>
        </Flex>
    </Flex>
);
