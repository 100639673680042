import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from '@twilio/conversations/lib/message';
import { Conversation } from '@twilio/conversations/lib/conversation';
import io from 'socket.io-client';
import MediaQuery from 'react-responsive';

import {
    ButtonGroup,
    Box,
    Divider,
    Avatar,
    MenuButton,
    Button,
    MenuList,
    MenuItem,
    Menu,
    Text,
    Container,
    Flex,
} from '@chakra-ui/react';

import { ButtonWithNotification } from '../ButtonWithNotification/ButtonWithNotification';
import { LogoIconDashboard } from '../../../../assets/icons/LogoDashboard';
import { SearchUsersContainer } from '../SearchUsers/SearchUsersContainer';
import { NotificationsPortal, AgenciesDropdown } from './components';

import { RootStore } from '../../../../store';
import { logout } from '../../../../store/actions/auth/auth.actions';
import { toggleUserProfileModal } from '../../../../store/actions/profile.actions';
import { setCurrentAgencyRequest } from '../../../../pages/Sidebar/store/actions';
import { getUnreadsData, getUserAvatarRequest, getUserInfoRequest } from '../../../../store/actions/user.actions';

import { SetCurrentAgencyPayloadType } from '../../../../pages/Sidebar/types';
import { UserAvatarPayload } from '../../../types';

import { getAvatarPicture } from '../../../../common/utils';
import { getTwillioToken } from '../../../../pages/Messages/utils';

import { Home, Messages, LogoutIcon } from '../../../../assets/icons';
import { HOMEPAGE_PATH } from '../../../constants';
import { formatPrice } from '../../aglobal/utils';
import { getAllAgenciesTypesRequest } from '../../../../store/actions/directories.actions';
import { FirstNameLastNameModal } from '../../aglobal/FirstNameLastNameModal';
import { UserWarningPanel } from '../../aglobal/UserWarningPanel';
import { MobileTopPanel } from './MobileTopPanel';
import { ChatOffMarket } from './components/ChatOffMarket';

const Conversations = require('@twilio/conversations');

export const DashboardHeader: React.FC = () => {
    const dispatch = useDispatch();
    const onLogout = () => dispatch(logout());
    const onSetCurrentAgency = (payload: SetCurrentAgencyPayloadType) =>
        dispatch(setCurrentAgencyRequest(payload));
    const getUserPhoto = (payload: UserAvatarPayload) => {
        //console.log('-->PHOTO', payload);


        dispatch(getUserAvatarRequest(payload));
    };
    const { user: currentUser, loading } = useSelector((state: RootStore) => state.user);


    const userAvatar = useSelector((state: RootStore) => state.user.userPhoto);
    const agencies = useSelector((state: RootStore) => state.user.user.agencies);
    const token = useSelector((state: RootStore) => state.auth.user.token.accessToken);
    const currentAgency = useSelector((state: RootStore) => state.user.user.currentAgency);
    const {requests, activity} = useSelector((state: RootStore) => state.user.unreads);

    useEffect(() => {
    }, [requests]);

    useEffect(() => {
        if(window.socket){
            return;
        }
        window.io = io;
        window.socket = window.io.connect(window.baseUrl+':3099?access_token='+token, {
            transports: ['websocket'],
            withCredentials: true,
        });

        window.socket.off('update_unread_chat');
        window.socket.on('update_unread_chat', () => {
            dispatch(getUnreadsData());
        });

        dispatch(getUnreadsData());
    }, []);
    
    const { value: accountStatus } = useSelector(
        (state: RootStore) => state.payments.getCustomerAccountStatus,
    );
    const roles = useSelector((state: RootStore) => state.auth.user.roles);

    const isAccessAllowed =
        roles?.some((role: string) => (role === 'user' || role === 'newuser')) && accountStatus === 'verified';

    const history = useHistory();
    const handleSettingsClick = () => history.push('/settings');
    const handleSettingsHelp = () => history.push('/settings/support');

    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [messageNotification, setMessageNotification] = useState<boolean>(false);


    /*
    const initConversations = async (token: string) => {
        try {
            const conversationsClient = await Conversations.Client.create(token);
            conversationsClient.on('conversationJoined', (conversation: Conversation) => {
                setConversations((prevState: Conversation[]) => {
                    return [...prevState, conversation];
                });
            });
            conversationsClient.on('conversationUpdated', (conversation: any) => {
                setConversations(prevState => [...prevState, conversation.conversation]);
            });
        } catch (error) {
            console.log('error', error);
        }
    };
    */

    useEffect(() => {
        dispatch(getUserInfoRequest());
    }, []);

    useEffect(() => {
        if (currentUser.id !== 0) {
            getUserPhoto(currentUser.id);
        }
    }, [currentUser.id]);

    useEffect(() => {
        //getTwillioToken(token, initConversations).catch(console.log);
    }, []);

    /*
    useEffect(() => {
        try{
            conversations.forEach((item: Conversation) => {
                item.getMessages()
                    .then((messagePaginator: any) => {
                        const message = messagePaginator.items[messagePaginator.items.length - 1];
                        item.getUnreadMessagesCount().then((res: number | null) => {
                            if (Number(message?.author) !== currentUser.id) {
                                setMessageNotification(Boolean(res));
                            }
                        });
                    })
                    .catch((err: any) => {
                        console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
                    });
                item.on('messageAdded', (m: Message) => {
                    if (Number(m?.author) !== currentUser.id) {
                        setMessageNotification(true);
                    }
                });
            });
            
        }catch(error){
            console.log('twilio error', error);
        }
    }, [conversations]);
    */

    const fetchProfile = () => {
        console.log('FETCH PROFILE');
        dispatch(getUserInfoRequest());
    };


    //console.log('CURRENCT USER', currentUser);

    const avatarName =
        !loading && !userAvatar.avatar?.itemHash
            ? currentUser.firstName + ' ' + currentUser.lastName
            : undefined;

    useEffect(() => {
        if(!currentUser || !currentUser.id){
            return;
        }
        let isSub = currentUser.isActiveSubscribe;

        if(!isSub && location.pathname.indexOf('/settings') == -1){
            location.href = '/settings/subscription';
        }

    }, [currentUser]);


    useEffect(() => {
        dispatch(getAllAgenciesTypesRequest());
    }, []);

    return (
        <>
        
            <Container
                className="DASHBOARD_HEADER_COMPONENT"
                display="flex"
                maxW="container.xl"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                minHeight="80px"
                h="80px"
                >
                <MediaQuery maxDeviceWidth={1120}>
                    <MobileTopPanel />
                </MediaQuery>
                <MediaQuery minDeviceWidth={1121}>
                    <NavLink to={HOMEPAGE_PATH} replace>
                        <LogoIconDashboard />
                    </NavLink>
                    <ButtonGroup spacing="25px" d="flex" flexDirection="row" alignItems="center">
                        <FirstNameLastNameModal

                        />
                        <UserWarningPanel 
                        
                        />
                        <SearchUsersContainer />
                        <NavLink to={HOMEPAGE_PATH}>
                            <ButtonWithNotification buttonIcon={<Home width="20" height="20" />} />
                        </NavLink>
                        <NavLink to="/messages/none">
                            <ButtonWithNotification
                                buttonIcon={<Messages width="20" height="20" />}
                                notification={messageNotification}
                            />
                        </NavLink>
                        <NotificationsPortal isAccessAllowed={isAccessAllowed} />
                        <ChatOffMarket />
                        <div 
                            className="currentBalance"
                            style={{color: "white"}} 
                            onClick={fetchProfile}
                            >
                            ${formatPrice(currentUser.balance)}
                        </div>
                        <Menu id="headerMenu">
                            <MenuButton className="MENU_TOP" as={Button} rounded={'full'} variant={'link'} minW={0}>
                                <Avatar
                                    h="50px"
                                    w="50px"
                                    src={getAvatarPicture(userAvatar.avatar)}
                                    name={avatarName}
                                />
                            </MenuButton>
                            <MenuList w="300px" zIndex={10}>
                                <MenuItem
                                    justifyContent="flex-start"
                                    px="20px"
                                    onClick={() =>
                                        dispatch(
                                            toggleUserProfileModal({
                                                value: true,
                                                userId: currentUser.id,
                                            }),
                                        )
                                    }
                                >
                                    <Avatar
                                        h="50px"
                                        w="50px"
                                        src={getAvatarPicture(userAvatar.avatar)}
                                        name={currentUser.firstName + ' ' + currentUser.lastName}
                                    />
                                    <Text
                                        ml="10px"
                                        fontSize="16px"
                                        color="brand"
                                        textShadow="0px 3px 10px"
                                    >
                                        {currentUser.firstName + ' ' + currentUser.lastName}
                                    </Text>
                                </MenuItem>
                                <Flex justifyContent="center" alignItems="center" mt="10px" px="20px">
                                    <AgenciesDropdown
                                        width="100%"
                                        maxW="100%"
                                        height="50px"
                                        border="1px solid #D1D2DB"
                                        borderRadius={'15px'}
                                        options={agencies}
                                        token={token}
                                        onSetCurrentAgency={onSetCurrentAgency}
                                        currentAgency={currentAgency}
                                    />
                                </Flex>
                                <MenuItem
                                    onClick={handleSettingsClick}
                                    mt="20px"
                                    _hover={{
                                        color: '#EB5757',
                                    }}
                                    fontSize="15px"
                                    color="brand"
                                    px="20px"
                                >
                                    Profile Settings
                                </MenuItem>
                                <MenuItem
                                    onClick={handleSettingsHelp}
                                    _hover={{
                                        color: '#EB5757',
                                    }}
                                    fontSize="15px"
                                    color="brand"
                                    px="20px"
                                >
                                    Help
                                </MenuItem>
                                <MenuItem onClick={onLogout} fontSize="15px" color="#EB5757" px="20px">
                                    Log Out
                                    <Box ml={2}>
                                        <LogoutIcon />
                                    </Box>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </ButtonGroup>
                </MediaQuery>
            </Container>
            <Divider />
        </>
    );
};
