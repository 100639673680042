import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Flex, Avatar, HStack, Text, chakra, IconButton } from '@chakra-ui/react';
import dayjs from 'dayjs';
import pluralize from 'pluralize';

import { getAvatarPicture, fonts, convertTimeIn12Hours } from '../../../common/utils';
import { toggleUserProfileModal } from '../../../store/actions/profile.actions';

import { colors } from '../../../common/constants';

import { RootStore } from '../../../store';

import {
    LocationIcon,
    DropdownArrowIcon,
    ConsultMethodIcon,
    Home,
    Calendar,
    Clock,
} from '../../../assets/icons';
import { BorderIconBox } from './BorderIconBox';

import { SentRequestType } from '../types';
import { IdNameTypes } from '../../../common/types';
import { StatusActivityItem } from '../../../common/components/aglobal/StatusActivityItem';
import { setReadRequest } from '../store/actions';

interface ISentRequestItemProps extends SentRequestType {
    onOpenModal: (value: number) => void;
    elem?: any;
}

export const SentRequestItem: React.FC<ISentRequestItemProps> = ({
    elem,
    addresses,
    categoryId,
    dateTime,
    duration,
    onOpenModal,
    ownerAvatar,
    ownerId,
    id,
    propertiesToShow,
    requestTypeName,
    timeSlot,
    typeId,
    state,
    timeZone,
}) => {
    const dispatch = useDispatch();
    const requestCategories = useSelector(
        (state: RootStore) => state.directories.requestCategories,
    );
    const category = requestCategories?.filter((item: IdNameTypes) => item.id === categoryId)[0]
        ?.name;
    return (
        <>
            <Text color="#4FD1C5" fontWeight={500} fontSize="15px" lineHeight="18px">
                {category}
            </Text>
            <Flex h="90px" bg="inputBackground" borderRadius="14px" px="25px" mb="20px" mt="10px">
                <HStack align="flex-start" alignSelf="center" width="40%" maxW="300px">
                    <Avatar
                        cursor="pointer"
                        src={getAvatarPicture(ownerAvatar)}
                        w="30px"
                        h="30px"
                        onClick={() => {
                            dispatch(
                                toggleUserProfileModal({
                                    value: true,
                                    userId: ownerId,
                                }),
                            );
                        }}
                    />
                    <Box>
                        {elem && (
                            <StatusActivityItem 
                                elem={elem}
                            />
                        )}
                        <Text color="brand" fontSize="15px" lineHeight="18px" fontWeight={600}>
                            {requestTypeName}
                        </Text>
                        <Flex alignItems="center" mt={1}>
                            <LocationIcon />
                            <Text
                                ml={1}
                                color="brand"
                                fontSize="12px"
                                lineHeight="16px"
                                fontWeight={500}
                                noOfLines={1}
                            >
                                {addresses?.join('')}
                            </Text>
                        </Flex>
                    </Box>
                </HStack>
                <Flex>
                    {' '}
                    {typeId === 6 ? (
                        <>
                            <BorderIconBox
                                icon={<Calendar />}
                                text={dayjs.utc(timeSlot.date).format('MM/DD/YY')}
                                isFirst
                            />
                            <BorderIconBox
                                icon={<Clock />}
                                text={convertTimeIn12Hours(timeSlot.startTime, timeZone)}
                            />
                            <BorderIconBox
                                icon={<Clock />}
                                text={convertTimeIn12Hours(timeSlot.endTime, timeZone)}
                            />
                        </>
                    ) : typeId === 7 ? (
                        <BorderIconBox
                            icon={
                                <Text
                                    color="brand"
                                    fontSize="12px"
                                    fontWeight="500"
                                    fontFamily={fonts.montserrat}
                                >
                                    State:
                                </Text>
                            }
                            text={state}
                            isFirst
                        />
                    ) : (
                        <>
                            <BorderIconBox
                                icon={<Calendar />}
                                text={dayjs.utc(dateTime).format('MM/DD/YYYY')}
                                isFirst
                            />
                            <BorderIconBox
                                icon={<Clock />}
                                text={dayjs(dateTime).format('hh:mm A')}
                            />
                            {typeId !== 4 && (
                                <BorderIconBox
                                    icon={
                                        typeId === 1 ? (
                                            <Home color={colors.brand} width="16" height="16" />
                                        ) : (
                                            <ConsultMethodIcon />
                                        )
                                    }
                                    text={
                                        typeId === 1
                                            ? `${propertiesToShow} ${pluralize(
                                                  'house',
                                                  propertiesToShow,
                                              )}`
                                            : `${duration} ${pluralize('hour', duration)}`
                                    }
                                />
                            )}
                        </>
                    )}
                </Flex>
                <IconButton
                    onClick={() => {
                        dispatch(setReadRequest(id));
                        onOpenModal(id)
                    }}
                    bg="transparent"
                    ml="auto"
                    alignSelf="center"
                    aria-label="More for job"
                    icon={
                        <chakra.span transform="rotate(-90deg)">
                            <DropdownArrowIcon />
                        </chakra.span>
                    }
                />
            </Flex>
        </>
    );
};
