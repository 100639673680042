import React, { useEffect } from 'react';
import * as yup from 'yup';
import {
    Modal,
    ModalContent,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    Box,
    CloseButton,
    Flex,
    Button,
    VStack,
} from '@chakra-ui/react';
import { AgentItem } from './components/AgentItem';
import { TypeAhead } from '../../../../blocks';
import { colors } from '../../../../../../common/constants';
import {
    ToggleUserProfileModalPayload,
    SearchAgentByAreaPayload,
    SelectValue,
    SearchAgentByAreaResponse,
} from '../../../../../../common/types';
import { Typography } from '../../../../blocks/Typography/Typography';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from '../../../../../schemes/messages';
import { Resolver, useForm } from 'react-hook-form';

const areasResolver = yupResolver(
    yup.object().shape({
        cityId: yup.string().required(ErrorMessages.REQUIRED),
    }),
);

interface ISearchAgentByAreaModal {
    setModalVisible: () => void;
    modalVisible: boolean;
    onSearchAgentsByArea: (payload: SearchAgentByAreaPayload) => void;
    onOpenProfileImage: (payload: ToggleUserProfileModalPayload) => void;
    userId: number;
    getCities: (value: string) => Promise<SelectValue[]>;
    loading: boolean;
    items: SearchAgentByAreaResponse[];
    onClearAvailableAgents: () => void;
}

export const SearchAgentByAreaModal: React.FC<ISearchAgentByAreaModal> = ({
    modalVisible,
    setModalVisible,
    onSearchAgentsByArea,
    onOpenProfileImage,
    userId,
    getCities,
    loading,
    items,
    onClearAvailableAgents,
}) => {
    const {
        setValue,
        formState: { errors },
        clearErrors,
        handleSubmit,
        register,
    } = useForm<{ cityId: string }>({
        resolver: areasResolver as Resolver<{ cityId: string }>,
    });

    const closeModal = () => {
        onClearAvailableAgents();
        setValue('cityId', '');
        clearErrors('cityId');
        setModalVisible();
    };

    return (
        <Modal isOpen={modalVisible} onClose={closeModal} size="3xl">
            <ModalOverlay />
            <ModalContent
                borderRadius={'18px'}
                h={'85vh'}
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <Box d="flex" alignItems="center" justifyContent="space-between" w="100%" px={20}>
                    <ModalHeader flex={1} px="0">
                        <Typography size={22} weight={700}>
                            Search Results For Area Name
                        </Typography>
                    </ModalHeader>
                    <CloseButton onClick={closeModal} />
                </Box>
                <ModalBody d="flex" flexDirection="column" overflowY="auto" w="100%" px={20}>
                    <Flex justifyContent="space-between" alignItems="center">
                        <TypeAhead
                            onStateChange={ev => {
                                console.log(ev, 'onStateChange');
                                if (ev?.value) {
                                    setValue('cityId', ev.value);
                                    clearErrors('cityId');
                                }
                            }}
                            placeholder={'e.g. ‘Downtown Los Angeles’'}
                            label={'Area Name'}
                            loadOptions={getCities}
                            errors={errors.cityId?.message}
                        />
                        <Button
                            isLoading={loading}
                            ml={'32px'}
                            w={'190px'}
                            h={'64px'}
                            variant={'primary'}
                            onClick={handleSubmit(data => {
                                onSearchAgentsByArea({ userId, cityId: Number(data.cityId) });
                            })}
                        >
                            Search
                        </Button>
                    </Flex>
                    <Typography my="10px" size={20} lineHeight={'30px'}>
                        Available Agents
                    </Typography>
                    <VStack spacing={'13px'} align={'stretch'}>
                        {items.map(item => (
                            <AgentItem
                                key={item.id}
                                item={item}
                                onOpenProfileImage={onOpenProfileImage}
                            />
                        ))}
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
