import { call, put, takeEvery } from 'redux-saga/effects';
import API from '../../common/api/api.base';
import { createChat, getChatUserInfo, getChatUserInfoSuccess, getChatUserInfoError } from '../actions/messages.actions';
import { push } from 'connected-react-router';
import {
    toggleUserProfileModal
} from '../actions/profile.actions';

type mockType = {
    value: string,
    success: boolean,
    errors: any,
    messages: any
}

function* workerCreateChat(action: ReturnType<typeof createChat>) {
    try {
        const result: mockType = yield call(API.post, `/api/chat/${action.payload}`, {});
        yield put(push(`messages/${action.payload}`));
        yield put(toggleUserProfileModal({ value: false, userId: action.payload }))
    } catch (error) {
        console.log('workerGetOpenHouseInfo', error);
    }
}

function* workerGetChatUserInfo(action: ReturnType <typeof getChatUserInfo>) {
    try {
        const result: mockType = yield call(API.get, `/api/chat/user-info?UserId=${action.payload}`, {},);
        if (result.success) {
            yield put(getChatUserInfoSuccess(result.value));
          } else {
            yield put(getChatUserInfoError(result.errors));
          }
    } catch (error) {
        console.log(error);
    }
}

export function* watchCreateChat() {
    yield takeEvery(createChat.type, workerCreateChat);
    yield takeEvery(getChatUserInfo.type, workerGetChatUserInfo);
}
