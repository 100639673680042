import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Resolver } from 'react-hook-form';
import commentResolver from '../validation/comments.schema';

import {
    Avatar,
    Box,
    Flex,
    ModalBody,
    Text,
    Image,
    Wrap,
    WrapItem,
    Tag,
    Divider,
    Menu,
    MenuButton,
    MenuList,
    IconButton,
    MenuItem,
    HStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';

import { CustomModalWrapper, PostEditor } from '../../components';
import { Loader } from '../../../../common/components';
import { Comments } from './components/Comments';

import { baseUrl } from '../../../../common/api/api.base';

import {
    addCommentToPostRequest,
    editCommentRequest,
    getDetailsPostRequest,
    saveTogglePostRequest,
} from '../../store/actions';
import { RootStore } from '../../../../store';

import { IdNameTypes } from '../../../../common/types';
import { AddReportPayload, CommentForm } from '../../types';

import {
    BookMark,
    CommentsIcon,
    DeleteIcon,
    EditPostIcon,
    MoreIcon,
    ReportIcon,
} from '../../../../assets/icons';
import { colors } from '../../../../common/constants';
import { getAvatarPicture } from '../../../../common/utils';
import { ShareIcon } from '../../../../assets/icons/ShareIcon';
import { SharingModal } from '../../../../common/components/modals/SharingModal/SharingModal';

interface IDetailsPostModalProps {
    size: string;
    isOpen: boolean;
    onClose: () => void;
    title: string;
    detailsPostId: number;
    reportTypes: IdNameTypes[];
    setReportPostId: Dispatch<SetStateAction<number>>;
    setReportTypeId: Dispatch<SetStateAction<number>>;
    onAddReport: (payload: AddReportPayload) => void;
    setEditPostId: Dispatch<SetStateAction<number>>;
    onDeletePost: (id: number) => void;
}

export const DetailsPostModal: React.FC<IDetailsPostModalProps> = React.memo(
    ({
        size,
        isOpen,
        onClose,
        title,
        detailsPostId,
        reportTypes,
        setReportPostId,
        setReportTypeId,
        onAddReport,
        setEditPostId,
        onDeletePost,
    }) => {
        const {
            handleSubmit,
            formState: { errors },
            setValue,
            register,
            reset,
            watch,
            clearErrors,
        } = useForm({
            resolver: commentResolver as Resolver<CommentForm>,
        });

        const dispatch = useDispatch();
        const { post, loading } = useSelector((state: RootStore) => state.posts.detailsPost);
        const currentUserId = useSelector((state: RootStore) => state.user.user.id);
        const { loading: addCommentLoading } = useSelector(
            (state: RootStore) => state.posts.addComment,
        );

        const [isSharingModalOpen, setIsSharingModalOpen] = useState(false);
        const [commentToEditId, setCommentToEditId] = useState<number | null>(null);

        const commentValue = watch('comment');

        const isMine = currentUserId === post.createdBy.id;

        const onSaveTogglePost = () => {
            dispatch(
                saveTogglePostRequest({
                    PostId: post.id,
                    isSavedPost: !post.isSaved,
                }),
            );
        };
        const onSubmit = (data: CommentForm) => {
            dispatch(
                addCommentToPostRequest({
                    PostId: post.id,
                    Text: data.comment,
                }),
            );
            reset({});
        };

        const onSaveUpdatedComment = (data: CommentForm) => {
            if (!commentToEditId) return;
            dispatch(editCommentRequest({ commentId: commentToEditId, text: data.comment }));
            setCommentToEditId(null);
        };

        const onEdit = (text: string, id: number) => {
            setCommentToEditId(id);
        };

        const onCancelEdit = () => {
            setCommentToEditId(null);
        };

        const onModalClose = () => {
            onClose();
            setCommentToEditId(null);
        };

        useEffect(() => {
            register('comment');
        }, [register]);

        useEffect(() => {
            if (isOpen && detailsPostId) {
                dispatch(getDetailsPostRequest(detailsPostId));
            }
        }, [isOpen, detailsPostId]);

        useEffect(() => {
            if (!isOpen) {
                reset({});
                clearErrors('comment');
            }
        }, [isOpen]);

        const defineInnerModalSize = (customSize: string) => {
            const firstLetter = customSize.charAt(0);
            const sizeNumber =
                Number(firstLetter) > 1 ? Number(firstLetter) - 1 : Number(firstLetter);

            const splittedString = customSize.split('');

            splittedString.splice(0, 1, sizeNumber === 1 ? '' : sizeNumber.toString());

            return splittedString.join('');
        };

        return (
            <CustomModalWrapper isOpen={isOpen} title={title} size={size} onClose={onModalClose}>
                <ModalBody h={loading ? '85vh' : 'auto'} className="blast_modal_post">
                    {loading ? (
                        <Loader spinnerSize={'md'} centerHeight={'100%'} />
                    ) : (
                        <>
                            {/*    Header Post*/}
                            {post.isSuspended && (
                                <Box
                                    textAlign={'center'}
                                    py={'8px'}
                                    top={0}
                                    left={0}
                                    right={0}
                                    position={'absolute'}
                                    bgColor={'#FFEFBE'}
                                >
                                    <Text
                                        _before={{
                                            content: `"!"`,
                                            marginRight: '10px',
                                        }}
                                        color={colors.orange}
                                        letterSpacing={'1px'}
                                        textTransform={'uppercase'}
                                        fontWeight={'600'}
                                        fontSize={'12px'}
                                        lineHeight={'16,8px'}
                                    >
                                        Suspended
                                    </Text>
                                </Box>
                            )}

                            <Flex justifyContent={'space-between'} align={'center'} mt={'27px'}>
                                <Flex align={'center'}>
                                    <Avatar
                                        mr={'8px'}
                                        boxSize={'6'}
                                        name={post.createdBy.fullName}
                                        src={getAvatarPicture(post.createdBy.avatar)}
                                    />
                                    <Text
                                        lineHeight={'24px'}
                                        color={'brand'}
                                        weight={500}
                                        fontSize={'16px'}
                                    >
                                        {post.createdBy.fullName}
                                    </Text>
                                    <Text
                                        ml={'21px'}
                                        color={'#888DAB'}
                                        lineHeight={'21px'}
                                        fontSize={'14px'}
                                        fontWeight={400}
                                    >
                                        {dayjs(post.createdAt).format('MM/DD/YY')}
                                    </Text>
                                </Flex>
                                <Flex alignItems="center" sx={{ '> *': { p: '.3em' } }}>
                                    <Box as={'button'} onClick={onSaveTogglePost}>
                                        <BookMark colorFill={post.isSaved ? '#2C335C' : 'none'} />
                                    </Box>
                                    <Flex align={'center'}>
                                        <CommentsIcon />
                                        <Text>{post.comments.length}</Text>
                                    </Flex>
                                    <IconButton
                                        aria-label="Share button"
                                        icon={<ShareIcon />}
                                        h="auto"
                                        minW="auto"
                                        bg="white"
                                        onClick={() => setIsSharingModalOpen(true)}
                                        _hover={{ bg: 'white' }}
                                    />
                                    <Menu>
                                        <MenuButton
                                            as={IconButton}
                                            aria-label={'More'}
                                            icon={isMine ? <MoreIcon /> : <ReportIcon />}
                                            boxShadow="none"
                                            bg="transparent"
                                        />
                                        <MenuList>
                                            {isMine ? (
                                                <>
                                                    <MenuItem
                                                        onClick={() => {
                                                            setEditPostId(post.id);
                                                        }}
                                                        icon={<EditPostIcon />}
                                                    >
                                                        Edit
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            onDeletePost(post.id);
                                                        }}
                                                        icon={<DeleteIcon />}
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                </>
                                            ) : (
                                                <>
                                                    {reportTypes.map(reportType => (
                                                        <MenuItem
                                                            onClick={() => {
                                                                if (reportType.id === 6) {
                                                                    setReportPostId?.(post.id);
                                                                    setReportTypeId?.(
                                                                        reportType.id,
                                                                    );
                                                                } else {
                                                                    onAddReport({
                                                                        PostId: post.id,
                                                                        TypeId: reportType.id,
                                                                    });
                                                                }
                                                            }}
                                                            key={reportType.id}
                                                        >
                                                            {reportType.name}
                                                        </MenuItem>
                                                    ))}
                                                </>
                                            )}
                                        </MenuList>
                                    </Menu>
                                </Flex>
                            </Flex>
                            {/* Image Box*/}
                            {post.photo.itemExtension && (
                                <Image
                                    mt={'13px'}
                                    h={'324px'}
                                    w={'100%'}
                                    objectFit={'cover'}
                                    alt={'post image'}
                                    src={`${baseUrl}/api/storage/static-images/${post.photo.itemHash}${post.photo.itemExtension}`}
                                />
                            )}
                            {/*Tags*/}
                            {!!post.tags.length && (
                                <Wrap spacing={'22px'} my={'18px'}>
                                    {post.tags.map((tag: IdNameTypes) => (
                                        <WrapItem key={tag.id}>
                                            <Tag
                                                color={colors.secondaryFontDarker}
                                                fontSize={'12px'}
                                                p={'12px 8px'}
                                                variant={'outline'}
                                                h={'32px'}
                                                borderRadius={'40px'}
                                            >
                                                {tag.name}
                                            </Tag>
                                        </WrapItem>
                                    ))}
                                </Wrap>
                            )}
                            {/* Title*/}
                            <Text
                                className="post_title"
                                lineHeight={'35px'}
                                fontSize={'24px'}
                                letterSpacing={'2px'}
                                fontWeight={'600'}
                            >
                                {post.title}
                            </Text>
                            {/*    Post Text*/}
                            <Text
                                margin="20px 0 20px 0"
                                className="post_text"
                                color={colors.secondaryFontDarker}
                                lineHeight={'24px'}
                                fontSize={'16px'}
                                letterSpacing={'1px'}
                                fontWeight={'400'}
                                >
                                <div dangerouslySetInnerHTML={{ __html: post.text? post.text.replace(/\n/g,'<br>') : ''}} />
                            </Text>
                            {!!post.comments.length && (
                                <Divider border={'1px solid #D1D2DB'} my={'32px'} />
                            )}
                            {/*Comments */}
                            <Comments
                                comments={post.comments}
                                postId={post.id}
                                onEdit={onEdit}
                                commentToEditId={commentToEditId}
                                onSubmit={onSaveUpdatedComment}
                                onCancelEdit={onCancelEdit}
                            />
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/*Post Editor*/}
                                <PostEditor
                                    addCommentLoading={addCommentLoading}
                                    value={commentValue}
                                    errorMsg={errors.comment?.message}
                                    placeholder={'Write your comment here ...'}
                                    onChange={value => {
                                        // console.log(value, 'VALUE')
                                        setValue('comment', value);
                                    }}
                                />
                            </form>
                        </>
                    )}
                </ModalBody>
                <SharingModal
                    size={defineInnerModalSize(size)}
                    isOpen={isSharingModalOpen}
                    onClose={() => setIsSharingModalOpen(false)}
                />
            </CustomModalWrapper>
        );
    },
);
