import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import {
    IconButton,
} from '@chakra-ui/react';
import { SendIcon } from '../../../../../assets/icons/SendIcon';
import { SendUpIcon } from '../../../../../assets/icons/SendUpIcon';
import { SendIconGrey } from '../../../../../assets/icons/SendIconGrey';
import axios from 'axios';

let interval: NodeJS.Timeout;

export const ChatOffMarket: React.FC = () => {
    
  const [countMessages, setcountMessages] = useState<number>(0);

  const getChatHandler = () => {
    axios.get(window.baseUrl+'/api/chat/getUnreadMessages')
    .then((response: any) => {
      setcountMessages(response.data.countMessages);
    });
  };

  useEffect(() => {
      clearInterval(interval);
      interval = setInterval(() => {
        getChatHandler();
      }, 10000);
      setTimeout(() => {
        getChatHandler();
      }, 2000);
    }, []);

  return (
      <>
          <NavLink to="/market/chat" replace>
            <div className="count_om_messages">
              <SendIconGrey />
              {(countMessages > 0) &&
                <div className="n_cofcm">
                  { countMessages }
                </div>
              }
            </div>
          </NavLink>
      </>
  );
};
