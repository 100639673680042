import React from 'react';

export const LogoIconDashboard: React.FC = () => (
<svg width="116" height="44" viewBox="0 0 116 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.3267 8.56769C10.6509 8.62814 10.9175 8.79025 11.1263 9.05403C11.3351 9.3178 11.4395 9.62004 11.4395 9.96075C11.4395 10.2685 11.3626 10.5405 11.2087 10.7768C11.0603 11.0076 10.8433 11.189 10.5575 11.3208C10.2718 11.4527 9.9338 11.5187 9.54364 11.5187H7.0625V5.76508H9.43648C9.82664 5.76508 10.1619 5.82828 10.4421 5.95467C10.7279 6.08106 10.9422 6.25691 11.0851 6.48222C11.2334 6.70753 11.3076 6.96306 11.3076 7.24881C11.3076 7.58403 11.217 7.86429 11.0356 8.0896C10.8598 8.31491 10.6235 8.47427 10.3267 8.56769ZM8.21652 8.13906H9.27162C9.54638 8.13906 9.75795 8.07861 9.90633 7.95771C10.0547 7.83132 10.1289 7.65272 10.1289 7.42192C10.1289 7.19111 10.0547 7.01252 9.90633 6.88612C9.75795 6.75973 9.54638 6.69654 9.27162 6.69654H8.21652V8.13906ZM9.37878 10.579C9.65904 10.579 9.8761 10.513 10.03 10.3811C10.1893 10.2493 10.269 10.0624 10.269 9.82062C10.269 9.57333 10.1866 9.381 10.0217 9.24361C9.85687 9.10074 9.63431 9.0293 9.35405 9.0293H8.21652V10.579H9.37878Z" fill="white"/>
<path d="M13.6781 6.69654V8.13906H15.6152V9.05403H13.6781V10.579H15.8625V11.5187H12.5241V5.75684H15.8625V6.69654H13.6781Z" fill="white"/>
<path d="M20.9915 5.76508V6.69654H19.4583V11.5187H18.3043V6.69654H16.7711V5.76508H20.9915Z" fill="white"/>
<path d="M25.5264 10.4224H23.2348L22.8557 11.5187H21.6439L23.7129 5.75684H25.0565L27.1255 11.5187H25.9056L25.5264 10.4224ZM25.2131 9.49915L24.3806 7.0922L23.5481 9.49915H25.2131Z" fill="white"/>
<rect x="0.329719" y="0.329719" width="33.5293" height="16.6161" rx="2.30803" stroke="#FE805C" strokeWidth="0.659438"/>
<path d="M33.5014 40.9926L35.2994 37.6878C35.5085 37.8192 35.7074 37.9662 35.8944 38.1276C37.6155 39.8373 40.9024 40.1001 43.0261 38.9924C44.8275 38.0532 45.6799 36.4536 45.9923 34.5565C46.2017 33.3125 46.1099 32.0364 45.7245 30.8352C45.41 29.6943 44.7368 28.6849 43.8044 27.956C42.872 27.2271 41.73 26.8175 40.5469 26.7876C39.388 26.683 38.2231 26.9064 37.1851 27.4322C35.0792 28.5573 34.1941 30.4465 34.094 32.7174C34.0092 34.6557 33.7623 36.5354 32.8479 38.2709C32.1981 39.5784 31.2306 40.7021 30.034 41.5388C28.8374 42.3755 27.45 42.8987 25.9988 43.0603C24.43 43.289 22.8318 43.2141 21.2913 42.8396C20.0377 42.5869 18.8608 42.0439 17.8552 41.2539C16.8496 40.464 16.0432 39.4493 15.5008 38.2912C13.6627 34.4147 13.8541 30.5932 16.3125 27.0271C17.9983 24.5826 20.4776 23.4436 23.3996 23.2587C25.6557 23.0347 27.9201 23.5951 29.8114 24.8454C30.1292 25.0636 30.4163 25.3278 30.7312 25.5812L28.96 28.8444C28.6486 28.5791 28.3803 28.354 28.1136 28.1254C24.8628 25.3353 19.652 26.8451 18.4992 30.9572C17.8471 33.2837 18.0385 35.5477 19.527 37.5608C19.9398 38.0983 20.4543 38.5491 21.0414 38.8876C21.6285 39.226 22.2765 39.4454 22.9484 39.5333C23.9893 39.7301 25.0616 39.6844 26.0821 39.3999C27.0677 39.1496 27.9587 38.617 28.6457 37.8674C29.3328 37.1178 29.786 36.1839 29.9497 35.1803C30.2011 33.9942 30.1942 32.7551 30.3667 31.5497C31.0143 27.0152 34.4421 23.342 39.6886 23.2408C41.4766 23.1526 43.2574 23.5249 44.8603 24.3223C47.5289 25.7211 49.0511 27.999 49.6863 30.8788C50.2706 33.4178 49.9094 36.0827 48.6703 38.3745C48.0255 39.6481 47.0745 40.7417 45.9027 41.5569C44.7309 42.3722 43.3749 42.8836 41.9566 43.0454C39.5336 43.4768 37.0359 43.0859 34.8606 41.9347C34.3902 41.6464 33.9364 41.3319 33.5014 40.9926V40.9926Z" fill="white"/>
<path d="M5.41905 39.1233C7.98902 39.1233 10.3656 39.1476 12.7412 39.1084C13.0477 39.074 13.3574 39.1351 13.6279 39.2833C13.8984 39.4315 14.1165 39.6596 14.2525 39.9364C14.7103 40.6196 15.2157 41.2696 15.7649 41.8816C15.9429 42.1033 16.1288 42.317 16.2815 42.4995C16.2586 42.561 16.2306 42.6206 16.1977 42.6775C16.178 42.7036 16.15 42.7222 16.1184 42.7305C15.9592 42.7674 15.7971 42.7907 15.634 42.8004C10.5816 42.7981 5.52897 42.7923 0.475975 42.7831C0.353865 42.7594 0.235133 42.7207 0.122439 42.6681C-0.0312732 41.6531 -0.163664 40.6748 0.535973 39.7069C3.20461 36.0178 5.82813 32.296 8.46453 28.5841C8.74469 28.1904 9.00401 27.7803 9.3878 27.2061H0.286068V23.8046C1.10025 23.5959 13.8226 23.5567 14.909 23.7575C15.0469 24.1543 15.0873 24.5784 15.0269 24.9941C14.9665 25.4098 14.807 25.8049 14.5619 26.146C11.6811 30.1683 8.83245 34.2159 5.97539 38.2555C5.81028 38.4916 5.6645 38.7395 5.41905 39.1233Z" fill="white"/>
<path d="M55.2188 27.1526C54.2441 27.106 53.2675 27.1125 52.2934 27.1719C50.9685 27.3256 49.9946 26.988 49.409 25.7126C49.1876 25.3443 48.9137 25.0102 48.5958 24.721C48.3375 24.4339 48.0574 24.1666 47.8184 23.9231C47.8338 23.8599 47.8557 23.7984 47.8838 23.7397C47.9031 23.7134 47.9319 23.6956 47.9641 23.6901C53.1818 23.6693 58.3994 23.6501 63.617 23.6326C64.0419 23.6326 64.4668 23.6326 64.9602 23.6326C65.2151 24.7776 65.2394 25.9619 65.0316 27.1164H59.202C59.1653 27.523 59.1157 27.8066 59.1172 28.0887C59.1355 32.6118 59.1668 37.1344 59.1792 41.6575C59.1792 42.7082 59.08 42.7841 58.0481 42.798C57.3867 42.8084 56.7252 42.8153 56.0648 42.798C55.376 42.7806 55.2793 42.6819 55.2288 41.9883C55.2079 41.6907 55.2193 41.3932 55.2193 41.0957C55.2193 36.9346 55.2193 32.7738 55.2193 28.6133L55.2188 27.1526Z" fill="white"/>
<path d="M106.199 43.0375C102.58 43.0559 99.6715 41.6804 97.932 38.4376C96.9849 36.7787 96.5003 34.8961 96.5285 32.9861C96.5568 31.0761 97.0969 29.2087 98.0927 27.5786C99.3477 25.3671 101.359 24.1077 103.833 23.5622C106.392 22.9368 109.094 23.3061 111.391 24.5956C113.938 26.0196 115.228 28.3318 115.769 31.1075C116.129 33.03 115.978 35.0134 115.331 36.8593C114.752 38.664 113.611 40.2366 112.076 41.348C110.54 42.4593 108.69 43.0514 106.794 43.038C106.595 43.0385 106.398 43.0375 106.199 43.0375ZM98.3213 33.1018C98.3127 34.8877 98.8181 36.6384 99.7771 38.145C101.879 41.3234 105.963 42.1718 109.282 40.9282C110.824 40.3832 112.12 39.3063 112.938 37.8912C113.653 36.6975 114.088 35.3577 114.212 33.972C114.336 32.5863 114.144 31.1905 113.652 29.8892C113.272 28.7553 112.606 27.7383 111.719 26.936C109.723 25.146 107.351 24.73 104.788 25.0805C103.649 25.21 102.559 25.6164 101.614 26.2642C100.669 26.9119 99.8964 27.7813 99.3645 28.7964C98.6654 30.1229 98.3069 31.6024 98.3213 33.1018V33.1018Z" fill="white"/>
<path d="M67.1279 25.1801V23.699C67.4541 23.6772 67.7422 23.6415 68.0303 23.6405C70.5735 23.636 73.1157 23.6246 75.6594 23.6405C78.5645 23.6579 80.7348 25.2471 81.5327 28.0292C82.4832 31.3514 80.9471 34.8853 77.6998 35.8923C76.9432 36.1207 76.1593 36.2457 75.3693 36.2642C73.555 36.3183 71.7372 36.2781 69.9209 36.287C68.9788 36.2915 68.9104 36.355 68.9015 37.3045C68.8866 38.8223 68.9109 40.3416 68.8822 41.8593C68.8673 42.6824 68.7146 42.8014 67.9648 42.8014C67.1918 42.8014 67.0659 42.6948 67.0614 41.8712C67.0498 39.7586 67.0498 37.6458 67.0614 35.5329C67.0659 34.6795 67.1497 34.5972 68.0625 34.5907C70.5418 34.5749 73.017 34.6101 75.4928 34.5595C76.215 34.5661 76.931 34.4263 77.5976 34.1485C78.2775 33.8357 78.858 33.3417 79.2755 32.7206C79.693 32.0996 79.9313 31.3756 79.9643 30.628C80.1031 29.2064 79.9068 27.8631 78.9126 26.7341C78.5894 26.3364 78.1881 26.0091 77.7334 25.7725C77.2788 25.536 76.7805 25.3951 76.2693 25.3586C75.8076 25.3299 75.346 25.2991 74.8844 25.2966C72.7706 25.2907 70.6568 25.3011 68.544 25.2897C68.0923 25.2867 67.6401 25.2213 67.1279 25.1801Z" fill="white"/>
<path d="M88.8351 35.5314C90.0315 36.829 91.0822 37.961 92.125 39.1015C93.1618 40.2325 94.1907 41.3705 95.2573 42.5441C93.9681 43.0434 93.2868 42.8957 92.5093 42.0483C90.3448 39.6874 88.1807 37.3273 86.0172 34.9681C85.8025 34.7341 85.6235 34.4688 85.2868 34.0369C85.8263 34.0057 86.1664 33.9819 86.5061 33.968C87.4943 33.9744 88.4821 33.9275 89.4653 33.8277C92.5013 33.3626 93.1683 30.7123 92.7711 28.6709C92.3462 26.4778 90.6593 25.2401 88.3239 25.2664C86.8033 25.2836 85.2842 25.2928 83.7665 25.2942C82.2924 25.2942 82.2017 25.2347 81.3691 23.7461C81.5846 23.6997 81.8029 23.6679 82.0227 23.6509C84.2698 23.6415 86.517 23.5924 88.7617 23.6509C94.21 23.7898 95.9614 29.2223 94.0102 32.714C93.0731 34.3914 91.531 35.2081 89.6686 35.4823C89.4757 35.5081 89.2769 35.5051 88.8351 35.5314Z" fill="white"/>
</svg>
);
