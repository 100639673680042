import { put, call, takeEvery } from 'redux-saga/effects';
import { deleteRequest, deleteRequestSuccess, deleteRequestError } from '../actions';
import API from '../../../../common/api/api.base';
import { IResponseWithoutValue } from './../../../../common/types';
import { getDashboardRequest } from '../../../../store/actions/dashboard.actions';

function* workerDeleteRequest(action: ReturnType<typeof deleteRequest>) {
    console.log('deleteRequest action', action);
    try {
        const result: IResponseWithoutValue = yield call(
            API.delete,
            `/api/request/delete/${action.payload.id}`,
        );
        if (result.success) {
            yield put(deleteRequestSuccess(action.payload.id));
            console.log('deleteRequestSuccess', result);
            if (action.payload.dashboardRequestPayload) {
                yield put(getDashboardRequest(action.payload.dashboardRequestPayload));
            }
        } else {
            yield put(deleteRequestError(result.errors));
            console.log('deleteRequestError', result);
        }
    } catch (error) {
        console.log('error delete request');
    }
}

export default function* watchDeleteRequestSaga() {
    yield takeEvery(deleteRequest.type, workerDeleteRequest);
}
