import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getDashboardRequest, getDashboardRequestError, getDashboardRequestSuccess, setDashboardRequestPageNumber } from '../actions/dashboard.actions';
import { PayloadError } from '../../common/types';
import { DashboardType } from '../../common/types/dashboard.types';

type InitialState = {
    dashboardRequest: {
        loading: boolean;
        errors: PayloadError[];
        items: DashboardType[];
        totalCount: number;
        loadMoreLoading: boolean;
        pageNumber: number;
    };
};

const initialState: InitialState = {
    dashboardRequest: {
        errors: [],
        items: [],
        loadMoreLoading: false,
        loading: false,
        totalCount: 0,
        pageNumber: 1,
    },
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getDashboardRequest, (state, action) => {
                if (action.payload.pageNumber === 1) {
                    state.dashboardRequest.loading = true;
                } else {
                    state.dashboardRequest.loadMoreLoading = true;
                }
                state.dashboardRequest.errors = [];
            })
            .addCase(getDashboardRequestSuccess, (state, action) => {
                state.dashboardRequest.totalCount = action.payload.totalCount;
                state.dashboardRequest.items =
                    action.payload.pageNumber === 1
                        ? action.payload.requests
                        : [...state.dashboardRequest.items, ...action.payload.requests];
                state.dashboardRequest.loading = false;
                state.dashboardRequest.loadMoreLoading = false;
            })
            .addCase(getDashboardRequestError, (state, action) => {
                state.dashboardRequest.errors = action.payload;
                state.dashboardRequest.loading = false;
                state.dashboardRequest.loadMoreLoading = false;
            })
            .addCase(setDashboardRequestPageNumber, (state, action) => {
                state.dashboardRequest.pageNumber = action.payload;
            })
    },
});

export default dashboardSlice.reducer;
