import React, { useState } from 'react';
import { SubmitHandler, Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Countdown from 'react-countdown';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    Box,
    Text,
    Flex,
    HStack,
    PinInput,
    PinInputField,
    Checkbox,
    IconButton,
} from '@chakra-ui/react';
import { ISignUpProps, SignUpData } from '../SignUp/types';
import { CustomButton, CustomInput } from '../../../common/components';
import { BackArrow, EmailIcon } from '../../../assets/icons';
import axios from 'axios';
import { ErrorMessages } from '../../../common/schemes/messages';
import { AuthCardPanel } from '../../../common/components/aglobal/AuthCardPanel';

export interface IChangePassword {
    email: string;
    code: string; 
		isUpdates?: () => void;
		clickBack?: () => void;
		url?: string;
}

export type IFormdata = {
    password: string;
    repeatPassword: string;
};

const formChema = yup.object().shape({
	password: yup
			.string()
			.min(8, ErrorMessages.SHORT_PASSWORD)
			.required(ErrorMessages.REQUIRED)
			.matches(/\d/, ErrorMessages.PASSWORD_DIGIT)
			.matches(/[A-Z]/, ErrorMessages.PASSWORD_UPPERCASE)
			.matches(/\W/g, ErrorMessages.NON_ALPHANUMERIC_MISSING)
			.test('unique-chars', ErrorMessages.MINIMUM_4_CHARS, function (value?: string) {
					return new Set(value?.split('')).size >= 4;
			}),
	repeatPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const FormChangePassword: React.FC<IChangePassword> = ({
	email,
	code,
	isUpdates,
	clickBack,
	url = '/api/auth/saveNewPassword'
}) => {
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
        watch,
        reset,
    } = useForm<IFormdata>({
			resolver: yupResolver(formChema)
		});

		const [loading, setloading] = useState<boolean>(false);

		const saveNewPassword = (payload: IFormdata) => {
			setloading(true);
			axios.post(window.baseUrl+url, {
				email: email,
				code: code,
				password: payload.password
			})
			.then((response: any) => {
				if(isUpdates){
					isUpdates();
				}
			});
		};

    return (
        <>
				<form className="fixed_form" onSubmit={handleSubmit(saveNewPassword)}>
            <Box
                borderRadius={20}
                backgroundColor="#FFFFFF"
                maxW="100%"
                position="relative"
								p="60px"
								className="fixed_m_box"
								>
								<AuthCardPanel>
									<br />
									<Flex
											position="relative"
											flexDirection="column"
											justifyContent="space-around"
									>
											<IconButton
													aria-label="Back"
													variant="unstyled"
													position="absolute"
													top={{ base: '-52px' }}
													left={{ base: '-5px' }}
													d="flex"
													alignItems="center"
													onClick={() => {
														if(clickBack){
															setloading(false);
															clickBack();
														}
													}}
											>
													<BackArrow />
											</IconButton>
											<Text
													fontWeight={900}
													fontSize={{ base: '14px', lg: '18px' }}
													color="brand"
											>
													Enter New Password
											</Text>
									</Flex>
									<br />
									<Flex
											h="100%"
											flexDirection="column"
											justifyContent="space-around"
									>
										<Box
											marginBottom="0px"
										>
										<CustomInput
												label="Password *"
												width="100%"
												placeholder="Password"
												register={{ ...register('password') }}
												errors={errors.password}
												type="password"
												isPassword
												isBorder={false}
												fontSize="14px"
												textSize="16px"
												height="50px"
										/>
										</Box>
										<Box
											marginBottom="30px"
										>
											<CustomInput
													label="Re-enter Password *"
													width="100%"
													placeholder="Repeat Password"
													register={{ ...register('repeatPassword') }}
													type="password"
													isPassword
													errors={errors.repeatPassword}
													isBorder={false}
													fontSize="14px"
													textSize="16px"
													height="50px"
											/>
										</Box>
										<Box>
												<CustomButton
														onClick={() => {

														}}
														type="submit"
														isLoading={loading}
														loadingText="Signing up..."
														text="Confirm"
														width="100%"
														height="60px"
														bgColor="brand"
														boxShadow="0px 3px 3px grey"
												/>
										</Box>
									</Flex>
								</AuthCardPanel>
            </Box>
        </form>
        </>
    );
};
