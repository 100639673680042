import React, { useState } from 'react';
import { Box, Text, Tabs, TabPanels, TabPanel, Flex, HStack, Container } from '@chakra-ui/react';

import { AvailableRequestDetailsModal, OpenHouseRequestDetailsModal } from './modals';
import { MyRequestsType, RequestInfoType } from './types';
import { MyRequestsTab, AgencyRequestsTab } from './tabs';
import { TabsSwitcher, FiltersBlock } from '../Opportunities/components';
import { requestTypesWithReferral } from '../../common/constants';
import { useHistory } from 'react-router-dom';
import { IdNameTypes } from '../../common/types';
import { BackButton } from '../../common/components';
import { DashboardHeader } from '../../common/components/blocks/DashboardWrapper/DashboardHeader';

export type RequestsType = {
    requestsLoading: boolean;
    requestInfoLoading: boolean;
    requestInfo: RequestInfoType;
    onGetRequest: (id: number) => void;
    myRequests: MyRequestsType[];
    tabIndex: number;
    setTabIndex: (value: number) => void;
    loadMoreAgencyRequests: () => void;
    typeIdsSelected: number[];
    setTypeIdsSelected: (value: number[]) => void;
    setSentRequestToShowId: (value: number) => void;
    setPendingRequestToShowId: (value: number) => void;
    loadMoreMyRequests: () => void;
    handleAcceptRequest: (IsAccepted: boolean, RequestId: number) => void;
    pendingLoadMoreLoading: boolean;
    sentLoadMoreLoading: boolean;
    requestCategories: IdNameTypes[];
};

const Requests: React.FC<RequestsType> = ({
    myRequests,
    requestsLoading,
    onGetRequest,
    requestInfo,
    requestInfoLoading,
    tabIndex,
    setTabIndex,
    loadMoreAgencyRequests,
    setTypeIdsSelected,
    typeIdsSelected,
    setSentRequestToShowId,
    setPendingRequestToShowId,
    loadMoreMyRequests,
    handleAcceptRequest,
    pendingLoadMoreLoading,
    sentLoadMoreLoading,
    requestCategories,
}) => {
    let history = useHistory();
    const [isAvailableModalOpen, setIsAvailableModalOpen] = useState(false);

    const handleOpenAvailableModal = (id: number) => {
        setIsAvailableModalOpen(true);
        onGetRequest(id);
    };

    return (
        <Flex backgroundColor="brand" flexDirection="column" h="100vh" alignItems="center">
            <DashboardHeader />
            <Container
                maxW="container.lg"
                bgColor="white"
                h="90vh"
                mt="15px"
                borderRadius="10px"
                p="25px 33px"
            >
                <HStack mb="16px">
                    <BackButton mb="0" text="" />
                    <Text fontSize="24px" lineHeight="25px" color="brand" fontWeight="bold">
                        Requests
                    </Text>
                </HStack>
                <Tabs variant="unstyled" index={tabIndex}>
                    <Flex justifyContent="space-between">
                        <TabsSwitcher
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            firstTabLabel="My Requests"
                            secondTabLabel="Brokerage Requests"
                        />
                        <Box w="20px" />
                        <FiltersBlock
                            openCreateNewPost={() => history.push('/find-assistance')}
                            setTypeIdsSelected={setTypeIdsSelected}
                            menuValues={requestCategories.map(elem => ({
                                label: elem.name,
                                value: elem.id,
                            }))}
                            showButton={false}
                        />
                    </Flex>
                    <TabPanels>
                        <TabPanel p="0" h="100%" pt="10px">
                            <MyRequestsTab
                                myRequests={myRequests.filter(elem => !elem.isOtherAgent)}
                                setPendingRequestToShowId={setPendingRequestToShowId}
                                loadMoreMyRequests={loadMoreMyRequests}
                                loading={requestsLoading}
                                loadMoreLoading={pendingLoadMoreLoading}
                                requestCategories={requestCategories}
                            />
                        </TabPanel>
                        <TabPanel p="0" h="100%" pt="10px">
                            <AgencyRequestsTab
                                loadMoreAgencyRequests={loadMoreAgencyRequests}
                                setSentRequestToShowId={setSentRequestToShowId}
                                loadMoreLoading={sentLoadMoreLoading}
                                handleOpenAvailableModal={handleOpenAvailableModal}
                                handleAcceptRequest={handleAcceptRequest}
                                requestInfo={requestInfo}
                                onGetRequest={onGetRequest}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Container>

            <AvailableRequestDetailsModal
                isOpen={isAvailableModalOpen}
                onClose={setIsAvailableModalOpen}
                requestInfo={requestInfo}
                requestInfoLoading={requestInfoLoading}
                handleAcceptRequest={handleAcceptRequest}
            />
            <OpenHouseRequestDetailsModal isOpen={false} onClose={setIsAvailableModalOpen} />
        </Flex>
    );
};

export default Requests;
