import React, { useEffect } from 'react';
import SidebarContainer from '../Sidebar/Sidebar.container';
import { DashboardWrapper } from '../../common/components/blocks/DashboardWrapper/DashboardWrapper';
import { MyHomeContent } from './MyHomeContent';

export const MyHome: React.FC = () => {
    return (
        <DashboardWrapper
          paddingBottom="20px"
          paddingTop="20px"
          paddingLeft="20px"
          paddingRight="20px"
          sidebar={<SidebarContainer isAccessAllowed />}
          content={<MyHomeContent />}
        />
    );
};
