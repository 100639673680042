import { useEffect, useState } from 'react';

export const useLocalStorage = <T extends any>(key: any, initialValue?: T) => {
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (err) {
            console.error(err);
            return initialValue;
        }
    });

    const setValue = (value: T) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        setValue(storedValue)
    }, [storedValue])

    return [storedValue, setStoredValue] as const;
};

export default useLocalStorage;
