import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Text, HStack, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';
import { useDelta } from 'react-delta';
import qs from 'qs';

import { BackButton } from '../../../common/components';
import { TabsSwitcher } from '../../../common/components/blocks/TabsSwitcher';
import { FiltersBlock } from '../../Listings/components/FiltersBlock';
import { CalendarTab } from '../CalendarTab/CalendarTab';
import { AgencyListingsTab } from '../../Listings/tabs';

import { RootStore } from '../../../store';
import {
    getListingTypesRequest,
    getMyListings,
    getOpenHouseInfo,
    getAgencyListingsRequest,
    getAgencyRosterRequest,
    getAgencyCities,
} from '../../../store/actions/listings.actions';
import {
    getOpenHouseAudiences,
    getOpenHouseHostTypes,
    getOpenHouseTypes,
} from '../../../store/actions/directories.actions';

import { OpenHouseDetailsOpenConfig, ListingsType, IdNameTypes } from '../../../common/types';
import { sortByTypes } from '../../../common/constants';
import PeopleCardsContainer from '../../Listings/tabs/PeopleCardsContainer';
import { defaultOpenHouseDetailsOpenConfig } from '../MyAgency';
import { ListingDetailsModal, OpenhouseDetailsModal } from '../../Listings/modals';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toggleUserProfileModal } from '../../../store/actions/profile.actions';
import _ from 'lodash';
import { FreshListings } from '../../../common/components/aglobal/FreshListings';

const tabsOptions = [
    {
        index: 0,
        label: 'Brokerage Listings',
    },
    {
        index: 1,
        label: 'Brokerage Roster',
    },
    {
        index: 2,
        label: 'Brokerage Calendar',
    },
];

export const MyAgencyContent = () => {
    const { pathname, search } = useLocation();
    const { push } = useHistory();

    const [statesSelected, setStates] = useState<number[]>([]);

    const onOpenModal = (
        value: number,
        listing: ListingsType,
        isMyListings: boolean,
        isManageOpenHouse: boolean,
    ) => {
        setToShowListingId(value);
        push(`${pathname}?id=${value}`);
        if (listing.isOpenHouse) {
            setShowOpenhouseInfoModal({
                isOpen: true,
                listingStatusId: listing.listingStatusId || 0,
                isMyListings,
                isManageOpenHouse,
            });
        } else {
            setShowListingInfoModal(true);
        }
    };

    const history = useHistory();
    const {tab} = useParams<{tab:string}>();

    const [showOpenhouseInfoModal, setShowOpenhouseInfoModal] =
        useState<OpenHouseDetailsOpenConfig>(defaultOpenHouseDetailsOpenConfig);
    const [showAddTimeSlotModal, setShowAddTimeSlotModal] = useState(false);
    const [toShowListingId, setToShowListingId] = useState(0);
    const [showListingInfoModal, setShowListingInfoModal] = useState(false);
    const [tabIndex, setTabIndex] = useState(_.toNumber(tab)? _.toNumber(tab) : 0);
    const [currentPageListings, setCurrentPageListings] = useState(1);
    const [currentPageRoster, setCurrentPageRoster] = useState(1);
    const [onEndReachedCalled, setOnEndReachedCalled] = useState(false);
    const [onEndReachedRosterCalled, setOnEndReachedRosterCalled] = useState(false);
    const [sortValue, setSortValue] = useState(sortByTypes[0].value);
    const [isSorted, setIsSorted] = useState(false);
    const [isOpenHouse, setIsOpenHouse] = useState(false);
    const [propertyTypes, setPropertyTypes] = useState<number[]>([]);
    const [cities, setCities] = useState<string[]>([]);

    const {
        loading: agencyListingsLoading,
        items: agencyListings,
        loadMoreLoading: agencyListingsMoreLoading,
        totalCount: agencyListingsTotalCount,
    } = useSelector((state: RootStore) => state.listings.agencyListings);

    const {
        loading: agencyRosterLoading,
        items: agencyRoster,
        loadMoreLoading: agencyRosterMoreLoading,
        totalCount: agencyRosterTotalCount,
    } = useSelector((state: RootStore) => state.listings.agencyRoster);

    const { loading: addSlotLoading, errors: addSlotErrors } = useSelector(
        (state: RootStore) => state.listings.addTimeSlot,
    );

    const { items: listingTypes } = useSelector((state: RootStore) => state.listings.listingTypes);
    const { items: agencyCities } = useSelector((state: RootStore) => state.listings.agencyCities);

    const addSlotLoadingDelta = useDelta(addSlotLoading);

    const dispatch = useDispatch();
    const onGetOpenHouseTypes = () => dispatch(getOpenHouseTypes());
    const onGetOpenHouseHostTypes = () => dispatch(getOpenHouseHostTypes());
    const onGetOpenHouseAudiences = () => dispatch(getOpenHouseAudiences());
    const onGetListingTypes = () => dispatch(getListingTypesRequest());
    const onGetAgencyCities = () => dispatch(getAgencyCities());
    const onOpenListingsModal = () => {
        const { id, userId } = qs.parse(search, { ignoreQueryPrefix: true });

        if (id) {
            setShowListingInfoModal(true);
            setToShowListingId(Number(id));
        } else if (userId) {
            dispatch(toggleUserProfileModal({ value: true, userId: Number(userId) }));
        }
    };

    useEffect(() => {
        if (tabIndex === 0) {
            dispatch(
                getAgencyListingsRequest({
                    cities,
                    isOpenHouse,
                    pageNumber: currentPageListings,
                    pageSize: 20,
                    propertyTypes,
                    sortBy: sortValue === 1 ? 0 : 1,
                    sortOrder: isSorted,
                    states: statesSelected,
                }),
            );
        } else if (tabIndex === 1) {
            dispatch(
                getAgencyRosterRequest({
                    pageNumber: currentPageRoster,
                    pageSize: 20,
                }),
            );
        }
    }, [
        tabIndex,
        sortValue,
        isSorted,
        cities,
        isOpenHouse,
        propertyTypes,
        currentPageListings,
        currentPageRoster,
        statesSelected,
    ]);

    useEffect(() => {
        onGetListingTypes();
        onGetOpenHouseTypes();
        onGetOpenHouseHostTypes();
        onGetOpenHouseAudiences();
        onGetAgencyCities();
        onOpenListingsModal();
    }, []);

    useEffect(() => {
        if (addSlotLoadingDelta && addSlotLoadingDelta.prev) {
            if (addSlotLoadingDelta.prev && !addSlotLoadingDelta.curr && !addSlotErrors.length) {
                setShowAddTimeSlotModal(false);
                dispatch(getMyListings());
                dispatch(getOpenHouseInfo(toShowListingId));
            }
        }
    }, [addSlotLoadingDelta]);

    const loadMoreListings = () => {
        if (agencyListings.length >= agencyListingsTotalCount) {
            setOnEndReachedCalled(true);
        } else if (!onEndReachedCalled) {
            setCurrentPageListings((prev: number) => prev + 1);
        }
    };

    const loadMoreRoster = () => {
        if (agencyRoster.length >= agencyRosterTotalCount) {
            setOnEndReachedRosterCalled(true);
        } else if (!onEndReachedRosterCalled) {
            setCurrentPageRoster((prev: number) => prev + 1);
        }
    };

    useEffect(() => {
        if(_.toNumber(tab) == _.toNumber(tabIndex)){
            return;
        }
        if(tab){
            setTabIndex(_.toNumber(tab));
        }
    }, [tab]);

    useEffect(() => {
        if(_.toNumber(tab) == _.toNumber(tabIndex)){
            return;
        }
        history.push('/my-agency/'+tabIndex);
    }, [
        tabIndex,
    ]);

    return (
        <>
            <HStack my="16px">
                <Flex justifyContent="space-between" width="100%">
                    <Flex alignItems="center">
                        <BackButton mb="0" text="" />
                        <Text fontSize="24px" lineHeight="25px" color="brand" fontWeight="bold">
                            My Brokerage
                        </Text>
                    </Flex>
                    <div className='1111'>
                        <FreshListings />
                    </div>
                </Flex>
            </HStack>
            <Tabs variant="unstyled" index={tabIndex}>
                <Flex justifyContent="space-between">
                    <Flex>
                        <TabsSwitcher
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            tabsOptions={tabsOptions}
                        />
                    </Flex>
                    {tabIndex === 0 && (
                        <FiltersBlock
                            listingTypes={listingTypes}
                            agencyCities={agencyCities}
                            setSortValue={setSortValue}
                            sortValue={sortValue}
                            setIsSorted={setIsSorted}
                            setCities={setCities}
                            setStates={setStates}
                            setIsOpenHouse={setIsOpenHouse}
                            setPropertyTypes={setPropertyTypes}
                        />
                    )}
                </Flex>
                <TabPanels>
                    <TabPanel p="0" h="100%" pt="10px">
                        <AgencyListingsTab
                            items={agencyListings}
                            loading={agencyListingsLoading}
                            loadMoreLoading={agencyListingsMoreLoading}
                            onOpenModal={onOpenModal}
                            loadMoreItems={loadMoreListings}
                        />
                    </TabPanel>
                    <TabPanel p="0" h="100%" pt="10px">
                        <PeopleCardsContainer
                            items={agencyRoster}
                            loading={agencyRosterLoading}
                            loadMoreLoading={agencyRosterMoreLoading}
                            loadMoreItems={loadMoreRoster}
                        />
                    </TabPanel>
                    <TabPanel p="0" h="100%" pt="10px">
                        <CalendarTab />
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <OpenhouseDetailsModal
                showOpenhouseInfoModal={showOpenhouseInfoModal}
                onClose={() => {
                    setShowOpenhouseInfoModal(defaultOpenHouseDetailsOpenConfig);
                    setToShowListingId(0);
                }}
                listingId={toShowListingId}
                setShowAddTimeSlotModal={setShowAddTimeSlotModal}
                getItems={() => dispatch(getMyListings())}
            />
            <ListingDetailsModal
                isOpen={showListingInfoModal}
                onClose={() => {
                    setShowListingInfoModal(false);
                    setToShowListingId(0);
                    push(pathname);
                }}
                listingId={toShowListingId}
            />
        </>
    );
};
function useNavigate() {
    throw new Error('Function not implemented.');
}

