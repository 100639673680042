import React from 'react'
import {Tag, TagLabel} from "@chakra-ui/react";

interface ICustomTagProps {
    isSelected: boolean
    name:string
    setSelected: () => void
}

export const CustomTag:React.FC<ICustomTagProps> = ({
    isSelected,
    name,
    setSelected
 }) => (
    <>
        <Tag
            backgroundColor={isSelected ? '#2C335C' : '#E2E8F0'}
            onClick={setSelected}
            boxShadow={'none'}
            border={'1px solid #fff'}
            px={'12px'}
            py={'8px'}
            height={'32px'}
            borderRadius={'40px'}
            as={'button'}
            variant={isSelected ? 'subtle' : 'outline'}
        >
            <TagLabel
                color={isSelected ? '#fff' : '#222'}
                fontWeight={500}
                fontSize={12}
            >
                {name}
            </TagLabel>
        </Tag>
    </>
)
