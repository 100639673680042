import React, { useState } from 'react';

export const CenterMenuPanel: React.FC = () => {

    return (
        <div className="o_cp">
            <div className="center_menu_panel">
                <a href="/how-it-works">How it Works</a>
                <a href="/pricing">Pricing</a>
                <a href="/about-us">About ZootPro</a>
            </div>
        </div>
    );
};
