import React, { useEffect, useState } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, useToast } from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Flex,
    Button,
    CloseButton,
} from '@chakra-ui/react';
import axios from 'axios';
import { CustomButton, Loader } from '..';
import { useSelector } from 'react-redux';
import { dispatch, RootStore } from '../../../store';
import { useHistory } from 'react-router-dom';
import { colors } from '../../constants';
import { BackArrow } from '../../../assets/icons/BackArrow';
import { fonts } from '../../utils/fonts';
import { getUserInfoRequest } from '../../../store/actions/user.actions';
import _ from 'lodash';

interface ComponentInterface {
    onClick: () => void;
}

export const NoLicenseHello: React.FC<ComponentInterface> = ({ onClick }) => {

    return (
        <div className="no_license_block">
          <div className="title">
            Add Licenses to Get Started
          </div>
          <div className="btn_click" onClick={() => {
            onClick();  
          }}>
            Add License
          </div>
          <div className="center_info">

          <Accordion defaultIndex={[0]} className="accordion_fix">
            <AccordionItem>
                <AccordionButton className="new_a_button">
                    <Box as="span" flex='1' textAlign='left'>
                      Why do we need your license information?
                    </Box>
                    <AccordionIcon width="30px" height="30px" className="accordion_btn" />
                </AccordionButton>
                <AccordionPanel
                    className="new_a_panel"
                    pb={4}>
                      ZootPro was built to allow for real 
                      estate agents to work together within a brokerage 
                      compliantly and within the bounds of the MLS rules. 
                      <br />
                      In order to continue to keep the system compliant for everyone, all agents who sign up to join the platform are vetted for active licensure so that only licensed agents can participate and preserve the confidentiality of all information that will be exchanged by agents on the platform.
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionButton className="new_a_button">
                    <Box as="span" flex='1' textAlign='left'>
                    What if I have more than one license?
                    </Box>
                    <AccordionIcon width="30px" height="30px" className="accordion_btn" />
                </AccordionButton>
                <AccordionPanel
                    className="new_a_panel"
                    pb={4}>
                    You can decide which license to begin your account with. After adding your
                    first license, you have the ability to add as many additional licenses as
                    possible to your account as you may choose.
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionButton className="new_a_button">
                    <Box as="span" flex='1' textAlign='left'>
                    My license expires soon, what happens then?
                    </Box>
                    <AccordionIcon width="30px" height="30px" className="accordion_btn" />
                </AccordionButton>
                <AccordionPanel
                    className="new_a_panel"
                    pb={4}>
                      Our system is designed to help you keep track of your license and will
                      alert you for its upcoming expiration. After you have renewed your license, 
                      you can simply alert the our team and the system to this fact from within
                      your account settings page.
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionButton className="new_a_button">
                    <Box as="span" flex='1' textAlign='left'>
                    How will my license be vetted?
                    </Box>
                    <AccordionIcon width="30px" height="30px" className="accordion_btn" />
                </AccordionButton>
                <AccordionPanel
                    className="new_a_panel"
                    pb={4}>
                    We have a streamlined administrative system which allows a ZootPro community administrator to access the license information that you share on this form, and with that information to confirm from the real estate commission whether your license is active or not. 
                    <br />
                    If we run into problems while going through this vetting process, a member of our might even reach out to you to gather more information.
                </AccordionPanel>
            </AccordionItem>

          </Accordion>

          </div>
        </div>
    );
};
