import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { HOMEPAGE_PATH } from '../../constants';

interface PrivateRouteProps extends RouteProps {
    component: any;
    isSignedIn: boolean;
    isAccessAllowed: boolean;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = props => {
    const { component: Component, isSignedIn, isAccessAllowed, ...rest } = props;
    return (
        <Route
            {...rest}
            render={routeProps =>
                !isSignedIn ? (
                    <Redirect
                        to={{
                            pathname: '/ ',
                            state: { from: routeProps.location },
                        }}
                    />
                ) : isAccessAllowed ? (
                    <Component {...routeProps} />
                ) : (
                    <Redirect
                        to={{ pathname: HOMEPAGE_PATH, state: { from: routeProps.location } }}
                    />
                )
            }
        />
    );
};
