import React from 'react';
import { Box, Container, Flex, Heading, Text } from '@chakra-ui/react';
import { LandingHeader, LandingFooter, TrialBanner } from '../../common/components/landing';
import { AgencyRelationshipForm } from './AgencyRelationshipForm';
import AgenciesRelationShipsBG from '../../assets/landing/agency-relationships-header-bg.png';
import { fonts } from '../../common/utils';
import { StateDirectoryItem } from '../../common/types';
import { LandingFooterv2 } from '../../common/components/landing/LandingFooterv2';

interface IAgencyRelationshipProps {
    states: StateDirectoryItem[];
    onSendCooperationDetail: (data: FormData) => void;
    sendingDetailsLoading: boolean;
}

export const AgencyRelationship: React.FC<IAgencyRelationshipProps> = ({
    states,
    onSendCooperationDetail,
    sendingDetailsLoading,
}) => {
    return (
        <Box>
            <LandingHeader />
            <Box
                h={{ base: '300px', lg: '450px' }}
                w="100%"
                backgroundRepeat="no-repeat"
                backgroundImage={AgenciesRelationShipsBG}
                position="relative"
                backgroundSize="cover"
                backgroundPosition="center"
                overflowY="hidden"
            >
                <Box
                    position="absolute"
                    background="linear-gradient(180deg, #1E223D 0%, #2C335C 100%)"
                    h="100%"
                    w="100%"
                    top="0"
                    left="0"
                    opacity={0.7}
                    zIndex={1}
                />
                <Container maxW="container.xl" zIndex={2} position="inherit" h="100%">
                    <Flex direction="column" height="100%" justify="center" pb="70px">
                        <Heading
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize={{ base: '28px', lg: '52px' }}
                            lineHeight={{ base: '34px', lg: '62px' }}
                            fontWeight="bold"
                            w={{ base: '100%', lg: '70%' }}
                        >
                            Brokerage Cooperation
                        </Heading>
                        <Text
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize="16px"
                            lineHeight="28px"
                            my="23px"
                        >
                            Submit the details for agency cooperation agreements here.
                        </Text>
                    </Flex>
                </Container>
            </Box>
            <Box
                bg="white"
                borderTopRadius={{ base: '30px', md: '70px' }}
                mt="-70px"
                zIndex={10}
                position="relative"
            >
                <Container maxW="container.xl" py={{ base: '0', lg: '75px' }}>
                    <AgencyRelationshipForm
                        states={states}
                        onSendCooperationDetail={onSendCooperationDetail}
                        sendingDetailsLoading={sendingDetailsLoading}
                    />
                </Container>
            </Box>
            <TrialBanner
                title="Grow your real estate business the stress-free way."
                buttonTitle='Start Now'
                textUnderButton='Cancel anytime'
            />
            <LandingFooterv2 />
        </Box>
    );
};
