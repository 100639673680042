export enum fonts {
    poppins = 'Poppins, sans-serif',
    dmSans = 'DM Sans, sans-serif',
    montserrat = 'Montserrat, sans-serif',
    mulish = 'Mulish, sans-serif',
    avenirBlack = 'Avenir Black',
    avenirBook = 'Avenir Book',
    avenirRoman = 'Avenir Roman',
    ubuntu = 'Ubuntu, sans-serif',
}
