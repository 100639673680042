import React from 'react';

import icon from '../../../src/assets/img/calendar.svg';
import iconCheck from '../../../src/assets/img/check_icon.svg';

export const CalendarIcon2: React.FC = () => (
    <img style={{width: '25px', height: '25px'}} src={icon} />
);

export const CalendarIcon2V2: React.FC<{isActive?: boolean}> = ({isActive = false}) => (
    <div className={'icon_fix '+ (isActive? 'active' : '')} 
        style={{width: "25px", height: '25px', maskImage: "url("+icon+")"  }}
    />
);

export const CheckIconV2: React.FC<{className? : string}> = ({className = ''}) => (
    <img className={className}
        style={{width: '20px', height: '20px'}} 
        src={iconCheck} 
    />
);

