import { createAction } from '@reduxjs/toolkit';
import {
    GetOpenHouseInfoSuccessResponse,
    ListingsType,
    PersonCardType,
    UpdateListingTimeSlotPayload,
    RecreateExternalTimeSlot,
    DeleteListingTimeSlotPayload,
    AddListingTimeSlotPayload,
    ListingInfoType,
    GetAgentOpenHousesPayload,
    GetListingTypesResponse,
    GetMyListingsResponse,
    GetMyClientsResponse,
    GetAgencyListingsPayload,
    GetAgencyRosterPayload,
    PayloadError,
    IResponseWithCustomValue,
    GetMyClientsPayload,
} from '../../common/types';
import { GetDashboardRequestPayload } from '../../common/types/dashboard.types';

// GET OPEN HOUSE INFO
export const getOpenHouseInfo = createAction<number>('GET_OPEN_HOUSE_INFO');
export const getOpenHouseInfoSuccess = createAction<
    IResponseWithCustomValue<GetOpenHouseInfoSuccessResponse>
>('GET_OPEN_HOUSE_INFO_SUCCESS');

// GET MY LISTINGS
export const getMyListings = createAction('GET_MY_LISTINGS');
export const getMyListingsSuccess = createAction<GetMyListingsResponse>('GET_MY_LISTINGS_SUCCESS');
export const getMyListingsError = createAction<PayloadError[]>('GET_MY_LISTINGS_ERROR');

// GET MY CLIENTS
export const getMyClients = createAction<GetMyClientsPayload>('GET_MY_CLIENTS');
export const getMyClientsSuccess = createAction<GetMyClientsResponse>('GET_MY_CLIENTS_SUCCESS');
export const getMyClientsError = createAction<PayloadError[]>('GET_MY_CLIENTS_ERROR');

// GET AGENCY LISTINGS
export const getAgencyListingsRequest = createAction<GetAgencyListingsPayload>(
    'GET_AGENCY_LISTINGS_REQUEST',
);
export const getAgencyListingsSuccess = createAction<{
    listings: ListingsType[];
    totalCount: number;
    pageNumber: number;
}>('GET_AGENCY_LISTINGS_SUCCESS');
export const getAgencyListingsError = createAction<PayloadError[]>('GET_AGENCY_LISTINGS_ERROR');

// GET AGENCY ROSTER
export const getAgencyRosterRequest = createAction<GetAgencyRosterPayload>(
    'GET_AGENCY_ROSTER_REQUEST',
);
export const getAgencyRosterSuccess = createAction<{
    users: PersonCardType[];
    totalCount: number;
    pageNumber: number;
}>('GET_AGENCY_ROSTER_SUCCESS');
export const getAgencyRosterError = createAction<PayloadError[]>('GET_AGENCY_ROSTER_ERROR');

// ADD LISTING TIME SLOT
export const addListingTimeSlot = createAction<AddListingTimeSlotPayload>('ADD_LISTING_TIME_SLOT');
export const addListingTimeSlotSuccess = createAction<number>('ADD_LISTING_TIME_SLOT_SUCCESS');
export const addListingTimeSlotError = createAction<PayloadError[]>('ADD_LISTING_TIME_SLOT_ERROR');

export const recreateExternalTimeSlot = createAction<RecreateExternalTimeSlot>(
    'RECREATE_EXTERNAL_TIME_SLOT',
);
export const recreateExternalTimeSlotSuccess = createAction<number>(
    'RECREATE_EXTERNAL_TIME_SLOT_SUCCESS',
);
export const recreateExternalTimeSlotError = createAction<PayloadError[]>(
    'RECREATE_EXTERNAL_TIME_SLOT_ERROR',
);

// UPDATE LISTING TIME SLOT
export const updateListingTimeSlot = createAction<UpdateListingTimeSlotPayload>(
    'UPDATE_LISTING_TIME_SLOT',
);

export const updateListingTimeSlotSuccess = createAction('UPDATE_LISTING_TIME_SLOT_SUCCESS');
export const updateListingTimeSlotError = createAction<PayloadError[]>(
    'UPDATE_LISTING_TIME_SLOT_ERROR',
);

// DELETE LISTING TIME SLOT
export const deleteListingTimeSlot = createAction<DeleteListingTimeSlotPayload>(
    'DELETE_LISTING_TIME_SLOT',
);
export const deleteListingTimeSlotSuccess = createAction<DeleteListingTimeSlotPayload>(
    'DELETE_LISTING_TIME_SLOT_SUCCESS',
);
export const deleteListingTimeSlotError = createAction<PayloadError[]>(
    'DELETE_LISTING_TIME_SLOT_ERROR',
);

// GET LISTING INFO
export const getListingInfo = createAction<number>('GET_LISTING_INFO');
export const getListingInfoSuccess = createAction<ListingInfoType>('GET_LISTING_INFO_SUCCESS');
export const getListingInfoError = createAction<PayloadError[]>('GET_LISTING_INFO_ERROR');

export const getOpenHouseInfoError = createAction<PayloadError[]>('GET_OPEN_HOUSE_INFO_ERROR');

export const getAgentOpenHouses = createAction<GetAgentOpenHousesPayload>('GET_AGENT_OPEN_HOUSES');
export const getAgentOpenHousesSuccess = createAction<{
    listings: ListingsType[];
    totalCount: number;
}>('GET_AGENT_OPEN_HOUSES_SUCCESS');
export const getAgentOpenHousesError = createAction<PayloadError[]>('GET_AGENT_OPEN_HOUSES_ERROR');

export const getListingTypesRequest = createAction('GET_LISTING_TYPES_REQUEST');
export const getListingTypesSuccess = createAction<GetListingTypesResponse>(
    'GET_LISTING_TYPES_SUCCESS',
);
export const getListingTypesError = createAction<PayloadError[]>('GET_LISTING_TYPES_ERROR');

// GET AGENCY CITIES
export const getAgencyCities = createAction('GET_AGENCY_CITIES');
export const getAgencyCitiesSuccess = createAction<Array<string>>('GET_AGENCY_CITIES_SUCCESS');
export const getAgencyCitiesError = createAction<PayloadError[]>('GET_AGENCY_CITIES_ERROR');

export const selectMultipleTimeSlotsRequest = createAction<{timeslots: number[], dashboardRequestPayload?: GetDashboardRequestPayload}>(
    'SELECT_MULTIPLE_TIME_SLOTS_REQUEST',
);
export const selectMutlipleTimeSlotsSuccess = createAction('SELECT_MULTIPLE_TIME_SLOTS_SUCCESS');
export const selectMultipleTimeSlotsError = createAction<PayloadError[]>(
    'SELECT_MULTIPLE_TIME_SLOTS_ERROR',
);
export const multipleTimeSlotsErrorClear = createAction('MULTIPLE_TIME_SLOTS_ERROR_CLEAR');
