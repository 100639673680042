import React from 'react';
import {
    CloseButton,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    ModalProps,
    Text,
} from '@chakra-ui/react';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';

import { colors } from '../../../constants';
import { fonts } from '../../../utils';

interface SharingModalProps {
    isOpen: boolean;
    size?: ModalProps['size'];
    onClose: VoidFunction;
}

const SHARING_PROVIDERS = [
    { provider: 'facebook', Button: FacebookShareButton, Icon: FacebookIcon },
    { provider: 'telegram', Button: TelegramShareButton, Icon: TelegramIcon },
    { provider: 'twitter', Button: TwitterShareButton, Icon: TwitterIcon },
    { provider: 'whatsapp', Button: WhatsappShareButton, Icon: WhatsappIcon },
    { provider: 'linkedin', Button: LinkedinShareButton, Icon: LinkedinIcon },
    { provider: 'email', Button: EmailShareButton, Icon: EmailIcon },
];

export const SharingModal: React.FC<SharingModalProps> = ({ isOpen, onClose, size = '4xl' }) => {
    const sharingUrl = window.location.href;

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={size} autoFocus={false} isCentered>
            <ModalOverlay />
            <ModalContent bgColor={colors.modalBackground}>
                <ModalHeader display="flex" justifyContent="space-between">
                    <Text color="brand" fontSize="22px" fontFamily={fonts.montserrat}>
                        Share with:
                    </Text>
                    <CloseButton onClick={onClose} />
                </ModalHeader>
                <ModalBody pb="24px">
                    <HStack spacing={1}>
                        {SHARING_PROVIDERS.map(({ provider, Button, Icon }) => (
                            <Button key={provider} url={sharingUrl}>
                                <Icon />
                            </Button>
                        ))}
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
