import { all } from 'redux-saga/effects';
import watchGetMyRequestsSaga from './get-my-requests.saga';
import watchAcceptRequestSaga from './accept-request.saga';
import watchCancelAcceptedWorkSaga from './cancel-accepted-work.saga';
import watchDeleteRequestSaga from './delete-request.saga';
import watchEditRequestSaga from './edit-request.saga';
import watchGetOpenHouseDetailsSaga from './get-open-house-details.saga';
import watchGetRequestSaga from './get-request.saga';
import watchSetReadRequestSaga from './set-request.saga';
import watchGetAgencyRequestsSaga from './get-agency-requests.saga';
import watchRequestApplicantsSaga from './request-applicants.saga';
import watchGetUserFeedbacksSaga from './get-user-feedbacks.saga';
import workerGetFeedbackUserinfo from './get-feedback-userinfo.saga';
import watchChangeClientNameSaga from './change-client-name.saga';
import watchChangeClientPhoneSaga from './change-client-phone.saga';
import watchChangeClientTypeSaga from './change-client-type.saga';
import watchChangePrivateNoteSaga from './change-private-note.saga';

export default function* watchRequestsSaga() {
    yield all([
        watchGetMyRequestsSaga(),
        watchAcceptRequestSaga(),
        watchCancelAcceptedWorkSaga(),
        watchDeleteRequestSaga(),
        watchEditRequestSaga(),
        watchGetOpenHouseDetailsSaga(),
        watchGetRequestSaga(),
        watchSetReadRequestSaga(),
        watchGetAgencyRequestsSaga(),
        watchRequestApplicantsSaga(),
        watchGetUserFeedbacksSaga(),
        workerGetFeedbackUserinfo(),
        watchChangeClientNameSaga(),
        watchChangeClientPhoneSaga(),
        watchChangeClientTypeSaga(),
        watchChangePrivateNoteSaga(),
    ]);
}
