import React, { useEffect, useState } from 'react';
import { HStack, PinInput, PinInputField, useToast } from '@chakra-ui/react';
import * as yup from 'yup';

import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Flex,
    Button,
    CloseButton,
} from '@chakra-ui/react';
import axios from 'axios';
import { CustomButton, CustomInput, Loader } from '..';
import { useSelector } from 'react-redux';
import { dispatch, RootStore } from '../../../store';
import { useHistory } from 'react-router-dom';
import { colors } from '../../constants';
import { BackArrow } from '../../../assets/icons/BackArrow';
import { fonts } from '../../utils/fonts';
import { getUserInfoRequest } from '../../../store/actions/user.actions';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from '../../schemes/messages';
import Countdown from 'react-countdown';
import { ClockIcon, NameInputIcon } from '../../../assets/icons';

type RequestFormValues = {
  first_name: string;
  last_name: string;
};

const RequestSchema = yup.object().shape({
  first_name: yup.string().required(ErrorMessages.REQUIRED),
  last_name: yup.string().required(ErrorMessages.REQUIRED),
});

export const FirstNameLastNameModal: React.FC = () => {


    const {
      register,
      setValue,
      formState: { errors },
      handleSubmit,
      control,
      clearErrors,
      watch,
      reset,
      trigger,
  } = useForm<RequestFormValues>({
      resolver: yupResolver(RequestSchema),
  });

		const [loading, SetLoagin] = useState(false);
		const [isOpen, setIsOpen] = useState(false);
		
    const toast = useToast();
    const history = useHistory();
    const { user: currentUser } = useSelector((state: RootStore) => state.user);
    const [loader, setLoader] = useState<boolean>(false);
    const [LoaderBtn, setLoaderBtn] = useState<boolean>(false);

    const onClose = () => {
      setIsOpen(false);
    }

		const onSubmit = async (data: RequestFormValues) => {
      setLoaderBtn(true);
      await axios.post(window.baseUrl+'/api/profile/savefl', data)
        .then(() => {
          setLoaderBtn(false);
          setIsOpen(false);
          dispatch(getUserInfoRequest());

          history.push('/settings/licenses');
        }).catch((error: any) => {
          setLoaderBtn(false);
        });
    };

    useEffect(() => {
      if(currentUser && currentUser.id){
        if(!currentUser.firstName){
          setIsOpen(true);
        }
      }
    }, [currentUser]);

    return (
        <div className="wraper_fl">
          <Modal
            closeOnEsc={false} 
            closeOnOverlayClick={false} 
            isOpen={isOpen}
            onClose={onClose}
            size="3xl">
              <ModalOverlay />
              <ModalContent
                  className="modal_first_nameln"
                  w={'600px'}
                  borderRadius={'18px'}
                  h={'auto'}
                  bgColor={colors.modalBackground}
                  alignItems="center"
                  paddingBottom="20px"
              >
                  <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      w="100%"
                      px="30px"
                      pt="10px"
                      background="#fff"
                      borderRadius="20px 20px 0px 0px"
                  >
                      <ModalHeader flex={1} align="center">
                          <Text
                            fontSize="22px"
                            color="brand"
                            textAlign="center"
                            lineHeight="18px"
                            fontWeight={700}
                            fontFamily={fonts.montserrat}
                            >
                            Welcome to ZootPro
                          </Text>
                          <div className="desc_title">
                            Please provide your personal information.
                          </div>
                      </ModalHeader>
                  </Flex>
                  <ModalBody w="100%" background="#fff" d="flex" flexDirection="column" overflowY="auto">
                    <form onSubmit={handleSubmit(onSubmit)} className="form_fl">
                      <CustomInput
                          leftIcon={<NameInputIcon />}
                          label="First Name"
                          placeholder="Enter First Name"
                          width="100%"
                          register={{ ...register('first_name') }}
                          errors={errors.first_name}
                      />
                      <br />
                      <CustomInput
                        leftIcon={<NameInputIcon />}
                        label="Last Name"
                        placeholder="Enter Last Name"
                        width="100%"
                        register={{ ...register('last_name') }}
                        errors={errors.last_name}
                      />
                      <br />

                      <Button
                        isFullWidth
                        gridColumnStart="2"
                        variant={'primary'}
                        boxShadow="0px 3px 3px grey"
                        isLoading={LoaderBtn}
                        onClick={() => {
                          handleSubmit(onSubmit)();
                        }}
                        >
                        Submit
                      </Button>
                      <br/>
                    </form>
                    
                  </ModalBody>
              </ModalContent>
          </Modal>
        </div>
    );
};
