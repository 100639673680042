import { call, put, takeEvery } from '@redux-saga/core/effects';
import qs from 'qs';

import API from '../../../common/api/api.base';

import {
    LicenseType,
    LicenseDraftType,
    SetAvailableRequestsPayload,
    RatesType,
    SetNotificationSettingsRequestPayload,
} from '../types';
import { IResponseWithCustomValue, IResponseWithoutValue } from '../../../common/types';

import {
    addCalendarError,
    addCalendarIdError,
    addCalendarIdRequest,
    addCalendarIdSuccess,
    addCalendarRequest,
    addCalendarSuccess,
    addLicenseError,
    addLicenseRequest,
    addLicenseSuccess,
    changeEmailError,
    changeEmailRequest,
    changeEmailSuccess,
    changeFullNameError,
    changeFullNameRequest,
    changeFullNameSuccess,
    changeLicensePhotoError,
    changeLicensePhotoRequest,
    changeLicensePhotoSuccess,
    changePasswordError,
    changePasswordRequest,
    changePasswordSuccess,
    changeUserAvatarError,
    changeUserAvatarRequest,
    changeUserAvatarSuccess,
    editRateError,
    editRateRequest,
    editRateSuccess,
    getCalendarError,
    getCalendarRequest,
    getCalendarSuccess,
    getGoogleCalendarError,
    getGoogleCalendarRequest,
    getGoogleCalendarSuccess,
    getLicenesDraftSuccess,
    getLicenseDraftError,
    getLicenseDraftRequest,
    getLicenseError,
    getLicensesRequest,
    getLicenseSuccess,
    getRatesError,
    getRatesRequest,
    getRatesSuccess,
    saveLicenseDraftError,
    saveLicenseDraftRequest,
    saveLicenseDraftSuccess,
    sendCodeToEmailError,
    sendCodeToEmailRequest,
    sendCodeToEmailSuccess,
    updatePhoneNumberError,
    updatePhoneNumberRequest,
    updatePhoneNumberSuccess,
    verifyEmailCodeError,
    verifyEmailCodeRequest,
    verifyEmailCodeSuccess,
    verifyEmailError,
    verifyEmailRequest,
    verifyEmailSuccess,
    verifyPhoneNumberError,
    verifyPhoneNumberRequest,
    verifyPhoneNumberSuccess,
    getAvailableRequests,
    getAvailableRequestsSuccess,
    getAvailableRequestsError,
    setAvailableRequests,
    setAvailableRequestsSuccess,
    setAvailableRequestsError,
    getNotificationSettingsRequest,
    getNotificationSettingsRequestSuccess,
    getNotificationSettingsRequestError,
    setNotificationSettingsRequest,
    editProfile,
    archiveCurrentUser,
    archiveCurrentUserSuccess,
    archiveCurrentUserError,
    switchIsArchiveCurrentUserModalOpen,
    renewLicenseRequest,
    renewLicenseSuccess,
    renewLicenseError,
} from './actions';
import { getUserInfoRequest } from '../../../store/actions/user.actions';

import { Alerter } from '../../../common/utils';
import { logout } from '../../../store/actions/auth/auth.actions';
import { dispatch } from '../../../store';

function* workerEditProfile(action: ReturnType<typeof editProfile>) {
    const stringifyOptions = {
        addQueryPrefix: true,
        skipNulls: true,
    };

    const params = qs.stringify(action.payload, stringifyOptions);

    try {
        const result: IResponseWithoutValue = yield call(API.put, `/api/profile${params}`, {});

        if (result.success) {
            yield put(getUserInfoRequest());
        } else {
            Alerter.error(result.errors.toString());
        }
    } catch (err: any) {
        const message = err instanceof Error ? err.message : err;
        Alerter.error(message);
    }
}

function* workerArchiveCurrentUser() {
    try {
        const result: IResponseWithoutValue = yield call(
            API.patch,
            `/api/profile/archive-current-user`,
            {},
        );

        if (result.success) {
            yield put(archiveCurrentUserSuccess());
            yield put(switchIsArchiveCurrentUserModalOpen(false));
            yield put(logout());
            Alerter.success(result.messages?.[0]?.messageText);
        } else {
            yield put(archiveCurrentUserError(result.errors));
            Alerter.error(result.errors.toString());
        }
    } catch (err: any) {
        Alerter.error('Something went wrong. Try again later');
    }
}

function* workerSendCodeToEmail() {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            `/api/profile/send-verification-code`,
            {},
        );
        console.log(result, 'result');
        if (result.success) {
            yield put(sendCodeToEmailSuccess());
        } else {
            yield put(sendCodeToEmailError());
        }
    } catch (err) {
        yield put(sendCodeToEmailError());
    }
}

function* workerVerifyEmailCode(action: ReturnType<typeof verifyEmailCodeRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            `/api/profile/verify-code`,
            action.payload,
        );
        if (result.success) {
            yield put(verifyEmailCodeSuccess());
        } else {
            yield put(verifyEmailCodeError());
        }
    } catch (err) {
        yield put(verifyEmailCodeError());
    }
}

function* workerUpdatePhoneNumber(action: ReturnType<typeof updatePhoneNumberRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            `/api/profile/update-phone-number`,
            action.payload,
        );
        if (result.success) {
            yield put(updatePhoneNumberSuccess());
        } else {
            yield put(updatePhoneNumberError());
        }
    } catch (err) {
        yield put(updatePhoneNumberError());
    }
}

function* workerVerifyPhoneNumber(action: ReturnType<typeof verifyPhoneNumberRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            `/api/profile/verify-phone-number`,
            action.payload,
        );
        if (result.success) {
            yield put(verifyPhoneNumberSuccess());
            Alerter.success('Phone number successfully changed');
        } else {
            yield put(verifyPhoneNumberError(result.errors));
        }
    } catch (err) {
        console.log('workerVerifyPhoneNumber error', err);
        // yield put(verifyPhoneNumberError());
    }
}

function* workerChangeEmail(action: ReturnType<typeof changeEmailRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            `/api/profile/change-email`,
            action.payload,
        );
        if (result.success) {
            yield put(changeEmailSuccess());
        } else {
            yield put(changeEmailError());
        }
    } catch (err) {
        yield put(changeEmailError());
    }
}

function* workerVerifyEmail(action: ReturnType<typeof verifyEmailRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            `/api/profile/verify-new-email`,
            action.payload,
        );
        if (result.success) {
            yield put(verifyEmailSuccess());
            // Alerter.success('Email successfully changed');
        } else {
            yield put(verifyEmailError(result.errors));
        }
    } catch (err) {
        console.log('workerVerifyEmail error', err);
        // yield put(verifyEmailError());
    }
}

function* workerChangePassword(action: ReturnType<typeof changePasswordRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            `/api/profile/change-password?OldPassword=${action.payload.oldPassword}&NewPassword=${action.payload.newPassword}&ConfirmNewPassword=${action.payload.confirmNewPassword}`,
            {},
        );
        if (result.success) {
            yield put(changePasswordSuccess());
        } else {
            yield put(changePasswordError());
        }
    } catch (err) {
        yield put(changePasswordError());
    }
}

function* workerChangeFullName(action: ReturnType<typeof changeFullNameRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            `/api/profile?FirstName=${action.payload.firstName}&LastName=${action.payload.lastName}&Email=${action.payload.email}&PhoneNumber=${action.payload.phoneNumber}`,
            {},
        );
        if (result.success) {
            yield put(changeFullNameSuccess());
        } else {
            yield put(changeFullNameError());
        }
    } catch (err) {
        yield put(changeFullNameError());
    }
}

function* workerChangeLicensePhoto(action: ReturnType<typeof changeLicensePhotoRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.file,
            `/api/auth/set-id-license-photo`,
            action.payload.photo,
        );
        if (result.success) {
            yield put(changeLicensePhotoSuccess());
        } else {
            yield put(changeLicensePhotoError());
        }
    } catch (err) {
        yield put(changeLicensePhotoError());
    }
}

function* workerChangeUserAvatar(action: ReturnType<typeof changeUserAvatarRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.file,
            `/api/profile-set-photo`,
            action.payload.photo,
        );
        if (result.success) {
            yield put(changeUserAvatarSuccess());
        } else {
            yield put(changeUserAvatarError());
        }
    } catch (err) {
        yield put(changeUserAvatarError());
    }
}

function* workerGetRates() {
    try {
        const result: IResponseWithCustomValue<{ rates: RatesType[] }> = yield call(
            API.get,
            `/api/profile/get-rates`,
            {},
        );
        if (result.success) {
            yield put(getRatesSuccess(result));
        } else {
            yield put(getRatesError());
        }
    } catch (err) {
        yield put(getRatesError());
    }
}

function* workerEditRate(action: ReturnType<typeof editRateRequest>) {
    try {
        const result: IResponseWithCustomValue<{ rates: RatesType[] }> = yield call(
            API.put,
            `/api/profile/add-rates`,
            {
                rateModels: [action.payload],
            },
        );
        if (result.success) {
            yield put(editRateSuccess());
        } else yield put(editRateError());
    } catch (err) {
        yield put(editRateError());
    }
}

function* workerGetGoogleCalendar(action: ReturnType<typeof getGoogleCalendarRequest>) {
    try {
        const result: IResponseWithCustomValue<string> = yield call(
            API.get,
            `/api/profile/get-user-calendar-id`,
            {},
        );
        if (result.success) {
            yield put(getGoogleCalendarSuccess(result.value));
        } else {
            yield put(getGoogleCalendarError());
        }
    } catch (err) {
        yield put(getGoogleCalendarError());
    }
}

function* workerAddCalendarId(action: ReturnType<typeof addCalendarIdRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            `/api/profile/set-calendar-id`,
            action.payload,
        );
        if (result.success) {
            yield put(addCalendarIdSuccess());
        } else {
            yield put(addCalendarIdError());
        }
    } catch (err) {
        yield put(addCalendarIdError());
    }
}

function* workerGetCalendar(action: ReturnType<typeof getCalendarRequest>) {
    try {
        const result: IResponseWithCustomValue<any> = yield call(
            API.get,
            `/api/profile/get-work-hours`,
            {},
        );
        if (result.success) {
            yield put(getCalendarSuccess(result));
        } else {
            yield put(getCalendarError());
        }
    } catch (err) {
        yield put(getCalendarError());
    }
}

function* workerAddCalendar(action: ReturnType<typeof addCalendarRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(API.put, `/api/profile/add-work-hours`, {
            workHours: [action.payload],
        });
        if (result.success) {
            yield put(addCalendarSuccess());
        } else {
            yield put(addCalendarError());
        }
    } catch (err) {
        yield put(addCalendarError());
    }
}

function* workerGetLicenses() {
    try {
        const result: IResponseWithCustomValue<{ licenseModels: LicenseType[] }> = yield call(
            API.get,
            `/api/profile/get-licenses`,
            {},
        );
        if (result.success) {
            yield put(getLicenseSuccess(result.value.licenseModels));
        } else {
            yield put(getLicenseError());
        }
    } catch (err) {
        yield put(getLicenseError());
    }
}

function* workerRenewLicense(action: ReturnType<typeof renewLicenseRequest>) {
    const stringifiedParams = qs.stringify({ LicenseId: action.payload.id });

    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            `/api/profile/renew-license?${stringifiedParams}`,
            {},
        );
        if (result.success) {
            yield put(renewLicenseSuccess(action.payload.id));
        } else {
            yield put(renewLicenseError(result.errors));
        }
    } catch (err) {
        const message = err instanceof Error ? err.message : 'Unknown error occured';

        yield put(renewLicenseError([{ key: 'unknown', errorMessage: message }]));
    }
}

function* workerSaveLicenseDraft(action: ReturnType<typeof saveLicenseDraftRequest>) {
    try {
        const result: IResponseWithCustomValue<number> = yield call(
            API.put,
            `/api/profile/save-license-draft`,
            action.payload,
        );
        if (result.success) {
            yield put(saveLicenseDraftSuccess(result.value));
            Alerter.success('Saved to draft successfully');
            location.reload();
        } else {
            yield put(saveLicenseDraftError(result.errors));
        }
    } catch (err) {
        console.log(err, 'workerSaveLicenseDraft err');
        // yield put(saveLicenseDraftError());
    }
}

function* workerGetLicenseDraft(action: ReturnType<typeof getLicenseDraftRequest>) {
    try {
        const result: IResponseWithCustomValue<null | LicenseDraftType> = yield call(
            API.get,
            `/api/profile/license-draft`,
            action.payload,
        );
        if (result.success) {
            yield put(getLicenesDraftSuccess(result.value));
        } else {
            yield put(getLicenseDraftError());
        }
    } catch (err) {
        yield put(getLicenseDraftError());
    }
}

function* workerAddLicense(action: ReturnType<typeof addLicenseRequest>) {
    try {
        const result: IResponseWithCustomValue<number> = yield call(
            API.post,
            `/api/profile/add-license`,
            action.payload,
        );
        if (result.success) {
            yield put(addLicenseSuccess(result.value));
            Alerter.success('License added successfully');
            dispatch(getUserInfoRequest());
        } else {
            yield put(addLicenseError(result.errors));
        }
    } catch (err) {
        console.log(err, 'workerAddLicense err');
        // yield put(addLicenseError());
    }
}

function* workerGetAvailableRequests() {
    try {
        const result: IResponseWithCustomValue<SetAvailableRequestsPayload> = yield call(
            API.get,
            '/api/profile/available-request-types',
            {},
        );
        console.log(result, 'workerGetAvailableRequests result');
        if (result.success) {
            yield put(getAvailableRequestsSuccess(result.value));
        } else {
            yield put(getAvailableRequestsError(result.errors));
        }
    } catch (error) {
        console.log('workerGetAvailableRequests error', error);
    }
}

function* workerSetAvailableRequests(action: ReturnType<typeof setAvailableRequests>) {
    const requestTypes = action.payload.requestTypes
        ?.map(type => `&AvailableRequestIds=${type}`)
        .join('');
    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            `/api/profile/available-request-types?${requestTypes}`,
            {},
        );
        console.log('workerSetAvailableRequests result', result);
        if (result.success) {
            yield put(setAvailableRequestsSuccess());
        } else {
            yield put(setAvailableRequestsError(result.errors));
        }
    } catch (error) {
        console.log('workerSetAvailableRequests error', error);
    }
}

function* workerGetAvailableRequestsForNotificationSettings() {
    try {
        const result: IResponseWithCustomValue<SetNotificationSettingsRequestPayload> = yield call(
            API.get,
            '/api/profile/muted-notification-cases',
            {},
        );
        console.log(result, 'workerGetAvailableRequestsForNotificationSettings result');
        if (result.success) {
            yield put(getNotificationSettingsRequestSuccess(result.value));
        } else {
            yield put(getNotificationSettingsRequestError(result.errors));
        }
    } catch (error) {
        console.log('workerGetAvailableRequestsForNotificationSettings error', error);
    }
}

function* workerSetAvailableRequestsForNotificationSettings(
    action: ReturnType<typeof setNotificationSettingsRequest>,
) {
    const cases = action.payload.cases?.map(type => `&CaseIds=${type}`).join('');
    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            `api/profile/muted-notification-cases?${cases}`,
            {},
        );
        console.log('workerSetAvailableRequestsForNotificationSettings result', result);
    } catch (error) {
        console.log('workerSetAvailableRequestsForNotificationSettings error', error);
    }
}

export default function* watchSettingsSaga() {
    yield takeEvery(editProfile.type, workerEditProfile);
    yield takeEvery(archiveCurrentUser.type, workerArchiveCurrentUser);
    yield takeEvery(sendCodeToEmailRequest.type, workerSendCodeToEmail);
    yield takeEvery(verifyEmailCodeRequest.type, workerVerifyEmailCode);
    yield takeEvery(updatePhoneNumberRequest.type, workerUpdatePhoneNumber);
    yield takeEvery(verifyPhoneNumberRequest.type, workerVerifyPhoneNumber);
    yield takeEvery(changeEmailRequest.type, workerChangeEmail);
    yield takeEvery(verifyEmailRequest.type, workerVerifyEmail);
    yield takeEvery(changePasswordRequest.type, workerChangePassword);
    yield takeEvery(changeFullNameRequest.type, workerChangeFullName);
    yield takeEvery(changeLicensePhotoRequest.type, workerChangeLicensePhoto);
    yield takeEvery(changeUserAvatarRequest.type, workerChangeUserAvatar);
    yield takeEvery(getRatesRequest.type, workerGetRates);
    yield takeEvery(editRateRequest.type, workerEditRate);
    yield takeEvery(getGoogleCalendarRequest.type, workerGetGoogleCalendar);
    yield takeEvery(addCalendarIdRequest.type, workerAddCalendarId);
    yield takeEvery(getCalendarRequest.type, workerGetCalendar);
    yield takeEvery(addCalendarRequest.type, workerAddCalendar);
    yield takeEvery(getLicensesRequest.type, workerGetLicenses);
    yield takeEvery(saveLicenseDraftRequest.type, workerSaveLicenseDraft);
    yield takeEvery(getLicenseDraftRequest.type, workerGetLicenseDraft);
    yield takeEvery(addLicenseRequest.type, workerAddLicense);
    yield takeEvery(getAvailableRequests.type, workerGetAvailableRequests);
    yield takeEvery(setAvailableRequests.type, workerSetAvailableRequests);
    yield takeEvery(
        getNotificationSettingsRequest.type,
        workerGetAvailableRequestsForNotificationSettings,
    );
    yield takeEvery(
        setNotificationSettingsRequest.type,
        workerSetAvailableRequestsForNotificationSettings,
    );
    yield takeEvery(renewLicenseRequest.type, workerRenewLicense);
}
