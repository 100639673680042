import API from '../api/api.base';
import { IResponseWithCustomValue, SelectValue, PrevAgent, IdNameTypes, IAddressess } from '../../common/types';
import _ from 'lodash';

const filterByState = (array: any[], stateValue: string | string[] = '') => {
    if (array && typeof stateValue === 'string' && stateValue !== '') {
        return array.filter(item => item.description.split(', ').includes(stateValue));
    }
    if (typeof stateValue === 'object' && stateValue.length !== 0) {
        return array.filter(item =>
            item.description.split(', ').some((value: string) => stateValue.includes(value)),
        );
    }
    if (stateValue === '' || stateValue.length === 0) {
        return array;
    }
    return [];
};

export const carryAddressLoadOptions = (
    searchByCities?: boolean,
    stateFilterValue?: string | string[],
): ((value: string) => Promise<SelectValue[]>) => {
    return function (inputValue: string) {
        return new Promise(async resolve => {
            try {
                const result = await API.get(
                    `/api/v1/generic/google/autocomplete?states=${stateFilterValue}&searchQuery=${inputValue}&isSearchByCities=${searchByCities}`,
                    {},
                );
                const filtered = filterByState(result.predictions, stateFilterValue);
                //const filtered = result.predictions;
                resolve(filtered.map((item: { place_id: any; description: any; }) => ({ value: item.place_id, label: item.description })));
            } catch (error) {
                console.log(error, 'Error occured');
            }
        });
    };
};

export const filterAddressessSync = (
    allAddresses?: IAddressess[],
    stateFilterValue?: string | string[],
): SelectValue[]  => {

    var stateFArr = stateFilterValue? _.isArray(stateFilterValue)? stateFilterValue : [stateFilterValue] : null;
    var allAddressesFilter = allAddresses;
    if(!_.isEmpty(stateFArr)){
        allAddressesFilter = allAddresses?.filter(el => {
            return stateFArr?.includes(el.state);
        });
    }
    
    let ress = allAddressesFilter?.map(el => {
        return {
            value: el.address,
            label: el.address,
        };
    });
    return ress? ress : [];
};

export const filterAddressess = (
    allAddresses?: IAddressess[],
    stateFilterValue?: string | string[],
): ((value: string) => Promise<SelectValue[]>)  => {
    return function (inputValue: string) {
    return new Promise(async resolve => {
        var stateFArr = stateFilterValue? _.isArray(stateFilterValue)? stateFilterValue : [stateFilterValue] : null;
        var allAddressesFilter = allAddresses;
        if(!_.isEmpty(stateFArr)){
            allAddressesFilter = allAddresses?.filter(el => {
                return stateFArr?.includes(el.state);
            });
        }
        
        let ress = allAddressesFilter?.map(el => {
            return {
                value: el.address,
                label: el.address,
            };
        });
        ress = ress? ress : [];

        resolve(ress);

    });
    }
};

export const agentLoadOptions = (inputValue: string): Promise<SelectValue[]> =>
    new Promise(async resolve => {
        try {
            const result: IResponseWithCustomValue<{ totalCount: number; agents: PrevAgent[] }> =
                await API.get(
                    `/api/get-available-users/get-last-agents?Name=${inputValue}&PageNumber=1&PageSize=10`,
                    {},
                );
            resolve(
                result.value.agents.map(item => ({
                    value: item.id + '',
                    label: `${item.firstName} ${item.lastName}`,
                })),
            );
        } catch (error) {
            console.log('Error occured');
        }
    });

export const agenciesLoadOptions = (inputValue: string): Promise<SelectValue[]> => 
    new Promise(async resolve => {
        try {
            const result: IResponseWithCustomValue<{agencies: IdNameTypes[]}> =
                await API.get(
                    `/api/get-agencies?SearchQuery=${inputValue}&PageNumber=1&PageSize=10`,
                    {}
                );
            resolve(
                result.value.agencies.map(item => ({
                    value: item.id + '',
                    label: item.name
                })),
            );
        } catch (error) {
            console.log('Error occured');
        }
    })
