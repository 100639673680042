import React from 'react';
import { USER_SOCIAL_NETWORKS } from '../../../../constants';
import * as userProfileSocialsIcons from '../../../../../assets/icons/UserProfileSocials';
import { ProfileImageType } from '../../../../types';
import { Box, Link, SimpleGrid, Text } from '@chakra-ui/react';

interface IProps {
    userData: ProfileImageType;
}

export const UserProfileSocials: React.FC<IProps> = ({ userData }) => {
    const socials: { link: string; Icon: React.FC }[] = [];

    USER_SOCIAL_NETWORKS.forEach(socialName => {
        const link = userData[socialName];

        if (link) {
            type IconNameType = keyof typeof userProfileSocialsIcons;

            const iconName = (socialName[0].toUpperCase() +
                socialName.slice(1) +
                'Icon') as IconNameType;

            const Icon = userProfileSocialsIcons[iconName];

            socials.push({
                link,
                Icon,
            });
        }
    });

    if (socials.length) {
        return (
            <Box margin="0" padding="0">
                <Text fontWeight={700} fontSize={16} margin="32px 0 19px">
                    Socials
                </Text>

                <SimpleGrid columns={4} spacing="8px">
                    {socials.map(({ link, Icon }) => {
                        const correctedLink = /^http(s)?:\/\//.test(link)
                            ? link
                            : `https://${link}`;
                        return (
                            <Link key={link} href={correctedLink} isExternal>
                                <Icon />
                            </Link>
                        );
                    })}
                </SimpleGrid>
            </Box>
        );
    } else return null;
};
