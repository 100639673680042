import React, { Dispatch, SetStateAction } from 'react';
import { Box, Tab, TabList, Text } from '@chakra-ui/react';
import { colors } from '../../../common/constants';
interface ICustomTabs {
    setTabIndex: Dispatch<SetStateAction<number>>;
    tabIndex: number;
    labels: Array<string>;
}

export const CustomTabs: React.FC<ICustomTabs> = ({ setTabIndex, tabIndex, labels }) => {
    return (
        <TabList w="22vw" h="50px" borderRadius="20px" bg={'#F6F7FB'}>
            {labels.map((label, index) => (
                <Tab
                    p={'0'}
                    w={'100%'}
                    onClick={() => setTabIndex(index + 1)}
                    key={label}
                    position={'relative'}
                >
                    <Text
                        fontSize={'14px'}
                        fontWeight={400}
                        lineHeight={'21px'}
                        letterSpacing={0.2}
                        color={tabIndex === index + 1 ? '#2C335C' : '#2C335C'}
                    >
                        {label}
                    </Text>
                    {tabIndex === index + 1 && (
                        <Box
                            bg="indicatorGreen"
                            position="absolute"
                            w="90%"
                            h="3px"
                            boxShadow={`0px -1px 3px ${colors.indicatorGreenShadow}`}
                            bottom={0}
                            left="5%"
                        />
                    )}
                </Tab>
            ))}
        </TabList>
    );
};
