import { put, call, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
    checkPolicyViewedRequest,
    checkPolicyViewedResult,
    checkTermsViewedRequest,
    checkTermsViewedResult,
} from '../actions/onboarding.actions';
import { loginSuccess } from '../actions/auth/auth.actions';
import Api from '../../common/api/api.base';
import { HOMEPAGE_PATH } from '../../common/constants';
import { IResponseWithoutValue } from '../../common/types';

function* workerCheckPolicyViewed(action: ReturnType<typeof checkPolicyViewedRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            Api.put,
            '/api/auth/privacy-policy',
            {},
            {
                Authorization: `Bearer ${action.payload?.value.token.accessToken}`,
            },
        );
        console.log(result, 'workerCheckPolicyViewed result');
        if (result.success) {
            yield put(checkPolicyViewedResult());
            yield put(push('/terms-and-conditions', { loginResult: action.payload }));
        } else {
            yield put(checkPolicyViewedResult());
        }
    } catch (error) {
        console.log(error);
    }
}

function* workerCheckTermsViewed(action: ReturnType<typeof checkTermsViewedRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            Api.put,
            '/api/auth/terms-of-service',
            {},
            {
                Authorization: `Bearer ${action.payload?.value.token.accessToken}`,
            },
        );
        console.log(result, 'workerCheckTermsViewed');
        if (result.success) {
            console.log('action payload', action.payload!);
            yield put(checkTermsViewedResult());
            yield put(loginSuccess(action.payload!));
            yield put(push(HOMEPAGE_PATH));
        } else {
            yield put(checkTermsViewedResult());
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* watchOnboardingSaga() {
    yield takeEvery(checkPolicyViewedRequest.type, workerCheckPolicyViewed);
    yield takeEvery(checkTermsViewedRequest.type, workerCheckTermsViewed);
}
