import React, { useState } from 'react';

import logoFooter from '../../../img/logo-footer.svg';
import { ModalDemo } from '../../../pages/Landing/ModalDemo';

export const LandingFooterv2: React.FC = () => {
    const [isOpenModalForm, setisOpenModalForm] = useState<boolean>(false);

    return (
        <div className="new_home_landing">
            <footer id="footer">
                <ModalDemo
                    isOpen={isOpenModalForm}
                    onClose={() => {
                        setisOpenModalForm(false);
                    }}
                />
                <div className="container-small">
                    <div className="row">
                        <div className="col">
                            <img className="logo-footer" src={logoFooter} alt="" />
                            <p>A brief conversation to discuss your agent roster, listings exclusives system, and your other intra-brokerage needs.</p>
                        </div>
                        <div className="col">
                            <ul>
                                <li>
                                    <a href="/about-us">About</a>
                                </li>
                                <li>
                                    <a href="/terms-and-conditions">Terms of Services</a>
                                </li>
                                <li>
                                    <a href="/privacy-policy">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="/pricing">Pricing</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <ul>
                                <li>
                                    <a href="/how-it-works">How It Works</a>
                                </li>
                                <li>
                                    <a href="/help">FAQs</a>
                                </li>
                                <li>
                                    <a href="/contact">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <p className="text-uppercase fw-600 footer_title">GET AN INSIDE VIEW</p>
                            <p>A brief conversation to discuss your agent roster, listings exclusives system, and your other intra-brokerage needs.</p>
                            <button type="button" className="btn btn-primary btn_footer"
                                onClick={() => {
                                    setisOpenModalForm(true);
                                }}
                            >Request Demo</button>
                        </div>
                    </div>
                </div>
                <hr />
                <div id="copyright" className="container">
                    <div className="row">
                        <div className="col-md-6">
                            Copyright © 2024 ZootPro Inc. All rights reserved
                        </div>
                        <div className="col-md-6 d-flex">
                            <ul className="socials">
                                {/*
                                <li>
                                    <a href="http://facebook.com/zootpro" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5C18.6274 0.5 24 5.87258 24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5ZM13.2508 19.5557V13.0271H15.053L15.2918 10.7773H13.2508L13.2538 9.65125C13.2538 9.06447 13.3096 8.75006 14.1524 8.75006H15.279V6.5H13.4766C11.3116 6.5 10.5495 7.5914 10.5495 9.42678V10.7776H9.2V13.0274H10.5495V19.5557H13.2508Z" fill="#0089FF"/>
                                        </svg>
                                    </a>
                                </li>
                                */}
                                <li>
                                    <a href="https://www.instagram.com/zootproapp/" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5C18.6274 0.5 24 5.87258 24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5ZM12.0006 6.1C10.2624 6.1 10.0443 6.1076 9.36164 6.13867C8.6803 6.16987 8.21523 6.27773 7.80829 6.436C7.38735 6.59947 7.03028 6.81814 6.67454 7.17401C6.31854 7.52975 6.09987 7.88682 5.93587 8.30762C5.7772 8.71469 5.6692 9.1799 5.63853 9.86097C5.608 10.5436 5.6 10.7619 5.6 12.5001C5.6 14.2382 5.60773 14.4557 5.63867 15.1384C5.67 15.8197 5.77787 16.2848 5.936 16.6917C6.09961 17.1126 6.31827 17.4697 6.67414 17.8255C7.02975 18.1815 7.38682 18.4007 7.80749 18.5641C8.21469 18.7224 8.6799 18.8303 9.36111 18.8615C10.0438 18.8925 10.2618 18.9001 11.9998 18.9001C13.7381 18.9001 13.9556 18.8925 14.6382 18.8615C15.3196 18.8303 15.7852 18.7224 16.1924 18.5641C16.6132 18.4007 16.9697 18.1815 17.3253 17.8255C17.6813 17.4697 17.9 17.1126 18.064 16.6918C18.2213 16.2848 18.3293 15.8196 18.3613 15.1385C18.392 14.4558 18.4 14.2382 18.4 12.5001C18.4 10.7619 18.392 10.5438 18.3613 9.86111C18.3293 9.17977 18.2213 8.71469 18.064 8.30776C17.9 7.88682 17.6813 7.52975 17.3253 7.17401C16.9693 6.81801 16.6133 6.59934 16.192 6.436C15.784 6.27773 15.3186 6.16987 14.6373 6.13867C13.9546 6.1076 13.7373 6.1 11.9986 6.1H12.0006Z" fill="#0089FF"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.4265 7.25335C11.5969 7.25308 11.787 7.25335 12.0006 7.25335C13.7094 7.25335 13.912 7.25948 14.5868 7.29015C15.2108 7.31868 15.5494 7.42295 15.775 7.51055C16.0737 7.62655 16.2866 7.76522 16.5105 7.98922C16.7345 8.21322 16.8732 8.42656 16.9895 8.72523C17.0771 8.95056 17.1815 9.28923 17.2099 9.91324C17.2405 10.5879 17.2472 10.7906 17.2472 12.4986C17.2472 14.2066 17.2405 14.4093 17.2099 15.084C17.1813 15.708 17.0771 16.0466 16.9895 16.272C16.8735 16.5706 16.7345 16.7833 16.5105 17.0072C16.2865 17.2312 16.0738 17.3699 15.775 17.4859C15.5497 17.5739 15.2108 17.6779 14.5868 17.7064C13.9121 17.7371 13.7094 17.7437 12.0006 17.7437C10.2916 17.7437 10.0891 17.7371 9.41444 17.7064C8.79043 17.6776 8.45176 17.5733 8.22603 17.4857C7.92736 17.3697 7.71402 17.231 7.49002 17.007C7.26602 16.783 7.12735 16.5702 7.01108 16.2714C6.92348 16.0461 6.81908 15.7074 6.79068 15.0834C6.76001 14.4088 6.75388 14.2061 6.75388 12.497C6.75388 10.7879 6.76001 10.5863 6.79068 9.91164C6.81921 9.28763 6.92348 8.94896 7.01108 8.72336C7.12708 8.42469 7.26602 8.21136 7.49002 7.98735C7.71402 7.76335 7.92736 7.62468 8.22603 7.50841C8.45163 7.42041 8.79043 7.31641 9.41444 7.28775C10.0048 7.26108 10.2336 7.25308 11.4265 7.25175V7.25335ZM15.4169 8.31602C14.9929 8.31602 14.6489 8.65963 14.6489 9.08376C14.6489 9.50777 14.9929 9.85177 15.4169 9.85177C15.8409 9.85177 16.1849 9.50777 16.1849 9.08376C16.1849 8.65976 15.8409 8.31602 15.4169 8.31602ZM12.0006 9.21337C10.1855 9.21337 8.7139 10.685 8.7139 12.5001C8.7139 14.3152 10.1855 15.7861 12.0006 15.7861C13.8157 15.7861 15.2868 14.3152 15.2868 12.5001C15.2868 10.685 13.8157 9.21337 12.0006 9.21337Z" fill="#0089FF"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12.0006 10.3667C13.1787 10.3667 14.134 11.3218 14.134 12.5001C14.134 13.6782 13.1787 14.6334 12.0006 14.6334C10.8223 14.6334 9.86724 13.6782 9.86724 12.5001C9.86724 11.3218 10.8223 10.3667 12.0006 10.3667Z" fill="#0089FF"/>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="http://linkedin.com/company/zootpro" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5C18.6274 0.5 24 5.87258 24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5ZM8.48027 10.4389H5.76081V18.6098H8.48027V10.4389ZM8.65938 7.91135C8.64173 7.1102 8.06883 6.5 7.13852 6.5C6.20821 6.5 5.6 7.1102 5.6 7.91135C5.6 8.6959 6.19023 9.32367 7.10322 9.32367H7.12059C8.06883 9.32367 8.65938 8.6959 8.65938 7.91135ZM18.2877 13.9249C18.2877 11.4151 16.9461 10.2471 15.1566 10.2471C13.7127 10.2471 13.0663 11.0401 12.7053 11.5965V10.4391H9.98555C10.0214 11.2059 9.98555 18.61 9.98555 18.61H12.7053V14.0467C12.7053 13.8025 12.723 13.5589 12.7949 13.3841C12.9914 12.8962 13.4389 12.3911 14.1903 12.3911C15.1747 12.3911 15.5682 13.1404 15.5682 14.2384V18.6098H18.2876L18.2877 13.9249Z" fill="#0089FF"/>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://X.com/zootproapp" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5C18.6274 0.5 24 5.87258 24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5ZM11.6406 10.2537L11.6658 10.669L11.2461 10.6181C9.71843 10.4232 8.38383 9.76226 7.25067 8.65216L6.69668 8.10135L6.55399 8.5081C6.25181 9.41482 6.44487 10.3724 7.0744 11.0164C7.41015 11.3723 7.33461 11.4232 6.75544 11.2113C6.55399 11.1435 6.37772 11.0927 6.36093 11.1181C6.30218 11.1774 6.50362 11.9486 6.66311 12.2536C6.88134 12.6773 7.32621 13.0925 7.81305 13.3383L8.22434 13.5332L7.73751 13.5417C7.26746 13.5417 7.25067 13.5501 7.30103 13.7281C7.46891 14.2789 8.13201 14.8636 8.87066 15.1178L9.39108 15.2958L8.93781 15.567C8.26631 15.9568 7.4773 16.1771 6.68829 16.194C6.31057 16.2025 6 16.2364 6 16.2618C6 16.3466 7.02404 16.8211 7.61999 17.0075C9.40786 17.5584 11.5315 17.3211 13.1263 16.3805C14.2595 15.711 15.3926 14.3806 15.9214 13.0925C16.2068 12.4062 16.4922 11.152 16.4922 10.5503C16.4922 10.1605 16.5174 10.1097 16.9874 9.64362C17.2644 9.37245 17.5246 9.07586 17.575 8.99112C17.6589 8.83012 17.6505 8.83011 17.2224 8.97417C16.509 9.22839 16.4083 9.1945 16.7608 8.81317C17.021 8.542 17.3316 8.0505 17.3316 7.90645C17.3316 7.88102 17.2057 7.92339 17.063 7.99966C16.9119 8.0844 16.5761 8.21151 16.3243 8.28778L15.8711 8.43184L15.4598 8.15219C15.2331 7.99966 14.9142 7.83018 14.7463 7.77934C14.3182 7.6607 13.6635 7.67765 13.2774 7.81323C12.2282 8.19456 11.5651 9.17755 11.6406 10.2537Z" fill="#0089FF"/>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};
