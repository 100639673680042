import { Button, Flex, useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CustomInput } from '../blocks/CustomInput/CustomInput';
import { RequestBalanceResolver } from './RequestBalanceResolver';

interface IFormChangeBalance {
  emitSubmited?: () => void;
}

type RequestFormValues = {
    amount: number;
};

export const FormChangeBalance: React.FC<IFormChangeBalance> = ({emitSubmited}) => {
    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
        control,
        clearErrors,
        watch,
        reset,
        trigger,
    } = useForm<RequestFormValues>({
        resolver: RequestBalanceResolver,
    });

    const [loader, setLoader] = useState<boolean>(false);
    const toast = useToast();

    const onSubmit = async (data: RequestFormValues) => {
      setLoader(true);
      await axios.post(window.baseUrl+'/api/top_up_balance', data)
        .then(() => {
          setLoader(false);
          //location.reload();
          toast({
            title: 'Your balance successfully updated',
            status: 'info',
            isClosable: true,
          });
          if(emitSubmited){
            emitSubmited();
          }
        }).catch((error: any) => {
          setLoader(false);
          let status = error.response.status;

          if(status === 422){
            toast({
              title: error.response.data.error,
              status: 'error',
              isClosable: true,
            });
            return;
          }

          if(status === 423){
            console.warn('!!!THIS PROCCESS NOT WORK!!!!! STRIPE');
            return;
          }
        });
    };

    return (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CustomInput
                label="Enter Amount: *"
                placeholder="0"
                width="100%"
                register={{ ...register('amount') }}
                errors={errors.amount}
            />
            <br />
            <Button
              isFullWidth
              gridColumnStart="2"
              variant={'primary'}
              boxShadow="0px 3px 3px grey"
              isLoading={loader}
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              >
              Add to balance
            </Button>
          </form>
        </>
    );
};
