import { Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import { CalendarIcon2 } from '../../../assets/icons/CalendarIcon2';
import { PlusIcon } from '../../../assets/icons/PlusIcon';
import { MainHelpModalNow } from '../../../pages/Sidebar/mainmodal';
import { GetHelpNowModal } from '../../../pages/Sidebar/modals/GetHelpNowModal';

interface IBtnAddRequest {
    type?: 'big' | 'small',
}

export const BtnAddRequest: React.FC<IBtnAddRequest> = ({
	type = "big",
}) => 
{
  const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			{type === 'big' && (
				<div className="big_btn" onClick={() => {
          setIsOpen(true);
          }}>
					+ Post
				</div>
			)}

      {type === 'small' && (
				<div className="small_btn" onClick={() => {
          setIsOpen(true);
          }}>
					<PlusIcon />
				</div>
			)}

      <MainHelpModalNow
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          title={'Select type'}
      />
		</>
	);
};

