import { call, put, takeEvery } from 'redux-saga/effects';

import Api from '../../../common/api/api.base';
import { IAddressess, IGetAddressessSuccessResponse, IGetStatesSuccessResponse, IResponseWithCustomValue } from '../../../common/types';
import { getAllAddresses, getallAddressesSuccess, getStates, getStatesError, getStatesSuccess } from '../../actions/directories.actions';

function* workerGetAllAddresses() {
    try {
        const result: IResponseWithCustomValue<IGetAddressessSuccessResponse> = yield call(
            Api.get,
            '/api/directories/getAllAddresses',
            {},
        );
        console.log('getAllAddressess', result);
        

        if (result.success) {
            yield put(getallAddressesSuccess(result));
        } else {

        }
    } catch (error) {
        console.log(error);
    }
}

export function* watchGetAllAddressesDirectorySaga() {
    console.log('1111');

    yield takeEvery(getAllAddresses.type, workerGetAllAddresses);
}
