import React from 'react';
import { Text, VStack } from '@chakra-ui/react';

import { CommentForm, CommentType } from '../../../types';
import { colors } from '../../../../../common/constants';

import { CommentBox } from './CommentBox';

interface ICommentsProps {
    comments: Array<CommentType>;
    postId: number;
    onEdit: (text: string, id: number) => void;
    commentToEditId: number | null;
    onSubmit: (data: CommentForm) => void;
    onCancelEdit: () => void;
}

export const Comments: React.FC<ICommentsProps> = ({ comments, postId, onEdit, commentToEditId, onSubmit, onCancelEdit }) => {
    return (
        <>
            <Text color={colors.brand} lineHeight={'27px'} fontSize={'18px'} fontWeight={'400'}>
                {`Comments (${comments.length})`}
            </Text>
            {!!comments.length && (
                <VStack spacing={'30px'} my={'24px'} align={'stretch'}>
                    {comments.map(comment => (
                        <CommentBox 
                            key={comment.id} 
                            comment={comment} 
                            onEdit={onEdit} 
                            postId={postId} 
                            commentToEditId={commentToEditId} 
                            onSubmit={onSubmit}
                            onCancelEdit={onCancelEdit}
                        />
                    ))}
                </VStack>
            )}
            {/*    Send Comment*/}
        </>
    );
};
