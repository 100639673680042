import React from 'react';
import { Box, Text, Avatar, HStack, } from '@chakra-ui/react';
import { StarIcon } from '../../../../../assets/icons';
import { UserFeedbackType } from '../../../../../pages/Requests/types';
import { getAvatarPicture } from '../../../../../common/utils';

interface IReviewItemProps extends UserFeedbackType {
    onOpenReviewer: () => void;
}

export const ReviewItem: React.FC<IReviewItemProps> = ({
    agent,
    rate,
    avatar,
    dateTime,
    // agentId,
    // id,
    // requestType,
    text,
    onOpenReviewer,
}) => (
    <Box borderBottom="1px solid #D1D2DB" mt="20px">
        <HStack align="flex-start" spacing="20px" w="100%">
            <Avatar name={agent} src={getAvatarPicture(avatar)} w="60px" h="60px" />
            <Box>
                <Text
                    color="brand"
                    fontWeight="semibold"
                    textDecoration="underline"
                    fontSize="16px"
                    lineHeight="24px"
                    as="button"
                    onClick={onOpenReviewer}
                >
                    {agent}
                </Text>
                {/*
                <HStack>
                    <Text color="secondaryFontDarker" fontSize="16px" lineHeight="24px">
                        Rate:
                    </Text>
                    <HStack>
                        <StarIcon color="#FFAA2A" />
                        <Text color="#111734" fontSize="16px">
                            {rate}
                        </Text>
                    </HStack>
                </HStack>
                */}
            </Box>
        </HStack>
        <Text color="brand" fontSize="16px" lineHeight="24px" mb="20px" mt="10px">
            {text}
        </Text>
    </Box>
);
