import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse, { domToReact } from 'html-react-parser';

import {
    Box,
    Flex,
    Text,
    Avatar,
    MenuButton,
    IconButton,
    Menu,
    MenuList,
    MenuItem,
} from '@chakra-ui/react';
import dayjs from 'dayjs';

import commentResolver from '../../validation/comments.schema';
import { Controller, Resolver, useForm } from 'react-hook-form';

import { RootStore } from '../../../../../store';
import { deleteCommentRequest } from '../../../store/actions';
import { CommentForm, CommentType } from '../../../types';

import { DeleteIcon, EditPostIcon, MoreIcon } from '../../../../../assets/icons';
import { colors } from '../../../../../common/constants';
import { getAvatarPicture } from '../../../../../common/utils';

import { PostEditor } from '../../../components';

interface ICommentBoxProps {
    comment: CommentType;
    onEdit: (text: string, id: number) => void;
    postId: number;
    commentToEditId: number | null;
    onSubmit: (data: CommentForm) => void;
    onCancelEdit: () => void;
}

export const CommentBox: React.FC<ICommentBoxProps> = ({
    comment,
    onEdit,
    postId,
    commentToEditId,
    onSubmit,
    onCancelEdit,
}) => {
    const dispatch = useDispatch();
    const currentUserId = useSelector((state: RootStore) => state.user.user.id);

    const onDeleteComment = (id: number) => {
        dispatch(
            deleteCommentRequest({
                commentId: id,
                postId,
            }),
        );
    };

    const { handleSubmit, reset, control } = useForm({
        resolver: commentResolver as Resolver<CommentForm>,
    });

    const isMine = currentUserId === comment.createdBy.id;
    const isEditing = comment.id === commentToEditId;

    const commentParseOptions = {
        replace: (domNode: any) => {
            if (domNode.name === 'p') {
                return <span>{domToReact(domNode.children)}</span>;
            }
        },
    };

    const handleCancel = () => {
        onCancelEdit();
        reset({});
    };

    const onCommentSave = (data: CommentForm) => {
        if (data.comment === comment.text) {
            onCancelEdit();
        } else {
            onSubmit(data);
        }
    };

    return (
        <Box>
            {/*    Comment Header*/}
            <Flex justifyContent={'space-between'}>
                <Flex align={'center'}>
                    <Avatar
                        mr={'8px'}
                        boxSize={'10'}
                        name={comment.createdBy.fullName}
                        src={getAvatarPicture(comment.createdBy.avatar)}
                    />
                    <Box>
                        <Text
                            color={colors.brand}
                            letterSpacing={'1px'}
                            lineHeight={'21px'}
                            fontSize={'16px'}
                            fontWeight={'500'}
                        >
                            {comment.createdBy.fullName}
                        </Text>
                        <Text
                            color={colors.secondaryFontDarker}
                            letterSpacing={'1px'}
                            lineHeight={'21px'}
                            fontSize={'12px'}
                            fontWeight={'400'}
                        >
                            {dayjs(comment.createdAt).fromNow()}
                        </Text>
                    </Box>
                </Flex>
                <Menu>
                    {isMine && (
                        <>
                            <MenuButton
                                as={IconButton}
                                aria-label={'More'}
                                icon={<MoreIcon />}
                                boxShadow="none"
                                bg="transparent"
                            />
                            <MenuList>
                                <MenuItem
                                    onClick={() => onEdit(comment.text, comment.id)}
                                    icon={<EditPostIcon />}
                                >
                                    Edit
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        onDeleteComment(comment.id);
                                    }}
                                    icon={<DeleteIcon />}
                                >
                                    Delete
                                </MenuItem>
                            </MenuList>
                        </>
                    )}
                </Menu>
            </Flex>
            {/*    Comment Text*/}
            {isEditing ? (
                <form onSubmit={handleSubmit(onCommentSave)}>
                    <Controller
                        defaultValue={comment.text}
                        name="comment"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                            <PostEditor
                                onCancelEdit={handleCancel}
                                value={value}
                                errorMsg={error?.message}
                                isEditing={isEditing}
                                onChange={value => {
                                    onChange(value);
                                }}
                            />
                        )}
                    />
                </form>
            ) : (
                <Text
                    mt={'12px'}
                    color={'#16192C'}
                    letterSpacing={'1px'}
                    lineHeight={'24px'}
                    fontSize={'16px'}
                    fontWeight={'400'}
                >
                    {parse(comment.text, commentParseOptions)}
                    {comment.isEdited && (
                        <Text
                            color={'#888DAB'}
                            fontSize={'14px'}
                            lineHeight={'21px'}
                            fontWeight={'400'}
                            ml={'6px'}
                            as="span"
                        >
                            (Edited)
                        </Text>
                    )}
                </Text>
            )}
        </Box>
    );
};
