import React from 'react';

import iSubscribe from '../../../src/assets/img/main_image.png';
import ihome from '../../../src/assets/img/icon_home.svg';

export const ImageSubscribe: React.FC<{className? : string}> = ({className = ''}) => (
    <img className={className}
        src={iSubscribe} 
    />
);

export const IconHomeV2: React.FC<{className? : string}> = ({className = ''}) => (
    <img className={className}
        src={ihome} 
    />
);

