import {
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    Avatar,
    CloseButton,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { RateStar } from '../components/RateStar';
import { CustomButton, CustomTextarea, Loader } from '../../../common/components';
import { AddFeedbackPayload } from '../types';
import { getAvatarPicture } from '../../../common/utils';
import { RequestInfoType } from '../../Requests/types';
import { getFeedbackUserInfo } from '../../Requests/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { useDelta } from 'react-delta';
import dayjs from 'dayjs';
import { Rating } from '../../../common/constants';

interface IFeedbackModal {
    isOpen: boolean;
    onClose: () => void;
    assistRequestId: number;
    addFeedback: (v: AddFeedbackPayload) => void;
}

export const FeedbackModal: React.FC<IFeedbackModal> = ({
    isOpen,
    onClose,
    assistRequestId,
    addFeedback,
}) => {
    const dispatch = useDispatch();
    const [starCount, setStarCount] = useState(0);
    const [noRatingText, setNoRatingText] = useState('');

    const {
        value: feedbackUserInfo,
        errors: feedbackUserInfoErrors,
        loading,
    } = useSelector((state: RootStore) => state.requests.feedbackUserInfo);
    const { loading: addFeedbackLoading, errors: addFeedbackError } = useSelector(
        (state: RootStore) => state.activity.addFeedback,
    );

    const addFeedbackLoadingDelta = useDelta(addFeedbackLoading);

    // const getActivityCategory = () => {
    //     switch (activity.requestCategoryId) {
    //         case 1:
    //             return 'Assistance Request';
    //         case 2:
    //             return 'Opportunity Request';
    //         case 3:
    //             return 'Open House';
    //         default:
    //             return '';
    //     }
    // };

    const {
        formState: { errors },
        handleSubmit,
        register,
        reset,
    } = useForm({});

    const handleClose = () => {
        onClose();
        setNoRatingText('');
        setStarCount(0);
        reset(undefined);
        location.reload();
    };

    const onSubmit = (data: { feedback: string }) => {
        const payload = {
            value: starCount,
            feedback: data.feedback,
            assistRequestId: assistRequestId,
        };
        if (starCount > 0) {
            addFeedback(payload);
        } else {
            setNoRatingText('Please rate the agent!');
        }
    };

    useEffect(() => {
        if (isOpen) {
            dispatch(getFeedbackUserInfo(assistRequestId));
        }
    }, [isOpen]);

    useEffect(() => {
        if (addFeedbackLoadingDelta && addFeedbackLoadingDelta.prev) {
            if (
                addFeedbackLoadingDelta.prev &&
                !addFeedbackLoadingDelta.curr &&
                !addFeedbackError.length
            ) {
                handleClose();
            }
        }
    }, [addFeedbackLoadingDelta]);

    return (
        <Modal isOpen={isOpen} onClose={handleClose} size="lg">
            <ModalOverlay />
            <ModalContent borderRadius={'18px'} alignItems="center">
                <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    px="50px"
                >
                    <Flex justify="space-between" align="center" w="100%" pt="30px" pb="10px">
                        <Text color="brand" fontSize="18px" fontWeight="bold" lineHeight="22px">
                            Please rate this service:
                        </Text>
                        <CloseButton onClick={handleClose} />
                    </Flex>
                </Flex>

                <ModalBody d="flex" w="100%" flexDirection="column" overflowY="auto" px="50px">
                    {loading ? (
                        <Loader centerHeight="600px" />
                    ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Flex
                                align="center"
                                width="100%"
                                justify="flex-start"
                                borderBottom="1px solid #D1D2DB"
                                pb="20px"
                            >
                                <Avatar
                                    boxSize="60px"
                                    src={getAvatarPicture(feedbackUserInfo?.avatar)}
                                    mr="15px"
                                    name={feedbackUserInfo.fullName}
                                />
                                <Flex direction="column" mr="30px">
                                    <Text
                                        fontSize="16px"
                                        fontWeight="500"
                                        lineHeight="25px"
                                        color="brand"
                                        mr="15px"
                                    >
                                        {feedbackUserInfo.fullName}
                                    </Text>
                                    <Text
                                        fontSize="12px"
                                        color="brand"
                                        lineHeight="18px"
                                        fontWeight="300"
                                    >
                                        {feedbackUserInfo.requestType}
                                    </Text>
                                    <Text
                                        fontSize="12px"
                                        color="brand"
                                        lineHeight="18px"
                                        fontWeight="300"
                                        opacity={0.5}
                                    >
                                        {dayjs(feedbackUserInfo.dateTime).format('MMMM D, YYYY')}
                                    </Text>
                                </Flex>
                            </Flex>
                            <Text
                                mt="20px"
                                alignSelf="flex-start"
                                fontSize="16px"
                                color="brand"
                                fontWeight="600"
                            >
                                {feedbackUserInfo.requestType}
                            </Text>
                            <Flex
                                w="100%"
                                mt="16px"
                                mb="20px"
                                direction="column"
                                bg="#F6F7FB"
                                borderRadius="24px"
                                padding="15px 35px"
                            >
                                <Flex justifyContent="space-between">
                                    <Text
                                        fontSize="13px"
                                        lineHeight="20px"
                                        fontWeight="light"
                                        mb="20px"
                                        color="brand"
                                    >
                                        Rate
                                    </Text>
                                    <Text
                                        fontSize="13px"
                                        lineHeight="20px"
                                        fontWeight="light"
                                        mb="20px"
                                        color="brand"
                                    >
                                        {Rating[starCount]}
                                    </Text>
                                </Flex>
                                <RateStar
                                    setStarCount={(value: number) => {
                                        setStarCount(value);
                                        setNoRatingText('');
                                    }}
                                    starCount={starCount}
                                />
                                <Text color="red" mt="20px">
                                    {noRatingText}
                                </Text>
                            </Flex>
                            <CustomTextarea
                                label="Feedback"
                                height="200px"
                                register={{ ...register('feedback') }}
                            />
                            <Flex w="100%" justify="flex-end" py="20px">
                                <CustomButton
                                    borderRadius="14px"
                                    height="60px"
                                    text="Skip"
                                    bgColor="transparent"
                                    borderColor="brand"
                                    color="brand"
                                    onClick={handleClose}
                                />
                                <CustomButton
                                    borderRadius="14px"
                                    height="60px"
                                    text="Send"
                                    type="submit"
                                    bgColor="brand"
                                    ml="10px"
                                    isLoading={addFeedbackLoading}
                                />
                            </Flex>
                        </form>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
