import React, { useEffect, useState } from 'react';
import { HStack, PinInput, PinInputField, useToast } from '@chakra-ui/react';
import * as yup from 'yup';

import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Flex,
    Button,
    CloseButton,
} from '@chakra-ui/react';
import axios from 'axios';
import { CustomButton, CustomInput, Loader } from '..';
import { useSelector } from 'react-redux';
import { dispatch, RootStore } from '../../../store';
import { useHistory } from 'react-router-dom';
import { colors } from '../../constants';
import { BackArrow } from '../../../assets/icons/BackArrow';
import { fonts } from '../../utils/fonts';
import { getUserInfoRequest } from '../../../store/actions/user.actions';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from '../../schemes/messages';
import Countdown from 'react-countdown';

interface ICancelSubscribeModal {
    isOpen: boolean;
    onClose: () => void;
		emitSubmited?: (code: string) => void;
}

type RequestFormValues = {
  email: string;
  code: string;
};

const RequestSchema = yup.object().shape({
    email: yup.string(),
    code: yup.string(),
});

export const VerifyAuthModal: React.FC<ICancelSubscribeModal> = ({ isOpen, onClose, emitSubmited }) => {
    const handleClose = () => {
        onClose();
    };

    const {
      register,
      setValue,
      formState: { errors },
      handleSubmit,
      control,
      clearErrors,
      watch,
      reset,
      trigger,
  } = useForm<RequestFormValues>({
      resolver: yupResolver(RequestSchema),
  });

		const [loading, SetLoagin] = useState(false);
		
    const toast = useToast();
    const history = useHistory();
    const { user: currentUser } = useSelector((state: RootStore) => state.user);
    const [loader, setLoader] = useState<boolean>(false);
    const [LoaderBtn, setLoaderBtn] = useState<boolean>(false);
    const [showCode, setshowCode] = useState<boolean>(false);

    useEffect(() => {
      if(currentUser){
        setValue('email', currentUser.email);
      }
    }, [currentUser]);

		const onSubmit = async (data: RequestFormValues) => {
      setLoaderBtn(true);

      await axios.post(window.baseUrl+'/api/verify/sendCodeEmail', data)
        .then(() => {
          setLoaderBtn(false);
          setshowCode(true);
        }).catch((error: any) => {
          setLoaderBtn(false);
        });
    };

    const onSubmitCheckcode = async (data: RequestFormValues) => {
      setLoaderBtn(true);

      await axios.post(window.baseUrl+'/api/verify/verifyEmail', data)
        .then(() => {
          setLoaderBtn(false);
          onClose();
          if(emitSubmited){
            emitSubmited(data.code);
          }
        }).catch((error: any) => {
          setLoaderBtn(false);
          let status = error.response.status;
          if(status === 422){
            toast({
              title: 'Verification code is incorrect',
              status: 'error',
              isClosable: true,
            });
            return;
          }

        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent
                w={'600px'}
                borderRadius={'18px'}
                h={'auto'}
                bgColor={colors.modalBackground}
                alignItems="center"
                paddingBottom="20px"
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    px="30px"
                    pt="10px"
                    background="#fff"
                    borderRadius="20px 20px 0px 0px"
                >
                    <Box as="button" onClick={onClose}>
                        <BackArrow />
                    </Box>
                    <ModalHeader flex={1} align="center">
                        <Text
                          fontSize="22px"
                          color="brand"
                          textAlign="center"
                          lineHeight="18px"
                          fontWeight={700}
                          fontFamily={fonts.montserrat}
                          >
                          Verify account
                        </Text>
                    </ModalHeader>
                    <CloseButton onClick={onClose} />
                </Flex>
                <ModalBody w="100%" background="#fff" d="flex" flexDirection="column" overflowY="auto">
									<br />
                  <br />

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <CustomInput
                        disabled={true}
                        label="Email"
                        placeholder="0"
                        width="100%"
                        register={{ ...register('email') }}
                        errors={errors.email}
                    />
                    <br />

                    {showCode && (
                      <>
                      <Box
                          mb="20px"
                          pt="22px"
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                      >
                          <Text color="brand" fontWeight={300} fontSize="14px" lineHeight="18px">
                              Enter Code We Sent To Your Email
                          </Text>
                          <Text color="green" fontWeight={300} fontSize="14px" lineHeight="18px">
                              <Countdown
                                  date={Date.now() + 5 * 60 * 1000}
                                  renderer={(props: any) => {
                                      const timerValue = `${props.formatted.minutes}:${props.formatted.seconds}`;
                                      return <Text>{timerValue}</Text>;
                                  }}
                                  onComplete={() => {
                                    setshowCode(false);
                                  }}
                              />
                          </Text>
                      </Box>
                      <Box mt="16px" mb="30px">
                        <Controller
                          control={control}
                          name="code"
                          render={({ field: { ref, ...restField } }) => (
                            <HStack
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              >
                              <PinInput placeholder="-" autoFocus={true} {...restField}>
                                <PinInputField
                                    w="65px"
                                    h="65px"
                                    borderRadius="15px"
                                    ref={ref}
                                />
                                <PinInputField w="65px" h="65px" borderRadius="15px" />
                                <PinInputField w="65px" h="65px" borderRadius="15px" />
                                <PinInputField w="65px" h="65px" borderRadius="15px" />
                              </PinInput>
                            </HStack>
                          )}
                        />
                      </Box>
                      </>
                    )}

                    <Button
                      isFullWidth
                      gridColumnStart="2"
                      variant={'primary'}
                      boxShadow="0px 3px 3px grey"
                      isLoading={LoaderBtn}
                      onClick={() => {
                        if(showCode){
                          handleSubmit(onSubmitCheckcode)();
                        }else{
                          handleSubmit(onSubmit)();
                        }
                      }}
                      >
                      {showCode? "Check code" : "Send code"}
                    </Button>
                    <br/>
                  </form>
                  
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
