import React from 'react';
import {
    Box,
    Flex,
    IconButton,
    Modal,
    ModalContent,
    ModalOverlay,
    Text,
    Image,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { colors, TypeIds } from '../../../../common/constants';
import { CloseButton } from '../../../../assets/icons';
import { fonts } from '../../../../common/utils';
import Showing from '../../../../assets/icons/GetHelpNow/get-help-1.svg';
import HomeInspection from '../../../../assets/icons/GetHelpNow/get-help-2.svg';
import OpenHouse from '../../../../assets/icons/GetHelpNow/get-help-3.svg';
import FinalWalk from '../../../../assets/icons/GetHelpNow/get-help-4.svg';
import Appraisal from '../../../../assets/icons/GetHelpNow/get-help-5.svg';
import Other from '../../../../assets/icons/GetHelpNow/get-help-6.svg';

interface IOpenFindAssistance {
    isOpen: boolean;
    onClose: () => void;
    title: string;
}

export const GetHelpNowModal: React.FC<IOpenFindAssistance> = ({ isOpen, onClose, title }) => {
    const goToMyStuff = () => {
        location.href='/my-stuff';
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
            <ModalOverlay />
            <ModalContent
                overflow={'hidden'}
                height="725px"
                borderRadius="18px"
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <Text
                    color={colors.brand}
                    fontFamily={fonts.montserrat}
                    fontWeight={700}
                    fontSize="22px"
                    lineHeight="18px"
                    textAlign="center"
                    fontStyle="normal"
                    mt="40px"
                    mb="90px"
                >
                    {title}
                </Text>
                <IconButton
                    onClick={onClose}
                    aria-label={'close modal'}
                    icon={<CloseButton />}
                    variant="ghost"
                    position="absolute"
                    top="30px"
                    right="55px"
                />
                <Box mb="90px">
                    <Flex mb="20px">
                        <Link to={{ pathname: '/find-assistance' }}>
                            <Flex
                                borderRadius="14px"
                                borderWidth="5px"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                cursor="pointer"
                            >
                                <Image
                                    src={Showing}
                                    alt="Showing"
                                    width="56px"
                                    height="56px"
                                    mb="25px"
                                    mt="50px"
                                />
                                <Text
                                    width="235px"
                                    textAlign="center"
                                    mb="50px"
                                    lineHeight="28px"
                                    color={colors.brand}
                                    fontWeight="600"
                                    fontStyle="normal"
                                    fontFamily={fonts.poppins}
                                    fontSize="18px"
                                >
                                    Showing
                                </Text>
                            </Flex>
                        </Link>
                        <Link
                            to={{ pathname: '/find-assistance', state: TypeIds['Home Inspection'] }}
                        >
                            <Flex
                                borderRadius="14px"
                                borderWidth="5px"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                ml="20px"
                                mr="20px"
                            >
                                <Image
                                    src={HomeInspection}
                                    alt="HomeInspection"
                                    width="56px"
                                    height="56px"
                                    mb="25px"
                                    mt="50px"
                                />
                                <Text
                                    width="235px"
                                    textAlign="center"
                                    mb="50px"
                                    lineHeight="28px"
                                    color={colors.brand}
                                    fontWeight="600"
                                    fontStyle="normal"
                                    fontFamily={fonts.poppins}
                                    fontSize="18px"
                                >
                                    Home Inspection
                                </Text>
                            </Flex>
                        </Link>
                        <div>
                            <Flex
                                onClick={goToMyStuff}
                                borderRadius="14px"
                                borderWidth="5px"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Image
                                    src={OpenHouse}
                                    alt="OpenHouse"
                                    width="63px"
                                    height="56px"
                                    mb="25px"
                                    mt="50px"
                                />
                                <Text
                                    width="235px"
                                    textAlign="center"
                                    mb="50px"
                                    lineHeight="28px"
                                    color={colors.brand}
                                    fontWeight="600"
                                    fontStyle="normal"
                                    fontFamily={fonts.poppins}
                                    fontSize="18px"
                                >
                                    Open House Coverage
                                </Text>
                            </Flex>
                        </div>
                    </Flex>
                    <Flex>
                        <Link
                            to={{
                                pathname: '/find-assistance',
                                state: TypeIds['Final Walk-Through'],
                            }}
                        >
                            <Flex
                                borderRadius="14px"
                                borderWidth="5px"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Image
                                    src={FinalWalk}
                                    alt="FinalWalk"
                                    width="32px"
                                    height="50px"
                                    mb="25px"
                                    mt="50px"
                                />
                                <Text
                                    width="235px"
                                    textAlign="center"
                                    mb="50px"
                                    lineHeight="28px"
                                    color={colors.brand}
                                    fontWeight="600"
                                    fontStyle="normal"
                                    fontFamily={fonts.poppins}
                                    fontSize="18px"
                                >
                                    Final Walk-through
                                </Text>
                            </Flex>
                        </Link>
                        <Link
                            to={{
                                pathname: '/find-assistance',
                                state: TypeIds['Appraisal'],
                            }}
                        >
                            <Flex
                                borderRadius="14px"
                                borderWidth="5px"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                ml="20px"
                                mr="20px"
                            >
                                <Image
                                    src={Appraisal}
                                    alt="Appraisal"
                                    width="42px"
                                    height="42px"
                                    mb="25px"
                                    mt="50px"
                                />
                                <Text
                                    width="235px"
                                    textAlign="center"
                                    mb="50px"
                                    lineHeight="28px"
                                    color={colors.brand}
                                    fontWeight="600"
                                    fontStyle="normal"
                                    fontFamily={fonts.poppins}
                                    fontSize="18px"
                                >
                                    Appraisal
                                </Text>
                            </Flex>
                        </Link>
                        <Link
                            to={{
                                pathname: '/find-assistance',
                                state: TypeIds['Other'],
                            }}
                        >
                            <Flex
                                borderRadius="14px"
                                borderWidth="5px"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Image
                                    src={Other}
                                    alt="Other"
                                    width="51px"
                                    height="42px"
                                    mb="25px"
                                    mt="50px"
                                />
                                <Text
                                    width="235px"
                                    textAlign="center"
                                    mb="50px"
                                    lineHeight="28px"
                                    color={colors.brand}
                                    fontWeight="600"
                                    fontStyle="normal"
                                    fontFamily={fonts.poppins}
                                    fontSize="18px"
                                >
                                    Other
                                </Text>
                            </Flex>
                        </Link>
                    </Flex>
                </Box>
            </ModalContent>
        </Modal>
    );
};
