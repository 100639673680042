import { createSlice } from '@reduxjs/toolkit';
import {PayloadMessage, IdNameTypes, SearchAgentByAreaResponse, PayloadError} from '../../common/types';
import { State } from '../../pages/Sidebar/types';
import { logout } from '../actions/auth/auth.actions';
import {
    getUserAvatarRequest,
    getUserAvatarSuccess,
    getUserInfoRequest,
    getUserInfoSuccess,
    getProfileAgenciesSuccess,
    searchAgentsByArea,
    searchAgentsByAreaSuccess,
    searchAgentsByAreaError,
    clearAvailableAgents,
    getUnreadsData,
    setUnreadsData,
} from '../actions/user.actions';

type InitialState = {
    unreads: {
        requests : number,
        activity : number,
    },
    loading: boolean;
    errors: PayloadMessage[];
    user: {
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        location: string;
        licenseJurisdiction: string | null;
        rates: string | null;
        brokerOffice: string | null;
        preferredAreasOfService: string | null;
        preferredPayments: string | null;
        agencies: IdNameTypes[];
        currentAgency: number;
        currentStates: State[];
        mlsIds: Array<string>;
        idLicensePhoto: {
            id: number;
            itemHash: string;
            itemExtension: string;
        };
        isLicenseEditable: boolean;
        isSubscribed: boolean,
        isFreeSubscriptionAvailable: boolean,
        userWithdrawProfile?: {
            id: number,
            company?: string,
            account?: string,
        };
    };
    userPhoto: any;
    agencies: IdNameTypes[];
    availableAgents:{
        items: SearchAgentByAreaResponse[],
        loading: boolean
        errors: PayloadError[]
    }

};

export const initialState: InitialState = {
    loading: false,
    errors: [],
    unreads: {
        requests : 0,
        activity : 0,
    },
    user: {
        agencies: [],
        brokerOffice: null,
        currentAgency: 0,
        email: '',
        firstName: '',
        id: 0,
        idLicensePhoto: {
            id: 0,
            itemHash: '',
            itemExtension: '',
        },
        currentStates: [],
        isLicenseEditable: false,
        lastName: '',
        licenseJurisdiction: null,
        location: '',
        mlsIds: [],
        phoneNumber: '',
        preferredAreasOfService: null,
        preferredPayments: null,
        rates: null,
        isSubscribed: false,
        isFreeSubscriptionAvailable: false
    },
    userPhoto: [],
    agencies: [],
    availableAgents:{
        items:[],
        loading: false,
        errors: []
    }
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(setUnreadsData, (state, action) => {
                state.unreads.requests = action.payload.requests;
                state.unreads.activity = action.payload.activity;
            })
            .addCase(getUnreadsData, (state, action) => {
                //state.unreads = true;
                
            })


            .addCase(getUserInfoRequest, state => {
                state.loading = true;
                state.errors = [];
            })
            .addCase(getUserInfoSuccess, (state, action) => {
                state.loading = true;
                state.errors = [];
                state.user = action.payload;
            })
            .addCase(getUserAvatarRequest, state => {
                state.loading = true;
                state.errors = [];
            })
            .addCase(getUserAvatarSuccess, (state, action) => {
                state.loading = true;
                state.errors = [];
                state.userPhoto = action.payload.value.profileImage;
            })
            .addCase(getProfileAgenciesSuccess, (state, action) => {
                state.agencies = action.payload;
            })
            .addCase(logout, (state) => {
                state.userPhoto = [];
                state.user = initialState.user; 
            })
            .addCase(searchAgentsByArea, (state) => {
                state.availableAgents.loading = true
                state.availableAgents.errors = []
            }).addCase(searchAgentsByAreaSuccess, (state, action) => {
            state.availableAgents.loading = false
            state.availableAgents.items = action.payload
        }).addCase(searchAgentsByAreaError, (state, action) =>{
            state.availableAgents.loading = false
            state.availableAgents.errors = action.payload
        }).addCase(clearAvailableAgents, (state) => {
            state.availableAgents.items = []
        })
    },
});

export default userSlice.reducer;
