import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
	useDisclosure,
	Button,
	SlideOptions,
	SlideDirection,
	MenuButton,
	Menu,
	Avatar,
	MenuList,
	MenuItem,
	Text,
	Flex,
	Box,
} from '@chakra-ui/react'
import { HOMEPAGE_PATH } from '../../../constants';
import { LogoIconDashboard } from '../../../../assets/icons/LogoDashboard';
import SidebarContainer from '../../../../pages/Sidebar/Sidebar.container';
import { MobileMenuBtn } from '../../../../assets/icons/MobileMenuBtn';
import { ButtonWithNotification } from '../ButtonWithNotification/ButtonWithNotification';
import { Home, LogoutIcon, Messages } from '../../../../assets/icons';
import { NotificationsPortal } from './components/NotificationsPortal';
import { dispatch, RootStore } from '../../../../store';
import { getUserInfoRequest } from '../../../../store/actions/user.actions';
import { formatPrice } from '../../aglobal/utils';
import { getAvatarPicture } from '../../../utils/getAvatarPicture';
import { toggleUserProfileModal } from '../../../../store/actions/profile.actions';
import { AgenciesDropdown } from './components/AgenciesDropdown/AgenciesDropdown';
import { SetCurrentAgencyPayloadType } from '../../../../pages/Sidebar/types';
import { setCurrentAgencyRequest } from '../../../../pages/Sidebar/store/actions';
import { logout } from '../../../../store/actions/auth/auth.actions';

export const MobileTopPanel: React.FC = () => {
    
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenTwo, onOpen: onOpenTwo, onClose: onCloseTwo } = useDisclosure();
	const [placement, setPlacement] = useState<SlideDirection>('left');

	const [messageNotification, setMessageNotification] = useState<boolean>(false);

	const { value: accountStatus } = useSelector(
			(state: RootStore) => state.payments.getCustomerAccountStatus,
	);
	const roles = useSelector((state: RootStore) => state.auth.user.roles);

	const isAccessAllowed =
			roles?.some((role: string) => (role === 'user' || role === 'newuser')) && accountStatus === 'verified';

	const fetchProfile = () => {
			console.log('FETCH PROFILE');
			dispatch(getUserInfoRequest());
	};

	const onSetCurrentAgency = (payload: SetCurrentAgencyPayloadType) =>
		dispatch(setCurrentAgencyRequest(payload));

	const { user: currentUser, loading } = useSelector((state: RootStore) => state.user);
	const userAvatar = useSelector((state: RootStore) => state.user.userPhoto);
	const agencies = useSelector((state: RootStore) => state.user.user.agencies);
	const token = useSelector((state: RootStore) => state.auth.user.token.accessToken);
	const currentAgency = useSelector((state: RootStore) => state.user.user.currentAgency);
	const onLogout = () => dispatch(logout());

	const history = useHistory();
	const handleSettingsClick = () => history.push('/settings');
	const handleSettingsHelp = () => history.push('/settings/support');

	const avatarName =
	!loading && !userAvatar.avatar?.itemHash
			? currentUser.firstName + ' ' + currentUser.lastName
			: undefined;

	return (
		<>
			<div className="dm_content">
				<div className="left">
					<div className="inline_flex" onClick={onOpen}>
						<MobileMenuBtn />
					</div>
				</div>
				<div className="center">
					<NavLink to={HOMEPAGE_PATH} replace>
						<LogoIconDashboard />
					</NavLink>
				</div>
				<div className="right">
					<div className="right_block_m">
						<NavLink className="icon_menu" to="/messages/none">
							<ButtonWithNotification
								buttonIcon={<Messages width="20" height="20" />}
								notification={messageNotification}
							/>
						</NavLink>
						<div className="icon_menu">
							<NotificationsPortal isAccessAllowed={isAccessAllowed} />
						</div>
						<Menu id="headerMenu">
							<MenuButton className="MENU_TOP" as={Button} rounded={'full'} variant={'link'} minW={0}>
								<Avatar
									ml="0px"
									h="40px"
									w="40px"
									src={getAvatarPicture(userAvatar.avatar)}
									name={avatarName}
								/>
							</MenuButton>
							<MenuList w="300px" zIndex={10} className="menu_list_mobile_d">
									<MenuItem
										justifyContent="flex-start"
										px="20px"
										onClick={() =>
											dispatch(
												toggleUserProfileModal({
													value: true,
													userId: currentUser.id,
												}),
											)
										}
										>
										<Avatar
											h="50px"
											w="50px"
											src={getAvatarPicture(userAvatar.avatar)}
											name={currentUser.firstName + ' ' + currentUser.lastName}
										/>
										<Text
											ml="10px"
											fontSize="16px"
											color="brand"
											textShadow="0px 3px 10px"
											>
											{currentUser.firstName + ' ' + currentUser.lastName}
										</Text>
									</MenuItem>
									<div 
										className="currentBalance"
										style={{color: "black"}} 
										onClick={fetchProfile}
										>
										Balance: ${formatPrice(currentUser.balance)}
									</div>
									<Flex justifyContent="center" alignItems="center" mt="10px" px="20px">
										<AgenciesDropdown
												width="100%"
												maxW="100%"
												height="50px"
												border="1px solid #D1D2DB"
												borderRadius={'15px'}
												options={agencies}
												token={token}
												onSetCurrentAgency={onSetCurrentAgency}
												currentAgency={currentAgency}
										/>
									</Flex>
									<MenuItem
										onClick={handleSettingsClick}
										mt="20px"
										_hover={{
											color: '#EB5757',
										}}
										fontSize="15px"
										color="brand"
										px="20px"
										>
										Profile Settings
									</MenuItem>
									<MenuItem
										onClick={handleSettingsHelp}
										_hover={{
											color: '#EB5757',
										}}
										fontSize="15px"
										color="brand"
										px="20px"
										>
										Help
									</MenuItem>
									<MenuItem onClick={onLogout} fontSize="15px" color="#EB5757" px="20px">
										Log Out
										<Box ml={2}>
											<LogoutIcon />
										</Box>
									</MenuItem>
							</MenuList>
						</Menu>
					</div>
				</div>
			</div>
			
			<Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth='1px'>Menu</DrawerHeader>
					<DrawerBody class="main_menu_modal_body">
						<div className="sidebar_mobile_main_menu">
						<SidebarContainer 
							onCLickLink={() => {
								console.log('FFFFFFFFFFFFFFF');
								onClose();
							}}
							isAccessAllowed={true} 
						/>
						</div>
					</DrawerBody>
				</DrawerContent>
			</Drawer>

		</>
	);
};
