import React from 'react';

import BlastEmpty from '../../../src/assets/img/home2.svg';

export const Home2: React.FC = () => (
    <img style={{width: '25px', height: '25px'}} src={BlastEmpty} />
);

export const Home2v2: React.FC<{isActive?: boolean}> = ({isActive = false}) => (
    <div className={'icon_fix '+ (isActive? 'active' : '')} 
        style={{width: "25px", height: '25px', maskImage: "url("+BlastEmpty+")"  }}
    />
);
