import React, { useState } from 'react';
import { Container, Box, Heading, Text, Image, HStack, Flex, Link, Button, Grid, GridItem, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import {
    LandingHeader,
    MainPageInfoBlock,
    LandingFooter,
    TrialBanner,
    MainPagePoint,
    MainPageHelpPoint,
} from '../../common/components/landing';
import HeaderBG from '../../assets/landing/new-landing-header-bg.png';
import HeaderMobileBG from '../../assets/landing/new-landing-header-bg-mobile.png';
import { ReactComponent as PlayIcon } from '../../assets/landing/play-circle.svg';
import LandingHeroPic from '../../assets/landing/landing-main-hero.png';
import { fonts } from '../../common/utils';
import { mainInfoBlocks, mainInfoPoints, mainInfoHelpPoints, colors } from '../../common/constants';
import { useDispatch } from 'react-redux';
import { toggleLandingVideoModal } from '../../store/actions/landing.actions';
import { ReactComponent as Dots } from '../../assets/landing/dots.svg';
import DownloadAppstore from '../../assets/landing/download-appstore.png';
import DownloadGoogleplay from '../../assets/landing/download-googleplay.png';
import { ImageSlider1 } from '../../assets/icons/LandingIcoms';

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'

import '../../new_landing.scss';

import testimonial1 from '../../img/testimonial-1.png';
import testimonial2 from '../../img/testimonial-2.png';
import testimonial3 from '../../img/testimonial-3.png';

import icnMf from '../../img/Multi-Family.png';
import feat1 from '../../img/feature-1.png';
import feat2 from '../../img/feature-2.png';
import feat3 from '../../img/feature-3.png';

import rev4 from '../../img/review-4.png';
import rev5 from '../../img/review-5.png';
import logoFooter from '../../img/logo-footer.svg';

import videoImage from '../../img/video_image.png';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import { LandingFooterv2 } from '../../common/components/landing/LandingFooterv2';
import { ModalSupport } from '../Sidebar/modalsupport';
import { ModalDemo } from './ModalDemo';

var swiperObj: any;

SwiperCore.use([Navigation]);

import brand1 from '../../img/brand1.svg';
import brand2 from '../../img/barnd2.svg';
import brand3 from '../../img/brand3.svg';

export const NewLanding2: React.FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenModalForm, setisOpenModalForm] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<number>(0);


    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <div className="new_home_landing">
            <LandingHeader />

            <ModalDemo
                isOpen={isOpenModalForm}
                onClose={() => {
                    setisOpenModalForm(false);
                }}
            />

            <Modal
                isOpen={isOpen} onClose={() => {
                setIsOpen(false);
                }}>
                <ModalOverlay />
                <ModalContent 
                    maxW="900px"
                    className="video_modal"
                    >
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <div className="video-frame">
                            <iframe width="100%" height="450" src="https://www.youtube.com/embed/K2p-SfJa8Yw?si=uEH_7cQk3rPgJ-_S" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        
                    </ModalFooter>
                </ModalContent>
            </Modal>

			<div id="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pe-4">
                            <div className="mb-4 title_f_1">#1  Real Estate Affiliations Solution </div>
                            <div className="fs-22 desc_1_f">
                                ZootPro’s affiliated-agent to affiliated-agent products for coverage and runner needs, 
                                and our inter-brokerage and intra-brokerage solutions for sharing referrals and exclusives bring ease,
                                efficiency, and transparency to complying with affiliations and MLS rules, 
                                and earning and tracking referral  commissions. 
                            </div>
                            <button 
                                type="button" 
                                className="red_btn btn btn-primary mt-4"
                                onClick={() => {
                                    setisOpenModalForm(true)
                                }}
                                >
                                Book a Demo
                            </button>
                        </div>
                        <div className="col-lg-6 ps-5">
                            <img 
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                                src={videoImage} 
                                className="video_image" 
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div id="features">
                <div className="container-small">
                    <h2>Comprehensive Leverage Products</h2>
                    <div className="fs-22 mb-56">
                        Whether an agent is needing extra time or extra hands, ZootPro&apos;s 
                        software products allows agents to juggle the many other clients,
                        properties, and commitments that they would otherwise be unable to take on. 
                        By providing leveraged access to the time and skills of affiliated agents as 
                        required for compliance, busier agents regain precious time for pressing matters without 
                        compromising earnings or commissions while newer agents gain additional opportunities to
                        earn with their real estate license.
                    </div>
                    <div className="row text-start align-items-center mb-56">
                        <div className="col-lg-7 pe-5">
                            <h3>Coverage & Referrals</h3>
                            <p>
                            Agents get access to fellow affiliated agents who have signed up and are instantly available to assist with contract-to-close transactions coordination, coverage and runner activities, or the needs of calendar conflicts that may arise with clients and listings. The first solution of its kind on the market today, agents can also send, receive, and track referrals anywhere across the country through ZootPro&apos;s trusted proprietary network of vetted and top producing agents who run top businesses in their markets.
                            </p>
                        </div>
                        <div className="col-lg-5">
                            <img className="w-100" src={feat1} alt="" />
                        </div>
                    </div>
                    <div className="row text-start align-items-center mb-56">
                        <div className="col-lg-5">
                            <img className="w-100" src={feat2} alt="" />
                        </div>
                        <div className="col-lg-7 ps-5">
                            <h3>Off-Market-Needs <span className="badge">Coming Soon</span></h3>
                            <p>
                            Whether a client is wanting to test the waters of
                            the market or looking for a more 
                            private sale, agents become pros at finding
                            matches and solutions to their clients&apos; home sale or home purchase needs 
                            from within their brokerage of affiliation, or within their larger market 
                            area through ZootPro&apos;s proprietary and innovative algorithm 
                            for matching and ranking client&apos;s needs against the needs of the clients of other agents.
                            </p>
                        </div>
                    </div>
                    <div className="row text-start align-items-center">
                        <div className="col-lg-7 pe-5">
                            <h3>Brokerage Affiliation Solutions</h3>
                            <p>
                                ZootPro provides brokers a birds eye view to track coverage requests within a brokerage, 
                                referrals sent and received, and market needs shared with other agents in the area. 
                                Brokerages are increasing sales and gaining control to curate exclusives and 
                                internal brokerage opportunities with the first system that is designed by 
                                legal and compliance experts to comply with the MLS and real estate rules in today&apos;s 
                                evolving regulatory landscape.
                            </p>
                        </div>
                        <div className="col-lg-5">
                            <img className="w-100" src={feat3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cta bg-image">
                <div className="container-small">
                    <div className="cta-section">
                        <div className="row">
                            <div className="col-md-6 col-lg-8 pb-5">
                                <div className="h2_title2">Don&apos;t miss this opportunity to<br/> try ZootPro</div>
                                <div className="desc_df2">Track coverage requests within your brokerage, referrals, and market needs.</div>
                                <button
                                    type="button" 
                                    className="btn btn-primary"
                                    onClick={() => {
                                        setisOpenModalForm(true)
                                    }}
                                >Request Demo</button>
                            </div>
                            <div className="ms-auto col-md-4 col-lg-3 align-items-end d-flex">
                                <img className="file_right" src={icnMf} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="testimonials">
                <div className="container-small">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="text-center">
                                <h2>Voices From Our Valued Agents And Brokers:
                                    Discover What They&apos;re Sharing</h2>
                            </div>
                        </div>
                    </div>
                    <div id="testimonials-block" >
                        <div className="testimonials">
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                            onSwiper={(swiper) => {
                                swiperObj = swiper;
                                console.log(swiperObj);
                            }}
                            onSlideChange={(el) => {
                                setActiveIndex(el.activeIndex);

                                /*
                                console.log('-->');
                                console.log(el);
                                console.log(swiperObj);

                                window.test = swiperObj;
                                */
                            }}
                            >
                            <SwiperSlide>
                                <div className="card testimonial-card">
                                    <div className="card-body">
                                        <div className="testimonial-text">
                                        ZootPro was key in helping me gain back some of my time for crucial, higher income-producing activities that allow me to leverage my strengths. Would highly recommend for any agent that&apos;s struggling with time management, and finding themselves running around for showings when they could be generating lucrative new business via their network using their core skill-set.
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="card testimonial-card">
                                    <div className="card-body">
                                        <div className="testimonial-text">
                                        ZootPro is a game changer, especially for individual agents like myself. Finding runners or affiliated agents for coverage for clients from within my brokerage used to be painful but all that&apos;s changed. It&apos;s a brilliant concept done right, and also very easy to use. I&apos;m always inviting other agents to join the platform because the more people are on, the more we can leverage each other&apos;s skills and time.
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="card testimonial-card">
                                    <div className="card-body">
                                        <div className="testimonial-text">
                                        ZootPro has the best chance to help agents look for new opportunities. The great thing is that I am able to find open houses to host and showings to pick up. I am able to grow my skills and business, and grow my circle by getting connected to so many agents.
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="card testimonial-card">
                                    <div className="card-body">
                                        <div className="testimonial-text">
                                        Bringing ZootPro to our agents was the best leverage and productivity tool addition to our technology offerings. It would typically take us hours or even never to find free agents to cover for others. ZootPro made this easy by allowing agents to liaise amongst themselves without leaving our brokerage so that we stay compliant of the commission affiliation rules. Highly recommend!
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="card testimonial-card">
                                    <div className="card-body">
                                        <div className="testimonial-text">
                                        I&apos;ve been using ZootPro since Day 1 and it has been a real lifesaver. It has streamlined my business and allowed me to focus on my most important tasks while also allowing me to provide opportunities for younger agents to get valuable real world experience. In an industry that traditionally has been a bit slow to innovate, I&apos;m glad to see that an app like ZootPro finally exists.
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                            
                        </div>
                        <div className="testimonials-nav">
                            <div className={"testimonial-short-info "+(activeIndex == 0? 'active' : '')} >
                                <div className="testimonial-short-info-inner">
                                    <div className="testimonial-avatar">
                                        <img src={testimonial1}  alt=""/>
                                    </div>
                                    <div>
                                        <p className="author-name">Spencer Bodian</p>
                                        <p className="author-title">COMPASS, Real Estate Agent</p>
                                        <p className="location">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M7.99935 8.66536C9.10392 8.66536 9.99935 7.76993 9.99935 6.66536C9.99935 5.56079 9.10392 4.66536 7.99935 4.66536C6.89478 4.66536 5.99935 5.56079 5.99935 6.66536C5.99935 7.76993 6.89478 8.66536 7.99935 8.66536Z" stroke="#6C92CB" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M7.99935 14.6654C10.666 11.9987 13.3327 9.61088 13.3327 6.66536C13.3327 3.71985 10.9449 1.33203 7.99935 1.33203C5.05383 1.33203 2.66602 3.71985 2.66602 6.66536C2.66602 9.61088 5.33268 11.9987 7.99935 14.6654Z" stroke="#6C92CB" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            Miami
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={"testimonial-short-info "+(activeIndex == 1? 'active' : '')} >
                                <div className="testimonial-short-info-inner">
                                    <div className="testimonial-avatar">
                                        <img src={testimonial2}  alt=""/>
                                    </div>
                                    <div>
                                        <p className="author-name">Sharif Z. Ibrahim</p>
                                        <p className="author-title">Keller Williams</p>
                                        <p className="location">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M7.99935 8.66536C9.10392 8.66536 9.99935 7.76993 9.99935 6.66536C9.99935 5.56079 9.10392 4.66536 7.99935 4.66536C6.89478 4.66536 5.99935 5.56079 5.99935 6.66536C5.99935 7.76993 6.89478 8.66536 7.99935 8.66536Z" stroke="#6C92CB" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M7.99935 14.6654C10.666 11.9987 13.3327 9.61088 13.3327 6.66536C13.3327 3.71985 10.9449 1.33203 7.99935 1.33203C5.05383 1.33203 2.66602 3.71985 2.66602 6.66536C2.66602 9.61088 5.33268 11.9987 7.99935 14.6654Z" stroke="#6C92CB" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            DC, Maryland, Virginia
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={"testimonial-short-info "+(activeIndex == 2? 'active' : '')} >
                                <div className="testimonial-short-info-inner">
                                    <div className="testimonial-avatar">
                                        <img src={testimonial3}  alt=""/>
                                    </div>
                                    <div>
                                        <p className="author-name">Aalaa Shibly</p>
                                        <p className="author-title">Keller Williams</p>
                                        <p className="location">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M7.99935 8.66536C9.10392 8.66536 9.99935 7.76993 9.99935 6.66536C9.99935 5.56079 9.10392 4.66536 7.99935 4.66536C6.89478 4.66536 5.99935 5.56079 5.99935 6.66536C5.99935 7.76993 6.89478 8.66536 7.99935 8.66536Z" stroke="#6C92CB" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M7.99935 14.6654C10.666 11.9987 13.3327 9.61088 13.3327 6.66536C13.3327 3.71985 10.9449 1.33203 7.99935 1.33203C5.05383 1.33203 2.66602 3.71985 2.66602 6.66536C2.66602 9.61088 5.33268 11.9987 7.99935 14.6654Z" stroke="#6C92CB" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            DC, Washington
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={"testimonial-short-info "+(activeIndex == 3? 'active' : '')} >
                                <div className="testimonial-short-info-inner">
                                    <div className="testimonial-avatar">
                                        <img src={rev4}  alt=""/>
                                    </div>
                                    <div>
                                        <p className="author-name">Tracey Williams Barnett</p>
                                        <p className="author-title">RLAH @Properties</p>
                                        <p className="location">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M7.99935 8.66536C9.10392 8.66536 9.99935 7.76993 9.99935 6.66536C9.99935 5.56079 9.10392 4.66536 7.99935 4.66536C6.89478 4.66536 5.99935 5.56079 5.99935 6.66536C5.99935 7.76993 6.89478 8.66536 7.99935 8.66536Z" stroke="#6C92CB" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M7.99935 14.6654C10.666 11.9987 13.3327 9.61088 13.3327 6.66536C13.3327 3.71985 10.9449 1.33203 7.99935 1.33203C5.05383 1.33203 2.66602 3.71985 2.66602 6.66536C2.66602 9.61088 5.33268 11.9987 7.99935 14.6654Z" stroke="#6C92CB" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            DC, Maryland, Virginia
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={"testimonial-short-info "+(activeIndex == 4? 'active' : '')} >
                                <div className="testimonial-short-info-inner">
                                    <div className="testimonial-avatar">
                                        <img src={rev5}  alt=""/>
                                    </div>
                                    <div>
                                        <p className="author-name">Buchi C. Offodile</p>
                                        <p className="author-title">Sold 100 Real Estate</p>
                                        <p className="location">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M7.99935 8.66536C9.10392 8.66536 9.99935 7.76993 9.99935 6.66536C9.99935 5.56079 9.10392 4.66536 7.99935 4.66536C6.89478 4.66536 5.99935 5.56079 5.99935 6.66536C5.99935 7.76993 6.89478 8.66536 7.99935 8.66536Z" stroke="#6C92CB" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M7.99935 14.6654C10.666 11.9987 13.3327 9.61088 13.3327 6.66536C13.3327 3.71985 10.9449 1.33203 7.99935 1.33203C5.05383 1.33203 2.66602 3.71985 2.66602 6.66536C2.66602 9.61088 5.33268 11.9987 7.99935 14.6654Z" stroke="#6C92CB" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            Maryland
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*
            <div id="faq">
                <div className="container-small">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">


                            <div className="top-content text-center">
                                <h2>Frequently Asked Questions</h2>
                                <p className="subheading">ZootPro has caught the eyes of many. We are always seeking to demystify and simplify the job of real estate agents who we believe are the #1 most essential piece of any transaction. Learn more about ZootPro.</p>
                                <p>Need more help? <a href="/contact">Contact us anytime.</a></p>
                            </div>

                            <Accordion defaultIndex={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]} allowMultiple>
                                <AccordionItem>
                                    <AccordionButton className="new_a_button">
                                        <Box as="span" flex='1' textAlign='left'>
                                            Is ZootPro good for Realtors?
                                        </Box>
                                        <AccordionIcon className="accordion_btn" />
                                    </AccordionButton>
                                    <AccordionPanel
                                        className="new_a_panel"
                                        pb={4}>
                                        Yes, ZootPro is good for new and established agents. 
                                        It is hard for newer agents to get a footing in the business,
                                        and ZootPro provides an avenue to generate business 
                                        by assisting other agents with licensed activities for a small fee. 
                                        Established agents can use ZootPro to gain more leverage in their 
                                        business by outsourcing non-revenue generating tasks to other agents
                                        so that they can focus on the more important things such as generating
                                        more leads, converting current clients of spending time with family
                                        or on other passions.
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionButton className="new_a_button">
                                        <Box as="span" flex='1' textAlign='left'>
                                            Is ZootPro good for Brokers?
                                        </Box>
                                        <AccordionIcon className="accordion_btn" />
                                    </AccordionButton>
                                    <AccordionPanel
                                        className="new_a_panel"
                                        pb={4}>
                                            ZootPro is great for Brokers. Real estate agents leave the 
                                            business at a high rate for various reasons including lack 
                                            of community, trouble with time management, and even problems
                                            with lead generation. New agents most times have no ground on 
                                            which to begin to grow a business. ZootPro helps brokers reduce 
                                            agents attrition and increase agent engagement and productivity 
                                            by providing instant access to other agents for community 
                                            and networking, access to lead generation opportunities for agents who might host open houses for other agents, and even access to time management opportunities as these agents can begin to understand the business by helping other agents who have a need.
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionButton className="new_a_button">
                                        <Box as="span" flex='1' textAlign='left'>
                                            Is ZootPro good for homebuyers?
                                        </Box>
                                        <AccordionIcon className="accordion_btn" />
                                    </AccordionButton>
                                    <AccordionPanel
                                        className="new_a_panel"
                                        pb={4}>
                                        Homebuyers whose real estate agents are part of ZootPro gain an instant fiduciary to other agents who are affiliated to his or her real estate agent. When your agent is out of town and you need a last minute showing, you can rest assured that your agent will get you a trusted professional to assist quickly and properly.
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionButton className="new_a_button">
                                        <Box as="span" flex='1' textAlign='left'>
                                            Is ZootPro good for showings?
                                        </Box>
                                        <AccordionIcon className="accordion_btn" />
                                    </AccordionButton>
                                    <AccordionPanel
                                        className="new_a_panel"
                                        pb={4}>
                                        Homebuyers whose real estate agents are part of ZootPro gain an instant fiduciary to other agents who are affiliated to his or her real estate agent. When your agent is out of town and you need a last minute showing, you can rest assured that your agent will get you a trusted professional to assist quickly and properly.
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionButton className="new_a_button">
                                        <Box as="span" flex='1' textAlign='left'>
                                            Is ZootPro good for Home inspections?
                                        </Box>
                                        <AccordionIcon className="accordion_btn" />
                                    </AccordionButton>
                                    <AccordionPanel
                                        className="new_a_panel"
                                        pb={4}>
                                        Activities like home inspections take a lot of time and do not generate revenues for the agent representing the buyer or seller. When this time could be spent attending to other pressing matters, ZootPro makes it easy to find help quickly.
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionButton className="new_a_button">
                                        <Box as="span" flex='1' textAlign='left'>
                                        Is ZootPro good for real estate agents to hire other agents?
                                        </Box>
                                        <AccordionIcon className="accordion_btn" />
                                    </AccordionButton>
                                    <AccordionPanel
                                        className="new_a_panel"
                                        pb={4}>
                                        Yes. Agents have always assisted other agents with tasks. The only problem has always been finding agents who are affiliated in order to preserve the terms of the broker-agent agreement that disallows agents from hiring agents of other brokerages. Today, agents post to social media, use Excel files to share their needs or availability when there is a better way that this could be done. ZootPro solves this problem beautifully and innovatively for the industry.
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionButton className="new_a_button">
                                        <Box as="span" flex='1' textAlign='left'>
                                        Why is ZootPro legally sound and compliant?
                                        </Box>
                                        <AccordionIcon className="accordion_btn" />
                                    </AccordionButton>
                                    <AccordionPanel
                                        className="new_a_panel"
                                        pb={4}>
                                        Agents are retained to be part of a brokerage through a broker-agent agreement which makes the agent in most cases an exclusive representative of the selected broker. When an agent uses an agent at a different brokerage for any licensed sales activity, they run the risk of breaking this agreement which was created to preclude any misunderstandings in the market about ownerships of leads and clients. ZootPro is the only solution that has solved this problem.
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionButton className="new_a_button">
                                        <Box as="span" flex='1' textAlign='left'>
                                        Is ZootPro a brokerage?
                                        </Box>
                                        <AccordionIcon className="accordion_btn" />
                                    </AccordionButton>
                                    <AccordionPanel
                                        className="new_a_panel"
                                        pb={4}>
                                        ZootPro is a software provider. We are not a brokerage. We do not supervise real estate agents or collect and disperse funds to real estate agents as is expected of brokers.
                                    </AccordionPanel>
                                </AccordionItem>

                            </Accordion>
                        </div>
                    </div>
                    <div className="text-center faq-button">
                        <a href="/help" className="btn btn-primary">View All Frequently Asked Questions</a>
                    </div>
                </div>
            </div>
            */}
            <div className="cta">
                <div className="container-small">
                    <div className="cta-section">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="h2_title3">Don&apos;t miss this opportunity to try ZootPro</div>
                                <p>Let’s find some time to discuss your brokerage retention plans, reporting needs, and existing systems.</p>
                            </div>
                            <div className="col-md-6 align-items-end d-flex">
                                <div className="buttons ms-auto justify-content-between w-100 relative_fe">
                                    <button
                                        type="button" 
                                        className="btn btn-outline-primary"
                                        onClick={() => {
                                            setisOpenModalForm(true)
                                        }}
                                        >Request Demo
                                    </button>
                                    <div className="right_btn_flp">
                                        <div className="ncc_req">
                                            No credit card required.
                                        </div>
                                        <a href="/auth/signup/createaccount" type="button" className="btn btn-primary">
                                            Create Free Account
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bottom_agents">
                <div className="title">
                Trusted By Agents at...
                </div>
                <div className="brands_b">
                    <img
                        src={brand1} 
                    />
                    <img
                        src={brand2} 
                    />
                    <img
                        src={brand3} 
                    />
                </div>
            </div>

            <LandingFooterv2 />
            {/*
                <LandingFooter />
            */}
            {/*
            <ImageSlider1 />

            <Modal
                isOpen={isOpen} onClose={() => {
                setIsOpen(false);
            }}>
                <ModalOverlay />
                <ModalContent
                    >
                    <ModalHeader>Modal Title</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        111111111
                    </ModalBody>

                    <ModalFooter>
                        <span className="btn_close"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >close</span>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            */}
            
            {/*
            <Box>
                <LandingHeader />

                <Box
                    h={{ base: '780px', sm: '700px', lg: '600px' }}
                    w="100%"
                    backgroundRepeat="no-repeat"
                    backgroundImage={{ base: HeaderMobileBG, md: HeaderBG }}
                    position="relative"
                    backgroundSize="cover"
                    overflowY="hidden"
                    backgroundPosition={{ base: 'top', lg: 'inherit' }}
                >
                    <Box
                        position="absolute"
                        background="linear-gradient(180deg, #1E223D 0%, #2C335C 100%)"
                        h="100%"
                        w="100%"
                        top="0"
                        left="0"
                        opacity={0.7}
                        zIndex={1}
                    />
                    <Container
                        maxW="container.xl"
                        zIndex={2}
                        position="inherit"
                        h="100%"
                    >
                        <Flex
                            direction="column"
                            height="100%"
                            justify={{ base: 'flex-start', lg: 'center' }}
                            pb="70px"
                            w="100%"
                        >
                            <Heading
                                color="white"
                                fontFamily={fonts.montserrat}
                                fontSize={{ base: '28px', lg: '45px' }}
                                lineHeight={{ base: '38px', lg: '62px' }}
                                fontWeight="bold"
                                w={{ base: '100%', md: '60%' }}
                                mt={{ base: '100px', lg: '0' }}
                            >
                                Providing real estate agents with instant access to coverage for daily clients and listings needs
                            </Heading>
                            <Text
                                color="white"
                                fontFamily={fonts.montserrat}
                                fontSize="16px"
                                lineHeight="28px"
                                my="23px"
                                w={{ base: '100%', md: '60%' }}
                            >
                                Agents assisting agents with showings and more, in a legally sound and compliant way.
                            </Text>
                            <HStack spacing="25px" mb="45px">
                                <Link
                                    target={'_blank'}
                                    href="https://apps.apple.com/app/zootpro/id1601250757"
                                >
                                    <Image w={{ base: '125px', lg: '140px' }} src={DownloadAppstore} />
                                </Link>
                                <Link
                                    w={{ base: '140px', lg: '160px' }}
                                    target={'_blank'}
                                    href="https://play.google.com/store/apps/details?id=com.zootpro"
                                >
                                    <Image src={DownloadGoogleplay} />
                                </Link>
                            </HStack>
                            <HStack as="button" onClick={() => dispatch(toggleLandingVideoModal(true))}>
                                <PlayIcon />
                                <Text
                                    fontSize="16px"
                                    color="white"
                                    fontFamily={fonts.montserrat}
                                    fontWeight="500"
                                >
                                    Watch video
                                </Text>
                            </HStack>
                        </Flex>

                        <Image
                            src={LandingHeroPic}
                            position="absolute"
                            right="0"
                            bottom={{ base: '0px', lg: '-80px' }}
                            width={{ base: '240px', sm: '270px', md: '400px', lg: '40%' }}
                        />
                    </Container>
                </Box>
                <Box
                    bg="white"
                    borderTopRadius={{ base: '30px', md: '70px' }}
                    mt="-70px"
                    zIndex={10}
                    position="relative"
                >
                    <Container maxW="container.xl" py={{ base: '50px', lg: '100px' }}>
                        <Box
                            maxWidth={835}
                            margin="0 auto"
                            marginBottom={{ base: '28px', lg: '70px' }}
                            fontFamily={fonts.montserrat}
                        >
                            <Heading
                                fontFamily={fonts.montserrat}
                                fontWeight="700"
                                fontSize={{ base: '16px', lg: '32px' }}
                                textAlign="center"
                                lineHeight={{ base: '24px', lg: '40px' }}
                            >
                                90% of real estate agents leave the business in the first five years
                            </Heading>
                            <Text
                                textAlign="center"
                                fontWeight={500}
                                mt="9px"
                                mb="9px"
                                lineHeight={{ base: '24px', lg: '26px' }}
                                fontSize="16px"
                            >
                                *Inman News
                            </Text>
                            <Text
                                textAlign="center"
                                fontWeight={500}
                                lineHeight={{ base: '24px', lg: '31px' }}
                                fontSize={{ base: '16px', lg: '20px' }}
                            >
                                Get instant fiduciary leverage to help service more clients and grow
                                your business.
                            </Text>
                        </Box>
                        <Flex
                            direction={{ base: 'column', md: 'row' }}
                            spacing="25px"
                            position="relative"
                            mb={{ base: '25px', md: '50px' }}
                        >
                            {mainInfoHelpPoints.map((elem, index) => (
                                <MainPageHelpPoint key={elem.title} index={index} {...elem} />
                            ))}
                        </Flex>
                        <Flex justifyContent={'space-between'} alignItems={'center'} direction={{base: 'column', xl: 'row'}} marginBottom={{ base: '0', md: '165px' }}>
                            <Button
                                fontFamily={fonts.montserrat}
                                fontWeight="900"
                                fontSize={{ base: '16px', sm: '22px', md: '32px'}}
                                bg="#2F80ED"
                                width={{ base: '264px', sm: '464px', md: '586px'}}
                                color={colors.white}
                                height={{ base: '62px', md: '100px'}}
                                marginBottom={{ base: '20px', xl: '0' }}
                                borderRadius="25px"
                                _hover={{
                                    textDecoration: 'none',
                                }}
                                as={Link}
                                href="/auth/signup/createaccount"
                            >
                                Activate Your Agent Account
                            </Button>
                            <Button
                                fontFamily={fonts.montserrat}
                                fontWeight="900"
                                fontSize={{ base: '16px', sm: '22px', md: '32px'}}
                                bg="#2F80ED"
                                width={{ base: '264px', sm: '464px', md: '586px'}}
                                color={colors.white}
                                height={{ base: '62px', md: '100px'}}
                                borderRadius="25px"
                                _hover={{
                                    textDecoration: 'none',
                                }}
                                as={Link}
                                href="/request-demo"
                            >
                                Get Brokerage Level Access
                            </Button>
                        </Flex>
                        {mainInfoBlocks.map((elem, index) => (
                            <MainPageInfoBlock key={elem.heading} {...elem} index={index} />
                        ))}
                        <Flex
                            direction={{ base: 'column', md: 'row' }}
                            spacing="25px"
                            position="relative"
                            mt={{ base: '86px', md: '180px' }}
                            mb={{ base: '46px', lg: '141px' }}
                        >
                            <Box
                                position="absolute"
                                top={{ base: '-15px', lg: '-45px' }}
                                left={{ base: '-15px', lg: '-50px' }}
                                bottom={{ base: '0', lg: 'auto' }}
                                zIndex={0}
                            >
                                <Dots />
                            </Box>
                            <Box
                                position="absolute"
                                bottom={{ base: '-15px', lg: '-45px' }}
                                right={{ base: 'auto', md: '-15px', lg: '-15px', xl: '-50px' }}
                                left={{ base: '-15px', md: 'auto' }}
                                zIndex={0}
                            >
                                <Dots />
                            </Box>
                            <Box
                                display={{ base: 'block', md: 'none' }}
                                position="absolute"
                                top="25%"
                                right="-15px"
                                zIndex={0}
                            >
                                <Dots />
                            </Box>
                            {mainInfoPoints.map((elem, index) => (
                                <MainPagePoint key={elem.title} index={index} {...elem} />
                            ))}
                        </Flex>
                    </Container>
                </Box>
                <TrialBanner />
                <LandingFooter />
            </Box>
            */}
        </div>
    );
};
