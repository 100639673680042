import React from 'react';
import { Container, Box, Heading, Text, Flex, SimpleGrid, useMediaQuery } from '@chakra-ui/react';
import {
    LandingHeader,
    LandingFooter,
    PricingPlanBlock,
    PricingBanner,
    PricingCommonQuestonsBlock,
} from '../../common/components/landing';
import { pricingCommonQuestionPoints, colors } from '../../common/constants';
import { fonts } from '../../common/utils';
import PricingBg from '../../assets/landing/pricing-header-bg.png';
import { LandingFooterv2 } from '../../common/components/landing/LandingFooterv2';

export const Pricing: React.FC = () => {
    const [isLargerThan1050] = useMediaQuery('(min-width: 1100px');
    const [isLargerThan900] = useMediaQuery('(min-width: 900px');
    const [isLargerThan600] = useMediaQuery('(min-width: 600px');

    return (
        <Box>
            <LandingHeader />
            <Box
                h="450px"
                w="100%"
                backgroundRepeat="no-repeat"
                backgroundImage={PricingBg}
                position="relative"
                backgroundSize="cover"
                overflowY="hidden"
            >
                <Box
                    position="absolute"
                    background="linear-gradient(180deg, #1E223D 0%, #2C335C 100%)"
                    h="100%"
                    w="100%"
                    top="0"
                    left="0"
                    opacity={0.7}
                    zIndex={1}
                />
                <Container maxW="container.lg" zIndex={2} position="inherit" h="100%">
                    <Flex
                        direction="column"
                        height="100%"
                        justify="center"
                        pb={isLargerThan600 ? '70px' : '300px'}
                    >
                        <Heading
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize={isLargerThan600 ? '52px' : '24px'}
                            lineHeight="62px"
                            fontWeight="bold"
                            w="60%"
                        >
                            Subscriptions
                        </Heading>
                        <Text
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize={isLargerThan600 ? '16px' : '13px'}
                            lineHeight="28px"
                            my={isLargerThan600 ? '23px' : '0'}
                        >
                            Pricing is simple with no hidden fees. Choose between two plans.
                        </Text>
                    </Flex>
                </Container>
            </Box>
            <Box
                bg="white"
                borderTopRadius={isLargerThan600 ? '70px' : '40px'}
                mt={isLargerThan600 ? '-70px' : '-300px'}
                zIndex={10}
                position="relative"
            >
                <Container maxW="container.lg" pt={isLargerThan600 ? '75px' : '40px'}>
                    <Box
                        d="flex"
                        flexDirection={isLargerThan1050 ? 'row' : 'column'}
                        justifyContent="space-between"
                        alignItems="space-between"
                        marginLeft="auto"
                        marginRight="auto"
                    >
                        <Box w={isLargerThan1050 ? '45%' : '90%'} mb="20px" ml="auto" mr="auto">
                            <PricingPlanBlock periodText="Premier Monthly" color="#BF40BF" period="Monthly" />
                        </Box>
                        <Box w={isLargerThan1050 ? '45%' : '90%'} mb="20px" ml="auto" mr="auto">
                            <PricingPlanBlock periodText="Premier Annually" color="#2CC197" period="Annual" />
                        </Box>
                    </Box>
                </Container>
                <Container maxW="container.lg" pb={isLargerThan600 ? '75px' : '40px'}>
                    <Box
                        height="1px"
                        width="100%"
                        border="0"
                        bg="#D1D2DB"
                        mt="50px"
                        d={isLargerThan600 ? 'none' : 'block'}
                    />
                    <Box
                        d="flex"
                        justifyContent={isLargerThan900 ? 'space-between' : 'space-around'}
                        alignItems="center"
                        mt="50px"
                    >
                        <Box
                            height="1px"
                            width={isLargerThan900 ? '35%' : '20%'}
                            border="0"
                            bg="#D1D2DB"
                            d={isLargerThan600 ? 'block' : 'none'}
                        />
                        <Box
                            fontFamily={fonts.montserrat}
                            color={colors.brand}
                            fontWeight="semibold"
                            fontSize="18px"
                        >
                            Common Pricing Questions
                        </Box>
                        <Box
                            height="1px"
                            width={isLargerThan900 ? '35%' : '20%'}
                            border="0"
                            bg="#D1D2DB"
                            d={isLargerThan600 ? 'block' : 'none'}
                        />
                    </Box>
                    <SimpleGrid columns={isLargerThan900 ? 2 : 1} columnGap="100px" rowGap="50px">
                        {pricingCommonQuestionPoints.map(
                            (
                                item: {
                                    title: string;
                                    description: string;
                                },
                                index: number,
                            ) => (
                                <PricingCommonQuestonsBlock key={index} {...item} />
                            ),
                        )}
                    </SimpleGrid>
                </Container>
            </Box>
            <PricingBanner />
            <LandingFooterv2 />
        </Box>
    );
};
