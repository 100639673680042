import { put, call, takeEvery } from 'redux-saga/effects';
import {
    getApplicantsToRequest,
    getApplicantsToRequestSuccess,
    getApplicantsToRequestError,
    confirmApplicant,
    confirmApplicantSuccess,
    confirmApplicantError,
    getPartnerApplicantsRequest,
    getPartnerApplicantsSuccess,
    getPartnerApplicantsFailed,
    rejectApplicantRequest,
    rejectApplicantSuccess,
    rejectApplicantError,
} from '../actions';
import API from '../../../../common/api/api.base';
import { IResponseWithoutValue, IResponseWithCustomValue } from './../../../../common/types';
import { ApplicantType } from '../../types';
import { getDashboardRequest } from '../../../../store/actions/dashboard.actions';

function* workerGetApplicants(action: ReturnType<typeof getApplicantsToRequest>) {
    console.log('getApplicantsToRequest action', action);
    try {
        const result: IResponseWithCustomValue<{ applicantModels: ApplicantType[] }> = yield call(
            API.get,
            `/api/request/applicants?RequestId=${action.payload}`,
            {},
        );
        if (result.success) {
            yield put(getApplicantsToRequestSuccess(result.value.applicantModels));
            console.log('getApplicantsToRequestSuccess', result);
        } else {
            yield put(getApplicantsToRequestError(result.errors));
            console.log('getApplicantsToRequestError', result);
        }
    } catch (error) {
        console.error(error);
    }
}

function* workerConfirmApplicant(action: ReturnType<typeof confirmApplicant>) {
    try {

        const result: IResponseWithoutValue = yield call(
            API.put,
            '/api/request/confirm-applicant',
            action.payload,
        );
        if (result.success) {
            location.reload();
            /*
            yield put(confirmApplicantSuccess(action.payload.applicantId));
            console.log('confirmApplicantSuccess', result);
            if (action.payload.dashboardRequestPayload) {
                yield put(getDashboardRequest(action.payload.dashboardRequestPayload));
            }
            */
        } else {
            yield put(confirmApplicantError(result.errors));
            console.log('confirmApplicantError', result);
        }
    } catch (error) {
        console.log('confirmApplicantError', error);
    }
}

function* workerRejectApplicant(action: ReturnType<typeof rejectApplicantRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.delete,
            `/api/request/reject-applicant?RequestId=${action.payload.requestId}&ApplicantId=${action.payload.applicantId}`,
        );
        if (result.success) {
            yield put(rejectApplicantSuccess(action.payload));
            if (action.payload.dashboardRequestPayload) {
                yield put(getDashboardRequest(action.payload.dashboardRequestPayload));
            }
            yield workerGetApplicants({ payload: action.payload.requestId, type: '' });
            yield workerGetPartnerApplicants({ payload: action.payload.requestId, type: '' });
            console.log('rejectApplicantSuccess', result);
        } else {
            yield put(rejectApplicantError(result.errors));
            console.log('rejectApplicantError', result);
        }
    } catch (error) {
        console.log('rejectApplicantError', error);
    }
}

function* workerGetPartnerApplicants(action: ReturnType<typeof getPartnerApplicantsRequest>) {
    try {
        const result: IResponseWithCustomValue<{ applicantModels: ApplicantType[] }> = yield call(
            API.get,
            `/api/request/get-partner-agency-applicants?RequestId=${action.payload}`,
            {},
        );
        if (result.success) {
            yield put(getPartnerApplicantsSuccess(result.value.applicantModels));
            console.log('getPartnerApplicants Success', result);
        } else {
            yield put(getPartnerApplicantsFailed(result.errors));
            console.log('getPartnerApplicants Result', result);
        }
    } catch (error) {
        console.log(error, 'workerGetPartnerApplicants');
    }
}

export default function* watchRequestApplicantsSaga() {
    yield takeEvery(getApplicantsToRequest.type, workerGetApplicants);
    yield takeEvery(confirmApplicant.type, workerConfirmApplicant);
    yield takeEvery(getPartnerApplicantsRequest.type, workerGetPartnerApplicants);
    yield takeEvery(rejectApplicantRequest.type, workerRejectApplicant);
}
